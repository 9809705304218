import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  UUID: any;
  Date: any;
  Upload: any;
  BigInt: any;
};

export type Acl = {
  __typename?: 'ACL';
  id: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AddUserDeviceInput = {
  userId: Scalars['Int'];
  deviceId: Scalars['String'];
  installId: Scalars['String'];
  bundleId?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  deviceLocale?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  installReferrer?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  systemName?: Maybe<Scalars['String']>;
  systemVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  pushToken?: Maybe<Scalars['String']>;
  expoPushToken?: Maybe<Scalars['String']>;
};

export type ApplyLoyaltyPromoCodeResult = {
  __typename?: 'ApplyLoyaltyPromoCodeResult';
  promoCode: LoyaltyPromoCode;
  promo: LoyaltyPromo;
};

export enum AppStoreEnum {
  APP_STORE = 'APP_STORE',
  GOOGLE_PLAY = 'GOOGLE_PLAY',
}

export type Article = {
  __typename?: 'Article';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  categoryId: Scalars['Int'];
  state: ArticleState;
  publishedAt?: Maybe<Scalars['DateTime']>;
  localeId: Scalars['Int'];
  url?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  category: ArticleCategory;
  tags?: Maybe<Array<Scalars['String']>>;
  blocks?: Maybe<Array<ArticleBlock>>;
  preview?: Maybe<Array<ArticleBlock>>;
  authors?: Maybe<Array<ArticleAuthor>>;
  links?: Maybe<Array<ArticleLink>>;
  log?: Maybe<Array<ArticleLog>>;
  title: Scalars['String'];
  locale: SystemLocale;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ArticleArticleTag = {
  __typename?: 'ArticleArticleTag';
  id: Scalars['Int'];
  articleId: Scalars['Int'];
  tagId: Scalars['Int'];
  tag: ArticleTag;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ArticleArticleTagConnection = DefaultConnection & {
  __typename?: 'ArticleArticleTagConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ArticleArticleTag>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type ArticleArticleTagCreateInput = {
  articleId: Scalars['Int'];
  tagId: Scalars['Int'];
};

export type ArticleArticleTagUpdateInput = {
  articleId?: Maybe<Scalars['Int']>;
  tagId?: Maybe<Scalars['Int']>;
};

export type ArticleAuthor = {
  __typename?: 'ArticleAuthor';
  id: Scalars['Int'];
  articleId: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  role: ArticleAuthorRole;
  user: SystemUser;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ArticleAuthorConnection = DefaultConnection & {
  __typename?: 'ArticleAuthorConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ArticleAuthor>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type ArticleAuthorCreateInput = {
  articleId: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  role?: Maybe<ArticleAuthorRole>;
};

export enum ArticleAuthorRole {
  AUTHOR = 'AUTHOR',
  EDITOR = 'EDITOR',
  JOURNALIST = 'JOURNALIST',
  PHOTOGRAPHER = 'PHOTOGRAPHER',
  PHOTO_EDITOR = 'PHOTO_EDITOR',
}

export type ArticleAuthorUpdateInput = {
  articleId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  role?: Maybe<ArticleAuthorRole>;
};

export type ArticleBlock = {
  __typename?: 'ArticleBlock';
  id: Scalars['Int'];
  articleId: Scalars['Int'];
  authorId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  type: ArticleBlockType;
  data?: Maybe<Scalars['JSON']>;
  isPreview: Scalars['Boolean'];
  sort: Scalars['Int'];
  versions?: Maybe<Array<ArticleBlock>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ArticleBlockAuthor = {
  __typename?: 'ArticleBlockAuthor';
  blockId: Scalars['Int'];
  author: SystemUser;
};

export type ArticleBlockAuthorsConnection = DefaultConnection & {
  __typename?: 'ArticleBlockAuthorsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ArticleBlockAuthor>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type ArticleBlockConnection = DefaultConnection & {
  __typename?: 'ArticleBlockConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ArticleBlock>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type ArticleBlockCreateInput = {
  articleId: Scalars['Int'];
  authorId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ArticleBlockType>;
  data?: Maybe<Scalars['JSON']>;
  isPreview?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['Int']>;
};

export type ArticleBlocksUpdateInput = {
  id: Scalars['Int'];
  articleId?: Maybe<Scalars['Int']>;
  authorId?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ArticleBlockType>;
  data?: Maybe<Scalars['JSON']>;
  isPreview?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['Int']>;
};

export type ArticleBlockTitle = {
  __typename?: 'ArticleBlockTitle';
  locale: Scalars['String'];
  value: Scalars['String'];
};

export enum ArticleBlockType {
  TITLE = 'TITLE',
  SUB_TITLE = 'SUB_TITLE',
  INCUT = 'INCUT',
  HTML = 'HTML',
  PHOTO_GALLERY = 'PHOTO_GALLERY',
  ENTITY = 'ENTITY',
  EMBED = 'EMBED',
  VIDEO = 'VIDEO',
  CODE = 'CODE',
  LINK = 'LINK',
  TEXT = 'TEXT',
  PDF = 'PDF',
  ACTIONS = 'ACTIONS',
}

export type ArticleBlockUpdateInput = {
  articleId?: Maybe<Scalars['Int']>;
  authorId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ArticleBlockType>;
  data?: Maybe<Scalars['JSON']>;
  isPreview?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['Int']>;
};

export type ArticleCategoriesConnection = {
  __typename?: 'ArticleCategoriesConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ArticleCategory>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type ArticleCategory = {
  __typename?: 'ArticleCategory';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ArticleCategoryConnection = DefaultConnection & {
  __typename?: 'ArticleCategoryConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ArticleCategory>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type ArticleCategoryCreateInput = {
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
};

export type ArticleCategoryUpdateInput = {
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
};

export type ArticleCategoryWhereInput = {
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
};

export type ArticleCreateInput = {
  categoryId: Scalars['Int'];
  state?: Maybe<ArticleState>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  localeId: Scalars['Int'];
  url?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type ArticleLink = {
  __typename?: 'ArticleLink';
  id: Scalars['Int'];
  articleId: Scalars['Int'];
  entityId: Scalars['Int'];
  entityType: ArticleLinkEntityType;
  entity: ArticleLinkEntity;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ArticleLinkConnection = DefaultConnection & {
  __typename?: 'ArticleLinkConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ArticleLink>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type ArticleLinkCreateInput = {
  articleId: Scalars['Int'];
  entityId: Scalars['Int'];
  entityType?: Maybe<ArticleLinkEntityType>;
};

export type ArticleLinkEntity = TournamentMatch | TournamentTour | TournamentTeam | TournamentMatchEvent;

export type ArticleLinkEntityRecord =
  | TournamentMatch
  | TournamentTour
  | TournamentTeam
  | TournamentMatchEvent
  | TournamentTeamMember;

export enum ArticleLinkEntityType {
  MATCH = 'MATCH',
  TOUR = 'TOUR',
  TEAM = 'TEAM',
  EVENT = 'EVENT',
  PLAYER = 'PLAYER',
}

export type ArticleLinkEntityTypeConnection = DefaultConnection & {
  __typename?: 'ArticleLinkEntityTypeConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ArticleLinkEntityRecord>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type ArticleLinkInput = {
  entityId: Scalars['Int'];
  entityType: EntityType;
};

export type ArticleLinkUpdateInput = {
  articleId?: Maybe<Scalars['Int']>;
  entityId?: Maybe<Scalars['Int']>;
  entityType?: Maybe<ArticleLinkEntityType>;
};

export type ArticleLog = {
  __typename?: 'ArticleLog';
  id: Scalars['Int'];
  articleId: Scalars['Int'];
  authorId: Scalars['Int'];
  state: ArticleLogState;
  data?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum ArticleLogState {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  DELETED = 'DELETED',
  CHECKING = 'CHECKING',
  FACT_CHECKING = 'FACT_CHECKING',
}

export type ArticlesConnection = DefaultConnection & {
  __typename?: 'ArticlesConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<Article>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum ArticleState {
  DRAFT = 'DRAFT',
  CHECKING = 'CHECKING',
  DELETED = 'DELETED',
  FACT_CHECKING = 'FACT_CHECKING',
  PUBLISHED = 'PUBLISHED',
}

export type ArticleTag = {
  __typename?: 'ArticleTag';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ArticleTagConnection = DefaultConnection & {
  __typename?: 'ArticleTagConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ArticleTag>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type ArticleTagCreateInput = {
  name: Scalars['String'];
  source?: Maybe<Scalars['String']>;
};

export type ArticleTagUpdateInput = {
  name?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type ArticleUpdateInput = {
  categoryId?: Maybe<Scalars['Int']>;
  state?: Maybe<ArticleState>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  localeId?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type ArticleWhereInput = {
  authorId?: Maybe<Array<Scalars['Int']>>;
  categoryId?: Maybe<Array<Scalars['Int']>>;
  state?: Maybe<Array<ArticleState>>;
  tags?: Maybe<Array<Scalars['String']>>;
  links?: Maybe<Array<ArticleLinkInput>>;
  locale?: Maybe<Array<Scalars['String']>>;
  publishedAt?: Maybe<Array<Scalars['DateTime']>>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  currentUser: User;
};

export type CashierCheckReceiptInput = {
  orderId?: Maybe<Scalars['Int']>;
  orderType?: Maybe<PaymentTransactionOrderType>;
  userId?: Maybe<Scalars['Int']>;
  providerId: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  receipt?: Maybe<PaymentReceiptInput>;
};

export type Catalogue = {
  __typename?: 'Catalogue';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: CatalogueState;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  localeIdx?: Maybe<Array<Scalars['Int']>>;
  currencyIdx?: Maybe<Array<Scalars['Int']>>;
  locales?: Maybe<Array<Locale>>;
  currencies?: Maybe<Array<Currency>>;
  items: Scalars['Int'];
  data: Array<ExtensionData>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CatalogueCategory = {
  __typename?: 'CatalogueCategory';
  id: Scalars['Int'];
  name: Scalars['String'];
  preview?: Maybe<Scalars['String']>;
  catalogueId: Scalars['Int'];
  state: CatalogueCategoryState;
  data: Array<ExtensionData>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CatalogueCategoryBatchUpdateInput = {
  id: Scalars['Int'];
  state?: Maybe<CatalogueItemState>;
  sort?: Maybe<Scalars['Int']>;
};

export type CatalogueCategoryConnection = DefaultConnection & {
  __typename?: 'CatalogueCategoryConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CatalogueCategory>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CatalogueCategoryCreateInput = {
  name: Scalars['String'];
  preview?: Maybe<Scalars['String']>;
  catalogueId: Scalars['Int'];
  state?: Maybe<CatalogueCategoryState>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type CatalogueCategoryFieldsConnection = DefaultConnection & {
  __typename?: 'CatalogueCategoryFieldsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ExtensionField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum CatalogueCategoryState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type CatalogueCategoryUpdateInput = {
  name?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  catalogueId?: Maybe<Scalars['Int']>;
  state?: Maybe<CatalogueCategoryState>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type CatalogueConnection = DefaultConnection & {
  __typename?: 'CatalogueConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<Catalogue>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CatalogueCreateInput = {
  state?: Maybe<CatalogueState>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  localeIdx?: Maybe<Array<Scalars['Int']>>;
  currencyIdx?: Maybe<Array<Scalars['Int']>>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type CatalogueFieldsConnection = DefaultConnection & {
  __typename?: 'CatalogueFieldsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ExtensionField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CatalogueItem = {
  __typename?: 'CatalogueItem';
  id: Scalars['Int'];
  catalogueId: Scalars['Int'];
  state: CatalogueItemState;
  name: Scalars['String'];
  preview?: Maybe<Scalars['String']>;
  prices?: Maybe<Array<CatalogueItemPrice>>;
  data: Array<ExtensionData>;
  parentId: Array<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CatalogueItemBatchUpdateInput = {
  id: Scalars['Int'];
  state?: Maybe<CatalogueItemState>;
  sort?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type CatalogueItemConnection = DefaultConnection & {
  __typename?: 'CatalogueItemConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CatalogueItem>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CatalogueItemCreateInput = {
  catalogueId: Scalars['Int'];
  state?: Maybe<CatalogueItemState>;
  name: Scalars['String'];
  preview?: Maybe<Scalars['String']>;
  parentId?: Maybe<Array<Scalars['Int']>>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type CatalogueItemFieldsConnection = DefaultConnection & {
  __typename?: 'CatalogueItemFieldsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ExtensionField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CatalogueItemPrice = {
  __typename?: 'CatalogueItemPrice';
  id: Scalars['Int'];
  currencyId?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
  catalogueItemId: Scalars['Int'];
  currency: Currency;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CatalogueItemPriceConnection = DefaultConnection & {
  __typename?: 'CatalogueItemPriceConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CatalogueItemPrice>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CatalogueItemPriceCreateInput = {
  currencyId?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
  catalogueItemId: Scalars['Int'];
};

export type CatalogueItemPriceUpdateInput = {
  currencyId?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
  catalogueItemId?: Maybe<Scalars['Int']>;
};

export enum CatalogueItemState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type CatalogueItemUpdateInput = {
  catalogueId?: Maybe<Scalars['Int']>;
  state?: Maybe<CatalogueItemState>;
  name?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  parentId?: Maybe<Array<Scalars['Int']>>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type CatalogueRelation = {
  __typename?: 'CatalogueRelation';
  id: Scalars['Int'];
  sort: Scalars['Int'];
  itemId: Scalars['Int'];
  type: CatalogueRelationType;
  parentId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CatalogueRelationConnection = DefaultConnection & {
  __typename?: 'CatalogueRelationConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CatalogueRelation>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CatalogueRelationCreateInput = {
  sort?: Maybe<Scalars['Int']>;
  itemId: Scalars['Int'];
  type?: Maybe<CatalogueRelationType>;
  parentId?: Maybe<Scalars['Int']>;
};

export enum CatalogueRelationType {
  CATEGORY = 'CATEGORY',
  ITEM = 'ITEM',
}

export type CatalogueRelationUpdateInput = {
  sort?: Maybe<Scalars['Int']>;
  itemId?: Maybe<Scalars['Int']>;
  type?: Maybe<CatalogueRelationType>;
  parentId?: Maybe<Scalars['Int']>;
};

export enum CatalogueState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type CatalogueSummary = {
  __typename?: 'CatalogueSummary';
  itemsCount: Scalars['Int'];
  topItems?: Maybe<Array<CatalogueItem>>;
};

export type CatalogueSummaryWidget = {
  __typename?: 'CatalogueSummaryWidget';
  catalogue: Catalogue;
  summary: CatalogueSummary;
};

export type CatalogueUpdateInput = {
  state?: Maybe<CatalogueState>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  localeIdx?: Maybe<Array<Scalars['Int']>>;
  currencyIdx?: Maybe<Array<Scalars['Int']>>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type Catering = {
  __typename?: 'Catering';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  eventPlaceId?: Maybe<Scalars['Int']>;
  commerceShopId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  state: CateringState;
  sectors: Array<Scalars['Int']>;
  printReceiptTemplateId?: Maybe<Scalars['Int']>;
  startSale?: Maybe<Scalars['DateTime']>;
  endSale?: Maybe<Scalars['DateTime']>;
  eventPlace?: Maybe<EventPlace>;
  commerceShop?: Maybe<CommerceShop>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CateringConnection = DefaultConnection & {
  __typename?: 'CateringConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<Catering>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CateringCreateInput = {
  eventPlaceId?: Maybe<Scalars['Int']>;
  commerceShopId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  state?: Maybe<CateringState>;
  sectors?: Maybe<Array<Scalars['Int']>>;
  printReceiptTemplateId?: Maybe<Scalars['Int']>;
  startSale?: Maybe<Scalars['DateTime']>;
  endSale?: Maybe<Scalars['DateTime']>;
};

export enum CateringState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type CateringUpdateInput = {
  eventPlaceId?: Maybe<Scalars['Int']>;
  commerceShopId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<CateringState>;
  sectors?: Maybe<Array<Scalars['Int']>>;
  printReceiptTemplateId?: Maybe<Scalars['Int']>;
  startSale?: Maybe<Scalars['DateTime']>;
  endSale?: Maybe<Scalars['DateTime']>;
};

export type CheckUserInput = {
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type Client = {
  __typename?: 'Client';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  type?: Maybe<ClientType>;
  logo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  colors?: Maybe<Array<Maybe<Scalars['String']>>>;
  domain?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ClientPublicProfile = {
  __typename?: 'ClientPublicProfile';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  settings?: Maybe<ClientPublicSettings>;
};

export type ClientPublicSettings = {
  __typename?: 'ClientPublicSettings';
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  colors?: Maybe<Array<Scalars['String']>>;
  locales?: Maybe<Array<Scalars['Int']>>;
  defaultLocale?: Maybe<LocaleEnum>;
  domain?: Maybe<Scalars['String']>;
};

export type ClientSettings = {
  __typename?: 'ClientSettings';
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  defaultLocale: LocaleEnum;
  paymentGatewayId: Scalars['Int'];
  clientId: Scalars['ID'];
  colors?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ClientSettingsRental = {
  __typename?: 'ClientSettingsRental';
  initialPrepayment: Scalars['Int'];
};

export enum ClientType {
  INDIVIDUAL = 'INDIVIDUAL',
  LEGAL_ENTITY = 'LEGAL_ENTITY',
}

export type CommerceOrder = {
  __typename?: 'CommerceOrder';
  id: Scalars['Int'];
  shopId: Scalars['Int'];
  clientId: Scalars['Int'];
  state: CommerceOrderState;
  paymentState: CommerceOrderPaymentState;
  paymentStartedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['Int'];
  user: User;
  externalEntityId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  paidAmount: Scalars['Float'];
  items?: Maybe<Array<CommerceOrderItem>>;
  data?: Maybe<Array<CommerceOrderData>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CommerceOrderConnection = DefaultConnection & {
  __typename?: 'CommerceOrderConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CommerceOrder>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CommerceOrderCreateInput = {
  shopId: Scalars['Int'];
  state?: Maybe<CommerceOrderState>;
  paymentState?: Maybe<CommerceOrderPaymentState>;
  userId: Scalars['Int'];
  externalEntityId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
  items?: Maybe<Array<Maybe<OrderItemInput>>>;
  data?: Maybe<Array<Maybe<OrderDataInput>>>;
};

export type CommerceOrderData = {
  __typename?: 'CommerceOrderData';
  id: Scalars['Int'];
  value?: Maybe<Scalars['String']>;
  orderFieldId: Scalars['Int'];
  orderItemId?: Maybe<Scalars['Int']>;
  orderId: Scalars['Int'];
  key?: Maybe<Scalars['String']>;
  field: CommerceOrderField;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CommerceOrderDataConnection = DefaultConnection & {
  __typename?: 'CommerceOrderDataConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CommerceOrderData>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CommerceOrderDataCreateInput = {
  value?: Maybe<Scalars['String']>;
  orderFieldId: Scalars['Int'];
  orderItemId?: Maybe<Scalars['Int']>;
  orderId: Scalars['Int'];
};

export type CommerceOrderDatasUpdateInput = {
  id: Scalars['Int'];
  value?: Maybe<Scalars['String']>;
  orderFieldId?: Maybe<Scalars['Int']>;
  orderItemId?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
};

export type CommerceOrderDataUpdateInput = {
  value?: Maybe<Scalars['String']>;
  orderFieldId?: Maybe<Scalars['Int']>;
  orderItemId?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
};

export type CommerceOrderField = {
  __typename?: 'CommerceOrderField';
  id: Scalars['Int'];
  shopId: Scalars['Int'];
  name: Scalars['String'];
  dataType: CommerceOrderFieldDataType;
  hasLocale: Scalars['Boolean'];
  sort: Scalars['Int'];
  translations?: Maybe<Array<CommerceOrderFieldTranslation>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CommerceOrderFieldConnection = DefaultConnection & {
  __typename?: 'CommerceOrderFieldConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CommerceOrderField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CommerceOrderFieldCreateInput = {
  shopId: Scalars['Int'];
  name: Scalars['String'];
  dataType: CommerceOrderFieldDataType;
  hasLocale?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['Int']>;
};

export enum CommerceOrderFieldDataType {
  BOOLEAN = 'BOOLEAN',
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  ENUM = 'ENUM',
  ARRAY = 'ARRAY',
  FILES = 'FILES',
}

export type CommerceOrderFieldTranslation = {
  __typename?: 'CommerceOrderFieldTranslation';
  id: Scalars['Int'];
  localeId?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CommerceOrderFieldTranslationConnection = DefaultConnection & {
  __typename?: 'CommerceOrderFieldTranslationConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CommerceOrderFieldTranslation>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CommerceOrderFieldUpdateInput = {
  shopId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  dataType?: Maybe<CommerceOrderFieldDataType>;
  hasLocale?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['Int']>;
};

export type CommerceOrderItem = {
  __typename?: 'CommerceOrderItem';
  id: Scalars['Int'];
  orderId: Scalars['Int'];
  state: CommerceOrderItemState;
  catalogueItemId: Scalars['Int'];
  discountId?: Maybe<Scalars['Int']>;
  currencyId: Scalars['Int'];
  qty: Scalars['Int'];
  price: Scalars['Float'];
  discountedPrice?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  catalogueItem: CatalogueItem;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CommerceOrderItemConnection = DefaultConnection & {
  __typename?: 'CommerceOrderItemConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CommerceOrderItem>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CommerceOrderItemCreateInput = {
  orderId: Scalars['Int'];
  state?: Maybe<CommerceOrderItemState>;
  catalogueItemId: Scalars['Int'];
  qty: Scalars['Int'];
};

export enum CommerceOrderItemState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type CommerceOrderItemUpdateInput = {
  orderId?: Maybe<Scalars['Int']>;
  state?: Maybe<CommerceOrderItemState>;
  catalogueItemId?: Maybe<Scalars['Int']>;
  qty?: Maybe<Scalars['Int']>;
};

export type CommerceOrderLog = {
  __typename?: 'CommerceOrderLog';
  id: Scalars['Int'];
  orderId: Scalars['Int'];
  orderState: CommerceOrderLogOrderState;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CommerceOrderLogConnection = DefaultConnection & {
  __typename?: 'CommerceOrderLogConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CommerceOrderLog>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum CommerceOrderLogOrderState {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  ON_DELIVERY = 'ON_DELIVERY',
  DELIVERED = 'DELIVERED',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
}

export enum CommerceOrderPaymentState {
  UNPAID = 'UNPAID',
  PAID = 'PAID',
}

export enum CommerceOrderState {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  ON_DELIVERY = 'ON_DELIVERY',
  DELIVERED = 'DELIVERED',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
}

export type CommerceOrderStatesWidgetData = {
  __typename?: 'CommerceOrderStatesWidgetData';
  state: CommerceOrderState;
  count: Scalars['Int'];
  total: Scalars['Float'];
};

export type CommerceOrderUpdateInput = {
  shopId?: Maybe<Scalars['Int']>;
  state?: Maybe<CommerceOrderState>;
  paymentState?: Maybe<CommerceOrderPaymentState>;
  paymentStartedAt?: Maybe<Scalars['DateTime']>;
  paidAmount?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Int']>;
  externalEntityId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
};

export type CommerceSettings = {
  __typename?: 'CommerceSettings';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  access?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CommerceSettingsConnection = DefaultConnection & {
  __typename?: 'CommerceSettingsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CommerceSettings>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CommerceSettingsCreateInput = {
  access?: Maybe<Scalars['JSON']>;
};

export type CommerceSettingsUpdateInput = {
  access?: Maybe<Scalars['JSON']>;
};

export type CommerceShop = {
  __typename?: 'CommerceShop';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: CommerceShopState;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  codeMask?: Maybe<Scalars['String']>;
  catalogueId: Scalars['Int'];
  currencyId?: Maybe<Scalars['Int']>;
  saleEnabled: Scalars['Boolean'];
  settings?: Maybe<Scalars['JSON']>;
  catalogue?: Maybe<Catalogue>;
  currency?: Maybe<Currency>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CommerceShopConnection = DefaultConnection & {
  __typename?: 'CommerceShopConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CommerceShop>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CommerceShopCreateInput = {
  state?: Maybe<CommerceShopState>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  codeMask?: Maybe<Scalars['String']>;
  catalogueId: Scalars['Int'];
  currencyId?: Maybe<Scalars['Int']>;
  saleEnabled?: Maybe<Scalars['Boolean']>;
  settings?: Maybe<Scalars['JSON']>;
};

export type CommerceShopGraph = {
  __typename?: 'CommerceShopGraph';
  name: Scalars['String'];
  data?: Maybe<Array<CommerceShopGraphData>>;
};

export type CommerceShopGraphData = {
  __typename?: 'CommerceShopGraphData';
  date: Scalars['DateTime'];
  total: Scalars['Float'];
  paidAmount: Scalars['Float'];
};

export type CommerceShopInfo = {
  __typename?: 'CommerceShopInfo';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: CommerceShopState;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  codeMask?: Maybe<Scalars['String']>;
  catalogueId: Scalars['Int'];
  currencyId?: Maybe<Scalars['Int']>;
  saleEnabled: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  items: Scalars['Int'];
  orders: Scalars['Int'];
};

export enum CommerceShopState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type CommerceShopSummary = {
  __typename?: 'CommerceShopSummary';
  ordersCount: Scalars['Int'];
  itemsCount: Scalars['Int'];
  topItems?: Maybe<Array<CatalogueItem>>;
};

export type CommerceShopUpdateInput = {
  state?: Maybe<CommerceShopState>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  codeMask?: Maybe<Scalars['String']>;
  catalogueId?: Maybe<Scalars['Int']>;
  currencyId?: Maybe<Scalars['Int']>;
  saleEnabled?: Maybe<Scalars['Boolean']>;
  settings?: Maybe<Scalars['JSON']>;
};

export type CommerceSummaryWidget = {
  __typename?: 'CommerceSummaryWidget';
  shop: CommerceShop;
  summary: CommerceShopSummary;
  graphs?: Maybe<Array<CommerceShopGraph>>;
};

export type CommerceTemplate = {
  __typename?: 'CommerceTemplate';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  preview?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CommerceTemplateConnection = DefaultConnection & {
  __typename?: 'CommerceTemplateConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CommerceTemplate>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CommerceTemplateCreateInput = {
  name: Scalars['String'];
  preview?: Maybe<Scalars['String']>;
  body: Scalars['String'];
};

export type CommerceTemplateUpdateInput = {
  name?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
};

export type CommerceWidget = {
  __typename?: 'CommerceWidget';
  shops: Array<CommerceShopInfo>;
};

export type CommonGraph = {
  __typename?: 'CommonGraph';
  name: Scalars['String'];
  dataset: Array<CommonGraphDataset>;
};

export type CommonGraphDataset = {
  __typename?: 'CommonGraphDataset';
  label: Scalars['String'];
  data: Array<CommonGraphDatasetData>;
};

export type CommonGraphDatasetData = {
  __typename?: 'CommonGraphDatasetData';
  x: Scalars['Date'];
  y: Scalars['Int'];
};

export type ConfirmOrderPaymentInput = {
  amount: Scalars['Float'];
  currencyId: Scalars['Int'];
  providerId: Scalars['Int'];
  orderId: Scalars['Int'];
  orderType: PaymentTransactionOrderType;
  isSuccess: Scalars['Boolean'];
  data?: Maybe<Scalars['JSON']>;
};

export type ConfirmOrderPaymentResponse = {
  __typename?: 'ConfirmOrderPaymentResponse';
  code: Scalars['String'];
  transaction: PaymentTransaction;
  user: User;
};

export type Constant = {
  __typename?: 'Constant';
  name: Scalars['String'];
  value: Scalars['Int'];
};

export type Constants = {
  __typename?: 'Constants';
  MAX_PARTIAL_MAILING_QUEUE_DATA: Scalars['Int'];
};

export type ContentTranslation = {
  __typename?: 'ContentTranslation';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  channel?: Maybe<Scalars['String']>;
  localeId: Scalars['Int'];
  key: Scalars['String'];
  value: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ContentTranslationConnection = DefaultConnection & {
  __typename?: 'ContentTranslationConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ContentTranslation>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type ContentTranslationCreateInput = {
  channel?: Maybe<Scalars['String']>;
  localeId: Scalars['Int'];
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ContentTranslationSaveInput = {
  channel?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  value: Scalars['String'];
  localeId: Scalars['Int'];
};

export type ContentTranslationUpdateInput = {
  channel?: Maybe<Scalars['String']>;
  localeId?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CoordsInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CreateExtensionFieldInput = {
  relationId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  dataType: EntityDataType;
  isReadOnly?: Maybe<Scalars['Boolean']>;
  i18n?: Maybe<Scalars['Int']>;
  sort: Scalars['Int'];
  translations?: Maybe<Array<ExtensionFieldTranslationInput>>;
};

export type CreateSystemUserInput = {
  username: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<SystemUserState>;
};

export type CreateUserDeviceInput = {
  userId?: Maybe<Scalars['Int']>;
  deviceId: Scalars['String'];
  installId: Scalars['String'];
  bundleId?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  deviceLocale?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  installReferrer?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  systemName?: Maybe<Scalars['String']>;
  systemVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  pushToken?: Maybe<Scalars['String']>;
  expoPushToken?: Maybe<Scalars['String']>;
  APNSPushToken?: Maybe<Scalars['String']>;
};

export type CreateUserLocationInput = {
  userId?: Maybe<Scalars['Int']>;
  deviceId?: Maybe<Scalars['String']>;
  coords?: Maybe<Scalars['JSON']>;
};

export type CryptogramPaymentInput = {
  amount: Scalars['Float'];
  orderId?: Maybe<Scalars['Int']>;
  orderCode?: Maybe<Scalars['String']>;
  orderType?: Maybe<PaymentTransactionOrderType>;
  userId?: Maybe<Scalars['Int']>;
  cryptogram: Scalars['String'];
  cardHolderName: Scalars['String'];
  expiresAt?: Maybe<Scalars['String']>;
  currencyId: Scalars['Int'];
  providerId: Scalars['Int'];
  IPAddress: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  saveBankCard?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  receipt?: Maybe<PaymentReceiptInput>;
};

export type Currency = {
  __typename?: 'Currency';
  id: Scalars['Int'];
  iso3: Scalars['String'];
  name: Scalars['String'];
  sign: Scalars['String'];
  isDefault: Scalars['Boolean'];
  isVirtual: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type DatePicker = {
  start: Scalars['String'];
  finish: Scalars['String'];
};

export type DefaultConnection = {
  total?: Maybe<Scalars['Int']>;
  cursor: Scalars['Int'];
  limit: Scalars['Int'];
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type DefaultQueryInput = {
  filters?: Maybe<Array<FilterInput>>;
  sort?: Maybe<Array<Maybe<DefaultSortInput>>>;
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  allFields?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  attributes?: Maybe<Array<Scalars['String']>>;
};

export type DefaultSortInput = {
  field: Scalars['String'];
  direction: SortingDirection;
};

export enum DeviceOsEnum {
  IOS = 'iOS',
  ANDROID = 'Android',
  OTHER = 'other',
}

export type Dispute = {
  __typename?: 'Dispute';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: DisputeState;
  result: DisputeResult;
  confirmation: DisputeConfirmation;
  userId: Scalars['Int'];
  matchId: Scalars['Int'];
  amount: Scalars['Int'];
  winAmount: Scalars['Int'];
  text: Scalars['String'];
  user: User;
  match: TournamentMatch;
  responses: Array<Maybe<DisputeDisputeResponse>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum DisputeConfirmation {
  WAITING = 'WAITING',
  WIN = 'WIN',
  LOSS = 'LOSS',
}

export type DisputeConnection = DefaultConnection & {
  __typename?: 'DisputeConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<Dispute>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type DisputeCreateInput = {
  matchId: Scalars['Int'];
  amount: Scalars['Int'];
  text: Scalars['String'];
  userId?: Maybe<Scalars['Int']>;
};

export type DisputeDisputeResponse = {
  __typename?: 'DisputeDisputeResponse';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  disputeId: Scalars['Int'];
  state: DisputeDisputeResponseState;
  userId: Scalars['Int'];
  amount: Scalars['Int'];
  comment: Scalars['String'];
  respondedAt: Scalars['DateTime'];
  user: User;
  dispute: Dispute;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type DisputeDisputeResponseConnection = DefaultConnection & {
  __typename?: 'DisputeDisputeResponseConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<DisputeDisputeResponse>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum DisputeDisputeResponseState {
  ACTIVE = 'ACTIVE',
  WIN = 'WIN',
  LOSS = 'LOSS',
  WAITING = 'WAITING',
  CANCELLED = 'CANCELLED',
}

export type DisputeRating = {
  __typename?: 'DisputeRating';
  clientId: Scalars['Int'];
  userId: Scalars['Int'];
  position: Scalars['Int'];
  sumPoints: Scalars['Int'];
  countPoints: Scalars['Int'];
  user: User;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type DisputeRatingBySeason = {
  __typename?: 'DisputeRatingBySeason';
  clientId: Scalars['Int'];
  userId: Scalars['Int'];
  seasonId: Scalars['Int'];
  position: Scalars['Int'];
  sumPoints: Scalars['Int'];
  countPoints: Scalars['Int'];
  user: User;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type DisputeRatingBySeasonConnection = DefaultConnection & {
  __typename?: 'DisputeRatingBySeasonConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<DisputeRatingBySeason>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type DisputeRatingByTour = {
  __typename?: 'DisputeRatingByTour';
  clientId: Scalars['Int'];
  userId: Scalars['Int'];
  position: Scalars['Int'];
  tourId: Scalars['Int'];
  sumPoints: Scalars['Int'];
  countPoints: Scalars['Int'];
  user: User;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type DisputeRatingByTourConnection = DefaultConnection & {
  __typename?: 'DisputeRatingByTourConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<DisputeRatingByTour>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type DisputeRatingConnection = DefaultConnection & {
  __typename?: 'DisputeRatingConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<DisputeRating>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type DisputeRatingTotal = {
  __typename?: 'DisputeRatingTotal';
  clientId: Scalars['Int'];
  user: User;
  positionBySeason?: Maybe<Scalars['Int']>;
  positionByTour?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  sumPointsBySeason?: Maybe<Scalars['Int']>;
  sumPointsByTour?: Maybe<Scalars['Int']>;
  sumPoints?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
  countBySeason?: Maybe<Scalars['Int']>;
  countByTour?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export type DisputeResponseCreateInput = {
  disputeId: Scalars['Int'];
  amount: Scalars['Int'];
  comment: Scalars['String'];
  userId?: Maybe<Scalars['Int']>;
};

export type DisputeResponseUpdateInput = {
  disputeId?: Maybe<Scalars['Int']>;
  state?: Maybe<DisputeDisputeResponseState>;
  userId?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  respondedAt?: Maybe<Scalars['DateTime']>;
};

export enum DisputeResult {
  WIN = 'WIN',
  LOSS = 'LOSS',
  CANCELLED = 'CANCELLED',
  MODERATION = 'MODERATION',
  WAITING = 'WAITING',
}

export type DisputeSettings = {
  __typename?: 'DisputeSettings';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  pushTemplates?: Maybe<Scalars['JSON']>;
  simulators?: Maybe<Scalars['JSON']>;
  transportId?: Maybe<Scalars['Int']>;
  paymentCurrencyId?: Maybe<Scalars['Int']>;
  registrationRewardAmount?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type DisputeSettingsUpdateInput = {
  pushTemplates?: Maybe<Scalars['JSON']>;
  simulators?: Maybe<Scalars['JSON']>;
  transportId?: Maybe<Scalars['Int']>;
  paymentCurrencyId?: Maybe<Scalars['Int']>;
  registrationRewardAmount?: Maybe<Scalars['Float']>;
};

export type DisputeStat = {
  __typename?: 'DisputeStat';
  seasonId: Scalars['Int'];
  count: Scalars['Int'];
};

export enum DisputeState {
  ACTIVE = 'ACTIVE',
  LOCKED = 'LOCKED',
  FINISHED = 'FINISHED',
  DELETED = 'DELETED',
  ARCHIVED = 'ARCHIVED',
}

export type DisputeUpdateInput = {
  state?: Maybe<DisputeState>;
  result?: Maybe<DisputeResult>;
  confirmation?: Maybe<DisputeConfirmation>;
  userId?: Maybe<Scalars['Int']>;
  matchId?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  winAmount?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
};

export type EntityDataInput = {
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  type?: Maybe<EntityDataType>;
};

export enum EntityDataType {
  BOOLEAN = 'BOOLEAN',
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  ENUM = 'ENUM',
  ARRAY = 'ARRAY',
  FILES = 'FILES',
  JSON = 'JSON',
}

export enum EntityType {
  MATCH = 'MATCH',
  TOUR = 'TOUR',
  TEAM = 'TEAM',
  EVENT = 'EVENT',
  PLAYER = 'PLAYER',
}

export type Event = {
  __typename?: 'Event';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: EventState;
  title: Scalars['String'];
  eventTypeId: Scalars['Int'];
  eventPlaceId: Scalars['Int'];
  tournamentMatchId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  saleStartDate?: Maybe<Scalars['DateTime']>;
  saleFinishDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  finishDate?: Maybe<Scalars['DateTime']>;
  availableSlots?: Maybe<Scalars['Int']>;
  saleLimit?: Maybe<Scalars['Int']>;
  templates?: Maybe<Scalars['JSON']>;
  agreement?: Maybe<Scalars['String']>;
  whitelist: Scalars['Boolean'];
  isShowPublic: Scalars['Boolean'];
  isFanIdRequired: Scalars['Boolean'];
  linkedEventId?: Maybe<Scalars['Int']>;
  isViewOnly?: Maybe<Scalars['Boolean']>;
  data: Array<ExtensionData>;
  eventType: EventType;
  eventPlace: EventPlace;
  eventAccess?: Maybe<EventEventAccess>;
  tournamentMatch?: Maybe<TournamentMatch>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EventConnection = DefaultConnection & {
  __typename?: 'EventConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<Event>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type EventCreateInput = {
  state?: Maybe<EventState>;
  title: Scalars['String'];
  eventTypeId: Scalars['Int'];
  eventPlaceId: Scalars['Int'];
  tournamentMatchId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  saleStartDate?: Maybe<Scalars['DateTime']>;
  saleFinishDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  finishDate?: Maybe<Scalars['DateTime']>;
  availableSlots?: Maybe<Scalars['Int']>;
  saleLimit?: Maybe<Scalars['Int']>;
  templates?: Maybe<Scalars['JSON']>;
  agreement?: Maybe<Scalars['String']>;
  whitelist?: Maybe<Scalars['Boolean']>;
  isShowPublic?: Maybe<Scalars['Boolean']>;
  isFanIdRequired?: Maybe<Scalars['Boolean']>;
  linkedEventId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type EventEventAccess = {
  __typename?: 'EventEventAccess';
  id: Scalars['Int'];
  eventId: Scalars['Int'];
  isVisibleToPublic: Scalars['Boolean'];
  userGroupToSector?: Maybe<Scalars['JSON']>;
  sectorUserSeatLimit?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EventEventAccessConnection = DefaultConnection & {
  __typename?: 'EventEventAccessConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<EventEventAccess>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type EventEventAccessCreateInput = {
  eventId: Scalars['Int'];
  isVisibleToPublic: Scalars['Boolean'];
  userGroupToSector?: Maybe<Scalars['JSON']>;
  sectorUserSeatLimit?: Maybe<Scalars['JSON']>;
};

export type EventEventAccessUpdateInput = {
  eventId?: Maybe<Scalars['Int']>;
  isVisibleToPublic?: Maybe<Scalars['Boolean']>;
  userGroupToSector?: Maybe<Scalars['JSON']>;
  sectorUserSeatLimit?: Maybe<Scalars['JSON']>;
};

export type EventEventWhitelist = {
  __typename?: 'EventEventWhitelist';
  id: Scalars['Int'];
  eventId: Scalars['Int'];
  userId: Scalars['Int'];
  sector?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['String']>;
  seat?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EventEventWhitelistConnection = DefaultConnection & {
  __typename?: 'EventEventWhitelistConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<EventEventWhitelist>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type EventEventWhitelistCreateInput = {
  eventId: Scalars['Int'];
  userId: Scalars['Int'];
  sector?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['String']>;
  seat?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type EventEventWhitelistUpdateInput = {
  eventId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  sector?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['String']>;
  seat?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type EventFieldsConnection = DefaultConnection & {
  __typename?: 'EventFieldsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ExtensionField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type EventOrder = {
  __typename?: 'EventOrder';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: EventOrderState;
  paymentState: EventOrderPaymentState;
  discountId?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
  eventId: Scalars['Int'];
  code: Scalars['String'];
  total: Scalars['Float'];
  paymentStartedAt?: Maybe<Scalars['DateTime']>;
  paidAmount: Scalars['Float'];
  currencyId: Scalars['Int'];
  reservedTill: Scalars['DateTime'];
  isFiscal?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  tickets: Array<EventOrderTicket>;
  data: Array<ExtensionData>;
  user: User;
  event: Event;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EventOrderConnection = DefaultConnection & {
  __typename?: 'EventOrderConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<EventOrder>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type EventOrderCreateInput = {
  state?: Maybe<EventOrderState>;
  paymentState?: Maybe<EventOrderPaymentState>;
  discountId?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
  eventId: Scalars['Int'];
  code: Scalars['String'];
  total: Scalars['Float'];
  paymentStartedAt?: Maybe<Scalars['DateTime']>;
  paidAmount: Scalars['Float'];
  currencyId: Scalars['Int'];
  reservedTill: Scalars['DateTime'];
  isFiscal?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type EventOrderFieldsConnection = DefaultConnection & {
  __typename?: 'EventOrderFieldsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ExtensionField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum EventOrderPaymentState {
  UNPAID = 'UNPAID',
  PAID = 'PAID',
}

export enum EventOrderState {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  ON_DELIVERY = 'ON_DELIVERY',
  DELIVERED = 'DELIVERED',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
}

export type EventOrderTicket = {
  __typename?: 'EventOrderTicket';
  id: Scalars['Int'];
  eventOrderId: Scalars['Int'];
  eventId: Scalars['Int'];
  barcode?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  discountId?: Maybe<Scalars['Int']>;
  data: Array<ExtensionData>;
  eventOrder: EventOrder;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EventOrderTicketConnection = DefaultConnection & {
  __typename?: 'EventOrderTicketConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<EventOrderTicket>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type EventOrderTicketCreateInput = {
  eventOrderId: Scalars['Int'];
  eventId: Scalars['Int'];
  barcode?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  discountId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type EventOrderTicketFieldsConnection = DefaultConnection & {
  __typename?: 'EventOrderTicketFieldsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ExtensionField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type EventOrderTicketUpdateInput = {
  eventOrderId?: Maybe<Scalars['Int']>;
  eventId?: Maybe<Scalars['Int']>;
  barcode?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  discountId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type EventOrderUpdateInput = {
  state?: Maybe<EventOrderState>;
  paymentState?: Maybe<EventOrderPaymentState>;
  discountId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  eventId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
  paymentStartedAt?: Maybe<Scalars['DateTime']>;
  paidAmount?: Maybe<Scalars['Float']>;
  currencyId?: Maybe<Scalars['Int']>;
  reservedTill?: Maybe<Scalars['DateTime']>;
  isFiscal?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type EventPlace = {
  __typename?: 'EventPlace';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  preview?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  localeIdx?: Maybe<Array<Scalars['Int']>>;
  locales?: Maybe<Array<SystemLocale>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EventPlaceConnection = DefaultConnection & {
  __typename?: 'EventPlaceConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<EventPlace>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type EventPlaceCreateInput = {
  preview?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  localeIdx?: Maybe<Array<Scalars['Int']>>;
};

export type EventPlaceSector = {
  __typename?: 'EventPlaceSector';
  id: Scalars['Int'];
  eventPlaceId: Scalars['Int'];
  name: Scalars['String'];
  isSectorSaleEnabled: Scalars['Boolean'];
  userSeatLimit?: Maybe<Scalars['Int']>;
  seatsCount?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isAllowed?: Maybe<Scalars['Boolean']>;
};

export type EventPlaceSectorConnection = DefaultConnection & {
  __typename?: 'EventPlaceSectorConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<EventPlaceSector>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type EventPlaceSectorCreateInput = {
  eventPlaceId: Scalars['Int'];
  name: Scalars['String'];
  isSectorSaleEnabled: Scalars['Boolean'];
  userSeatLimit?: Maybe<Scalars['Int']>;
  seatsCount?: Maybe<Scalars['Int']>;
};

export type EventPlaceSectorUpdateInput = {
  eventPlaceId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  isSectorSaleEnabled?: Maybe<Scalars['Boolean']>;
  userSeatLimit?: Maybe<Scalars['Int']>;
  seatsCount?: Maybe<Scalars['Int']>;
};

export type EventPlaceUpdateInput = {
  preview?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  localeIdx?: Maybe<Array<Scalars['Int']>>;
};

export type EventReserve = {
  __typename?: 'EventReserve';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  uuid: Scalars['UUID'];
  eventId: Scalars['Int'];
  userId: Scalars['Int'];
  expiryDate: Scalars['DateTime'];
  meta?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EventReserveConnection = DefaultConnection & {
  __typename?: 'EventReserveConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<EventReserve>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type EventReserveCreateInput = {
  uuid?: Maybe<Scalars['UUID']>;
  eventId: Scalars['Int'];
  userId: Scalars['Int'];
  expiryDate: Scalars['DateTime'];
  meta?: Maybe<Scalars['JSON']>;
};

export type EventReserveUpdateInput = {
  uuid?: Maybe<Scalars['UUID']>;
  eventId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type EventSettings = {
  __typename?: 'EventSettings';
  id: Scalars['Int'];
  clientId?: Maybe<Scalars['Int']>;
  pdf?: Maybe<Scalars['JSON']>;
  applePass?: Maybe<Scalars['JSON']>;
  email?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EventSettingsUpdateInput = {
  pdf?: Maybe<Scalars['JSON']>;
  applePass?: Maybe<Scalars['JSON']>;
  email?: Maybe<Scalars['JSON']>;
};

export enum EventState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type EventType = {
  __typename?: 'EventType';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  templates?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EventTypeConnection = DefaultConnection & {
  __typename?: 'EventTypeConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<EventType>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type EventTypeCreateInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  templates?: Maybe<Scalars['JSON']>;
};

export type EventTypeUpdateInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  templates?: Maybe<Scalars['JSON']>;
};

export type EventUpdateInput = {
  state?: Maybe<EventState>;
  title?: Maybe<Scalars['String']>;
  eventTypeId?: Maybe<Scalars['Int']>;
  eventPlaceId?: Maybe<Scalars['Int']>;
  tournamentMatchId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  saleStartDate?: Maybe<Scalars['DateTime']>;
  saleFinishDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  finishDate?: Maybe<Scalars['DateTime']>;
  availableSlots?: Maybe<Scalars['Int']>;
  saleLimit?: Maybe<Scalars['Int']>;
  templates?: Maybe<Scalars['JSON']>;
  agreement?: Maybe<Scalars['String']>;
  whitelist?: Maybe<Scalars['Boolean']>;
  isShowPublic?: Maybe<Scalars['Boolean']>;
  isFanIdRequired?: Maybe<Scalars['Boolean']>;
  linkedEventId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type EventWidgetActiveEvents = {
  __typename?: 'EventWidgetActiveEvents';
  activeCount: Scalars['Int'];
  todayCount: Scalars['Int'];
};

export type EventWidgetEventTypesSales = {
  __typename?: 'EventWidgetEventTypesSales';
  ticketTotalSale: Scalars['Int'];
  graphs: Array<WidgetSummaryGraph>;
};

export type EventWidgetNearestMatch = {
  __typename?: 'EventWidgetNearestMatch';
  ticketTotalSale: Scalars['Int'];
  event: Event;
};

export type EventWidgetSales = {
  __typename?: 'EventWidgetSales';
  eventSaleCount: Scalars['Int'];
  ticketTotalSale: Scalars['Int'];
};

export type EventWidgetTicketSales = {
  __typename?: 'EventWidgetTicketSales';
  summary: EventWidgetTicketSalesTotals;
  graphs: Array<CommonGraph>;
};

export type EventWidgetTicketSalesInput = {
  eventId?: Maybe<Scalars['Int']>;
  dates?: Maybe<Array<Scalars['DateTime']>>;
};

export type EventWidgetTicketSalesTotals = {
  __typename?: 'EventWidgetTicketSalesTotals';
  totalCount: Scalars['Int'];
  soldCount: Scalars['Int'];
  availableCount: Scalars['Int'];
  soldAmount: Scalars['Float'];
};

export type ExportFields = {
  __typename?: 'ExportFields';
  name: Scalars['String'];
  title: Scalars['String'];
  isExtension: Scalars['Boolean'];
};

export type ExportFieldsInput = {
  name: Scalars['String'];
  title: Scalars['String'];
  isExtension: Scalars['Boolean'];
};

export enum ExportFormat {
  CSV = 'CSV',
  XLS = 'XLS',
  XLSX = 'XLSX',
  JSON = 'JSON',
}

export type ExportResponse = {
  __typename?: 'ExportResponse';
  model: Scalars['String'];
  fields: Array<ExportFields>;
  fileUrl?: Maybe<Scalars['String']>;
  format?: Maybe<ExportFormat>;
};

export type ExportSettingsInput = {
  format: ExportFormat;
  fields: Array<ExportFieldsInput>;
};

export type ExportUnisenderSettingsInput = {
  listId?: Maybe<Scalars['String']>;
  listTitle?: Maybe<Scalars['String']>;
  fields: Array<ExportFieldsInput>;
};

export type ExtendedLoyaltyPromo = {
  __typename?: 'ExtendedLoyaltyPromo';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  eventId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  description: Scalars['String'];
  imageUri?: Maybe<Scalars['String']>;
  state: LoyaltyPromoState;
  amount?: Maybe<Scalars['Float']>;
  currencyId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  codeType: LoyaltyPromoCodeType;
  codesCount: Scalars['Int'];
  startDate: Scalars['DateTime'];
  finishDate: Scalars['DateTime'];
  promoCodeStartDate?: Maybe<Scalars['DateTime']>;
  promoCodeFinishDate?: Maybe<Scalars['DateTime']>;
  discountMaxAmount?: Maybe<Scalars['Float']>;
  discountPercent?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  usedCount: Scalars['Int'];
  total: Scalars['Int'];
};

export type ExtensionData = {
  __typename?: 'ExtensionData';
  id: Scalars['Int'];
  fieldId: Scalars['Int'];
  localeId?: Maybe<Scalars['Int']>;
  instanceId: Scalars['Int'];
  value?: Maybe<Scalars['JSON']>;
  key: Scalars['String'];
  field: ExtensionField;
  translations: Array<ExtensionDataTranslation>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ExtensionDataTranslation = {
  __typename?: 'ExtensionDataTranslation';
  id: Scalars['Int'];
  localeId: Scalars['Int'];
  dataId: Scalars['Int'];
  value: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ExtensionField = {
  __typename?: 'ExtensionField';
  id: Scalars['Int'];
  relationId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  dataType: EntityDataType;
  isReadOnly: Scalars['Boolean'];
  i18n?: Maybe<Scalars['Int']>;
  sort: Scalars['Int'];
  translations: Array<ExtensionFieldTranslation>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ExtensionFieldTranslation = {
  __typename?: 'ExtensionFieldTranslation';
  id: Scalars['Int'];
  localeId: Scalars['Int'];
  fieldId: Scalars['Int'];
  value: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ExtensionFieldTranslationInput = {
  localeId: Scalars['Int'];
  value: Scalars['String'];
};

export type ExtensionUpdateDataInput = {
  key?: Maybe<Scalars['String']>;
  fieldId?: Maybe<Scalars['Int']>;
  localeId?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export enum FilterDataType {
  ARRAY = 'ARRAY',
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  TIME = 'TIME',
  ENUM = 'ENUM',
}

export type FilterInput = {
  field: Scalars['String'];
  dataType?: Maybe<FilterDataType>;
  isRange?: Maybe<Scalars['Boolean']>;
  isExclude?: Maybe<Scalars['Boolean']>;
  isSearch?: Maybe<Scalars['Boolean']>;
  value: Scalars['String'];
};

export type FilterItem = {
  __typename?: 'FilterItem';
  field: Scalars['String'];
  type: FilterType;
  dataType: FilterDataType;
  options?: Maybe<Array<Maybe<FilterItemOption>>>;
  isRangeAvailable: Scalars['Boolean'];
  isExcludAvailable: Scalars['Boolean'];
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
  step?: Maybe<Scalars['Int']>;
};

export type FilterItemOption = {
  __typename?: 'FilterItemOption';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum FilterType {
  INPUT = 'INPUT',
  SELECT = 'SELECT',
  SELECT_MULTIPLE = 'SELECT_MULTIPLE',
  CHECKBOX = 'CHECKBOX',
  RANGE = 'RANGE',
}

export type GenericObject = {
  __typename?: 'GenericObject';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type GeoPoint = {
  __typename?: 'GeoPoint';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type ImportColumns = {
  __typename?: 'ImportColumns';
  sort: Scalars['Int'];
  name: Scalars['String'];
  field: Scalars['String'];
};

export type ImportFields = {
  __typename?: 'ImportFields';
  name: Scalars['String'];
  title: Scalars['String'];
  isExtension: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
};

export type ImportFieldsInput = {
  name: Scalars['String'];
  title: Scalars['String'];
  isExtension: Scalars['Boolean'];
};

export type ImportFile = {
  __typename?: 'ImportFile';
  name: Scalars['String'];
  size: Scalars['Int'];
  rowsCount: Scalars['Int'];
  mimeType: Scalars['String'];
  colSeparator?: Maybe<Scalars['String']>;
  rowSeparator?: Maybe<Scalars['String']>;
};

export type ImportFileInput = {
  name: Scalars['String'];
  size: Scalars['Int'];
  rowsCount: Scalars['Int'];
  mimeType: Scalars['String'];
  colSeparator?: Maybe<Scalars['String']>;
  rowSeparator?: Maybe<Scalars['String']>;
};

export type ImportInput = {
  file: ImportFileInput;
  columns: Array<ImportFieldsInput>;
  model: Scalars['String'];
  additional?: Maybe<Scalars['JSON']>;
};

export type ImportUploadResponse = {
  __typename?: 'ImportUploadResponse';
  file: ImportFile;
  selection: Array<Scalars['JSON']>;
  columns: Array<ImportColumns>;
  model: Scalars['String'];
  fields: Array<ImportFields>;
};

export type InitPaymentInput = {
  providerId: Scalars['Int'];
  backref: Scalars['String'];
  orderId: Scalars['Int'];
  orderType: PaymentTransactionOrderType;
  comment?: Maybe<Scalars['String']>;
};

export type InitPaymentResponse = {
  __typename?: 'InitPaymentResponse';
  providerId: Scalars['Int'];
  amount: Scalars['Float'];
  orderId: Scalars['Int'];
  orderType: PaymentTransactionOrderType;
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
};

export enum LastPurchaseEnum {
  LESS_THAN_MONTH = 'less_than_month',
  MORE_THAN_MONTH = 'more_than_month',
  LESS_THAN_HALF_YEAR = 'less_than_half_year',
  MORE_THAN_HALF_YEAR = 'more_than_half_year',
}

export type Locale = {
  __typename?: 'Locale';
  id: Scalars['Int'];
  iso: Scalars['String'];
  title: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum LocaleEnum {
  RU = 'RU',
  EN = 'EN',
}

export type LoyaltyAccountSummary = {
  __typename?: 'LoyaltyAccountSummary';
  applicationsCount: Scalars['Int'];
  accountsCount: Scalars['Int'];
  processedCount: Scalars['Int'];
  unprocessedCount: Scalars['Int'];
};

export type LoyaltyApplication = {
  __typename?: 'LoyaltyApplication';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  partnerId: Scalars['Int'];
  userId: Scalars['Int'];
  state: LoyaltyApplicationState;
  meta?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type LoyaltyApplicationConnection = DefaultConnection & {
  __typename?: 'LoyaltyApplicationConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<LoyaltyApplication>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type LoyaltyApplicationCreateInput = {
  partnerId: Scalars['Int'];
  userId: Scalars['Int'];
  state?: Maybe<LoyaltyApplicationState>;
  meta?: Maybe<Scalars['JSON']>;
};

export enum LoyaltyApplicationState {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

export type LoyaltyApplicationUpdateInput = {
  partnerId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  state?: Maybe<LoyaltyApplicationState>;
  meta?: Maybe<Scalars['JSON']>;
};

export type LoyaltyPartner = {
  __typename?: 'LoyaltyPartner';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: LoyaltyPartnerState;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type LoyaltyPartnerConnection = DefaultConnection & {
  __typename?: 'LoyaltyPartnerConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<LoyaltyPartner>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type LoyaltyPartnerCreateInput = {
  state?: Maybe<LoyaltyPartnerState>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['Int']>;
};

export type LoyaltyPartnerEntityConnection = DefaultConnection & {
  __typename?: 'LoyaltyPartnerEntityConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<Scalars['String']>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum LoyaltyPartnerState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type LoyaltyPartnerUpdateInput = {
  state?: Maybe<LoyaltyPartnerState>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['Int']>;
};

export type LoyaltyPromo = {
  __typename?: 'LoyaltyPromo';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  eventId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  description: Scalars['String'];
  imageUri?: Maybe<Scalars['String']>;
  state: LoyaltyPromoState;
  amount?: Maybe<Scalars['Float']>;
  currencyId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  codeType: LoyaltyPromoCodeType;
  codesCount: Scalars['Int'];
  startDate: Scalars['DateTime'];
  finishDate: Scalars['DateTime'];
  promoCodeStartDate?: Maybe<Scalars['DateTime']>;
  promoCodeFinishDate?: Maybe<Scalars['DateTime']>;
  discountMaxAmount?: Maybe<Scalars['Float']>;
  discountPercent?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type LoyaltyPromoCode = {
  __typename?: 'LoyaltyPromoCode';
  id: Scalars['Int'];
  promoId: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  state: LoyaltyPromoCodeState;
  code: Scalars['String'];
  amount?: Maybe<Scalars['Float']>;
  amountUsed?: Maybe<Scalars['Float']>;
  currencyId?: Maybe<Scalars['Int']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  appliedAt?: Maybe<Scalars['DateTime']>;
  promo: LoyaltyPromo;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type LoyaltyPromoCodeConnection = DefaultConnection & {
  __typename?: 'LoyaltyPromoCodeConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<LoyaltyPromoCode>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type LoyaltyPromoCodeCreateInput = {
  promoId: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  state?: Maybe<LoyaltyPromoCodeState>;
  code: Scalars['String'];
  amount?: Maybe<Scalars['Float']>;
  amountUsed?: Maybe<Scalars['Float']>;
  currencyId?: Maybe<Scalars['Int']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  appliedAt?: Maybe<Scalars['DateTime']>;
};

export enum LoyaltyPromoCodeState {
  NEW = 'NEW',
  EXPIRED = 'EXPIRED',
  PROCESSING = 'PROCESSING',
  APPLIED = 'APPLIED',
}

export enum LoyaltyPromoCodeType {
  UNIQUE = 'UNIQUE',
  SINGLE = 'SINGLE',
}

export type LoyaltyPromoCodeUpdateInput = {
  promoId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  state?: Maybe<LoyaltyPromoCodeState>;
  code?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  amountUsed?: Maybe<Scalars['Float']>;
  currencyId?: Maybe<Scalars['Int']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  appliedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPromoConnection = DefaultConnection & {
  __typename?: 'LoyaltyPromoConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ExtendedLoyaltyPromo>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type LoyaltyPromoCreateInput = {
  eventId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  imageUri?: Maybe<Scalars['String']>;
  state?: Maybe<LoyaltyPromoState>;
  amount?: Maybe<Scalars['Float']>;
  currencyId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  codeType: LoyaltyPromoCodeType;
  codesCount?: Maybe<Scalars['Int']>;
  startDate: Scalars['DateTime'];
  finishDate: Scalars['DateTime'];
  promoCodeStartDate?: Maybe<Scalars['DateTime']>;
  promoCodeFinishDate?: Maybe<Scalars['DateTime']>;
  discountMaxAmount?: Maybe<Scalars['Float']>;
  discountPercent?: Maybe<Scalars['Float']>;
};

export enum LoyaltyPromoState {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
  ARCHIVED = 'ARCHIVED',
}

export type LoyaltyPromoUpdateInput = {
  eventId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageUri?: Maybe<Scalars['String']>;
  state?: Maybe<LoyaltyPromoState>;
  amount?: Maybe<Scalars['Float']>;
  currencyId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  codeType?: Maybe<LoyaltyPromoCodeType>;
  codesCount?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  finishDate?: Maybe<Scalars['DateTime']>;
  promoCodeStartDate?: Maybe<Scalars['DateTime']>;
  promoCodeFinishDate?: Maybe<Scalars['DateTime']>;
  discountMaxAmount?: Maybe<Scalars['Float']>;
  discountPercent?: Maybe<Scalars['Float']>;
};

export type LoyaltySummaryGraph = {
  __typename?: 'LoyaltySummaryGraph';
  name: Scalars['String'];
  dataset: Array<LoyaltySummaryGraphDataset>;
};

export type LoyaltySummaryGraphDataset = {
  __typename?: 'LoyaltySummaryGraphDataset';
  label: Scalars['String'];
  data: Array<LoyaltySummaryGraphDatasetData>;
};

export type LoyaltySummaryGraphDatasetData = {
  __typename?: 'LoyaltySummaryGraphDatasetData';
  x: Scalars['Date'];
  y: Scalars['Int'];
};

export type LoyaltySummaryWidget = {
  __typename?: 'LoyaltySummaryWidget';
  partner: LoyaltyPartner;
  summary: LoyaltyAccountSummary;
  graphs?: Maybe<Array<LoyaltySummaryGraph>>;
};

export type LoyaltySummaryWidgetInput = {
  id?: Maybe<Array<Scalars['Int']>>;
  date?: Maybe<Array<Scalars['DateTime']>>;
};

export enum MarketPlaceEnum {
  TICKET = 'ticket',
  SHOP = 'shop',
}

export type MatchEventSubscription = {
  __typename?: 'MatchEventSubscription';
  deviceId: Scalars['Int'];
  subscription: Array<MatchEventSubscriptionSubscription>;
};

export type MatchEventSubscriptionSubscription = {
  __typename?: 'MatchEventSubscriptionSubscription';
  eventName: TournamentMatchEventEvent;
  teamIdx?: Maybe<Array<Scalars['Int']>>;
  isSubscribed?: Maybe<Scalars['Boolean']>;
};

export type MessengerMailing = {
  __typename?: 'MessengerMailing';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  sendAfter?: Maybe<Scalars['DateTime']>;
  sender?: Maybe<Scalars['String']>;
  channel: MessengerMailingChannel;
  state: MessengerMailingState;
  subject?: Maybe<Scalars['String']>;
  richText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['Int']>;
  transportId?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['String']>;
  metaDesign?: Maybe<Scalars['JSON']>;
  currentTask?: Maybe<MessengerQueueTask>;
  transport?: Maybe<MessengerTransport>;
  stats?: Maybe<Array<MessengerQueueNotificationStat>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type MessengerMailingActivity = {
  __typename?: 'MessengerMailingActivity';
  id: Scalars['Int'];
  mailingId: Scalars['Int'];
  userId: Scalars['Int'];
  action: Scalars['String'];
  meta?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type MessengerMailingActivityConnection = DefaultConnection & {
  __typename?: 'MessengerMailingActivityConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<MessengerMailingActivity>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type MessengerMailingActivityCreateInput = {
  mailingId: Scalars['Int'];
  userId: Scalars['Int'];
  action: Scalars['String'];
  meta?: Maybe<Scalars['JSON']>;
};

export type MessengerMailingActivityUpdateInput = {
  mailingId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  action?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
};

export enum MessengerMailingChannel {
  EMAIL = 'EMAIL',
  SERVICE_EMAIL = 'SERVICE_EMAIL',
  SMS = 'SMS',
  PUSH = 'PUSH',
  TELEGRAM = 'TELEGRAM',
  SLACK = 'SLACK',
}

export type MessengerMailingChannelStat = {
  __typename?: 'MessengerMailingChannelStat';
  channel: MessengerMailingChannel;
  value: Scalars['Int'];
};

export type MessengerMailingConnection = DefaultConnection & {
  __typename?: 'MessengerMailingConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<MessengerMailing>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type MessengerMailingCreateInput = {
  name: Scalars['String'];
  sendAfter?: Maybe<Scalars['DateTime']>;
  sender?: Maybe<Scalars['String']>;
  channel: MessengerMailingChannel;
  currentTask?: Maybe<Scalars['String']>;
  state?: Maybe<MessengerMailingState>;
  subject?: Maybe<Scalars['String']>;
  richText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['Int']>;
  transportId?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['String']>;
  metaDesign?: Maybe<Scalars['JSON']>;
};

export type MessengerMailingRedirect = {
  __typename?: 'MessengerMailingRedirect';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  mailingId: Scalars['Int'];
  userId: Scalars['Int'];
  utmCampaign?: Maybe<Scalars['String']>;
  utmId?: Maybe<Scalars['String']>;
  redirectAddress?: Maybe<Scalars['String']>;
  redirectsCount?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type MessengerMailingRedirectAnalytics = {
  __typename?: 'MessengerMailingRedirectAnalytics';
  utmCampaign: Scalars['String'];
  redirectsCount: Scalars['Int'];
  uniqueCount: Scalars['Int'];
};

export type MessengerMailingRedirectConnection = DefaultConnection & {
  __typename?: 'MessengerMailingRedirectConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<MessengerMailingRedirect>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type MessengerMailingRedirectCreateInput = {
  mailingId: Scalars['Int'];
  userId: Scalars['Int'];
  utmCampaign?: Maybe<Scalars['String']>;
  utmId?: Maybe<Scalars['String']>;
  redirectAddress?: Maybe<Scalars['String']>;
  redirectsCount?: Maybe<Scalars['Int']>;
};

export enum MessengerMailingState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  QUEUE_PROCESSING = 'QUEUE_PROCESSING',
  CANCELLED = 'CANCELLED',
  FINISHED = 'FINISHED',
  START = 'START',
}

export type MessengerMailingUpdateInput = {
  name?: Maybe<Scalars['String']>;
  sendAfter?: Maybe<Scalars['DateTime']>;
  sender?: Maybe<Scalars['String']>;
  channel?: Maybe<MessengerMailingChannel>;
  currentTask?: Maybe<Scalars['String']>;
  state?: Maybe<MessengerMailingState>;
  subject?: Maybe<Scalars['String']>;
  richText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['Int']>;
  transportId?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['String']>;
  metaDesign?: Maybe<Scalars['JSON']>;
};

export type MessengerProvider = {
  __typename?: 'MessengerProvider';
  id: Scalars['Int'];
  name: Scalars['String'];
  channel: MessengerProviderChannel;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum MessengerProviderChannel {
  EMAIL = 'EMAIL',
  SERVICE_EMAIL = 'SERVICE_EMAIL',
  SMS = 'SMS',
  PUSH = 'PUSH',
  TELEGRAM = 'TELEGRAM',
  SLACK = 'SLACK',
}

export type MessengerProviderConnection = DefaultConnection & {
  __typename?: 'MessengerProviderConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<MessengerProvider>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type MessengerProviderCreateInput = {
  name: Scalars['String'];
  channel: MessengerProviderChannel;
};

export type MessengerProviderUpdateInput = {
  name?: Maybe<Scalars['String']>;
  channel?: Maybe<MessengerProviderChannel>;
};

export type MessengerQueueNotification = {
  __typename?: 'MessengerQueueNotification';
  id: Scalars['String'];
  clientId: Scalars['Int'];
  mailingId?: Maybe<Scalars['Int']>;
  mailing?: Maybe<MessengerMailing>;
  userId?: Maybe<Scalars['Int']>;
  state: MessengerQueueNotificationState;
  channel: MessengerQueueNotificationChannel;
  willSendAt?: Maybe<Scalars['DateTime']>;
  sender?: Maybe<Scalars['String']>;
  recipient?: Maybe<Scalars['JSON']>;
  payload?: Maybe<Scalars['JSON']>;
  envelope?: Maybe<Scalars['JSON']>;
  transportId?: Maybe<Scalars['Int']>;
  transportMessageId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  richText?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum MessengerQueueNotificationChannel {
  EMAIL = 'EMAIL',
  SERVICE_EMAIL = 'SERVICE_EMAIL',
  SMS = 'SMS',
  PUSH = 'PUSH',
  TELEGRAM = 'TELEGRAM',
  SLACK = 'SLACK',
}

export type MessengerQueueNotificationConnection = DefaultConnection & {
  __typename?: 'MessengerQueueNotificationConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<MessengerQueueNotification>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type MessengerQueueNotificationCreateInput = {
  mailingId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  state?: Maybe<MessengerQueueNotificationState>;
  channel: MessengerQueueNotificationChannel;
  willSendAt?: Maybe<Scalars['DateTime']>;
  sender?: Maybe<Scalars['String']>;
  recipient?: Maybe<Scalars['JSON']>;
  payload?: Maybe<Scalars['JSON']>;
  envelope?: Maybe<Scalars['JSON']>;
  transportId?: Maybe<Scalars['Int']>;
  transportMessageId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  richText?: Maybe<Scalars['String']>;
};

export type MessengerQueueNotificationStat = {
  __typename?: 'MessengerQueueNotificationStat';
  state: MessengerQueueNotificationState;
  value: Scalars['Int'];
};

export enum MessengerQueueNotificationState {
  READY = 'READY',
  PROCESSING = 'PROCESSING',
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
  OPENED = 'OPENED',
  BOUNCED = 'BOUNCED',
  SPAM = 'SPAM',
  DELETED = 'DELETED',
}

export type MessengerQueueNotificationUpdateInput = {
  mailingId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  state?: Maybe<MessengerQueueNotificationState>;
  channel?: Maybe<MessengerQueueNotificationChannel>;
  willSendAt?: Maybe<Scalars['DateTime']>;
  sender?: Maybe<Scalars['String']>;
  recipient?: Maybe<Scalars['JSON']>;
  payload?: Maybe<Scalars['JSON']>;
  envelope?: Maybe<Scalars['JSON']>;
  transportId?: Maybe<Scalars['Int']>;
  transportMessageId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  richText?: Maybe<Scalars['String']>;
};

export type MessengerQueueTask = {
  __typename?: 'MessengerQueueTask';
  id: Scalars['Int'];
  mailingId?: Maybe<Scalars['Int']>;
  operation: MessengerQueueTaskOperation;
  state: MessengerQueueTaskState;
  itemsToProcess: Scalars['Int'];
  itemsProcessed: Scalars['Int'];
  itemsInvalid: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type MessengerQueueTaskConnection = DefaultConnection & {
  __typename?: 'MessengerQueueTaskConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<MessengerQueueTask>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type MessengerQueueTaskCreateInput = {
  mailingId?: Maybe<Scalars['Int']>;
  operation: MessengerQueueTaskOperation;
  state: MessengerQueueTaskState;
  itemsToProcess?: Maybe<Scalars['Int']>;
  itemsProcessed?: Maybe<Scalars['Int']>;
  itemsInvalid?: Maybe<Scalars['Int']>;
};

export enum MessengerQueueTaskOperation {
  ADD = 'ADD',
  DELETE = 'DELETE',
  START = 'START',
  STOP = 'STOP',
  PAUSE = 'PAUSE',
}

export enum MessengerQueueTaskState {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  FINISHED = 'FINISHED',
  CANCELLED = 'CANCELLED',
}

export type MessengerQueueTaskUpdateInput = {
  mailingId?: Maybe<Scalars['Int']>;
  operation?: Maybe<MessengerQueueTaskOperation>;
  state?: Maybe<MessengerQueueTaskState>;
  itemsToProcess?: Maybe<Scalars['Int']>;
  itemsProcessed?: Maybe<Scalars['Int']>;
  itemsInvalid?: Maybe<Scalars['Int']>;
};

export type MessengerSetting = {
  __typename?: 'MessengerSetting';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  testers: Scalars['JSON'];
  defaultSender: Scalars['JSON'];
  defaultTransport?: Maybe<Scalars['JSON']>;
  defaultSubscription?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  emailSenders?: Maybe<Array<Scalars['String']>>;
  smsSenders?: Maybe<Array<Scalars['String']>>;
  pushSenders?: Maybe<Array<Scalars['String']>>;
  emailDefault?: Maybe<Scalars['String']>;
  smsDefault?: Maybe<Scalars['String']>;
  pushDefault?: Maybe<Scalars['String']>;
  unsubscribeUrl?: Maybe<Scalars['String']>;
};

export type MessengerSettingUpdateInput = {
  emailSenders?: Maybe<Array<Scalars['String']>>;
  smsSenders?: Maybe<Array<Scalars['String']>>;
  pushSenders?: Maybe<Array<Scalars['String']>>;
  testers?: Maybe<Scalars['JSON']>;
  defaultSender?: Maybe<Scalars['JSON']>;
  defaultTransport?: Maybe<Scalars['JSON']>;
  defaultSubscription?: Maybe<Scalars['Int']>;
};

export type MessengerTemplate = {
  __typename?: 'MessengerTemplate';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: MessengerTemplateState;
  channel: MessengerTemplateChannel;
  sender: Scalars['String'];
  name: Scalars['String'];
  title: Scalars['String'];
  richText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  externalTemplateId?: Maybe<Scalars['String']>;
  templateProviderId?: Maybe<Scalars['Int']>;
  templateProvider?: Maybe<MessengerTemplateProvider>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum MessengerTemplateChannel {
  EMAIL = 'EMAIL',
  SERVICE_EMAIL = 'SERVICE_EMAIL',
  SMS = 'SMS',
  PUSH = 'PUSH',
  TELEGRAM = 'TELEGRAM',
  SLACK = 'SLACK',
}

export type MessengerTemplateConnection = DefaultConnection & {
  __typename?: 'MessengerTemplateConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<MessengerTemplate>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type MessengerTemplateCreateInput = {
  state?: Maybe<MessengerTemplateState>;
  channel: MessengerTemplateChannel;
  sender: Scalars['String'];
  name: Scalars['String'];
  title: Scalars['String'];
  richText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  externalTemplateId?: Maybe<Scalars['String']>;
  templateProviderId?: Maybe<Scalars['Int']>;
};

export type MessengerTemplateProvider = {
  __typename?: 'MessengerTemplateProvider';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: MessengerTemplateProviderState;
  name: Scalars['String'];
  settings?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type MessengerTemplateProviderConnection = DefaultConnection & {
  __typename?: 'MessengerTemplateProviderConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<MessengerTemplateProvider>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum MessengerTemplateProviderState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type MessengerTemplateProviderUpdateInput = {
  state?: Maybe<MessengerTemplateProviderState>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
};

export enum MessengerTemplateState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type MessengerTemplateUpdateInput = {
  state?: Maybe<MessengerTemplateState>;
  channel?: Maybe<MessengerTemplateChannel>;
  sender?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  richText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  externalTemplateId?: Maybe<Scalars['String']>;
  templateProviderId?: Maybe<Scalars['Int']>;
};

export type MessengerTransport = {
  __typename?: 'MessengerTransport';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  settings: Scalars['JSON'];
  providerId: Scalars['Int'];
  state: MessengerTransportState;
  channel: MessengerTransportChannel;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum MessengerTransportChannel {
  EMAIL = 'EMAIL',
  SERVICE_EMAIL = 'SERVICE_EMAIL',
  SMS = 'SMS',
  PUSH = 'PUSH',
  TELEGRAM = 'TELEGRAM',
  SLACK = 'SLACK',
}

export type MessengerTransportConnection = DefaultConnection & {
  __typename?: 'MessengerTransportConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<MessengerTransport>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type MessengerTransportCreateInput = {
  name: Scalars['String'];
  settings: Scalars['JSON'];
  providerId: Scalars['Int'];
  state?: Maybe<MessengerTransportState>;
  channel: MessengerTransportChannel;
};

export enum MessengerTransportState {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export type MessengerTransportUpdateInput = {
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
  providerId?: Maybe<Scalars['Int']>;
  state?: Maybe<MessengerTransportState>;
  channel?: Maybe<MessengerTransportChannel>;
};

export type MobileTranslation = {
  __typename?: 'MobileTranslation';
  id: Scalars['Int'];
  locale: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type MobileTranslationSaveInput = {
  key: Scalars['String'];
  value: Scalars['String'];
  locale: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  test?: Maybe<Scalars['String']>;
  updateArticleBlocks?: Maybe<Scalars['Int']>;
  saveArticleTags: Array<ArticleArticleTag>;
  createArticle: Article;
  updateArticle: Article;
  deleteArticle: Article;
  createArticleArticleTag: ArticleArticleTag;
  updateArticleArticleTag: ArticleArticleTag;
  deleteArticleArticleTag: ArticleArticleTag;
  createArticleAuthor: ArticleAuthor;
  updateArticleAuthor: ArticleAuthor;
  deleteArticleAuthor: ArticleAuthor;
  createArticleBlock: ArticleBlock;
  updateArticleBlock: ArticleBlock;
  deleteArticleBlock: ArticleBlock;
  createArticleCategory: ArticleCategory;
  updateArticleCategory: ArticleCategory;
  deleteArticleCategory: ArticleCategory;
  createArticleLink: ArticleLink;
  updateArticleLink: ArticleLink;
  deleteArticleLink: ArticleLink;
  createArticleTag: ArticleTag;
  updateArticleTag: ArticleTag;
  deleteArticleTag: ArticleTag;
  updateCatalogueItemBatch: Scalars['Int'];
  updateCatalogueCategoryBatch: Scalars['Int'];
  createCatalogue: Catalogue;
  updateCatalogue: Catalogue;
  deleteCatalogue: Catalogue;
  createCatalogueField: ExtensionField;
  updateCatalogueField: ExtensionField;
  deleteCatalogueField: Scalars['Boolean'];
  createCatalogueCategory: CatalogueCategory;
  updateCatalogueCategory: CatalogueCategory;
  deleteCatalogueCategory: CatalogueCategory;
  createCatalogueCategoryField: ExtensionField;
  updateCatalogueCategoryField: ExtensionField;
  deleteCatalogueCategoryField: Scalars['Boolean'];
  createCatalogueItem: CatalogueItem;
  updateCatalogueItem: CatalogueItem;
  deleteCatalogueItem: CatalogueItem;
  createCatalogueItemField: ExtensionField;
  updateCatalogueItemField: ExtensionField;
  deleteCatalogueItemField: Scalars['Boolean'];
  createCatalogueItemPrice: CatalogueItemPrice;
  updateCatalogueItemPrice: CatalogueItemPrice;
  deleteCatalogueItemPrice: CatalogueItemPrice;
  createCatalogueRelation: CatalogueRelation;
  updateCatalogueRelation: CatalogueRelation;
  deleteCatalogueRelation: CatalogueRelation;
  createCatering: Catering;
  updateCatering: Catering;
  deleteCatering: Catering;
  createCommerceOrderData: CommerceOrderData;
  updateCommerceOrderData: CommerceOrderData;
  deleteCommerceOrderData: CommerceOrderData;
  createCommerceOrderField: CommerceOrderField;
  updateCommerceOrderField: CommerceOrderField;
  deleteCommerceOrderField: CommerceOrderField;
  createCommerceOrderItem: CommerceOrderItem;
  updateCommerceOrderItem: CommerceOrderItem;
  deleteCommerceOrderItem: CommerceOrderItem;
  createCommerceSettings: CommerceSettings;
  updateCommerceSettings: CommerceSettings;
  deleteCommerceSettings: CommerceSettings;
  createCommerceShop: CommerceShop;
  updateCommerceShop: CommerceShop;
  deleteCommerceShop: CommerceShop;
  createCommerceTemplate: CommerceTemplate;
  updateCommerceTemplate: CommerceTemplate;
  deleteCommerceTemplate: CommerceTemplate;
  createCommerceOrder: CommerceOrder;
  cancelCommerceOrder: CommerceOrder;
  updateCommerceOrder: CommerceOrder;
  deleteCommerceOrder: CommerceOrder;
  updateCommerceOrderDatas: Scalars['Int'];
  commerceOrderPrint?: Maybe<Scalars['String']>;
  createContentTranslation: ContentTranslation;
  updateContentTranslation: ContentTranslation;
  deleteContentTranslation: ContentTranslation;
  saveContentTranslations: Array<ContentTranslation>;
  createDispute: Dispute;
  createDisputeDisputeResponse: DisputeDisputeResponse;
  updateDispute: Dispute;
  updateDisputeDisputeResponse: DisputeDisputeResponse;
  confirmDispute: Dispute;
  confirmDisputeDisputeResponse: DisputeDisputeResponse;
  confirmDisputeDisputeModerator: Dispute;
  archivingDisputesFromSeason: Scalars['Int'];
  saveDisputeSettings: DisputeSettings;
  updateDisputeSettings: DisputeSettings;
  generateDocsByEventOrder: Scalars['Boolean'];
  importToEventWhitelist: Scalars['Int'];
  addUsersToGroupFromTickets: Array<SystemAsyncTask>;
  cancelEventOrder: EventOrder;
  createEventOrderTicketsByOrderId: Array<EventOrderTicket>;
  exportEventsTickets: Scalars['String'];
  createEvent: Event;
  updateEvent: Event;
  deleteEvent: Event;
  createEventField: ExtensionField;
  updateEventField: ExtensionField;
  deleteEventField: Scalars['Boolean'];
  createEventEventAccess: EventEventAccess;
  updateEventEventAccess: EventEventAccess;
  deleteEventEventAccess: EventEventAccess;
  createEventEventWhitelist: EventEventWhitelist;
  updateEventEventWhitelist: EventEventWhitelist;
  deleteEventEventWhitelist: EventEventWhitelist;
  createEventOrder: EventOrder;
  updateEventOrder: EventOrder;
  deleteEventOrder: EventOrder;
  createEventOrderField: ExtensionField;
  updateEventOrderField: ExtensionField;
  deleteEventOrderField: Scalars['Boolean'];
  createEventOrderTicket: EventOrderTicket;
  updateEventOrderTicket: EventOrderTicket;
  deleteEventOrderTicket: EventOrderTicket;
  createEventOrderTicketField: ExtensionField;
  updateEventOrderTicketField: ExtensionField;
  deleteEventOrderTicketField: Scalars['Boolean'];
  createEventPlace: EventPlace;
  updateEventPlace: EventPlace;
  deleteEventPlace: EventPlace;
  createEventPlaceSector: EventPlaceSector;
  updateEventPlaceSector: EventPlaceSector;
  deleteEventPlaceSector: EventPlaceSector;
  createEventReserve: EventReserve;
  updateEventReserve: EventReserve;
  deleteEventReserve: EventReserve;
  updateEventSettings: EventSettings;
  createEventType: EventType;
  updateEventType: EventType;
  deleteEventType: EventType;
  deleteEventOrderTicketByOrderId: Scalars['Int'];
  exportUsersToUnisender: ExportResponse;
  unisenderCreateList: UnisenderList;
  createPaymentAccountByApplicationCode?: Maybe<PaymentAccount>;
  createLoyaltyApplication: LoyaltyApplication;
  updateLoyaltyApplication: LoyaltyApplication;
  deleteLoyaltyApplication: LoyaltyApplication;
  createLoyaltyPartner: LoyaltyPartner;
  updateLoyaltyPartner: LoyaltyPartner;
  deleteLoyaltyPartner: LoyaltyPartner;
  createLoyaltyPromo: LoyaltyPromo;
  updateLoyaltyPromo: LoyaltyPromo;
  deleteLoyaltyPromo: LoyaltyPromo;
  createLoyaltyPromoCode: LoyaltyPromoCode;
  updateLoyaltyPromoCode: LoyaltyPromoCode;
  deleteLoyaltyPromoCode: LoyaltyPromoCode;
  generateLoyaltyPromoCodes: Scalars['Int'];
  resetLoyaltyPromoCodes: Scalars['Int'];
  applyLoyaltyPromoCode: ApplyLoyaltyPromoCodeResult;
  importPromocodes?: Maybe<Scalars['String']>;
  exportPromocodes: Scalars['String'];
  createMessengerMailing: MessengerMailing;
  updateMessengerMailing: MessengerMailing;
  deleteMessengerMailing: MessengerMailing;
  createMessengerMailingActivity: MessengerMailingActivity;
  updateMessengerMailingActivity: MessengerMailingActivity;
  deleteMessengerMailingActivity: MessengerMailingActivity;
  createMessengerMailingRedirect: MessengerMailingRedirect;
  createMessengerProvider: MessengerProvider;
  updateMessengerProvider: MessengerProvider;
  deleteMessengerProvider: MessengerProvider;
  createMessengerQueueTask: MessengerQueueTask;
  updateMessengerQueueTask: MessengerQueueTask;
  deleteMessengerQueueTask: MessengerQueueTask;
  createMessengerTemplate: MessengerTemplate;
  updateMessengerTemplate: MessengerTemplate;
  deleteMessengerTemplate: MessengerTemplate;
  updateMessengerTemplateProvider: MessengerTemplateProvider;
  createMessengerTransport: MessengerTransport;
  updateMessengerTransport: MessengerTransport;
  deleteMessengerTransport: MessengerTransport;
  sendPushByUser?: Maybe<Scalars['String']>;
  sendNotification: MessengerQueueNotification;
  sendPushBySubscriptions?: Maybe<Scalars['String']>;
  addToMessengerQueue: MessengerQueueTask;
  deleteFromMessengerQueue: Scalars['Int'];
  updateMessengerSetting: MessengerSetting;
  importRecipientsToQueueNotification: MessengerQueueTask;
  testMessengerMailing?: Maybe<Scalars['String']>;
  createMessengerQueueNotification: MessengerQueueNotification;
  updateMessengerQueueNotification: MessengerQueueNotification;
  deleteMessengerQueueNotification: MessengerQueueNotification;
  saveRedirectOnMail: Scalars['Boolean'];
  importTemplateNotifications?: Maybe<Scalars['String']>;
  saveMobileTranslations?: Maybe<Array<MobileTranslation>>;
  deleteMobileTranslation: MobileTranslation;
  depositToAccount: ProviderResponse;
  chargeFromAccount: ProviderResponse;
  updatePaymentAccountByPartnerCode?: Maybe<Scalars['String']>;
  syncPaymentAccountBalanceByPartnerCode: Scalars['Int'];
  syncPaymentAccount: PaymentAccount;
  syncPaymentAccounts: Array<PaymentAccount>;
  createPaymentAccount: PaymentAccount;
  updatePaymentAccount: PaymentAccount;
  deletePaymentAccount: PaymentAccount;
  createPaymentBankCard: PaymentBankCard;
  updatePaymentBankCard: PaymentBankCard;
  deletePaymentBankCard: PaymentBankCard;
  createPaymentChannel: PaymentChannel;
  updatePaymentChannel: PaymentChannel;
  deletePaymentChannel: PaymentChannel;
  createPaymentCurrency: PaymentCurrency;
  updatePaymentCurrency: PaymentCurrency;
  deletePaymentCurrency: PaymentCurrency;
  createPaymentGateway: PaymentGateway;
  updatePaymentGateway: PaymentGateway;
  deletePaymentGateway: PaymentGateway;
  createPaymentProvider: PaymentProvider;
  updatePaymentProvider: PaymentProvider;
  deletePaymentProvider: PaymentProvider;
  createPaymentTransaction: PaymentTransaction;
  updatePaymentTransaction: PaymentTransaction;
  deletePaymentTransaction: PaymentTransaction;
  createPaymentTransactionLog: PaymentTransactionLog;
  updatePaymentTransactionLog: PaymentTransactionLog;
  deletePaymentTransactionLog: PaymentTransactionLog;
  payOrder: CommerceOrder;
  refundOrder: Array<PaymentTransaction>;
  chargeByBankCard?: Maybe<PaymentResponse>;
  process3DSecure?: Maybe<PaymentTransaction>;
  sendCashierCheckByOrder?: Maybe<PaymentReceiptResponse>;
  sendCashierCheckByReceipt?: Maybe<PaymentReceiptResponse>;
  initPayment?: Maybe<InitPaymentResponse>;
  paymentWebHook?: Maybe<PaymentWebHookResponse>;
  confirmOrderPayment: ConfirmOrderPaymentResponse;
  paymentConfirmPurchase: Scalars['Boolean'];
  validatePaymentByOrderId: PaymentInfo;
  startQuizAttempt: QuizAttempt;
  finishQuizAttempt: QuizAttempt;
  resetQuizAttempts: Scalars['Boolean'];
  createQuizQuestion: QuizQuestion;
  updateQuizQuestion: QuizQuestion;
  deleteQuizQuestion: QuizQuestion;
  createQuizQuestionAnswer: QuizQuestionAnswer;
  updateQuizQuestionAnswer: QuizQuestionAnswer;
  deleteQuizQuestionAnswer: QuizQuestionAnswer;
  createQuiz: Quiz;
  updateQuiz: Quiz;
  deleteQuiz: Quiz;
  createQuizAttemptAnswer: QuizAttemptAnswer;
  createSocialNetwork: SocialNetwork;
  updateSocialNetwork: SocialNetwork;
  deleteSocialNetwork: SocialNetwork;
  createSocialNewsSetting: SocialNewsSetting;
  updateSocialNewsSetting: SocialNewsSetting;
  deleteSocialNewsSetting: SocialNewsSetting;
  updateSystemAsyncTask: SystemAsyncTask;
  createSystemPrinter: SystemPrinter;
  updateSystemPrinter: SystemPrinter;
  deleteSystemPrinter: SystemPrinter;
  createSystemTask: SystemTask;
  updateSystemTask: SystemTask;
  deleteSystemTask: SystemTask;
  createSystemTranslation: SystemTranslation;
  updateSystemTranslation: SystemTranslation;
  deleteSystemTranslation: SystemTranslation;
  createSystemWebHook: SystemWebHook;
  updateSystemWebHook: SystemWebHook;
  deleteSystemWebHook: SystemWebHook;
  updateSystemTranslations?: Maybe<Array<SystemTranslation>>;
  saveSystemTranslation: SystemTranslation;
  systemLogin: SystemLogin;
  systemLogout: Scalars['Boolean'];
  createSystemUser: SystemUser;
  updateSystemUser: SystemUser;
  grantAccess: Scalars['Boolean'];
  revokeAccess: Scalars['Boolean'];
  updateUserSettings: UserSettings;
  triggerSystemWebHook?: Maybe<Scalars['String']>;
  createTemplateApplePass: TemplateApplePass;
  updateTemplateApplePass: TemplateApplePass;
  deleteTemplateApplePass: TemplateApplePass;
  createTemplateNotification: TemplateNotification;
  updateTemplateNotification: TemplateNotification;
  deleteTemplateNotification: TemplateNotification;
  createTemplatePDF: TemplatePdf;
  updateTemplatePDF: TemplatePdf;
  deleteTemplatePDF: TemplatePdf;
  updateTemplateProvider: TemplateProvider;
  createTournamentDispute: TournamentDispute;
  updateTournamentDispute: TournamentDispute;
  deleteTournamentDispute: TournamentDispute;
  createTournamentDisputeResponse: TournamentDisputeResponse;
  updateTournamentDisputeResponse: TournamentDisputeResponse;
  deleteTournamentDisputeResponse: TournamentDisputeResponse;
  createTournamentDisqualification: TournamentDisqualification;
  updateTournamentDisqualification: TournamentDisqualification;
  deleteTournamentDisqualification: TournamentDisqualification;
  createTournamentGroup: TournamentGroup;
  updateTournamentGroup: TournamentGroup;
  deleteTournamentGroup: TournamentGroup;
  createTournamentMatch: TournamentMatch;
  updateTournamentMatch: TournamentMatch;
  deleteTournamentMatch: TournamentMatch;
  createTournamentMatchField: ExtensionField;
  updateTournamentMatchField: ExtensionField;
  deleteTournamentMatchField: Scalars['Boolean'];
  createTournamentMatchEvent: TournamentMatchEvent;
  updateTournamentMatchEvent: TournamentMatchEvent;
  deleteTournamentMatchEvent: TournamentMatchEvent;
  createTournamentMatchLineUp: TournamentMatchLineUp;
  updateTournamentMatchLineUp: TournamentMatchLineUp;
  deleteTournamentMatchLineUp: TournamentMatchLineUp;
  createTournamentMatchProtocol: TournamentMatchProtocol;
  updateTournamentMatchProtocol: TournamentMatchProtocol;
  deleteTournamentMatchProtocol: TournamentMatchProtocol;
  createTournamentMatchStat: TournamentMatchStat;
  updateTournamentMatchStat: TournamentMatchStat;
  deleteTournamentMatchStat: TournamentMatchStat;
  createTournamentPlayerStat: TournamentPlayerStat;
  updateTournamentPlayerStat: TournamentPlayerStat;
  deleteTournamentPlayerStat: TournamentPlayerStat;
  createTournamentReferee: TournamentReferee;
  updateTournamentReferee: TournamentReferee;
  deleteTournamentReferee: TournamentReferee;
  createTournamentSeason: TournamentSeason;
  updateTournamentSeason: TournamentSeason;
  deleteTournamentSeason: TournamentSeason;
  createTournamentSeasonApplicationForm: TournamentSeasonApplicationForm;
  updateTournamentSeasonApplicationForm: TournamentSeasonApplicationForm;
  deleteTournamentSeasonApplicationForm: TournamentSeasonApplicationForm;
  createTournamentSeasonTeam: TournamentSeasonTeam;
  updateTournamentSeasonTeam: TournamentSeasonTeam;
  deleteTournamentSeasonTeam: TournamentSeasonTeam;
  createTournamentSeasonTeamMember: TournamentSeasonTeamMember;
  updateTournamentSeasonTeamMember: TournamentSeasonTeamMember;
  deleteTournamentSeasonTeamMember: TournamentSeasonTeamMember;
  createTournamentSeasonTeamStat: TournamentSeasonTeamStat;
  updateTournamentSeasonTeamStat: TournamentSeasonTeamStat;
  deleteTournamentSeasonTeamStat: TournamentSeasonTeamStat;
  updateTournamentSetting: TournamentSetting;
  createTournamentStadium: TournamentStadium;
  updateTournamentStadium: TournamentStadium;
  deleteTournamentStadium: TournamentStadium;
  createTournamentTeam: TournamentTeam;
  updateTournamentTeam: TournamentTeam;
  deleteTournamentTeam: TournamentTeam;
  createTournamentTeamField: ExtensionField;
  updateTournamentTeamField: ExtensionField;
  deleteTournamentTeamField: Scalars['Boolean'];
  createTournamentTeamGroup: TournamentTeamGroup;
  updateTournamentTeamGroup: TournamentTeamGroup;
  deleteTournamentTeamGroup: TournamentTeamGroup;
  createTournamentTeamHistory: TournamentTeamHistory;
  updateTournamentTeamHistory: TournamentTeamHistory;
  deleteTournamentTeamHistory: TournamentTeamHistory;
  createTournamentTeamMember: TournamentTeamMember;
  updateTournamentTeamMember: TournamentTeamMember;
  deleteTournamentTeamMember: TournamentTeamMember;
  createTournamentTeamMemberField: ExtensionField;
  updateTournamentTeamMemberField: ExtensionField;
  deleteTournamentTeamMemberField: Scalars['Boolean'];
  createTournamentTeamMemberSocialAccount: TournamentTeamMemberSocialAccount;
  updateTournamentTeamMemberSocialAccount: TournamentTeamMemberSocialAccount;
  deleteTournamentTeamMemberSocialAccount: TournamentTeamMemberSocialAccount;
  createTournamentTour: TournamentTour;
  updateTournamentTour: TournamentTour;
  deleteTournamentTour: TournamentTour;
  createTournament: Tournament;
  updateTournament: Tournament;
  deleteTournament: Tournament;
  createTournamentField: ExtensionField;
  updateTournamentField: ExtensionField;
  deleteTournamentField: Scalars['Boolean'];
  createTournamentTranslation: TournamentTranslation;
  updateTournamentTranslation: TournamentTranslation;
  deleteTournamentTranslation: TournamentTranslation;
  importTournamentMatchData?: Maybe<Scalars['String']>;
  syncTournamentSeasonStats?: Maybe<Scalars['String']>;
  syncTournamentMatchEvents?: Maybe<Scalars['String']>;
  importTournamentSeasonTeamStats: Scalars['Int'];
  syncTournamentSeasonTeams: Scalars['Int'];
  importTournamentPlayerStats: Scalars['Int'];
  importTournamentMatchLineups: Scalars['Int'];
  importTournamentMatchEvents: Scalars['Int'];
  importTournamentMatchStats: Scalars['Int'];
  syncTournamentTour: TournamentTour;
  createUserApplicationLogComment: UserApplicationLog;
  createUserAnalyticData: UserAnalyticData;
  updateUserAnalyticData: UserAnalyticData;
  createUserAnalyticField: UserAnalyticField;
  updateUserAnalyticField: UserAnalyticField;
  deleteUserAnalyticField: UserAnalyticField;
  createUserApplication: UserApplication;
  updateUserApplication: UserApplication;
  deleteUserApplication: UserApplication;
  createUserApplicationField: ExtensionField;
  updateUserApplicationField: ExtensionField;
  deleteUserApplicationField: Scalars['Boolean'];
  createUserApplicationType: UserApplicationType;
  updateUserApplicationType: UserApplicationType;
  deleteUserApplicationType: UserApplicationType;
  createUserDocument: UserDocument;
  updateUserDocument: UserDocument;
  deleteUserDocument: UserDocument;
  createUserGroup: UserGroup;
  updateUserGroup: UserGroup;
  deleteUserGroup: UserGroup;
  createUserKid: UserKid;
  updateUserKid: UserKid;
  deleteUserKid: UserKid;
  createUserSocialAccount: UserSocialAccount;
  updateUserSocialAccount: UserSocialAccount;
  deleteUserSocialAccount: UserSocialAccount;
  createUserSubscriber: UserSubscriber;
  updateUserSubscriber: UserSubscriber;
  deleteUserSubscriber: UserSubscriber;
  createUserSubscribersLog: UserSubscribersLog;
  updateUserSubscribersLog: UserSubscribersLog;
  deleteUserSubscribersLog: UserSubscribersLog;
  createUserSubscription: UserSubscription;
  updateUserSubscription: UserSubscription;
  deleteUserSubscription: UserSubscription;
  createUserField: ExtensionField;
  updateUserField: ExtensionField;
  deleteUserField: Scalars['Boolean'];
  subscribeToMatchEvents: MatchEventSubscription;
  validatePhone: Scalars['Boolean'];
  sendPin: Scalars['Boolean'];
  sendPinByCall: Scalars['Boolean'];
  validatePin: AuthPayload;
  saveUserSettings: UserSetting;
  addToUsersSubscription: Scalars['Int'];
  deleteFromUsersSubscription: Scalars['Int'];
  createUserSubscribers: Scalars['Int'];
  deleteUserSubscribers: Scalars['Int'];
  importUserSubscribers: Scalars['Int'];
  unsubscribeUser: Array<UserSubscriber>;
  unsubscribeUserByEmail: Array<UserSubscriber>;
  unsubscribeUserByEmails: Scalars['Boolean'];
  addUsersToGroup: Array<SystemAsyncTask>;
  addUserToGroup: Scalars['Int'];
  deleteUserFromGroup: Array<SystemAsyncTask>;
  createUser: User;
  updateUser: User;
  registerUser: UserAuthResponse;
  registerUserSocial: UserSocialAuthResponse;
  loginUser: UserAuthResponse;
  masterLoginUser: UserAuthResponse;
  loginUserSocial: UserSocialAuthResponse;
  updateUserAccessToken: UserAuthResponse;
  updateUserRefreshToken: UserAuthResponse;
  resetUserPassword: Scalars['Boolean'];
  confirmUserEmail: Scalars['Boolean'];
  deleteUserById: Scalars['Boolean'];
  addUserToCategory?: Maybe<Scalars['Boolean']>;
  removeUserToCategory?: Maybe<Scalars['Boolean']>;
  addUserDevice: UserDevice;
  createUserDevice: UserDevice;
  addUserLocation?: Maybe<UserLocation>;
  createUserLocation: UserLocation;
  importUserOrdersAndTickets?: Maybe<UserImportResponse>;
  importUsers: SystemAsyncTask;
  createUserDataField?: Maybe<UserDataField>;
  updateUserDataField?: Maybe<UserDataField>;
  deleteUserDataField?: Maybe<UserDataField>;
  createUserAnalyticDataBatch?: Maybe<Scalars['String']>;
  updateMySubscriptions: UserSubscriptionConnection;
  updateUserSubscriptions: UserSubscriptionConnection;
  uploadFileToImportUsers: ImportUploadResponse;
  exportUsers: ExportResponse;
};

export type MutationUpdateArticleBlocksArgs = {
  data: Array<ArticleBlocksUpdateInput>;
};

export type MutationSaveArticleTagsArgs = {
  data: SaveArticleTagsInput;
};

export type MutationCreateArticleArgs = {
  data: ArticleCreateInput;
};

export type MutationUpdateArticleArgs = {
  id: Scalars['Int'];
  data: ArticleUpdateInput;
};

export type MutationDeleteArticleArgs = {
  id: Scalars['Int'];
};

export type MutationCreateArticleArticleTagArgs = {
  data: ArticleArticleTagCreateInput;
};

export type MutationUpdateArticleArticleTagArgs = {
  id: Scalars['Int'];
  data: ArticleArticleTagUpdateInput;
};

export type MutationDeleteArticleArticleTagArgs = {
  id: Scalars['Int'];
};

export type MutationCreateArticleAuthorArgs = {
  data: ArticleAuthorCreateInput;
};

export type MutationUpdateArticleAuthorArgs = {
  id: Scalars['Int'];
  data: ArticleAuthorUpdateInput;
};

export type MutationDeleteArticleAuthorArgs = {
  id: Scalars['Int'];
};

export type MutationCreateArticleBlockArgs = {
  data: ArticleBlockCreateInput;
};

export type MutationUpdateArticleBlockArgs = {
  id: Scalars['Int'];
  data: ArticleBlockUpdateInput;
};

export type MutationDeleteArticleBlockArgs = {
  id: Scalars['Int'];
};

export type MutationCreateArticleCategoryArgs = {
  data: ArticleCategoryCreateInput;
};

export type MutationUpdateArticleCategoryArgs = {
  id: Scalars['Int'];
  data: ArticleCategoryUpdateInput;
};

export type MutationDeleteArticleCategoryArgs = {
  id: Scalars['Int'];
};

export type MutationCreateArticleLinkArgs = {
  data: ArticleLinkCreateInput;
};

export type MutationUpdateArticleLinkArgs = {
  id: Scalars['Int'];
  data: ArticleLinkUpdateInput;
};

export type MutationDeleteArticleLinkArgs = {
  id: Scalars['Int'];
};

export type MutationCreateArticleTagArgs = {
  data: ArticleTagCreateInput;
};

export type MutationUpdateArticleTagArgs = {
  id: Scalars['Int'];
  data: ArticleTagUpdateInput;
};

export type MutationDeleteArticleTagArgs = {
  id: Scalars['Int'];
};

export type MutationUpdateCatalogueItemBatchArgs = {
  data: Array<CatalogueItemBatchUpdateInput>;
};

export type MutationUpdateCatalogueCategoryBatchArgs = {
  data: Array<CatalogueCategoryBatchUpdateInput>;
};

export type MutationCreateCatalogueArgs = {
  data: CatalogueCreateInput;
};

export type MutationUpdateCatalogueArgs = {
  id: Scalars['Int'];
  data: CatalogueUpdateInput;
};

export type MutationDeleteCatalogueArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCatalogueFieldArgs = {
  data: CreateExtensionFieldInput;
};

export type MutationUpdateCatalogueFieldArgs = {
  id: Scalars['Int'];
  data: UpdateExtensionFieldInput;
};

export type MutationDeleteCatalogueFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCatalogueCategoryArgs = {
  data: CatalogueCategoryCreateInput;
};

export type MutationUpdateCatalogueCategoryArgs = {
  id: Scalars['Int'];
  data: CatalogueCategoryUpdateInput;
};

export type MutationDeleteCatalogueCategoryArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCatalogueCategoryFieldArgs = {
  data: CreateExtensionFieldInput;
};

export type MutationUpdateCatalogueCategoryFieldArgs = {
  id: Scalars['Int'];
  data: UpdateExtensionFieldInput;
};

export type MutationDeleteCatalogueCategoryFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCatalogueItemArgs = {
  data: CatalogueItemCreateInput;
};

export type MutationUpdateCatalogueItemArgs = {
  id: Scalars['Int'];
  data: CatalogueItemUpdateInput;
};

export type MutationDeleteCatalogueItemArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCatalogueItemFieldArgs = {
  data: CreateExtensionFieldInput;
};

export type MutationUpdateCatalogueItemFieldArgs = {
  id: Scalars['Int'];
  data: UpdateExtensionFieldInput;
};

export type MutationDeleteCatalogueItemFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCatalogueItemPriceArgs = {
  data: CatalogueItemPriceCreateInput;
};

export type MutationUpdateCatalogueItemPriceArgs = {
  id: Scalars['Int'];
  data: CatalogueItemPriceUpdateInput;
};

export type MutationDeleteCatalogueItemPriceArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCatalogueRelationArgs = {
  data: CatalogueRelationCreateInput;
};

export type MutationUpdateCatalogueRelationArgs = {
  id: Scalars['Int'];
  data: CatalogueRelationUpdateInput;
};

export type MutationDeleteCatalogueRelationArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCateringArgs = {
  data: CateringCreateInput;
};

export type MutationUpdateCateringArgs = {
  id: Scalars['Int'];
  data: CateringUpdateInput;
};

export type MutationDeleteCateringArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCommerceOrderDataArgs = {
  data: CommerceOrderDataCreateInput;
};

export type MutationUpdateCommerceOrderDataArgs = {
  id: Scalars['Int'];
  data: CommerceOrderDataUpdateInput;
};

export type MutationDeleteCommerceOrderDataArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCommerceOrderFieldArgs = {
  data: CommerceOrderFieldCreateInput;
};

export type MutationUpdateCommerceOrderFieldArgs = {
  id: Scalars['Int'];
  data: CommerceOrderFieldUpdateInput;
};

export type MutationDeleteCommerceOrderFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCommerceOrderItemArgs = {
  data: CommerceOrderItemCreateInput;
};

export type MutationUpdateCommerceOrderItemArgs = {
  id: Scalars['Int'];
  data: CommerceOrderItemUpdateInput;
};

export type MutationDeleteCommerceOrderItemArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCommerceSettingsArgs = {
  data: CommerceSettingsCreateInput;
};

export type MutationUpdateCommerceSettingsArgs = {
  id: Scalars['Int'];
  data: CommerceSettingsUpdateInput;
};

export type MutationDeleteCommerceSettingsArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCommerceShopArgs = {
  data: CommerceShopCreateInput;
};

export type MutationUpdateCommerceShopArgs = {
  id: Scalars['Int'];
  data: CommerceShopUpdateInput;
};

export type MutationDeleteCommerceShopArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCommerceTemplateArgs = {
  data: CommerceTemplateCreateInput;
};

export type MutationUpdateCommerceTemplateArgs = {
  id: Scalars['Int'];
  data: CommerceTemplateUpdateInput;
};

export type MutationDeleteCommerceTemplateArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCommerceOrderArgs = {
  data: CommerceOrderCreateInput;
};

export type MutationCancelCommerceOrderArgs = {
  id: Scalars['Int'];
};

export type MutationUpdateCommerceOrderArgs = {
  id: Scalars['Int'];
  data: CommerceOrderUpdateInput;
};

export type MutationDeleteCommerceOrderArgs = {
  id: Scalars['Int'];
};

export type MutationUpdateCommerceOrderDatasArgs = {
  data: Array<CommerceOrderDatasUpdateInput>;
};

export type MutationCommerceOrderPrintArgs = {
  id: Scalars['Int'];
  printerId: Scalars['Int'];
  templateId: Scalars['Int'];
};

export type MutationCreateContentTranslationArgs = {
  data: ContentTranslationCreateInput;
};

export type MutationUpdateContentTranslationArgs = {
  id: Scalars['Int'];
  data: ContentTranslationUpdateInput;
};

export type MutationDeleteContentTranslationArgs = {
  id: Scalars['Int'];
};

export type MutationSaveContentTranslationsArgs = {
  data: Array<ContentTranslationSaveInput>;
};

export type MutationCreateDisputeArgs = {
  data: DisputeCreateInput;
};

export type MutationCreateDisputeDisputeResponseArgs = {
  data: DisputeResponseCreateInput;
};

export type MutationUpdateDisputeArgs = {
  id: Scalars['Int'];
  data: DisputeUpdateInput;
};

export type MutationUpdateDisputeDisputeResponseArgs = {
  id: Scalars['Int'];
  data: DisputeResponseUpdateInput;
};

export type MutationConfirmDisputeArgs = {
  id: Scalars['Int'];
  isWin: Scalars['Boolean'];
};

export type MutationConfirmDisputeDisputeResponseArgs = {
  id: Scalars['Int'];
  isWin: Scalars['Boolean'];
};

export type MutationConfirmDisputeDisputeModeratorArgs = {
  id: Scalars['Int'];
  result: DisputeResult;
};

export type MutationArchivingDisputesFromSeasonArgs = {
  seasonId: Scalars['Int'];
};

export type MutationSaveDisputeSettingsArgs = {
  data: DisputeSettingsUpdateInput;
};

export type MutationUpdateDisputeSettingsArgs = {
  id: Scalars['Int'];
  data: DisputeSettingsUpdateInput;
};

export type MutationGenerateDocsByEventOrderArgs = {
  userId: Scalars['Int'];
  notify: Scalars['Boolean'];
  orderId?: Maybe<Scalars['Int']>;
  ticketId?: Maybe<Scalars['Int']>;
};

export type MutationImportToEventWhitelistArgs = {
  eventId: Scalars['Int'];
  file: Scalars['Upload'];
};

export type MutationAddUsersToGroupFromTicketsArgs = {
  groupId: Array<Scalars['Int']>;
  query: DefaultQueryInput;
};

export type MutationCancelEventOrderArgs = {
  id: Scalars['Int'];
};

export type MutationCreateEventOrderTicketsByOrderIdArgs = {
  orderId: Scalars['Int'];
  data: Array<EventOrderTicketCreateInput>;
};

export type MutationExportEventsTicketsArgs = {
  eventId: Scalars['Int'];
};

export type MutationCreateEventArgs = {
  data: EventCreateInput;
};

export type MutationUpdateEventArgs = {
  id: Scalars['Int'];
  data: EventUpdateInput;
};

export type MutationDeleteEventArgs = {
  id: Scalars['Int'];
};

export type MutationCreateEventFieldArgs = {
  data: CreateExtensionFieldInput;
};

export type MutationUpdateEventFieldArgs = {
  id: Scalars['Int'];
  data: UpdateExtensionFieldInput;
};

export type MutationDeleteEventFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateEventEventAccessArgs = {
  data: EventEventAccessCreateInput;
};

export type MutationUpdateEventEventAccessArgs = {
  id: Scalars['Int'];
  data: EventEventAccessUpdateInput;
};

export type MutationDeleteEventEventAccessArgs = {
  id: Scalars['Int'];
};

export type MutationCreateEventEventWhitelistArgs = {
  data: EventEventWhitelistCreateInput;
};

export type MutationUpdateEventEventWhitelistArgs = {
  id: Scalars['Int'];
  data: EventEventWhitelistUpdateInput;
};

export type MutationDeleteEventEventWhitelistArgs = {
  id: Scalars['Int'];
};

export type MutationCreateEventOrderArgs = {
  data: EventOrderCreateInput;
};

export type MutationUpdateEventOrderArgs = {
  id: Scalars['Int'];
  data: EventOrderUpdateInput;
};

export type MutationDeleteEventOrderArgs = {
  id: Scalars['Int'];
};

export type MutationCreateEventOrderFieldArgs = {
  data: CreateExtensionFieldInput;
};

export type MutationUpdateEventOrderFieldArgs = {
  id: Scalars['Int'];
  data: UpdateExtensionFieldInput;
};

export type MutationDeleteEventOrderFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateEventOrderTicketArgs = {
  data: EventOrderTicketCreateInput;
};

export type MutationUpdateEventOrderTicketArgs = {
  id: Scalars['Int'];
  data: EventOrderTicketUpdateInput;
};

export type MutationDeleteEventOrderTicketArgs = {
  id: Scalars['Int'];
};

export type MutationCreateEventOrderTicketFieldArgs = {
  data: CreateExtensionFieldInput;
};

export type MutationUpdateEventOrderTicketFieldArgs = {
  id: Scalars['Int'];
  data: UpdateExtensionFieldInput;
};

export type MutationDeleteEventOrderTicketFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateEventPlaceArgs = {
  data: EventPlaceCreateInput;
};

export type MutationUpdateEventPlaceArgs = {
  id: Scalars['Int'];
  data: EventPlaceUpdateInput;
};

export type MutationDeleteEventPlaceArgs = {
  id: Scalars['Int'];
};

export type MutationCreateEventPlaceSectorArgs = {
  data: EventPlaceSectorCreateInput;
};

export type MutationUpdateEventPlaceSectorArgs = {
  id: Scalars['Int'];
  data: EventPlaceSectorUpdateInput;
};

export type MutationDeleteEventPlaceSectorArgs = {
  id: Scalars['Int'];
};

export type MutationCreateEventReserveArgs = {
  data: EventReserveCreateInput;
};

export type MutationUpdateEventReserveArgs = {
  id: Scalars['Int'];
  data: EventReserveUpdateInput;
};

export type MutationDeleteEventReserveArgs = {
  id: Scalars['Int'];
};

export type MutationUpdateEventSettingsArgs = {
  id: Scalars['Int'];
  data: EventSettingsUpdateInput;
};

export type MutationCreateEventTypeArgs = {
  data: EventTypeCreateInput;
};

export type MutationUpdateEventTypeArgs = {
  id: Scalars['Int'];
  data: EventTypeUpdateInput;
};

export type MutationDeleteEventTypeArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteEventOrderTicketByOrderIdArgs = {
  orderId: Scalars['Int'];
};

export type MutationExportUsersToUnisenderArgs = {
  query?: Maybe<DefaultQueryInput>;
  settings: ExportUnisenderSettingsInput;
};

export type MutationUnisenderCreateListArgs = {
  data: UnisenderCreateListInput;
};

export type MutationCreatePaymentAccountByApplicationCodeArgs = {
  userId: Scalars['Int'];
  partnerId: Scalars['Int'];
  providerCode: Scalars['String'];
};

export type MutationCreateLoyaltyApplicationArgs = {
  data: LoyaltyApplicationCreateInput;
};

export type MutationUpdateLoyaltyApplicationArgs = {
  id: Scalars['Int'];
  data: LoyaltyApplicationUpdateInput;
};

export type MutationDeleteLoyaltyApplicationArgs = {
  id: Scalars['Int'];
};

export type MutationCreateLoyaltyPartnerArgs = {
  data: LoyaltyPartnerCreateInput;
};

export type MutationUpdateLoyaltyPartnerArgs = {
  id: Scalars['Int'];
  data: LoyaltyPartnerUpdateInput;
};

export type MutationDeleteLoyaltyPartnerArgs = {
  id: Scalars['Int'];
};

export type MutationCreateLoyaltyPromoArgs = {
  data: LoyaltyPromoCreateInput;
};

export type MutationUpdateLoyaltyPromoArgs = {
  id: Scalars['Int'];
  data: LoyaltyPromoUpdateInput;
};

export type MutationDeleteLoyaltyPromoArgs = {
  id: Scalars['Int'];
};

export type MutationCreateLoyaltyPromoCodeArgs = {
  data: LoyaltyPromoCodeCreateInput;
};

export type MutationUpdateLoyaltyPromoCodeArgs = {
  id: Scalars['Int'];
  data: LoyaltyPromoCodeUpdateInput;
};

export type MutationDeleteLoyaltyPromoCodeArgs = {
  id: Scalars['Int'];
};

export type MutationGenerateLoyaltyPromoCodesArgs = {
  promoId: Scalars['Int'];
  count: Scalars['Int'];
  length: Scalars['Int'];
};

export type MutationResetLoyaltyPromoCodesArgs = {
  promoId: Scalars['Int'];
};

export type MutationApplyLoyaltyPromoCodeArgs = {
  code: Scalars['String'];
};

export type MutationImportPromocodesArgs = {
  promoId: Scalars['Int'];
  file: Scalars['Upload'];
};

export type MutationExportPromocodesArgs = {
  promoId: Scalars['Int'];
  format: ExportFormat;
};

export type MutationCreateMessengerMailingArgs = {
  data: MessengerMailingCreateInput;
};

export type MutationUpdateMessengerMailingArgs = {
  id: Scalars['Int'];
  data: MessengerMailingUpdateInput;
};

export type MutationDeleteMessengerMailingArgs = {
  id: Scalars['Int'];
};

export type MutationCreateMessengerMailingActivityArgs = {
  data: MessengerMailingActivityCreateInput;
};

export type MutationUpdateMessengerMailingActivityArgs = {
  id: Scalars['Int'];
  data: MessengerMailingActivityUpdateInput;
};

export type MutationDeleteMessengerMailingActivityArgs = {
  id: Scalars['Int'];
};

export type MutationCreateMessengerMailingRedirectArgs = {
  data: MessengerMailingRedirectCreateInput;
};

export type MutationCreateMessengerProviderArgs = {
  data: MessengerProviderCreateInput;
};

export type MutationUpdateMessengerProviderArgs = {
  id: Scalars['Int'];
  data: MessengerProviderUpdateInput;
};

export type MutationDeleteMessengerProviderArgs = {
  id: Scalars['Int'];
};

export type MutationCreateMessengerQueueTaskArgs = {
  data: MessengerQueueTaskCreateInput;
};

export type MutationUpdateMessengerQueueTaskArgs = {
  id: Scalars['Int'];
  data: MessengerQueueTaskUpdateInput;
};

export type MutationDeleteMessengerQueueTaskArgs = {
  id: Scalars['Int'];
};

export type MutationCreateMessengerTemplateArgs = {
  data: MessengerTemplateCreateInput;
};

export type MutationUpdateMessengerTemplateArgs = {
  id: Scalars['Int'];
  data: MessengerTemplateUpdateInput;
};

export type MutationDeleteMessengerTemplateArgs = {
  id: Scalars['Int'];
};

export type MutationUpdateMessengerTemplateProviderArgs = {
  id: Scalars['Int'];
  data: MessengerTemplateProviderUpdateInput;
};

export type MutationCreateMessengerTransportArgs = {
  data: MessengerTransportCreateInput;
};

export type MutationUpdateMessengerTransportArgs = {
  id: Scalars['Int'];
  data: MessengerTransportUpdateInput;
};

export type MutationDeleteMessengerTransportArgs = {
  id: Scalars['Int'];
};

export type MutationSendPushByUserArgs = {
  data: UserPushInput;
};

export type MutationSendNotificationArgs = {
  data: SendNotificationInput;
};

export type MutationSendPushBySubscriptionsArgs = {
  data: SubscriptionPushInput;
};

export type MutationAddToMessengerQueueArgs = {
  mailingId: Scalars['Int'];
  userId?: Maybe<Array<Scalars['Int']>>;
  query?: Maybe<DefaultQueryInput>;
};

export type MutationDeleteFromMessengerQueueArgs = {
  mailingId: Scalars['Int'];
  userId?: Maybe<Array<Scalars['Int']>>;
};

export type MutationUpdateMessengerSettingArgs = {
  data: MessengerSettingUpdateInput;
};

export type MutationImportRecipientsToQueueNotificationArgs = {
  mailingId: Scalars['Int'];
  file: Scalars['Upload'];
  subscriptionId?: Maybe<Scalars['Int']>;
};

export type MutationTestMessengerMailingArgs = {
  mailingId: Scalars['Int'];
};

export type MutationCreateMessengerQueueNotificationArgs = {
  data: MessengerQueueNotificationCreateInput;
};

export type MutationUpdateMessengerQueueNotificationArgs = {
  id: Scalars['String'];
  data: MessengerQueueNotificationUpdateInput;
};

export type MutationDeleteMessengerQueueNotificationArgs = {
  id: Scalars['String'];
};

export type MutationSaveRedirectOnMailArgs = {
  data: SaveRedirectOnMailInput;
};

export type MutationImportTemplateNotificationsArgs = {
  providerId?: Maybe<Scalars['Int']>;
  templateId?: Maybe<Scalars['Int']>;
};

export type MutationSaveMobileTranslationsArgs = {
  data?: Maybe<Array<MobileTranslationSaveInput>>;
};

export type MutationDeleteMobileTranslationArgs = {
  id: Scalars['Int'];
};

export type MutationDepositToAccountArgs = {
  data: PaymentAccountOperationInput;
};

export type MutationChargeFromAccountArgs = {
  data: PaymentAccountOperationInput;
};

export type MutationUpdatePaymentAccountByPartnerCodeArgs = {
  where: PaymentAccountWhereInput;
  data: PaymentAccountUpdateInput;
};

export type MutationSyncPaymentAccountBalanceByPartnerCodeArgs = {
  where: PaymentAccountWhereInput;
};

export type MutationSyncPaymentAccountArgs = {
  id: Scalars['Int'];
};

export type MutationSyncPaymentAccountsArgs = {
  userId: Scalars['Int'];
  currencyId?: Maybe<Scalars['Int']>;
};

export type MutationCreatePaymentAccountArgs = {
  data: PaymentAccountCreateInput;
};

export type MutationUpdatePaymentAccountArgs = {
  id: Scalars['Int'];
  data: PaymentAccountUpdateInput;
};

export type MutationDeletePaymentAccountArgs = {
  id: Scalars['Int'];
};

export type MutationCreatePaymentBankCardArgs = {
  data: PaymentBankCardCreateInput;
};

export type MutationUpdatePaymentBankCardArgs = {
  id: Scalars['Int'];
  data: PaymentBankCardUpdateInput;
};

export type MutationDeletePaymentBankCardArgs = {
  id: Scalars['Int'];
};

export type MutationCreatePaymentChannelArgs = {
  data: PaymentChannelCreateInput;
};

export type MutationUpdatePaymentChannelArgs = {
  id: Scalars['Int'];
  data: PaymentChannelUpdateInput;
};

export type MutationDeletePaymentChannelArgs = {
  id: Scalars['Int'];
};

export type MutationCreatePaymentCurrencyArgs = {
  data: PaymentCurrencyCreateInput;
};

export type MutationUpdatePaymentCurrencyArgs = {
  id: Scalars['Int'];
  data: PaymentCurrencyUpdateInput;
};

export type MutationDeletePaymentCurrencyArgs = {
  id: Scalars['Int'];
};

export type MutationCreatePaymentGatewayArgs = {
  data: PaymentGatewayCreateInput;
};

export type MutationUpdatePaymentGatewayArgs = {
  id: Scalars['Int'];
  data: PaymentGatewayUpdateInput;
};

export type MutationDeletePaymentGatewayArgs = {
  id: Scalars['Int'];
};

export type MutationCreatePaymentProviderArgs = {
  data: PaymentProviderCreateInput;
};

export type MutationUpdatePaymentProviderArgs = {
  id: Scalars['Int'];
  data: PaymentProviderUpdateInput;
};

export type MutationDeletePaymentProviderArgs = {
  id: Scalars['Int'];
};

export type MutationCreatePaymentTransactionArgs = {
  data: PaymentTransactionCreateInput;
};

export type MutationUpdatePaymentTransactionArgs = {
  id: Scalars['Int'];
  data: PaymentTransactionUpdateInput;
};

export type MutationDeletePaymentTransactionArgs = {
  id: Scalars['Int'];
};

export type MutationCreatePaymentTransactionLogArgs = {
  data: PaymentTransactionLogCreateInput;
};

export type MutationUpdatePaymentTransactionLogArgs = {
  id: Scalars['Int'];
  data: PaymentTransactionLogUpdateInput;
};

export type MutationDeletePaymentTransactionLogArgs = {
  id: Scalars['Int'];
};

export type MutationPayOrderArgs = {
  data: PayOrderInput;
};

export type MutationRefundOrderArgs = {
  data: RefundOrderInput;
};

export type MutationChargeByBankCardArgs = {
  data: CryptogramPaymentInput;
};

export type MutationProcess3DSecureArgs = {
  data: Process3DSecureInput;
};

export type MutationSendCashierCheckByOrderArgs = {
  orderId: Scalars['Int'];
  orderType: PaymentTransactionOrderType;
  type: PaymentReceiptType;
};

export type MutationSendCashierCheckByReceiptArgs = {
  data: CashierCheckReceiptInput;
};

export type MutationInitPaymentArgs = {
  data: InitPaymentInput;
};

export type MutationPaymentWebHookArgs = {
  data: PaymentWebHookInput;
};

export type MutationConfirmOrderPaymentArgs = {
  data: ConfirmOrderPaymentInput;
};

export type MutationPaymentConfirmPurchaseArgs = {
  store?: Maybe<AppStoreEnum>;
  receipts: Array<Scalars['String']>;
};

export type MutationValidatePaymentByOrderIdArgs = {
  providerId: Scalars['Int'];
  orderId: Scalars['Int'];
  orderType: PaymentTransactionOrderType;
};

export type MutationStartQuizAttemptArgs = {
  quizId: Scalars['Int'];
};

export type MutationFinishQuizAttemptArgs = {
  id: Scalars['Int'];
};

export type MutationResetQuizAttemptsArgs = {
  data: QuizResetAttemptsInput;
};

export type MutationCreateQuizQuestionArgs = {
  data: QuizQuestionCreateInput;
};

export type MutationUpdateQuizQuestionArgs = {
  id: Scalars['Int'];
  data: QuizQuestionUpdateInput;
};

export type MutationDeleteQuizQuestionArgs = {
  id: Scalars['Int'];
};

export type MutationCreateQuizQuestionAnswerArgs = {
  data: QuizQuestionAnswerCreateInput;
};

export type MutationUpdateQuizQuestionAnswerArgs = {
  id: Scalars['Int'];
  data: QuizQuestionAnswerUpdateInput;
};

export type MutationDeleteQuizQuestionAnswerArgs = {
  id: Scalars['Int'];
};

export type MutationCreateQuizArgs = {
  data: QuizCreateInput;
};

export type MutationUpdateQuizArgs = {
  id: Scalars['Int'];
  data: QuizUpdateInput;
};

export type MutationDeleteQuizArgs = {
  id: Scalars['Int'];
};

export type MutationCreateQuizAttemptAnswerArgs = {
  data: QuizAttemptAnswerCreateInput;
};

export type MutationCreateSocialNetworkArgs = {
  data: SocialNetworkCreateInput;
};

export type MutationUpdateSocialNetworkArgs = {
  id: Scalars['Int'];
  data: SocialNetworkUpdateInput;
};

export type MutationDeleteSocialNetworkArgs = {
  id: Scalars['Int'];
};

export type MutationCreateSocialNewsSettingArgs = {
  data: SocialNewsSettingCreateInput;
};

export type MutationUpdateSocialNewsSettingArgs = {
  id: Scalars['Int'];
  data: SocialNewsSettingUpdateInput;
};

export type MutationDeleteSocialNewsSettingArgs = {
  id: Scalars['Int'];
};

export type MutationUpdateSystemAsyncTaskArgs = {
  id: Scalars['Int'];
  data: SystemAsyncTaskUpdateInput;
};

export type MutationCreateSystemPrinterArgs = {
  data: SystemPrinterCreateInput;
};

export type MutationUpdateSystemPrinterArgs = {
  id: Scalars['Int'];
  data: SystemPrinterUpdateInput;
};

export type MutationDeleteSystemPrinterArgs = {
  id: Scalars['Int'];
};

export type MutationCreateSystemTaskArgs = {
  data: SystemTaskCreateInput;
};

export type MutationUpdateSystemTaskArgs = {
  id: Scalars['UUID'];
  data: SystemTaskUpdateInput;
};

export type MutationDeleteSystemTaskArgs = {
  id: Scalars['UUID'];
};

export type MutationCreateSystemTranslationArgs = {
  data: SystemTranslationCreateInput;
};

export type MutationUpdateSystemTranslationArgs = {
  id: Scalars['Int'];
  data: SystemTranslationUpdateInput;
};

export type MutationDeleteSystemTranslationArgs = {
  id: Scalars['Int'];
};

export type MutationCreateSystemWebHookArgs = {
  data: SystemWebHookCreateInput;
};

export type MutationUpdateSystemWebHookArgs = {
  id: Scalars['Int'];
  data: SystemWebHookUpdateInput;
};

export type MutationDeleteSystemWebHookArgs = {
  id: Scalars['Int'];
};

export type MutationUpdateSystemTranslationsArgs = {
  data: Array<SystemTranslationUpdateInput>;
};

export type MutationSaveSystemTranslationArgs = {
  data: SystemTranslationSaveInput;
};

export type MutationSystemLoginArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
  clientId?: Maybe<Scalars['Int']>;
};

export type MutationCreateSystemUserArgs = {
  data: CreateSystemUserInput;
};

export type MutationUpdateSystemUserArgs = {
  id: Scalars['ID'];
  data: UpdateSystemUserInput;
};

export type MutationGrantAccessArgs = {
  userId: Scalars['ID'];
  aclId: Scalars['ID'];
};

export type MutationRevokeAccessArgs = {
  userId: Scalars['ID'];
  aclId: Scalars['ID'];
};

export type MutationUpdateUserSettingsArgs = {
  data: UpdateUserSettingsInput;
};

export type MutationTriggerSystemWebHookArgs = {
  id: Scalars['Int'];
  entityId?: Maybe<Scalars['Int']>;
  payload?: Maybe<SystemWebHookPayloadInput>;
};

export type MutationCreateTemplateApplePassArgs = {
  data: TemplateApplePassCreateInput;
};

export type MutationUpdateTemplateApplePassArgs = {
  id: Scalars['Int'];
  data: TemplateApplePassUpdateInput;
};

export type MutationDeleteTemplateApplePassArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTemplateNotificationArgs = {
  data: TemplateNotificationCreateInput;
};

export type MutationUpdateTemplateNotificationArgs = {
  id: Scalars['Int'];
  data: TemplateNotificationUpdateInput;
};

export type MutationDeleteTemplateNotificationArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTemplatePdfArgs = {
  data: TemplatePdfCreateInput;
};

export type MutationUpdateTemplatePdfArgs = {
  id: Scalars['Int'];
  data: TemplatePdfUpdateInput;
};

export type MutationDeleteTemplatePdfArgs = {
  id: Scalars['Int'];
};

export type MutationUpdateTemplateProviderArgs = {
  id: Scalars['Int'];
  data: TemplateProviderUpdateInput;
};

export type MutationCreateTournamentDisputeArgs = {
  data: TournamentDisputeCreateInput;
};

export type MutationUpdateTournamentDisputeArgs = {
  id: Scalars['Int'];
  data: TournamentDisputeUpdateInput;
};

export type MutationDeleteTournamentDisputeArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentDisputeResponseArgs = {
  data: TournamentDisputeResponseCreateInput;
};

export type MutationUpdateTournamentDisputeResponseArgs = {
  id: Scalars['Int'];
  data: TournamentDisputeResponseUpdateInput;
};

export type MutationDeleteTournamentDisputeResponseArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentDisqualificationArgs = {
  data: TournamentDisqualificationCreateInput;
};

export type MutationUpdateTournamentDisqualificationArgs = {
  id: Scalars['Int'];
  data: TournamentDisqualificationUpdateInput;
};

export type MutationDeleteTournamentDisqualificationArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentGroupArgs = {
  data: TournamentGroupCreateInput;
};

export type MutationUpdateTournamentGroupArgs = {
  id: Scalars['Int'];
  data: TournamentGroupUpdateInput;
};

export type MutationDeleteTournamentGroupArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentMatchArgs = {
  data: TournamentMatchCreateInput;
};

export type MutationUpdateTournamentMatchArgs = {
  id: Scalars['Int'];
  data: TournamentMatchUpdateInput;
};

export type MutationDeleteTournamentMatchArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentMatchFieldArgs = {
  data: CreateExtensionFieldInput;
};

export type MutationUpdateTournamentMatchFieldArgs = {
  id: Scalars['Int'];
  data: UpdateExtensionFieldInput;
};

export type MutationDeleteTournamentMatchFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentMatchEventArgs = {
  data: TournamentMatchEventCreateInput;
};

export type MutationUpdateTournamentMatchEventArgs = {
  id: Scalars['Int'];
  data: TournamentMatchEventUpdateInput;
};

export type MutationDeleteTournamentMatchEventArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentMatchLineUpArgs = {
  data: TournamentMatchLineUpCreateInput;
};

export type MutationUpdateTournamentMatchLineUpArgs = {
  id: Scalars['Int'];
  data: TournamentMatchLineUpUpdateInput;
};

export type MutationDeleteTournamentMatchLineUpArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentMatchProtocolArgs = {
  data: TournamentMatchProtocolCreateInput;
};

export type MutationUpdateTournamentMatchProtocolArgs = {
  id: Scalars['Int'];
  data: TournamentMatchProtocolUpdateInput;
};

export type MutationDeleteTournamentMatchProtocolArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentMatchStatArgs = {
  data: TournamentMatchStatCreateInput;
};

export type MutationUpdateTournamentMatchStatArgs = {
  id: Scalars['Int'];
  data: TournamentMatchStatUpdateInput;
};

export type MutationDeleteTournamentMatchStatArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentPlayerStatArgs = {
  data: TournamentPlayerStatCreateInput;
};

export type MutationUpdateTournamentPlayerStatArgs = {
  id: Scalars['Int'];
  data: TournamentPlayerStatUpdateInput;
};

export type MutationDeleteTournamentPlayerStatArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentRefereeArgs = {
  data: TournamentRefereeCreateInput;
};

export type MutationUpdateTournamentRefereeArgs = {
  id: Scalars['Int'];
  data: TournamentRefereeUpdateInput;
};

export type MutationDeleteTournamentRefereeArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentSeasonArgs = {
  data: TournamentSeasonCreateInput;
};

export type MutationUpdateTournamentSeasonArgs = {
  id: Scalars['Int'];
  data: TournamentSeasonUpdateInput;
};

export type MutationDeleteTournamentSeasonArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentSeasonApplicationFormArgs = {
  data: TournamentSeasonApplicationFormCreateInput;
};

export type MutationUpdateTournamentSeasonApplicationFormArgs = {
  id: Scalars['Int'];
  data: TournamentSeasonApplicationFormUpdateInput;
};

export type MutationDeleteTournamentSeasonApplicationFormArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentSeasonTeamArgs = {
  data: TournamentSeasonTeamCreateInput;
};

export type MutationUpdateTournamentSeasonTeamArgs = {
  id: Scalars['Int'];
  data: TournamentSeasonTeamUpdateInput;
};

export type MutationDeleteTournamentSeasonTeamArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentSeasonTeamMemberArgs = {
  data: TournamentSeasonTeamMemberCreateInput;
};

export type MutationUpdateTournamentSeasonTeamMemberArgs = {
  id: Scalars['Int'];
  data: TournamentSeasonTeamMemberUpdateInput;
};

export type MutationDeleteTournamentSeasonTeamMemberArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentSeasonTeamStatArgs = {
  data: TournamentSeasonTeamStatCreateInput;
};

export type MutationUpdateTournamentSeasonTeamStatArgs = {
  id: Scalars['Int'];
  data: TournamentSeasonTeamStatUpdateInput;
};

export type MutationDeleteTournamentSeasonTeamStatArgs = {
  id: Scalars['Int'];
};

export type MutationUpdateTournamentSettingArgs = {
  id: Scalars['Int'];
  data: TournamentSettingUpdateInput;
};

export type MutationCreateTournamentStadiumArgs = {
  data: TournamentStadiumCreateInput;
};

export type MutationUpdateTournamentStadiumArgs = {
  id: Scalars['Int'];
  data: TournamentStadiumUpdateInput;
};

export type MutationDeleteTournamentStadiumArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentTeamArgs = {
  data: TournamentTeamCreateInput;
};

export type MutationUpdateTournamentTeamArgs = {
  id: Scalars['Int'];
  data: TournamentTeamUpdateInput;
};

export type MutationDeleteTournamentTeamArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentTeamFieldArgs = {
  data: CreateExtensionFieldInput;
};

export type MutationUpdateTournamentTeamFieldArgs = {
  id: Scalars['Int'];
  data: UpdateExtensionFieldInput;
};

export type MutationDeleteTournamentTeamFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentTeamGroupArgs = {
  data: TournamentTeamGroupCreateInput;
};

export type MutationUpdateTournamentTeamGroupArgs = {
  id: Scalars['Int'];
  data: TournamentTeamGroupUpdateInput;
};

export type MutationDeleteTournamentTeamGroupArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentTeamHistoryArgs = {
  data: TournamentTeamHistoryCreateInput;
};

export type MutationUpdateTournamentTeamHistoryArgs = {
  id: Scalars['Int'];
  data: TournamentTeamHistoryUpdateInput;
};

export type MutationDeleteTournamentTeamHistoryArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentTeamMemberArgs = {
  data: TournamentTeamMemberCreateInput;
};

export type MutationUpdateTournamentTeamMemberArgs = {
  id: Scalars['Int'];
  data: TournamentTeamMemberUpdateInput;
};

export type MutationDeleteTournamentTeamMemberArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentTeamMemberFieldArgs = {
  data: CreateExtensionFieldInput;
};

export type MutationUpdateTournamentTeamMemberFieldArgs = {
  id: Scalars['Int'];
  data: UpdateExtensionFieldInput;
};

export type MutationDeleteTournamentTeamMemberFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentTeamMemberSocialAccountArgs = {
  data: TournamentTeamMemberSocialAccountCreateInput;
};

export type MutationUpdateTournamentTeamMemberSocialAccountArgs = {
  id: Scalars['Int'];
  data: TournamentTeamMemberSocialAccountUpdateInput;
};

export type MutationDeleteTournamentTeamMemberSocialAccountArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentTourArgs = {
  data: TournamentTourCreateInput;
};

export type MutationUpdateTournamentTourArgs = {
  id: Scalars['Int'];
  data: TournamentTourUpdateInput;
};

export type MutationDeleteTournamentTourArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentArgs = {
  data: TournamentCreateInput;
};

export type MutationUpdateTournamentArgs = {
  id: Scalars['Int'];
  data: TournamentUpdateInput;
};

export type MutationDeleteTournamentArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentFieldArgs = {
  data: CreateExtensionFieldInput;
};

export type MutationUpdateTournamentFieldArgs = {
  id: Scalars['Int'];
  data: UpdateExtensionFieldInput;
};

export type MutationDeleteTournamentFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentTranslationArgs = {
  data: TournamentTranslationCreateInput;
};

export type MutationUpdateTournamentTranslationArgs = {
  id: Scalars['Int'];
  data: TournamentTranslationUpdateInput;
};

export type MutationDeleteTournamentTranslationArgs = {
  id: Scalars['Int'];
};

export type MutationImportTournamentMatchDataArgs = {
  type: TournamentMatchDataType;
};

export type MutationSyncTournamentSeasonStatsArgs = {
  seasonId: Scalars['Int'];
};

export type MutationImportTournamentSeasonTeamStatsArgs = {
  data: Array<TournamentSeasonTeamStatCreateInput>;
};

export type MutationSyncTournamentSeasonTeamsArgs = {
  data: Array<TournamentSeasonTeamCreateInput>;
};

export type MutationImportTournamentPlayerStatsArgs = {
  data: Array<TournamentPlayerStatCreateInput>;
};

export type MutationImportTournamentMatchLineupsArgs = {
  matchId: Scalars['Int'];
  data: Array<TournamentMatchLineUpCreateInput>;
};

export type MutationImportTournamentMatchEventsArgs = {
  matchId: Scalars['Int'];
  data: Array<TournamentMatchEventCreateInput>;
  notificate?: Maybe<Scalars['Boolean']>;
};

export type MutationImportTournamentMatchStatsArgs = {
  matchId: Scalars['Int'];
  data: Array<TournamentMatchStatCreateInput>;
};

export type MutationSyncTournamentTourArgs = {
  seasonId: Scalars['Int'];
};

export type MutationCreateUserApplicationLogCommentArgs = {
  applicationId: Scalars['Int'];
  comment: Scalars['String'];
};

export type MutationCreateUserAnalyticDataArgs = {
  data: UserAnalyticDataCreateInput;
};

export type MutationUpdateUserAnalyticDataArgs = {
  id: Scalars['Int'];
  data: UserAnalyticDataUpdateInput;
};

export type MutationCreateUserAnalyticFieldArgs = {
  data: UserAnalyticFieldCreateInput;
};

export type MutationUpdateUserAnalyticFieldArgs = {
  id: Scalars['Int'];
  data: UserAnalyticFieldUpdateInput;
};

export type MutationDeleteUserAnalyticFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateUserApplicationArgs = {
  data: UserApplicationCreateInput;
};

export type MutationUpdateUserApplicationArgs = {
  id: Scalars['Int'];
  data: UserApplicationUpdateInput;
};

export type MutationDeleteUserApplicationArgs = {
  id: Scalars['Int'];
};

export type MutationCreateUserApplicationFieldArgs = {
  data: CreateExtensionFieldInput;
};

export type MutationUpdateUserApplicationFieldArgs = {
  id: Scalars['Int'];
  data: UpdateExtensionFieldInput;
};

export type MutationDeleteUserApplicationFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateUserApplicationTypeArgs = {
  data: UserApplicationTypeCreateInput;
};

export type MutationUpdateUserApplicationTypeArgs = {
  id: Scalars['Int'];
  data: UserApplicationTypeUpdateInput;
};

export type MutationDeleteUserApplicationTypeArgs = {
  id: Scalars['Int'];
};

export type MutationCreateUserDocumentArgs = {
  data: UserDocumentCreateInput;
};

export type MutationUpdateUserDocumentArgs = {
  id: Scalars['Int'];
  data: UserDocumentUpdateInput;
};

export type MutationDeleteUserDocumentArgs = {
  id: Scalars['Int'];
};

export type MutationCreateUserGroupArgs = {
  data: UserGroupCreateInput;
};

export type MutationUpdateUserGroupArgs = {
  id: Scalars['Int'];
  data: UserGroupUpdateInput;
};

export type MutationDeleteUserGroupArgs = {
  id: Scalars['Int'];
};

export type MutationCreateUserKidArgs = {
  data: UserKidCreateInput;
};

export type MutationUpdateUserKidArgs = {
  id: Scalars['Int'];
  data: UserKidUpdateInput;
};

export type MutationDeleteUserKidArgs = {
  id: Scalars['Int'];
};

export type MutationCreateUserSocialAccountArgs = {
  data: UserSocialAccountCreateInput;
};

export type MutationUpdateUserSocialAccountArgs = {
  id: Scalars['Int'];
  data: UserSocialAccountUpdateInput;
};

export type MutationDeleteUserSocialAccountArgs = {
  id: Scalars['Int'];
};

export type MutationCreateUserSubscriberArgs = {
  data: UserSubscriberCreateInput;
};

export type MutationUpdateUserSubscriberArgs = {
  id: Scalars['String'];
  data: UserSubscriberUpdateInput;
};

export type MutationDeleteUserSubscriberArgs = {
  id: Scalars['String'];
};

export type MutationCreateUserSubscribersLogArgs = {
  data: UserSubscribersLogCreateInput;
};

export type MutationUpdateUserSubscribersLogArgs = {
  id: Scalars['Int'];
  data: UserSubscribersLogUpdateInput;
};

export type MutationDeleteUserSubscribersLogArgs = {
  id: Scalars['Int'];
};

export type MutationCreateUserSubscriptionArgs = {
  data: UserSubscriptionCreateInput;
};

export type MutationUpdateUserSubscriptionArgs = {
  id: Scalars['Int'];
  data: UserSubscriptionUpdateInput;
};

export type MutationDeleteUserSubscriptionArgs = {
  id: Scalars['Int'];
};

export type MutationCreateUserFieldArgs = {
  data: CreateExtensionFieldInput;
};

export type MutationUpdateUserFieldArgs = {
  id: Scalars['Int'];
  data: UpdateExtensionFieldInput;
};

export type MutationDeleteUserFieldArgs = {
  id: Scalars['Int'];
};

export type MutationSubscribeToMatchEventsArgs = {
  data: SubscribeToMatchEventsInput;
};

export type MutationValidatePhoneArgs = {
  phone: Scalars['String'];
  code?: Maybe<Scalars['String']>;
};

export type MutationSendPinArgs = {
  phone: Scalars['String'];
  deviceId?: Maybe<Scalars['String']>;
};

export type MutationSendPinByCallArgs = {
  phone: Scalars['String'];
  deviceId?: Maybe<Scalars['String']>;
};

export type MutationValidatePinArgs = {
  phone: Scalars['String'];
  pin: Scalars['String'];
  deviceId: Scalars['ID'];
  expoPushToken?: Maybe<Scalars['String']>;
  coords?: Maybe<CoordsInput>;
};

export type MutationSaveUserSettingsArgs = {
  data: SaveUserSettingsInput;
};

export type MutationAddToUsersSubscriptionArgs = {
  subscriptionId: Scalars['Int'];
  query?: Maybe<DefaultQueryInput>;
};

export type MutationDeleteFromUsersSubscriptionArgs = {
  subscriptionId: Scalars['Int'];
  userId?: Maybe<Array<Scalars['Int']>>;
};

export type MutationCreateUserSubscribersArgs = {
  subscriptionId: Scalars['Int'];
  userId: Array<Scalars['Int']>;
  modifierId?: Maybe<Scalars['Int']>;
};

export type MutationDeleteUserSubscribersArgs = {
  id: Array<Scalars['String']>;
};

export type MutationImportUserSubscribersArgs = {
  subscriptionId: Scalars['Int'];
  file: Scalars['Upload'];
};

export type MutationUnsubscribeUserArgs = {
  userId: Scalars['Int'];
};

export type MutationUnsubscribeUserByEmailArgs = {
  email: Scalars['String'];
};

export type MutationUnsubscribeUserByEmailsArgs = {
  emails: Array<Scalars['String']>;
};

export type MutationAddUsersToGroupArgs = {
  groupId: Array<Scalars['Int']>;
  query: DefaultQueryInput;
};

export type MutationAddUserToGroupArgs = {
  userId: Scalars['Int'];
  groupId: Array<Scalars['Int']>;
};

export type MutationDeleteUserFromGroupArgs = {
  groupId: Array<Scalars['Int']>;
  query: DefaultQueryInput;
};

export type MutationCreateUserArgs = {
  data?: Maybe<UserCreateInput>;
};

export type MutationUpdateUserArgs = {
  id: Scalars['Int'];
  data: UserUpdateInput;
};

export type MutationRegisterUserArgs = {
  data: UserCreateInput;
};

export type MutationRegisterUserSocialArgs = {
  type: UserSocialAccountSocialNetwork;
  data: SocialAuthData;
};

export type MutationLoginUserArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type MutationMasterLoginUserArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type MutationLoginUserSocialArgs = {
  type: UserSocialAccountSocialNetwork;
  data: SocialAuthData;
};

export type MutationUpdateUserAccessTokenArgs = {
  refreshToken: Scalars['String'];
};

export type MutationUpdateUserRefreshTokenArgs = {
  refreshToken: Scalars['String'];
};

export type MutationResetUserPasswordArgs = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  confirmationKey?: Maybe<Scalars['String']>;
};

export type MutationConfirmUserEmailArgs = {
  email: Scalars['String'];
  confirmationKey?: Maybe<Scalars['String']>;
};

export type MutationDeleteUserByIdArgs = {
  id: Scalars['Int'];
};

export type MutationAddUserToCategoryArgs = {
  userId?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['Int']>;
};

export type MutationRemoveUserToCategoryArgs = {
  userId?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['Int']>;
};

export type MutationAddUserDeviceArgs = {
  data: AddUserDeviceInput;
};

export type MutationCreateUserDeviceArgs = {
  data: CreateUserDeviceInput;
};

export type MutationAddUserLocationArgs = {
  data: UserLocationInput;
};

export type MutationCreateUserLocationArgs = {
  data: CreateUserLocationInput;
};

export type MutationImportUserOrdersAndTicketsArgs = {
  id: Scalars['Int'];
};

export type MutationImportUsersArgs = {
  data: ImportInput;
};

export type MutationCreateUserDataFieldArgs = {
  data: UserDataFieldCreateInput;
};

export type MutationUpdateUserDataFieldArgs = {
  id: Scalars['Int'];
  data: UserDataFieldUpdateInput;
};

export type MutationDeleteUserDataFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateUserAnalyticDataBatchArgs = {
  data: Array<UserAnalyticDataCreateInput>;
};

export type MutationUpdateMySubscriptionsArgs = {
  subscriptionId: Array<Scalars['Int']>;
};

export type MutationUpdateUserSubscriptionsArgs = {
  userId: Scalars['Int'];
  subscriptionId: Array<Scalars['Int']>;
};

export type MutationUploadFileToImportUsersArgs = {
  file: Scalars['Upload'];
};

export type MutationExportUsersArgs = {
  query?: Maybe<DefaultQueryInput>;
  settings?: Maybe<ExportSettingsInput>;
};

export type Navigation = {
  __typename?: 'Navigation';
  id: Scalars['Int'];
  parentId: Scalars['Int'];
  name: Scalars['String'];
  url: Scalars['String'];
  icon: Scalars['String'];
  sort: Scalars['Int'];
  children?: Maybe<Array<Navigation>>;
};

export type NewUsersWidget = {
  __typename?: 'NewUsersWidget';
  total: Scalars['Int'];
  graphs: Array<WidgetSummaryGraph>;
};

export enum NotificationTransportEnum {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  PUSH = 'PUSH',
}

export type OrderDataInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type OrderItemInput = {
  catalogueItemId: Scalars['Int'];
  discountId?: Maybe<Scalars['Int']>;
  currencyId?: Maybe<Scalars['Int']>;
  qty: Scalars['Int'];
  price?: Maybe<Scalars['Float']>;
};

export type PaymentAccount = {
  __typename?: 'PaymentAccount';
  id: Scalars['Int'];
  state: PaymentAccountState;
  userId: Scalars['Int'];
  currencyId: Scalars['Int'];
  balance: Scalars['Float'];
  spent: Scalars['Float'];
  providerId?: Maybe<Scalars['Int']>;
  providerCode?: Maybe<Scalars['String']>;
  user: User;
  currency: PaymentCurrency;
  provider?: Maybe<PaymentProvider>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentAccountBalance = {
  __typename?: 'PaymentAccountBalance';
  provider?: Maybe<PaymentProvider>;
  currency: Currency;
  balance: Scalars['Int'];
  providerId: Scalars['Int'];
  currencyId: Scalars['Int'];
};

export type PaymentAccountConnection = DefaultConnection & {
  __typename?: 'PaymentAccountConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<PaymentAccount>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type PaymentAccountCreateInput = {
  state?: Maybe<PaymentAccountState>;
  userId: Scalars['Int'];
  currencyId: Scalars['Int'];
  balance?: Maybe<Scalars['Float']>;
  spent?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['Int']>;
  providerCode?: Maybe<Scalars['String']>;
};

export type PaymentAccountOperationInput = {
  accountId: Scalars['Int'];
  amount: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
};

export enum PaymentAccountState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type PaymentAccountUpdateInput = {
  state?: Maybe<PaymentAccountState>;
  userId?: Maybe<Scalars['Int']>;
  currencyId?: Maybe<Scalars['Int']>;
  balance?: Maybe<Scalars['Float']>;
  spent?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['Int']>;
  providerCode?: Maybe<Scalars['String']>;
};

export type PaymentAccountWhereInput = {
  currencyId: Scalars['Int'];
  providerId: Scalars['Int'];
  providerCode: Scalars['String'];
};

export type PaymentBankCard = {
  __typename?: 'PaymentBankCard';
  id: Scalars['Int'];
  state: PaymentBankCardState;
  userId: Scalars['Int'];
  number: Scalars['String'];
  issuedBy?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['String']>;
  cardHolderName?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentBankCardConnection = DefaultConnection & {
  __typename?: 'PaymentBankCardConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<PaymentBankCard>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type PaymentBankCardCreateInput = {
  state?: Maybe<PaymentBankCardState>;
  userId: Scalars['Int'];
  number: Scalars['String'];
  issuedBy?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['String']>;
  cardHolderName?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

export enum PaymentBankCardState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type PaymentBankCardUpdateInput = {
  state?: Maybe<PaymentBankCardState>;
  userId?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['String']>;
  issuedBy?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['String']>;
  cardHolderName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export enum PaymentBasis {
  UNKNOWN = 'UNKNOWN',
  COMMODITY = 'COMMODITY',
  JOB = 'JOB',
  SERVICE = 'SERVICE',
  PAYMENT = 'PAYMENT',
  COMPOSITE = 'COMPOSITE',
  ANOTHER = 'ANOTHER',
}

export type PaymentChannel = {
  __typename?: 'PaymentChannel';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: PaymentChannelState;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentChannelConnection = DefaultConnection & {
  __typename?: 'PaymentChannelConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<PaymentChannel>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type PaymentChannelCreateInput = {
  state?: Maybe<PaymentChannelState>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export enum PaymentChannelState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type PaymentChannelSummaryWidget = {
  __typename?: 'PaymentChannelSummaryWidget';
  channel: PaymentChannel;
  graphs: Array<WidgetSummaryGraph>;
  sum: Scalars['Float'];
};

export type PaymentChannelUpdateInput = {
  state?: Maybe<PaymentChannelState>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type PaymentCurrency = {
  __typename?: 'PaymentCurrency';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  iso3: Scalars['String'];
  name: Scalars['String'];
  sign: Scalars['String'];
  isDefault: Scalars['Boolean'];
  isVirtual: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentCurrencyConnection = DefaultConnection & {
  __typename?: 'PaymentCurrencyConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<PaymentCurrency>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type PaymentCurrencyCreateInput = {
  iso3: Scalars['String'];
  name: Scalars['String'];
  sign: Scalars['String'];
  isDefault: Scalars['Boolean'];
  isVirtual: Scalars['Boolean'];
};

export type PaymentCurrencySummaryWidget = {
  __typename?: 'PaymentCurrencySummaryWidget';
  currency: PaymentCurrency;
  graphs: Array<WidgetSummaryGraph>;
  sum: Scalars['Float'];
};

export type PaymentCurrencyUpdateInput = {
  iso3?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sign?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isVirtual?: Maybe<Scalars['Boolean']>;
};

export type PaymentDataPsb = {
  __typename?: 'PaymentDataPSB';
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  desc?: Maybe<Scalars['String']>;
  terminal?: Maybe<Scalars['String']>;
  trtype?: Maybe<Scalars['Int']>;
  merch_name?: Maybe<Scalars['String']>;
  merchant?: Maybe<Scalars['BigInt']>;
  email?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['BigInt']>;
  nonce?: Maybe<Scalars['String']>;
  backref?: Maybe<Scalars['String']>;
  notify_url?: Maybe<Scalars['String']>;
  p_sign?: Maybe<Scalars['String']>;
};

export type PaymentGateway = {
  __typename?: 'PaymentGateway';
  id: Scalars['Int'];
  state: PaymentGatewayState;
  clientId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentGatewayConnection = DefaultConnection & {
  __typename?: 'PaymentGatewayConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<PaymentGateway>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type PaymentGatewayCreateInput = {
  state?: Maybe<PaymentGatewayState>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type PaymentGatewayProvider = {
  __typename?: 'PaymentGatewayProvider';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export enum PaymentGatewayState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type PaymentGatewaySummaryWidget = {
  __typename?: 'PaymentGatewaySummaryWidget';
  gateway: PaymentGateway;
  graphs: Array<WidgetSummaryGraph>;
  sum: Scalars['Float'];
};

export type PaymentGatewayUpdateInput = {
  state?: Maybe<PaymentGatewayState>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type PaymentInAppPurchase = {
  __typename?: 'PaymentInAppPurchase';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  productId: Scalars['String'];
  store: PaymentInAppPurchaseStore;
  title: Scalars['String'];
  currencyId?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
  currency: PaymentCurrency;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentInAppPurchaseConnection = DefaultConnection & {
  __typename?: 'PaymentInAppPurchaseConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<PaymentInAppPurchase>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum PaymentInAppPurchaseStore {
  APP_STORE = 'APP_STORE',
  GOOGLE_PLAY = 'GOOGLE_PLAY',
}

export type PaymentInAppTransaction = {
  __typename?: 'PaymentInAppTransaction';
  id: Scalars['Int'];
  state: PaymentInAppTransactionState;
  side: PaymentInAppTransactionSide;
  userId: Scalars['Int'];
  currencyId?: Maybe<Scalars['Int']>;
  paymentTransactionId?: Maybe<Scalars['String']>;
  store: PaymentInAppTransactionStore;
  amount?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  response?: Maybe<Scalars['JSON']>;
  user: User;
  currency: PaymentCurrency;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentInAppTransactionConnection = DefaultConnection & {
  __typename?: 'PaymentInAppTransactionConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<PaymentInAppTransaction>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum PaymentInAppTransactionSide {
  CHARGE = 'CHARGE',
  DEPOSIT = 'DEPOSIT',
}

export enum PaymentInAppTransactionState {
  AUTH = 'AUTH',
  HOLD = 'HOLD',
  PROCESSED = 'PROCESSED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}

export enum PaymentInAppTransactionStore {
  APP_STORE = 'APP_STORE',
  GOOGLE_PLAY = 'GOOGLE_PLAY',
}

export type PaymentInfo = {
  __typename?: 'PaymentInfo';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export enum PaymentMethod {
  UNKNOWN = 'UNKNOWN',
  FULL_PREPAYMENT = 'FULL_PREPAYMENT',
  PARTIAL_PREPAYMENT = 'PARTIAL_PREPAYMENT',
  ADVANCE = 'ADVANCE',
  FULL = 'FULL',
  PARTIAL_PAY_AND_CREDIT = 'PARTIAL_PAY_AND_CREDIT',
  CREDIT = 'CREDIT',
  CREDIT_PAYMENT = 'CREDIT_PAYMENT',
}

export type PaymentProvider = {
  __typename?: 'PaymentProvider';
  id: Scalars['Int'];
  gatewayId: Scalars['Int'];
  clientId: Scalars['Int'];
  state: PaymentProviderState;
  name: Scalars['String'];
  settings?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentProviderConnection = DefaultConnection & {
  __typename?: 'PaymentProviderConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<PaymentProvider>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type PaymentProviderCreateInput = {
  gatewayId: Scalars['Int'];
  state?: Maybe<PaymentProviderState>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['JSON']>;
};

export enum PaymentProviderState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type PaymentProviderSummaryWidget = {
  __typename?: 'PaymentProviderSummaryWidget';
  provider: PaymentProvider;
  graphs: Array<WidgetSummaryGraph>;
  sum: Scalars['Float'];
};

export type PaymentProviderUpdateInput = {
  gatewayId?: Maybe<Scalars['Int']>;
  state?: Maybe<PaymentProviderState>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
};

export type PaymentReceiptInput = {
  type: PaymentReceiptType;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  inn?: Maybe<Scalars['String']>;
  taxationSystem?: Maybe<TaxationSystemType>;
  items?: Maybe<Array<PaymentReceiptItemInput>>;
};

export type PaymentReceiptItem = {
  __typename?: 'PaymentReceiptItem';
  taxRate: TaxRateType;
  label: Scalars['String'];
  quantity: Scalars['Int'];
  price: Scalars['Float'];
  totalAmount: Scalars['Float'];
  measureUnit: Scalars['String'];
  paymentMethod: PaymentMethod;
  paymentBasis: PaymentBasis;
};

export type PaymentReceiptItemInput = {
  taxRate: TaxRateType;
  label: Scalars['String'];
  quantity: Scalars['Int'];
  price: Scalars['Float'];
  totalAmount: Scalars['Float'];
  measureUnit: Scalars['String'];
  paymentMethod: PaymentMethod;
  paymentBasis: PaymentBasis;
};

export type PaymentReceiptResponse = {
  __typename?: 'PaymentReceiptResponse';
  type: PaymentReceiptType;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  inn?: Maybe<Scalars['String']>;
  taxationSystem?: Maybe<TaxationSystemType>;
  items?: Maybe<Array<PaymentReceiptItem>>;
};

export enum PaymentReceiptType {
  INCOME = 'INCOME',
  INCOME_RETURN = 'INCOME_RETURN',
  EXPENSE = 'EXPENSE',
  EXPENSE_RETURN = 'EXPENSE_RETURN',
}

export type PaymentResponse = PaymentTransaction | Secure3DResponse;

export type PaymentResult = {
  __typename?: 'PaymentResult';
  amount: Scalars['Float'];
};

export type PaymentTransaction = {
  __typename?: 'PaymentTransaction';
  id: Scalars['Int'];
  state: PaymentTransactionState;
  side: PaymentTransactionSide;
  channelId?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
  accountId: Scalars['Int'];
  currencyId: Scalars['Int'];
  providerId?: Maybe<Scalars['Int']>;
  amount: Scalars['Float'];
  bankCardId?: Maybe<Scalars['Int']>;
  paymentTransactionId?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  orderType?: Maybe<PaymentTransactionOrderType>;
  comment?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  response?: Maybe<Scalars['JSON']>;
  provider?: Maybe<PaymentProvider>;
  user: User;
  currency: PaymentCurrency;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentTransactionConnection = DefaultConnection & {
  __typename?: 'PaymentTransactionConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<PaymentTransaction>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type PaymentTransactionCreateInput = {
  state: PaymentTransactionState;
  side: PaymentTransactionSide;
  channelId?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
  accountId: Scalars['Int'];
  currencyId: Scalars['Int'];
  providerId?: Maybe<Scalars['Int']>;
  amount: Scalars['Float'];
  bankCardId?: Maybe<Scalars['Int']>;
  paymentTransactionId?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  orderType?: Maybe<PaymentTransactionOrderType>;
  comment?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  response?: Maybe<Scalars['JSON']>;
};

export type PaymentTransactionLog = {
  __typename?: 'PaymentTransactionLog';
  id: Scalars['Int'];
  transactionId: Scalars['Int'];
  systemUserId: Scalars['Int'];
  operation: PaymentTransactionLogOperation;
  isSuccess: Scalars['Boolean'];
  amount: Scalars['Float'];
  error?: Maybe<Scalars['JSON']>;
  response?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  paymentCheck?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentTransactionLogConnection = DefaultConnection & {
  __typename?: 'PaymentTransactionLogConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<PaymentTransactionLog>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type PaymentTransactionLogCreateInput = {
  transactionId: Scalars['Int'];
  systemUserId: Scalars['Int'];
  operation: PaymentTransactionLogOperation;
  isSuccess: Scalars['Boolean'];
  amount: Scalars['Float'];
  error?: Maybe<Scalars['JSON']>;
  response?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  paymentCheck?: Maybe<Scalars['JSON']>;
};

export enum PaymentTransactionLogOperation {
  AUTH = 'AUTH',
  CONFIRM_AUTH = 'CONFIRM_AUTH',
  HOLD = 'HOLD',
  CONFIRM_HOLD = 'CONFIRM_HOLD',
  CHARGE = 'CHARGE',
  DEPOSIT = 'DEPOSIT',
  REFUND = 'REFUND',
  CANCEL = 'CANCEL',
}

export type PaymentTransactionLogUpdateInput = {
  transactionId?: Maybe<Scalars['Int']>;
  systemUserId?: Maybe<Scalars['Int']>;
  operation?: Maybe<PaymentTransactionLogOperation>;
  isSuccess?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  error?: Maybe<Scalars['JSON']>;
  response?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  paymentCheck?: Maybe<Scalars['JSON']>;
};

export enum PaymentTransactionOrderType {
  ECOMMERCE = 'ECOMMERCE',
  TICKET = 'TICKET',
}

export enum PaymentTransactionSide {
  CHARGE = 'CHARGE',
  DEPOSIT = 'DEPOSIT',
}

export enum PaymentTransactionState {
  AUTH = 'AUTH',
  HOLD = 'HOLD',
  PROCESSED = 'PROCESSED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}

export type PaymentTransactionUpdateInput = {
  state?: Maybe<PaymentTransactionState>;
  side?: Maybe<PaymentTransactionSide>;
  channelId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  accountId?: Maybe<Scalars['Int']>;
  currencyId?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
  bankCardId?: Maybe<Scalars['Int']>;
  paymentTransactionId?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  orderType?: Maybe<PaymentTransactionOrderType>;
  comment?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  response?: Maybe<Scalars['JSON']>;
};

export type PaymentWebHookInput = {
  providerId: Scalars['Int'];
  data: Scalars['String'];
};

export type PaymentWebHookResponse = {
  __typename?: 'PaymentWebHookResponse';
  status: Scalars['Boolean'];
  data?: Maybe<Scalars['JSON']>;
};

export type PayOrderInput = {
  orderId: Scalars['Int'];
  orderType: PaymentTransactionOrderType;
};

export type Printer = {
  __typename?: 'Printer';
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  isDefault: Scalars['Boolean'];
};

export type Process3DSecureInput = {
  providerId: Scalars['Int'];
  currencyId: Scalars['Int'];
  providerTransactionId: Scalars['String'];
  payload: Scalars['String'];
};

export type ProviderResponse = {
  __typename?: 'ProviderResponse';
  amount: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  apiVersion?: Maybe<Scalars['String']>;
  filters?: Maybe<Array<FilterItem>>;
  widgets?: Maybe<WidgetsResponse>;
  allArticles: ArticlesConnection;
  article?: Maybe<Article>;
  allArticleBlockAuthors?: Maybe<ArticleBlockAuthorsConnection>;
  allEntityRecordsByArticleLinkType: ArticleLinkEntityTypeConnection;
  allArticleArticleTags: ArticleArticleTagConnection;
  articleArticleTags: Array<ArticleArticleTag>;
  articleArticleTag: ArticleArticleTag;
  allArticleAuthors: ArticleAuthorConnection;
  articleAuthors: Array<ArticleAuthor>;
  articleAuthor: ArticleAuthor;
  allArticleBlocks: ArticleBlockConnection;
  articleBlocks: Array<ArticleBlock>;
  articleBlock: ArticleBlock;
  allArticleCategories: ArticleCategoryConnection;
  articleCategories: Array<ArticleCategory>;
  articleCategory: ArticleCategory;
  allArticleLinks: ArticleLinkConnection;
  articleLinks: Array<ArticleLink>;
  articleLink: ArticleLink;
  articleLogs: Array<ArticleLog>;
  allArticleTags: ArticleTagConnection;
  articleTags: Array<ArticleTag>;
  articleTag: ArticleTag;
  catalogueItemsByEvent: Array<CatalogueItem>;
  allCatalogues: CatalogueConnection;
  catalogues: Array<Catalogue>;
  catalogue: Catalogue;
  allCatalogueFields: CatalogueFieldsConnection;
  allCatalogueCategories: CatalogueCategoryConnection;
  catalogueCategories: Array<CatalogueCategory>;
  catalogueCategory: CatalogueCategory;
  allCatalogueCategoryFields: CatalogueCategoryFieldsConnection;
  allCatalogueItems: CatalogueItemConnection;
  catalogueItems: Array<CatalogueItem>;
  catalogueItem: CatalogueItem;
  allCatalogueItemFields: CatalogueItemFieldsConnection;
  allCatalogueItemPrices: CatalogueItemPriceConnection;
  catalogueItemPrices: Array<CatalogueItemPrice>;
  catalogueItemPrice: CatalogueItemPrice;
  allCatalogueRelations: CatalogueRelationConnection;
  catalogueRelations: Array<CatalogueRelation>;
  catalogueRelation: CatalogueRelation;
  catalogueSummaryWidget?: Maybe<Array<CatalogueSummaryWidget>>;
  allCaterings: CateringConnection;
  caterings: Array<Catering>;
  catering: Catering;
  allCommerceOrderDatas: CommerceOrderDataConnection;
  commerceOrderDatas: Array<CommerceOrderData>;
  commerceOrderData: CommerceOrderData;
  allCommerceOrderFields: CommerceOrderFieldConnection;
  commerceOrderFields: Array<CommerceOrderField>;
  commerceOrderField: CommerceOrderField;
  allCommerceOrderFieldTranslations: CommerceOrderFieldTranslationConnection;
  allCommerceOrderItems: CommerceOrderItemConnection;
  commerceOrderItems: Array<CommerceOrderItem>;
  commerceOrderItem: CommerceOrderItem;
  allCommerceOrderLogs: CommerceOrderLogConnection;
  allCommerceSettingses: CommerceSettingsConnection;
  commerceSettingses: Array<CommerceSettings>;
  commerceSettings: CommerceSettings;
  allCommerceShops: CommerceShopConnection;
  commerceShops: Array<CommerceShop>;
  commerceShop: CommerceShop;
  allCommerceTemplates: CommerceTemplateConnection;
  commerceTemplates: Array<CommerceTemplate>;
  commerceTemplate: CommerceTemplate;
  allCommerceOrders: CommerceOrderConnection;
  commerceOrder: CommerceOrder;
  commerceOrderFilters?: Maybe<Array<FilterItem>>;
  commerceSummaryWidget?: Maybe<Array<CommerceSummaryWidget>>;
  commerceOrderStatesWidget?: Maybe<Array<CommerceOrderStatesWidgetData>>;
  allContentTranslations: ContentTranslationConnection;
  contentTranslations: Array<ContentTranslation>;
  contentTranslation: ContentTranslation;
  contentTranslationsUpdatedAt: Scalars['DateTime'];
  disputeSettings: DisputeSettings;
  disputeRatingsTotal: Array<DisputeRatingTotal>;
  myDisputesAndResponses: Array<Dispute>;
  disputeStat: Array<DisputeStat>;
  allDisputes: DisputeConnection;
  dispute: Dispute;
  allDisputeDisputeResponses: DisputeDisputeResponseConnection;
  disputeDisputeResponse: DisputeDisputeResponse;
  allDisputeRatings: DisputeRatingConnection;
  allDisputeRatingBySeasons: DisputeRatingBySeasonConnection;
  allDisputeRatingByTours: DisputeRatingByTourConnection;
  allEventSectorForSale?: Maybe<Array<EventPlaceSector>>;
  allEvents: EventConnection;
  events: Array<Event>;
  event: Event;
  allEventFields: EventFieldsConnection;
  allEventEventAccesses: EventEventAccessConnection;
  eventEventAccesses: Array<EventEventAccess>;
  eventEventAccess: EventEventAccess;
  allEventEventWhitelists: EventEventWhitelistConnection;
  eventEventWhitelists: Array<EventEventWhitelist>;
  eventEventWhitelist: EventEventWhitelist;
  allEventOrders: EventOrderConnection;
  eventOrders: Array<EventOrder>;
  eventOrder: EventOrder;
  allEventOrderFields: EventOrderFieldsConnection;
  allEventOrderTickets: EventOrderTicketConnection;
  eventOrderTickets: Array<EventOrderTicket>;
  eventOrderTicket: EventOrderTicket;
  allEventOrderTicketFields: EventOrderTicketFieldsConnection;
  allEventPlaces: EventPlaceConnection;
  eventPlaces: Array<EventPlace>;
  eventPlace: EventPlace;
  allEventPlaceSectors: EventPlaceSectorConnection;
  eventPlaceSectors: Array<EventPlaceSector>;
  eventPlaceSector: EventPlaceSector;
  allEventReserves: EventReserveConnection;
  eventReserves: Array<EventReserve>;
  eventReserve: EventReserve;
  allEventTypes: EventTypeConnection;
  eventTypes: Array<EventType>;
  eventType: EventType;
  eventWidgetActiveEvents: EventWidgetActiveEvents;
  eventWidgetNextEvent?: Maybe<Event>;
  eventWidgetTicketSales?: Maybe<EventWidgetTicketSales>;
  eventWidgetNearestMatches: Array<EventWidgetNearestMatch>;
  eventWidgetSales: EventWidgetSales;
  eventWidgetEventTypesSales: EventWidgetEventTypesSales;
  unisenderLists: Array<UnisenderList>;
  allLoyaltyPartnerEntities: LoyaltyPartnerEntityConnection;
  applicationFilters?: Maybe<Array<FilterItem>>;
  loyaltyPartnerEntityByProviderCode?: Maybe<Scalars['String']>;
  allLoyaltyApplications: LoyaltyApplicationConnection;
  loyaltyApplications: Array<LoyaltyApplication>;
  loyaltyApplication: LoyaltyApplication;
  allLoyaltyPartners: LoyaltyPartnerConnection;
  loyaltyPartners: Array<LoyaltyPartner>;
  loyaltyPartner: LoyaltyPartner;
  allLoyaltyPromos: LoyaltyPromoConnection;
  loyaltyPromos: Array<LoyaltyPromo>;
  loyaltyPromo: ExtendedLoyaltyPromo;
  allLoyaltyPromoCodes: LoyaltyPromoCodeConnection;
  loyaltyPromoCodes: Array<LoyaltyPromoCode>;
  loyaltyPromoCode: LoyaltyPromoCode;
  loyaltySummaryWidget?: Maybe<Array<LoyaltySummaryWidget>>;
  allMessengerMailings: MessengerMailingConnection;
  messengerMailings: Array<MessengerMailing>;
  messengerMailing: MessengerMailing;
  allMessengerMailingActivities: MessengerMailingActivityConnection;
  messengerMailingActivities: Array<MessengerMailingActivity>;
  messengerMailingActivity: MessengerMailingActivity;
  allMessengerMailingRedirects: MessengerMailingRedirectConnection;
  messengerMailingRedirect: MessengerMailingRedirect;
  allMessengerProviders: MessengerProviderConnection;
  messengerProviders: Array<MessengerProvider>;
  messengerProvider: MessengerProvider;
  allMessengerQueueTasks: MessengerQueueTaskConnection;
  messengerQueueTasks: Array<MessengerQueueTask>;
  messengerQueueTask: MessengerQueueTask;
  allMessengerTemplates: MessengerTemplateConnection;
  messengerTemplates: Array<MessengerTemplate>;
  messengerTemplate: MessengerTemplate;
  allMessengerTemplateProviders: MessengerTemplateProviderConnection;
  messengerTemplateProvider: MessengerTemplateProvider;
  allMessengerTransports: MessengerTransportConnection;
  messengerTransports: Array<MessengerTransport>;
  messengerTransport: MessengerTransport;
  messengerMailingStat: Array<MessengerQueueNotificationStat>;
  messengerMailingChannels: Array<MessengerMailingChannelStat>;
  messengerSetting: MessengerSetting;
  messengerSettings: Array<MessengerSetting>;
  allMessengerQueueNotifications: MessengerQueueNotificationConnection;
  messengerQueueNotifications: Array<MessengerQueueNotification>;
  messengerQueueNotification: MessengerQueueNotification;
  redirectAnalytics: Array<MessengerMailingRedirectAnalytics>;
  allMobileTranslations?: Maybe<Array<MobileTranslation>>;
  mobileTranslation: MobileTranslation;
  mobileTranslationsUpdatedAt: Scalars['DateTime'];
  accountBalance: ProviderResponse;
  allPaymentAccounts: PaymentAccountConnection;
  paymentAccounts: Array<PaymentAccount>;
  paymentAccount: PaymentAccount;
  allPaymentBankCards: PaymentBankCardConnection;
  paymentBankCards: Array<PaymentBankCard>;
  paymentBankCard: PaymentBankCard;
  allPaymentChannels: PaymentChannelConnection;
  paymentChannels: Array<PaymentChannel>;
  paymentChannel: PaymentChannel;
  allPaymentCurrencies: PaymentCurrencyConnection;
  paymentCurrencies: Array<PaymentCurrency>;
  paymentCurrency: PaymentCurrency;
  allPaymentGatewaies: PaymentGatewayConnection;
  paymentGatewaies: Array<PaymentGateway>;
  paymentGateway: PaymentGateway;
  allPaymentInAppPurchases: PaymentInAppPurchaseConnection;
  paymentInAppPurchase: PaymentInAppPurchase;
  allPaymentInAppTransactions: PaymentInAppTransactionConnection;
  paymentInAppTransaction: PaymentInAppTransaction;
  allPaymentProviders: PaymentProviderConnection;
  paymentProviders: Array<PaymentProvider>;
  paymentProvider: PaymentProvider;
  allPaymentTransactions: PaymentTransactionConnection;
  paymentTransactions: Array<PaymentTransaction>;
  paymentTransaction: PaymentTransaction;
  allPaymentTransactionLogs: PaymentTransactionLogConnection;
  paymentTransactionLogs: Array<PaymentTransactionLog>;
  paymentTransactionLog: PaymentTransactionLog;
  paymentTransactionLogByTransactionId: PaymentTransactionLog;
  paymentChannelSummaryWidget: Array<PaymentChannelSummaryWidget>;
  paymentCurrencySummaryWidget: Array<PaymentCurrencySummaryWidget>;
  paymentProviderSummaryWidget: Array<PaymentProviderSummaryWidget>;
  paymentGatewaySummaryWidget: Array<PaymentGatewaySummaryWidget>;
  allQuizAttempts: QuizAttemptConnection;
  quizAttempts: Array<QuizAttempt>;
  quizAttempt: QuizAttempt;
  allQuizAttemptAnswers: QuizAttemptAnswerConnection;
  quizAttemptAnswers: Array<QuizAttemptAnswer>;
  quizAttemptAnswer: QuizAttemptAnswer;
  allQuizQuestions: QuizQuestionConnection;
  quizQuestions: Array<QuizQuestion>;
  quizQuestion: QuizQuestion;
  allQuizQuestionAnswers: QuizQuestionAnswerConnection;
  quizQuestionAnswers: Array<QuizQuestionAnswer>;
  quizQuestionAnswer: QuizQuestionAnswer;
  allQuizes: QuizConnection;
  quizes: Array<Quiz>;
  quiz: Quiz;
  allSocialNetworks: SocialNetworkConnection;
  socialNetworks: Array<SocialNetwork>;
  socialNetwork: SocialNetwork;
  allSocialNewsSettings: SocialNewsSettingConnection;
  socialNewsSettings: Array<SocialNewsSetting>;
  socialNewsSetting: SocialNewsSetting;
  clientPublicProfile?: Maybe<ClientPublicProfile>;
  allSystemAsyncTasks: SystemAsyncTaskConnection;
  systemAsyncTask: SystemAsyncTask;
  systemCountries: Array<SystemCountry>;
  allSystemPrinters: SystemPrinterConnection;
  systemPrinters: Array<SystemPrinter>;
  systemPrinter: SystemPrinter;
  allSystemTasks: SystemTaskConnection;
  systemTasks: Array<SystemTask>;
  systemTask: SystemTask;
  allSystemTranslations: SystemTranslationConnection;
  systemTranslations: Array<SystemTranslation>;
  systemTranslation: SystemTranslation;
  allSystemWebHooks: SystemWebHookConnection;
  systemWebHooks: Array<SystemWebHook>;
  systemWebHook: SystemWebHook;
  allSystemLocales: SystemLocaleConnection;
  systemLocales: Array<SystemLocale>;
  systemLocale: SystemLocale;
  getConstants: Constants;
  allSystemConstants: Array<Constant>;
  allPrinters: Array<Printer>;
  systemTranslationsUpdatedAt: Scalars['DateTime'];
  allSystemUsers: SystemUsersConnection;
  allSystemUserGroups: SystemUserGroupsConnection;
  systemUser: SystemUser;
  acl: Array<Acl>;
  navigation: Array<Navigation>;
  systemUserACL: Array<Acl>;
  systemUserProfile: SystemUser;
  systemUserSettings: UserSettings;
  allTemplateApplePasses: TemplateApplePassConnection;
  templateApplePasses: Array<TemplateApplePass>;
  templateApplePass: TemplateApplePass;
  allTemplateNotifications: TemplateNotificationConnection;
  templateNotifications: Array<TemplateNotification>;
  templateNotification: TemplateNotification;
  allTemplatePDFs: TemplatePdfConnection;
  templatePDFs: Array<TemplatePdf>;
  templatePDF: TemplatePdf;
  allTemplateProviders: TemplateProviderConnection;
  templateProvider: TemplateProvider;
  allTournamentDisputes: TournamentDisputeConnection;
  tournamentDisputes: Array<TournamentDispute>;
  tournamentDispute: TournamentDispute;
  allTournamentDisputeResponses: TournamentDisputeResponseConnection;
  tournamentDisputeResponses: Array<TournamentDisputeResponse>;
  tournamentDisputeResponse: TournamentDisputeResponse;
  allTournamentDisqualifications: TournamentDisqualificationConnection;
  tournamentDisqualification: TournamentDisqualification;
  allTournamentGroups: TournamentGroupConnection;
  tournamentGroups: Array<TournamentGroup>;
  tournamentGroup: TournamentGroup;
  allTournamentMatches: TournamentMatchConnection;
  tournamentMatches: Array<TournamentMatch>;
  tournamentMatch: TournamentMatch;
  allTournamentMatchFields: TournamentMatchFieldsConnection;
  allTournamentMatchEvents: TournamentMatchEventConnection;
  tournamentMatchEvent: TournamentMatchEvent;
  tournamentMatchLineUps: Array<TournamentMatchLineUp>;
  allTournamentMatchProtocols: TournamentMatchProtocolConnection;
  tournamentMatchProtocols: Array<TournamentMatchProtocol>;
  tournamentMatchProtocol: TournamentMatchProtocol;
  allTournamentMatchStats: TournamentMatchStatConnection;
  tournamentMatchStats: Array<TournamentMatchStat>;
  tournamentMatchStat: TournamentMatchStat;
  allTournamentPlayerStats: TournamentPlayerStatConnection;
  tournamentPlayerStats: Array<TournamentPlayerStat>;
  tournamentPlayerStat: TournamentPlayerStat;
  allTournamentReferees: TournamentRefereeConnection;
  allTournamentSeasons: TournamentSeasonConnection;
  tournamentSeason: TournamentSeason;
  allTournamentSeasonApplicationForms: TournamentSeasonApplicationFormConnection;
  tournamentSeasonApplicationForms: Array<TournamentSeasonApplicationForm>;
  tournamentSeasonApplicationForm: TournamentSeasonApplicationForm;
  allTournamentSeasonTeams: TournamentSeasonTeamConnection;
  tournamentSeasonTeams: Array<TournamentSeasonTeam>;
  tournamentSeasonTeam: TournamentSeasonTeam;
  allTournamentSeasonTeamMembers: TournamentSeasonTeamMemberConnection;
  tournamentSeasonTeamMembers: Array<TournamentSeasonTeamMember>;
  tournamentSeasonTeamMember: TournamentSeasonTeamMember;
  allTournamentSeasonTeamStats: TournamentSeasonTeamStatConnection;
  tournamentSeasonTeamStats: Array<TournamentSeasonTeamStat>;
  tournamentSeasonTeamStat: TournamentSeasonTeamStat;
  allTournamentStadiums: TournamentStadiumConnection;
  tournamentStadiums: Array<TournamentStadium>;
  tournamentStadium: TournamentStadium;
  allTournamentTeams: TournamentTeamConnection;
  tournamentTeams: Array<TournamentTeam>;
  tournamentTeam: TournamentTeam;
  allTournamentTeamFields: TournamentTeamFieldsConnection;
  allTournamentTeamGroups: TournamentTeamGroupConnection;
  tournamentTeamGroups: Array<TournamentTeamGroup>;
  tournamentTeamGroup: TournamentTeamGroup;
  allTournamentTeamHistories: TournamentTeamHistoryConnection;
  tournamentTeamHistories: Array<TournamentTeamHistory>;
  tournamentTeamHistory: TournamentTeamHistory;
  allTournamentTeamMembers: TournamentTeamMemberConnection;
  tournamentTeamMembers: Array<TournamentTeamMember>;
  tournamentTeamMember: TournamentTeamMember;
  allTournamentTeamMemberFields: TournamentTeamMemberFieldsConnection;
  allTournamentTeamMemberSocialAccounts: TournamentTeamMemberSocialAccountConnection;
  tournamentTeamMemberSocialAccounts: Array<TournamentTeamMemberSocialAccount>;
  tournamentTeamMemberSocialAccount: TournamentTeamMemberSocialAccount;
  allTournamentTours: TournamentTourConnection;
  tournamentTours: Array<TournamentTour>;
  tournamentTour: TournamentTour;
  allTournaments: TournamentConnection;
  tournaments: Array<Tournament>;
  tournament: Tournament;
  allTournamentFields: TournamentFieldsConnection;
  allTournamentTranslations: TournamentTranslationConnection;
  tournamentTranslations: Array<TournamentTranslation>;
  tournamentTranslation: TournamentTranslation;
  tournamentMatchesByStartTime: Array<TournamentMatch>;
  tournamentSettingByTournamentId: TournamentSetting;
  tournamentExtensionData: Array<ExtensionData>;
  userApplicationStats: Array<UserApplicationStats>;
  userApplicationLogs: Array<UserApplicationLog>;
  allUserAnalyticFields: UserAnalyticFieldConnection;
  userAnalyticFields: Array<UserAnalyticField>;
  userAnalyticField: UserAnalyticField;
  allUserApplications: UserApplicationConnection;
  userApplications: Array<UserApplication>;
  userApplication: UserApplication;
  allUserApplicationFields: UserApplicationFieldsConnection;
  allUserApplicationTypes: UserApplicationTypeConnection;
  userApplicationTypes: Array<UserApplicationType>;
  userApplicationType: UserApplicationType;
  allUserDocuments: UserDocumentConnection;
  userDocuments: Array<UserDocument>;
  userDocument: UserDocument;
  allUserGroups: UserGroupConnection;
  userGroups: Array<UserGroup>;
  userGroup: UserGroup;
  allUserKids: UserKidConnection;
  userKids: Array<UserKid>;
  userKid: UserKid;
  allUserMatchEventSubscribers: UserMatchEventSubscriberConnection;
  allUserSocialAccounts: UserSocialAccountConnection;
  userSocialAccounts: Array<UserSocialAccount>;
  userSocialAccount: UserSocialAccount;
  allUserSubscribers: UserSubscriberConnection;
  userSubscribers: Array<UserSubscriber>;
  userSubscriber: UserSubscriber;
  allUserSubscribersLogs: UserSubscribersLogConnection;
  userSubscribersLogs: Array<UserSubscribersLog>;
  userSubscribersLog: UserSubscribersLog;
  allUserSubscriptions: UserSubscriptionConnection;
  userSubscriptions: Array<UserSubscription>;
  userSubscription: UserSubscription;
  allUserFields: UserFieldsConnection;
  myMatchEventSubscriptions: MatchEventSubscription;
  userSettings?: Maybe<UserSetting>;
  userProfile: User;
  allUsers?: Maybe<UserConnection>;
  users?: Maybe<UsersConnection>;
  user: User;
  allUserDevices: UserDeviceConnection;
  userDevicesByUserId: Array<UserDevice>;
  userLocationsByUserId: Array<UserLocation>;
  userDataFields: Array<UserDataField>;
  checkUser?: Maybe<User>;
  newUsersWidget: NewUsersWidget;
};

export type QueryFiltersArgs = {
  queryName: Scalars['String'];
};

export type QueryAllArticlesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleArgs = {
  id: Scalars['Int'];
};

export type QueryAllArticleBlockAuthorsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllEntityRecordsByArticleLinkTypeArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllArticleArticleTagsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleArticleTagsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleArticleTagArgs = {
  id: Scalars['Int'];
};

export type QueryAllArticleAuthorsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleAuthorsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleAuthorArgs = {
  id: Scalars['Int'];
};

export type QueryAllArticleBlocksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleBlocksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleBlockArgs = {
  id: Scalars['Int'];
};

export type QueryAllArticleCategoriesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleCategoriesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleCategoryArgs = {
  id: Scalars['Int'];
};

export type QueryAllArticleLinksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleLinksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleLinkArgs = {
  id: Scalars['Int'];
};

export type QueryArticleLogsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllArticleTagsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleTagsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleTagArgs = {
  id: Scalars['Int'];
};

export type QueryCatalogueItemsByEventArgs = {
  eventId: Scalars['Int'];
};

export type QueryAllCataloguesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCataloguesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCatalogueArgs = {
  id: Scalars['Int'];
};

export type QueryAllCatalogueFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllCatalogueCategoriesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCatalogueCategoriesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCatalogueCategoryArgs = {
  id: Scalars['Int'];
};

export type QueryAllCatalogueCategoryFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllCatalogueItemsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCatalogueItemsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCatalogueItemArgs = {
  id: Scalars['Int'];
};

export type QueryAllCatalogueItemFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllCatalogueItemPricesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCatalogueItemPricesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCatalogueItemPriceArgs = {
  id: Scalars['Int'];
};

export type QueryAllCatalogueRelationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCatalogueRelationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCatalogueRelationArgs = {
  id: Scalars['Int'];
};

export type QueryCatalogueSummaryWidgetArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllCateringsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCateringsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCateringArgs = {
  id: Scalars['Int'];
};

export type QueryAllCommerceOrderDatasArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceOrderDatasArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceOrderDataArgs = {
  id: Scalars['Int'];
};

export type QueryAllCommerceOrderFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceOrderFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceOrderFieldArgs = {
  id: Scalars['Int'];
};

export type QueryAllCommerceOrderFieldTranslationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllCommerceOrderItemsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceOrderItemsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceOrderItemArgs = {
  id: Scalars['Int'];
};

export type QueryAllCommerceOrderLogsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllCommerceSettingsesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceSettingsesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceSettingsArgs = {
  id: Scalars['Int'];
};

export type QueryAllCommerceShopsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceShopsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceShopArgs = {
  id: Scalars['Int'];
};

export type QueryAllCommerceTemplatesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceTemplatesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceTemplateArgs = {
  id: Scalars['Int'];
};

export type QueryAllCommerceOrdersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceOrderArgs = {
  id: Scalars['Int'];
};

export type QueryCommerceOrderFiltersArgs = {
  shopId: Scalars['Int'];
};

export type QueryCommerceSummaryWidgetArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceOrderStatesWidgetArgs = {
  shopId: Scalars['Int'];
};

export type QueryAllContentTranslationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryContentTranslationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryContentTranslationArgs = {
  id: Scalars['Int'];
};

export type QueryContentTranslationsUpdatedAtArgs = {
  localeId: Scalars['Int'];
  channel?: Maybe<Scalars['String']>;
};

export type QueryDisputeRatingsTotalArgs = {
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['Int']>;
  type?: Maybe<RatingType>;
};

export type QueryMyDisputesAndResponsesArgs = {
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['Int']>;
};

export type QueryDisputeStatArgs = {
  userId: Scalars['Int'];
  states?: Maybe<Array<Scalars['String']>>;
};

export type QueryAllDisputesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryDisputeArgs = {
  id: Scalars['Int'];
};

export type QueryAllDisputeDisputeResponsesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryDisputeDisputeResponseArgs = {
  id: Scalars['Int'];
};

export type QueryAllDisputeRatingsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllDisputeRatingBySeasonsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllDisputeRatingByToursArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllEventSectorForSaleArgs = {
  eventId: Scalars['Int'];
};

export type QueryAllEventsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventArgs = {
  id: Scalars['Int'];
};

export type QueryAllEventFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllEventEventAccessesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventEventAccessesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventEventAccessArgs = {
  id: Scalars['Int'];
};

export type QueryAllEventEventWhitelistsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventEventWhitelistsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventEventWhitelistArgs = {
  id: Scalars['Int'];
};

export type QueryAllEventOrdersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventOrdersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventOrderArgs = {
  id: Scalars['Int'];
};

export type QueryAllEventOrderFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllEventOrderTicketsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventOrderTicketsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventOrderTicketArgs = {
  id: Scalars['Int'];
};

export type QueryAllEventOrderTicketFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllEventPlacesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventPlacesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventPlaceArgs = {
  id: Scalars['Int'];
};

export type QueryAllEventPlaceSectorsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventPlaceSectorsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventPlaceSectorArgs = {
  id: Scalars['Int'];
};

export type QueryAllEventReservesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventReservesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventReserveArgs = {
  id: Scalars['Int'];
};

export type QueryAllEventTypesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventTypesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventTypeArgs = {
  id: Scalars['Int'];
};

export type QueryEventWidgetTicketSalesArgs = {
  query?: Maybe<EventWidgetTicketSalesInput>;
};

export type QueryEventWidgetNearestMatchesArgs = {
  placeId?: Maybe<Scalars['Int']>;
};

export type QueryEventWidgetSalesArgs = {
  placeId?: Maybe<Scalars['Int']>;
};

export type QueryEventWidgetEventTypesSalesArgs = {
  placeId?: Maybe<Scalars['Int']>;
  dates?: Maybe<Array<Scalars['String']>>;
};

export type QueryAllLoyaltyPartnerEntitiesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryApplicationFiltersArgs = {
  partnerId: Scalars['Int'];
};

export type QueryLoyaltyPartnerEntityByProviderCodeArgs = {
  providerId?: Maybe<Scalars['Int']>;
  providerCode?: Maybe<Scalars['String']>;
};

export type QueryAllLoyaltyApplicationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryLoyaltyApplicationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryLoyaltyApplicationArgs = {
  id: Scalars['Int'];
};

export type QueryAllLoyaltyPartnersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryLoyaltyPartnersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryLoyaltyPartnerArgs = {
  id: Scalars['Int'];
};

export type QueryAllLoyaltyPromosArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryLoyaltyPromosArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryLoyaltyPromoArgs = {
  id: Scalars['Int'];
};

export type QueryAllLoyaltyPromoCodesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryLoyaltyPromoCodesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryLoyaltyPromoCodeArgs = {
  id: Scalars['Int'];
};

export type QueryLoyaltySummaryWidgetArgs = {
  where?: Maybe<LoyaltySummaryWidgetInput>;
};

export type QueryAllMessengerMailingsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerMailingsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerMailingArgs = {
  id: Scalars['Int'];
};

export type QueryAllMessengerMailingActivitiesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerMailingActivitiesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerMailingActivityArgs = {
  id: Scalars['Int'];
};

export type QueryAllMessengerMailingRedirectsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerMailingRedirectArgs = {
  id: Scalars['Int'];
};

export type QueryAllMessengerProvidersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerProvidersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerProviderArgs = {
  id: Scalars['Int'];
};

export type QueryAllMessengerQueueTasksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerQueueTasksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerQueueTaskArgs = {
  id: Scalars['Int'];
};

export type QueryAllMessengerTemplatesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerTemplatesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerTemplateArgs = {
  id: Scalars['Int'];
};

export type QueryAllMessengerTemplateProvidersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerTemplateProviderArgs = {
  id: Scalars['Int'];
};

export type QueryAllMessengerTransportsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerTransportsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerTransportArgs = {
  id: Scalars['Int'];
};

export type QueryMessengerMailingStatArgs = {
  mailingId?: Maybe<Scalars['Int']>;
};

export type QueryAllMessengerQueueNotificationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerQueueNotificationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerQueueNotificationArgs = {
  id: Scalars['String'];
};

export type QueryRedirectAnalyticsArgs = {
  data: RedirectAnalyticsInput;
};

export type QueryAllMobileTranslationsArgs = {
  locale: Scalars['String'];
};

export type QueryMobileTranslationArgs = {
  id: Scalars['Int'];
};

export type QueryMobileTranslationsUpdatedAtArgs = {
  locale: Scalars['String'];
};

export type QueryAccountBalanceArgs = {
  accountId: Scalars['Int'];
};

export type QueryAllPaymentAccountsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentAccountsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentAccountArgs = {
  id: Scalars['Int'];
};

export type QueryAllPaymentBankCardsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentBankCardsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentBankCardArgs = {
  id: Scalars['Int'];
};

export type QueryAllPaymentChannelsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentChannelsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentChannelArgs = {
  id: Scalars['Int'];
};

export type QueryAllPaymentCurrenciesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentCurrenciesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentCurrencyArgs = {
  id: Scalars['Int'];
};

export type QueryAllPaymentGatewaiesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentGatewaiesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentGatewayArgs = {
  id: Scalars['Int'];
};

export type QueryAllPaymentInAppPurchasesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentInAppPurchaseArgs = {
  id: Scalars['Int'];
};

export type QueryAllPaymentInAppTransactionsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentInAppTransactionArgs = {
  id: Scalars['Int'];
};

export type QueryAllPaymentProvidersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentProvidersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentProviderArgs = {
  id: Scalars['Int'];
};

export type QueryAllPaymentTransactionsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentTransactionsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentTransactionArgs = {
  id: Scalars['Int'];
};

export type QueryAllPaymentTransactionLogsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentTransactionLogsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentTransactionLogArgs = {
  id: Scalars['Int'];
};

export type QueryPaymentTransactionLogByTransactionIdArgs = {
  transactionId: Scalars['Int'];
};

export type QueryAllQuizAttemptsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryQuizAttemptsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryQuizAttemptArgs = {
  id: Scalars['Int'];
};

export type QueryAllQuizAttemptAnswersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryQuizAttemptAnswersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryQuizAttemptAnswerArgs = {
  id: Scalars['Int'];
};

export type QueryAllQuizQuestionsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryQuizQuestionsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryQuizQuestionArgs = {
  id: Scalars['Int'];
};

export type QueryAllQuizQuestionAnswersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryQuizQuestionAnswersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryQuizQuestionAnswerArgs = {
  id: Scalars['Int'];
};

export type QueryAllQuizesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryQuizesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryQuizArgs = {
  id: Scalars['Int'];
};

export type QueryAllSocialNetworksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySocialNetworksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySocialNetworkArgs = {
  id: Scalars['Int'];
};

export type QueryAllSocialNewsSettingsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySocialNewsSettingsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySocialNewsSettingArgs = {
  id: Scalars['Int'];
};

export type QueryClientPublicProfileArgs = {
  name?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
};

export type QueryAllSystemAsyncTasksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySystemAsyncTaskArgs = {
  id: Scalars['Int'];
};

export type QuerySystemCountriesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllSystemPrintersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySystemPrintersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySystemPrinterArgs = {
  id: Scalars['Int'];
};

export type QueryAllSystemTasksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySystemTasksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySystemTaskArgs = {
  id: Scalars['UUID'];
};

export type QueryAllSystemTranslationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySystemTranslationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySystemTranslationArgs = {
  id: Scalars['Int'];
};

export type QueryAllSystemWebHooksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySystemWebHooksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySystemWebHookArgs = {
  id: Scalars['Int'];
};

export type QueryAllSystemLocalesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySystemLocalesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySystemLocaleArgs = {
  id: Scalars['Int'];
};

export type QuerySystemTranslationsUpdatedAtArgs = {
  locale: Scalars['String'];
};

export type QueryAllSystemUsersArgs = {
  include?: Maybe<SystemUsersWhereInput>;
  sort?: Maybe<Array<DefaultSortInput>>;
  search?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryAllSystemUserGroupsArgs = {
  where?: Maybe<SystemUsersWhereInput>;
  sort?: Maybe<Array<DefaultSortInput>>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
};

export type QuerySystemUserArgs = {
  id: Scalars['ID'];
};

export type QuerySystemUserAclArgs = {
  userId: Scalars['ID'];
  clientId: Scalars['ID'];
};

export type QueryAllTemplateApplePassesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTemplateApplePassesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTemplateApplePassArgs = {
  id: Scalars['Int'];
};

export type QueryAllTemplateNotificationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTemplateNotificationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTemplateNotificationArgs = {
  id: Scalars['Int'];
};

export type QueryAllTemplatePdFsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTemplatePdFsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTemplatePdfArgs = {
  id: Scalars['Int'];
};

export type QueryAllTemplateProvidersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTemplateProviderArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentDisputesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentDisputesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentDisputeArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentDisputeResponsesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentDisputeResponsesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentDisputeResponseArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentDisqualificationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentDisqualificationArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentGroupsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentGroupsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentGroupArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentMatchesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentMatchesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentMatchArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentMatchFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllTournamentMatchEventsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentMatchEventArgs = {
  id: Scalars['Int'];
};

export type QueryTournamentMatchLineUpsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllTournamentMatchProtocolsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentMatchProtocolsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentMatchProtocolArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentMatchStatsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentMatchStatsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentMatchStatArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentPlayerStatsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentPlayerStatsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentPlayerStatArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentRefereesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllTournamentSeasonsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentSeasonArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentSeasonApplicationFormsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentSeasonApplicationFormsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentSeasonApplicationFormArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentSeasonTeamsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentSeasonTeamsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentSeasonTeamArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentSeasonTeamMembersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentSeasonTeamMembersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentSeasonTeamMemberArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentSeasonTeamStatsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentSeasonTeamStatsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentSeasonTeamStatArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentStadiumsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentStadiumsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentStadiumArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentTeamsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTeamsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTeamArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentTeamFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllTournamentTeamGroupsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTeamGroupsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTeamGroupArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentTeamHistoriesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTeamHistoriesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTeamHistoryArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentTeamMembersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTeamMembersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTeamMemberArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentTeamMemberFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllTournamentTeamMemberSocialAccountsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTeamMemberSocialAccountsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTeamMemberSocialAccountArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentToursArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentToursArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTourArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllTournamentTranslationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTranslationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTranslationArgs = {
  id: Scalars['Int'];
};

export type QueryTournamentMatchesByStartTimeArgs = {
  seasonId: Scalars['Int'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type QueryTournamentSettingByTournamentIdArgs = {
  tournamentId: Scalars['Int'];
};

export type QueryTournamentExtensionDataArgs = {
  instanceType: TournamentInstanceType;
  fieldId?: Maybe<Scalars['Int']>;
  instanceId?: Maybe<Scalars['Int']>;
};

export type QueryUserApplicationLogsArgs = {
  applicationId: Scalars['Int'];
};

export type QueryAllUserAnalyticFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserAnalyticFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserAnalyticFieldArgs = {
  id: Scalars['Int'];
};

export type QueryAllUserApplicationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserApplicationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserApplicationArgs = {
  id: Scalars['Int'];
};

export type QueryAllUserApplicationFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllUserApplicationTypesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserApplicationTypesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserApplicationTypeArgs = {
  id: Scalars['Int'];
};

export type QueryAllUserDocumentsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserDocumentsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserDocumentArgs = {
  id: Scalars['Int'];
};

export type QueryAllUserGroupsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserGroupsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserGroupArgs = {
  id: Scalars['Int'];
};

export type QueryAllUserKidsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserKidsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserKidArgs = {
  id: Scalars['Int'];
};

export type QueryAllUserMatchEventSubscribersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllUserSocialAccountsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserSocialAccountsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserSocialAccountArgs = {
  id: Scalars['Int'];
};

export type QueryAllUserSubscribersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserSubscribersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserSubscriberArgs = {
  id: Scalars['String'];
};

export type QueryAllUserSubscribersLogsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserSubscribersLogsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserSubscribersLogArgs = {
  id: Scalars['Int'];
};

export type QueryAllUserSubscriptionsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserSubscriptionsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserSubscriptionArgs = {
  id: Scalars['Int'];
};

export type QueryAllUserFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMyMatchEventSubscriptionsArgs = {
  deviceId: Scalars['Int'];
};

export type QueryAllUsersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUsersArgs = {
  where?: Maybe<UserWhereInput>;
  sort?: Maybe<DefaultSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryUserArgs = {
  id: Scalars['Int'];
};

export type QueryAllUserDevicesArgs = {
  search?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  include?: Maybe<UserDeviceWhereInput>;
  exclude?: Maybe<UserDeviceWhereInput>;
  ranges?: Maybe<Array<Scalars['String']>>;
  sort?: Maybe<Array<DefaultSortInput>>;
};

export type QueryUserDevicesByUserIdArgs = {
  userId: Scalars['Int'];
};

export type QueryUserLocationsByUserIdArgs = {
  userId: Scalars['Int'];
};

export type QueryCheckUserArgs = {
  data: CheckUserInput;
};

export type QueryNewUsersWidgetArgs = {
  dates?: Maybe<Array<Scalars['String']>>;
};

export type Quiz = {
  __typename?: 'Quiz';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  autoCreated?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  coverImageUrl?: Maybe<Scalars['String']>;
  maxAttempts: Scalars['Int'];
  startedAt?: Maybe<Scalars['DateTime']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  type: QuizType;
  state: QuizState;
  questions: Array<QuizQuestion>;
  attempts: Array<QuizAttempt>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type QuizAttempt = {
  __typename?: 'QuizAttempt';
  id: Scalars['Int'];
  quizId: Scalars['Int'];
  userId: Scalars['Int'];
  startedAt: Scalars['DateTime'];
  finishedAt?: Maybe<Scalars['DateTime']>;
  answered: Scalars['Int'];
  totalQuestions: Scalars['Int'];
  totalCorrectAnswers: Scalars['Int'];
  answers: Array<QuizAttemptAnswer>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type QuizAttemptAnswer = {
  __typename?: 'QuizAttemptAnswer';
  id: Scalars['Int'];
  attemptId: Scalars['Int'];
  userId: Scalars['Int'];
  questionId: Scalars['Int'];
  questionAnswerId: Scalars['Int'];
  isCorrect?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type QuizAttemptAnswerConnection = DefaultConnection & {
  __typename?: 'QuizAttemptAnswerConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<QuizAttemptAnswer>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type QuizAttemptAnswerCreateInput = {
  attemptId: Scalars['Int'];
  questionId: Scalars['Int'];
  questionAnswerId: Scalars['Int'];
};

export type QuizAttemptConnection = DefaultConnection & {
  __typename?: 'QuizAttemptConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<QuizAttempt>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type QuizConnection = DefaultConnection & {
  __typename?: 'QuizConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<Quiz>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type QuizCreateInput = {
  autoCreated?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  coverImageUrl?: Maybe<Scalars['String']>;
  maxAttempts?: Maybe<Scalars['Int']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  type: QuizType;
  state?: Maybe<QuizState>;
};

export type QuizQuestion = {
  __typename?: 'QuizQuestion';
  id: Scalars['Int'];
  quizId: Scalars['Int'];
  title: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  images?: Maybe<Scalars['JSON']>;
  isMultiple: Scalars['Boolean'];
  sort: Scalars['Int'];
  answers: Array<QuizQuestionAnswer>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type QuizQuestionAnswer = {
  __typename?: 'QuizQuestionAnswer';
  id: Scalars['Int'];
  questionId: Scalars['Int'];
  text?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isCorrect?: Maybe<Scalars['Boolean']>;
  sort: Scalars['Int'];
  stats?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type QuizQuestionAnswerConnection = DefaultConnection & {
  __typename?: 'QuizQuestionAnswerConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<QuizQuestionAnswer>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type QuizQuestionAnswerCreateInput = {
  questionId: Scalars['Int'];
  text?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isCorrect?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['Int']>;
};

export type QuizQuestionAnswerUpdateInput = {
  questionId?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isCorrect?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['Int']>;
};

export type QuizQuestionConnection = DefaultConnection & {
  __typename?: 'QuizQuestionConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<QuizQuestion>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type QuizQuestionCreateInput = {
  quizId: Scalars['Int'];
  title: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  images?: Maybe<Scalars['JSON']>;
  isMultiple: Scalars['Boolean'];
  sort?: Maybe<Scalars['Int']>;
};

export type QuizQuestionUpdateInput = {
  quizId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  images?: Maybe<Scalars['JSON']>;
  isMultiple?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['Int']>;
};

export type QuizResetAttemptsInput = {
  userId?: Maybe<Scalars['Int']>;
  quizId?: Maybe<Scalars['Int']>;
};

export enum QuizState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  FINISHED = 'FINISHED',
  DELETED = 'DELETED',
}

export enum QuizType {
  QUIZ = 'QUIZ',
  POLL = 'POLL',
}

export type QuizUpdateInput = {
  autoCreated?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  coverImageUrl?: Maybe<Scalars['String']>;
  maxAttempts?: Maybe<Scalars['Int']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<QuizType>;
  state?: Maybe<QuizState>;
};

export enum RatingType {
  SEASON = 'season',
  TOTAL = 'total',
  TOUR = 'tour',
}

export type RedirectAnalyticsInput = {
  mailingId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<DatePicker>;
};

export type RefundOrderInput = {
  orderId: Scalars['Int'];
  orderType: PaymentTransactionOrderType;
};

export type SaveArticleTagsInput = {
  articleId: Scalars['Int'];
  tags: Array<Scalars['String']>;
  isAppend?: Maybe<Scalars['Boolean']>;
};

export type SaveRedirectOnMailInput = {
  hash?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmId?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
};

export type SaveUserSettingsInput = {
  emailSettings?: Maybe<Scalars['JSON']>;
  defaultGroupId?: Maybe<Scalars['Int']>;
  pushTemplate?: Maybe<Scalars['Int']>;
  emailTemplate?: Maybe<Scalars['Int']>;
  pushTransport?: Maybe<Scalars['Int']>;
  emailTransport?: Maybe<Scalars['Int']>;
  emailSender?: Maybe<Scalars['String']>;
};

export type Secure3DResponse = {
  __typename?: 'Secure3DResponse';
  provider: PaymentGatewayProvider;
  providerResponse?: Maybe<Scalars['JSON']>;
  redirectUrl: Scalars['String'];
};

export type SendNotificationInput = {
  userId: Scalars['Int'];
  templateId?: Maybe<Scalars['Int']>;
  channel?: Maybe<MessengerQueueNotificationChannel>;
  sender?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  richText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  isNeedToProcess?: Maybe<Scalars['Boolean']>;
};

export type SocialAuthData = {
  accessToken: Scalars['String'];
  accessTokenSecret?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
};

export type SocialNetwork = {
  __typename?: 'SocialNetwork';
  id: Scalars['Int'];
  name: Scalars['String'];
  state: SocialNetworkState;
  description?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SocialNetworkConnection = DefaultConnection & {
  __typename?: 'SocialNetworkConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<SocialNetwork>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type SocialNetworkCreateInput = {
  name: Scalars['String'];
  state?: Maybe<SocialNetworkState>;
  description?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
};

export enum SocialNetworkState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type SocialNetworkUpdateInput = {
  name?: Maybe<Scalars['String']>;
  state?: Maybe<SocialNetworkState>;
  description?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
};

export type SocialNewsSetting = {
  __typename?: 'SocialNewsSetting';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  socialNetworkId: Scalars['Int'];
  url: Scalars['String'];
  entityType: SocialNewsSettingEntityType;
  entityId: Scalars['Int'];
  articleCategoryId: Scalars['Int'];
  localeId: Scalars['Int'];
  settings?: Maybe<Scalars['JSON']>;
  username?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  state: SocialNewsSettingState;
  error?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SocialNewsSettingConnection = DefaultConnection & {
  __typename?: 'SocialNewsSettingConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<SocialNewsSetting>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type SocialNewsSettingCreateInput = {
  socialNetworkId: Scalars['Int'];
  url: Scalars['String'];
  entityType: SocialNewsSettingEntityType;
  entityId: Scalars['Int'];
  articleCategoryId: Scalars['Int'];
  localeId: Scalars['Int'];
  settings?: Maybe<Scalars['JSON']>;
  username?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  state?: Maybe<SocialNewsSettingState>;
  error?: Maybe<Scalars['JSON']>;
};

export enum SocialNewsSettingEntityType {
  TEAM = 'TEAM',
  TEAM_MEMBER = 'TEAM_MEMBER',
  TOURNAMENT = 'TOURNAMENT',
}

export enum SocialNewsSettingState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type SocialNewsSettingUpdateInput = {
  socialNetworkId?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  entityType?: Maybe<SocialNewsSettingEntityType>;
  entityId?: Maybe<Scalars['Int']>;
  articleCategoryId?: Maybe<Scalars['Int']>;
  localeId?: Maybe<Scalars['Int']>;
  settings?: Maybe<Scalars['JSON']>;
  username?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  state?: Maybe<SocialNewsSettingState>;
  error?: Maybe<Scalars['JSON']>;
};

export type SocialProfile = {
  __typename?: 'SocialProfile';
  id: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  type?: Maybe<SocialProfileTypeEnum>;
  data?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum SocialProfileTypeEnum {
  VK = 'vk',
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  OK = 'ok',
}

export enum SortingDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type SubscribeToMatchEventsInput = {
  deviceId: Scalars['Int'];
  subscription: Array<SubscribeToMatchEventsSubscriptionInput>;
};

export type SubscribeToMatchEventsSubscriptionInput = {
  eventName: TournamentMatchEventEvent;
  teamIdx: Array<Scalars['Int']>;
};

export type SubscriptionPushInput = {
  subscriptionId: Array<Scalars['Int']>;
  modifierId?: Maybe<Array<Scalars['Int']>>;
  templateId?: Maybe<Scalars['Int']>;
  transportId?: Maybe<Scalars['Int']>;
  data?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type SystemAsyncTask = {
  __typename?: 'SystemAsyncTask';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  entity: SystemAsyncTaskEntity;
  entityId: Scalars['String'];
  operation: Scalars['String'];
  state: SystemAsyncTaskState;
  progress: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SystemAsyncTaskConnection = DefaultConnection & {
  __typename?: 'SystemAsyncTaskConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<SystemAsyncTask>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum SystemAsyncTaskEntity {
  SUBSCRIPTION = 'SUBSCRIPTION',
  MESSENGER_QUEUE = 'MESSENGER_QUEUE',
  EVENT_WHITELIST = 'EVENT_WHITELIST',
  USER_GROUP = 'USER_GROUP',
  USER = 'USER',
}

export enum SystemAsyncTaskState {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  FINISHED = 'FINISHED',
  CANCELLED = 'CANCELLED',
}

export type SystemAsyncTaskUpdateInput = {
  entity?: Maybe<SystemAsyncTaskEntity>;
  entityId?: Maybe<Scalars['String']>;
  operation?: Maybe<Scalars['String']>;
  state?: Maybe<SystemAsyncTaskState>;
  progress?: Maybe<Scalars['JSON']>;
};

export type SystemCountry = {
  __typename?: 'SystemCountry';
  id: Scalars['Int'];
  name: Scalars['String'];
  iso2: Scalars['String'];
  iso3: Scalars['String'];
  phoneCode: Scalars['String'];
  icon: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SystemLocale = {
  __typename?: 'SystemLocale';
  id: Scalars['Int'];
  iso: Scalars['String'];
  title: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SystemLocaleConnection = DefaultConnection & {
  __typename?: 'SystemLocaleConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<SystemLocale>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type SystemLogin = {
  __typename?: 'SystemLogin';
  currentUser: SystemUser;
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  clients?: Maybe<Array<Client>>;
  clientId?: Maybe<Scalars['Int']>;
};

export type SystemPrinter = {
  __typename?: 'SystemPrinter';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  title: Scalars['String'];
  model: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SystemPrinterConnection = DefaultConnection & {
  __typename?: 'SystemPrinterConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<SystemPrinter>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type SystemPrinterCreateInput = {
  name: Scalars['String'];
  title: Scalars['String'];
  model: Scalars['String'];
};

export type SystemPrinterUpdateInput = {
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
};

export type SystemTask = {
  __typename?: 'SystemTask';
  id: Scalars['UUID'];
  name: Scalars['String'];
  state: SystemTaskState;
  errors?: Maybe<Scalars['JSON']>;
  payload?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SystemTaskConnection = DefaultConnection & {
  __typename?: 'SystemTaskConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<SystemTask>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type SystemTaskCreateInput = {
  name: Scalars['String'];
  state?: Maybe<SystemTaskState>;
  errors?: Maybe<Scalars['JSON']>;
  payload?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
};

export enum SystemTaskState {
  PROCESSING = 'PROCESSING',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
}

export type SystemTaskUpdateInput = {
  name?: Maybe<Scalars['String']>;
  state?: Maybe<SystemTaskState>;
  errors?: Maybe<Scalars['JSON']>;
  payload?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
};

export type SystemTranslation = {
  __typename?: 'SystemTranslation';
  id: Scalars['Int'];
  locale: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SystemTranslationConnection = DefaultConnection & {
  __typename?: 'SystemTranslationConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<SystemTranslation>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type SystemTranslationCreateInput = {
  locale: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['String'];
};

export type SystemTranslationSaveInput = {
  key: Scalars['String'];
  value: Scalars['String'];
  locale: Scalars['String'];
};

export type SystemTranslationUpdateInput = {
  locale?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type SystemUser = {
  __typename?: 'SystemUser';
  id: Scalars['ID'];
  state: SystemUserState;
  email: Scalars['String'];
  username: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  locale: LocaleEnum;
  clients?: Maybe<Array<Client>>;
  ACL?: Maybe<Array<Acl>>;
};

export type SystemUserGroup = {
  __typename?: 'SystemUserGroup';
  id: Scalars['ID'];
  state: SystemUserState;
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SystemUserGroupsConnection = {
  __typename?: 'SystemUserGroupsConnection';
  total: Scalars['Int'];
  cursor: Scalars['Int'];
  limit: Scalars['Int'];
  sortable?: Maybe<Array<Scalars['String']>>;
  edges: Array<SystemUserGroup>;
};

export type SystemUsersConnection = {
  __typename?: 'SystemUsersConnection';
  total: Scalars['Int'];
  cursor: Scalars['Int'];
  limit: Scalars['Int'];
  sortable?: Maybe<Array<Scalars['String']>>;
  edges: Array<SystemUser>;
};

export enum SystemUserState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  BLOCKED = 'BLOCKED',
}

export type SystemUsersWhereInput = {
  id?: Maybe<Array<Scalars['ID']>>;
  state?: Maybe<Array<Maybe<SystemUserState>>>;
  createdAt?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
};

export type SystemWebHook = {
  __typename?: 'SystemWebHook';
  id: Scalars['Int'];
  clientId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  entity: SystemWebHookEntity;
  operation: SystemWebHookOperation;
  url: Scalars['String'];
  headers?: Maybe<Scalars['JSON']>;
  payload?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SystemWebHookConnection = DefaultConnection & {
  __typename?: 'SystemWebHookConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<SystemWebHook>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type SystemWebHookCreateInput = {
  title: Scalars['String'];
  entity: SystemWebHookEntity;
  operation: SystemWebHookOperation;
  url: Scalars['String'];
  headers?: Maybe<Scalars['JSON']>;
  payload?: Maybe<Scalars['JSON']>;
};

export enum SystemWebHookEntity {
  EVENT = 'EVENT',
  ORDER = 'ORDER',
  USER = 'USER',
  TICKET = 'TICKET',
}

export enum SystemWebHookOperation {
  IMPORT = 'IMPORT',
  LOAD = 'LOAD',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  SYNC = 'SYNC',
}

export type SystemWebHookPayloadInput = {
  params?: Maybe<Scalars['JSON']>;
  body?: Maybe<Scalars['JSON']>;
};

export type SystemWebHookUpdateInput = {
  title?: Maybe<Scalars['String']>;
  entity?: Maybe<SystemWebHookEntity>;
  operation?: Maybe<SystemWebHookOperation>;
  url?: Maybe<Scalars['String']>;
  headers?: Maybe<Scalars['JSON']>;
  payload?: Maybe<Scalars['JSON']>;
};

export enum TaxationSystemType {
  GENERAL = 'GENERAL',
  SIMPLIFIED_INCOME = 'SIMPLIFIED_INCOME',
  SIMPLIFIED_INCOME_EXPENSE = 'SIMPLIFIED_INCOME_EXPENSE',
  ENVD = 'ENVD',
  ESN = 'ESN',
  PATENT = 'PATENT',
}

export enum TaxRateType {
  NO_VAT = 'NO_VAT',
  VAT_0 = 'VAT_0',
  VAT_10 = 'VAT_10',
  VAT_20 = 'VAT_20',
  VAT_110 = 'VAT_110',
  VAT_120 = 'VAT_120',
}

export type TemplateApplePass = {
  __typename?: 'TemplateApplePass';
  id: Scalars['Int'];
  clientId?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pass?: Maybe<Scalars['JSON']>;
  files?: Maybe<Scalars['JSON']>;
  certificates?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TemplateApplePassConnection = DefaultConnection & {
  __typename?: 'TemplateApplePassConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TemplateApplePass>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TemplateApplePassCreateInput = {
  preview?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pass?: Maybe<Scalars['JSON']>;
  files?: Maybe<Scalars['JSON']>;
  certificates?: Maybe<Scalars['JSON']>;
};

export type TemplateApplePassUpdateInput = {
  preview?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pass?: Maybe<Scalars['JSON']>;
  files?: Maybe<Scalars['JSON']>;
  certificates?: Maybe<Scalars['JSON']>;
};

export type TemplateNotification = {
  __typename?: 'TemplateNotification';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: TemplateNotificationState;
  channel: TemplateNotificationChannel;
  sender: Scalars['String'];
  name: Scalars['String'];
  title: Scalars['String'];
  richText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  externalTemplateId?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum TemplateNotificationChannel {
  EMAIL = 'EMAIL',
  SERVICE_EMAIL = 'SERVICE_EMAIL',
  SMS = 'SMS',
  PUSH = 'PUSH',
  TELEGRAM = 'TELEGRAM',
  SLACK = 'SLACK',
}

export type TemplateNotificationConnection = DefaultConnection & {
  __typename?: 'TemplateNotificationConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TemplateNotification>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TemplateNotificationCreateInput = {
  state?: Maybe<TemplateNotificationState>;
  channel: TemplateNotificationChannel;
  sender: Scalars['String'];
  name: Scalars['String'];
  title: Scalars['String'];
  richText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  externalTemplateId?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['Int']>;
};

export enum TemplateNotificationState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type TemplateNotificationUpdateInput = {
  state?: Maybe<TemplateNotificationState>;
  channel?: Maybe<TemplateNotificationChannel>;
  sender?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  richText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  externalTemplateId?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['Int']>;
};

export type TemplatePdf = {
  __typename?: 'TemplatePDF';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  preview?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TemplatePdfConnection = DefaultConnection & {
  __typename?: 'TemplatePDFConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TemplatePdf>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TemplatePdfCreateInput = {
  name: Scalars['String'];
  preview?: Maybe<Scalars['String']>;
  body: Scalars['String'];
};

export type TemplatePdfUpdateInput = {
  name?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
};

export type TemplateProvider = {
  __typename?: 'TemplateProvider';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: TemplateProviderState;
  name: Scalars['String'];
  settings?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TemplateProviderConnection = DefaultConnection & {
  __typename?: 'TemplateProviderConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TemplateProvider>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum TemplateProviderState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type TemplateProviderUpdateInput = {
  state?: Maybe<TemplateProviderState>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
};

export type Tournament = {
  __typename?: 'Tournament';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  sport: TournamentSport;
  state: TournamentState;
  system: TournamentSystem;
  sort?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
  seasons: Array<TournamentSeason>;
  currentSeason?: Maybe<TournamentSeason>;
  data: Array<ExtensionData>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentConnection = DefaultConnection & {
  __typename?: 'TournamentConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<Tournament>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentCreateInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  sport: TournamentSport;
  state: TournamentState;
  system: TournamentSystem;
  sort?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type TournamentDispute = {
  __typename?: 'TournamentDispute';
  id: Scalars['Int'];
  state: TournamentDisputeState;
  result: TournamentDisputeResult;
  confirmation: TournamentDisputeConfirmation;
  userId: Scalars['Int'];
  matchId: Scalars['Int'];
  amount: Scalars['Int'];
  winAmount: Scalars['Int'];
  text: Scalars['String'];
  user: User;
  match: TournamentMatch;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum TournamentDisputeConfirmation {
  WAITING = 'WAITING',
  WIN = 'WIN',
  LOSS = 'LOSS',
}

export type TournamentDisputeConnection = DefaultConnection & {
  __typename?: 'TournamentDisputeConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentDispute>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentDisputeCreateInput = {
  state?: Maybe<TournamentDisputeState>;
  result?: Maybe<TournamentDisputeResult>;
  confirmation?: Maybe<TournamentDisputeConfirmation>;
  userId: Scalars['Int'];
  matchId: Scalars['Int'];
  amount: Scalars['Int'];
  winAmount?: Maybe<Scalars['Int']>;
  text: Scalars['String'];
};

export type TournamentDisputeResponse = {
  __typename?: 'TournamentDisputeResponse';
  id: Scalars['Int'];
  disputeId: Scalars['Int'];
  state: TournamentDisputeResponseState;
  userId: Scalars['Int'];
  amount: Scalars['Int'];
  comment: Scalars['String'];
  respondedAt: Scalars['DateTime'];
  user: User;
  dispute: TournamentDispute;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentDisputeResponseConnection = DefaultConnection & {
  __typename?: 'TournamentDisputeResponseConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentDisputeResponse>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentDisputeResponseCreateInput = {
  disputeId: Scalars['Int'];
  state?: Maybe<TournamentDisputeResponseState>;
  userId: Scalars['Int'];
  amount: Scalars['Int'];
  comment: Scalars['String'];
  respondedAt?: Maybe<Scalars['DateTime']>;
};

export enum TournamentDisputeResponseState {
  ACTIVE = 'ACTIVE',
  WIN = 'WIN',
  LOSS = 'LOSS',
  WAITING = 'WAITING',
}

export type TournamentDisputeResponseUpdateInput = {
  disputeId?: Maybe<Scalars['Int']>;
  state?: Maybe<TournamentDisputeResponseState>;
  userId?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  respondedAt?: Maybe<Scalars['DateTime']>;
};

export enum TournamentDisputeResult {
  WIN = 'WIN',
  LOSS = 'LOSS',
  CANCELLED = 'CANCELLED',
  MODERATION = 'MODERATION',
  WAITING = 'WAITING',
}

export enum TournamentDisputeState {
  ACTIVE = 'ACTIVE',
  LOCKED = 'LOCKED',
  FINISHED = 'FINISHED',
  DELETED = 'DELETED',
  ARCHIVED = 'ARCHIVED',
}

export type TournamentDisputeUpdateInput = {
  state?: Maybe<TournamentDisputeState>;
  result?: Maybe<TournamentDisputeResult>;
  confirmation?: Maybe<TournamentDisputeConfirmation>;
  userId?: Maybe<Scalars['Int']>;
  matchId?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  winAmount?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
};

export type TournamentDisqualification = {
  __typename?: 'TournamentDisqualification';
  id: Scalars['Int'];
  playerId: Scalars['Int'];
  reason: Scalars['String'];
  tournamentId: Scalars['Int'];
  seasonId: Scalars['Int'];
  resolutionDate: Scalars['DateTime'];
  disqualificationValue: Scalars['Int'];
  leftMatches: Scalars['Int'];
  state: TournamentDisqualificationState;
  meta?: Maybe<Scalars['JSON']>;
  player: TournamentTeamMember;
  tournament: Tournament;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentDisqualificationConnection = DefaultConnection & {
  __typename?: 'TournamentDisqualificationConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentDisqualification>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentDisqualificationCreateInput = {
  playerId: Scalars['Int'];
  reason: Scalars['String'];
  tournamentId: Scalars['Int'];
  seasonId: Scalars['Int'];
  resolutionDate: Scalars['DateTime'];
  disqualificationValue: Scalars['Int'];
  leftMatches: Scalars['Int'];
  state: TournamentDisqualificationState;
  meta?: Maybe<Scalars['JSON']>;
};

export enum TournamentDisqualificationState {
  CURRENT = 'CURRENT',
  FINISH = 'FINISH',
}

export type TournamentDisqualificationUpdateInput = {
  playerId?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  tournamentId?: Maybe<Scalars['Int']>;
  seasonId?: Maybe<Scalars['Int']>;
  resolutionDate?: Maybe<Scalars['DateTime']>;
  disqualificationValue?: Maybe<Scalars['Int']>;
  leftMatches?: Maybe<Scalars['Int']>;
  state?: Maybe<TournamentDisqualificationState>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentFieldsConnection = DefaultConnection & {
  __typename?: 'TournamentFieldsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ExtensionField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentGroup = {
  __typename?: 'TournamentGroup';
  id: Scalars['Int'];
  name: Scalars['String'];
  key?: Maybe<Scalars['String']>;
  seasonId: Scalars['Int'];
  stage: TournamentGroupStage;
  sort: Scalars['Int'];
  season: TournamentSeason;
  matches: Array<TournamentMatch>;
  teamStats: Array<TournamentSeasonTeamStat>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentGroupConnection = DefaultConnection & {
  __typename?: 'TournamentGroupConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentGroup>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentGroupCreateInput = {
  name: Scalars['String'];
  key?: Maybe<Scalars['String']>;
  seasonId: Scalars['Int'];
  stage?: Maybe<TournamentGroupStage>;
  sort?: Maybe<Scalars['Int']>;
};

export enum TournamentGroupStage {
  QUALIFICATION = 'QUALIFICATION',
  GROUPS = 'GROUPS',
  PLAY_OFF = 'PLAY_OFF',
  UNKNOWN = 'UNKNOWN',
}

export type TournamentGroupUpdateInput = {
  name?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['Int']>;
  stage?: Maybe<TournamentGroupStage>;
  sort?: Maybe<Scalars['Int']>;
};

export enum TournamentInstanceType {
  TEAM_MEMBER = 'TEAM_MEMBER',
  TEAM = 'TEAM',
  MATCH = 'MATCH',
}

export type TournamentMatch = {
  __typename?: 'TournamentMatch';
  id: Scalars['Int'];
  team1Id: Scalars['Int'];
  team2Id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  team1IdGoals?: Maybe<Scalars['Int']>;
  team2IdGoals?: Maybe<Scalars['Int']>;
  team1IdShootoutGoals?: Maybe<Scalars['Int']>;
  team2IdShootoutGoals?: Maybe<Scalars['Int']>;
  isOvertime: Scalars['Boolean'];
  isPenaltyShootout: Scalars['Boolean'];
  seasonId: Scalars['Int'];
  groupId?: Maybe<Scalars['Int']>;
  tourId?: Maybe<Scalars['Int']>;
  state: TournamentMatchState;
  meta?: Maybe<Scalars['JSON']>;
  videoStreams?: Maybe<Scalars['JSON']>;
  stadiumName?: Maybe<Scalars['String']>;
  stadiumAddress?: Maybe<Scalars['String']>;
  stadium?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  startTime?: Maybe<Scalars['DateTime']>;
  finishDate: Scalars['DateTime'];
  startedAt?: Maybe<Scalars['DateTime']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  result?: Maybe<Scalars['String']>;
  team1: TournamentTeam;
  team2: TournamentTeam;
  tour?: Maybe<TournamentTour>;
  season: TournamentSeason;
  group?: Maybe<TournamentGroup>;
  matchEvents: Array<TournamentMatchEvent>;
  matchStats: Array<TournamentMatchStat>;
  playerStat?: Maybe<TournamentPlayerStat>;
  disputes: Array<TournamentDispute>;
  disputeResponses: Array<TournamentDisputeResponse>;
  data: Array<ExtensionData>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentMatchConnection = DefaultConnection & {
  __typename?: 'TournamentMatchConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentMatch>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentMatchCreateInput = {
  team1Id: Scalars['Int'];
  team2Id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  team1IdGoals?: Maybe<Scalars['Int']>;
  team2IdGoals?: Maybe<Scalars['Int']>;
  team1IdShootoutGoals?: Maybe<Scalars['Int']>;
  team2IdShootoutGoals?: Maybe<Scalars['Int']>;
  isOvertime?: Maybe<Scalars['Boolean']>;
  isPenaltyShootout?: Maybe<Scalars['Boolean']>;
  seasonId: Scalars['Int'];
  groupId?: Maybe<Scalars['Int']>;
  tourId?: Maybe<Scalars['Int']>;
  state: TournamentMatchState;
  meta?: Maybe<Scalars['JSON']>;
  videoStreams?: Maybe<Scalars['JSON']>;
  stadiumName?: Maybe<Scalars['String']>;
  stadiumAddress?: Maybe<Scalars['String']>;
  stadium?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  startTime?: Maybe<Scalars['DateTime']>;
  finishDate: Scalars['DateTime'];
  startedAt?: Maybe<Scalars['DateTime']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  result?: Maybe<Scalars['String']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export enum TournamentMatchDataType {
  PROTOCOL = 'PROTOCOL',
  EVENT = 'EVENT',
  LINEUP = 'LINEUP',
  ALL = 'ALL',
}

export type TournamentMatchEvent = {
  __typename?: 'TournamentMatchEvent';
  id: Scalars['Int'];
  matchId: Scalars['Int'];
  datetime: Scalars['DateTime'];
  minute: Scalars['Int'];
  additionalTime?: Maybe<Scalars['Int']>;
  event: TournamentMatchEventEvent;
  teamMemberId?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['Int']>;
  linkedMatchEventId?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  isProtocol: Scalars['Boolean'];
  meta?: Maybe<Scalars['JSON']>;
  match: TournamentMatch;
  player?: Maybe<TournamentTeamMember>;
  team?: Maybe<TournamentTeam>;
  linkedMatchEvent?: Maybe<TournamentMatchEvent>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentMatchEventConnection = DefaultConnection & {
  __typename?: 'TournamentMatchEventConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentMatchEvent>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentMatchEventCreateInput = {
  matchId: Scalars['Int'];
  datetime?: Maybe<Scalars['DateTime']>;
  minute: Scalars['Int'];
  additionalTime?: Maybe<Scalars['Int']>;
  event: TournamentMatchEventEvent;
  teamMemberId?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['Int']>;
  linkedMatchEventId?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  isProtocol?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
};

export enum TournamentMatchEventEvent {
  AUTO_GOAL = 'AUTO_GOAL',
  CORNER_KICK = 'CORNER_KICK',
  FREE_KICK = 'FREE_KICK',
  GOAL = 'GOAL',
  IN = 'IN',
  MATCH_FINISH = 'MATCH_FINISH',
  MATCH_HALFTIME = 'MATCH_HALFTIME',
  MATCH_START = 'MATCH_START',
  OUT = 'OUT',
  PASS = 'PASS',
  ASSIST = 'ASSIST',
  PENALTY = 'PENALTY',
  PENALTY_NO = 'PENALTY_NO',
  RED_CARD = 'RED_CARD',
  TRAUMA = 'TRAUMA',
  YELLOW_CARD = 'YELLOW_CARD',
  YELLOW_CARD2 = 'YELLOW_CARD2',
  COMMENT = 'COMMENT',
  VAR = 'VAR',
  VIDEO = 'VIDEO',
  FINAL = 'FINAL',
}

export type TournamentMatchEventUpdateInput = {
  matchId?: Maybe<Scalars['Int']>;
  datetime?: Maybe<Scalars['DateTime']>;
  minute?: Maybe<Scalars['Int']>;
  additionalTime?: Maybe<Scalars['Int']>;
  event?: Maybe<TournamentMatchEventEvent>;
  teamMemberId?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['Int']>;
  linkedMatchEventId?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  isProtocol?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentMatchFieldsConnection = DefaultConnection & {
  __typename?: 'TournamentMatchFieldsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ExtensionField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentMatchLineUp = {
  __typename?: 'TournamentMatchLineUp';
  id: Scalars['Int'];
  matchId: Scalars['Int'];
  teamId?: Maybe<Scalars['Int']>;
  positionId: Scalars['Int'];
  teamMemberId?: Maybe<Scalars['Int']>;
  refereeId?: Maybe<Scalars['Int']>;
  type?: Maybe<TournamentMatchLineUpType>;
  initialRole?: Maybe<TournamentMatchLineUpInitialRole>;
  finalRole?: Maybe<TournamentMatchLineUpFinalRole>;
  initialState?: Maybe<TournamentMatchLineUpInitialState>;
  finalState?: Maybe<TournamentMatchLineUpFinalState>;
  number?: Maybe<Scalars['Int']>;
  isTeamCaptain?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  teamMember?: Maybe<TournamentTeamMember>;
  team?: Maybe<TournamentTeam>;
  referee?: Maybe<TournamentReferee>;
  position: GenericObject;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentMatchLineUpCreateInput = {
  matchId: Scalars['Int'];
  teamId?: Maybe<Scalars['Int']>;
  positionId?: Maybe<Scalars['Int']>;
  teamMemberId?: Maybe<Scalars['Int']>;
  refereeId?: Maybe<Scalars['Int']>;
  type?: Maybe<TournamentMatchLineUpType>;
  initialRole?: Maybe<TournamentMatchLineUpInitialRole>;
  finalRole?: Maybe<TournamentMatchLineUpFinalRole>;
  initialState?: Maybe<TournamentMatchLineUpInitialState>;
  finalState?: Maybe<TournamentMatchLineUpFinalState>;
  number?: Maybe<Scalars['Int']>;
  isTeamCaptain?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
};

export enum TournamentMatchLineUpFinalRole {
  DEFENDER = 'DEFENDER',
  HALFBACK = 'HALFBACK',
  GOALKEEPER = 'GOALKEEPER',
  FORWARD = 'FORWARD',
}

export enum TournamentMatchLineUpFinalState {
  MAIN = 'MAIN',
  SUBSTITUTE = 'SUBSTITUTE',
}

export enum TournamentMatchLineUpInitialRole {
  DEFENDER = 'DEFENDER',
  HALFBACK = 'HALFBACK',
  GOALKEEPER = 'GOALKEEPER',
  FORWARD = 'FORWARD',
}

export enum TournamentMatchLineUpInitialState {
  MAIN = 'MAIN',
  SUBSTITUTE = 'SUBSTITUTE',
}

export enum TournamentMatchLineUpType {
  PLAYER = 'PLAYER',
  COACH = 'COACH',
  REPRESENTATIVE = 'REPRESENTATIVE',
  REFEREE = 'REFEREE',
  MEDIC = 'MEDIC',
}

export type TournamentMatchLineUpUpdateInput = {
  matchId?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['Int']>;
  positionId?: Maybe<Scalars['Int']>;
  teamMemberId?: Maybe<Scalars['Int']>;
  refereeId?: Maybe<Scalars['Int']>;
  type?: Maybe<TournamentMatchLineUpType>;
  initialRole?: Maybe<TournamentMatchLineUpInitialRole>;
  finalRole?: Maybe<TournamentMatchLineUpFinalRole>;
  initialState?: Maybe<TournamentMatchLineUpInitialState>;
  finalState?: Maybe<TournamentMatchLineUpFinalState>;
  number?: Maybe<Scalars['Int']>;
  isTeamCaptain?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentMatchProtocol = {
  __typename?: 'TournamentMatchProtocol';
  id: Scalars['Int'];
  matchId: Scalars['Int'];
  minute?: Maybe<Scalars['Int']>;
  event: TournamentMatchProtocolEvent;
  teamMemberId: Scalars['Int'];
  teamId: Scalars['Int'];
  meta?: Maybe<Scalars['JSON']>;
  match: TournamentMatch;
  player?: Maybe<TournamentTeamMember>;
  team?: Maybe<TournamentTeam>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentMatchProtocolConnection = DefaultConnection & {
  __typename?: 'TournamentMatchProtocolConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentMatchProtocol>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentMatchProtocolCreateInput = {
  matchId: Scalars['Int'];
  minute?: Maybe<Scalars['Int']>;
  event: TournamentMatchProtocolEvent;
  teamMemberId: Scalars['Int'];
  teamId: Scalars['Int'];
  meta?: Maybe<Scalars['JSON']>;
};

export enum TournamentMatchProtocolEvent {
  AUTO_GOAL = 'AUTO_GOAL',
  CORNER_KICK = 'CORNER_KICK',
  FREE_KICK = 'FREE_KICK',
  GOAL = 'GOAL',
  IN = 'IN',
  MATCH_FINISH = 'MATCH_FINISH',
  MATCH_HALFTIME = 'MATCH_HALFTIME',
  MATCH_START = 'MATCH_START',
  OUT = 'OUT',
  PASS = 'PASS',
  ASSIST = 'ASSIST',
  PENALTY = 'PENALTY',
  PENALTY_NO = 'PENALTY_NO',
  RED_CARD = 'RED_CARD',
  TRAUMA = 'TRAUMA',
  YELLOW_CARD = 'YELLOW_CARD',
  YELLOW_CARD2 = 'YELLOW_CARD2',
  COMMENT = 'COMMENT',
  VAR = 'VAR',
  VIDEO = 'VIDEO',
  FINAL = 'FINAL',
}

export type TournamentMatchProtocolUpdateInput = {
  matchId?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  event?: Maybe<TournamentMatchProtocolEvent>;
  teamMemberId?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentMatchStat = {
  __typename?: 'TournamentMatchStat';
  id: Scalars['Int'];
  matchId: Scalars['Int'];
  team1Id?: Maybe<Scalars['Int']>;
  team2Id?: Maybe<Scalars['Int']>;
  type: TournamentMatchStatType;
  team1Value: Scalars['Int'];
  team2Value: Scalars['Int'];
  team1Comparative: Scalars['Float'];
  team2Comparative: Scalars['Float'];
  sort: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentMatchStatConnection = DefaultConnection & {
  __typename?: 'TournamentMatchStatConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentMatchStat>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentMatchStatCreateInput = {
  matchId: Scalars['Int'];
  team1Id?: Maybe<Scalars['Int']>;
  team2Id?: Maybe<Scalars['Int']>;
  type: TournamentMatchStatType;
  team1Value: Scalars['Int'];
  team2Value: Scalars['Int'];
  team1Comparative?: Maybe<Scalars['Float']>;
  team2Comparative?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Int']>;
};

export enum TournamentMatchState {
  PENDING = 'PENDING',
  PROGRESS = 'PROGRESS',
  FINISHED = 'FINISHED',
  HALF_TIME = 'HALF_TIME',
  OVER_TIME = 'OVER_TIME',
  CANCELED = 'CANCELED',
  FIRST_TIME = 'FIRST_TIME',
  SECOND_TIME = 'SECOND_TIME',
  MOVED = 'MOVED',
  PAUSE = 'PAUSE',
  PENALTY_SHOOTOUT = 'PENALTY_SHOOTOUT',
}

export enum TournamentMatchStatType {
  SHOT = 'SHOT',
  SHOTIN = 'SHOTIN',
  FAULT = 'FAULT',
  CORNER = 'CORNER',
  OFFSIDE = 'OFFSIDE',
  POSSESSION = 'POSSESSION',
  BLOCKED_SHOT = 'BLOCKED_SHOT',
  FREE_KICK = 'FREE_KICK',
  GOAL_KICK = 'GOAL_KICK',
  THROW_IN = 'THROW_IN',
  YELLOW_CARDS = 'YELLOW_CARDS',
  RED_CARDS = 'RED_CARDS',
}

export type TournamentMatchStatUpdateInput = {
  matchId?: Maybe<Scalars['Int']>;
  team1Id?: Maybe<Scalars['Int']>;
  team2Id?: Maybe<Scalars['Int']>;
  type?: Maybe<TournamentMatchStatType>;
  team1Value?: Maybe<Scalars['Int']>;
  team2Value?: Maybe<Scalars['Int']>;
  team1Comparative?: Maybe<Scalars['Float']>;
  team2Comparative?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Int']>;
};

export type TournamentMatchUpdateInput = {
  team1Id?: Maybe<Scalars['Int']>;
  team2Id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  team1IdGoals?: Maybe<Scalars['Int']>;
  team2IdGoals?: Maybe<Scalars['Int']>;
  team1IdShootoutGoals?: Maybe<Scalars['Int']>;
  team2IdShootoutGoals?: Maybe<Scalars['Int']>;
  isOvertime?: Maybe<Scalars['Boolean']>;
  isPenaltyShootout?: Maybe<Scalars['Boolean']>;
  seasonId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  tourId?: Maybe<Scalars['Int']>;
  state?: Maybe<TournamentMatchState>;
  meta?: Maybe<Scalars['JSON']>;
  videoStreams?: Maybe<Scalars['JSON']>;
  stadiumName?: Maybe<Scalars['String']>;
  stadiumAddress?: Maybe<Scalars['String']>;
  stadium?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['DateTime']>;
  finishDate?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  result?: Maybe<Scalars['String']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type TournamentPlayerStat = {
  __typename?: 'TournamentPlayerStat';
  id: Scalars['Int'];
  playerId: Scalars['Int'];
  seasonId: Scalars['Int'];
  games: Scalars['Int'];
  goals: Scalars['Int'];
  penaltyKicks: Scalars['Int'];
  passes: Scalars['Int'];
  yellowCards: Scalars['Int'];
  redCards: Scalars['Int'];
  missedGoals: Scalars['Int'];
  shutouts: Scalars['Int'];
  position?: Maybe<Scalars['Int']>;
  bombardierValue: Scalars['Float'];
  assistantValue: Scalars['Float'];
  goalsAndPassesValue: Scalars['Float'];
  goalkeeperValue: Scalars['Float'];
  warningValue: Scalars['Float'];
  meta?: Maybe<Scalars['JSON']>;
  player: TournamentTeamMember;
  season: TournamentSeason;
  allGoals: Scalars['Int'];
  goalsAndPasses: Scalars['Int'];
  avgGoals: Scalars['Float'];
  avgPasses: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentPlayerStatConnection = DefaultConnection & {
  __typename?: 'TournamentPlayerStatConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentPlayerStat>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentPlayerStatCreateInput = {
  playerId: Scalars['Int'];
  seasonId: Scalars['Int'];
  games?: Maybe<Scalars['Int']>;
  goals?: Maybe<Scalars['Int']>;
  penaltyKicks?: Maybe<Scalars['Int']>;
  passes?: Maybe<Scalars['Int']>;
  yellowCards?: Maybe<Scalars['Int']>;
  redCards?: Maybe<Scalars['Int']>;
  missedGoals?: Maybe<Scalars['Int']>;
  shutouts?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  bombardierValue?: Maybe<Scalars['Float']>;
  assistantValue?: Maybe<Scalars['Float']>;
  goalsAndPassesValue?: Maybe<Scalars['Float']>;
  goalkeeperValue?: Maybe<Scalars['Float']>;
  warningValue?: Maybe<Scalars['Float']>;
  meta?: Maybe<Scalars['JSON']>;
  allGoals?: Maybe<Scalars['Int']>;
  goalsAndPasses?: Maybe<Scalars['Int']>;
  avgGoals?: Maybe<Scalars['Float']>;
  avgPasses?: Maybe<Scalars['Float']>;
};

export type TournamentPlayerStatUpdateInput = {
  playerId?: Maybe<Scalars['Int']>;
  seasonId?: Maybe<Scalars['Int']>;
  games?: Maybe<Scalars['Int']>;
  goals?: Maybe<Scalars['Int']>;
  penaltyKicks?: Maybe<Scalars['Int']>;
  passes?: Maybe<Scalars['Int']>;
  yellowCards?: Maybe<Scalars['Int']>;
  redCards?: Maybe<Scalars['Int']>;
  missedGoals?: Maybe<Scalars['Int']>;
  shutouts?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  bombardierValue?: Maybe<Scalars['Float']>;
  assistantValue?: Maybe<Scalars['Float']>;
  goalsAndPassesValue?: Maybe<Scalars['Float']>;
  goalkeeperValue?: Maybe<Scalars['Float']>;
  warningValue?: Maybe<Scalars['Float']>;
  meta?: Maybe<Scalars['JSON']>;
  allGoals?: Maybe<Scalars['Int']>;
  goalsAndPasses?: Maybe<Scalars['Int']>;
  avgGoals?: Maybe<Scalars['Float']>;
  avgPasses?: Maybe<Scalars['Float']>;
};

export type TournamentReferee = {
  __typename?: 'TournamentReferee';
  id: Scalars['Int'];
  typeId: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  birthday: Scalars['String'];
  preview?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  type: GenericObject;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentRefereeConnection = DefaultConnection & {
  __typename?: 'TournamentRefereeConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentReferee>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentRefereeCreateInput = {
  typeId?: Maybe<Scalars['Int']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  birthday: Scalars['String'];
  preview?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentRefereeUpdateInput = {
  typeId?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentSeason = {
  __typename?: 'TournamentSeason';
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tournamentId: Scalars['Int'];
  startDate?: Maybe<Scalars['DateTime']>;
  finishDate?: Maybe<Scalars['DateTime']>;
  regulation?: Maybe<Scalars['String']>;
  state: TournamentSeasonState;
  sort?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
  tournament: Tournament;
  teams: Array<TournamentTeam>;
  tours: Array<TournamentTour>;
  matches: Array<TournamentMatch>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentSeasonApplicationForm = {
  __typename?: 'TournamentSeasonApplicationForm';
  id: Scalars['Int'];
  seasonId: Scalars['Int'];
  teamId: Scalars['Int'];
  meta?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentSeasonApplicationFormConnection = DefaultConnection & {
  __typename?: 'TournamentSeasonApplicationFormConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentSeasonApplicationForm>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentSeasonApplicationFormCreateInput = {
  seasonId: Scalars['Int'];
  teamId: Scalars['Int'];
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentSeasonApplicationFormUpdateInput = {
  seasonId?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentSeasonConnection = DefaultConnection & {
  __typename?: 'TournamentSeasonConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentSeason>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentSeasonCreateInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tournamentId: Scalars['Int'];
  startDate?: Maybe<Scalars['DateTime']>;
  finishDate?: Maybe<Scalars['DateTime']>;
  regulation?: Maybe<Scalars['String']>;
  state: TournamentSeasonState;
  sort?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
};

export enum TournamentSeasonState {
  FUTURE = 'FUTURE',
  CURRENT = 'CURRENT',
  FINISHED = 'FINISHED',
}

export type TournamentSeasonTeam = {
  __typename?: 'TournamentSeasonTeam';
  id: Scalars['Int'];
  seasonId: Scalars['Int'];
  teamId: Scalars['Int'];
  team: TournamentTeam;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentSeasonTeamConnection = DefaultConnection & {
  __typename?: 'TournamentSeasonTeamConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentSeasonTeam>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentSeasonTeamCreateInput = {
  seasonId: Scalars['Int'];
  teamId: Scalars['Int'];
};

export type TournamentSeasonTeamMember = {
  __typename?: 'TournamentSeasonTeamMember';
  id: Scalars['Int'];
  seasonId: Scalars['Int'];
  teamId: Scalars['Int'];
  teamMemberId: Scalars['Int'];
  state?: Maybe<TournamentSeasonTeamMemberState>;
  teamMember: TournamentTeamMember;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentSeasonTeamMemberConnection = DefaultConnection & {
  __typename?: 'TournamentSeasonTeamMemberConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentSeasonTeamMember>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentSeasonTeamMemberCreateInput = {
  seasonId: Scalars['Int'];
  teamId: Scalars['Int'];
  teamMemberId: Scalars['Int'];
  state?: Maybe<TournamentSeasonTeamMemberState>;
};

export enum TournamentSeasonTeamMemberState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type TournamentSeasonTeamMemberUpdateInput = {
  seasonId?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['Int']>;
  teamMemberId?: Maybe<Scalars['Int']>;
  state?: Maybe<TournamentSeasonTeamMemberState>;
};

export type TournamentSeasonTeamStat = {
  __typename?: 'TournamentSeasonTeamStat';
  id: Scalars['Int'];
  seasonId: Scalars['Int'];
  groupId?: Maybe<Scalars['Int']>;
  position: Scalars['Int'];
  teamId: Scalars['Int'];
  points: Scalars['Int'];
  games: Scalars['Int'];
  win: Scalars['Int'];
  draw: Scalars['Int'];
  loss: Scalars['Int'];
  goals: Scalars['Int'];
  missedGoals: Scalars['Int'];
  yellowCards: Scalars['Int'];
  redCards: Scalars['Int'];
  shutouts: Scalars['Int'];
  sort?: Maybe<Scalars['Float']>;
  meta?: Maybe<Scalars['JSON']>;
  team: TournamentTeam;
  season: TournamentSeason;
  group?: Maybe<TournamentGroup>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentSeasonTeamStatConnection = DefaultConnection & {
  __typename?: 'TournamentSeasonTeamStatConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentSeasonTeamStat>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentSeasonTeamStatCreateInput = {
  seasonId: Scalars['Int'];
  groupId?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  teamId: Scalars['Int'];
  points?: Maybe<Scalars['Int']>;
  games?: Maybe<Scalars['Int']>;
  win?: Maybe<Scalars['Int']>;
  draw?: Maybe<Scalars['Int']>;
  loss?: Maybe<Scalars['Int']>;
  goals?: Maybe<Scalars['Int']>;
  missedGoals?: Maybe<Scalars['Int']>;
  yellowCards?: Maybe<Scalars['Int']>;
  redCards?: Maybe<Scalars['Int']>;
  shutouts?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Float']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentSeasonTeamStatUpdateInput = {
  seasonId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  games?: Maybe<Scalars['Int']>;
  win?: Maybe<Scalars['Int']>;
  draw?: Maybe<Scalars['Int']>;
  loss?: Maybe<Scalars['Int']>;
  goals?: Maybe<Scalars['Int']>;
  missedGoals?: Maybe<Scalars['Int']>;
  yellowCards?: Maybe<Scalars['Int']>;
  redCards?: Maybe<Scalars['Int']>;
  shutouts?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Float']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentSeasonTeamUpdateInput = {
  seasonId?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['Int']>;
};

export type TournamentSeasonUpdateInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tournamentId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  finishDate?: Maybe<Scalars['DateTime']>;
  regulation?: Maybe<Scalars['String']>;
  state?: Maybe<TournamentSeasonState>;
  sort?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentSetting = {
  __typename?: 'TournamentSetting';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  tournamentId: Scalars['Int'];
  pushEventSubscriptions?: Maybe<Scalars['JSON']>;
  pushEventTemplates?: Maybe<Scalars['JSON']>;
  transportId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentSettingUpdateInput = {
  tournamentId?: Maybe<Scalars['Int']>;
  pushEventSubscriptions?: Maybe<Scalars['JSON']>;
  pushEventTemplates?: Maybe<Scalars['JSON']>;
  transportId?: Maybe<Scalars['Int']>;
};

export enum TournamentSport {
  FOOTBALL = 'FOOTBALL',
  HOCKEY = 'HOCKEY',
  BASKETBALL = 'BASKETBALL',
  TENNIS = 'TENNIS',
}

export type TournamentStadium = {
  __typename?: 'TournamentStadium';
  id: Scalars['Int'];
  teamId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  builtIn?: Maybe<Scalars['DateTime']>;
  capacity?: Maybe<Scalars['Int']>;
  team?: Maybe<TournamentTeam>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentStadiumConnection = DefaultConnection & {
  __typename?: 'TournamentStadiumConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentStadium>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentStadiumCreateInput = {
  teamId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  builtIn?: Maybe<Scalars['DateTime']>;
  capacity?: Maybe<Scalars['Int']>;
};

export type TournamentStadiumUpdateInput = {
  teamId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  builtIn?: Maybe<Scalars['DateTime']>;
  capacity?: Maybe<Scalars['Int']>;
};

export enum TournamentState {
  FUTURE = 'FUTURE',
  CURRENT = 'CURRENT',
  FINISHED = 'FINISHED',
}

export enum TournamentSystem {
  ROUND = 'ROUND',
  PLAYOFF = 'PLAYOFF',
}

export type TournamentTeam = {
  __typename?: 'TournamentTeam';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  history?: Maybe<Scalars['String']>;
  foundingDate?: Maybe<Scalars['String']>;
  altLogo?: Maybe<Scalars['String']>;
  stadium?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  teamMembers: Array<TournamentTeamMember>;
  teamHistories: Array<TournamentTeamHistory>;
  teamStats: Array<TournamentSeasonTeamStat>;
  teamForm?: Maybe<Array<TournamentTeamForm>>;
  teamMembersCount: Scalars['Int'];
  data: Array<ExtensionData>;
  seasons: Array<TournamentSeason>;
  homeStadium?: Maybe<TournamentStadium>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentTeamConnection = DefaultConnection & {
  __typename?: 'TournamentTeamConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentTeam>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentTeamCreateInput = {
  name: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  history?: Maybe<Scalars['String']>;
  foundingDate?: Maybe<Scalars['String']>;
  altLogo?: Maybe<Scalars['String']>;
  stadium?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  teamForm?: Maybe<Array<Scalars['String']>>;
  meta?: Maybe<Scalars['JSON']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type TournamentTeamFieldsConnection = DefaultConnection & {
  __typename?: 'TournamentTeamFieldsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ExtensionField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum TournamentTeamForm {
  WIN = 'WIN',
  DRAW = 'DRAW',
  LOSE = 'LOSE',
}

export type TournamentTeamGroup = {
  __typename?: 'TournamentTeamGroup';
  id: Scalars['Int'];
  groupId: Scalars['Int'];
  teamId: Scalars['Int'];
  group: TournamentGroup;
  team: TournamentTeam;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentTeamGroupConnection = DefaultConnection & {
  __typename?: 'TournamentTeamGroupConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentTeamGroup>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentTeamGroupCreateInput = {
  groupId: Scalars['Int'];
  teamId: Scalars['Int'];
};

export type TournamentTeamGroupUpdateInput = {
  groupId?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['Int']>;
};

export type TournamentTeamHistory = {
  __typename?: 'TournamentTeamHistory';
  id: Scalars['Int'];
  teamId: Scalars['Int'];
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  translationId?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentTeamHistoryConnection = DefaultConnection & {
  __typename?: 'TournamentTeamHistoryConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentTeamHistory>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentTeamHistoryCreateInput = {
  teamId: Scalars['Int'];
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  translationId?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentTeamHistoryUpdateInput = {
  teamId?: Maybe<Scalars['Int']>;
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  translationId?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentTeamMember = {
  __typename?: 'TournamentTeamMember';
  id: Scalars['Int'];
  type: TournamentTeamMemberType;
  positionId: Scalars['Int'];
  teamId: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  birthday: Scalars['String'];
  weight?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  role?: Maybe<TournamentTeamMemberRole>;
  bio?: Maybe<Scalars['String']>;
  citizenship?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  team: TournamentTeam;
  playerStats: Array<TournamentPlayerStat>;
  data: Array<ExtensionData>;
  position: GenericObject;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentTeamMemberConnection = DefaultConnection & {
  __typename?: 'TournamentTeamMemberConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentTeamMember>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentTeamMemberCreateInput = {
  type: TournamentTeamMemberType;
  positionId?: Maybe<Scalars['Int']>;
  teamId: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  birthday: Scalars['String'];
  weight?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  role?: Maybe<TournamentTeamMemberRole>;
  bio?: Maybe<Scalars['String']>;
  citizenship?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type TournamentTeamMemberFieldsConnection = DefaultConnection & {
  __typename?: 'TournamentTeamMemberFieldsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ExtensionField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum TournamentTeamMemberRole {
  DEFENDER = 'DEFENDER',
  HALFBACK = 'HALFBACK',
  GOALKEEPER = 'GOALKEEPER',
  FORWARD = 'FORWARD',
}

export type TournamentTeamMemberSocialAccount = {
  __typename?: 'TournamentTeamMemberSocialAccount';
  id: Scalars['Int'];
  state: TournamentTeamMemberSocialAccountState;
  socialNetwork: TournamentTeamMemberSocialAccountSocialNetwork;
  socialNetworkId: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  teamMemberId: Scalars['Int'];
  payload: Scalars['String'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  teamMember: TournamentTeamMember;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentTeamMemberSocialAccountConnection = DefaultConnection & {
  __typename?: 'TournamentTeamMemberSocialAccountConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentTeamMemberSocialAccount>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentTeamMemberSocialAccountCreateInput = {
  state?: Maybe<TournamentTeamMemberSocialAccountState>;
  socialNetwork: TournamentTeamMemberSocialAccountSocialNetwork;
  socialNetworkId: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  teamMemberId: Scalars['Int'];
  payload: Scalars['String'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export enum TournamentTeamMemberSocialAccountSocialNetwork {
  GOOGLE = 'GOOGLE',
  YOUTUBE = 'YOUTUBE',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  TWITCH = 'TWITCH',
  TWITTER = 'TWITTER',
  VK = 'VK',
}

export enum TournamentTeamMemberSocialAccountState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type TournamentTeamMemberSocialAccountUpdateInput = {
  state?: Maybe<TournamentTeamMemberSocialAccountState>;
  socialNetwork?: Maybe<TournamentTeamMemberSocialAccountSocialNetwork>;
  socialNetworkId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  teamMemberId?: Maybe<Scalars['Int']>;
  payload?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export enum TournamentTeamMemberType {
  PLAYER = 'PLAYER',
  COACH = 'COACH',
  REPRESENTATIVE = 'REPRESENTATIVE',
}

export type TournamentTeamMemberUpdateInput = {
  type?: Maybe<TournamentTeamMemberType>;
  positionId?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  role?: Maybe<TournamentTeamMemberRole>;
  bio?: Maybe<Scalars['String']>;
  citizenship?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type TournamentTeamUpdateInput = {
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  history?: Maybe<Scalars['String']>;
  foundingDate?: Maybe<Scalars['String']>;
  altLogo?: Maybe<Scalars['String']>;
  stadium?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  teamForm?: Maybe<Array<Scalars['String']>>;
  meta?: Maybe<Scalars['JSON']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type TournamentTour = {
  __typename?: 'TournamentTour';
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tournamentId: Scalars['Int'];
  seasonId: Scalars['Int'];
  number: Scalars['Int'];
  startDate?: Maybe<Scalars['DateTime']>;
  finishDate?: Maybe<Scalars['DateTime']>;
  state: TournamentTourState;
  sort?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
  matches: Array<TournamentMatch>;
  season: TournamentSeason;
  tournament: Tournament;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentTourConnection = DefaultConnection & {
  __typename?: 'TournamentTourConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentTour>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentTourCreateInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tournamentId: Scalars['Int'];
  seasonId: Scalars['Int'];
  number: Scalars['Int'];
  startDate?: Maybe<Scalars['DateTime']>;
  finishDate?: Maybe<Scalars['DateTime']>;
  state: TournamentTourState;
  sort?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
};

export enum TournamentTourState {
  FUTURE = 'FUTURE',
  CURRENT = 'CURRENT',
  FINISHED = 'FINISHED',
}

export type TournamentTourUpdateInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tournamentId?: Maybe<Scalars['Int']>;
  seasonId?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  finishDate?: Maybe<Scalars['DateTime']>;
  state?: Maybe<TournamentTourState>;
  sort?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentTranslation = {
  __typename?: 'TournamentTranslation';
  id: Scalars['Int'];
  entity: TournamentTranslationEntity;
  entityId: Scalars['Int'];
  localeId: Scalars['Int'];
  field: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentTranslationConnection = DefaultConnection & {
  __typename?: 'TournamentTranslationConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentTranslation>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentTranslationCreateInput = {
  entity: TournamentTranslationEntity;
  entityId: Scalars['Int'];
  localeId: Scalars['Int'];
  field: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export enum TournamentTranslationEntity {
  TOURNAMENT = 'TOURNAMENT',
  MATCH = 'MATCH',
  TEAM = 'TEAM',
  TEAM_MEMBER = 'TEAM_MEMBER',
}

export type TournamentTranslationUpdateInput = {
  entity?: Maybe<TournamentTranslationEntity>;
  entityId?: Maybe<Scalars['Int']>;
  localeId?: Maybe<Scalars['Int']>;
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type TournamentUpdateInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  sport?: Maybe<TournamentSport>;
  state?: Maybe<TournamentState>;
  system?: Maybe<TournamentSystem>;
  sort?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type UnisenderCreateListInput = {
  title: Scalars['String'];
};

export type UnisenderList = {
  __typename?: 'UnisenderList';
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
};

export type UpdateExtensionFieldInput = {
  relationId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  dataType?: Maybe<EntityDataType>;
  isReadOnly?: Maybe<Scalars['Boolean']>;
  i18n?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
  translations?: Maybe<Array<ExtensionFieldTranslationInput>>;
};

export type UpdateSystemUserInput = {
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  state?: Maybe<SystemUserState>;
};

export type UpdateUserSettingsInput = {
  locale?: Maybe<LocaleEnum>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  uuid?: Maybe<Scalars['UUID']>;
  state: UserStateEnum;
  username: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  gender?: Maybe<UserGenderEnum>;
  devices?: Maybe<Array<UserDevice>>;
  groups?: Maybe<Array<UserGroup>>;
  accounts: Array<PaymentAccount>;
  orders: Array<CommerceOrder>;
  isVerifiedEmail: Scalars['Boolean'];
  isVerifiedPhone: Scalars['Boolean'];
  source: Scalars['String'];
  data: Array<ExtensionData>;
  analytics: Array<ExtensionData>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserAnalyticData = {
  __typename?: 'UserAnalyticData';
  id: Scalars['Int'];
  fieldId: Scalars['Int'];
  instanceId: Scalars['Int'];
  value?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserAnalyticDataCreateInput = {
  fieldId: Scalars['Int'];
  instanceId: Scalars['Int'];
  value?: Maybe<Scalars['String']>;
};

export type UserAnalyticDataUpdateInput = {
  fieldId?: Maybe<Scalars['Int']>;
  instanceId?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export type UserAnalyticField = {
  __typename?: 'UserAnalyticField';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  relationId: Scalars['Int'];
  name: Scalars['String'];
  dataType: UserAnalyticFieldDataType;
  isReadOnly: Scalars['Boolean'];
  sort: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserAnalyticFieldConnection = DefaultConnection & {
  __typename?: 'UserAnalyticFieldConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<UserAnalyticField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type UserAnalyticFieldCreateInput = {
  relationId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  dataType: UserAnalyticFieldDataType;
  isReadOnly?: Maybe<Scalars['Boolean']>;
  sort: Scalars['Int'];
};

export enum UserAnalyticFieldDataType {
  BOOLEAN = 'BOOLEAN',
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  ENUM = 'ENUM',
  ARRAY = 'ARRAY',
  FILES = 'FILES',
  JSON = 'JSON',
}

export type UserAnalyticFieldUpdateInput = {
  relationId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  dataType?: Maybe<UserAnalyticFieldDataType>;
  isReadOnly?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['Int']>;
};

export type UserApplication = {
  __typename?: 'UserApplication';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  typeId: Scalars['Int'];
  payload?: Maybe<Scalars['JSON']>;
  state: UserApplicationState;
  comment?: Maybe<Scalars['String']>;
  assigneeId?: Maybe<Scalars['Int']>;
  data: Array<ExtensionData>;
  user?: Maybe<User>;
  type: UserApplicationType;
  assignee?: Maybe<SystemUser>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserApplicationConnection = DefaultConnection & {
  __typename?: 'UserApplicationConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<UserApplication>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type UserApplicationCreateInput = {
  userId?: Maybe<Scalars['Int']>;
  typeId: Scalars['Int'];
  payload?: Maybe<Scalars['JSON']>;
  state?: Maybe<UserApplicationState>;
  comment?: Maybe<Scalars['String']>;
  assigneeId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type UserApplicationFieldsConnection = DefaultConnection & {
  __typename?: 'UserApplicationFieldsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ExtensionField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type UserApplicationLog = {
  __typename?: 'UserApplicationLog';
  id: Scalars['Int'];
  applicationId: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  systemUserId?: Maybe<Scalars['Int']>;
  type: UserApplicationLogType;
  data?: Maybe<Scalars['JSON']>;
  comment?: Maybe<Scalars['String']>;
  application: UserApplication;
  user?: Maybe<User>;
  systemUser?: Maybe<SystemUser>;
  createdAt: Scalars['DateTime'];
};

export enum UserApplicationLogType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  COMMENT = 'COMMENT',
}

export enum UserApplicationState {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  NEW = 'NEW',
  DELETED = 'DELETED',
}

export type UserApplicationStats = {
  __typename?: 'UserApplicationStats';
  type: UserApplicationType;
  accepted: Scalars['Int'];
  declined: Scalars['Int'];
  new: Scalars['Int'];
  deleted: Scalars['Int'];
};

export type UserApplicationType = {
  __typename?: 'UserApplicationType';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserApplicationTypeConnection = DefaultConnection & {
  __typename?: 'UserApplicationTypeConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<UserApplicationType>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type UserApplicationTypeCreateInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
};

export type UserApplicationTypeUpdateInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
};

export type UserApplicationUpdateInput = {
  userId?: Maybe<Scalars['Int']>;
  typeId?: Maybe<Scalars['Int']>;
  payload?: Maybe<Scalars['JSON']>;
  state?: Maybe<UserApplicationState>;
  comment?: Maybe<Scalars['String']>;
  assigneeId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type UserAuthResponse = {
  __typename?: 'UserAuthResponse';
  user: User;
  refreshToken: Scalars['String'];
  accessToken: Scalars['String'];
};

export type UserConnection = DefaultConnection & {
  __typename?: 'UserConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<User>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type UserCreateInput = {
  id?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['Int']>;
  username: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
  gender?: Maybe<UserGenderEnum>;
  source?: Maybe<Scalars['String']>;
  state?: Maybe<UserStateEnum>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserData = {
  __typename?: 'UserData';
  key: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type UserDataField = {
  __typename?: 'UserDataField';
  id: Scalars['Int'];
  name: Scalars['String'];
  title: Scalars['String'];
  dataType?: Maybe<UserDataFieldDataType>;
  options?: Maybe<Array<Scalars['String']>>;
  isSystem: Scalars['Boolean'];
  defaultValue?: Maybe<Scalars['String']>;
};

export type UserDataFieldCreateInput = {
  name: Scalars['String'];
  dataType: UserDataFieldDataType;
  title: Scalars['String'];
  options?: Maybe<Scalars['String']>;
  isSystem?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
};

export enum UserDataFieldDataType {
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  ENUM = 'ENUM',
  BOOLEAN = 'BOOLEAN',
}

export type UserDataFieldUpdateInput = {
  name?: Maybe<Scalars['String']>;
  dataType?: Maybe<UserDataFieldDataType>;
  title?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['String']>;
  isSystem?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
};

export type UserDevice = {
  __typename?: 'UserDevice';
  id: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  deviceId: Scalars['String'];
  installId: Scalars['String'];
  bundleId?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  deviceLocale?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  installReferrer?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  systemName?: Maybe<Scalars['String']>;
  systemVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  pushToken?: Maybe<Scalars['String']>;
  expoPushToken?: Maybe<Scalars['String']>;
  APNSPushToken?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserDeviceConnection = DefaultConnection & {
  __typename?: 'UserDeviceConnection';
  total: Scalars['Int'];
  cursor: Scalars['Int'];
  limit: Scalars['Int'];
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
  edges: Array<UserDevice>;
};

export type UserDeviceWhereInput = {
  clientId?: Maybe<Array<Scalars['Int']>>;
  userId?: Maybe<Array<Scalars['Int']>>;
  deviceId?: Maybe<Array<Scalars['String']>>;
  installId?: Maybe<Array<Scalars['String']>>;
  bundleId?: Maybe<Array<Scalars['String']>>;
  brand?: Maybe<Array<Scalars['String']>>;
  deviceLocale?: Maybe<Array<Scalars['String']>>;
  deviceName?: Maybe<Array<Scalars['String']>>;
  deviceModel?: Maybe<Array<Scalars['String']>>;
  installReferrer?: Maybe<Array<Scalars['String']>>;
  manufacturer?: Maybe<Array<Scalars['String']>>;
  systemName?: Maybe<Array<Scalars['String']>>;
  systemVersion?: Maybe<Array<Scalars['String']>>;
  appVersion?: Maybe<Array<Scalars['String']>>;
  userAgent?: Maybe<Array<Scalars['String']>>;
  pushToken?: Maybe<Array<Scalars['String']>>;
  expoPushToken?: Maybe<Array<Scalars['String']>>;
};

export type UserDocument = {
  __typename?: 'UserDocument';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  userId: Scalars['Int'];
  kidId?: Maybe<Scalars['Int']>;
  type: UserDocumentType;
  data?: Maybe<Scalars['JSON']>;
  state?: Maybe<UserDocumentState>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserDocumentConnection = DefaultConnection & {
  __typename?: 'UserDocumentConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<UserDocument>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type UserDocumentCreateInput = {
  userId: Scalars['Int'];
  kidId?: Maybe<Scalars['Int']>;
  type: UserDocumentType;
  data?: Maybe<Scalars['JSON']>;
  state?: Maybe<UserDocumentState>;
};

export enum UserDocumentState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export enum UserDocumentType {
  PASSPORT = 'PASSPORT',
  BIRTH_CERTIFICATE = 'BIRTH_CERTIFICATE',
  FAN_ID = 'FAN_ID',
}

export type UserDocumentUpdateInput = {
  userId?: Maybe<Scalars['Int']>;
  kidId?: Maybe<Scalars['Int']>;
  type?: Maybe<UserDocumentType>;
  data?: Maybe<Scalars['JSON']>;
  state?: Maybe<UserDocumentState>;
};

export type UserFieldsConnection = DefaultConnection & {
  __typename?: 'UserFieldsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ExtensionField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum UserFilterDataType {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  ARRAY = 'array',
}

export enum UserFilterType {
  SELECT = 'select',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  TEXT = 'text',
  INPUT = 'input',
  MULTISELECT = 'multiselect',
  RANGESLIDER = 'rangeSlider',
}

export enum UserGenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export type UserGroup = {
  __typename?: 'UserGroup';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  sort: Scalars['Int'];
  usersCount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserGroupConnection = DefaultConnection & {
  __typename?: 'UserGroupConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<UserGroup>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type UserGroupCreateInput = {
  name: Scalars['String'];
  sort?: Maybe<Scalars['Int']>;
  usersCount?: Maybe<Scalars['Int']>;
};

export type UserGroupUpdateInput = {
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
  usersCount?: Maybe<Scalars['Int']>;
};

export type UserImportResponse = {
  __typename?: 'UserImportResponse';
  orders?: Maybe<Scalars['Int']>;
  tickets?: Maybe<Scalars['Int']>;
};

export enum UserIncomeEnum {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export type UserKid = {
  __typename?: 'UserKid';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  userId: Scalars['Int'];
  state: UserKidState;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  gender?: Maybe<UserKidGender>;
  isSeasonTicket?: Maybe<Scalars['Boolean']>;
  seasonTicket?: Maybe<Scalars['String']>;
  isSeasonTicketPast?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['Int']>;
  user: User;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserKidConnection = DefaultConnection & {
  __typename?: 'UserKidConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<UserKid>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type UserKidCreateInput = {
  userId: Scalars['Int'];
  state?: Maybe<UserKidState>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  gender?: Maybe<UserKidGender>;
  isSeasonTicket?: Maybe<Scalars['Boolean']>;
  seasonTicket?: Maybe<Scalars['String']>;
  isSeasonTicketPast?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['Int']>;
};

export enum UserKidGender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum UserKidState {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  NEW = 'NEW',
  DELETED = 'DELETED',
}

export type UserKidUpdateInput = {
  userId?: Maybe<Scalars['Int']>;
  state?: Maybe<UserKidState>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<UserKidGender>;
  isSeasonTicket?: Maybe<Scalars['Boolean']>;
  seasonTicket?: Maybe<Scalars['String']>;
  isSeasonTicketPast?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['Int']>;
};

export type UserLocation = {
  __typename?: 'UserLocation';
  id: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  deviceId?: Maybe<Scalars['String']>;
  coords?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserLocationInput = {
  userId?: Maybe<Scalars['Int']>;
  deviceId?: Maybe<Scalars['String']>;
  coords?: Maybe<Scalars['JSON']>;
};

export type UserMatchEventSubscriber = {
  __typename?: 'UserMatchEventSubscriber';
  deviceId: Scalars['Int'];
  clientId: Scalars['Int'];
  eventName: UserMatchEventSubscriberEventName;
  teamIdx?: Maybe<Array<Scalars['Int']>>;
  device: UserDevice;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserMatchEventSubscriberConnection = DefaultConnection & {
  __typename?: 'UserMatchEventSubscriberConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<UserMatchEventSubscriber>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum UserMatchEventSubscriberEventName {
  AUTO_GOAL = 'AUTO_GOAL',
  CORNER_KICK = 'CORNER_KICK',
  FREE_KICK = 'FREE_KICK',
  GOAL = 'GOAL',
  IN = 'IN',
  MATCH_FINISH = 'MATCH_FINISH',
  MATCH_HALFTIME = 'MATCH_HALFTIME',
  MATCH_START = 'MATCH_START',
  OUT = 'OUT',
  PASS = 'PASS',
  ASSIST = 'ASSIST',
  PENALTY = 'PENALTY',
  PENALTY_NO = 'PENALTY_NO',
  RED_CARD = 'RED_CARD',
  TRAUMA = 'TRAUMA',
  YELLOW_CARD = 'YELLOW_CARD',
  YELLOW_CARD2 = 'YELLOW_CARD2',
  COMMENT = 'COMMENT',
  VAR = 'VAR',
  VIDEO = 'VIDEO',
  FINAL = 'FINAL',
}

export type UserPublicProfile = {
  __typename?: 'UserPublicProfile';
  id: Scalars['ID'];
  username: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  locale: LocaleEnum;
  avatar?: Maybe<Scalars['String']>;
};

export type UserPushInput = {
  userId: Array<Scalars['Int']>;
  templateId?: Maybe<Scalars['Int']>;
  transportId?: Maybe<Scalars['Int']>;
  data?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type UsersConnection = {
  __typename?: 'UsersConnection';
  total: Scalars['Int'];
  hasMore: Scalars['Boolean'];
  edges: Array<Maybe<User>>;
};

export type UserSetting = {
  __typename?: 'UserSetting';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  emailSettings: Scalars['JSON'];
  defaultGroupId?: Maybe<Scalars['Int']>;
  defaultGroup?: Maybe<UserGroup>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  pushTemplate?: Maybe<Scalars['Int']>;
  emailTemplate?: Maybe<Scalars['Int']>;
  pushTransport?: Maybe<Scalars['Int']>;
  emailTransport?: Maybe<Scalars['Int']>;
  emailSender?: Maybe<Scalars['String']>;
};

export type UserSettings = {
  __typename?: 'UserSettings';
  locale: LocaleEnum;
};

export type UserSocialAccount = {
  __typename?: 'UserSocialAccount';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  state: UserSocialAccountState;
  socialNetwork: UserSocialAccountSocialNetwork;
  socialNetworkId: Scalars['String'];
  payload: Scalars['String'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserSocialAccountConnection = DefaultConnection & {
  __typename?: 'UserSocialAccountConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<UserSocialAccount>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type UserSocialAccountCreateInput = {
  userId?: Maybe<Scalars['Int']>;
  state?: Maybe<UserSocialAccountState>;
  socialNetwork: UserSocialAccountSocialNetwork;
  socialNetworkId: Scalars['String'];
  payload: Scalars['String'];
  expiresAt?: Maybe<Scalars['DateTime']>;
};

export enum UserSocialAccountSocialNetwork {
  TWITCH = 'TWITCH',
  GOOGLE = 'GOOGLE',
  INSTAGRAM = 'INSTAGRAM',
  VK = 'VK',
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER',
  RPL = 'RPL',
  APPLE = 'APPLE',
}

export enum UserSocialAccountState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type UserSocialAccountUpdateInput = {
  userId?: Maybe<Scalars['Int']>;
  state?: Maybe<UserSocialAccountState>;
  socialNetwork?: Maybe<UserSocialAccountSocialNetwork>;
  socialNetworkId?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
};

export type UserSocialAuthResponse = {
  __typename?: 'UserSocialAuthResponse';
  socialAccount: UserSocialAccount;
  user: User;
  refreshToken: Scalars['String'];
  accessToken: Scalars['String'];
};

export enum UserStateEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
  BLOCKED = 'BLOCKED',
}

export type UserSubscriber = {
  __typename?: 'UserSubscriber';
  id: Scalars['String'];
  clientId: Scalars['Int'];
  userId: Scalars['Int'];
  subscriptionId: Scalars['Int'];
  modifierId?: Maybe<Scalars['Int']>;
  user: User;
  subscription: UserSubscription;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserSubscriberConnection = DefaultConnection & {
  __typename?: 'UserSubscriberConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<UserSubscriber>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type UserSubscriberCreateInput = {
  userId: Scalars['Int'];
  subscriptionId: Scalars['Int'];
  modifierId?: Maybe<Scalars['Int']>;
};

export type UserSubscribersLog = {
  __typename?: 'UserSubscribersLog';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  subscriptionId: Scalars['Int'];
  state: UserSubscribersLogState;
  reason: Scalars['String'];
  systemUserId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserSubscribersLogConnection = DefaultConnection & {
  __typename?: 'UserSubscribersLogConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<UserSubscribersLog>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type UserSubscribersLogCreateInput = {
  userId: Scalars['Int'];
  subscriptionId: Scalars['Int'];
  state: UserSubscribersLogState;
  reason: Scalars['String'];
  systemUserId: Scalars['Int'];
};

export enum UserSubscribersLogState {
  ADDED = 'ADDED',
  REMOVED = 'REMOVED',
}

export type UserSubscribersLogUpdateInput = {
  userId?: Maybe<Scalars['Int']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  state?: Maybe<UserSubscribersLogState>;
  reason?: Maybe<Scalars['String']>;
  systemUserId?: Maybe<Scalars['Int']>;
};

export type UserSubscriberUpdateInput = {
  userId?: Maybe<Scalars['Int']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  modifierId?: Maybe<Scalars['Int']>;
};

export type UserSubscription = {
  __typename?: 'UserSubscription';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: UserSubscriptionState;
  name: Scalars['String'];
  access: UserSubscriptionAccess;
  description?: Maybe<Scalars['String']>;
  subscribersCount: Scalars['Int'];
  isSubscriber?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum UserSubscriptionAccess {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export type UserSubscriptionConnection = DefaultConnection & {
  __typename?: 'UserSubscriptionConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<UserSubscription>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type UserSubscriptionCreateInput = {
  state?: Maybe<UserSubscriptionState>;
  name: Scalars['String'];
  access: UserSubscriptionAccess;
  description?: Maybe<Scalars['String']>;
  isSubscriber?: Maybe<Scalars['Boolean']>;
};

export enum UserSubscriptionState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type UserSubscriptionUpdateInput = {
  state?: Maybe<UserSubscriptionState>;
  name?: Maybe<Scalars['String']>;
  access?: Maybe<UserSubscriptionAccess>;
  description?: Maybe<Scalars['String']>;
  isSubscriber?: Maybe<Scalars['Boolean']>;
};

export type UserUpdateInput = {
  id?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  gender?: Maybe<UserGenderEnum>;
  source?: Maybe<Scalars['String']>;
  state?: Maybe<UserStateEnum>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type UserWhereInput = {
  id?: Maybe<Array<Scalars['Int']>>;
  uuid?: Maybe<Array<Scalars['UUID']>>;
  state?: Maybe<Array<UserStateEnum>>;
  gender?: Maybe<Array<UserGenderEnum>>;
  birthday?: Maybe<Array<Scalars['DateTime']>>;
  email?: Maybe<Array<Scalars['String']>>;
  phone?: Maybe<Array<Scalars['String']>>;
  firstName?: Maybe<Array<Scalars['String']>>;
  lastName?: Maybe<Array<Scalars['String']>>;
  middleName?: Maybe<Array<Scalars['String']>>;
  isVerifiedEmail?: Maybe<Array<Scalars['Boolean']>>;
  isVerifiedPhone?: Maybe<Array<Scalars['Boolean']>>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
  createdAt?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt?: Maybe<Array<Scalars['DateTime']>>;
};

export type WidgetsResponse = {
  __typename?: 'WidgetsResponse';
  commerceWidget: CommerceWidget;
};

export type WidgetSummaryGraph = {
  __typename?: 'WidgetSummaryGraph';
  name: Scalars['String'];
  dataset: Array<WidgetSummaryGraphDataset>;
};

export type WidgetSummaryGraphDataset = {
  __typename?: 'WidgetSummaryGraphDataset';
  label: Scalars['String'];
  data: Array<WidgetSummaryGraphDatasetData>;
};

export type WidgetSummaryGraphDatasetData = {
  __typename?: 'WidgetSummaryGraphDatasetData';
  x: Scalars['Date'];
  y: Scalars['Int'];
};

export type ImportColumnsFragment = { __typename?: 'ImportColumns' } & Pick<ImportColumns, 'name' | 'field' | 'sort'>;

export type ImportUploadResponseFragment = { __typename?: 'ImportUploadResponse' } & Pick<
  ImportUploadResponse,
  'selection' | 'model'
> & {
    file: { __typename?: 'ImportFile' } & ImportFileFragment;
    columns: Array<{ __typename?: 'ImportColumns' } & ImportColumnsFragment>;
    fields: Array<{ __typename?: 'ImportFields' } & ImportFieldsFragment>;
  };

export type TournamentMatchProtocolFragment = { __typename?: 'TournamentMatchProtocol' } & Pick<
  TournamentMatchProtocol,
  'id' | 'matchId' | 'minute' | 'event' | 'teamMemberId' | 'teamId' | 'createdAt' | 'updatedAt'
>;

export type TournamentMatchProtocolDetailsFragment = { __typename?: 'TournamentMatchProtocol' } & Pick<
  TournamentMatchProtocol,
  'id' | 'matchId' | 'minute' | 'event' | 'teamMemberId' | 'teamId' | 'createdAt' | 'updatedAt'
> & {
    match: { __typename?: 'TournamentMatch' } & TournamentMatchFragment;
    player: Maybe<{ __typename?: 'TournamentTeamMember' } & TournamentTeamMemberFragment>;
    team: Maybe<{ __typename?: 'TournamentTeam' } & TournamentTeamFragment>;
  };

export type TournamentMatchProtocolConnectionFragment = { __typename?: 'TournamentMatchProtocolConnection' } & Pick<
  TournamentMatchProtocolConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TournamentMatchProtocol' } & TournamentMatchProtocolFragment> };

export type AclFragment = { __typename?: 'ACL' } & Pick<Acl, 'id' | 'parentId' | 'name' | 'createdAt' | 'updatedAt'>;

export type ArticleFragment = { __typename?: 'Article' } & Pick<
  Article,
  'id' | 'clientId' | 'categoryId' | 'state' | 'tags' | 'localeId' | 'createdAt' | 'publishedAt' | 'title'
> & {
    locale: { __typename?: 'SystemLocale' } & SystemLocaleFragment;
    authors: Maybe<Array<{ __typename?: 'ArticleAuthor' } & ArticleAuthorFragment>>;
  };

export type ArticleFullFragment = { __typename?: 'Article' } & {
  category: { __typename?: 'ArticleCategory' } & ArticleCategoryFragment;
  blocks: Maybe<Array<{ __typename?: 'ArticleBlock' } & ArticleBlockFragment>>;
  preview: Maybe<Array<{ __typename?: 'ArticleBlock' } & ArticleBlockFragment>>;
  authors: Maybe<Array<{ __typename?: 'ArticleAuthor' } & ArticleAuthorFragment>>;
  links: Maybe<Array<{ __typename?: 'ArticleLink' } & ArticleLinkFragment>>;
  log: Maybe<Array<{ __typename?: 'ArticleLog' } & ArticleLogFragment>>;
} & ArticleFragment;

export type ArticleAuthorFragment = { __typename?: 'ArticleAuthor' } & Pick<
  ArticleAuthor,
  'id' | 'articleId' | 'userId' | 'role' | 'createdAt' | 'updatedAt'
> & { user: { __typename?: 'SystemUser' } & SystemUserFragment };

export type ArticleAuthorFullFragment = { __typename?: 'ArticleAuthor' } & Pick<ArticleAuthor, 'userId' | 'role'> & {
    user: { __typename?: 'SystemUser' } & SystemUserFragment;
  };

export type ArticleAuthorConnectionFragment = { __typename?: 'ArticleAuthorConnection' } & Pick<
  ArticleAuthorConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'ArticleAuthor' } & ArticleAuthorFragment> };

export type ArticleBlockFragment = { __typename?: 'ArticleBlock' } & Pick<
  ArticleBlock,
  'id' | 'articleId' | 'authorId' | 'name' | 'type' | 'data' | 'isPreview' | 'sort' | 'createdAt' | 'updatedAt'
>;

export type ArticleBlockAuthorFragment = { __typename?: 'ArticleBlockAuthor' } & Pick<ArticleBlockAuthor, 'blockId'> & {
    author: { __typename?: 'SystemUser' } & SystemUserFragment;
  };

export type ArticleBlockAuthorsConnectionFragment = { __typename?: 'ArticleBlockAuthorsConnection' } & Pick<
  ArticleBlockAuthorsConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'ArticleBlockAuthor' } & ArticleBlockAuthorFragment> };

export type ArticleBlockConnectionFragment = { __typename?: 'ArticleBlockConnection' } & Pick<
  ArticleBlockConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'ArticleBlock' } & ArticleBlockFragment> };

export type ArticleBlockTitleFragment = { __typename?: 'ArticleBlockTitle' } & Pick<ArticleBlockTitle, 'value'>;

export type ArticleCategoryConnectionFragment = { __typename?: 'ArticleCategoryConnection' } & Pick<
  ArticleCategoryConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'ArticleCategory' } & ArticleCategoryFragment> };

export type ArticleCategoryFragment = { __typename?: 'ArticleCategory' } & Pick<
  ArticleCategory,
  'id' | 'clientId' | 'name' | 'parentId' | 'createdAt'
>;

export type ArticlesConnectionFragment = { __typename?: 'ArticlesConnection' } & Pick<
  ArticlesConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'Article' } & ArticleFragment> };

export type ArticleLinkFragment = { __typename?: 'ArticleLink' } & Pick<
  ArticleLink,
  'articleId' | 'entityId' | 'entityType'
>;

export type ArticleLinkFullFragment = { __typename?: 'ArticleLink' } & Pick<
  ArticleLink,
  'articleId' | 'entityId' | 'entityType'
>;

export type ArticleLinkConnectionFragment = { __typename?: 'ArticleLinkConnection' } & Pick<
  ArticleLinkConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'ArticleLink' } & ArticleLinkFragment> };

type ArticleLinkEntityRecord_TournamentMatch_Fragment = { __typename?: 'TournamentMatch' } & TournamentMatchFragment;

type ArticleLinkEntityRecord_TournamentTour_Fragment = { __typename?: 'TournamentTour' } & Pick<TournamentTour, 'id'>;

type ArticleLinkEntityRecord_TournamentTeam_Fragment = { __typename?: 'TournamentTeam' } & Pick<TournamentTeam, 'id'>;

type ArticleLinkEntityRecord_TournamentMatchEvent_Fragment = { __typename?: 'TournamentMatchEvent' } & Pick<
  TournamentMatchEvent,
  'id'
>;

type ArticleLinkEntityRecord_TournamentTeamMember_Fragment = { __typename?: 'TournamentTeamMember' } & Pick<
  TournamentTeamMember,
  'id'
>;

export type ArticleLinkEntityRecordFragment =
  | ArticleLinkEntityRecord_TournamentMatch_Fragment
  | ArticleLinkEntityRecord_TournamentTour_Fragment
  | ArticleLinkEntityRecord_TournamentTeam_Fragment
  | ArticleLinkEntityRecord_TournamentMatchEvent_Fragment
  | ArticleLinkEntityRecord_TournamentTeamMember_Fragment;

export type ArticleLinkEntityTypeConnectionFragment = { __typename?: 'ArticleLinkEntityTypeConnection' } & Pick<
  ArticleLinkEntityTypeConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & {
    edges: Array<
      | ({ __typename?: 'TournamentMatch' } & ArticleLinkEntityRecord_TournamentMatch_Fragment)
      | ({ __typename?: 'TournamentTour' } & ArticleLinkEntityRecord_TournamentTour_Fragment)
      | ({ __typename?: 'TournamentTeam' } & ArticleLinkEntityRecord_TournamentTeam_Fragment)
      | ({ __typename?: 'TournamentMatchEvent' } & ArticleLinkEntityRecord_TournamentMatchEvent_Fragment)
      | ({ __typename?: 'TournamentTeamMember' } & ArticleLinkEntityRecord_TournamentTeamMember_Fragment)
    >;
  };

export type ArticleLogFragment = { __typename?: 'ArticleLog' } & Pick<
  ArticleLog,
  'articleId' | 'authorId' | 'data' | 'state' | 'createdAt'
>;

export type ArticleTagFragment = { __typename?: 'ArticleTag' } & Pick<ArticleTag, 'id' | 'createdAt' | 'updatedAt'>;

export type ArticleTagConnectionFragment = { __typename?: 'ArticleTagConnection' } & Pick<
  ArticleTagConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'ArticleTag' } & ArticleTagFragment> };

export type CatalogueFlatFragment = { __typename?: 'Catalogue' } & Pick<
  Catalogue,
  | 'id'
  | 'clientId'
  | 'state'
  | 'name'
  | 'description'
  | 'localeIdx'
  | 'preview'
  | 'currencyIdx'
  | 'createdAt'
  | 'updatedAt'
>;

export type CatalogueFragment = { __typename?: 'Catalogue' } & {
  locales: Maybe<Array<{ __typename?: 'Locale' } & LocaleFragment>>;
  currencies: Maybe<Array<{ __typename?: 'Currency' } & CurrencyFragment>>;
} & CatalogueFlatFragment;

export type CatalogueFullFragment = { __typename?: 'Catalogue' } & {
  locales: Maybe<Array<{ __typename?: 'Locale' } & LocaleFragment>>;
  currencies: Maybe<Array<{ __typename?: 'Currency' } & CurrencyFragment>>;
  data: Array<{ __typename?: 'ExtensionData' } & ExtensionDataFragment>;
} & CatalogueFlatFragment;

export type CatalogueCategoryFragment = { __typename?: 'CatalogueCategory' } & Pick<
  CatalogueCategory,
  'id' | 'name' | 'catalogueId' | 'state' | 'createdAt' | 'updatedAt'
>;

export type CatalogueCategoryFullFragment = { __typename?: 'CatalogueCategory' } & Pick<
  CatalogueCategory,
  'id' | 'name' | 'catalogueId' | 'state' | 'createdAt' | 'updatedAt'
> & { data: Array<{ __typename?: 'ExtensionData' } & ExtensionDataFragment> };

export type CatalogueCategoryConnectionFragment = { __typename?: 'CatalogueCategoryConnection' } & Pick<
  CatalogueCategoryConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'CatalogueCategory' } & CatalogueCategoryFragment> };

export type CatalogueConnectionFragment = { __typename?: 'CatalogueConnection' } & Pick<
  CatalogueConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'Catalogue' } & CatalogueFragment> };

export type CatalogueItemFlatFragment = { __typename?: 'CatalogueItem' } & Pick<
  CatalogueItem,
  'id' | 'catalogueId' | 'name' | 'preview' | 'state' | 'createdAt' | 'updatedAt' | 'parentId'
>;

export type CatalogueItemInCollectionFragment = { __typename?: 'CatalogueItem' } & {
  prices: Maybe<Array<{ __typename?: 'CatalogueItemPrice' } & CatalogueItemPriceFragment>>;
} & CatalogueItemFlatFragment;

export type CatalogueItemFragment = { __typename?: 'CatalogueItem' } & {
  data: Array<{ __typename?: 'ExtensionData' } & ExtensionDataFragment>;
} & CatalogueItemFlatFragment;

export type CatalogueItemConnectionFragment = { __typename?: 'CatalogueItemConnection' } & Pick<
  CatalogueItemConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'CatalogueItem' } & CatalogueItemInCollectionFragment> };

export type CatalogueItemFieldsConnectionFragment = { __typename?: 'CatalogueItemFieldsConnection' } & Pick<
  CatalogueItemFieldsConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'ExtensionField' } & ExtensionFieldFragment> };

export type CatalogueItemPriceFragment = { __typename?: 'CatalogueItemPrice' } & Pick<
  CatalogueItemPrice,
  'id' | 'currencyId' | 'value' | 'catalogueItemId' | 'createdAt' | 'updatedAt'
> & { currency: { __typename?: 'Currency' } & CurrencyFragment };

export type CatalogueItemPriceConnectionFragment = { __typename?: 'CatalogueItemPriceConnection' } & Pick<
  CatalogueItemPriceConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'CatalogueItemPrice' } & CatalogueItemPriceFragment> };

export type CatalogueSummaryFragment = { __typename?: 'CatalogueSummary' } & Pick<CatalogueSummary, 'itemsCount'> & {
    topItems: Maybe<Array<{ __typename?: 'CatalogueItem' } & CatalogueItemFragment>>;
  };

export type CatalogueSummaryWidgetFragment = { __typename?: 'CatalogueSummaryWidget' } & {
  catalogue: { __typename?: 'Catalogue' } & CatalogueFragment;
  summary: { __typename?: 'CatalogueSummary' } & CatalogueSummaryFragment;
};

export type CateringFlatFragment = { __typename?: 'Catering' } & Pick<
  Catering,
  | 'id'
  | 'clientId'
  | 'eventPlaceId'
  | 'commerceShopId'
  | 'state'
  | 'printReceiptTemplateId'
  | 'name'
  | 'sectors'
  | 'createdAt'
  | 'updatedAt'
>;

export type CateringFragment = { __typename?: 'Catering' } & {
  eventPlace: Maybe<{ __typename?: 'EventPlace' } & EventPlaceFragment>;
  commerceShop: Maybe<{ __typename?: 'CommerceShop' } & CommerceShopFlatFragment>;
} & CateringFlatFragment;

export type CateringConnectionFragment = { __typename?: 'CateringConnection' } & Pick<
  CateringConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'Catering' } & CateringFragment> };

export type ClientFragment = { __typename?: 'Client' } & Pick<
  Client,
  'id' | 'title' | 'role' | 'type' | 'logo' | 'website' | 'colors' | 'domain' | 'createdAt' | 'updatedAt'
>;

export type ClientPublicProfileFragment = { __typename?: 'ClientPublicProfile' } & Pick<
  ClientPublicProfile,
  'id' | 'title'
> & { settings: Maybe<{ __typename?: 'ClientPublicSettings' } & ClientPublicSettingsFragment> };

export type ClientPublicSettingsFragment = { __typename?: 'ClientPublicSettings' } & Pick<
  ClientPublicSettings,
  'name' | 'logo' | 'website' | 'colors' | 'defaultLocale' | 'domain'
>;

export type CommerceOrderUpdateFragment = { __typename?: 'CommerceOrder' } & Pick<
  CommerceOrder,
  | 'id'
  | 'shopId'
  | 'clientId'
  | 'state'
  | 'paymentState'
  | 'userId'
  | 'externalEntityId'
  | 'code'
  | 'total'
  | 'paidAmount'
  | 'createdAt'
  | 'updatedAt'
>;

export type CommerceOrderFragment = { __typename?: 'CommerceOrder' } & Pick<
  CommerceOrder,
  | 'id'
  | 'shopId'
  | 'clientId'
  | 'state'
  | 'paymentState'
  | 'userId'
  | 'externalEntityId'
  | 'code'
  | 'total'
  | 'paidAmount'
  | 'createdAt'
  | 'updatedAt'
> & {
    user: { __typename?: 'User' } & UserFlatFragment;
    data: Maybe<Array<{ __typename?: 'CommerceOrderData' } & Pick<CommerceOrderData, 'key' | 'value'>>>;
    items: Maybe<Array<{ __typename?: 'CommerceOrderItem' } & CommerceOrderItemFragment>>;
  };

export type CommerceOrderConnectionFragment = { __typename?: 'CommerceOrderConnection' } & Pick<
  CommerceOrderConnection,
  'total' | 'limit' | 'cursor' | 'searchable' | 'sortable'
> & { edges: Array<{ __typename?: 'CommerceOrder' } & CommerceOrderFragment> };

export type CommerceOrderDataFragment = { __typename?: 'CommerceOrderData' } & Pick<
  CommerceOrderData,
  'id' | 'value' | 'orderFieldId' | 'key' | 'createdAt' | 'updatedAt'
> & { field: { __typename?: 'CommerceOrderField' } & CommerceOrderFieldFragment };

export type CommerceOrderDataConnectionFragment = { __typename?: 'CommerceOrderDataConnection' } & Pick<
  CommerceOrderDataConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'CommerceOrderData' } & CommerceOrderDataFragment> };

export type CommerceOrderFieldFragment = { __typename?: 'CommerceOrderField' } & Pick<
  CommerceOrderField,
  'id' | 'shopId' | 'name' | 'dataType' | 'hasLocale' | 'sort' | 'createdAt' | 'updatedAt'
>;

export type CommerceOrderFieldConnectionFragment = { __typename?: 'CommerceOrderFieldConnection' } & Pick<
  CommerceOrderFieldConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'CommerceOrderField' } & CommerceOrderFieldFragment> };

export type CommerceOrderFieldTranslationFragment = { __typename?: 'CommerceOrderFieldTranslation' } & Pick<
  CommerceOrderFieldTranslation,
  'id' | 'localeId' | 'value' | 'createdAt' | 'updatedAt'
>;

export type CommerceOrderItemFlatFragment = { __typename?: 'CommerceOrderItem' } & Pick<
  CommerceOrderItem,
  | 'id'
  | 'orderId'
  | 'state'
  | 'catalogueItemId'
  | 'discountId'
  | 'currencyId'
  | 'qty'
  | 'price'
  | 'discountedPrice'
  | 'total'
  | 'createdAt'
  | 'updatedAt'
>;

export type CommerceOrderItemFragment = { __typename?: 'CommerceOrderItem' } & {
  catalogueItem: { __typename?: 'CatalogueItem' } & CatalogueItemFlatFragment;
} & CommerceOrderItemFlatFragment;

export type CommerceOrderItemConnectionFragment = { __typename?: 'CommerceOrderItemConnection' } & Pick<
  CommerceOrderItemConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'CommerceOrderItem' } & CommerceOrderItemFragment> };

export type CommerceOrderStatesWidgetDataFragment = { __typename?: 'CommerceOrderStatesWidgetData' } & Pick<
  CommerceOrderStatesWidgetData,
  'state' | 'count' | 'total'
>;

export type CommerceShopFlatFragment = { __typename?: 'CommerceShop' } & Pick<
  CommerceShop,
  | 'id'
  | 'clientId'
  | 'state'
  | 'name'
  | 'description'
  | 'codeMask'
  | 'catalogueId'
  | 'currencyId'
  | 'createdAt'
  | 'updatedAt'
  | 'settings'
>;

export type CommerceShopFragment = { __typename?: 'CommerceShop' } & {
  currency: Maybe<{ __typename?: 'Currency' } & CurrencyFragment>;
  catalogue: Maybe<{ __typename?: 'Catalogue' } & CatalogueFlatFragment>;
} & CommerceShopFlatFragment;

export type CommerceShopConnectionFragment = { __typename?: 'CommerceShopConnection' } & Pick<
  CommerceShopConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'CommerceShop' } & CommerceShopFragment> };

export type CommerceShopGraphFragment = { __typename?: 'CommerceShopGraph' } & Pick<CommerceShopGraph, 'name'> & {
    data: Maybe<
      Array<{ __typename?: 'CommerceShopGraphData' } & Pick<CommerceShopGraphData, 'date' | 'total' | 'paidAmount'>>
    >;
  };

export type CommerceShopSummaryFragment = { __typename?: 'CommerceShopSummary' } & Pick<
  CommerceShopSummary,
  'ordersCount' | 'itemsCount'
> & { topItems: Maybe<Array<{ __typename?: 'CatalogueItem' } & CatalogueItemFragment>> };

export type CommerceSummaryWidgetFragment = { __typename?: 'CommerceSummaryWidget' } & {
  shop: { __typename?: 'CommerceShop' } & CommerceShopFragment;
  summary: { __typename?: 'CommerceShopSummary' } & CommerceShopSummaryFragment;
  graphs: Maybe<Array<{ __typename?: 'CommerceShopGraph' } & CommerceShopGraphFragment>>;
};

export type CommerceTemplateFragment = { __typename?: 'CommerceTemplate' } & Pick<
  CommerceTemplate,
  'id' | 'clientId' | 'name' | 'preview' | 'body' | 'createdAt' | 'updatedAt'
>;

export type CommerceTemplateConnectionFragment = { __typename?: 'CommerceTemplateConnection' } & Pick<
  CommerceTemplateConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'CommerceTemplate' } & CommerceTemplateFragment> };

export type CommonGraphFragment = { __typename?: 'CommonGraph' } & Pick<CommonGraph, 'name'> & {
    dataset: Array<{ __typename?: 'CommonGraphDataset' } & CommonGraphDatasetFragment>;
  };

export type CommonGraphDatasetFragment = { __typename?: 'CommonGraphDataset' } & Pick<CommonGraphDataset, 'label'> & {
    data: Array<{ __typename?: 'CommonGraphDatasetData' } & CommonGraphDatasetDataFragment>;
  };

export type CommonGraphDatasetDataFragment = { __typename?: 'CommonGraphDatasetData' } & Pick<
  CommonGraphDatasetData,
  'x' | 'y'
>;

export type ContentTranslationFragment = { __typename?: 'ContentTranslation' } & Pick<
  ContentTranslation,
  'id' | 'clientId' | 'channel' | 'localeId' | 'key' | 'value' | 'createdAt' | 'updatedAt'
>;

export type CurrencyFragment = { __typename?: 'Currency' } & Pick<
  Currency,
  'id' | 'iso3' | 'name' | 'sign' | 'isDefault' | 'isVirtual' | 'createdAt' | 'updatedAt'
>;

type DefaultConnection_ArticlesConnection_Fragment = { __typename?: 'ArticlesConnection' } & Pick<
  ArticlesConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_ArticleBlockAuthorsConnection_Fragment = { __typename?: 'ArticleBlockAuthorsConnection' } & Pick<
  ArticleBlockAuthorsConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_ArticleLinkEntityTypeConnection_Fragment = {
  __typename?: 'ArticleLinkEntityTypeConnection';
} & Pick<ArticleLinkEntityTypeConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_ArticleArticleTagConnection_Fragment = { __typename?: 'ArticleArticleTagConnection' } & Pick<
  ArticleArticleTagConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_ArticleAuthorConnection_Fragment = { __typename?: 'ArticleAuthorConnection' } & Pick<
  ArticleAuthorConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_ArticleBlockConnection_Fragment = { __typename?: 'ArticleBlockConnection' } & Pick<
  ArticleBlockConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_ArticleCategoryConnection_Fragment = { __typename?: 'ArticleCategoryConnection' } & Pick<
  ArticleCategoryConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_ArticleLinkConnection_Fragment = { __typename?: 'ArticleLinkConnection' } & Pick<
  ArticleLinkConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_ArticleTagConnection_Fragment = { __typename?: 'ArticleTagConnection' } & Pick<
  ArticleTagConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_CatalogueConnection_Fragment = { __typename?: 'CatalogueConnection' } & Pick<
  CatalogueConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_CatalogueFieldsConnection_Fragment = { __typename?: 'CatalogueFieldsConnection' } & Pick<
  CatalogueFieldsConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_CatalogueCategoryConnection_Fragment = { __typename?: 'CatalogueCategoryConnection' } & Pick<
  CatalogueCategoryConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_CatalogueCategoryFieldsConnection_Fragment = {
  __typename?: 'CatalogueCategoryFieldsConnection';
} & Pick<CatalogueCategoryFieldsConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_CatalogueItemConnection_Fragment = { __typename?: 'CatalogueItemConnection' } & Pick<
  CatalogueItemConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_CatalogueItemFieldsConnection_Fragment = { __typename?: 'CatalogueItemFieldsConnection' } & Pick<
  CatalogueItemFieldsConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_CatalogueItemPriceConnection_Fragment = { __typename?: 'CatalogueItemPriceConnection' } & Pick<
  CatalogueItemPriceConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_CatalogueRelationConnection_Fragment = { __typename?: 'CatalogueRelationConnection' } & Pick<
  CatalogueRelationConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_CateringConnection_Fragment = { __typename?: 'CateringConnection' } & Pick<
  CateringConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_CommerceOrderDataConnection_Fragment = { __typename?: 'CommerceOrderDataConnection' } & Pick<
  CommerceOrderDataConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_CommerceOrderFieldConnection_Fragment = { __typename?: 'CommerceOrderFieldConnection' } & Pick<
  CommerceOrderFieldConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_CommerceOrderFieldTranslationConnection_Fragment = {
  __typename?: 'CommerceOrderFieldTranslationConnection';
} & Pick<CommerceOrderFieldTranslationConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_CommerceOrderItemConnection_Fragment = { __typename?: 'CommerceOrderItemConnection' } & Pick<
  CommerceOrderItemConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_CommerceOrderLogConnection_Fragment = { __typename?: 'CommerceOrderLogConnection' } & Pick<
  CommerceOrderLogConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_CommerceSettingsConnection_Fragment = { __typename?: 'CommerceSettingsConnection' } & Pick<
  CommerceSettingsConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_CommerceShopConnection_Fragment = { __typename?: 'CommerceShopConnection' } & Pick<
  CommerceShopConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_CommerceTemplateConnection_Fragment = { __typename?: 'CommerceTemplateConnection' } & Pick<
  CommerceTemplateConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_CommerceOrderConnection_Fragment = { __typename?: 'CommerceOrderConnection' } & Pick<
  CommerceOrderConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_ContentTranslationConnection_Fragment = { __typename?: 'ContentTranslationConnection' } & Pick<
  ContentTranslationConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_DisputeConnection_Fragment = { __typename?: 'DisputeConnection' } & Pick<
  DisputeConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_DisputeDisputeResponseConnection_Fragment = {
  __typename?: 'DisputeDisputeResponseConnection';
} & Pick<DisputeDisputeResponseConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_DisputeRatingConnection_Fragment = { __typename?: 'DisputeRatingConnection' } & Pick<
  DisputeRatingConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_DisputeRatingBySeasonConnection_Fragment = {
  __typename?: 'DisputeRatingBySeasonConnection';
} & Pick<DisputeRatingBySeasonConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_DisputeRatingByTourConnection_Fragment = { __typename?: 'DisputeRatingByTourConnection' } & Pick<
  DisputeRatingByTourConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_EventConnection_Fragment = { __typename?: 'EventConnection' } & Pick<
  EventConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_EventFieldsConnection_Fragment = { __typename?: 'EventFieldsConnection' } & Pick<
  EventFieldsConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_EventEventAccessConnection_Fragment = { __typename?: 'EventEventAccessConnection' } & Pick<
  EventEventAccessConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_EventEventWhitelistConnection_Fragment = { __typename?: 'EventEventWhitelistConnection' } & Pick<
  EventEventWhitelistConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_EventOrderConnection_Fragment = { __typename?: 'EventOrderConnection' } & Pick<
  EventOrderConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_EventOrderFieldsConnection_Fragment = { __typename?: 'EventOrderFieldsConnection' } & Pick<
  EventOrderFieldsConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_EventOrderTicketConnection_Fragment = { __typename?: 'EventOrderTicketConnection' } & Pick<
  EventOrderTicketConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_EventOrderTicketFieldsConnection_Fragment = {
  __typename?: 'EventOrderTicketFieldsConnection';
} & Pick<EventOrderTicketFieldsConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_EventPlaceConnection_Fragment = { __typename?: 'EventPlaceConnection' } & Pick<
  EventPlaceConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_EventPlaceSectorConnection_Fragment = { __typename?: 'EventPlaceSectorConnection' } & Pick<
  EventPlaceSectorConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_EventReserveConnection_Fragment = { __typename?: 'EventReserveConnection' } & Pick<
  EventReserveConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_EventTypeConnection_Fragment = { __typename?: 'EventTypeConnection' } & Pick<
  EventTypeConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_LoyaltyPartnerEntityConnection_Fragment = {
  __typename?: 'LoyaltyPartnerEntityConnection';
} & Pick<LoyaltyPartnerEntityConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_LoyaltyApplicationConnection_Fragment = { __typename?: 'LoyaltyApplicationConnection' } & Pick<
  LoyaltyApplicationConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_LoyaltyPartnerConnection_Fragment = { __typename?: 'LoyaltyPartnerConnection' } & Pick<
  LoyaltyPartnerConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_LoyaltyPromoConnection_Fragment = { __typename?: 'LoyaltyPromoConnection' } & Pick<
  LoyaltyPromoConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_LoyaltyPromoCodeConnection_Fragment = { __typename?: 'LoyaltyPromoCodeConnection' } & Pick<
  LoyaltyPromoCodeConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_MessengerMailingConnection_Fragment = { __typename?: 'MessengerMailingConnection' } & Pick<
  MessengerMailingConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_MessengerMailingActivityConnection_Fragment = {
  __typename?: 'MessengerMailingActivityConnection';
} & Pick<MessengerMailingActivityConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_MessengerMailingRedirectConnection_Fragment = {
  __typename?: 'MessengerMailingRedirectConnection';
} & Pick<MessengerMailingRedirectConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_MessengerProviderConnection_Fragment = { __typename?: 'MessengerProviderConnection' } & Pick<
  MessengerProviderConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_MessengerQueueTaskConnection_Fragment = { __typename?: 'MessengerQueueTaskConnection' } & Pick<
  MessengerQueueTaskConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_MessengerTemplateConnection_Fragment = { __typename?: 'MessengerTemplateConnection' } & Pick<
  MessengerTemplateConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_MessengerTemplateProviderConnection_Fragment = {
  __typename?: 'MessengerTemplateProviderConnection';
} & Pick<MessengerTemplateProviderConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_MessengerTransportConnection_Fragment = { __typename?: 'MessengerTransportConnection' } & Pick<
  MessengerTransportConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_MessengerQueueNotificationConnection_Fragment = {
  __typename?: 'MessengerQueueNotificationConnection';
} & Pick<MessengerQueueNotificationConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_PaymentAccountConnection_Fragment = { __typename?: 'PaymentAccountConnection' } & Pick<
  PaymentAccountConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_PaymentBankCardConnection_Fragment = { __typename?: 'PaymentBankCardConnection' } & Pick<
  PaymentBankCardConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_PaymentChannelConnection_Fragment = { __typename?: 'PaymentChannelConnection' } & Pick<
  PaymentChannelConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_PaymentCurrencyConnection_Fragment = { __typename?: 'PaymentCurrencyConnection' } & Pick<
  PaymentCurrencyConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_PaymentGatewayConnection_Fragment = { __typename?: 'PaymentGatewayConnection' } & Pick<
  PaymentGatewayConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_PaymentInAppPurchaseConnection_Fragment = {
  __typename?: 'PaymentInAppPurchaseConnection';
} & Pick<PaymentInAppPurchaseConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_PaymentInAppTransactionConnection_Fragment = {
  __typename?: 'PaymentInAppTransactionConnection';
} & Pick<PaymentInAppTransactionConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_PaymentProviderConnection_Fragment = { __typename?: 'PaymentProviderConnection' } & Pick<
  PaymentProviderConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_PaymentTransactionConnection_Fragment = { __typename?: 'PaymentTransactionConnection' } & Pick<
  PaymentTransactionConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_PaymentTransactionLogConnection_Fragment = {
  __typename?: 'PaymentTransactionLogConnection';
} & Pick<PaymentTransactionLogConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_QuizAttemptConnection_Fragment = { __typename?: 'QuizAttemptConnection' } & Pick<
  QuizAttemptConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_QuizAttemptAnswerConnection_Fragment = { __typename?: 'QuizAttemptAnswerConnection' } & Pick<
  QuizAttemptAnswerConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_QuizQuestionConnection_Fragment = { __typename?: 'QuizQuestionConnection' } & Pick<
  QuizQuestionConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_QuizQuestionAnswerConnection_Fragment = { __typename?: 'QuizQuestionAnswerConnection' } & Pick<
  QuizQuestionAnswerConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_QuizConnection_Fragment = { __typename?: 'QuizConnection' } & Pick<
  QuizConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_SocialNetworkConnection_Fragment = { __typename?: 'SocialNetworkConnection' } & Pick<
  SocialNetworkConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_SocialNewsSettingConnection_Fragment = { __typename?: 'SocialNewsSettingConnection' } & Pick<
  SocialNewsSettingConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_SystemAsyncTaskConnection_Fragment = { __typename?: 'SystemAsyncTaskConnection' } & Pick<
  SystemAsyncTaskConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_SystemPrinterConnection_Fragment = { __typename?: 'SystemPrinterConnection' } & Pick<
  SystemPrinterConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_SystemTaskConnection_Fragment = { __typename?: 'SystemTaskConnection' } & Pick<
  SystemTaskConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_SystemTranslationConnection_Fragment = { __typename?: 'SystemTranslationConnection' } & Pick<
  SystemTranslationConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_SystemWebHookConnection_Fragment = { __typename?: 'SystemWebHookConnection' } & Pick<
  SystemWebHookConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_SystemLocaleConnection_Fragment = { __typename?: 'SystemLocaleConnection' } & Pick<
  SystemLocaleConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_TemplateApplePassConnection_Fragment = { __typename?: 'TemplateApplePassConnection' } & Pick<
  TemplateApplePassConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_TemplateNotificationConnection_Fragment = {
  __typename?: 'TemplateNotificationConnection';
} & Pick<TemplateNotificationConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_TemplatePdfConnection_Fragment = { __typename?: 'TemplatePDFConnection' } & Pick<
  TemplatePdfConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_TemplateProviderConnection_Fragment = { __typename?: 'TemplateProviderConnection' } & Pick<
  TemplateProviderConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_TournamentDisputeConnection_Fragment = { __typename?: 'TournamentDisputeConnection' } & Pick<
  TournamentDisputeConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_TournamentDisputeResponseConnection_Fragment = {
  __typename?: 'TournamentDisputeResponseConnection';
} & Pick<TournamentDisputeResponseConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_TournamentDisqualificationConnection_Fragment = {
  __typename?: 'TournamentDisqualificationConnection';
} & Pick<TournamentDisqualificationConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_TournamentGroupConnection_Fragment = { __typename?: 'TournamentGroupConnection' } & Pick<
  TournamentGroupConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_TournamentMatchConnection_Fragment = { __typename?: 'TournamentMatchConnection' } & Pick<
  TournamentMatchConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_TournamentMatchFieldsConnection_Fragment = {
  __typename?: 'TournamentMatchFieldsConnection';
} & Pick<TournamentMatchFieldsConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_TournamentMatchEventConnection_Fragment = {
  __typename?: 'TournamentMatchEventConnection';
} & Pick<TournamentMatchEventConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_TournamentMatchProtocolConnection_Fragment = {
  __typename?: 'TournamentMatchProtocolConnection';
} & Pick<TournamentMatchProtocolConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_TournamentMatchStatConnection_Fragment = { __typename?: 'TournamentMatchStatConnection' } & Pick<
  TournamentMatchStatConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_TournamentPlayerStatConnection_Fragment = {
  __typename?: 'TournamentPlayerStatConnection';
} & Pick<TournamentPlayerStatConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_TournamentRefereeConnection_Fragment = { __typename?: 'TournamentRefereeConnection' } & Pick<
  TournamentRefereeConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_TournamentSeasonConnection_Fragment = { __typename?: 'TournamentSeasonConnection' } & Pick<
  TournamentSeasonConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_TournamentSeasonApplicationFormConnection_Fragment = {
  __typename?: 'TournamentSeasonApplicationFormConnection';
} & Pick<TournamentSeasonApplicationFormConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_TournamentSeasonTeamConnection_Fragment = {
  __typename?: 'TournamentSeasonTeamConnection';
} & Pick<TournamentSeasonTeamConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_TournamentSeasonTeamMemberConnection_Fragment = {
  __typename?: 'TournamentSeasonTeamMemberConnection';
} & Pick<TournamentSeasonTeamMemberConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_TournamentSeasonTeamStatConnection_Fragment = {
  __typename?: 'TournamentSeasonTeamStatConnection';
} & Pick<TournamentSeasonTeamStatConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_TournamentStadiumConnection_Fragment = { __typename?: 'TournamentStadiumConnection' } & Pick<
  TournamentStadiumConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_TournamentTeamConnection_Fragment = { __typename?: 'TournamentTeamConnection' } & Pick<
  TournamentTeamConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_TournamentTeamFieldsConnection_Fragment = {
  __typename?: 'TournamentTeamFieldsConnection';
} & Pick<TournamentTeamFieldsConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_TournamentTeamGroupConnection_Fragment = { __typename?: 'TournamentTeamGroupConnection' } & Pick<
  TournamentTeamGroupConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_TournamentTeamHistoryConnection_Fragment = {
  __typename?: 'TournamentTeamHistoryConnection';
} & Pick<TournamentTeamHistoryConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_TournamentTeamMemberConnection_Fragment = {
  __typename?: 'TournamentTeamMemberConnection';
} & Pick<TournamentTeamMemberConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_TournamentTeamMemberFieldsConnection_Fragment = {
  __typename?: 'TournamentTeamMemberFieldsConnection';
} & Pick<TournamentTeamMemberFieldsConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_TournamentTeamMemberSocialAccountConnection_Fragment = {
  __typename?: 'TournamentTeamMemberSocialAccountConnection';
} & Pick<TournamentTeamMemberSocialAccountConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_TournamentTourConnection_Fragment = { __typename?: 'TournamentTourConnection' } & Pick<
  TournamentTourConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_TournamentConnection_Fragment = { __typename?: 'TournamentConnection' } & Pick<
  TournamentConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_TournamentFieldsConnection_Fragment = { __typename?: 'TournamentFieldsConnection' } & Pick<
  TournamentFieldsConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_TournamentTranslationConnection_Fragment = {
  __typename?: 'TournamentTranslationConnection';
} & Pick<TournamentTranslationConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_UserAnalyticFieldConnection_Fragment = { __typename?: 'UserAnalyticFieldConnection' } & Pick<
  UserAnalyticFieldConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_UserApplicationConnection_Fragment = { __typename?: 'UserApplicationConnection' } & Pick<
  UserApplicationConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_UserApplicationFieldsConnection_Fragment = {
  __typename?: 'UserApplicationFieldsConnection';
} & Pick<UserApplicationFieldsConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_UserApplicationTypeConnection_Fragment = { __typename?: 'UserApplicationTypeConnection' } & Pick<
  UserApplicationTypeConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_UserDocumentConnection_Fragment = { __typename?: 'UserDocumentConnection' } & Pick<
  UserDocumentConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_UserGroupConnection_Fragment = { __typename?: 'UserGroupConnection' } & Pick<
  UserGroupConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_UserKidConnection_Fragment = { __typename?: 'UserKidConnection' } & Pick<
  UserKidConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_UserMatchEventSubscriberConnection_Fragment = {
  __typename?: 'UserMatchEventSubscriberConnection';
} & Pick<UserMatchEventSubscriberConnection, 'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'>;

type DefaultConnection_UserSocialAccountConnection_Fragment = { __typename?: 'UserSocialAccountConnection' } & Pick<
  UserSocialAccountConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_UserSubscriberConnection_Fragment = { __typename?: 'UserSubscriberConnection' } & Pick<
  UserSubscriberConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_UserSubscribersLogConnection_Fragment = { __typename?: 'UserSubscribersLogConnection' } & Pick<
  UserSubscribersLogConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_UserSubscriptionConnection_Fragment = { __typename?: 'UserSubscriptionConnection' } & Pick<
  UserSubscriptionConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_UserFieldsConnection_Fragment = { __typename?: 'UserFieldsConnection' } & Pick<
  UserFieldsConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_UserConnection_Fragment = { __typename?: 'UserConnection' } & Pick<
  UserConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

type DefaultConnection_UserDeviceConnection_Fragment = { __typename?: 'UserDeviceConnection' } & Pick<
  UserDeviceConnection,
  'total' | 'cursor' | 'limit' | 'sortable' | 'searchable'
>;

export type DefaultConnectionFragment =
  | DefaultConnection_ArticlesConnection_Fragment
  | DefaultConnection_ArticleBlockAuthorsConnection_Fragment
  | DefaultConnection_ArticleLinkEntityTypeConnection_Fragment
  | DefaultConnection_ArticleArticleTagConnection_Fragment
  | DefaultConnection_ArticleAuthorConnection_Fragment
  | DefaultConnection_ArticleBlockConnection_Fragment
  | DefaultConnection_ArticleCategoryConnection_Fragment
  | DefaultConnection_ArticleLinkConnection_Fragment
  | DefaultConnection_ArticleTagConnection_Fragment
  | DefaultConnection_CatalogueConnection_Fragment
  | DefaultConnection_CatalogueFieldsConnection_Fragment
  | DefaultConnection_CatalogueCategoryConnection_Fragment
  | DefaultConnection_CatalogueCategoryFieldsConnection_Fragment
  | DefaultConnection_CatalogueItemConnection_Fragment
  | DefaultConnection_CatalogueItemFieldsConnection_Fragment
  | DefaultConnection_CatalogueItemPriceConnection_Fragment
  | DefaultConnection_CatalogueRelationConnection_Fragment
  | DefaultConnection_CateringConnection_Fragment
  | DefaultConnection_CommerceOrderDataConnection_Fragment
  | DefaultConnection_CommerceOrderFieldConnection_Fragment
  | DefaultConnection_CommerceOrderFieldTranslationConnection_Fragment
  | DefaultConnection_CommerceOrderItemConnection_Fragment
  | DefaultConnection_CommerceOrderLogConnection_Fragment
  | DefaultConnection_CommerceSettingsConnection_Fragment
  | DefaultConnection_CommerceShopConnection_Fragment
  | DefaultConnection_CommerceTemplateConnection_Fragment
  | DefaultConnection_CommerceOrderConnection_Fragment
  | DefaultConnection_ContentTranslationConnection_Fragment
  | DefaultConnection_DisputeConnection_Fragment
  | DefaultConnection_DisputeDisputeResponseConnection_Fragment
  | DefaultConnection_DisputeRatingConnection_Fragment
  | DefaultConnection_DisputeRatingBySeasonConnection_Fragment
  | DefaultConnection_DisputeRatingByTourConnection_Fragment
  | DefaultConnection_EventConnection_Fragment
  | DefaultConnection_EventFieldsConnection_Fragment
  | DefaultConnection_EventEventAccessConnection_Fragment
  | DefaultConnection_EventEventWhitelistConnection_Fragment
  | DefaultConnection_EventOrderConnection_Fragment
  | DefaultConnection_EventOrderFieldsConnection_Fragment
  | DefaultConnection_EventOrderTicketConnection_Fragment
  | DefaultConnection_EventOrderTicketFieldsConnection_Fragment
  | DefaultConnection_EventPlaceConnection_Fragment
  | DefaultConnection_EventPlaceSectorConnection_Fragment
  | DefaultConnection_EventReserveConnection_Fragment
  | DefaultConnection_EventTypeConnection_Fragment
  | DefaultConnection_LoyaltyPartnerEntityConnection_Fragment
  | DefaultConnection_LoyaltyApplicationConnection_Fragment
  | DefaultConnection_LoyaltyPartnerConnection_Fragment
  | DefaultConnection_LoyaltyPromoConnection_Fragment
  | DefaultConnection_LoyaltyPromoCodeConnection_Fragment
  | DefaultConnection_MessengerMailingConnection_Fragment
  | DefaultConnection_MessengerMailingActivityConnection_Fragment
  | DefaultConnection_MessengerMailingRedirectConnection_Fragment
  | DefaultConnection_MessengerProviderConnection_Fragment
  | DefaultConnection_MessengerQueueTaskConnection_Fragment
  | DefaultConnection_MessengerTemplateConnection_Fragment
  | DefaultConnection_MessengerTemplateProviderConnection_Fragment
  | DefaultConnection_MessengerTransportConnection_Fragment
  | DefaultConnection_MessengerQueueNotificationConnection_Fragment
  | DefaultConnection_PaymentAccountConnection_Fragment
  | DefaultConnection_PaymentBankCardConnection_Fragment
  | DefaultConnection_PaymentChannelConnection_Fragment
  | DefaultConnection_PaymentCurrencyConnection_Fragment
  | DefaultConnection_PaymentGatewayConnection_Fragment
  | DefaultConnection_PaymentInAppPurchaseConnection_Fragment
  | DefaultConnection_PaymentInAppTransactionConnection_Fragment
  | DefaultConnection_PaymentProviderConnection_Fragment
  | DefaultConnection_PaymentTransactionConnection_Fragment
  | DefaultConnection_PaymentTransactionLogConnection_Fragment
  | DefaultConnection_QuizAttemptConnection_Fragment
  | DefaultConnection_QuizAttemptAnswerConnection_Fragment
  | DefaultConnection_QuizQuestionConnection_Fragment
  | DefaultConnection_QuizQuestionAnswerConnection_Fragment
  | DefaultConnection_QuizConnection_Fragment
  | DefaultConnection_SocialNetworkConnection_Fragment
  | DefaultConnection_SocialNewsSettingConnection_Fragment
  | DefaultConnection_SystemAsyncTaskConnection_Fragment
  | DefaultConnection_SystemPrinterConnection_Fragment
  | DefaultConnection_SystemTaskConnection_Fragment
  | DefaultConnection_SystemTranslationConnection_Fragment
  | DefaultConnection_SystemWebHookConnection_Fragment
  | DefaultConnection_SystemLocaleConnection_Fragment
  | DefaultConnection_TemplateApplePassConnection_Fragment
  | DefaultConnection_TemplateNotificationConnection_Fragment
  | DefaultConnection_TemplatePdfConnection_Fragment
  | DefaultConnection_TemplateProviderConnection_Fragment
  | DefaultConnection_TournamentDisputeConnection_Fragment
  | DefaultConnection_TournamentDisputeResponseConnection_Fragment
  | DefaultConnection_TournamentDisqualificationConnection_Fragment
  | DefaultConnection_TournamentGroupConnection_Fragment
  | DefaultConnection_TournamentMatchConnection_Fragment
  | DefaultConnection_TournamentMatchFieldsConnection_Fragment
  | DefaultConnection_TournamentMatchEventConnection_Fragment
  | DefaultConnection_TournamentMatchProtocolConnection_Fragment
  | DefaultConnection_TournamentMatchStatConnection_Fragment
  | DefaultConnection_TournamentPlayerStatConnection_Fragment
  | DefaultConnection_TournamentRefereeConnection_Fragment
  | DefaultConnection_TournamentSeasonConnection_Fragment
  | DefaultConnection_TournamentSeasonApplicationFormConnection_Fragment
  | DefaultConnection_TournamentSeasonTeamConnection_Fragment
  | DefaultConnection_TournamentSeasonTeamMemberConnection_Fragment
  | DefaultConnection_TournamentSeasonTeamStatConnection_Fragment
  | DefaultConnection_TournamentStadiumConnection_Fragment
  | DefaultConnection_TournamentTeamConnection_Fragment
  | DefaultConnection_TournamentTeamFieldsConnection_Fragment
  | DefaultConnection_TournamentTeamGroupConnection_Fragment
  | DefaultConnection_TournamentTeamHistoryConnection_Fragment
  | DefaultConnection_TournamentTeamMemberConnection_Fragment
  | DefaultConnection_TournamentTeamMemberFieldsConnection_Fragment
  | DefaultConnection_TournamentTeamMemberSocialAccountConnection_Fragment
  | DefaultConnection_TournamentTourConnection_Fragment
  | DefaultConnection_TournamentConnection_Fragment
  | DefaultConnection_TournamentFieldsConnection_Fragment
  | DefaultConnection_TournamentTranslationConnection_Fragment
  | DefaultConnection_UserAnalyticFieldConnection_Fragment
  | DefaultConnection_UserApplicationConnection_Fragment
  | DefaultConnection_UserApplicationFieldsConnection_Fragment
  | DefaultConnection_UserApplicationTypeConnection_Fragment
  | DefaultConnection_UserDocumentConnection_Fragment
  | DefaultConnection_UserGroupConnection_Fragment
  | DefaultConnection_UserKidConnection_Fragment
  | DefaultConnection_UserMatchEventSubscriberConnection_Fragment
  | DefaultConnection_UserSocialAccountConnection_Fragment
  | DefaultConnection_UserSubscriberConnection_Fragment
  | DefaultConnection_UserSubscribersLogConnection_Fragment
  | DefaultConnection_UserSubscriptionConnection_Fragment
  | DefaultConnection_UserFieldsConnection_Fragment
  | DefaultConnection_UserConnection_Fragment
  | DefaultConnection_UserDeviceConnection_Fragment;

export type DisputeFlatFragment = { __typename?: 'Dispute' } & Pick<
  Dispute,
  | 'id'
  | 'state'
  | 'result'
  | 'confirmation'
  | 'userId'
  | 'matchId'
  | 'amount'
  | 'winAmount'
  | 'text'
  | 'createdAt'
  | 'updatedAt'
>;

export type DisputeFragment = { __typename?: 'Dispute' } & {
  user: { __typename?: 'User' } & UserFlatFragment;
  match: { __typename?: 'TournamentMatch' } & TournamentMatchFragment;
} & DisputeFlatFragment;

export type DisputeConnectionFragment = { __typename?: 'DisputeConnection' } & Pick<
  DisputeConnection,
  'total' | 'limit' | 'cursor' | 'sortable' | 'searchable'
> & { edges: Array<{ __typename?: 'Dispute' } & DisputeFragment> };

export type DisputeDisputeResponseFlatFragment = { __typename?: 'DisputeDisputeResponse' } & Pick<
  DisputeDisputeResponse,
  | 'id'
  | 'clientId'
  | 'disputeId'
  | 'state'
  | 'userId'
  | 'amount'
  | 'comment'
  | 'respondedAt'
  | 'createdAt'
  | 'updatedAt'
>;

export type DisputeDisputeResponseFragment = { __typename?: 'DisputeDisputeResponse' } & {
  user: { __typename?: 'User' } & UserFlatFragment;
} & DisputeDisputeResponseFlatFragment;

export type DisputeDisputeResponseConnectionFragment = { __typename?: 'DisputeDisputeResponseConnection' } & Pick<
  DisputeDisputeResponseConnection,
  'total' | 'limit' | 'cursor' | 'sortable' | 'searchable'
> & { edges: Array<{ __typename?: 'DisputeDisputeResponse' } & DisputeDisputeResponseFragment> };

export type DisputeSettingsFragment = { __typename?: 'DisputeSettings' } & Pick<
  DisputeSettings,
  'id' | 'clientId' | 'pushTemplates' | 'simulators' | 'transportId' | 'createdAt' | 'updatedAt'
>;

export type EventFragment = { __typename?: 'Event' } & Pick<
  Event,
  | 'id'
  | 'clientId'
  | 'image'
  | 'state'
  | 'title'
  | 'eventTypeId'
  | 'eventPlaceId'
  | 'description'
  | 'saleStartDate'
  | 'saleFinishDate'
  | 'startDate'
  | 'finishDate'
  | 'availableSlots'
  | 'saleLimit'
  | 'createdAt'
  | 'updatedAt'
  | 'templates'
  | 'isShowPublic'
> & { data: Array<{ __typename?: 'ExtensionData' } & ExtensionDataFragment> };

export type EventFullFragment = { __typename?: 'Event' } & {
  eventType: { __typename?: 'EventType' } & EventTypeFragment;
  tournamentMatch: Maybe<{ __typename?: 'TournamentMatch' } & TournamentMatchWidgetFragment>;
} & EventFragment;

export type EventWidgetFragment = { __typename?: 'Event' } & Pick<
  Event,
  | 'id'
  | 'clientId'
  | 'state'
  | 'title'
  | 'eventTypeId'
  | 'description'
  | 'saleStartDate'
  | 'saleFinishDate'
  | 'startDate'
  | 'finishDate'
  | 'availableSlots'
  | 'saleLimit'
  | 'templates'
  | 'createdAt'
  | 'updatedAt'
> & {
    eventType: { __typename?: 'EventType' } & EventTypeFragment;
    tournamentMatch: Maybe<{ __typename?: 'TournamentMatch' } & TournamentMatchWidgetFragment>;
  };

export type NearestMatchFragment = { __typename?: 'Event' } & Pick<
  Event,
  | 'id'
  | 'state'
  | 'title'
  | 'eventTypeId'
  | 'description'
  | 'saleStartDate'
  | 'saleFinishDate'
  | 'startDate'
  | 'finishDate'
  | 'templates'
  | 'createdAt'
  | 'updatedAt'
> & { tournamentMatch: Maybe<{ __typename?: 'TournamentMatch' } & TournamentMatchWidgetFragment> };

export type EventConnectionFragment = { __typename?: 'EventConnection' } & Pick<
  EventConnection,
  'total' | 'limit' | 'cursor' | 'sortable' | 'searchable'
> & { edges: Array<{ __typename?: 'Event' } & EventFullFragment> };

export type EventEventAccessFragment = { __typename?: 'EventEventAccess' } & Pick<
  EventEventAccess,
  'id' | 'eventId' | 'isVisibleToPublic' | 'userGroupToSector' | 'sectorUserSeatLimit' | 'createdAt' | 'updatedAt'
>;

export type EventEventAccessConnectionFragment = { __typename?: 'EventEventAccessConnection' } & Pick<
  EventEventAccessConnection,
  'total' | 'limit' | 'cursor' | 'sortable' | 'searchable'
> & { edges: Array<{ __typename?: 'EventEventAccess' } & EventEventAccessFragment> };

export type EventFieldsConnectionFragment = { __typename?: 'EventFieldsConnection' } & Pick<
  EventFieldsConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'ExtensionField' } & ExtensionFieldFragment> };

export type EventOrderFullFragment = { __typename?: 'EventOrder' } & Pick<
  EventOrder,
  | 'id'
  | 'clientId'
  | 'state'
  | 'paymentState'
  | 'discountId'
  | 'userId'
  | 'code'
  | 'total'
  | 'paidAmount'
  | 'reservedTill'
  | 'isFiscal'
  | 'meta'
  | 'createdAt'
  | 'updatedAt'
> & {
    tickets: Array<
      { __typename?: 'EventOrderTicket' } & Pick<
        EventOrderTicket,
        'id' | 'eventOrderId' | 'eventId' | 'barcode' | 'price' | 'quantity' | 'discountId' | 'createdAt' | 'updatedAt'
      >
    >;
  };

export type EventOrderFragment = { __typename?: 'EventOrder' } & Pick<
  EventOrder,
  | 'id'
  | 'clientId'
  | 'state'
  | 'paymentState'
  | 'discountId'
  | 'userId'
  | 'code'
  | 'total'
  | 'paidAmount'
  | 'reservedTill'
  | 'isFiscal'
  | 'meta'
  | 'createdAt'
  | 'updatedAt'
>;

export type EventOrderConnectionFragment = { __typename?: 'EventOrderConnection' } & Pick<
  EventOrderConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'EventOrder' } & EventOrderFullFragment> };

export type EventOrderTicketFlatFragment = { __typename?: 'EventOrderTicket' } & Pick<
  EventOrderTicket,
  'id' | 'eventOrderId' | 'eventId' | 'barcode' | 'price' | 'quantity' | 'discountId' | 'createdAt' | 'updatedAt'
>;

export type EventOrderTicketWithDataFragment = { __typename?: 'EventOrderTicket' } & {
  data: Array<{ __typename?: 'ExtensionData' } & ExtensionDataFragment>;
} & EventOrderTicketFlatFragment;

export type EventOrderTicketFullFragment = { __typename?: 'EventOrderTicket' } & {
  data: Array<{ __typename?: 'ExtensionData' } & ExtensionDataFragment>;
  eventOrder: { __typename?: 'EventOrder' } & { user: { __typename?: 'User' } & UserFlatFragment } & EventOrderFragment;
} & EventOrderTicketFlatFragment;

export type EventOrderTicketConnectionFragment = { __typename?: 'EventOrderTicketConnection' } & Pick<
  EventOrderTicketConnection,
  'total' | 'limit' | 'cursor' | 'searchable' | 'sortable'
> & { edges: Array<{ __typename?: 'EventOrderTicket' } & EventOrderTicketFullFragment> };

export type EventPlaceFragment = { __typename?: 'EventPlace' } & Pick<
  EventPlace,
  'id' | 'clientId' | 'name' | 'address' | 'createdAt' | 'updatedAt'
> & { locales: Maybe<Array<{ __typename?: 'SystemLocale' } & SystemLocaleFragment>> };

export type EventPlaceConnectionFragment = { __typename?: 'EventPlaceConnection' } & Pick<
  EventPlaceConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'EventPlace' } & EventPlaceFragment> };

export type EventPlaceSectorFragment = { __typename?: 'EventPlaceSector' } & Pick<
  EventPlaceSector,
  | 'id'
  | 'eventPlaceId'
  | 'name'
  | 'isSectorSaleEnabled'
  | 'userSeatLimit'
  | 'seatsCount'
  | 'isAllowed'
  | 'createdAt'
  | 'updatedAt'
>;

export type EventPlaceSectorConnectionFragment = { __typename?: 'EventPlaceSectorConnection' } & {
  edges: Array<{ __typename?: 'EventPlaceSector' } & EventPlaceSectorFragment>;
} & DefaultConnection_EventPlaceSectorConnection_Fragment;

export type EventTypeFragment = { __typename?: 'EventType' } & Pick<
  EventType,
  'id' | 'name' | 'description' | 'image' | 'templates' | 'createdAt' | 'updatedAt'
>;

export type EventTypeConnectionFragment = { __typename?: 'EventTypeConnection' } & Pick<
  EventTypeConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'EventType' } & EventTypeFragment> };

export type EventWidgetActiveEventsFragment = { __typename?: 'EventWidgetActiveEvents' } & Pick<
  EventWidgetActiveEvents,
  'activeCount' | 'todayCount'
>;

export type EventWidgetNearestMatchFragment = { __typename?: 'EventWidgetNearestMatch' } & Pick<
  EventWidgetNearestMatch,
  'ticketTotalSale'
> & { event: { __typename?: 'Event' } & NearestMatchFragment };

export type EventWidgetSalesFragment = { __typename?: 'EventWidgetSales' } & Pick<
  EventWidgetSales,
  'eventSaleCount' | 'ticketTotalSale'
>;

export type EventWidgetTicketSalesTotalsFragment = { __typename?: 'EventWidgetTicketSalesTotals' } & Pick<
  EventWidgetTicketSalesTotals,
  'totalCount' | 'soldCount' | 'availableCount' | 'soldAmount'
>;

export type ExtensionDataFragment = { __typename?: 'ExtensionData' } & Pick<
  ExtensionData,
  'id' | 'fieldId' | 'localeId' | 'instanceId' | 'value' | 'key' | 'createdAt' | 'updatedAt'
> & { field: { __typename?: 'ExtensionField' } & ExtensionFieldFragment };

export type ExtensionFieldFragment = { __typename?: 'ExtensionField' } & Pick<
  ExtensionField,
  'id' | 'relationId' | 'name' | 'dataType' | 'isReadOnly' | 'sort' | 'i18n' | 'createdAt' | 'updatedAt'
> & { translations: Array<{ __typename?: 'ExtensionFieldTranslation' } & ExtensionFieldTranslationFragment> };

export type ExtensionFieldContentTranslationFragment = { __typename?: 'ExtensionField' } & Pick<
  ExtensionField,
  'id' | 'relationId' | 'name' | 'dataType' | 'isReadOnly' | 'sort' | 'i18n' | 'createdAt' | 'updatedAt'
> & { translations: Array<{ __typename?: 'ExtensionFieldTranslation' } & ExtensionFieldTranslationFragment> };

export type ExtensionFieldTranslationFragment = { __typename?: 'ExtensionFieldTranslation' } & Pick<
  ExtensionFieldTranslation,
  'id' | 'localeId' | 'fieldId' | 'value' | 'createdAt' | 'updatedAt'
>;

export type FilterItemFragment = { __typename: 'FilterItem' } & Pick<
  FilterItem,
  'field' | 'type' | 'dataType' | 'isRangeAvailable' | 'isExcludAvailable' | 'min' | 'max' | 'step'
> & { options: Maybe<Array<Maybe<{ __typename: 'FilterItemOption' } & Pick<FilterItemOption, 'id' | 'name'>>>> };

export type ImportFieldsFragment = { __typename?: 'ImportFields' } & Pick<
  ImportFields,
  'name' | 'title' | 'isExtension' | 'isRequired'
>;

export type ImportFileFragment = { __typename?: 'ImportFile' } & Pick<
  ImportFile,
  'name' | 'size' | 'rowsCount' | 'mimeType' | 'colSeparator' | 'rowSeparator'
>;

export type LocaleFragment = { __typename?: 'Locale' } & Pick<
  Locale,
  'id' | 'iso' | 'title' | 'createdAt' | 'updatedAt'
>;

export type LoyaltyAccountSummaryFragment = { __typename?: 'LoyaltyAccountSummary' } & Pick<
  LoyaltyAccountSummary,
  'applicationsCount' | 'accountsCount' | 'unprocessedCount'
>;

export type LoyaltyPartnerFragment = { __typename?: 'LoyaltyPartner' } & Pick<
  LoyaltyPartner,
  | 'id'
  | 'clientId'
  | 'state'
  | 'name'
  | 'description'
  | 'logo'
  | 'website'
  | 'shopId'
  | 'providerId'
  | 'createdAt'
  | 'updatedAt'
>;

export type LoyaltyPartnerConnectionFragment = { __typename?: 'LoyaltyPartnerConnection' } & Pick<
  LoyaltyPartnerConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'LoyaltyPartner' } & LoyaltyPartnerFragment> };

export type LoyaltyPartnerEntityConnectionFragment = { __typename?: 'LoyaltyPartnerEntityConnection' } & Pick<
  LoyaltyPartnerEntityConnection,
  'total' | 'limit' | 'cursor' | 'sortable' | 'edges'
>;

export type LoyaltyPromoFragment = { __typename?: 'LoyaltyPromo' } & Pick<
  LoyaltyPromo,
  | 'id'
  | 'clientId'
  | 'name'
  | 'description'
  | 'imageUri'
  | 'state'
  | 'amount'
  | 'currencyId'
  | 'code'
  | 'codeType'
  | 'codesCount'
  | 'startDate'
  | 'finishDate'
  | 'promoCodeStartDate'
  | 'promoCodeFinishDate'
  | 'discountMaxAmount'
  | 'discountPercent'
  | 'createdAt'
  | 'updatedAt'
>;

export type ExtendedLoyaltyPromoFragment = { __typename?: 'ExtendedLoyaltyPromo' } & Pick<
  ExtendedLoyaltyPromo,
  | 'id'
  | 'clientId'
  | 'name'
  | 'description'
  | 'imageUri'
  | 'state'
  | 'amount'
  | 'currencyId'
  | 'code'
  | 'codeType'
  | 'codesCount'
  | 'startDate'
  | 'finishDate'
  | 'promoCodeStartDate'
  | 'promoCodeFinishDate'
  | 'discountMaxAmount'
  | 'discountPercent'
  | 'createdAt'
  | 'updatedAt'
  | 'usedCount'
  | 'total'
>;

export type LoyaltyPromoCodeFragment = { __typename?: 'LoyaltyPromoCode' } & Pick<
  LoyaltyPromoCode,
  | 'id'
  | 'promoId'
  | 'userId'
  | 'state'
  | 'code'
  | 'amount'
  | 'amountUsed'
  | 'currencyId'
  | 'expiredAt'
  | 'appliedAt'
  | 'createdAt'
  | 'updatedAt'
>;

export type LoyaltyPromoCodeFullFragment = { __typename?: 'LoyaltyPromoCode' } & Pick<
  LoyaltyPromoCode,
  | 'id'
  | 'promoId'
  | 'userId'
  | 'state'
  | 'code'
  | 'amount'
  | 'amountUsed'
  | 'currencyId'
  | 'expiredAt'
  | 'appliedAt'
  | 'createdAt'
  | 'updatedAt'
> & { promo: { __typename?: 'LoyaltyPromo' } & LoyaltyPromoFragment };

export type LoyaltyPromoCodeConnectionFragment = { __typename?: 'LoyaltyPromoCodeConnection' } & Pick<
  LoyaltyPromoCodeConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'LoyaltyPromoCode' } & LoyaltyPromoCodeFragment> };

export type LoyaltyPromoConnectionFragment = { __typename?: 'LoyaltyPromoConnection' } & Pick<
  LoyaltyPromoConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'ExtendedLoyaltyPromo' } & ExtendedLoyaltyPromoFragment> };

export type LoyaltySummaryGraphFragment = { __typename?: 'LoyaltySummaryGraph' } & Pick<LoyaltySummaryGraph, 'name'> & {
    dataset: Array<{ __typename?: 'LoyaltySummaryGraphDataset' } & LoyaltySummaryGraphDatasetFragment>;
  };

export type LoyaltySummaryGraphDatasetFragment = { __typename?: 'LoyaltySummaryGraphDataset' } & Pick<
  LoyaltySummaryGraphDataset,
  'label'
> & { data: Array<{ __typename?: 'LoyaltySummaryGraphDatasetData' } & LoyaltySummaryGraphDatasetDataFragment> };

export type LoyaltySummaryGraphDatasetDataFragment = { __typename?: 'LoyaltySummaryGraphDatasetData' } & Pick<
  LoyaltySummaryGraphDatasetData,
  'x' | 'y'
>;

export type LoyaltySummaryWidgetFragment = { __typename?: 'LoyaltySummaryWidget' } & {
  partner: { __typename?: 'LoyaltyPartner' } & LoyaltyPartnerFragment;
  summary: { __typename?: 'LoyaltyAccountSummary' } & LoyaltyAccountSummaryFragment;
  graphs: Maybe<Array<{ __typename?: 'LoyaltySummaryGraph' } & LoyaltySummaryGraphFragment>>;
};

export type MessengerMailingFragment = { __typename?: 'MessengerMailing' } & Pick<
  MessengerMailing,
  | 'id'
  | 'clientId'
  | 'name'
  | 'sendAfter'
  | 'sender'
  | 'channel'
  | 'preview'
  | 'transportId'
  | 'image'
  | 'state'
  | 'subject'
  | 'richText'
  | 'metaDesign'
  | 'text'
  | 'templateId'
  | 'createdAt'
  | 'updatedAt'
> & {
    stats: Maybe<Array<{ __typename?: 'MessengerQueueNotificationStat' } & MessengerQueueNotificationStatFragment>>;
    currentTask: Maybe<{ __typename?: 'MessengerQueueTask' } & MessengerQueueTaskFragment>;
  };

export type MessengerMailingConnectionFragment = { __typename?: 'MessengerMailingConnection' } & Pick<
  MessengerMailingConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'MessengerMailing' } & MessengerMailingFragment> };

export type MessengerQueueNotificationFragment = { __typename?: 'MessengerQueueNotification' } & Pick<
  MessengerQueueNotification,
  | 'id'
  | 'clientId'
  | 'mailingId'
  | 'userId'
  | 'state'
  | 'channel'
  | 'willSendAt'
  | 'sender'
  | 'recipient'
  | 'payload'
  | 'envelope'
  | 'createdAt'
  | 'updatedAt'
> & { mailing: Maybe<{ __typename?: 'MessengerMailing' } & MessengerMailingFragment> };

export type MessengerQueueNotificationConnectionFragment = {
  __typename?: 'MessengerQueueNotificationConnection';
} & Pick<MessengerQueueNotificationConnection, 'total' | 'limit' | 'cursor' | 'sortable' | 'searchable'> & {
    edges: Array<{ __typename?: 'MessengerQueueNotification' } & MessengerQueueNotificationFragment>;
  };

export type MessengerQueueNotificationStatFragment = { __typename?: 'MessengerQueueNotificationStat' } & Pick<
  MessengerQueueNotificationStat,
  'state' | 'value'
>;

export type MessengerQueueTaskFragment = { __typename?: 'MessengerQueueTask' } & Pick<
  MessengerQueueTask,
  | 'id'
  | 'mailingId'
  | 'operation'
  | 'state'
  | 'itemsToProcess'
  | 'itemsProcessed'
  | 'itemsInvalid'
  | 'createdAt'
  | 'updatedAt'
>;

export type MessengerQueueTaskConnectionFragment = { __typename?: 'MessengerQueueTaskConnection' } & Pick<
  MessengerQueueTaskConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'MessengerQueueTask' } & MessengerQueueTaskFragment> };

export type MessengerSettingFragment = { __typename?: 'MessengerSetting' } & Pick<
  MessengerSetting,
  | 'id'
  | 'clientId'
  | 'testers'
  | 'defaultSender'
  | 'createdAt'
  | 'updatedAt'
  | 'emailDefault'
  | 'defaultSubscription'
  | 'smsDefault'
  | 'pushDefault'
  | 'emailSenders'
  | 'smsSenders'
  | 'pushSenders'
>;

export type MessengerTemplateFragment = { __typename?: 'MessengerTemplate' } & Pick<
  MessengerTemplate,
  | 'id'
  | 'clientId'
  | 'state'
  | 'channel'
  | 'sender'
  | 'name'
  | 'title'
  | 'richText'
  | 'preview'
  | 'text'
  | 'createdAt'
  | 'updatedAt'
>;

export type MessengerTemplateConnectionFragment = { __typename?: 'MessengerTemplateConnection' } & Pick<
  MessengerTemplateConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'MessengerTemplate' } & MessengerTemplateFragment> };

export type MessengerTransportFragment = { __typename?: 'MessengerTransport' } & Pick<
  MessengerTransport,
  'id' | 'clientId' | 'name' | 'settings' | 'providerId' | 'state' | 'createdAt' | 'updatedAt' | 'channel'
>;

export type MessengerTransportConnectionFragment = { __typename?: 'MessengerTransportConnection' } & Pick<
  MessengerTransportConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'MessengerTransport' } & MessengerTransportFragment> };

export type MobileTranslationFragment = { __typename?: 'MobileTranslation' } & Pick<
  MobileTranslation,
  'id' | 'locale' | 'key' | 'value' | 'updatedAt'
>;

export type NavigationFragment = { __typename?: 'Navigation' } & Pick<
  Navigation,
  'id' | 'parentId' | 'name' | 'url' | 'icon' | 'sort'
> & {
    children: Maybe<
      Array<{ __typename?: 'Navigation' } & Pick<Navigation, 'id' | 'parentId' | 'name' | 'url' | 'icon' | 'sort'>>
    >;
  };

export type PaymentAccountFragment = { __typename?: 'PaymentAccount' } & Pick<
  PaymentAccount,
  | 'id'
  | 'state'
  | 'userId'
  | 'currencyId'
  | 'balance'
  | 'spent'
  | 'providerId'
  | 'providerCode'
  | 'createdAt'
  | 'updatedAt'
> & {
    user: { __typename?: 'User' } & UserFlatFragment;
    currency: { __typename?: 'PaymentCurrency' } & PaymentCurrencyFragment;
    provider: Maybe<{ __typename?: 'PaymentProvider' } & PaymentProviderFragment>;
  };

export type PaymentAccountFlatFragment = { __typename?: 'PaymentAccount' } & Pick<
  PaymentAccount,
  | 'id'
  | 'state'
  | 'userId'
  | 'currencyId'
  | 'balance'
  | 'spent'
  | 'providerId'
  | 'providerCode'
  | 'createdAt'
  | 'updatedAt'
> & {
    user: { __typename?: 'User' } & Pick<User, 'id' | 'firstName' | 'lastName' | 'middleName'> & {
        data: Array<{ __typename?: 'ExtensionData' } & Pick<ExtensionData, 'id' | 'value' | 'key'>>;
      };
  };

export type PaymentAccountConnectionFragment = { __typename?: 'PaymentAccountConnection' } & Pick<
  PaymentAccountConnection,
  'total' | 'limit' | 'cursor' | 'sortable' | 'searchable'
> & { edges: Array<{ __typename?: 'PaymentAccount' } & PaymentAccountFragment> };

export type PaymentChannelFragment = { __typename?: 'PaymentChannel' } & Pick<
  PaymentChannel,
  'id' | 'clientId' | 'state' | 'name' | 'description' | 'createdAt' | 'updatedAt'
>;

export type PaymentCurrencyFragment = { __typename?: 'PaymentCurrency' } & Pick<
  PaymentCurrency,
  'id' | 'clientId' | 'iso3' | 'name' | 'sign' | 'isDefault' | 'isVirtual' | 'createdAt' | 'updatedAt'
>;

export type PaymentCurrencyConnectionFragment = { __typename?: 'PaymentCurrencyConnection' } & Pick<
  PaymentCurrencyConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'PaymentCurrency' } & PaymentCurrencyFragment> };

export type PaymentGatewayFragment = { __typename?: 'PaymentGateway' } & Pick<
  PaymentGateway,
  'id' | 'state' | 'clientId' | 'name' | 'description' | 'createdAt' | 'updatedAt'
>;

export type PaymentGatewayConnectionFragment = { __typename?: 'PaymentGatewayConnection' } & Pick<
  PaymentGatewayConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'PaymentGateway' } & PaymentGatewayFragment> };

export type PaymentProviderFragment = { __typename?: 'PaymentProvider' } & Pick<
  PaymentProvider,
  'id' | 'gatewayId' | 'clientId' | 'state' | 'name' | 'settings' | 'createdAt' | 'updatedAt'
>;

export type PaymentProviderConnectionFragment = { __typename?: 'PaymentProviderConnection' } & Pick<
  PaymentProviderConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'PaymentProvider' } & PaymentProviderFragment> };

export type PaymentTransactionFragment = { __typename?: 'PaymentTransaction' } & Pick<
  PaymentTransaction,
  | 'id'
  | 'state'
  | 'side'
  | 'userId'
  | 'accountId'
  | 'currencyId'
  | 'providerId'
  | 'comment'
  | 'amount'
  | 'bankCardId'
  | 'orderId'
  | 'orderType'
  | 'meta'
  | 'createdAt'
  | 'updatedAt'
> & {
    user: { __typename?: 'User' } & Pick<User, 'id' | 'firstName' | 'middleName' | 'lastName'>;
    provider: Maybe<{ __typename?: 'PaymentProvider' } & Pick<PaymentProvider, 'id' | 'name'>>;
    currency: { __typename?: 'PaymentCurrency' } & Pick<PaymentCurrency, 'id' | 'name'>;
  };

export type RefundPaymentTransactionFragment = { __typename?: 'PaymentTransaction' } & Pick<
  PaymentTransaction,
  | 'id'
  | 'state'
  | 'side'
  | 'userId'
  | 'accountId'
  | 'currencyId'
  | 'providerId'
  | 'amount'
  | 'bankCardId'
  | 'orderId'
  | 'orderType'
  | 'createdAt'
  | 'updatedAt'
>;

export type PaymentTransactionConnectionFragment = { __typename?: 'PaymentTransactionConnection' } & Pick<
  PaymentTransactionConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'PaymentTransaction' } & PaymentTransactionFragment> };

export type TournamentSeasonFullFragment = { __typename?: 'TournamentSeason' } & Pick<
  TournamentSeason,
  | 'id'
  | 'title'
  | 'description'
  | 'tournamentId'
  | 'startDate'
  | 'finishDate'
  | 'state'
  | 'sort'
  | 'createdAt'
  | 'updatedAt'
> & { teams: Array<{ __typename?: 'TournamentTeam' } & TournamentTeamFragment> };

export type TournamentSeasonTitleFragment = { __typename?: 'TournamentSeason' } & Pick<
  TournamentSeason,
  'id' | 'title'
>;

export type TournamentSeasonFragment = { __typename?: 'TournamentSeason' } & Pick<
  TournamentSeason,
  | 'id'
  | 'title'
  | 'description'
  | 'tournamentId'
  | 'startDate'
  | 'finishDate'
  | 'state'
  | 'sort'
  | 'createdAt'
  | 'updatedAt'
>;

export type SeasonTeamStatsFragment = { __typename?: 'TournamentSeasonTeamStat' } & Pick<
  TournamentSeasonTeamStat,
  | 'id'
  | 'position'
  | 'points'
  | 'games'
  | 'win'
  | 'loss'
  | 'draw'
  | 'yellowCards'
  | 'redCards'
  | 'goals'
  | 'shutouts'
  | 'missedGoals'
>;

export type TournamentSeasonTournamentFragment = { __typename?: 'TournamentSeason' } & Pick<
  TournamentSeason,
  | 'id'
  | 'title'
  | 'description'
  | 'tournamentId'
  | 'startDate'
  | 'finishDate'
  | 'state'
  | 'sort'
  | 'createdAt'
  | 'updatedAt'
> & { tournament: { __typename?: 'Tournament' } & TournamentFragment };

export type SystemAsyncTaskFragment = { __typename?: 'SystemAsyncTask' } & Pick<
  SystemAsyncTask,
  'id' | 'entity' | 'entityId' | 'operation' | 'state' | 'progress' | 'createdAt' | 'updatedAt'
>;

export type SystemAsyncTaskConnectionFragment = { __typename?: 'SystemAsyncTaskConnection' } & Pick<
  SystemAsyncTaskConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'SystemAsyncTask' } & SystemAsyncTaskFragment> };

export type SystemLocaleFragment = { __typename?: 'SystemLocale' } & Pick<
  SystemLocale,
  'id' | 'iso' | 'title' | 'createdAt' | 'updatedAt'
>;

export type SystemLocaleConnectionFragment = { __typename?: 'SystemLocaleConnection' } & Pick<
  SystemLocaleConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'SystemLocale' } & SystemLocaleFragment> };

export type SystemLoginFragment = { __typename?: 'SystemLogin' } & Pick<
  SystemLogin,
  'accessToken' | 'refreshToken' | 'clientId'
> & {
    currentUser: { __typename?: 'SystemUser' } & SystemUserFragment;
    clients: Maybe<Array<{ __typename?: 'Client' } & ClientFragment>>;
  };

export type SystemPrinterFragment = { __typename?: 'SystemPrinter' } & Pick<
  SystemPrinter,
  'id' | 'clientId' | 'name' | 'title' | 'model' | 'createdAt' | 'updatedAt'
>;

export type SystemPrinterConnectionFragment = { __typename?: 'SystemPrinterConnection' } & Pick<
  SystemPrinterConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'SystemPrinter' } & SystemPrinterFragment> };

export type SystemTaskFragment = { __typename?: 'SystemTask' } & Pick<
  SystemTask,
  'id' | 'name' | 'state' | 'errors' | 'payload' | 'settings' | 'createdAt' | 'updatedAt'
>;

export type SystemTranslationFragment = { __typename?: 'SystemTranslation' } & Pick<
  SystemTranslation,
  'id' | 'locale' | 'key' | 'value' | 'updatedAt'
>;

export type SystemTranslationConnectionFragment = { __typename?: 'SystemTranslationConnection' } & Pick<
  SystemTranslationConnection,
  'total' | 'limit' | 'cursor' | 'searchable' | 'sortable'
> & { edges: Array<{ __typename?: 'SystemTranslation' } & SystemTranslationFragment> };

export type SystemUserFragment = { __typename?: 'SystemUser' } & Pick<
  SystemUser,
  | 'id'
  | 'state'
  | 'email'
  | 'username'
  | 'phone'
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'photo'
  | 'createdAt'
  | 'updatedAt'
  | 'locale'
> & {
    clients: Maybe<Array<{ __typename?: 'Client' } & ClientFragment>>;
    ACL: Maybe<Array<{ __typename?: 'ACL' } & AclFragment>>;
  };

export type SystemUserLightFragment = { __typename?: 'SystemUser' } & Pick<
  SystemUser,
  'id' | 'firstName' | 'lastName' | 'middleName' | 'photo'
>;

export type SystemUsersConnectionFragment = { __typename?: 'SystemUsersConnection' } & Pick<
  SystemUsersConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'SystemUser' } & SystemUserFragment> };

export type SystemWebHookFragment = { __typename?: 'SystemWebHook' } & Pick<
  SystemWebHook,
  'id' | 'clientId' | 'title' | 'entity' | 'operation' | 'url' | 'headers' | 'payload' | 'createdAt' | 'updatedAt'
>;

export type SystemWebHookConnectionFragment = { __typename?: 'SystemWebHookConnection' } & Pick<
  SystemWebHookConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'SystemWebHook' } & SystemWebHookFragment> };

export type TournamentTeamConnectionFragment = { __typename?: 'TournamentTeamConnection' } & Pick<
  TournamentTeamConnection,
  'total' | 'limit' | 'cursor' | 'sortable' | 'searchable'
> & { edges: Array<{ __typename?: 'TournamentTeam' } & TournamentTeamFragment> };

export type TournamentTeamWithSeasonConnectionFragment = { __typename?: 'TournamentTeamConnection' } & Pick<
  TournamentTeamConnection,
  'total' | 'limit' | 'cursor' | 'sortable' | 'searchable'
> & { edges: Array<{ __typename?: 'TournamentTeam' } & TournamentTeamWithSeasonFragment> };

export type TeamHistoryFragment = { __typename?: 'TournamentTeamHistory' } & Pick<
  TournamentTeamHistory,
  'id' | 'field' | 'value' | 'translationId' | 'createdAt' | 'updatedAt'
>;

export type TemplateApplePassFragment = { __typename?: 'TemplateApplePass' } & Pick<
  TemplateApplePass,
  'id' | 'clientId' | 'preview' | 'name' | 'pass' | 'files' | 'certificates' | 'createdAt' | 'updatedAt'
>;

export type TemplateApplePassConnectionFragment = { __typename?: 'TemplateApplePassConnection' } & Pick<
  TemplateApplePassConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TemplateApplePass' } & TemplateApplePassFragment> };

export type TemplatePdfFragment = { __typename?: 'TemplatePDF' } & Pick<
  TemplatePdf,
  'id' | 'clientId' | 'name' | 'preview' | 'body' | 'createdAt' | 'updatedAt'
>;

export type TemplatePdfConnectionFragment = { __typename?: 'TemplatePDFConnection' } & Pick<
  TemplatePdfConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TemplatePDF' } & TemplatePdfFragment> };

export type TournamentFullFragment = { __typename?: 'Tournament' } & Pick<
  Tournament,
  'id' | 'clientId' | 'title' | 'description' | 'website' | 'state' | 'preview' | 'sort' | 'createdAt' | 'updatedAt'
> & { seasons: Array<{ __typename?: 'TournamentSeason' } & TournamentSeasonTitleFragment> };

export type TournamentFragment = { __typename?: 'Tournament' } & Pick<
  Tournament,
  'id' | 'clientId' | 'title' | 'description' | 'website' | 'state' | 'preview' | 'sort' | 'createdAt' | 'updatedAt'
>;

export type TournamentConnectionFragment = { __typename?: 'TournamentConnection' } & Pick<
  TournamentConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'Tournament' } & TournamentFullFragment> };

export type TournamentDisputeFlatFragment = { __typename?: 'TournamentDispute' } & Pick<
  TournamentDispute,
  | 'id'
  | 'state'
  | 'result'
  | 'confirmation'
  | 'userId'
  | 'matchId'
  | 'amount'
  | 'winAmount'
  | 'text'
  | 'createdAt'
  | 'updatedAt'
>;

export type TournamentDisputeFragment = { __typename?: 'TournamentDispute' } & {
  user: { __typename?: 'User' } & UserFlatFragment;
  match: { __typename?: 'TournamentMatch' } & TournamentMatchFragment;
} & TournamentDisputeFlatFragment;

export type TournamentDisqualificationFragment = { __typename?: 'TournamentDisqualification' } & Pick<
  TournamentDisqualification,
  | 'id'
  | 'playerId'
  | 'reason'
  | 'tournamentId'
  | 'resolutionDate'
  | 'disqualificationValue'
  | 'leftMatches'
  | 'state'
  | 'createdAt'
  | 'updatedAt'
>;

export type TournamentDisqualificationFullFragment = { __typename?: 'TournamentDisqualification' } & Pick<
  TournamentDisqualification,
  | 'id'
  | 'playerId'
  | 'reason'
  | 'tournamentId'
  | 'resolutionDate'
  | 'disqualificationValue'
  | 'leftMatches'
  | 'state'
  | 'createdAt'
  | 'updatedAt'
> & {
    player: { __typename?: 'TournamentTeamMember' } & TournamentTeamMemberFullFragment;
    tournament: { __typename?: 'Tournament' } & TournamentFragment;
  };

export type TournamentDisqualificationConnectionFragment = {
  __typename?: 'TournamentDisqualificationConnection';
} & Pick<TournamentDisqualificationConnection, 'total' | 'limit' | 'cursor' | 'sortable'> & {
    edges: Array<{ __typename?: 'TournamentDisqualification' } & TournamentDisqualificationFullFragment>;
  };

export type TournamentMatchWidgetFragment = { __typename?: 'TournamentMatch' } & Pick<
  TournamentMatch,
  'id' | 'team1Id' | 'team2Id' | 'state' | 'startDate' | 'startTime' | 'finishedAt' | 'result'
> & {
    team1: { __typename?: 'TournamentTeam' } & TournamentTeamSmallFragment;
    team2: { __typename?: 'TournamentTeam' } & TournamentTeamSmallFragment;
  };

export type TournamentMatchFragment = { __typename?: 'TournamentMatch' } & Pick<
  TournamentMatch,
  | 'id'
  | 'team1Id'
  | 'team2Id'
  | 'team1IdGoals'
  | 'team2IdGoals'
  | 'seasonId'
  | 'tourId'
  | 'state'
  | 'videoStreams'
  | 'stadiumName'
  | 'stadiumAddress'
  | 'startDate'
  | 'startTime'
  | 'finishDate'
  | 'startedAt'
  | 'finishedAt'
  | 'createdAt'
  | 'updatedAt'
> & {
    team1: { __typename?: 'TournamentTeam' } & TournamentTeamSmallFragment;
    team2: { __typename?: 'TournamentTeam' } & TournamentTeamSmallFragment;
  };

export type TournamentMatchDetailsFragment = { __typename?: 'TournamentMatch' } & {
  team1: { __typename?: 'TournamentTeam' } & TournamentTeamFragment;
  team2: { __typename?: 'TournamentTeam' } & TournamentTeamFragment;
} & TournamentMatchFragment;

export type TournamentMatchConnectionFragment = { __typename?: 'TournamentMatchConnection' } & Pick<
  TournamentMatchConnection,
  'total' | 'limit' | 'cursor' | 'sortable' | 'searchable'
> & { edges: Array<{ __typename?: 'TournamentMatch' } & TournamentMatchFragment> };

export type TournamentMatchEventFragment = { __typename?: 'TournamentMatchEvent' } & Pick<
  TournamentMatchEvent,
  | 'id'
  | 'matchId'
  | 'datetime'
  | 'minute'
  | 'event'
  | 'teamMemberId'
  | 'teamId'
  | 'comment'
  | 'isProtocol'
  | 'linkedMatchEventId'
  | 'createdAt'
  | 'updatedAt'
> & {
    player: Maybe<{ __typename?: 'TournamentTeamMember' } & TournamentTeamMemberSmallFragment>;
    team: Maybe<{ __typename?: 'TournamentTeam' } & TournamentTeamSmallFragment>;
  };

export type TournamentMatchEventConnectionFragment = { __typename?: 'TournamentMatchEventConnection' } & Pick<
  TournamentMatchEventConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TournamentMatchEvent' } & TournamentMatchEventFragment> };

export type TournamentMatchLineUpFragment = { __typename?: 'TournamentMatchLineUp' } & Pick<
  TournamentMatchLineUp,
  | 'id'
  | 'matchId'
  | 'teamId'
  | 'teamMemberId'
  | 'refereeId'
  | 'type'
  | 'initialRole'
  | 'finalRole'
  | 'initialState'
  | 'finalState'
  | 'number'
  | 'isTeamCaptain'
  | 'createdAt'
  | 'updatedAt'
> & {
    referee: Maybe<{ __typename?: 'TournamentReferee' } & TournamentRefereeFragment>;
    team: Maybe<{ __typename?: 'TournamentTeam' } & Pick<TournamentTeam, 'id' | 'name'>>;
    teamMember: Maybe<{ __typename?: 'TournamentTeamMember' } & TournamentTeamMemberSmallFragment>;
  };

export type TournamentPlayerStatFragment = { __typename?: 'TournamentPlayerStat' } & Pick<
  TournamentPlayerStat,
  | 'id'
  | 'playerId'
  | 'seasonId'
  | 'games'
  | 'goals'
  | 'penaltyKicks'
  | 'passes'
  | 'yellowCards'
  | 'redCards'
  | 'missedGoals'
  | 'shutouts'
  | 'bombardierValue'
  | 'assistantValue'
  | 'goalsAndPassesValue'
  | 'goalkeeperValue'
  | 'warningValue'
  | 'createdAt'
  | 'updatedAt'
>;

export type TournamentPlayerStatFullFragment = { __typename?: 'TournamentPlayerStat' } & Pick<
  TournamentPlayerStat,
  | 'id'
  | 'playerId'
  | 'seasonId'
  | 'games'
  | 'goals'
  | 'penaltyKicks'
  | 'passes'
  | 'yellowCards'
  | 'redCards'
  | 'missedGoals'
  | 'shutouts'
  | 'bombardierValue'
  | 'assistantValue'
  | 'goalsAndPassesValue'
  | 'goalkeeperValue'
  | 'warningValue'
  | 'createdAt'
  | 'updatedAt'
> & { player: { __typename?: 'TournamentTeamMember' } & TournamentTeamMemberFragment };

export type TournamentPlayerStatConnectionFragment = { __typename?: 'TournamentPlayerStatConnection' } & Pick<
  TournamentPlayerStatConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TournamentPlayerStat' } & TournamentPlayerStatFragment> };

export type TournamentRefereeFragment = { __typename?: 'TournamentReferee' } & Pick<
  TournamentReferee,
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'birthday'
  | 'preview'
  | 'photo'
  | 'category'
  | 'createdAt'
  | 'updatedAt'
>;

export type TournamentRefereeConnectionFragment = { __typename?: 'TournamentRefereeConnection' } & Pick<
  TournamentRefereeConnection,
  'total' | 'limit' | 'cursor' | 'searchable' | 'sortable'
> & { edges: Array<{ __typename?: 'TournamentReferee' } & TournamentRefereeFragment> };

export type TournamentSeasonConnectionFragment = { __typename?: 'TournamentSeasonConnection' } & Pick<
  TournamentSeasonConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TournamentSeason' } & TournamentSeasonFragment> };

export type TournamentSeasonTeamFragment = { __typename?: 'TournamentSeasonTeam' } & Pick<
  TournamentSeasonTeam,
  'id' | 'seasonId' | 'teamId' | 'createdAt' | 'updatedAt'
>;

export type TournamentSeasonTeamConnectionFragment = { __typename?: 'TournamentSeasonTeamConnection' } & Pick<
  TournamentSeasonTeamConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TournamentSeasonTeam' } & TournamentSeasonTeamFragment> };

export type TournamentSeasonTeamStatFragment = { __typename?: 'TournamentSeasonTeamStat' } & Pick<
  TournamentSeasonTeamStat,
  | 'id'
  | 'seasonId'
  | 'position'
  | 'teamId'
  | 'points'
  | 'games'
  | 'win'
  | 'draw'
  | 'loss'
  | 'goals'
  | 'missedGoals'
  | 'yellowCards'
  | 'redCards'
  | 'shutouts'
  | 'sort'
  | 'createdAt'
  | 'updatedAt'
>;

export type TournamentSeasonTeamStatExtendedFragment = { __typename?: 'TournamentSeasonTeamStat' } & Pick<
  TournamentSeasonTeamStat,
  | 'id'
  | 'seasonId'
  | 'position'
  | 'teamId'
  | 'points'
  | 'games'
  | 'win'
  | 'draw'
  | 'loss'
  | 'goals'
  | 'missedGoals'
  | 'yellowCards'
  | 'redCards'
  | 'shutouts'
  | 'sort'
  | 'createdAt'
  | 'updatedAt'
> & { season: { __typename?: 'TournamentSeason' } & TournamentSeasonTournamentFragment };

export type TournamentSeasonTeamStatConnectionFragment = { __typename?: 'TournamentSeasonTeamStatConnection' } & Pick<
  TournamentSeasonTeamStatConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TournamentSeasonTeamStat' } & TournamentSeasonTeamStatExtendedFragment> };

export type TournamentStadiumFragment = { __typename?: 'TournamentStadium' } & Pick<
  TournamentStadium,
  'id' | 'teamId' | 'name' | 'photo' | 'address' | 'lat' | 'lon' | 'builtIn' | 'capacity' | 'createdAt' | 'updatedAt'
> & { team: Maybe<{ __typename?: 'TournamentTeam' } & Pick<TournamentTeam, 'id' | 'name'>> };

export type TournamentTeamFragment = { __typename?: 'TournamentTeam' } & Pick<
  TournamentTeam,
  | 'id'
  | 'clientId'
  | 'name'
  | 'logo'
  | 'altLogo'
  | 'coverPhoto'
  | 'website'
  | 'description'
  | 'history'
  | 'foundingDate'
  | 'teamForm'
  | 'createdAt'
  | 'updatedAt'
> & {
    data: Array<
      { __typename?: 'ExtensionData' } & Pick<ExtensionData, 'key' | 'value'> & {
          translations: Array<
            { __typename?: 'ExtensionDataTranslation' } & Pick<ExtensionDataTranslation, 'localeId' | 'value'>
          >;
        }
    >;
    homeStadium: Maybe<{ __typename?: 'TournamentStadium' } & Pick<TournamentStadium, 'id' | 'name' | 'address'>>;
  };

export type TournamentTeamWithSeasonFragment = { __typename?: 'TournamentTeam' } & Pick<
  TournamentTeam,
  'id' | 'clientId' | 'name' | 'logo' | 'altLogo' | 'coverPhoto' | 'website' | 'description' | 'createdAt' | 'updatedAt'
> & {
    homeStadium: Maybe<{ __typename?: 'TournamentStadium' } & Pick<TournamentStadium, 'id' | 'name' | 'address'>>;
    seasons: Array<{ __typename?: 'TournamentSeason' } & Pick<TournamentSeason, 'id' | 'title'>>;
  };

export type TournamentTeamSmallFragment = { __typename?: 'TournamentTeam' } & Pick<
  TournamentTeam,
  'id' | 'name' | 'logo' | 'website'
>;

export type TournamentTeamDetailsFragment = { __typename?: 'TournamentTeam' } & {
  teamMembers: Array<{ __typename?: 'TournamentTeamMember' } & TournamentTeamMemberFragment>;
  teamHistories: Array<{ __typename?: 'TournamentTeamHistory' } & TeamHistoryFragment>;
  teamStats: Array<{ __typename?: 'TournamentSeasonTeamStat' } & SeasonTeamStatsFragment>;
} & TournamentTeamFragment;

export type TournamentTeamMemberSmallFragment = { __typename?: 'TournamentTeamMember' } & Pick<
  TournamentTeamMember,
  'id' | 'teamId' | 'firstName' | 'lastName' | 'middleName' | 'number' | 'role' | 'photo' | 'createdAt' | 'updatedAt'
>;

export type TournamentTeamMemberFullFragment = { __typename?: 'TournamentTeamMember' } & Pick<
  TournamentTeamMember,
  | 'id'
  | 'type'
  | 'teamId'
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'birthday'
  | 'weight'
  | 'height'
  | 'number'
  | 'role'
  | 'bio'
  | 'photo'
  | 'createdAt'
  | 'updatedAt'
> & {
    data: Array<{ __typename?: 'ExtensionData' } & ExtensionDataFragment>;
    team: { __typename?: 'TournamentTeam' } & Pick<TournamentTeam, 'id' | 'name' | 'logo' | 'website'>;
  };

export type TournamentTeamMemberFragment = { __typename?: 'TournamentTeamMember' } & Pick<
  TournamentTeamMember,
  | 'id'
  | 'type'
  | 'teamId'
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'birthday'
  | 'weight'
  | 'height'
  | 'number'
  | 'role'
  | 'bio'
  | 'photo'
  | 'createdAt'
  | 'updatedAt'
>;

export type TournamentTeamMemberConnectionFragment = { __typename?: 'TournamentTeamMemberConnection' } & Pick<
  TournamentTeamMemberConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TournamentTeamMember' } & TournamentTeamMemberFragment> };

export type TournamentTourFragment = { __typename?: 'TournamentTour' } & Pick<
  TournamentTour,
  | 'id'
  | 'title'
  | 'description'
  | 'tournamentId'
  | 'seasonId'
  | 'number'
  | 'startDate'
  | 'finishDate'
  | 'state'
  | 'sort'
  | 'createdAt'
  | 'updatedAt'
>;

export type TournamentTourDeepFragment = { __typename?: 'TournamentTour' } & {
  matches: Array<{ __typename?: 'TournamentMatch' } & TournamentMatchFragment>;
} & TournamentTourFragment;

export type TournamentTourConnectionFragment = { __typename?: 'TournamentTourConnection' } & Pick<
  TournamentTourConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TournamentTour' } & TournamentTourFragment> };

export type TournamentTourConnectionDeepFragment = { __typename?: 'TournamentTourConnection' } & Pick<
  TournamentTourConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TournamentTour' } & TournamentTourDeepFragment> };

export type UserFlatFragment = { __typename?: 'User' } & Pick<
  User,
  | 'id'
  | 'uuid'
  | 'state'
  | 'username'
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'birthday'
  | 'email'
  | 'phone'
  | 'gender'
  | 'isVerifiedEmail'
  | 'isVerifiedPhone'
  | 'createdAt'
  | 'updatedAt'
>;

export type UserFragment = { __typename?: 'User' } & {
  devices: Maybe<Array<{ __typename?: 'UserDevice' } & UserDeviceFragment>>;
  analytics: Array<{ __typename?: 'ExtensionData' } & ExtensionDataFragment>;
  data: Array<{ __typename?: 'ExtensionData' } & ExtensionDataFragment>;
  groups: Maybe<Array<{ __typename?: 'UserGroup' } & UserGroupFragment>>;
} & UserFlatFragment;

export type UserSimpleFragment = { __typename?: 'User' } & Pick<User, 'id' | 'firstName' | 'lastName' | 'middleName'>;

export type UserApplicationFragment = { __typename?: 'UserApplication' } & Pick<
  UserApplication,
  'id' | 'clientId' | 'userId' | 'typeId' | 'payload' | 'assigneeId' | 'state' | 'comment' | 'createdAt' | 'updatedAt'
> & {
    user: Maybe<{ __typename?: 'User' } & UserSimpleFragment>;
    assignee: Maybe<{ __typename?: 'SystemUser' } & SystemUserLightFragment>;
    data: Array<{ __typename?: 'ExtensionData' } & ExtensionDataFragment>;
    type: { __typename?: 'UserApplicationType' } & UserApplicationTypeFragment;
  };

export type UserApplicationLogFragment = { __typename?: 'UserApplicationLog' } & Pick<
  UserApplicationLog,
  'id' | 'applicationId' | 'userId' | 'systemUserId' | 'type' | 'data' | 'comment' | 'createdAt'
> & {
    user: Maybe<{ __typename?: 'User' } & UserSimpleFragment>;
    systemUser: Maybe<{ __typename?: 'SystemUser' } & SystemUserLightFragment>;
  };

export type UserApplicationStatsFragment = { __typename?: 'UserApplicationStats' } & Pick<
  UserApplicationStats,
  'accepted' | 'declined' | 'new' | 'deleted'
> & { type: { __typename?: 'UserApplicationType' } & Pick<UserApplicationType, 'id' | 'name'> };

export type UserApplicationTypeFragment = { __typename?: 'UserApplicationType' } & Pick<
  UserApplicationType,
  'id' | 'clientId' | 'name' | 'description' | 'createdAt' | 'updatedAt'
>;

export type UserConnectionFragment = { __typename?: 'UserConnection' } & Pick<
  UserConnection,
  'total' | 'limit' | 'cursor' | 'sortable' | 'searchable'
> & { edges: Array<{ __typename?: 'User' } & UserFragment> };

export type UserDataFragment = { __typename?: 'UserData' } & Pick<UserData, 'key' | 'title' | 'value'>;

export type UserDeviceFragment = { __typename?: 'UserDevice' } & Pick<
  UserDevice,
  | 'userId'
  | 'installId'
  | 'bundleId'
  | 'brand'
  | 'deviceLocale'
  | 'deviceName'
  | 'deviceModel'
  | 'installReferrer'
  | 'manufacturer'
  | 'systemName'
  | 'systemVersion'
  | 'appVersion'
  | 'userAgent'
  | 'pushToken'
  | 'expoPushToken'
  | 'createdAt'
  | 'updatedAt'
>;

export type UserDocumentFragment = { __typename?: 'UserDocument' } & Pick<
  UserDocument,
  'id' | 'userId' | 'kidId' | 'type' | 'data' | 'state' | 'createdAt' | 'updatedAt'
>;

export type UserDocumentConnectionFragment = { __typename?: 'UserDocumentConnection' } & Pick<
  UserDocumentConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'UserDocument' } & UserDocumentFragment> };

export type UserFieldsConnectionFragment = { __typename?: 'UserFieldsConnection' } & Pick<
  UserFieldsConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'ExtensionField' } & ExtensionFieldFragment> };

export type UserGroupFragment = { __typename?: 'UserGroup' } & Pick<
  UserGroup,
  'id' | 'clientId' | 'name' | 'usersCount' | 'sort' | 'createdAt' | 'updatedAt'
>;

export type UserGroupConnectionFragment = { __typename?: 'UserGroupConnection' } & Pick<
  UserGroupConnection,
  'total' | 'limit' | 'cursor' | 'sortable' | 'searchable'
> & { edges: Array<{ __typename?: 'UserGroup' } & UserGroupFragment> };

export type UserKidFragment = { __typename?: 'UserKid' } & Pick<
  UserKid,
  | 'id'
  | 'clientId'
  | 'userId'
  | 'state'
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'birthday'
  | 'gender'
  | 'seasonTicket'
  | 'height'
  | 'updatedAt'
  | 'createdAt'
> & { user: { __typename?: 'User' } & Pick<User, 'id' | 'firstName' | 'lastName' | 'middleName'> };

export type UserKidConnectionFragment = { __typename?: 'UserKidConnection' } & Pick<
  UserKidConnection,
  'total' | 'limit' | 'cursor' | 'searchable' | 'sortable'
> & { edges: Array<{ __typename?: 'UserKid' } & UserKidFragment> };

export type UserSettingFragment = { __typename?: 'UserSetting' } & Pick<
  UserSetting,
  'id' | 'pushTemplate' | 'emailTemplate' | 'pushTransport' | 'emailTransport' | 'emailSender'
>;

export type UserSocialAccountFragment = { __typename?: 'UserSocialAccount' } & Pick<
  UserSocialAccount,
  | 'id'
  | 'clientId'
  | 'userId'
  | 'state'
  | 'socialNetwork'
  | 'socialNetworkId'
  | 'payload'
  | 'expiresAt'
  | 'createdAt'
  | 'updatedAt'
>;

export type UserSubscriberFragment = { __typename?: 'UserSubscriber' } & Pick<
  UserSubscriber,
  'id' | 'clientId' | 'userId' | 'subscriptionId' | 'modifierId' | 'createdAt' | 'updatedAt'
> & { user: { __typename?: 'User' } & UserFlatFragment };

export type UserSubscriberConnectionFragment = { __typename?: 'UserSubscriberConnection' } & Pick<
  UserSubscriberConnection,
  'total' | 'limit' | 'cursor' | 'sortable' | 'searchable'
> & { edges: Array<{ __typename?: 'UserSubscriber' } & UserSubscriberFragment> };

export type UserSubscribersLogFragment = { __typename?: 'UserSubscribersLog' } & Pick<
  UserSubscribersLog,
  'id' | 'userId' | 'subscriptionId' | 'state' | 'reason' | 'systemUserId' | 'createdAt' | 'updatedAt'
>;

export type UserSubscribersLogConnectionFragment = { __typename?: 'UserSubscribersLogConnection' } & Pick<
  UserSubscribersLogConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'UserSubscribersLog' } & UserSubscribersLogFragment> };

export type UserSubscriptionFragment = { __typename?: 'UserSubscription' } & Pick<
  UserSubscription,
  | 'id'
  | 'clientId'
  | 'state'
  | 'name'
  | 'description'
  | 'subscribersCount'
  | 'isSubscriber'
  | 'access'
  | 'createdAt'
  | 'updatedAt'
>;

export type UserSubscriptionConnectionFragment = { __typename?: 'UserSubscriptionConnection' } & Pick<
  UserSubscriptionConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'UserSubscription' } & UserSubscriptionFragment> };

export type WidgetSummaryGraphFragment = { __typename?: 'WidgetSummaryGraph' } & Pick<WidgetSummaryGraph, 'name'> & {
    dataset: Array<{ __typename?: 'WidgetSummaryGraphDataset' } & WidgetSummaryGraphDatasetFragment>;
  };

export type WidgetSummaryGraphDatasetFragment = { __typename?: 'WidgetSummaryGraphDataset' } & Pick<
  WidgetSummaryGraphDataset,
  'label'
> & { data: Array<{ __typename?: 'WidgetSummaryGraphDatasetData' } & WidgetSummaryGraphDatasetDataFragment> };

export type WidgetSummaryGraphDatasetDataFragment = { __typename?: 'WidgetSummaryGraphDatasetData' } & Pick<
  WidgetSummaryGraphDatasetData,
  'x' | 'y'
>;

export type AddToMessengerQueueMutationVariables = {
  mailingId: Scalars['Int'];
  userId?: Maybe<Array<Scalars['Int']>>;
  query?: Maybe<DefaultQueryInput>;
};

export type AddToMessengerQueueMutation = { __typename?: 'Mutation' } & {
  addToMessengerQueue: { __typename?: 'MessengerQueueTask' } & MessengerQueueTaskFragment;
};

export type AddToUsersSubscriptionMutationVariables = {
  subscriptionId: Scalars['Int'];
  query?: Maybe<DefaultQueryInput>;
};

export type AddToUsersSubscriptionMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'addToUsersSubscription'>;

export type AddUsersToGroupMutationVariables = {
  groupId: Array<Scalars['Int']>;
  query: DefaultQueryInput;
};

export type AddUsersToGroupMutation = { __typename?: 'Mutation' } & {
  addUsersToGroup: Array<{ __typename?: 'SystemAsyncTask' } & SystemAsyncTaskFragment>;
};

export type AddUsersToGroupFromTicketsMutationVariables = {
  groupId: Array<Scalars['Int']>;
  query: DefaultQueryInput;
};

export type AddUsersToGroupFromTicketsMutation = { __typename?: 'Mutation' } & {
  addUsersToGroupFromTickets: Array<{ __typename?: 'SystemAsyncTask' } & SystemAsyncTaskFragment>;
};

export type ChargeFromAccountMutationVariables = {
  data: PaymentAccountOperationInput;
};

export type ChargeFromAccountMutation = { __typename?: 'Mutation' } & {
  chargeFromAccount: { __typename?: 'ProviderResponse' } & Pick<ProviderResponse, 'amount'>;
};

export type CommerceOrderPrintMutationVariables = {
  id: Scalars['Int'];
  printerId: Scalars['Int'];
  templateId: Scalars['Int'];
};

export type CommerceOrderPrintMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'commerceOrderPrint'>;

export type ConfirmDisputeDisputeModeratorMutationVariables = {
  id: Scalars['Int'];
  result: DisputeResult;
};

export type ConfirmDisputeDisputeModeratorMutation = { __typename?: 'Mutation' } & {
  confirmDisputeDisputeModerator: { __typename?: 'Dispute' } & DisputeFragment;
};

export type CreateArticleMutationVariables = {
  data: ArticleCreateInput;
};

export type CreateArticleMutation = { __typename?: 'Mutation' } & {
  createArticle: { __typename?: 'Article' } & ArticleFragment;
};

export type CreateArticleAuthorMutationVariables = {
  data: ArticleAuthorCreateInput;
};

export type CreateArticleAuthorMutation = { __typename?: 'Mutation' } & {
  createArticleAuthor: { __typename?: 'ArticleAuthor' } & ArticleAuthorFragment;
};

export type CreateArticleBlockMutationVariables = {
  data: ArticleBlockCreateInput;
};

export type CreateArticleBlockMutation = { __typename?: 'Mutation' } & {
  createArticleBlock: { __typename?: 'ArticleBlock' } & ArticleBlockFragment;
};

export type CreateArticleCategoryMutationVariables = {
  data: ArticleCategoryCreateInput;
};

export type CreateArticleCategoryMutation = { __typename?: 'Mutation' } & {
  createArticleCategory: { __typename?: 'ArticleCategory' } & ArticleCategoryFragment;
};

export type CreateArticleLinkMutationVariables = {
  data: ArticleLinkCreateInput;
};

export type CreateArticleLinkMutation = { __typename?: 'Mutation' } & {
  createArticleLink: { __typename?: 'ArticleLink' } & ArticleLinkFragment;
};

export type CreateArticleTagMutationVariables = {
  data: ArticleTagCreateInput;
};

export type CreateArticleTagMutation = { __typename?: 'Mutation' } & {
  createArticleTag: { __typename?: 'ArticleTag' } & ArticleTagFragment;
};

export type CreateCatalogueMutationVariables = {
  data: CatalogueCreateInput;
};

export type CreateCatalogueMutation = { __typename?: 'Mutation' } & {
  createCatalogue: { __typename?: 'Catalogue' } & CatalogueFlatFragment;
};

export type CreateCatalogueCategoryMutationVariables = {
  data: CatalogueCategoryCreateInput;
};

export type CreateCatalogueCategoryMutation = { __typename?: 'Mutation' } & {
  createCatalogueCategory: { __typename?: 'CatalogueCategory' } & CatalogueCategoryFragment;
};

export type CreateCatalogueItemMutationVariables = {
  data: CatalogueItemCreateInput;
};

export type CreateCatalogueItemMutation = { __typename?: 'Mutation' } & {
  createCatalogueItem: { __typename?: 'CatalogueItem' } & CatalogueItemFragment;
};

export type CreateCatalogueItemPriceMutationVariables = {
  data: CatalogueItemPriceCreateInput;
};

export type CreateCatalogueItemPriceMutation = { __typename?: 'Mutation' } & {
  createCatalogueItemPrice: { __typename?: 'CatalogueItemPrice' } & CatalogueItemPriceFragment;
};

export type CreateCateringMutationVariables = {
  data: CateringCreateInput;
};

export type CreateCateringMutation = { __typename?: 'Mutation' } & {
  createCatering: { __typename?: 'Catering' } & CateringFragment;
};

export type CreateCommerceOrderMutationVariables = {
  data: CommerceOrderCreateInput;
};

export type CreateCommerceOrderMutation = { __typename?: 'Mutation' } & {
  createCommerceOrder: { __typename?: 'CommerceOrder' } & CommerceOrderFragment;
};

export type CreateCommerceOrderDataMutationVariables = {
  data: CommerceOrderDataCreateInput;
};

export type CreateCommerceOrderDataMutation = { __typename?: 'Mutation' } & {
  createCommerceOrderData: { __typename?: 'CommerceOrderData' } & CommerceOrderDataFragment;
};

export type CreateCommerceOrderFieldMutationVariables = {
  data: CommerceOrderFieldCreateInput;
};

export type CreateCommerceOrderFieldMutation = { __typename?: 'Mutation' } & {
  createCommerceOrderField: { __typename?: 'CommerceOrderField' } & CommerceOrderFieldFragment;
};

export type CreateCommerceOrderItemMutationVariables = {
  data: CommerceOrderItemCreateInput;
};

export type CreateCommerceOrderItemMutation = { __typename?: 'Mutation' } & {
  createCommerceOrderItem: { __typename?: 'CommerceOrderItem' } & CommerceOrderItemFragment;
};

export type CreateCommerceShopMutationVariables = {
  data: CommerceShopCreateInput;
};

export type CreateCommerceShopMutation = { __typename?: 'Mutation' } & {
  createCommerceShop: { __typename?: 'CommerceShop' } & CommerceShopFragment;
};

export type CreateContentTranslationMutationVariables = {
  data: ContentTranslationCreateInput;
};

export type CreateContentTranslationMutation = { __typename?: 'Mutation' } & {
  createContentTranslation: { __typename?: 'ContentTranslation' } & ContentTranslationFragment;
};

export type CreateDisputeMutationVariables = {
  data: DisputeCreateInput;
};

export type CreateDisputeMutation = { __typename?: 'Mutation' } & {
  createDispute: { __typename?: 'Dispute' } & DisputeFragment;
};

export type CreateDisputeDisputeResponseMutationVariables = {
  data: DisputeResponseCreateInput;
};

export type CreateDisputeDisputeResponseMutation = { __typename?: 'Mutation' } & {
  createDisputeDisputeResponse: { __typename?: 'DisputeDisputeResponse' } & DisputeDisputeResponseFragment;
};

export type CreateEventMutationVariables = {
  data: EventCreateInput;
};

export type CreateEventMutation = { __typename?: 'Mutation' } & {
  createEvent: { __typename?: 'Event' } & EventFullFragment;
};

export type CreateEventEventAccessMutationVariables = {
  data: EventEventAccessCreateInput;
};

export type CreateEventEventAccessMutation = { __typename?: 'Mutation' } & {
  createEventEventAccess: { __typename?: 'EventEventAccess' } & EventEventAccessFragment;
};

export type CreateEventPlaceMutationVariables = {
  data: EventPlaceCreateInput;
};

export type CreateEventPlaceMutation = { __typename?: 'Mutation' } & {
  createEventPlace: { __typename?: 'EventPlace' } & EventPlaceFragment;
};

export type CreateEventPlaceSectorMutationVariables = {
  data: EventPlaceSectorCreateInput;
};

export type CreateEventPlaceSectorMutation = { __typename?: 'Mutation' } & {
  createEventPlaceSector: { __typename?: 'EventPlaceSector' } & EventPlaceSectorFragment;
};

export type CreateEventTypeMutationVariables = {
  data: EventTypeCreateInput;
};

export type CreateEventTypeMutation = { __typename?: 'Mutation' } & {
  createEventType: { __typename?: 'EventType' } & EventTypeFragment;
};

export type CreateLoyaltyPartnerMutationVariables = {
  data: LoyaltyPartnerCreateInput;
};

export type CreateLoyaltyPartnerMutation = { __typename?: 'Mutation' } & {
  createLoyaltyPartner: { __typename?: 'LoyaltyPartner' } & LoyaltyPartnerFragment;
};

export type CreateLoyaltyPromoMutationVariables = {
  data: LoyaltyPromoCreateInput;
};

export type CreateLoyaltyPromoMutation = { __typename?: 'Mutation' } & {
  createLoyaltyPromo: { __typename?: 'LoyaltyPromo' } & LoyaltyPromoFragment;
};

export type CreateLoyaltyPromoCodeMutationVariables = {
  data: LoyaltyPromoCodeCreateInput;
};

export type CreateLoyaltyPromoCodeMutation = { __typename?: 'Mutation' } & {
  createLoyaltyPromoCode: { __typename?: 'LoyaltyPromoCode' } & LoyaltyPromoCodeFragment;
};

export type CreateMessengerMailingMutationVariables = {
  data: MessengerMailingCreateInput;
};

export type CreateMessengerMailingMutation = { __typename?: 'Mutation' } & {
  createMessengerMailing: { __typename?: 'MessengerMailing' } & MessengerMailingFragment;
};

export type CreateMessengerQueueNotificationMutationVariables = {
  data: MessengerQueueNotificationCreateInput;
};

export type CreateMessengerQueueNotificationMutation = { __typename?: 'Mutation' } & {
  createMessengerQueueNotification: { __typename?: 'MessengerQueueNotification' } & MessengerQueueNotificationFragment;
};

export type CreateMessengerQueueTaskMutationVariables = {
  data: MessengerQueueTaskCreateInput;
};

export type CreateMessengerQueueTaskMutation = { __typename?: 'Mutation' } & {
  createMessengerQueueTask: { __typename?: 'MessengerQueueTask' } & MessengerQueueTaskFragment;
};

export type CreateMessengerTemplateMutationVariables = {
  data: MessengerTemplateCreateInput;
};

export type CreateMessengerTemplateMutation = { __typename?: 'Mutation' } & {
  createMessengerTemplate: { __typename?: 'MessengerTemplate' } & MessengerTemplateFragment;
};

export type CreateMessengerTransportMutationVariables = {
  data: MessengerTransportCreateInput;
};

export type CreateMessengerTransportMutation = { __typename?: 'Mutation' } & {
  createMessengerTransport: { __typename?: 'MessengerTransport' } & MessengerTransportFragment;
};

export type CreatePaymentAccountMutationVariables = {
  data: PaymentAccountCreateInput;
};

export type CreatePaymentAccountMutation = { __typename?: 'Mutation' } & {
  createPaymentAccount: { __typename?: 'PaymentAccount' } & PaymentAccountFragment;
};

export type CreatePaymentAccountByApplicationCodeMutationVariables = {
  userId: Scalars['Int'];
  partnerId: Scalars['Int'];
  providerCode: Scalars['String'];
};

export type CreatePaymentAccountByApplicationCodeMutation = { __typename?: 'Mutation' } & {
  createPaymentAccountByApplicationCode: Maybe<{ __typename?: 'PaymentAccount' } & PaymentAccountFragment>;
};

export type CreatePaymentCurrencyMutationVariables = {
  data: PaymentCurrencyCreateInput;
};

export type CreatePaymentCurrencyMutation = { __typename?: 'Mutation' } & {
  createPaymentCurrency: { __typename?: 'PaymentCurrency' } & PaymentCurrencyFragment;
};

export type CreatePaymentProviderMutationVariables = {
  data: PaymentProviderCreateInput;
};

export type CreatePaymentProviderMutation = { __typename?: 'Mutation' } & {
  createPaymentProvider: { __typename?: 'PaymentProvider' } & PaymentProviderFragment;
};

export type CreatePaymentTransactionMutationVariables = {
  data: PaymentTransactionCreateInput;
};

export type CreatePaymentTransactionMutation = { __typename?: 'Mutation' } & {
  createPaymentTransaction: { __typename?: 'PaymentTransaction' } & PaymentTransactionFragment;
};

export type CreateSystemPrinterMutationVariables = {
  data: SystemPrinterCreateInput;
};

export type CreateSystemPrinterMutation = { __typename?: 'Mutation' } & {
  createSystemPrinter: { __typename?: 'SystemPrinter' } & SystemPrinterFragment;
};

export type CreateSystemTranslationMutationVariables = {
  data: SystemTranslationCreateInput;
};

export type CreateSystemTranslationMutation = { __typename?: 'Mutation' } & {
  createSystemTranslation: { __typename?: 'SystemTranslation' } & SystemTranslationFragment;
};

export type CreateSystemUserMutationVariables = {
  data: CreateSystemUserInput;
};

export type CreateSystemUserMutation = { __typename?: 'Mutation' } & {
  createSystemUser: { __typename?: 'SystemUser' } & SystemUserFragment;
};

export type CreateSystemWebHookMutationVariables = {
  data: SystemWebHookCreateInput;
};

export type CreateSystemWebHookMutation = { __typename?: 'Mutation' } & {
  createSystemWebHook: { __typename?: 'SystemWebHook' } & SystemWebHookFragment;
};

export type CreateTemplateApplePassMutationVariables = {
  data: TemplateApplePassCreateInput;
};

export type CreateTemplateApplePassMutation = { __typename?: 'Mutation' } & {
  createTemplateApplePass: { __typename?: 'TemplateApplePass' } & TemplateApplePassFragment;
};

export type CreateTemplatePdfMutationVariables = {
  data: TemplatePdfCreateInput;
};

export type CreateTemplatePdfMutation = { __typename?: 'Mutation' } & {
  createTemplatePDF: { __typename?: 'TemplatePDF' } & TemplatePdfFragment;
};

export type CreateTournamentMutationVariables = {
  data: TournamentCreateInput;
};

export type CreateTournamentMutation = { __typename?: 'Mutation' } & {
  createTournament: { __typename?: 'Tournament' } & TournamentFullFragment;
};

export type CreateTournamentDisqualificationMutationVariables = {
  data: TournamentDisqualificationCreateInput;
};

export type CreateTournamentDisqualificationMutation = { __typename?: 'Mutation' } & {
  createTournamentDisqualification: { __typename?: 'TournamentDisqualification' } & TournamentDisqualificationFragment;
};

export type CreateTournamentMatchMutationVariables = {
  data: TournamentMatchCreateInput;
};

export type CreateTournamentMatchMutation = { __typename?: 'Mutation' } & {
  createTournamentMatch: { __typename?: 'TournamentMatch' } & TournamentMatchFragment;
};

export type CreateTournamentMatchEventMutationVariables = {
  data: TournamentMatchEventCreateInput;
};

export type CreateTournamentMatchEventMutation = { __typename?: 'Mutation' } & {
  createTournamentMatchEvent: { __typename?: 'TournamentMatchEvent' } & TournamentMatchEventFragment;
};

export type CreateTournamentMatchLineUpMutationVariables = {
  data: TournamentMatchLineUpCreateInput;
};

export type CreateTournamentMatchLineUpMutation = { __typename?: 'Mutation' } & {
  createTournamentMatchLineUp: { __typename?: 'TournamentMatchLineUp' } & TournamentMatchLineUpFragment;
};

export type CreateTournamentMatchProtocolMutationVariables = {
  data: TournamentMatchProtocolCreateInput;
};

export type CreateTournamentMatchProtocolMutation = { __typename?: 'Mutation' } & {
  createTournamentMatchProtocol: { __typename?: 'TournamentMatchProtocol' } & TournamentMatchProtocolFragment;
};

export type CreateTournamentPlayerStatMutationVariables = {
  data: TournamentPlayerStatCreateInput;
};

export type CreateTournamentPlayerStatMutation = { __typename?: 'Mutation' } & {
  createTournamentPlayerStat: { __typename?: 'TournamentPlayerStat' } & TournamentPlayerStatFragment;
};

export type CreateTournamentRefereeMutationVariables = {
  data: TournamentRefereeCreateInput;
};

export type CreateTournamentRefereeMutation = { __typename?: 'Mutation' } & {
  createTournamentReferee: { __typename?: 'TournamentReferee' } & TournamentRefereeFragment;
};

export type CreateTournamentSeasonMutationVariables = {
  data: TournamentSeasonCreateInput;
};

export type CreateTournamentSeasonMutation = { __typename?: 'Mutation' } & {
  createTournamentSeason: { __typename?: 'TournamentSeason' } & TournamentSeasonFragment;
};

export type CreateTournamentSeasonTeamMutationVariables = {
  data: TournamentSeasonTeamCreateInput;
};

export type CreateTournamentSeasonTeamMutation = { __typename?: 'Mutation' } & {
  createTournamentSeasonTeam: { __typename?: 'TournamentSeasonTeam' } & TournamentSeasonTeamFragment;
};

export type CreateTournamentSeasonTeamStatMutationVariables = {
  data: TournamentSeasonTeamStatCreateInput;
};

export type CreateTournamentSeasonTeamStatMutation = { __typename?: 'Mutation' } & {
  createTournamentSeasonTeamStat: { __typename?: 'TournamentSeasonTeamStat' } & TournamentSeasonTeamStatFragment;
};

export type CreateTournamentStadiumMutationVariables = {
  data: TournamentStadiumCreateInput;
};

export type CreateTournamentStadiumMutation = { __typename?: 'Mutation' } & {
  createTournamentStadium: { __typename?: 'TournamentStadium' } & TournamentStadiumFragment;
};

export type CreateTournamentTeamMutationVariables = {
  data: TournamentTeamCreateInput;
};

export type CreateTournamentTeamMutation = { __typename?: 'Mutation' } & {
  createTournamentTeam: { __typename?: 'TournamentTeam' } & TournamentTeamFragment;
};

export type CreateTournamentTeamMemberMutationVariables = {
  data: TournamentTeamMemberCreateInput;
};

export type CreateTournamentTeamMemberMutation = { __typename?: 'Mutation' } & {
  createTournamentTeamMember: { __typename?: 'TournamentTeamMember' } & TournamentTeamMemberFragment;
};

export type CreateTournamentTourMutationVariables = {
  data: TournamentTourCreateInput;
};

export type CreateTournamentTourMutation = { __typename?: 'Mutation' } & {
  createTournamentTour: { __typename?: 'TournamentTour' } & TournamentTourFragment;
};

export type CreateUserApplicationFieldMutationVariables = {
  data: CreateExtensionFieldInput;
};

export type CreateUserApplicationFieldMutation = { __typename?: 'Mutation' } & {
  createUserApplicationField: { __typename?: 'ExtensionField' } & ExtensionFieldFragment;
};

export type CreateUserApplicationLogCommentMutationVariables = {
  applicationId: Scalars['Int'];
  comment: Scalars['String'];
};

export type CreateUserApplicationLogCommentMutation = { __typename?: 'Mutation' } & {
  createUserApplicationLogComment: { __typename?: 'UserApplicationLog' } & UserApplicationLogFragment;
};

export type CreateUserApplicationTypeMutationVariables = {
  data: UserApplicationTypeCreateInput;
};

export type CreateUserApplicationTypeMutation = { __typename?: 'Mutation' } & {
  createUserApplicationType: { __typename?: 'UserApplicationType' } & UserApplicationTypeFragment;
};

export type CreateUserGroupMutationVariables = {
  data: UserGroupCreateInput;
};

export type CreateUserGroupMutation = { __typename?: 'Mutation' } & {
  createUserGroup: { __typename?: 'UserGroup' } & UserGroupFragment;
};

export type CreateUserSubscriberMutationVariables = {
  data: UserSubscriberCreateInput;
};

export type CreateUserSubscriberMutation = { __typename?: 'Mutation' } & {
  createUserSubscriber: { __typename?: 'UserSubscriber' } & UserSubscriberFragment;
};

export type CreateUserSubscribersMutationVariables = {
  subscriptionId: Scalars['Int'];
  userId: Array<Scalars['Int']>;
  modifierId?: Maybe<Scalars['Int']>;
};

export type CreateUserSubscribersMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'createUserSubscribers'>;

export type CreateUserSubscriptionMutationVariables = {
  data: UserSubscriptionCreateInput;
};

export type CreateUserSubscriptionMutation = { __typename?: 'Mutation' } & {
  createUserSubscription: { __typename?: 'UserSubscription' } & UserSubscriptionFragment;
};

export type DeleteArticleMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteArticleMutation = { __typename?: 'Mutation' } & {
  deleteArticle: { __typename?: 'Article' } & ArticleFragment;
};

export type DeleteArticleAuthorMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteArticleAuthorMutation = { __typename?: 'Mutation' } & {
  deleteArticleAuthor: { __typename?: 'ArticleAuthor' } & ArticleAuthorFragment;
};

export type DeleteArticleBlockMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteArticleBlockMutation = { __typename?: 'Mutation' } & {
  deleteArticleBlock: { __typename?: 'ArticleBlock' } & ArticleBlockFragment;
};

export type DeleteArticleCategoryMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteArticleCategoryMutation = { __typename?: 'Mutation' } & {
  deleteArticleCategory: { __typename?: 'ArticleCategory' } & ArticleCategoryFragment;
};

export type DeleteArticleLinkMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteArticleLinkMutation = { __typename?: 'Mutation' } & {
  deleteArticleLink: { __typename?: 'ArticleLink' } & ArticleLinkFragment;
};

export type DeleteArticleTagMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteArticleTagMutation = { __typename?: 'Mutation' } & {
  deleteArticleTag: { __typename?: 'ArticleTag' } & ArticleTagFragment;
};

export type DeleteCatalogueMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteCatalogueMutation = { __typename?: 'Mutation' } & {
  deleteCatalogue: { __typename?: 'Catalogue' } & CatalogueFlatFragment;
};

export type DeleteCatalogueCategoryMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteCatalogueCategoryMutation = { __typename?: 'Mutation' } & {
  deleteCatalogueCategory: { __typename?: 'CatalogueCategory' } & CatalogueCategoryFragment;
};

export type DeleteCatalogueItemMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteCatalogueItemMutation = { __typename?: 'Mutation' } & {
  deleteCatalogueItem: { __typename?: 'CatalogueItem' } & CatalogueItemFragment;
};

export type DeleteCatalogueItemPriceMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteCatalogueItemPriceMutation = { __typename?: 'Mutation' } & {
  deleteCatalogueItemPrice: { __typename?: 'CatalogueItemPrice' } & CatalogueItemPriceFragment;
};

export type DeleteCateringMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteCateringMutation = { __typename?: 'Mutation' } & {
  deleteCatering: { __typename?: 'Catering' } & CateringFragment;
};

export type DeleteCommerceOrderMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteCommerceOrderMutation = { __typename?: 'Mutation' } & {
  deleteCommerceOrder: { __typename?: 'CommerceOrder' } & CommerceOrderFragment;
};

export type DeleteCommerceOrderDataMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteCommerceOrderDataMutation = { __typename?: 'Mutation' } & {
  deleteCommerceOrderData: { __typename?: 'CommerceOrderData' } & CommerceOrderDataFragment;
};

export type DeleteCommerceOrderFieldMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteCommerceOrderFieldMutation = { __typename?: 'Mutation' } & {
  deleteCommerceOrderField: { __typename?: 'CommerceOrderField' } & CommerceOrderFieldFragment;
};

export type DeleteCommerceOrderItemMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteCommerceOrderItemMutation = { __typename?: 'Mutation' } & {
  deleteCommerceOrderItem: { __typename?: 'CommerceOrderItem' } & CommerceOrderItemFragment;
};

export type DeleteCommerceShopMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteCommerceShopMutation = { __typename?: 'Mutation' } & {
  deleteCommerceShop: { __typename?: 'CommerceShop' } & CommerceShopFragment;
};

export type DeleteContentTranslationMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteContentTranslationMutation = { __typename?: 'Mutation' } & {
  deleteContentTranslation: { __typename?: 'ContentTranslation' } & ContentTranslationFragment;
};

export type DeleteEventMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteEventMutation = { __typename?: 'Mutation' } & {
  deleteEvent: { __typename?: 'Event' } & EventFullFragment;
};

export type DeleteEventEventAccessMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteEventEventAccessMutation = { __typename?: 'Mutation' } & {
  deleteEventEventAccess: { __typename?: 'EventEventAccess' } & EventEventAccessFragment;
};

export type DeleteEventOrderMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteEventOrderMutation = { __typename?: 'Mutation' } & {
  deleteEventOrder: { __typename?: 'EventOrder' } & EventOrderFragment;
};

export type DeleteEventPlaceMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteEventPlaceMutation = { __typename?: 'Mutation' } & {
  deleteEventPlace: { __typename?: 'EventPlace' } & EventPlaceFragment;
};

export type DeleteEventPlaceSectorMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteEventPlaceSectorMutation = { __typename?: 'Mutation' } & {
  deleteEventPlaceSector: { __typename?: 'EventPlaceSector' } & EventPlaceSectorFragment;
};

export type DeleteEventTypeMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteEventTypeMutation = { __typename?: 'Mutation' } & {
  deleteEventType: { __typename?: 'EventType' } & EventTypeFragment;
};

export type DeleteFromMessengerQueueMutationVariables = {
  mailingId: Scalars['Int'];
  userId?: Maybe<Array<Scalars['Int']>>;
};

export type DeleteFromMessengerQueueMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteFromMessengerQueue'>;

export type DeleteFromUsersSubscriptionMutationVariables = {
  subscriptionId: Scalars['Int'];
  userId?: Maybe<Array<Scalars['Int']>>;
};

export type DeleteFromUsersSubscriptionMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteFromUsersSubscription'
>;

export type DeleteLoyaltyPartnerMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteLoyaltyPartnerMutation = { __typename?: 'Mutation' } & {
  deleteLoyaltyPartner: { __typename?: 'LoyaltyPartner' } & LoyaltyPartnerFragment;
};

export type DeleteLoyaltyPromoMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteLoyaltyPromoMutation = { __typename?: 'Mutation' } & {
  deleteLoyaltyPromo: { __typename?: 'LoyaltyPromo' } & LoyaltyPromoFragment;
};

export type DeleteLoyaltyPromoCodeMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteLoyaltyPromoCodeMutation = { __typename?: 'Mutation' } & {
  deleteLoyaltyPromoCode: { __typename?: 'LoyaltyPromoCode' } & LoyaltyPromoCodeFragment;
};

export type DeleteMessengerMailingMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteMessengerMailingMutation = { __typename?: 'Mutation' } & {
  deleteMessengerMailing: { __typename?: 'MessengerMailing' } & MessengerMailingFragment;
};

export type DeleteMessengerQueueNotificationMutationVariables = {
  id: Scalars['String'];
};

export type DeleteMessengerQueueNotificationMutation = { __typename?: 'Mutation' } & {
  deleteMessengerQueueNotification: { __typename?: 'MessengerQueueNotification' } & MessengerQueueNotificationFragment;
};

export type DeleteMessengerQueueTaskMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteMessengerQueueTaskMutation = { __typename?: 'Mutation' } & {
  deleteMessengerQueueTask: { __typename?: 'MessengerQueueTask' } & MessengerQueueTaskFragment;
};

export type DeleteMessengerTemplateMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteMessengerTemplateMutation = { __typename?: 'Mutation' } & {
  deleteMessengerTemplate: { __typename?: 'MessengerTemplate' } & MessengerTemplateFragment;
};

export type DeleteMessengerTransportMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteMessengerTransportMutation = { __typename?: 'Mutation' } & {
  deleteMessengerTransport: { __typename?: 'MessengerTransport' } & MessengerTransportFragment;
};

export type DeleteMobileTranslationMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteMobileTranslationMutation = { __typename?: 'Mutation' } & {
  deleteMobileTranslation: { __typename?: 'MobileTranslation' } & MobileTranslationFragment;
};

export type DeletePaymentAccountMutationVariables = {
  id: Scalars['Int'];
};

export type DeletePaymentAccountMutation = { __typename?: 'Mutation' } & {
  deletePaymentAccount: { __typename?: 'PaymentAccount' } & PaymentAccountFragment;
};

export type DeletePaymentCurrencyMutationVariables = {
  id: Scalars['Int'];
};

export type DeletePaymentCurrencyMutation = { __typename?: 'Mutation' } & {
  deletePaymentCurrency: { __typename?: 'PaymentCurrency' } & PaymentCurrencyFragment;
};

export type DeletePaymentProviderMutationVariables = {
  id: Scalars['Int'];
};

export type DeletePaymentProviderMutation = { __typename?: 'Mutation' } & {
  deletePaymentProvider: { __typename?: 'PaymentProvider' } & PaymentProviderFragment;
};

export type DeletePaymentTransactionMutationVariables = {
  id: Scalars['Int'];
};

export type DeletePaymentTransactionMutation = { __typename?: 'Mutation' } & {
  deletePaymentTransaction: { __typename?: 'PaymentTransaction' } & PaymentTransactionFragment;
};

export type DeleteSystemPrinterMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteSystemPrinterMutation = { __typename?: 'Mutation' } & {
  deleteSystemPrinter: { __typename?: 'SystemPrinter' } & SystemPrinterFragment;
};

export type DeleteSystemTranslationMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteSystemTranslationMutation = { __typename?: 'Mutation' } & {
  deleteSystemTranslation: { __typename?: 'SystemTranslation' } & SystemTranslationFragment;
};

export type DeleteSystemWebHookMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteSystemWebHookMutation = { __typename?: 'Mutation' } & {
  deleteSystemWebHook: { __typename?: 'SystemWebHook' } & SystemWebHookFragment;
};

export type DeleteTemplateApplePassMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteTemplateApplePassMutation = { __typename?: 'Mutation' } & {
  deleteTemplateApplePass: { __typename?: 'TemplateApplePass' } & TemplateApplePassFragment;
};

export type DeleteTemplatePdfMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteTemplatePdfMutation = { __typename?: 'Mutation' } & {
  deleteTemplatePDF: { __typename?: 'TemplatePDF' } & TemplatePdfFragment;
};

export type DeleteTournamentMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteTournamentMutation = { __typename?: 'Mutation' } & {
  deleteTournament: { __typename?: 'Tournament' } & TournamentFullFragment;
};

export type DeleteTournamentDisqualificationMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteTournamentDisqualificationMutation = { __typename?: 'Mutation' } & {
  deleteTournamentDisqualification: { __typename?: 'TournamentDisqualification' } & TournamentDisqualificationFragment;
};

export type DeleteTournamentMatchMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteTournamentMatchMutation = { __typename?: 'Mutation' } & {
  deleteTournamentMatch: { __typename?: 'TournamentMatch' } & TournamentMatchFragment;
};

export type DeleteTournamentMatchEventMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteTournamentMatchEventMutation = { __typename?: 'Mutation' } & {
  deleteTournamentMatchEvent: { __typename?: 'TournamentMatchEvent' } & TournamentMatchEventFragment;
};

export type DeleteTournamentMatchLineUpMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteTournamentMatchLineUpMutation = { __typename?: 'Mutation' } & {
  deleteTournamentMatchLineUp: { __typename?: 'TournamentMatchLineUp' } & TournamentMatchLineUpFragment;
};

export type DeleteTournamentMatchProtocolMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteTournamentMatchProtocolMutation = { __typename?: 'Mutation' } & {
  deleteTournamentMatchProtocol: { __typename?: 'TournamentMatchProtocol' } & TournamentMatchProtocolFragment;
};

export type DeleteTournamentPlayerStatMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteTournamentPlayerStatMutation = { __typename?: 'Mutation' } & {
  deleteTournamentPlayerStat: { __typename?: 'TournamentPlayerStat' } & TournamentPlayerStatFragment;
};

export type DeleteTournamentRefereeMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteTournamentRefereeMutation = { __typename?: 'Mutation' } & {
  deleteTournamentReferee: { __typename?: 'TournamentReferee' } & TournamentRefereeFragment;
};

export type DeleteTournamentSeasonMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteTournamentSeasonMutation = { __typename?: 'Mutation' } & {
  deleteTournamentSeason: { __typename?: 'TournamentSeason' } & TournamentSeasonFragment;
};

export type DeleteTournamentSeasonTeamMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteTournamentSeasonTeamMutation = { __typename?: 'Mutation' } & {
  deleteTournamentSeasonTeam: { __typename?: 'TournamentSeasonTeam' } & TournamentSeasonTeamFragment;
};

export type DeleteTournamentSeasonTeamStatMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteTournamentSeasonTeamStatMutation = { __typename?: 'Mutation' } & {
  deleteTournamentSeasonTeamStat: { __typename?: 'TournamentSeasonTeamStat' } & TournamentSeasonTeamStatFragment;
};

export type DeleteTournamentStadiumMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteTournamentStadiumMutation = { __typename?: 'Mutation' } & {
  deleteTournamentStadium: { __typename?: 'TournamentStadium' } & TournamentStadiumFragment;
};

export type DeleteTournamentTeamMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteTournamentTeamMutation = { __typename?: 'Mutation' } & {
  deleteTournamentTeam: { __typename?: 'TournamentTeam' } & TournamentTeamFragment;
};

export type DeleteTournamentTeamMemberMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteTournamentTeamMemberMutation = { __typename?: 'Mutation' } & {
  deleteTournamentTeamMember: { __typename?: 'TournamentTeamMember' } & TournamentTeamMemberFragment;
};

export type DeleteTournamentTourMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteTournamentTourMutation = { __typename?: 'Mutation' } & {
  deleteTournamentTour: { __typename?: 'TournamentTour' } & TournamentTourFragment;
};

export type DeleteUserApplicationTypeMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteUserApplicationTypeMutation = { __typename?: 'Mutation' } & {
  deleteUserApplicationType: { __typename?: 'UserApplicationType' } & UserApplicationTypeFragment;
};

export type DeleteUserByIdMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteUserByIdMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteUserById'>;

export type DeleteUserFromGroupMutationVariables = {
  groupId: Array<Scalars['Int']>;
  query: DefaultQueryInput;
};

export type DeleteUserFromGroupMutation = { __typename?: 'Mutation' } & {
  deleteUserFromGroup: Array<
    { __typename?: 'SystemAsyncTask' } & Pick<
      SystemAsyncTask,
      'id' | 'clientId' | 'entityId' | 'operation' | 'state' | 'progress' | 'createdAt' | 'updatedAt'
    >
  >;
};

export type DeleteUserGroupMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteUserGroupMutation = { __typename?: 'Mutation' } & {
  deleteUserGroup: { __typename?: 'UserGroup' } & UserGroupFragment;
};

export type DeleteUserSubscriberMutationVariables = {
  id: Scalars['String'];
};

export type DeleteUserSubscriberMutation = { __typename?: 'Mutation' } & {
  deleteUserSubscriber: { __typename?: 'UserSubscriber' } & UserSubscriberFragment;
};

export type DeleteUserSubscribersMutationVariables = {
  id: Array<Scalars['String']>;
};

export type DeleteUserSubscribersMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteUserSubscribers'>;

export type DeleteUserSubscriptionMutationVariables = {
  id: Scalars['Int'];
};

export type DeleteUserSubscriptionMutation = { __typename?: 'Mutation' } & {
  deleteUserSubscription: { __typename?: 'UserSubscription' } & UserSubscriptionFragment;
};

export type DepositToAccountMutationVariables = {
  data: PaymentAccountOperationInput;
};

export type DepositToAccountMutation = { __typename?: 'Mutation' } & {
  depositToAccount: { __typename?: 'ProviderResponse' } & Pick<ProviderResponse, 'amount'>;
};

export type ExportEventsTicketsMutationVariables = {
  eventId: Scalars['Int'];
};

export type ExportEventsTicketsMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'exportEventsTickets'>;

export type ExportPromocodesMutationVariables = {
  promoId: Scalars['Int'];
  format: ExportFormat;
};

export type ExportPromocodesMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'exportPromocodes'>;

export type ExportUsersMutationVariables = {
  query?: Maybe<DefaultQueryInput>;
  settings?: Maybe<ExportSettingsInput>;
};

export type ExportUsersMutation = { __typename?: 'Mutation' } & {
  exportUsers: { __typename?: 'ExportResponse' } & Pick<ExportResponse, 'model' | 'fileUrl' | 'format'> & {
      fields: Array<{ __typename?: 'ExportFields' } & Pick<ExportFields, 'name' | 'title' | 'isExtension'>>;
    };
};

export type ExportUsersToUnisenderMutationVariables = {
  query?: Maybe<DefaultQueryInput>;
  settings: ExportUnisenderSettingsInput;
};

export type ExportUsersToUnisenderMutation = { __typename?: 'Mutation' } & {
  exportUsersToUnisender: { __typename?: 'ExportResponse' } & Pick<ExportResponse, 'model' | 'fileUrl' | 'format'> & {
      fields: Array<{ __typename?: 'ExportFields' } & Pick<ExportFields, 'name' | 'title' | 'isExtension'>>;
    };
};

export type GenerateDocsByEventOrderMutationVariables = {
  userId: Scalars['Int'];
  notify: Scalars['Boolean'];
  orderId?: Maybe<Scalars['Int']>;
  ticketId?: Maybe<Scalars['Int']>;
};

export type GenerateDocsByEventOrderMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'generateDocsByEventOrder'>;

export type GenerateLoyaltyPromoCodesMutationVariables = {
  promoId: Scalars['Int'];
  count: Scalars['Int'];
  length: Scalars['Int'];
};

export type GenerateLoyaltyPromoCodesMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'generateLoyaltyPromoCodes'
>;

export type GrantAccessMutationVariables = {
  userId: Scalars['ID'];
  aclId: Scalars['ID'];
};

export type GrantAccessMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'grantAccess'>;

export type ImportPromocodesMutationVariables = {
  promoId: Scalars['Int'];
  file: Scalars['Upload'];
};

export type ImportPromocodesMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'importPromocodes'>;

export type ImportRecipientsToQueueNotificationMutationVariables = {
  mailingId: Scalars['Int'];
  file: Scalars['Upload'];
};

export type ImportRecipientsToQueueNotificationMutation = { __typename?: 'Mutation' } & {
  importRecipientsToQueueNotification: { __typename?: 'MessengerQueueTask' } & MessengerQueueTaskFragment;
};

export type ImportTemplateNotificationsMutationVariables = {
  providerId?: Maybe<Scalars['Int']>;
  templateId?: Maybe<Scalars['Int']>;
};

export type ImportTemplateNotificationsMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'importTemplateNotifications'
>;

export type ImportUserSubscribersMutationVariables = {
  subscriptionId: Scalars['Int'];
  file: Scalars['Upload'];
};

export type ImportUserSubscribersMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'importUserSubscribers'>;

export type ImportUsersMutationVariables = {
  data: ImportInput;
};

export type ImportUsersMutation = { __typename?: 'Mutation' } & {
  importUsers: { __typename?: 'SystemAsyncTask' } & SystemAsyncTaskFragment;
};

export type RefundOrderMutationVariables = {
  data: RefundOrderInput;
};

export type RefundOrderMutation = { __typename?: 'Mutation' } & {
  refundOrder: Array<{ __typename?: 'PaymentTransaction' } & RefundPaymentTransactionFragment>;
};

export type ResetLoyaltyPromoCodesMutationVariables = {
  promoId: Scalars['Int'];
};

export type ResetLoyaltyPromoCodesMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'resetLoyaltyPromoCodes'>;

export type RevokeAccessMutationVariables = {
  userId: Scalars['ID'];
  aclId: Scalars['ID'];
};

export type RevokeAccessMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'revokeAccess'>;

export type SaveDisputeSettingsMutationVariables = {
  data: DisputeSettingsUpdateInput;
};

export type SaveDisputeSettingsMutation = { __typename?: 'Mutation' } & {
  saveDisputeSettings: { __typename?: 'DisputeSettings' } & DisputeSettingsFragment;
};

export type SaveMobileTranslationsMutationVariables = {
  data?: Maybe<Array<MobileTranslationSaveInput>>;
};

export type SaveMobileTranslationsMutation = { __typename?: 'Mutation' } & {
  saveMobileTranslations: Maybe<Array<{ __typename?: 'MobileTranslation' } & MobileTranslationFragment>>;
};

export type SaveSystemTranslationMutationVariables = {
  data: SystemTranslationSaveInput;
};

export type SaveSystemTranslationMutation = { __typename?: 'Mutation' } & {
  saveSystemTranslation: { __typename?: 'SystemTranslation' } & SystemTranslationFragment;
};

export type SaveUserSettingsMutationVariables = {
  data: SaveUserSettingsInput;
};

export type SaveUserSettingsMutation = { __typename?: 'Mutation' } & {
  saveUserSettings: { __typename?: 'UserSetting' } & UserSettingFragment;
};

export type SystemLoginMutationVariables = {
  username: Scalars['String'];
  password: Scalars['String'];
  clientId?: Maybe<Scalars['Int']>;
};

export type SystemLoginMutation = { __typename?: 'Mutation' } & {
  systemLogin: { __typename?: 'SystemLogin' } & SystemLoginFragment;
};

export type TestMessengerMailingMutationVariables = {
  mailingId: Scalars['Int'];
};

export type TestMessengerMailingMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'testMessengerMailing'>;

export type TriggerSystemWebHookMutationVariables = {
  id: Scalars['Int'];
  entityId?: Maybe<Scalars['Int']>;
  payload?: Maybe<SystemWebHookPayloadInput>;
};

export type TriggerSystemWebHookMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'triggerSystemWebHook'>;

export type UnisenderCreateListMutationVariables = {
  data: UnisenderCreateListInput;
};

export type UnisenderCreateListMutation = { __typename?: 'Mutation' } & {
  unisenderCreateList: { __typename?: 'UnisenderList' } & Pick<UnisenderList, 'id' | 'title'>;
};

export type UpdateArticleMutationVariables = {
  id: Scalars['Int'];
  data: ArticleUpdateInput;
};

export type UpdateArticleMutation = { __typename?: 'Mutation' } & {
  updateArticle: { __typename?: 'Article' } & ArticleFragment;
};

export type UpdateArticleAuthorMutationVariables = {
  id: Scalars['Int'];
  data: ArticleAuthorUpdateInput;
};

export type UpdateArticleAuthorMutation = { __typename?: 'Mutation' } & {
  updateArticleAuthor: { __typename?: 'ArticleAuthor' } & ArticleAuthorFragment;
};

export type UpdateArticleBlockMutationVariables = {
  id: Scalars['Int'];
  data: ArticleBlockUpdateInput;
};

export type UpdateArticleBlockMutation = { __typename?: 'Mutation' } & {
  updateArticleBlock: { __typename?: 'ArticleBlock' } & ArticleBlockFragment;
};

export type UpdateArticleBlocksMutationVariables = {
  data: Array<ArticleBlocksUpdateInput>;
};

export type UpdateArticleBlocksMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'updateArticleBlocks'>;

export type UpdateArticleCategoryMutationVariables = {
  id: Scalars['Int'];
  data: ArticleCategoryUpdateInput;
};

export type UpdateArticleCategoryMutation = { __typename?: 'Mutation' } & {
  updateArticleCategory: { __typename?: 'ArticleCategory' } & ArticleCategoryFragment;
};

export type UpdateArticleLinkMutationVariables = {
  id: Scalars['Int'];
  data: ArticleLinkUpdateInput;
};

export type UpdateArticleLinkMutation = { __typename?: 'Mutation' } & {
  updateArticleLink: { __typename?: 'ArticleLink' } & ArticleLinkFragment;
};

export type UpdateArticleTagMutationVariables = {
  id: Scalars['Int'];
  data: ArticleTagUpdateInput;
};

export type UpdateArticleTagMutation = { __typename?: 'Mutation' } & {
  updateArticleTag: { __typename?: 'ArticleTag' } & ArticleTagFragment;
};

export type UpdateCatalogueMutationVariables = {
  id: Scalars['Int'];
  data: CatalogueUpdateInput;
};

export type UpdateCatalogueMutation = { __typename?: 'Mutation' } & {
  updateCatalogue: { __typename?: 'Catalogue' } & CatalogueFlatFragment;
};

export type UpdateCatalogueCategoryMutationVariables = {
  id: Scalars['Int'];
  data: CatalogueCategoryUpdateInput;
};

export type UpdateCatalogueCategoryMutation = { __typename?: 'Mutation' } & {
  updateCatalogueCategory: { __typename?: 'CatalogueCategory' } & CatalogueCategoryFullFragment;
};

export type UpdateCatalogueCategoryBatchMutationVariables = {
  data: Array<CatalogueCategoryBatchUpdateInput>;
};

export type UpdateCatalogueCategoryBatchMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'updateCatalogueCategoryBatch'
>;

export type UpdateCatalogueItemMutationVariables = {
  id: Scalars['Int'];
  data: CatalogueItemUpdateInput;
};

export type UpdateCatalogueItemMutation = { __typename?: 'Mutation' } & {
  updateCatalogueItem: { __typename?: 'CatalogueItem' } & CatalogueItemFragment;
};

export type UpdateCatalogueItemBatchMutationVariables = {
  data: Array<CatalogueItemBatchUpdateInput>;
};

export type UpdateCatalogueItemBatchMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'updateCatalogueItemBatch'>;

export type UpdateCatalogueItemPriceMutationVariables = {
  id: Scalars['Int'];
  data: CatalogueItemPriceUpdateInput;
};

export type UpdateCatalogueItemPriceMutation = { __typename?: 'Mutation' } & {
  updateCatalogueItemPrice: { __typename?: 'CatalogueItemPrice' } & CatalogueItemPriceFragment;
};

export type UpdateCateringMutationVariables = {
  id: Scalars['Int'];
  data: CateringUpdateInput;
};

export type UpdateCateringMutation = { __typename?: 'Mutation' } & {
  updateCatering: { __typename?: 'Catering' } & CateringFragment;
};

export type UpdateCommerceOrderMutationVariables = {
  id: Scalars['Int'];
  data: CommerceOrderUpdateInput;
};

export type UpdateCommerceOrderMutation = { __typename?: 'Mutation' } & {
  updateCommerceOrder: { __typename?: 'CommerceOrder' } & CommerceOrderUpdateFragment;
};

export type UpdateCommerceOrderDataMutationVariables = {
  id: Scalars['Int'];
  data: CommerceOrderDataUpdateInput;
};

export type UpdateCommerceOrderDataMutation = { __typename?: 'Mutation' } & {
  updateCommerceOrderData: { __typename?: 'CommerceOrderData' } & CommerceOrderDataFragment;
};

export type UpdateCommerceOrderDatasMutationVariables = {
  data: Array<CommerceOrderDatasUpdateInput>;
};

export type UpdateCommerceOrderDatasMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'updateCommerceOrderDatas'>;

export type UpdateCommerceOrderFieldMutationVariables = {
  id: Scalars['Int'];
  data: CommerceOrderFieldUpdateInput;
};

export type UpdateCommerceOrderFieldMutation = { __typename?: 'Mutation' } & {
  updateCommerceOrderField: { __typename?: 'CommerceOrderField' } & CommerceOrderFieldFragment;
};

export type UpdateCommerceOrderItemMutationVariables = {
  id: Scalars['Int'];
  data: CommerceOrderItemUpdateInput;
};

export type UpdateCommerceOrderItemMutation = { __typename?: 'Mutation' } & {
  updateCommerceOrderItem: { __typename?: 'CommerceOrderItem' } & CommerceOrderItemFragment;
};

export type UpdateCommerceShopMutationVariables = {
  id: Scalars['Int'];
  data: CommerceShopUpdateInput;
};

export type UpdateCommerceShopMutation = { __typename?: 'Mutation' } & {
  updateCommerceShop: { __typename?: 'CommerceShop' } & CommerceShopFragment;
};

export type UpdateContentTranslationMutationVariables = {
  id: Scalars['Int'];
  data: ContentTranslationUpdateInput;
};

export type UpdateContentTranslationMutation = { __typename?: 'Mutation' } & {
  updateContentTranslation: { __typename?: 'ContentTranslation' } & ContentTranslationFragment;
};

export type UpdateDisputeMutationVariables = {
  id: Scalars['Int'];
  data: DisputeUpdateInput;
};

export type UpdateDisputeMutation = { __typename?: 'Mutation' } & {
  updateDispute: { __typename?: 'Dispute' } & DisputeFragment;
};

export type UpdateDisputeDisputeResponseMutationVariables = {
  id: Scalars['Int'];
  data: DisputeResponseUpdateInput;
};

export type UpdateDisputeDisputeResponseMutation = { __typename?: 'Mutation' } & {
  updateDisputeDisputeResponse: { __typename?: 'DisputeDisputeResponse' } & DisputeDisputeResponseFragment;
};

export type UpdateEventMutationVariables = {
  id: Scalars['Int'];
  data: EventUpdateInput;
};

export type UpdateEventMutation = { __typename?: 'Mutation' } & {
  updateEvent: { __typename?: 'Event' } & EventFullFragment;
};

export type UpdateEventEventAccessMutationVariables = {
  id: Scalars['Int'];
  data: EventEventAccessUpdateInput;
};

export type UpdateEventEventAccessMutation = { __typename?: 'Mutation' } & {
  updateEventEventAccess: { __typename?: 'EventEventAccess' } & EventEventAccessFragment;
};

export type UpdateEventOrderMutationVariables = {
  id: Scalars['Int'];
  data: EventOrderUpdateInput;
};

export type UpdateEventOrderMutation = { __typename?: 'Mutation' } & {
  updateEventOrder: { __typename?: 'EventOrder' } & EventOrderFragment;
};

export type UpdateEventPlaceMutationVariables = {
  id: Scalars['Int'];
  data: EventPlaceUpdateInput;
};

export type UpdateEventPlaceMutation = { __typename?: 'Mutation' } & {
  updateEventPlace: { __typename?: 'EventPlace' } & EventPlaceFragment;
};

export type UpdateEventPlaceSectorMutationVariables = {
  id: Scalars['Int'];
  data: EventPlaceSectorUpdateInput;
};

export type UpdateEventPlaceSectorMutation = { __typename?: 'Mutation' } & {
  updateEventPlaceSector: { __typename?: 'EventPlaceSector' } & EventPlaceSectorFragment;
};

export type UpdateEventTypeMutationVariables = {
  id: Scalars['Int'];
  data: EventTypeUpdateInput;
};

export type UpdateEventTypeMutation = { __typename?: 'Mutation' } & {
  updateEventType: { __typename?: 'EventType' } & EventTypeFragment;
};

export type UpdateLoyaltyPartnerMutationVariables = {
  id: Scalars['Int'];
  data: LoyaltyPartnerUpdateInput;
};

export type UpdateLoyaltyPartnerMutation = { __typename?: 'Mutation' } & {
  updateLoyaltyPartner: { __typename?: 'LoyaltyPartner' } & LoyaltyPartnerFragment;
};

export type UpdateLoyaltyPromoMutationVariables = {
  id: Scalars['Int'];
  data: LoyaltyPromoUpdateInput;
};

export type UpdateLoyaltyPromoMutation = { __typename?: 'Mutation' } & {
  updateLoyaltyPromo: { __typename?: 'LoyaltyPromo' } & LoyaltyPromoFragment;
};

export type UpdateLoyaltyPromoCodeMutationVariables = {
  id: Scalars['Int'];
  data: LoyaltyPromoCodeUpdateInput;
};

export type UpdateLoyaltyPromoCodeMutation = { __typename?: 'Mutation' } & {
  updateLoyaltyPromoCode: { __typename?: 'LoyaltyPromoCode' } & LoyaltyPromoCodeFragment;
};

export type UpdateMessengerMailingMutationVariables = {
  id: Scalars['Int'];
  data: MessengerMailingUpdateInput;
};

export type UpdateMessengerMailingMutation = { __typename?: 'Mutation' } & {
  updateMessengerMailing: { __typename?: 'MessengerMailing' } & MessengerMailingFragment;
};

export type UpdateMessengerQueueNotificationMutationVariables = {
  id: Scalars['String'];
  data: MessengerQueueNotificationUpdateInput;
};

export type UpdateMessengerQueueNotificationMutation = { __typename?: 'Mutation' } & {
  updateMessengerQueueNotification: { __typename?: 'MessengerQueueNotification' } & MessengerQueueNotificationFragment;
};

export type UpdateMessengerQueueTaskMutationVariables = {
  id: Scalars['Int'];
  data: MessengerQueueTaskUpdateInput;
};

export type UpdateMessengerQueueTaskMutation = { __typename?: 'Mutation' } & {
  updateMessengerQueueTask: { __typename?: 'MessengerQueueTask' } & MessengerQueueTaskFragment;
};

export type UpdateMessengerSettingMutationVariables = {
  data: MessengerSettingUpdateInput;
};

export type UpdateMessengerSettingMutation = { __typename?: 'Mutation' } & {
  updateMessengerSetting: { __typename?: 'MessengerSetting' } & MessengerSettingFragment;
};

export type UpdateMessengerTemplateMutationVariables = {
  id: Scalars['Int'];
  data: MessengerTemplateUpdateInput;
};

export type UpdateMessengerTemplateMutation = { __typename?: 'Mutation' } & {
  updateMessengerTemplate: { __typename?: 'MessengerTemplate' } & MessengerTemplateFragment;
};

export type UpdateMessengerTransportMutationVariables = {
  id: Scalars['Int'];
  data: MessengerTransportUpdateInput;
};

export type UpdateMessengerTransportMutation = { __typename?: 'Mutation' } & {
  updateMessengerTransport: { __typename?: 'MessengerTransport' } & MessengerTransportFragment;
};

export type UpdatePaymentAccountMutationVariables = {
  id: Scalars['Int'];
  data: PaymentAccountUpdateInput;
};

export type UpdatePaymentAccountMutation = { __typename?: 'Mutation' } & {
  updatePaymentAccount: { __typename?: 'PaymentAccount' } & PaymentAccountFragment;
};

export type UpdatePaymentCurrencyMutationVariables = {
  id: Scalars['Int'];
  data: PaymentCurrencyUpdateInput;
};

export type UpdatePaymentCurrencyMutation = { __typename?: 'Mutation' } & {
  updatePaymentCurrency: { __typename?: 'PaymentCurrency' } & PaymentCurrencyFragment;
};

export type UpdatePaymentProviderMutationVariables = {
  id: Scalars['Int'];
  data: PaymentProviderUpdateInput;
};

export type UpdatePaymentProviderMutation = { __typename?: 'Mutation' } & {
  updatePaymentProvider: { __typename?: 'PaymentProvider' } & PaymentProviderFragment;
};

export type UpdatePaymentTransactionMutationVariables = {
  id: Scalars['Int'];
  data: PaymentTransactionUpdateInput;
};

export type UpdatePaymentTransactionMutation = { __typename?: 'Mutation' } & {
  updatePaymentTransaction: { __typename?: 'PaymentTransaction' } & PaymentTransactionFragment;
};

export type UpdateSystemTaskMutationVariables = {
  id: Scalars['UUID'];
  data: SystemTaskUpdateInput;
};

export type UpdateSystemTaskMutation = { __typename?: 'Mutation' } & {
  updateSystemTask: { __typename?: 'SystemTask' } & SystemTaskFragment;
};

export type UpdateSystemPrinterMutationVariables = {
  id: Scalars['Int'];
  data: SystemPrinterUpdateInput;
};

export type UpdateSystemPrinterMutation = { __typename?: 'Mutation' } & {
  updateSystemPrinter: { __typename?: 'SystemPrinter' } & SystemPrinterFragment;
};

export type UpdateSystemTranslationMutationVariables = {
  id: Scalars['Int'];
  data: SystemTranslationUpdateInput;
};

export type UpdateSystemTranslationMutation = { __typename?: 'Mutation' } & {
  updateSystemTranslation: { __typename?: 'SystemTranslation' } & SystemTranslationFragment;
};

export type UpdateSystemTranslationsMutationVariables = {
  data: Array<SystemTranslationUpdateInput>;
};

export type UpdateSystemTranslationsMutation = { __typename?: 'Mutation' } & {
  updateSystemTranslations: Maybe<Array<{ __typename?: 'SystemTranslation' } & SystemTranslationFragment>>;
};

export type UpdateSystemUserMutationVariables = {
  id: Scalars['ID'];
  data: UpdateSystemUserInput;
};

export type UpdateSystemUserMutation = { __typename?: 'Mutation' } & {
  updateSystemUser: { __typename?: 'SystemUser' } & SystemUserFragment;
};

export type UpdateSystemWebHookMutationVariables = {
  id: Scalars['Int'];
  data: SystemWebHookUpdateInput;
};

export type UpdateSystemWebHookMutation = { __typename?: 'Mutation' } & {
  updateSystemWebHook: { __typename?: 'SystemWebHook' } & SystemWebHookFragment;
};

export type UpdateTemplateApplePassMutationVariables = {
  id: Scalars['Int'];
  data: TemplateApplePassUpdateInput;
};

export type UpdateTemplateApplePassMutation = { __typename?: 'Mutation' } & {
  updateTemplateApplePass: { __typename?: 'TemplateApplePass' } & TemplateApplePassFragment;
};

export type UpdateTemplatePdfMutationVariables = {
  id: Scalars['Int'];
  data: TemplatePdfUpdateInput;
};

export type UpdateTemplatePdfMutation = { __typename?: 'Mutation' } & {
  updateTemplatePDF: { __typename?: 'TemplatePDF' } & TemplatePdfFragment;
};

export type UpdateTournamentMutationVariables = {
  id: Scalars['Int'];
  data: TournamentUpdateInput;
};

export type UpdateTournamentMutation = { __typename?: 'Mutation' } & {
  updateTournament: { __typename?: 'Tournament' } & TournamentFullFragment;
};

export type UpdateTournamentDisqualificationMutationVariables = {
  id: Scalars['Int'];
  data: TournamentDisqualificationUpdateInput;
};

export type UpdateTournamentDisqualificationMutation = { __typename?: 'Mutation' } & {
  updateTournamentDisqualification: { __typename?: 'TournamentDisqualification' } & TournamentDisqualificationFragment;
};

export type UpdateTournamentMatchMutationVariables = {
  id: Scalars['Int'];
  data: TournamentMatchUpdateInput;
};

export type UpdateTournamentMatchMutation = { __typename?: 'Mutation' } & {
  updateTournamentMatch: { __typename?: 'TournamentMatch' } & TournamentMatchFragment;
};

export type UpdateTournamentMatchEventMutationVariables = {
  id: Scalars['Int'];
  data: TournamentMatchEventUpdateInput;
};

export type UpdateTournamentMatchEventMutation = { __typename?: 'Mutation' } & {
  updateTournamentMatchEvent: { __typename?: 'TournamentMatchEvent' } & TournamentMatchEventFragment;
};

export type UpdateTournamentMatchLineUpMutationVariables = {
  id: Scalars['Int'];
  data: TournamentMatchLineUpUpdateInput;
};

export type UpdateTournamentMatchLineUpMutation = { __typename?: 'Mutation' } & {
  updateTournamentMatchLineUp: { __typename?: 'TournamentMatchLineUp' } & TournamentMatchLineUpFragment;
};

export type UpdateTournamentMatchProtocolMutationVariables = {
  id: Scalars['Int'];
  data: TournamentMatchProtocolUpdateInput;
};

export type UpdateTournamentMatchProtocolMutation = { __typename?: 'Mutation' } & {
  updateTournamentMatchProtocol: { __typename?: 'TournamentMatchProtocol' } & TournamentMatchProtocolFragment;
};

export type UpdateTournamentPlayerStatMutationVariables = {
  id: Scalars['Int'];
  data: TournamentPlayerStatUpdateInput;
};

export type UpdateTournamentPlayerStatMutation = { __typename?: 'Mutation' } & {
  updateTournamentPlayerStat: { __typename?: 'TournamentPlayerStat' } & TournamentPlayerStatFragment;
};

export type UpdateTournamentRefereeMutationVariables = {
  id: Scalars['Int'];
  data: TournamentRefereeUpdateInput;
};

export type UpdateTournamentRefereeMutation = { __typename?: 'Mutation' } & {
  updateTournamentReferee: { __typename?: 'TournamentReferee' } & TournamentRefereeFragment;
};

export type UpdateTournamentSeasonMutationVariables = {
  id: Scalars['Int'];
  data: TournamentSeasonUpdateInput;
};

export type UpdateTournamentSeasonMutation = { __typename?: 'Mutation' } & {
  updateTournamentSeason: { __typename?: 'TournamentSeason' } & TournamentSeasonFragment;
};

export type UpdateTournamentSeasonTeamMutationVariables = {
  id: Scalars['Int'];
  data: TournamentSeasonTeamUpdateInput;
};

export type UpdateTournamentSeasonTeamMutation = { __typename?: 'Mutation' } & {
  updateTournamentSeasonTeam: { __typename?: 'TournamentSeasonTeam' } & TournamentSeasonTeamFragment;
};

export type UpdateTournamentSeasonTeamStatMutationVariables = {
  id: Scalars['Int'];
  data: TournamentSeasonTeamStatUpdateInput;
};

export type UpdateTournamentSeasonTeamStatMutation = { __typename?: 'Mutation' } & {
  updateTournamentSeasonTeamStat: { __typename?: 'TournamentSeasonTeamStat' } & TournamentSeasonTeamStatFragment;
};

export type UpdateTournamentStadiumMutationVariables = {
  id: Scalars['Int'];
  data: TournamentStadiumUpdateInput;
};

export type UpdateTournamentStadiumMutation = { __typename?: 'Mutation' } & {
  updateTournamentStadium: { __typename?: 'TournamentStadium' } & TournamentStadiumFragment;
};

export type UpdateTournamentTeamMutationVariables = {
  id: Scalars['Int'];
  data: TournamentTeamUpdateInput;
};

export type UpdateTournamentTeamMutation = { __typename?: 'Mutation' } & {
  updateTournamentTeam: { __typename?: 'TournamentTeam' } & TournamentTeamFragment;
};

export type UpdateTournamentTeamMemberMutationVariables = {
  id: Scalars['Int'];
  data: TournamentTeamMemberUpdateInput;
};

export type UpdateTournamentTeamMemberMutation = { __typename?: 'Mutation' } & {
  updateTournamentTeamMember: { __typename?: 'TournamentTeamMember' } & TournamentTeamMemberFragment;
};

export type UpdateTournamentTourMutationVariables = {
  id: Scalars['Int'];
  data: TournamentTourUpdateInput;
};

export type UpdateTournamentTourMutation = { __typename?: 'Mutation' } & {
  updateTournamentTour: { __typename?: 'TournamentTour' } & TournamentTourFragment;
};

export type UpdateUserMutationVariables = {
  id: Scalars['Int'];
  data: UserUpdateInput;
};

export type UpdateUserMutation = { __typename?: 'Mutation' } & { updateUser: { __typename?: 'User' } & UserFragment };

export type UpdateUserApplicationMutationVariables = {
  id: Scalars['Int'];
  data: UserApplicationUpdateInput;
};

export type UpdateUserApplicationMutation = { __typename?: 'Mutation' } & {
  updateUserApplication: { __typename?: 'UserApplication' } & UserApplicationFragment;
};

export type UpdateUserApplicationFieldMutationVariables = {
  id: Scalars['Int'];
  data: UpdateExtensionFieldInput;
};

export type UpdateUserApplicationFieldMutation = { __typename?: 'Mutation' } & {
  updateUserApplicationField: { __typename?: 'ExtensionField' } & ExtensionFieldFragment;
};

export type UpdateUserApplicationTypeMutationVariables = {
  id: Scalars['Int'];
  data: UserApplicationTypeUpdateInput;
};

export type UpdateUserApplicationTypeMutation = { __typename?: 'Mutation' } & {
  updateUserApplicationType: { __typename?: 'UserApplicationType' } & UserApplicationTypeFragment;
};

export type UpdateUserGroupMutationVariables = {
  id: Scalars['Int'];
  data: UserGroupUpdateInput;
};

export type UpdateUserGroupMutation = { __typename?: 'Mutation' } & {
  updateUserGroup: { __typename?: 'UserGroup' } & UserGroupFragment;
};

export type UpdateUserKidMutationVariables = {
  id: Scalars['Int'];
  data: UserKidUpdateInput;
};

export type UpdateUserKidMutation = { __typename?: 'Mutation' } & {
  updateUserKid: { __typename?: 'UserKid' } & Pick<UserKid, 'id'>;
};

export type UpdateUserSubscriberMutationVariables = {
  id: Scalars['String'];
  data: UserSubscriberUpdateInput;
};

export type UpdateUserSubscriberMutation = { __typename?: 'Mutation' } & {
  updateUserSubscriber: { __typename?: 'UserSubscriber' } & UserSubscriberFragment;
};

export type UpdateUserSubscriptionMutationVariables = {
  id: Scalars['Int'];
  data: UserSubscriptionUpdateInput;
};

export type UpdateUserSubscriptionMutation = { __typename?: 'Mutation' } & {
  updateUserSubscription: { __typename?: 'UserSubscription' } & UserSubscriptionFragment;
};

export type UpdateUserSubscriptionsMutationVariables = {
  userId: Scalars['Int'];
  subscriptionId: Array<Scalars['Int']>;
};

export type UpdateUserSubscriptionsMutation = { __typename?: 'Mutation' } & {
  updateUserSubscriptions: { __typename?: 'UserSubscriptionConnection' } & Pick<
    UserSubscriptionConnection,
    'total' | 'limit' | 'cursor'
  > & { edges: Array<{ __typename?: 'UserSubscription' } & UserSubscriptionFragment> };
};

export type UploadFileToImportUsersMutationVariables = {
  file: Scalars['Upload'];
};

export type UploadFileToImportUsersMutation = { __typename?: 'Mutation' } & {
  uploadFileToImportUsers: { __typename?: 'ImportUploadResponse' } & ImportUploadResponseFragment;
};

export type AclQueryVariables = {};

export type AclQuery = { __typename?: 'Query' } & { acl: Array<{ __typename?: 'ACL' } & AclFragment> };

export type AllArticleAuthorsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllArticleAuthorsQuery = { __typename?: 'Query' } & {
  allArticleAuthors: { __typename?: 'ArticleAuthorConnection' } & ArticleAuthorConnectionFragment;
};

export type AllArticleBlockAuthorsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllArticleBlockAuthorsQuery = { __typename?: 'Query' } & {
  allArticleBlockAuthors: Maybe<
    { __typename?: 'ArticleBlockAuthorsConnection' } & ArticleBlockAuthorsConnectionFragment
  >;
};

export type AllArticleBlocksQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllArticleBlocksQuery = { __typename?: 'Query' } & {
  allArticleBlocks: { __typename?: 'ArticleBlockConnection' } & ArticleBlockConnectionFragment;
};

export type AllArticleCategoriesQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllArticleCategoriesQuery = { __typename?: 'Query' } & {
  allArticleCategories: { __typename?: 'ArticleCategoryConnection' } & ArticleCategoryConnectionFragment;
};

export type AllEntityRecordsByArticleLinkTypeQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllEntityRecordsByArticleLinkTypeQuery = { __typename?: 'Query' } & {
  allEntityRecordsByArticleLinkType: {
    __typename?: 'ArticleLinkEntityTypeConnection';
  } & ArticleLinkEntityTypeConnectionFragment;
};

export type AllArticleLinksQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllArticleLinksQuery = { __typename?: 'Query' } & {
  allArticleLinks: { __typename?: 'ArticleLinkConnection' } & ArticleLinkConnectionFragment;
};

export type AllArticleTagsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllArticleTagsQuery = { __typename?: 'Query' } & {
  allArticleTags: { __typename?: 'ArticleTagConnection' } & ArticleTagConnectionFragment;
};

export type AllArticlesQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllArticlesQuery = { __typename?: 'Query' } & {
  allArticles: { __typename?: 'ArticlesConnection' } & ArticlesConnectionFragment;
};

export type AllCatalogueCategoriesQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllCatalogueCategoriesQuery = { __typename?: 'Query' } & {
  allCatalogueCategories: { __typename?: 'CatalogueCategoryConnection' } & CatalogueCategoryConnectionFragment;
};

export type AllCatalogueCategoryFieldsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllCatalogueCategoryFieldsQuery = { __typename?: 'Query' } & {
  allCatalogueCategoryFields: { __typename?: 'CatalogueCategoryFieldsConnection' } & Pick<
    CatalogueCategoryFieldsConnection,
    'total'
  > & { edges: Array<{ __typename?: 'ExtensionField' } & ExtensionFieldContentTranslationFragment> };
};

export type AllCatalogueFieldsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllCatalogueFieldsQuery = { __typename?: 'Query' } & {
  allCatalogueFields: { __typename?: 'CatalogueFieldsConnection' } & Pick<CatalogueFieldsConnection, 'total'> & {
      edges: Array<{ __typename?: 'ExtensionField' } & ExtensionFieldContentTranslationFragment>;
    };
};

export type AllCatalogueItemFieldsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllCatalogueItemFieldsQuery = { __typename?: 'Query' } & {
  allCatalogueItemFields: { __typename?: 'CatalogueItemFieldsConnection' } & CatalogueItemFieldsConnectionFragment;
};

export type AllCatalogueItemPricesQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllCatalogueItemPricesQuery = { __typename?: 'Query' } & {
  allCatalogueItemPrices: { __typename?: 'CatalogueItemPriceConnection' } & CatalogueItemPriceConnectionFragment;
};

export type AllCatalogueItemsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllCatalogueItemsQuery = { __typename?: 'Query' } & {
  allCatalogueItems: { __typename?: 'CatalogueItemConnection' } & CatalogueItemConnectionFragment;
};

export type AllCatalogueItemsAndCategoriesQueryVariables = {
  categoriesQuery?: Maybe<DefaultQueryInput>;
  itemsQuery?: Maybe<DefaultQueryInput>;
};

export type AllCatalogueItemsAndCategoriesQuery = { __typename?: 'Query' } & {
  allCatalogueCategories: { __typename?: 'CatalogueCategoryConnection' } & CatalogueCategoryConnectionFragment;
  allCatalogueItems: { __typename?: 'CatalogueItemConnection' } & CatalogueItemConnectionFragment;
};

export type AllCataloguesQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllCataloguesQuery = { __typename?: 'Query' } & {
  allCatalogues: { __typename?: 'CatalogueConnection' } & CatalogueConnectionFragment;
};

export type AllCateringsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllCateringsQuery = { __typename?: 'Query' } & {
  allCaterings: { __typename?: 'CateringConnection' } & CateringConnectionFragment;
};

export type AllCommerceOrderDatasQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllCommerceOrderDatasQuery = { __typename?: 'Query' } & {
  allCommerceOrderDatas: { __typename?: 'CommerceOrderDataConnection' } & CommerceOrderDataConnectionFragment;
};

export type AllCommerceOrderFieldsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllCommerceOrderFieldsQuery = { __typename?: 'Query' } & {
  allCommerceOrderFields: { __typename?: 'CommerceOrderFieldConnection' } & CommerceOrderFieldConnectionFragment;
};

export type AllCommerceOrderItemsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllCommerceOrderItemsQuery = { __typename?: 'Query' } & {
  allCommerceOrderItems: { __typename?: 'CommerceOrderItemConnection' } & CommerceOrderItemConnectionFragment;
};

export type AllCommerceOrdersQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllCommerceOrdersQuery = { __typename?: 'Query' } & {
  allCommerceOrders: { __typename?: 'CommerceOrderConnection' } & CommerceOrderConnectionFragment;
};

export type AllCommerceShopsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllCommerceShopsQuery = { __typename?: 'Query' } & {
  allCommerceShops: { __typename?: 'CommerceShopConnection' } & CommerceShopConnectionFragment;
};

export type AllCommerceTemplatesQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllCommerceTemplatesQuery = { __typename?: 'Query' } & {
  allCommerceTemplates: { __typename?: 'CommerceTemplateConnection' } & CommerceTemplateConnectionFragment;
};

export type AllContentTranslationsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllContentTranslationsQuery = { __typename?: 'Query' } & {
  allContentTranslations: { __typename?: 'ContentTranslationConnection' } & Pick<
    ContentTranslationConnection,
    'total' | 'limit' | 'cursor' | 'sortable' | 'searchable'
  > & { edges: Array<{ __typename?: 'ContentTranslation' } & ContentTranslationFragment> };
};

export type AllDisputeDisputeResponsesQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllDisputeDisputeResponsesQuery = { __typename?: 'Query' } & {
  allDisputeDisputeResponses: {
    __typename?: 'DisputeDisputeResponseConnection';
  } & DisputeDisputeResponseConnectionFragment;
};

export type AllDisputesQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllDisputesQuery = { __typename?: 'Query' } & {
  allDisputes: { __typename?: 'DisputeConnection' } & DisputeConnectionFragment;
};

export type AllEventEventAccessesQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllEventEventAccessesQuery = { __typename?: 'Query' } & {
  allEventEventAccesses: { __typename?: 'EventEventAccessConnection' } & EventEventAccessConnectionFragment;
};

export type AllEventFieldsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllEventFieldsQuery = { __typename?: 'Query' } & {
  allEventFields: { __typename?: 'EventFieldsConnection' } & EventFieldsConnectionFragment;
};

export type AllEventOrderTicketsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllEventOrderTicketsQuery = { __typename?: 'Query' } & {
  allEventOrderTickets: { __typename?: 'EventOrderTicketConnection' } & EventOrderTicketConnectionFragment;
};

export type AllEventOrdersQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllEventOrdersQuery = { __typename?: 'Query' } & {
  allEventOrders: { __typename?: 'EventOrderConnection' } & EventOrderConnectionFragment;
};

export type AllEventPlaceSectorsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllEventPlaceSectorsQuery = { __typename?: 'Query' } & {
  allEventPlaceSectors: { __typename?: 'EventPlaceSectorConnection' } & EventPlaceSectorConnectionFragment;
};

export type AllEventPlacesQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllEventPlacesQuery = { __typename?: 'Query' } & {
  allEventPlaces: { __typename?: 'EventPlaceConnection' } & EventPlaceConnectionFragment;
};

export type AllEventTypesQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllEventTypesQuery = { __typename?: 'Query' } & {
  allEventTypes: { __typename?: 'EventTypeConnection' } & EventTypeConnectionFragment;
};

export type AllEventsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllEventsQuery = { __typename?: 'Query' } & {
  allEvents: { __typename?: 'EventConnection' } & EventConnectionFragment;
};

export type AllLoyaltyPartnerEntitiesQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllLoyaltyPartnerEntitiesQuery = { __typename?: 'Query' } & {
  allLoyaltyPartnerEntities: { __typename?: 'LoyaltyPartnerEntityConnection' } & LoyaltyPartnerEntityConnectionFragment;
};

export type AllLoyaltyPartnersQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllLoyaltyPartnersQuery = { __typename?: 'Query' } & {
  allLoyaltyPartners: { __typename?: 'LoyaltyPartnerConnection' } & LoyaltyPartnerConnectionFragment;
};

export type AllLoyaltyPromoCodesQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllLoyaltyPromoCodesQuery = { __typename?: 'Query' } & {
  allLoyaltyPromoCodes: { __typename?: 'LoyaltyPromoCodeConnection' } & LoyaltyPromoCodeConnectionFragment;
};

export type AllLoyaltyPromosQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllLoyaltyPromosQuery = { __typename?: 'Query' } & {
  allLoyaltyPromos: { __typename?: 'LoyaltyPromoConnection' } & LoyaltyPromoConnectionFragment;
};

export type AllMessengerMailingsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllMessengerMailingsQuery = { __typename?: 'Query' } & {
  allMessengerMailings: { __typename?: 'MessengerMailingConnection' } & MessengerMailingConnectionFragment;
};

export type AllMessengerQueueNotificationsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllMessengerQueueNotificationsQuery = { __typename?: 'Query' } & {
  allMessengerQueueNotifications: {
    __typename?: 'MessengerQueueNotificationConnection';
  } & MessengerQueueNotificationConnectionFragment;
};

export type AllMessengerQueueTasksQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllMessengerQueueTasksQuery = { __typename?: 'Query' } & {
  allMessengerQueueTasks: { __typename?: 'MessengerQueueTaskConnection' } & MessengerQueueTaskConnectionFragment;
};

export type AllMessengerTemplatesQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllMessengerTemplatesQuery = { __typename?: 'Query' } & {
  allMessengerTemplates: { __typename?: 'MessengerTemplateConnection' } & MessengerTemplateConnectionFragment;
};

export type AllMessengerTransportsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllMessengerTransportsQuery = { __typename?: 'Query' } & {
  allMessengerTransports: { __typename?: 'MessengerTransportConnection' } & MessengerTransportConnectionFragment;
};

export type AllMobileTranslationsQueryVariables = {
  locale: Scalars['String'];
};

export type AllMobileTranslationsQuery = { __typename?: 'Query' } & {
  allMobileTranslations: Maybe<Array<{ __typename?: 'MobileTranslation' } & MobileTranslationFragment>>;
};

export type AllPaymentAccountsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllPaymentAccountsQuery = { __typename?: 'Query' } & {
  allPaymentAccounts: { __typename?: 'PaymentAccountConnection' } & PaymentAccountConnectionFragment;
};

export type AllPaymentCurrenciesQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllPaymentCurrenciesQuery = { __typename?: 'Query' } & {
  allPaymentCurrencies: { __typename?: 'PaymentCurrencyConnection' } & PaymentCurrencyConnectionFragment;
};

export type AllPaymentGatewaiesQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllPaymentGatewaiesQuery = { __typename?: 'Query' } & {
  allPaymentGatewaies: { __typename?: 'PaymentGatewayConnection' } & PaymentGatewayConnectionFragment;
};

export type AllPaymentProvidersQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllPaymentProvidersQuery = { __typename?: 'Query' } & {
  allPaymentProviders: { __typename?: 'PaymentProviderConnection' } & PaymentProviderConnectionFragment;
};

export type AllPaymentTransactionsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllPaymentTransactionsQuery = { __typename?: 'Query' } & {
  allPaymentTransactions: { __typename?: 'PaymentTransactionConnection' } & PaymentTransactionConnectionFragment;
};

export type AllSystemAsyncTasksQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllSystemAsyncTasksQuery = { __typename?: 'Query' } & {
  allSystemAsyncTasks: { __typename?: 'SystemAsyncTaskConnection' } & SystemAsyncTaskConnectionFragment;
};

export type AllSystemLocalesQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllSystemLocalesQuery = { __typename?: 'Query' } & {
  allSystemLocales: { __typename?: 'SystemLocaleConnection' } & SystemLocaleConnectionFragment;
};

export type AllSystemPrintersQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllSystemPrintersQuery = { __typename?: 'Query' } & {
  allSystemPrinters: { __typename?: 'SystemPrinterConnection' } & SystemPrinterConnectionFragment;
};

export type AllSystemTranslationsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllSystemTranslationsQuery = { __typename?: 'Query' } & {
  allSystemTranslations: { __typename?: 'SystemTranslationConnection' } & SystemTranslationConnectionFragment;
};

export type AllSystemUsersQueryVariables = {
  include?: Maybe<SystemUsersWhereInput>;
  sort?: Maybe<Array<DefaultSortInput>>;
  search?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type AllSystemUsersQuery = { __typename?: 'Query' } & {
  allSystemUsers: { __typename?: 'SystemUsersConnection' } & SystemUsersConnectionFragment;
};

export type AllSystemWebHooksQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllSystemWebHooksQuery = { __typename?: 'Query' } & {
  allSystemWebHooks: { __typename?: 'SystemWebHookConnection' } & SystemWebHookConnectionFragment;
};

export type AllTemplateApplePassesQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllTemplateApplePassesQuery = { __typename?: 'Query' } & {
  allTemplateApplePasses: { __typename?: 'TemplateApplePassConnection' } & TemplateApplePassConnectionFragment;
};

export type AllTemplatePdFsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllTemplatePdFsQuery = { __typename?: 'Query' } & {
  allTemplatePDFs: { __typename?: 'TemplatePDFConnection' } & TemplatePdfConnectionFragment;
};

export type AllTournamentDisqualificationsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllTournamentDisqualificationsQuery = { __typename?: 'Query' } & {
  allTournamentDisqualifications: {
    __typename?: 'TournamentDisqualificationConnection';
  } & TournamentDisqualificationConnectionFragment;
};

export type AllTournamentFieldsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllTournamentFieldsQuery = { __typename?: 'Query' } & {
  allTournamentFields: { __typename?: 'TournamentFieldsConnection' } & Pick<TournamentFieldsConnection, 'total'> & {
      edges: Array<{ __typename?: 'ExtensionField' } & ExtensionFieldContentTranslationFragment>;
    };
};

export type AllTournamentMatchEventsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllTournamentMatchEventsQuery = { __typename?: 'Query' } & {
  allTournamentMatchEvents: { __typename?: 'TournamentMatchEventConnection' } & TournamentMatchEventConnectionFragment;
};

export type AllTournamentMatchProtocolsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllTournamentMatchProtocolsQuery = { __typename?: 'Query' } & {
  allTournamentMatchProtocols: {
    __typename?: 'TournamentMatchProtocolConnection';
  } & TournamentMatchProtocolConnectionFragment;
};

export type AllTournamentMatchesQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllTournamentMatchesQuery = { __typename?: 'Query' } & {
  allTournamentMatches: { __typename?: 'TournamentMatchConnection' } & TournamentMatchConnectionFragment;
};

export type AllTournamentPlayerStatsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllTournamentPlayerStatsQuery = { __typename?: 'Query' } & {
  allTournamentPlayerStats: { __typename?: 'TournamentPlayerStatConnection' } & TournamentPlayerStatConnectionFragment;
};

export type AllTournamentRefereesQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllTournamentRefereesQuery = { __typename?: 'Query' } & {
  allTournamentReferees: { __typename?: 'TournamentRefereeConnection' } & TournamentRefereeConnectionFragment;
};

export type AllTournamentSeasonTeamStatsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllTournamentSeasonTeamStatsQuery = { __typename?: 'Query' } & {
  allTournamentSeasonTeamStats: {
    __typename?: 'TournamentSeasonTeamStatConnection';
  } & TournamentSeasonTeamStatConnectionFragment;
};

export type AllTournamentSeasonTeamsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllTournamentSeasonTeamsQuery = { __typename?: 'Query' } & {
  allTournamentSeasonTeams: { __typename?: 'TournamentSeasonTeamConnection' } & TournamentSeasonTeamConnectionFragment;
};

export type AllTournamentSeasonsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllTournamentSeasonsQuery = { __typename?: 'Query' } & {
  allTournamentSeasons: { __typename?: 'TournamentSeasonConnection' } & TournamentSeasonConnectionFragment;
};

export type AllTournamentStadiumsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllTournamentStadiumsQuery = { __typename?: 'Query' } & {
  allTournamentStadiums: { __typename?: 'TournamentStadiumConnection' } & Pick<
    TournamentStadiumConnection,
    'total' | 'limit' | 'cursor' | 'sortable' | 'searchable'
  > & { edges: Array<{ __typename?: 'TournamentStadium' } & TournamentStadiumFragment> };
};

export type AllTournamentTeamFieldsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllTournamentTeamFieldsQuery = { __typename?: 'Query' } & {
  allTournamentTeamFields: { __typename?: 'TournamentTeamFieldsConnection' } & Pick<
    TournamentTeamFieldsConnection,
    'total'
  > & { edges: Array<{ __typename?: 'ExtensionField' } & ExtensionFieldContentTranslationFragment> };
};

export type AllTournamentTeamMemberFieldsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllTournamentTeamMemberFieldsQuery = { __typename?: 'Query' } & {
  allTournamentTeamMemberFields: { __typename?: 'TournamentTeamMemberFieldsConnection' } & Pick<
    TournamentTeamMemberFieldsConnection,
    'total'
  > & { edges: Array<{ __typename?: 'ExtensionField' } & ExtensionFieldContentTranslationFragment> };
};

export type AllTournamentTeamMembersQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllTournamentTeamMembersQuery = { __typename?: 'Query' } & {
  allTournamentTeamMembers: { __typename?: 'TournamentTeamMemberConnection' } & TournamentTeamMemberConnectionFragment;
};

export type AllTournamentTeamsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllTournamentTeamsQuery = { __typename?: 'Query' } & {
  allTournamentTeams: { __typename?: 'TournamentTeamConnection' } & TournamentTeamConnectionFragment;
};

export type AllTournamentTeamsWithSeasonsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllTournamentTeamsWithSeasonsQuery = { __typename?: 'Query' } & {
  allTournamentTeams: { __typename?: 'TournamentTeamConnection' } & TournamentTeamWithSeasonConnectionFragment;
};

export type AllTournamentToursQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllTournamentToursQuery = { __typename?: 'Query' } & {
  allTournamentTours: { __typename?: 'TournamentTourConnection' } & TournamentTourConnectionFragment;
};

export type AllTournamentToursDeepQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllTournamentToursDeepQuery = { __typename?: 'Query' } & {
  allTournamentTours: { __typename?: 'TournamentTourConnection' } & TournamentTourConnectionDeepFragment;
};

export type AllTournamentsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllTournamentsQuery = { __typename?: 'Query' } & {
  allTournaments: { __typename?: 'TournamentConnection' } & TournamentConnectionFragment;
};

export type AllUserApplicationFieldsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllUserApplicationFieldsQuery = { __typename?: 'Query' } & {
  allUserApplicationFields: { __typename?: 'UserApplicationFieldsConnection' } & Pick<
    UserApplicationFieldsConnection,
    'total' | 'limit' | 'cursor' | 'sortable' | 'searchable'
  > & { edges: Array<{ __typename?: 'ExtensionField' } & ExtensionFieldFragment> };
};

export type AllUserApplicationTypesQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllUserApplicationTypesQuery = { __typename?: 'Query' } & {
  allUserApplicationTypes: { __typename?: 'UserApplicationTypeConnection' } & Pick<
    UserApplicationTypeConnection,
    'total' | 'limit' | 'cursor' | 'sortable' | 'searchable'
  > & { edges: Array<{ __typename?: 'UserApplicationType' } & UserApplicationTypeFragment> };
};

export type AllUserApplicationsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllUserApplicationsQuery = { __typename?: 'Query' } & {
  allUserApplications: { __typename?: 'UserApplicationConnection' } & Pick<
    UserApplicationConnection,
    'total' | 'limit' | 'cursor' | 'sortable' | 'searchable'
  > & { edges: Array<{ __typename?: 'UserApplication' } & UserApplicationFragment> };
};

export type AllUserDocumentsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllUserDocumentsQuery = { __typename?: 'Query' } & {
  allUserDocuments: { __typename?: 'UserDocumentConnection' } & UserDocumentConnectionFragment;
};

export type AllUserFieldsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllUserFieldsQuery = { __typename?: 'Query' } & {
  allUserFields: { __typename?: 'UserFieldsConnection' } & UserFieldsConnectionFragment;
};

export type AllUserGroupsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllUserGroupsQuery = { __typename?: 'Query' } & {
  allUserGroups: { __typename?: 'UserGroupConnection' } & UserGroupConnectionFragment;
};

export type AllUserKidsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllUserKidsQuery = { __typename?: 'Query' } & {
  allUserKids: { __typename?: 'UserKidConnection' } & UserKidConnectionFragment;
};

export type AllUserSocialAccountsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllUserSocialAccountsQuery = { __typename?: 'Query' } & {
  allUserSocialAccounts: { __typename?: 'UserSocialAccountConnection' } & Pick<UserSocialAccountConnection, 'total'> & {
      edges: Array<{ __typename?: 'UserSocialAccount' } & UserSocialAccountFragment>;
    };
};

export type AllUserSubscribersQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllUserSubscribersQuery = { __typename?: 'Query' } & {
  allUserSubscribers: { __typename?: 'UserSubscriberConnection' } & UserSubscriberConnectionFragment;
};

export type AllUserSubscribersLogsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllUserSubscribersLogsQuery = { __typename?: 'Query' } & {
  allUserSubscribersLogs: { __typename?: 'UserSubscribersLogConnection' } & UserSubscribersLogConnectionFragment;
};

export type AllUserSubscriptionsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllUserSubscriptionsQuery = { __typename?: 'Query' } & {
  allUserSubscriptions: { __typename?: 'UserSubscriptionConnection' } & UserSubscriptionConnectionFragment;
};

export type AllUsersQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type AllUsersQuery = { __typename?: 'Query' } & {
  allUsers: Maybe<{ __typename?: 'UserConnection' } & UserConnectionFragment>;
};

export type ApplicationFiltersQueryVariables = {
  partnerId: Scalars['Int'];
};

export type ApplicationFiltersQuery = { __typename?: 'Query' } & {
  applicationFilters: Maybe<Array<{ __typename?: 'FilterItem' } & FilterItemFragment>>;
};

export type ArticleQueryVariables = {
  id: Scalars['Int'];
};

export type ArticleQuery = { __typename?: 'Query' } & {
  article: Maybe<{ __typename?: 'Article' } & ArticleFullFragment>;
};

export type ArticleAuthorQueryVariables = {
  id: Scalars['Int'];
};

export type ArticleAuthorQuery = { __typename?: 'Query' } & {
  articleAuthor: { __typename?: 'ArticleAuthor' } & ArticleAuthorFragment;
};

export type ArticleBlockQueryVariables = {
  id: Scalars['Int'];
};

export type ArticleBlockQuery = { __typename?: 'Query' } & {
  articleBlock: { __typename?: 'ArticleBlock' } & ArticleBlockFragment;
};

export type ArticleCategoryQueryVariables = {
  id: Scalars['Int'];
};

export type ArticleCategoryQuery = { __typename?: 'Query' } & {
  articleCategory: { __typename?: 'ArticleCategory' } & ArticleCategoryFragment;
};

export type ArticleLinkQueryVariables = {
  id: Scalars['Int'];
};

export type ArticleLinkQuery = { __typename?: 'Query' } & {
  articleLink: { __typename?: 'ArticleLink' } & ArticleLinkFragment;
};

export type ArticleTagQueryVariables = {
  id: Scalars['Int'];
};

export type ArticleTagQuery = { __typename?: 'Query' } & {
  articleTag: { __typename?: 'ArticleTag' } & ArticleTagFragment;
};

export type CatalogueQueryVariables = {
  id: Scalars['Int'];
};

export type CatalogueQuery = { __typename?: 'Query' } & {
  catalogue: { __typename?: 'Catalogue' } & CatalogueFullFragment;
};

export type CatalogueCategoryQueryVariables = {
  id: Scalars['Int'];
};

export type CatalogueCategoryQuery = { __typename?: 'Query' } & {
  catalogueCategory: { __typename?: 'CatalogueCategory' } & CatalogueCategoryFullFragment;
};

export type CatalogueItemQueryVariables = {
  id: Scalars['Int'];
};

export type CatalogueItemQuery = { __typename?: 'Query' } & {
  catalogueItem: { __typename?: 'CatalogueItem' } & CatalogueItemFragment;
};

export type CatalogueItemPriceQueryVariables = {
  id: Scalars['Int'];
};

export type CatalogueItemPriceQuery = { __typename?: 'Query' } & {
  catalogueItemPrice: { __typename?: 'CatalogueItemPrice' } & CatalogueItemPriceFragment;
};

export type CatalogueItemsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type CatalogueItemsQuery = { __typename?: 'Query' } & {
  catalogueItems: Array<{ __typename?: 'CatalogueItem' } & CatalogueItemInCollectionFragment>;
};

export type CatalogueItemsByEventQueryVariables = {
  eventId: Scalars['Int'];
};

export type CatalogueItemsByEventQuery = { __typename?: 'Query' } & {
  catalogueItemsByEvent: Array<{ __typename?: 'CatalogueItem' } & CatalogueItemFragment>;
};

export type CatalogueSummaryWidgetQueryVariables = {};

export type CatalogueSummaryWidgetQuery = { __typename?: 'Query' } & {
  catalogueSummaryWidget: Maybe<Array<{ __typename?: 'CatalogueSummaryWidget' } & CatalogueSummaryWidgetFragment>>;
};

export type CateringQueryVariables = {
  id: Scalars['Int'];
};

export type CateringQuery = { __typename?: 'Query' } & { catering: { __typename?: 'Catering' } & CateringFragment };

export type ClientPublicProfileQueryVariables = {
  name?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
};

export type ClientPublicProfileQuery = { __typename?: 'Query' } & {
  clientPublicProfile: Maybe<{ __typename?: 'ClientPublicProfile' } & ClientPublicProfileFragment>;
};

export type CommerceOrderQueryVariables = {
  id: Scalars['Int'];
};

export type CommerceOrderQuery = { __typename?: 'Query' } & {
  commerceOrder: { __typename?: 'CommerceOrder' } & CommerceOrderFragment;
};

export type CommerceOrderDataQueryVariables = {
  id: Scalars['Int'];
};

export type CommerceOrderDataQuery = { __typename?: 'Query' } & {
  commerceOrderData: { __typename?: 'CommerceOrderData' } & CommerceOrderDataFragment;
};

export type CommerceOrderFieldQueryVariables = {
  id: Scalars['Int'];
};

export type CommerceOrderFieldQuery = { __typename?: 'Query' } & {
  commerceOrderField: { __typename?: 'CommerceOrderField' } & CommerceOrderFieldFragment;
};

export type CommerceOrderFiltersQueryVariables = {
  shopId: Scalars['Int'];
};

export type CommerceOrderFiltersQuery = { __typename?: 'Query' } & {
  commerceOrderFilters: Maybe<Array<{ __typename?: 'FilterItem' } & FilterItemFragment>>;
};

export type CommerceOrderItemQueryVariables = {
  id: Scalars['Int'];
};

export type CommerceOrderItemQuery = { __typename?: 'Query' } & {
  commerceOrderItem: { __typename?: 'CommerceOrderItem' } & CommerceOrderItemFragment;
};

export type CommerceOrderStatesWidgetQueryVariables = {
  shopId: Scalars['Int'];
};

export type CommerceOrderStatesWidgetQuery = { __typename?: 'Query' } & {
  commerceOrderStatesWidget: Maybe<
    Array<{ __typename?: 'CommerceOrderStatesWidgetData' } & CommerceOrderStatesWidgetDataFragment>
  >;
};

export type CommerceShopQueryVariables = {
  id: Scalars['Int'];
};

export type CommerceShopQuery = { __typename?: 'Query' } & {
  commerceShop: { __typename?: 'CommerceShop' } & CommerceShopFragment;
};

export type CommerceSummaryWidgetQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type CommerceSummaryWidgetQuery = { __typename?: 'Query' } & {
  commerceSummaryWidget: Maybe<Array<{ __typename?: 'CommerceSummaryWidget' } & CommerceSummaryWidgetFragment>>;
};

export type DisputeQueryVariables = {
  id: Scalars['Int'];
};

export type DisputeQuery = { __typename?: 'Query' } & { dispute: { __typename?: 'Dispute' } & DisputeFragment };

export type DisputeDisputeResponseQueryVariables = {
  id: Scalars['Int'];
};

export type DisputeDisputeResponseQuery = { __typename?: 'Query' } & {
  disputeDisputeResponse: { __typename?: 'DisputeDisputeResponse' } & DisputeDisputeResponseFragment;
};

export type DisputeSettingsQueryVariables = {};

export type DisputeSettingsQuery = { __typename?: 'Query' } & {
  disputeSettings: { __typename?: 'DisputeSettings' } & DisputeSettingsFragment;
};

export type EventQueryVariables = {
  id: Scalars['Int'];
};

export type EventQuery = { __typename?: 'Query' } & { event: { __typename?: 'Event' } & EventFullFragment };

export type EventEventAccessQueryVariables = {
  id: Scalars['Int'];
};

export type EventEventAccessQuery = { __typename?: 'Query' } & {
  eventEventAccess: { __typename?: 'EventEventAccess' } & EventEventAccessFragment;
};

export type EventOrderQueryVariables = {
  id: Scalars['Int'];
};

export type EventOrderQuery = { __typename?: 'Query' } & {
  eventOrder: { __typename?: 'EventOrder' } & EventOrderFragment;
};

export type EventOrderTicketQueryVariables = {
  id: Scalars['Int'];
};

export type EventOrderTicketQuery = { __typename?: 'Query' } & {
  eventOrderTicket: { __typename?: 'EventOrderTicket' } & EventOrderTicketFullFragment;
};

export type EventPlaceQueryVariables = {
  id: Scalars['Int'];
};

export type EventPlaceQuery = { __typename?: 'Query' } & {
  eventPlace: { __typename?: 'EventPlace' } & EventPlaceFragment;
};

export type EventWidgetActiveEventsQueryVariables = {};

export type EventWidgetActiveEventsQuery = { __typename?: 'Query' } & {
  eventWidgetActiveEvents: { __typename?: 'EventWidgetActiveEvents' } & EventWidgetActiveEventsFragment;
};

export type EventWidgetEventTypesSalesQueryVariables = {
  placeId: Scalars['Int'];
  dates?: Maybe<Array<Scalars['String']>>;
};

export type EventWidgetEventTypesSalesQuery = { __typename?: 'Query' } & {
  eventWidgetEventTypesSales: { __typename?: 'EventWidgetEventTypesSales' } & Pick<
    EventWidgetEventTypesSales,
    'ticketTotalSale'
  > & { graphs: Array<{ __typename?: 'WidgetSummaryGraph' } & WidgetSummaryGraphFragment> };
};

export type EventWidgetNearestMatchesQueryVariables = {
  placeId: Scalars['Int'];
};

export type EventWidgetNearestMatchesQuery = { __typename?: 'Query' } & {
  eventWidgetNearestMatches: Array<{ __typename?: 'EventWidgetNearestMatch' } & EventWidgetNearestMatchFragment>;
};

export type EventWidgetNextEventQueryVariables = {};

export type EventWidgetNextEventQuery = { __typename?: 'Query' } & {
  eventWidgetNextEvent: Maybe<{ __typename?: 'Event' } & EventWidgetFragment>;
};

export type EventWidgetSalesQueryVariables = {
  placeId?: Maybe<Scalars['Int']>;
};

export type EventWidgetSalesQuery = { __typename?: 'Query' } & {
  eventWidgetSales: { __typename?: 'EventWidgetSales' } & EventWidgetSalesFragment;
};

export type EventWidgetTicketSalesQueryVariables = {
  query?: Maybe<EventWidgetTicketSalesInput>;
};

export type EventWidgetTicketSalesQuery = { __typename?: 'Query' } & {
  eventWidgetTicketSales: Maybe<
    { __typename?: 'EventWidgetTicketSales' } & {
      summary: { __typename?: 'EventWidgetTicketSalesTotals' } & EventWidgetTicketSalesTotalsFragment;
      graphs: Array<{ __typename?: 'CommonGraph' } & CommonGraphFragment>;
    }
  >;
};

export type FiltersQueryVariables = {
  queryName: Scalars['String'];
};

export type FiltersQuery = { __typename?: 'Query' } & {
  filters: Maybe<Array<{ __typename?: 'FilterItem' } & FilterItemFragment>>;
};

export type LoyaltyPartnerQueryVariables = {
  id: Scalars['Int'];
};

export type LoyaltyPartnerQuery = { __typename?: 'Query' } & {
  loyaltyPartner: { __typename?: 'LoyaltyPartner' } & LoyaltyPartnerFragment;
};

export type LoyaltyPartnerEntityByProviderCodeQueryVariables = {
  providerId?: Maybe<Scalars['Int']>;
  providerCode?: Maybe<Scalars['String']>;
};

export type LoyaltyPartnerEntityByProviderCodeQuery = { __typename?: 'Query' } & Pick<
  Query,
  'loyaltyPartnerEntityByProviderCode'
>;

export type LoyaltyPromoQueryVariables = {
  id: Scalars['Int'];
};

export type LoyaltyPromoQuery = { __typename?: 'Query' } & {
  loyaltyPromo: { __typename?: 'ExtendedLoyaltyPromo' } & ExtendedLoyaltyPromoFragment;
};

export type LoyaltyPromoCodeQueryVariables = {
  id: Scalars['Int'];
};

export type LoyaltyPromoCodeQuery = { __typename?: 'Query' } & {
  loyaltyPromoCode: { __typename?: 'LoyaltyPromoCode' } & LoyaltyPromoCodeFragment;
};

export type LoyaltySummaryWidgetQueryVariables = {
  where?: Maybe<LoyaltySummaryWidgetInput>;
};

export type LoyaltySummaryWidgetQuery = { __typename?: 'Query' } & {
  loyaltySummaryWidget: Maybe<Array<{ __typename?: 'LoyaltySummaryWidget' } & LoyaltySummaryWidgetFragment>>;
};

export type MessengerMailingQueryVariables = {
  id: Scalars['Int'];
};

export type MessengerMailingQuery = { __typename?: 'Query' } & {
  messengerMailing: { __typename?: 'MessengerMailing' } & MessengerMailingFragment;
};

export type MessengerMailingChannelsQueryVariables = {};

export type MessengerMailingChannelsQuery = { __typename?: 'Query' } & {
  messengerMailingChannels: Array<
    { __typename?: 'MessengerMailingChannelStat' } & Pick<MessengerMailingChannelStat, 'channel' | 'value'>
  >;
};

export type MessengerMailingStatQueryVariables = {
  mailingId?: Maybe<Scalars['Int']>;
};

export type MessengerMailingStatQuery = { __typename?: 'Query' } & {
  messengerMailingStat: Array<
    { __typename?: 'MessengerQueueNotificationStat' } & Pick<MessengerQueueNotificationStat, 'state' | 'value'>
  >;
};

export type MessengerQueueNotificationQueryVariables = {
  id: Scalars['String'];
};

export type MessengerQueueNotificationQuery = { __typename?: 'Query' } & {
  messengerQueueNotification: { __typename?: 'MessengerQueueNotification' } & MessengerQueueNotificationFragment;
};

export type MessengerQueueTaskQueryVariables = {
  id: Scalars['Int'];
};

export type MessengerQueueTaskQuery = { __typename?: 'Query' } & {
  messengerQueueTask: { __typename?: 'MessengerQueueTask' } & MessengerQueueTaskFragment;
};

export type MessengerSettingQueryVariables = {};

export type MessengerSettingQuery = { __typename?: 'Query' } & {
  messengerSetting: { __typename?: 'MessengerSetting' } & MessengerSettingFragment;
};

export type MessengerTemplateQueryVariables = {
  id: Scalars['Int'];
};

export type MessengerTemplateQuery = { __typename?: 'Query' } & {
  messengerTemplate: { __typename?: 'MessengerTemplate' } & MessengerTemplateFragment;
};

export type MessengerTransportQueryVariables = {
  id: Scalars['Int'];
};

export type MessengerTransportQuery = { __typename?: 'Query' } & {
  messengerTransport: { __typename?: 'MessengerTransport' } & MessengerTransportFragment;
};

export type MobileTranslationQueryVariables = {
  id: Scalars['Int'];
};

export type MobileTranslationQuery = { __typename?: 'Query' } & {
  mobileTranslation: { __typename?: 'MobileTranslation' } & MobileTranslationFragment;
};

export type NavigationQueryVariables = {};

export type NavigationQuery = { __typename?: 'Query' } & {
  navigation: Array<{ __typename?: 'Navigation' } & NavigationFragment>;
};

export type NewUsersWidgetQueryVariables = {
  dates?: Maybe<Array<Scalars['String']>>;
};

export type NewUsersWidgetQuery = { __typename?: 'Query' } & {
  newUsersWidget: { __typename?: 'NewUsersWidget' } & Pick<NewUsersWidget, 'total'> & {
      graphs: Array<{ __typename?: 'WidgetSummaryGraph' } & WidgetSummaryGraphFragment>;
    };
};

export type PaymentAccountQueryVariables = {
  id: Scalars['Int'];
};

export type PaymentAccountQuery = { __typename?: 'Query' } & {
  paymentAccount: { __typename?: 'PaymentAccount' } & PaymentAccountFragment;
};

export type PaymentAccountsQueryVariables = {
  query: DefaultQueryInput;
};

export type PaymentAccountsQuery = { __typename?: 'Query' } & {
  paymentAccounts: Array<{ __typename?: 'PaymentAccount' } & PaymentAccountFlatFragment>;
};

export type PaymentChannelSummaryWidgetQueryVariables = {};

export type PaymentChannelSummaryWidgetQuery = { __typename?: 'Query' } & {
  paymentChannelSummaryWidget: Array<
    { __typename?: 'PaymentChannelSummaryWidget' } & Pick<PaymentChannelSummaryWidget, 'sum'> & {
        channel: { __typename?: 'PaymentChannel' } & PaymentChannelFragment;
        graphs: Array<{ __typename?: 'WidgetSummaryGraph' } & WidgetSummaryGraphFragment>;
      }
  >;
};

export type PaymentCurrencyQueryVariables = {
  id: Scalars['Int'];
};

export type PaymentCurrencyQuery = { __typename?: 'Query' } & {
  paymentCurrency: { __typename?: 'PaymentCurrency' } & PaymentCurrencyFragment;
};

export type PaymentCurrencySummaryWidgetQueryVariables = {};

export type PaymentCurrencySummaryWidgetQuery = { __typename?: 'Query' } & {
  paymentCurrencySummaryWidget: Array<
    { __typename?: 'PaymentCurrencySummaryWidget' } & {
      currency: { __typename?: 'PaymentCurrency' } & PaymentCurrencyFragment;
      graphs: Array<{ __typename?: 'WidgetSummaryGraph' } & WidgetSummaryGraphFragment>;
    }
  >;
};

export type PaymentGatewaySummaryWidgetQueryVariables = {};

export type PaymentGatewaySummaryWidgetQuery = { __typename?: 'Query' } & {
  paymentGatewaySummaryWidget: Array<
    { __typename?: 'PaymentGatewaySummaryWidget' } & {
      gateway: { __typename?: 'PaymentGateway' } & PaymentGatewayFragment;
      graphs: Array<{ __typename?: 'WidgetSummaryGraph' } & WidgetSummaryGraphFragment>;
    }
  >;
};

export type PaymentProviderQueryVariables = {
  id: Scalars['Int'];
};

export type PaymentProviderQuery = { __typename?: 'Query' } & {
  paymentProvider: { __typename?: 'PaymentProvider' } & PaymentProviderFragment;
};

export type PaymentProviderSummaryWidgetQueryVariables = {};

export type PaymentProviderSummaryWidgetQuery = { __typename?: 'Query' } & {
  paymentProviderSummaryWidget: Array<
    { __typename?: 'PaymentProviderSummaryWidget' } & Pick<PaymentProviderSummaryWidget, 'sum'> & {
        provider: { __typename?: 'PaymentProvider' } & PaymentProviderFragment;
        graphs: Array<{ __typename?: 'WidgetSummaryGraph' } & WidgetSummaryGraphFragment>;
      }
  >;
};

export type PaymentTransactionQueryVariables = {
  id: Scalars['Int'];
};

export type PaymentTransactionQuery = { __typename?: 'Query' } & {
  paymentTransaction: { __typename?: 'PaymentTransaction' } & PaymentTransactionFragment;
};

export type RedirectAnalyticsQueryVariables = {
  data: RedirectAnalyticsInput;
};

export type RedirectAnalyticsQuery = { __typename?: 'Query' } & {
  redirectAnalytics: Array<
    { __typename?: 'MessengerMailingRedirectAnalytics' } & Pick<
      MessengerMailingRedirectAnalytics,
      'utmCampaign' | 'redirectsCount'
    >
  >;
};

export type SystemLocaleQueryVariables = {
  id: Scalars['Int'];
};

export type SystemLocaleQuery = { __typename?: 'Query' } & {
  systemLocale: { __typename?: 'SystemLocale' } & SystemLocaleFragment;
};

export type SystemPrinterQueryVariables = {
  id: Scalars['Int'];
};

export type SystemPrinterQuery = { __typename?: 'Query' } & {
  systemPrinter: { __typename?: 'SystemPrinter' } & SystemPrinterFragment;
};

export type SystemUserQueryVariables = {
  id: Scalars['ID'];
};

export type SystemUserQuery = { __typename?: 'Query' } & {
  systemUser: { __typename?: 'SystemUser' } & SystemUserFragment;
};

export type SystemUserAclQueryVariables = {
  userId: Scalars['ID'];
  clientId: Scalars['ID'];
};

export type SystemUserAclQuery = { __typename?: 'Query' } & {
  systemUserACL: Array<{ __typename?: 'ACL' } & AclFragment>;
};

export type SystemUserProfileQueryVariables = {};

export type SystemUserProfileQuery = { __typename?: 'Query' } & {
  systemUserProfile: { __typename?: 'SystemUser' } & SystemUserFragment;
};

export type SystemWebHookQueryVariables = {
  id: Scalars['Int'];
};

export type SystemWebHookQuery = { __typename?: 'Query' } & {
  systemWebHook: { __typename?: 'SystemWebHook' } & SystemWebHookFragment;
};

export type TemplateApplePassQueryVariables = {
  id: Scalars['Int'];
};

export type TemplateApplePassQuery = { __typename?: 'Query' } & {
  templateApplePass: { __typename?: 'TemplateApplePass' } & TemplateApplePassFragment;
};

export type TemplatePdfQueryVariables = {
  id: Scalars['Int'];
};

export type TemplatePdfQuery = { __typename?: 'Query' } & {
  templatePDF: { __typename?: 'TemplatePDF' } & TemplatePdfFragment;
};

export type TournamentQueryVariables = {
  id: Scalars['Int'];
};

export type TournamentQuery = { __typename?: 'Query' } & {
  tournament: { __typename?: 'Tournament' } & TournamentFragment;
};

export type TournamentDisputeQueryVariables = {
  id: Scalars['Int'];
};

export type TournamentDisputeQuery = { __typename?: 'Query' } & {
  tournamentDispute: { __typename?: 'TournamentDispute' } & TournamentDisputeFragment;
};

export type TournamentDisqualificationQueryVariables = {
  id: Scalars['Int'];
};

export type TournamentDisqualificationQuery = { __typename?: 'Query' } & {
  tournamentDisqualification: { __typename?: 'TournamentDisqualification' } & TournamentDisqualificationFullFragment;
};

export type TournamentMatchQueryVariables = {
  id: Scalars['Int'];
};

export type TournamentMatchQuery = { __typename?: 'Query' } & {
  tournamentMatch: { __typename?: 'TournamentMatch' } & TournamentMatchDetailsFragment;
};

export type TournamentMatchEventQueryVariables = {
  id: Scalars['Int'];
};

export type TournamentMatchEventQuery = { __typename?: 'Query' } & {
  tournamentMatchEvent: { __typename?: 'TournamentMatchEvent' } & TournamentMatchEventFragment;
};

export type TournamentMatchLineUpsQueryVariables = {
  query: DefaultQueryInput;
};

export type TournamentMatchLineUpsQuery = { __typename?: 'Query' } & {
  tournamentMatchLineUps: Array<{ __typename?: 'TournamentMatchLineUp' } & TournamentMatchLineUpFragment>;
};

export type TournamentMatchProtocolQueryVariables = {
  id: Scalars['Int'];
};

export type TournamentMatchProtocolQuery = { __typename?: 'Query' } & {
  tournamentMatchProtocol: { __typename?: 'TournamentMatchProtocol' } & TournamentMatchProtocolFragment;
};

export type TournamentPlayerStatQueryVariables = {
  id: Scalars['Int'];
};

export type TournamentPlayerStatQuery = { __typename?: 'Query' } & {
  tournamentPlayerStat: { __typename?: 'TournamentPlayerStat' } & TournamentPlayerStatFullFragment;
};

export type TournamentSeasonQueryVariables = {
  id: Scalars['Int'];
};

export type TournamentSeasonQuery = { __typename?: 'Query' } & {
  tournamentSeason: { __typename?: 'TournamentSeason' } & TournamentSeasonFullFragment;
};

export type TournamentSeasonTeamQueryVariables = {
  id: Scalars['Int'];
};

export type TournamentSeasonTeamQuery = { __typename?: 'Query' } & {
  tournamentSeasonTeam: { __typename?: 'TournamentSeasonTeam' } & TournamentSeasonTeamFragment;
};

export type TournamentSeasonTeamStatQueryVariables = {
  id: Scalars['Int'];
};

export type TournamentSeasonTeamStatQuery = { __typename?: 'Query' } & {
  tournamentSeasonTeamStat: { __typename?: 'TournamentSeasonTeamStat' } & TournamentSeasonTeamStatFragment;
};

export type TournamentStadiumQueryVariables = {
  id: Scalars['Int'];
};

export type TournamentStadiumQuery = { __typename?: 'Query' } & {
  tournamentStadium: { __typename?: 'TournamentStadium' } & TournamentStadiumFragment;
};

export type TournamentTeamQueryVariables = {
  id: Scalars['Int'];
};

export type TournamentTeamQuery = { __typename?: 'Query' } & {
  tournamentTeam: { __typename?: 'TournamentTeam' } & TournamentTeamDetailsFragment;
};

export type TournamentTeamMemberQueryVariables = {
  id: Scalars['Int'];
};

export type TournamentTeamMemberQuery = { __typename?: 'Query' } & {
  tournamentTeamMember: { __typename?: 'TournamentTeamMember' } & TournamentTeamMemberFullFragment;
};

export type TournamentTourQueryVariables = {
  id: Scalars['Int'];
};

export type TournamentTourQuery = { __typename?: 'Query' } & {
  tournamentTour: { __typename?: 'TournamentTour' } & TournamentTourFragment;
};

export type UnisenderListsQueryVariables = {};

export type UnisenderListsQuery = { __typename?: 'Query' } & {
  unisenderLists: Array<{ __typename?: 'UnisenderList' } & Pick<UnisenderList, 'id' | 'title'>>;
};

export type UserQueryVariables = {
  id: Scalars['Int'];
};

export type UserQuery = { __typename?: 'Query' } & { user: { __typename?: 'User' } & UserFragment };

export type UserApplicationLogsQueryVariables = {
  applicationId: Scalars['Int'];
};

export type UserApplicationLogsQuery = { __typename?: 'Query' } & {
  userApplicationLogs: Array<{ __typename?: 'UserApplicationLog' } & UserApplicationLogFragment>;
};

export type UserApplicationsQueryVariables = {
  query?: Maybe<DefaultQueryInput>;
};

export type UserApplicationsQuery = { __typename?: 'Query' } & {
  userApplications: Array<{ __typename?: 'UserApplication' } & UserApplicationFragment>;
};

export type UserGroupQueryVariables = {
  id: Scalars['Int'];
};

export type UserGroupQuery = { __typename?: 'Query' } & { userGroup: { __typename?: 'UserGroup' } & UserGroupFragment };

export type UserSettingsQueryVariables = {};

export type UserSettingsQuery = { __typename?: 'Query' } & {
  userSettings: Maybe<{ __typename?: 'UserSetting' } & UserSettingFragment>;
};

export type UserSubscriberQueryVariables = {
  id: Scalars['String'];
};

export type UserSubscriberQuery = { __typename?: 'Query' } & {
  userSubscriber: { __typename?: 'UserSubscriber' } & UserSubscriberFragment;
};

export type UserSubscriptionQueryVariables = {
  id: Scalars['Int'];
};

export type UserSubscriptionQuery = { __typename?: 'Query' } & {
  userSubscription: { __typename?: 'UserSubscription' } & UserSubscriptionFragment;
};

export type WidgetsQueryVariables = {};

export type WidgetsQuery = { __typename?: 'Query' } & {
  widgets: Maybe<
    { __typename?: 'WidgetsResponse' } & {
      commerceWidget: { __typename?: 'CommerceWidget' } & {
        shops: Array<
          { __typename?: 'CommerceShopInfo' } & Pick<
            CommerceShopInfo,
            | 'id'
            | 'clientId'
            | 'state'
            | 'name'
            | 'description'
            | 'codeMask'
            | 'catalogueId'
            | 'currencyId'
            | 'saleEnabled'
            | 'createdAt'
            | 'updatedAt'
            | 'items'
            | 'orders'
          >
        >;
      };
    }
  >;
};

export const ImportFileFragmentDoc = gql`
  fragment importFile on ImportFile {
    name
    size
    rowsCount
    mimeType
    colSeparator
    rowSeparator
  }
`;
export const ImportColumnsFragmentDoc = gql`
  fragment importColumns on ImportColumns {
    name
    field
    sort
  }
`;
export const ImportFieldsFragmentDoc = gql`
  fragment importFields on ImportFields {
    name
    title
    isExtension
    isRequired
  }
`;
export const ImportUploadResponseFragmentDoc = gql`
  fragment importUploadResponse on ImportUploadResponse {
    file {
      ...importFile
    }
    selection
    columns {
      ...importColumns
    }
    model
    fields {
      ...importFields
    }
  }
  ${ImportFileFragmentDoc}
  ${ImportColumnsFragmentDoc}
  ${ImportFieldsFragmentDoc}
`;
export const TournamentTeamSmallFragmentDoc = gql`
  fragment tournamentTeamSmall on TournamentTeam {
    id
    name
    logo
    website
  }
`;
export const TournamentMatchFragmentDoc = gql`
  fragment tournamentMatch on TournamentMatch {
    id
    team1Id
    team2Id
    team1 {
      ...tournamentTeamSmall
    }
    team2 {
      ...tournamentTeamSmall
    }
    team1IdGoals
    team2IdGoals
    seasonId
    tourId
    state
    videoStreams
    stadiumName
    stadiumAddress
    startDate
    startTime
    finishDate
    startedAt
    finishedAt
    createdAt
    updatedAt
  }
  ${TournamentTeamSmallFragmentDoc}
`;
export const TournamentTeamMemberFragmentDoc = gql`
  fragment tournamentTeamMember on TournamentTeamMember {
    id
    type
    teamId
    firstName
    lastName
    middleName
    birthday
    weight
    height
    number
    role
    bio
    photo
    createdAt
    updatedAt
  }
`;
export const TournamentTeamFragmentDoc = gql`
  fragment tournamentTeam on TournamentTeam {
    id
    clientId
    name
    logo
    altLogo
    coverPhoto
    website
    description
    history
    foundingDate
    teamForm
    data {
      key
      value
      translations {
        localeId
        value
      }
    }
    homeStadium {
      id
      name
      address
    }
    createdAt
    updatedAt
  }
`;
export const TournamentMatchProtocolDetailsFragmentDoc = gql`
  fragment tournamentMatchProtocolDetails on TournamentMatchProtocol {
    id
    matchId
    minute
    event
    teamMemberId
    teamId
    match {
      ...tournamentMatch
    }
    player {
      ...tournamentTeamMember
    }
    team {
      ...tournamentTeam
    }
    createdAt
    updatedAt
  }
  ${TournamentMatchFragmentDoc}
  ${TournamentTeamMemberFragmentDoc}
  ${TournamentTeamFragmentDoc}
`;
export const TournamentMatchProtocolFragmentDoc = gql`
  fragment tournamentMatchProtocol on TournamentMatchProtocol {
    id
    matchId
    minute
    event
    teamMemberId
    teamId
    createdAt
    updatedAt
  }
`;
export const TournamentMatchProtocolConnectionFragmentDoc = gql`
  fragment tournamentMatchProtocolConnection on TournamentMatchProtocolConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...tournamentMatchProtocol
    }
  }
  ${TournamentMatchProtocolFragmentDoc}
`;
export const SystemLocaleFragmentDoc = gql`
  fragment systemLocale on SystemLocale {
    id
    iso
    title
    createdAt
    updatedAt
  }
`;
export const ClientFragmentDoc = gql`
  fragment client on Client {
    id
    title
    role
    type
    logo
    website
    colors
    domain
    createdAt
    updatedAt
  }
`;
export const AclFragmentDoc = gql`
  fragment acl on ACL {
    id
    parentId
    name
    createdAt
    updatedAt
  }
`;
export const SystemUserFragmentDoc = gql`
  fragment systemUser on SystemUser {
    id
    state
    email
    username
    phone
    firstName
    lastName
    middleName
    photo
    createdAt
    updatedAt
    locale
    clients {
      ...client
    }
    ACL {
      ...acl
    }
  }
  ${ClientFragmentDoc}
  ${AclFragmentDoc}
`;
export const ArticleAuthorFragmentDoc = gql`
  fragment articleAuthor on ArticleAuthor {
    id
    articleId
    userId
    user {
      ...systemUser
    }
    role
    createdAt
    updatedAt
  }
  ${SystemUserFragmentDoc}
`;
export const ArticleFragmentDoc = gql`
  fragment article on Article {
    id
    clientId
    categoryId
    state
    tags
    localeId
    locale {
      ...systemLocale
    }
    createdAt
    publishedAt
    title
    authors {
      ...articleAuthor
    }
  }
  ${SystemLocaleFragmentDoc}
  ${ArticleAuthorFragmentDoc}
`;
export const ArticleCategoryFragmentDoc = gql`
  fragment articleCategory on ArticleCategory {
    id
    clientId
    name
    parentId
    createdAt
  }
`;
export const ArticleBlockFragmentDoc = gql`
  fragment articleBlock on ArticleBlock {
    id
    articleId
    authorId
    name
    type
    data
    isPreview
    sort
    createdAt
    updatedAt
  }
`;
export const ArticleLinkFragmentDoc = gql`
  fragment articleLink on ArticleLink {
    articleId
    entityId
    entityType
  }
`;
export const ArticleLogFragmentDoc = gql`
  fragment articleLog on ArticleLog {
    articleId
    authorId
    data
    state
    createdAt
  }
`;
export const ArticleFullFragmentDoc = gql`
  fragment articleFull on Article {
    ...article
    category {
      ...articleCategory
    }
    blocks {
      ...articleBlock
    }
    preview {
      ...articleBlock
    }
    authors {
      ...articleAuthor
    }
    links {
      ...articleLink
    }
    log {
      ...articleLog
    }
  }
  ${ArticleFragmentDoc}
  ${ArticleCategoryFragmentDoc}
  ${ArticleBlockFragmentDoc}
  ${ArticleAuthorFragmentDoc}
  ${ArticleLinkFragmentDoc}
  ${ArticleLogFragmentDoc}
`;
export const ArticleAuthorFullFragmentDoc = gql`
  fragment articleAuthorFull on ArticleAuthor {
    userId
    role
    user {
      ...systemUser
    }
  }
  ${SystemUserFragmentDoc}
`;
export const ArticleAuthorConnectionFragmentDoc = gql`
  fragment articleAuthorConnection on ArticleAuthorConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...articleAuthor
    }
  }
  ${ArticleAuthorFragmentDoc}
`;
export const ArticleBlockAuthorFragmentDoc = gql`
  fragment articleBlockAuthor on ArticleBlockAuthor {
    blockId
    author {
      ...systemUser
    }
  }
  ${SystemUserFragmentDoc}
`;
export const ArticleBlockAuthorsConnectionFragmentDoc = gql`
  fragment articleBlockAuthorsConnection on ArticleBlockAuthorsConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...articleBlockAuthor
    }
  }
  ${ArticleBlockAuthorFragmentDoc}
`;
export const ArticleBlockConnectionFragmentDoc = gql`
  fragment articleBlockConnection on ArticleBlockConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...articleBlock
    }
  }
  ${ArticleBlockFragmentDoc}
`;
export const ArticleBlockTitleFragmentDoc = gql`
  fragment articleBlockTitle on ArticleBlockTitle {
    value
  }
`;
export const ArticleCategoryConnectionFragmentDoc = gql`
  fragment articleCategoryConnection on ArticleCategoryConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...articleCategory
    }
  }
  ${ArticleCategoryFragmentDoc}
`;
export const ArticlesConnectionFragmentDoc = gql`
  fragment articlesConnection on ArticlesConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...article
    }
  }
  ${ArticleFragmentDoc}
`;
export const ArticleLinkFullFragmentDoc = gql`
  fragment articleLinkFull on ArticleLink {
    articleId
    entityId
    entityType
  }
`;
export const ArticleLinkConnectionFragmentDoc = gql`
  fragment articleLinkConnection on ArticleLinkConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...articleLink
    }
  }
  ${ArticleLinkFragmentDoc}
`;
export const ArticleLinkEntityRecordFragmentDoc = gql`
  fragment articleLinkEntityRecord on ArticleLinkEntityRecord {
    ... on TournamentMatch {
      ...tournamentMatch
    }
    ... on TournamentTour {
      id
    }
    ... on TournamentTeam {
      id
    }
    ... on TournamentMatchEvent {
      id
    }
    ... on TournamentTeamMember {
      id
    }
  }
  ${TournamentMatchFragmentDoc}
`;
export const ArticleLinkEntityTypeConnectionFragmentDoc = gql`
  fragment articleLinkEntityTypeConnection on ArticleLinkEntityTypeConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...articleLinkEntityRecord
    }
  }
  ${ArticleLinkEntityRecordFragmentDoc}
`;
export const ArticleTagFragmentDoc = gql`
  fragment articleTag on ArticleTag {
    id
    createdAt
    updatedAt
  }
`;
export const ArticleTagConnectionFragmentDoc = gql`
  fragment articleTagConnection on ArticleTagConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...articleTag
    }
  }
  ${ArticleTagFragmentDoc}
`;
export const CatalogueFlatFragmentDoc = gql`
  fragment catalogueFlat on Catalogue {
    id
    clientId
    state
    name
    description
    localeIdx
    preview
    currencyIdx
    createdAt
    updatedAt
  }
`;
export const LocaleFragmentDoc = gql`
  fragment locale on Locale {
    id
    iso
    title
    createdAt
    updatedAt
  }
`;
export const CurrencyFragmentDoc = gql`
  fragment currency on Currency {
    id
    iso3
    name
    sign
    isDefault
    isVirtual
    createdAt
    updatedAt
  }
`;
export const ExtensionFieldTranslationFragmentDoc = gql`
  fragment extensionFieldTranslation on ExtensionFieldTranslation {
    id
    localeId
    fieldId
    value
    createdAt
    updatedAt
  }
`;
export const ExtensionFieldFragmentDoc = gql`
  fragment extensionField on ExtensionField {
    id
    relationId
    name
    dataType
    isReadOnly
    sort
    i18n
    translations {
      ...extensionFieldTranslation
    }
    createdAt
    updatedAt
  }
  ${ExtensionFieldTranslationFragmentDoc}
`;
export const ExtensionDataFragmentDoc = gql`
  fragment extensionData on ExtensionData {
    id
    fieldId
    localeId
    instanceId
    value
    key
    field {
      ...extensionField
    }
    createdAt
    updatedAt
  }
  ${ExtensionFieldFragmentDoc}
`;
export const CatalogueFullFragmentDoc = gql`
  fragment catalogueFull on Catalogue {
    ...catalogueFlat
    locales {
      ...locale
    }
    currencies {
      ...currency
    }
    data {
      ...extensionData
    }
  }
  ${CatalogueFlatFragmentDoc}
  ${LocaleFragmentDoc}
  ${CurrencyFragmentDoc}
  ${ExtensionDataFragmentDoc}
`;
export const CatalogueCategoryFullFragmentDoc = gql`
  fragment catalogueCategoryFull on CatalogueCategory {
    id
    name
    catalogueId
    state
    data {
      ...extensionData
    }
    createdAt
    updatedAt
  }
  ${ExtensionDataFragmentDoc}
`;
export const CatalogueCategoryFragmentDoc = gql`
  fragment catalogueCategory on CatalogueCategory {
    id
    name
    catalogueId
    state
    createdAt
    updatedAt
  }
`;
export const CatalogueCategoryConnectionFragmentDoc = gql`
  fragment catalogueCategoryConnection on CatalogueCategoryConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...catalogueCategory
    }
  }
  ${CatalogueCategoryFragmentDoc}
`;
export const CatalogueFragmentDoc = gql`
  fragment catalogue on Catalogue {
    ...catalogueFlat
    locales {
      ...locale
    }
    currencies {
      ...currency
    }
  }
  ${CatalogueFlatFragmentDoc}
  ${LocaleFragmentDoc}
  ${CurrencyFragmentDoc}
`;
export const CatalogueConnectionFragmentDoc = gql`
  fragment catalogueConnection on CatalogueConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...catalogue
    }
  }
  ${CatalogueFragmentDoc}
`;
export const CatalogueItemFlatFragmentDoc = gql`
  fragment catalogueItemFlat on CatalogueItem {
    id
    catalogueId
    name
    preview
    state
    createdAt
    updatedAt
    parentId
  }
`;
export const CatalogueItemPriceFragmentDoc = gql`
  fragment catalogueItemPrice on CatalogueItemPrice {
    id
    currencyId
    value
    catalogueItemId
    currency {
      ...currency
    }
    createdAt
    updatedAt
  }
  ${CurrencyFragmentDoc}
`;
export const CatalogueItemInCollectionFragmentDoc = gql`
  fragment catalogueItemInCollection on CatalogueItem {
    ...catalogueItemFlat
    prices {
      ...catalogueItemPrice
    }
  }
  ${CatalogueItemFlatFragmentDoc}
  ${CatalogueItemPriceFragmentDoc}
`;
export const CatalogueItemConnectionFragmentDoc = gql`
  fragment catalogueItemConnection on CatalogueItemConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...catalogueItemInCollection
    }
  }
  ${CatalogueItemInCollectionFragmentDoc}
`;
export const CatalogueItemFieldsConnectionFragmentDoc = gql`
  fragment catalogueItemFieldsConnection on CatalogueItemFieldsConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...extensionField
    }
  }
  ${ExtensionFieldFragmentDoc}
`;
export const CatalogueItemPriceConnectionFragmentDoc = gql`
  fragment catalogueItemPriceConnection on CatalogueItemPriceConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...catalogueItemPrice
    }
  }
  ${CatalogueItemPriceFragmentDoc}
`;
export const CatalogueItemFragmentDoc = gql`
  fragment catalogueItem on CatalogueItem {
    ...catalogueItemFlat
    data {
      ...extensionData
    }
  }
  ${CatalogueItemFlatFragmentDoc}
  ${ExtensionDataFragmentDoc}
`;
export const CatalogueSummaryFragmentDoc = gql`
  fragment catalogueSummary on CatalogueSummary {
    itemsCount
    topItems {
      ...catalogueItem
    }
  }
  ${CatalogueItemFragmentDoc}
`;
export const CatalogueSummaryWidgetFragmentDoc = gql`
  fragment catalogueSummaryWidget on CatalogueSummaryWidget {
    catalogue {
      ...catalogue
    }
    summary {
      ...catalogueSummary
    }
  }
  ${CatalogueFragmentDoc}
  ${CatalogueSummaryFragmentDoc}
`;
export const CateringFlatFragmentDoc = gql`
  fragment cateringFlat on Catering {
    id
    clientId
    eventPlaceId
    commerceShopId
    state
    printReceiptTemplateId
    name
    sectors
    createdAt
    updatedAt
  }
`;
export const EventPlaceFragmentDoc = gql`
  fragment eventPlace on EventPlace {
    id
    clientId
    name
    address
    createdAt
    updatedAt
    locales {
      ...systemLocale
    }
  }
  ${SystemLocaleFragmentDoc}
`;
export const CommerceShopFlatFragmentDoc = gql`
  fragment commerceShopFlat on CommerceShop {
    id
    clientId
    state
    name
    description
    codeMask
    catalogueId
    currencyId
    createdAt
    updatedAt
    settings
  }
`;
export const CateringFragmentDoc = gql`
  fragment catering on Catering {
    ...cateringFlat
    eventPlace {
      ...eventPlace
    }
    commerceShop {
      ...commerceShopFlat
    }
  }
  ${CateringFlatFragmentDoc}
  ${EventPlaceFragmentDoc}
  ${CommerceShopFlatFragmentDoc}
`;
export const CateringConnectionFragmentDoc = gql`
  fragment cateringConnection on CateringConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...catering
    }
  }
  ${CateringFragmentDoc}
`;
export const ClientPublicSettingsFragmentDoc = gql`
  fragment clientPublicSettings on ClientPublicSettings {
    name
    logo
    website
    colors
    defaultLocale
    domain
  }
`;
export const ClientPublicProfileFragmentDoc = gql`
  fragment clientPublicProfile on ClientPublicProfile {
    id
    title
    settings {
      ...clientPublicSettings
    }
  }
  ${ClientPublicSettingsFragmentDoc}
`;
export const CommerceOrderUpdateFragmentDoc = gql`
  fragment commerceOrderUpdate on CommerceOrder {
    id
    shopId
    clientId
    state
    paymentState
    userId
    externalEntityId
    code
    total
    paidAmount
    createdAt
    updatedAt
  }
`;
export const UserFlatFragmentDoc = gql`
  fragment userFlat on User {
    id
    uuid
    state
    username
    firstName
    lastName
    middleName
    birthday
    email
    phone
    gender
    isVerifiedEmail
    isVerifiedPhone
    createdAt
    updatedAt
  }
`;
export const CommerceOrderItemFlatFragmentDoc = gql`
  fragment commerceOrderItemFlat on CommerceOrderItem {
    id
    orderId
    state
    catalogueItemId
    discountId
    currencyId
    qty
    price
    discountedPrice
    total
    createdAt
    updatedAt
  }
`;
export const CommerceOrderItemFragmentDoc = gql`
  fragment commerceOrderItem on CommerceOrderItem {
    ...commerceOrderItemFlat
    catalogueItem {
      ...catalogueItemFlat
    }
  }
  ${CommerceOrderItemFlatFragmentDoc}
  ${CatalogueItemFlatFragmentDoc}
`;
export const CommerceOrderFragmentDoc = gql`
  fragment commerceOrder on CommerceOrder {
    id
    shopId
    clientId
    state
    paymentState
    user {
      ...userFlat
    }
    data {
      key
      value
    }
    items {
      ...commerceOrderItem
    }
    userId
    externalEntityId
    code
    total
    paidAmount
    createdAt
    updatedAt
  }
  ${UserFlatFragmentDoc}
  ${CommerceOrderItemFragmentDoc}
`;
export const CommerceOrderConnectionFragmentDoc = gql`
  fragment commerceOrderConnection on CommerceOrderConnection {
    total
    limit
    cursor
    searchable
    sortable
    edges {
      ...commerceOrder
    }
  }
  ${CommerceOrderFragmentDoc}
`;
export const CommerceOrderFieldFragmentDoc = gql`
  fragment commerceOrderField on CommerceOrderField {
    id
    shopId
    name
    dataType
    hasLocale
    sort
    createdAt
    updatedAt
  }
`;
export const CommerceOrderDataFragmentDoc = gql`
  fragment commerceOrderData on CommerceOrderData {
    id
    value
    orderFieldId
    field {
      ...commerceOrderField
    }
    key
    createdAt
    updatedAt
  }
  ${CommerceOrderFieldFragmentDoc}
`;
export const CommerceOrderDataConnectionFragmentDoc = gql`
  fragment commerceOrderDataConnection on CommerceOrderDataConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...commerceOrderData
    }
  }
  ${CommerceOrderDataFragmentDoc}
`;
export const CommerceOrderFieldConnectionFragmentDoc = gql`
  fragment commerceOrderFieldConnection on CommerceOrderFieldConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...commerceOrderField
    }
  }
  ${CommerceOrderFieldFragmentDoc}
`;
export const CommerceOrderFieldTranslationFragmentDoc = gql`
  fragment commerceOrderFieldTranslation on CommerceOrderFieldTranslation {
    id
    localeId
    value
    createdAt
    updatedAt
  }
`;
export const CommerceOrderItemConnectionFragmentDoc = gql`
  fragment commerceOrderItemConnection on CommerceOrderItemConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...commerceOrderItem
    }
  }
  ${CommerceOrderItemFragmentDoc}
`;
export const CommerceOrderStatesWidgetDataFragmentDoc = gql`
  fragment commerceOrderStatesWidgetData on CommerceOrderStatesWidgetData {
    state
    count
    total
  }
`;
export const CommerceShopFragmentDoc = gql`
  fragment commerceShop on CommerceShop {
    ...commerceShopFlat
    currency {
      ...currency
    }
    catalogue {
      ...catalogueFlat
    }
  }
  ${CommerceShopFlatFragmentDoc}
  ${CurrencyFragmentDoc}
  ${CatalogueFlatFragmentDoc}
`;
export const CommerceShopConnectionFragmentDoc = gql`
  fragment commerceShopConnection on CommerceShopConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...commerceShop
    }
  }
  ${CommerceShopFragmentDoc}
`;
export const CommerceShopSummaryFragmentDoc = gql`
  fragment commerceShopSummary on CommerceShopSummary {
    ordersCount
    itemsCount
    topItems {
      ...catalogueItem
    }
  }
  ${CatalogueItemFragmentDoc}
`;
export const CommerceShopGraphFragmentDoc = gql`
  fragment commerceShopGraph on CommerceShopGraph {
    name
    data {
      date
      total
      paidAmount
    }
  }
`;
export const CommerceSummaryWidgetFragmentDoc = gql`
  fragment commerceSummaryWidget on CommerceSummaryWidget {
    shop {
      ...commerceShop
    }
    summary {
      ...commerceShopSummary
    }
    graphs {
      ...commerceShopGraph
    }
  }
  ${CommerceShopFragmentDoc}
  ${CommerceShopSummaryFragmentDoc}
  ${CommerceShopGraphFragmentDoc}
`;
export const CommerceTemplateFragmentDoc = gql`
  fragment CommerceTemplate on CommerceTemplate {
    id
    clientId
    name
    preview
    body
    createdAt
    updatedAt
  }
`;
export const CommerceTemplateConnectionFragmentDoc = gql`
  fragment CommerceTemplateConnection on CommerceTemplateConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...CommerceTemplate
    }
  }
  ${CommerceTemplateFragmentDoc}
`;
export const CommonGraphDatasetDataFragmentDoc = gql`
  fragment commonGraphDatasetData on CommonGraphDatasetData {
    x
    y
  }
`;
export const CommonGraphDatasetFragmentDoc = gql`
  fragment commonGraphDataset on CommonGraphDataset {
    label
    data {
      ...commonGraphDatasetData
    }
  }
  ${CommonGraphDatasetDataFragmentDoc}
`;
export const CommonGraphFragmentDoc = gql`
  fragment commonGraph on CommonGraph {
    name
    dataset {
      ...commonGraphDataset
    }
  }
  ${CommonGraphDatasetFragmentDoc}
`;
export const ContentTranslationFragmentDoc = gql`
  fragment contentTranslation on ContentTranslation {
    id
    clientId
    channel
    localeId
    key
    value
    createdAt
    updatedAt
  }
`;
export const DisputeFlatFragmentDoc = gql`
  fragment disputeFlat on Dispute {
    id
    state
    result
    confirmation
    userId
    matchId
    amount
    winAmount
    text
    createdAt
    updatedAt
  }
`;
export const DisputeFragmentDoc = gql`
  fragment dispute on Dispute {
    ...disputeFlat
    user {
      ...userFlat
    }
    match {
      ...tournamentMatch
    }
  }
  ${DisputeFlatFragmentDoc}
  ${UserFlatFragmentDoc}
  ${TournamentMatchFragmentDoc}
`;
export const DisputeConnectionFragmentDoc = gql`
  fragment disputeConnection on DisputeConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...dispute
    }
  }
  ${DisputeFragmentDoc}
`;
export const DisputeDisputeResponseFlatFragmentDoc = gql`
  fragment disputeDisputeResponseFlat on DisputeDisputeResponse {
    id
    clientId
    disputeId
    state
    userId
    amount
    comment
    respondedAt
    createdAt
    updatedAt
  }
`;
export const DisputeDisputeResponseFragmentDoc = gql`
  fragment disputeDisputeResponse on DisputeDisputeResponse {
    ...disputeDisputeResponseFlat
    user {
      ...userFlat
    }
  }
  ${DisputeDisputeResponseFlatFragmentDoc}
  ${UserFlatFragmentDoc}
`;
export const DisputeDisputeResponseConnectionFragmentDoc = gql`
  fragment disputeDisputeResponseConnection on DisputeDisputeResponseConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...disputeDisputeResponse
    }
  }
  ${DisputeDisputeResponseFragmentDoc}
`;
export const DisputeSettingsFragmentDoc = gql`
  fragment disputeSettings on DisputeSettings {
    id
    clientId
    pushTemplates
    simulators
    transportId
    createdAt
    updatedAt
  }
`;
export const EventTypeFragmentDoc = gql`
  fragment eventType on EventType {
    id
    name
    description
    image
    templates
    createdAt
    updatedAt
  }
`;
export const TournamentMatchWidgetFragmentDoc = gql`
  fragment tournamentMatchWidget on TournamentMatch {
    id
    team1Id
    team2Id
    state
    startDate
    startTime
    finishedAt
    result
    team1 {
      ...tournamentTeamSmall
    }
    team2 {
      ...tournamentTeamSmall
    }
  }
  ${TournamentTeamSmallFragmentDoc}
`;
export const EventWidgetFragmentDoc = gql`
  fragment eventWidget on Event {
    id
    clientId
    state
    title
    eventTypeId
    description
    saleStartDate
    saleFinishDate
    startDate
    finishDate
    availableSlots
    saleLimit
    templates
    eventType {
      ...eventType
    }
    tournamentMatch {
      ...tournamentMatchWidget
    }
    createdAt
    updatedAt
  }
  ${EventTypeFragmentDoc}
  ${TournamentMatchWidgetFragmentDoc}
`;
export const EventFragmentDoc = gql`
  fragment event on Event {
    id
    clientId
    image
    state
    title
    eventTypeId
    eventPlaceId
    description
    saleStartDate
    saleFinishDate
    startDate
    finishDate
    availableSlots
    saleLimit
    createdAt
    updatedAt
    templates
    isShowPublic
    data {
      ...extensionData
    }
  }
  ${ExtensionDataFragmentDoc}
`;
export const EventFullFragmentDoc = gql`
  fragment eventFull on Event {
    ...event
    eventType {
      ...eventType
    }
    tournamentMatch {
      ...tournamentMatchWidget
    }
  }
  ${EventFragmentDoc}
  ${EventTypeFragmentDoc}
  ${TournamentMatchWidgetFragmentDoc}
`;
export const EventConnectionFragmentDoc = gql`
  fragment eventConnection on EventConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...eventFull
    }
  }
  ${EventFullFragmentDoc}
`;
export const EventEventAccessFragmentDoc = gql`
  fragment eventEventAccess on EventEventAccess {
    id
    eventId
    isVisibleToPublic
    userGroupToSector
    sectorUserSeatLimit
    createdAt
    updatedAt
  }
`;
export const EventEventAccessConnectionFragmentDoc = gql`
  fragment eventEventAccessConnection on EventEventAccessConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...eventEventAccess
    }
  }
  ${EventEventAccessFragmentDoc}
`;
export const EventFieldsConnectionFragmentDoc = gql`
  fragment eventFieldsConnection on EventFieldsConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...extensionField
    }
  }
  ${ExtensionFieldFragmentDoc}
`;
export const EventOrderFullFragmentDoc = gql`
  fragment eventOrderFull on EventOrder {
    id
    clientId
    state
    paymentState
    discountId
    userId
    code
    total
    paidAmount
    reservedTill
    isFiscal
    meta
    tickets {
      id
      eventOrderId
      eventId
      barcode
      price
      quantity
      discountId
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
`;
export const EventOrderConnectionFragmentDoc = gql`
  fragment eventOrderConnection on EventOrderConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...eventOrderFull
    }
  }
  ${EventOrderFullFragmentDoc}
`;
export const EventOrderTicketFlatFragmentDoc = gql`
  fragment eventOrderTicketFlat on EventOrderTicket {
    id
    eventOrderId
    eventId
    barcode
    price
    quantity
    discountId
    createdAt
    updatedAt
  }
`;
export const EventOrderTicketWithDataFragmentDoc = gql`
  fragment eventOrderTicketWithData on EventOrderTicket {
    ...eventOrderTicketFlat
    data {
      ...extensionData
    }
  }
  ${EventOrderTicketFlatFragmentDoc}
  ${ExtensionDataFragmentDoc}
`;
export const EventOrderFragmentDoc = gql`
  fragment eventOrder on EventOrder {
    id
    clientId
    state
    paymentState
    discountId
    userId
    code
    total
    paidAmount
    reservedTill
    isFiscal
    meta
    createdAt
    updatedAt
  }
`;
export const EventOrderTicketFullFragmentDoc = gql`
  fragment eventOrderTicketFull on EventOrderTicket {
    ...eventOrderTicketFlat
    data {
      ...extensionData
    }
    eventOrder {
      ...eventOrder
      user {
        ...userFlat
      }
    }
  }
  ${EventOrderTicketFlatFragmentDoc}
  ${ExtensionDataFragmentDoc}
  ${EventOrderFragmentDoc}
  ${UserFlatFragmentDoc}
`;
export const EventOrderTicketConnectionFragmentDoc = gql`
  fragment eventOrderTicketConnection on EventOrderTicketConnection {
    total
    limit
    cursor
    searchable
    sortable
    edges {
      ...eventOrderTicketFull
    }
  }
  ${EventOrderTicketFullFragmentDoc}
`;
export const EventPlaceConnectionFragmentDoc = gql`
  fragment eventPlaceConnection on EventPlaceConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...eventPlace
    }
  }
  ${EventPlaceFragmentDoc}
`;
export const DefaultConnectionFragmentDoc = gql`
  fragment defaultConnection on DefaultConnection {
    total
    cursor
    limit
    sortable
    searchable
  }
`;
export const EventPlaceSectorFragmentDoc = gql`
  fragment eventPlaceSector on EventPlaceSector {
    id
    eventPlaceId
    name
    isSectorSaleEnabled
    userSeatLimit
    seatsCount
    isAllowed
    createdAt
    updatedAt
  }
`;
export const EventPlaceSectorConnectionFragmentDoc = gql`
  fragment eventPlaceSectorConnection on EventPlaceSectorConnection {
    ...defaultConnection
    edges {
      ...eventPlaceSector
    }
  }
  ${DefaultConnectionFragmentDoc}
  ${EventPlaceSectorFragmentDoc}
`;
export const EventTypeConnectionFragmentDoc = gql`
  fragment eventTypeConnection on EventTypeConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...eventType
    }
  }
  ${EventTypeFragmentDoc}
`;
export const EventWidgetActiveEventsFragmentDoc = gql`
  fragment eventWidgetActiveEvents on EventWidgetActiveEvents {
    activeCount
    todayCount
  }
`;
export const NearestMatchFragmentDoc = gql`
  fragment nearestMatch on Event {
    id
    state
    title
    eventTypeId
    description
    saleStartDate
    saleFinishDate
    startDate
    finishDate
    templates
    tournamentMatch {
      ...tournamentMatchWidget
    }
    createdAt
    updatedAt
  }
  ${TournamentMatchWidgetFragmentDoc}
`;
export const EventWidgetNearestMatchFragmentDoc = gql`
  fragment eventWidgetNearestMatch on EventWidgetNearestMatch {
    ticketTotalSale
    event {
      ...nearestMatch
    }
  }
  ${NearestMatchFragmentDoc}
`;
export const EventWidgetSalesFragmentDoc = gql`
  fragment eventWidgetSales on EventWidgetSales {
    eventSaleCount
    ticketTotalSale
  }
`;
export const EventWidgetTicketSalesTotalsFragmentDoc = gql`
  fragment eventWidgetTicketSalesTotals on EventWidgetTicketSalesTotals {
    totalCount
    soldCount
    availableCount
    soldAmount
  }
`;
export const ExtensionFieldContentTranslationFragmentDoc = gql`
  fragment extensionFieldContentTranslation on ExtensionField {
    id
    relationId
    name
    dataType
    isReadOnly
    sort
    i18n
    translations {
      ...extensionFieldTranslation
    }
    createdAt
    updatedAt
  }
  ${ExtensionFieldTranslationFragmentDoc}
`;
export const FilterItemFragmentDoc = gql`
  fragment filterItem on FilterItem {
    __typename @skip(if: true)
    field
    type
    dataType
    options {
      __typename @skip(if: true)
      id
      name
    }
    isRangeAvailable
    isExcludAvailable
    min
    max
    step
  }
`;
export const LoyaltyPartnerFragmentDoc = gql`
  fragment loyaltyPartner on LoyaltyPartner {
    id
    clientId
    state
    name
    description
    logo
    website
    shopId
    providerId
    createdAt
    updatedAt
  }
`;
export const LoyaltyPartnerConnectionFragmentDoc = gql`
  fragment loyaltyPartnerConnection on LoyaltyPartnerConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...loyaltyPartner
    }
  }
  ${LoyaltyPartnerFragmentDoc}
`;
export const LoyaltyPartnerEntityConnectionFragmentDoc = gql`
  fragment loyaltyPartnerEntityConnection on LoyaltyPartnerEntityConnection {
    total
    limit
    cursor
    sortable
    edges
  }
`;
export const LoyaltyPromoFragmentDoc = gql`
  fragment loyaltyPromo on LoyaltyPromo {
    id
    clientId
    name
    description
    imageUri
    state
    amount
    currencyId
    code
    codeType
    codesCount
    startDate
    finishDate
    promoCodeStartDate
    promoCodeFinishDate
    discountMaxAmount
    discountPercent
    createdAt
    updatedAt
  }
`;
export const LoyaltyPromoCodeFullFragmentDoc = gql`
  fragment loyaltyPromoCodeFull on LoyaltyPromoCode {
    id
    promoId
    userId
    state
    code
    amount
    amountUsed
    currencyId
    expiredAt
    appliedAt
    promo {
      ...loyaltyPromo
    }
    createdAt
    updatedAt
  }
  ${LoyaltyPromoFragmentDoc}
`;
export const LoyaltyPromoCodeFragmentDoc = gql`
  fragment loyaltyPromoCode on LoyaltyPromoCode {
    id
    promoId
    userId
    state
    code
    amount
    amountUsed
    currencyId
    expiredAt
    appliedAt
    createdAt
    updatedAt
  }
`;
export const LoyaltyPromoCodeConnectionFragmentDoc = gql`
  fragment loyaltyPromoCodeConnection on LoyaltyPromoCodeConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...loyaltyPromoCode
    }
  }
  ${LoyaltyPromoCodeFragmentDoc}
`;
export const ExtendedLoyaltyPromoFragmentDoc = gql`
  fragment extendedLoyaltyPromo on ExtendedLoyaltyPromo {
    id
    clientId
    name
    description
    imageUri
    state
    amount
    currencyId
    code
    codeType
    codesCount
    startDate
    finishDate
    promoCodeStartDate
    promoCodeFinishDate
    discountMaxAmount
    discountPercent
    createdAt
    updatedAt
    usedCount
    total
  }
`;
export const LoyaltyPromoConnectionFragmentDoc = gql`
  fragment loyaltyPromoConnection on LoyaltyPromoConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...extendedLoyaltyPromo
    }
  }
  ${ExtendedLoyaltyPromoFragmentDoc}
`;
export const LoyaltyAccountSummaryFragmentDoc = gql`
  fragment loyaltyAccountSummary on LoyaltyAccountSummary {
    applicationsCount
    accountsCount
    unprocessedCount
  }
`;
export const LoyaltySummaryGraphDatasetDataFragmentDoc = gql`
  fragment loyaltySummaryGraphDatasetData on LoyaltySummaryGraphDatasetData {
    x
    y
  }
`;
export const LoyaltySummaryGraphDatasetFragmentDoc = gql`
  fragment loyaltySummaryGraphDataset on LoyaltySummaryGraphDataset {
    label
    data {
      ...loyaltySummaryGraphDatasetData
    }
  }
  ${LoyaltySummaryGraphDatasetDataFragmentDoc}
`;
export const LoyaltySummaryGraphFragmentDoc = gql`
  fragment loyaltySummaryGraph on LoyaltySummaryGraph {
    name
    dataset {
      ...loyaltySummaryGraphDataset
    }
  }
  ${LoyaltySummaryGraphDatasetFragmentDoc}
`;
export const LoyaltySummaryWidgetFragmentDoc = gql`
  fragment loyaltySummaryWidget on LoyaltySummaryWidget {
    partner {
      ...loyaltyPartner
    }
    summary {
      ...loyaltyAccountSummary
    }
    graphs {
      ...loyaltySummaryGraph
    }
  }
  ${LoyaltyPartnerFragmentDoc}
  ${LoyaltyAccountSummaryFragmentDoc}
  ${LoyaltySummaryGraphFragmentDoc}
`;
export const MessengerQueueNotificationStatFragmentDoc = gql`
  fragment messengerQueueNotificationStat on MessengerQueueNotificationStat {
    state
    value
  }
`;
export const MessengerQueueTaskFragmentDoc = gql`
  fragment messengerQueueTask on MessengerQueueTask {
    id
    mailingId
    operation
    state
    itemsToProcess
    itemsProcessed
    itemsInvalid
    createdAt
    updatedAt
  }
`;
export const MessengerMailingFragmentDoc = gql`
  fragment messengerMailing on MessengerMailing {
    id
    clientId
    name
    sendAfter
    sender
    channel
    preview
    transportId
    image
    stats {
      ...messengerQueueNotificationStat
    }
    currentTask {
      ...messengerQueueTask
    }
    state
    subject
    richText
    metaDesign
    text
    templateId
    createdAt
    updatedAt
  }
  ${MessengerQueueNotificationStatFragmentDoc}
  ${MessengerQueueTaskFragmentDoc}
`;
export const MessengerMailingConnectionFragmentDoc = gql`
  fragment messengerMailingConnection on MessengerMailingConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...messengerMailing
    }
  }
  ${MessengerMailingFragmentDoc}
`;
export const MessengerQueueNotificationFragmentDoc = gql`
  fragment messengerQueueNotification on MessengerQueueNotification {
    id
    clientId
    mailingId
    userId
    state
    channel
    willSendAt
    sender
    recipient
    payload
    envelope
    createdAt
    updatedAt
    mailing {
      ...messengerMailing
    }
  }
  ${MessengerMailingFragmentDoc}
`;
export const MessengerQueueNotificationConnectionFragmentDoc = gql`
  fragment messengerQueueNotificationConnection on MessengerQueueNotificationConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...messengerQueueNotification
    }
  }
  ${MessengerQueueNotificationFragmentDoc}
`;
export const MessengerQueueTaskConnectionFragmentDoc = gql`
  fragment messengerQueueTaskConnection on MessengerQueueTaskConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...messengerQueueTask
    }
  }
  ${MessengerQueueTaskFragmentDoc}
`;
export const MessengerSettingFragmentDoc = gql`
  fragment messengerSetting on MessengerSetting {
    id
    clientId
    testers
    defaultSender
    createdAt
    updatedAt
    emailDefault
    defaultSubscription
    smsDefault
    pushDefault
    emailSenders
    smsSenders
    pushSenders
  }
`;
export const MessengerTemplateFragmentDoc = gql`
  fragment messengerTemplate on MessengerTemplate {
    id
    clientId
    state
    channel
    sender
    name
    title
    richText
    preview
    text
    createdAt
    updatedAt
  }
`;
export const MessengerTemplateConnectionFragmentDoc = gql`
  fragment messengerTemplateConnection on MessengerTemplateConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...messengerTemplate
    }
  }
  ${MessengerTemplateFragmentDoc}
`;
export const MessengerTransportFragmentDoc = gql`
  fragment messengerTransport on MessengerTransport {
    id
    clientId
    name
    settings
    providerId
    state
    createdAt
    updatedAt
    channel
  }
`;
export const MessengerTransportConnectionFragmentDoc = gql`
  fragment messengerTransportConnection on MessengerTransportConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...messengerTransport
    }
  }
  ${MessengerTransportFragmentDoc}
`;
export const MobileTranslationFragmentDoc = gql`
  fragment mobileTranslation on MobileTranslation {
    id
    locale
    key
    value
    updatedAt
  }
`;
export const NavigationFragmentDoc = gql`
  fragment navigation on Navigation {
    id
    parentId
    name
    url
    icon
    sort
    children {
      id
      parentId
      name
      url
      icon
      sort
    }
  }
`;
export const PaymentAccountFlatFragmentDoc = gql`
  fragment paymentAccountFlat on PaymentAccount {
    id
    state
    userId
    currencyId
    user {
      id
      firstName
      lastName
      middleName
      data {
        id
        value
        key
      }
    }
    balance
    spent
    providerId
    providerCode
    createdAt
    updatedAt
  }
`;
export const PaymentCurrencyFragmentDoc = gql`
  fragment paymentCurrency on PaymentCurrency {
    id
    clientId
    iso3
    name
    sign
    isDefault
    isVirtual
    createdAt
    updatedAt
  }
`;
export const PaymentProviderFragmentDoc = gql`
  fragment paymentProvider on PaymentProvider {
    id
    gatewayId
    clientId
    state
    name
    settings
    createdAt
    updatedAt
  }
`;
export const PaymentAccountFragmentDoc = gql`
  fragment paymentAccount on PaymentAccount {
    id
    state
    userId
    user {
      ...userFlat
    }
    currencyId
    currency {
      ...paymentCurrency
    }
    provider {
      ...paymentProvider
    }
    balance
    spent
    providerId
    providerCode
    createdAt
    updatedAt
  }
  ${UserFlatFragmentDoc}
  ${PaymentCurrencyFragmentDoc}
  ${PaymentProviderFragmentDoc}
`;
export const PaymentAccountConnectionFragmentDoc = gql`
  fragment paymentAccountConnection on PaymentAccountConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...paymentAccount
    }
  }
  ${PaymentAccountFragmentDoc}
`;
export const PaymentChannelFragmentDoc = gql`
  fragment paymentChannel on PaymentChannel {
    id
    clientId
    state
    name
    description
    createdAt
    updatedAt
  }
`;
export const PaymentCurrencyConnectionFragmentDoc = gql`
  fragment paymentCurrencyConnection on PaymentCurrencyConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...paymentCurrency
    }
  }
  ${PaymentCurrencyFragmentDoc}
`;
export const PaymentGatewayFragmentDoc = gql`
  fragment paymentGateway on PaymentGateway {
    id
    state
    clientId
    name
    description
    createdAt
    updatedAt
  }
`;
export const PaymentGatewayConnectionFragmentDoc = gql`
  fragment paymentGatewayConnection on PaymentGatewayConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...paymentGateway
    }
  }
  ${PaymentGatewayFragmentDoc}
`;
export const PaymentProviderConnectionFragmentDoc = gql`
  fragment paymentProviderConnection on PaymentProviderConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...paymentProvider
    }
  }
  ${PaymentProviderFragmentDoc}
`;
export const RefundPaymentTransactionFragmentDoc = gql`
  fragment refundPaymentTransaction on PaymentTransaction {
    id
    state
    side
    userId
    accountId
    currencyId
    providerId
    amount
    bankCardId
    orderId
    orderType
    createdAt
    updatedAt
  }
`;
export const PaymentTransactionFragmentDoc = gql`
  fragment paymentTransaction on PaymentTransaction {
    id
    state
    side
    userId
    user {
      id
      firstName
      middleName
      lastName
    }
    provider {
      id
      name
    }
    currency {
      id
      name
    }
    accountId
    currencyId
    providerId
    comment
    amount
    bankCardId
    orderId
    orderType
    meta
    createdAt
    updatedAt
  }
`;
export const PaymentTransactionConnectionFragmentDoc = gql`
  fragment paymentTransactionConnection on PaymentTransactionConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...paymentTransaction
    }
  }
  ${PaymentTransactionFragmentDoc}
`;
export const TournamentSeasonFullFragmentDoc = gql`
  fragment tournamentSeasonFull on TournamentSeason {
    id
    title
    description
    tournamentId
    startDate
    finishDate
    state
    sort
    teams {
      ...tournamentTeam
    }
    createdAt
    updatedAt
  }
  ${TournamentTeamFragmentDoc}
`;
export const SystemAsyncTaskFragmentDoc = gql`
  fragment systemAsyncTask on SystemAsyncTask {
    id
    entity
    entityId
    operation
    state
    progress
    createdAt
    updatedAt
  }
`;
export const SystemAsyncTaskConnectionFragmentDoc = gql`
  fragment systemAsyncTaskConnection on SystemAsyncTaskConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...systemAsyncTask
    }
  }
  ${SystemAsyncTaskFragmentDoc}
`;
export const SystemLocaleConnectionFragmentDoc = gql`
  fragment systemLocaleConnection on SystemLocaleConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...systemLocale
    }
  }
  ${SystemLocaleFragmentDoc}
`;
export const SystemLoginFragmentDoc = gql`
  fragment systemLogin on SystemLogin {
    currentUser {
      ...systemUser
    }
    accessToken
    refreshToken
    clients {
      ...client
    }
    clientId
  }
  ${SystemUserFragmentDoc}
  ${ClientFragmentDoc}
`;
export const SystemPrinterFragmentDoc = gql`
  fragment systemPrinter on SystemPrinter {
    id
    clientId
    name
    title
    model
    createdAt
    updatedAt
  }
`;
export const SystemPrinterConnectionFragmentDoc = gql`
  fragment systemPrinterConnection on SystemPrinterConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...systemPrinter
    }
  }
  ${SystemPrinterFragmentDoc}
`;
export const SystemTaskFragmentDoc = gql`
  fragment systemTask on SystemTask {
    id
    name
    state
    errors
    payload
    settings
    createdAt
    updatedAt
  }
`;
export const SystemTranslationFragmentDoc = gql`
  fragment systemTranslation on SystemTranslation {
    id
    locale
    key
    value
    updatedAt
  }
`;
export const SystemTranslationConnectionFragmentDoc = gql`
  fragment systemTranslationConnection on SystemTranslationConnection {
    total
    limit
    cursor
    searchable
    sortable
    edges {
      ...systemTranslation
    }
  }
  ${SystemTranslationFragmentDoc}
`;
export const SystemUsersConnectionFragmentDoc = gql`
  fragment systemUsersConnection on SystemUsersConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...systemUser
    }
  }
  ${SystemUserFragmentDoc}
`;
export const SystemWebHookFragmentDoc = gql`
  fragment systemWebHook on SystemWebHook {
    id
    clientId
    title
    entity
    operation
    url
    headers
    payload
    createdAt
    updatedAt
  }
`;
export const SystemWebHookConnectionFragmentDoc = gql`
  fragment systemWebHookConnection on SystemWebHookConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...systemWebHook
    }
  }
  ${SystemWebHookFragmentDoc}
`;
export const TournamentTeamConnectionFragmentDoc = gql`
  fragment tournamentTeamConnection on TournamentTeamConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...tournamentTeam
    }
  }
  ${TournamentTeamFragmentDoc}
`;
export const TournamentTeamWithSeasonFragmentDoc = gql`
  fragment tournamentTeamWithSeason on TournamentTeam {
    id
    clientId
    name
    logo
    altLogo
    coverPhoto
    website
    description
    homeStadium {
      id
      name
      address
    }
    seasons {
      id
      title
    }
    createdAt
    updatedAt
  }
`;
export const TournamentTeamWithSeasonConnectionFragmentDoc = gql`
  fragment tournamentTeamWithSeasonConnection on TournamentTeamConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...tournamentTeamWithSeason
    }
  }
  ${TournamentTeamWithSeasonFragmentDoc}
`;
export const TemplateApplePassFragmentDoc = gql`
  fragment templateApplePass on TemplateApplePass {
    id
    clientId
    preview
    name
    pass
    files
    certificates
    createdAt
    updatedAt
  }
`;
export const TemplateApplePassConnectionFragmentDoc = gql`
  fragment templateApplePassConnection on TemplateApplePassConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...templateApplePass
    }
  }
  ${TemplateApplePassFragmentDoc}
`;
export const TemplatePdfFragmentDoc = gql`
  fragment templatePDF on TemplatePDF {
    id
    clientId
    name
    preview
    body
    createdAt
    updatedAt
  }
`;
export const TemplatePdfConnectionFragmentDoc = gql`
  fragment templatePDFConnection on TemplatePDFConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...templatePDF
    }
  }
  ${TemplatePdfFragmentDoc}
`;
export const TournamentSeasonTitleFragmentDoc = gql`
  fragment tournamentSeasonTitle on TournamentSeason {
    id
    title
  }
`;
export const TournamentFullFragmentDoc = gql`
  fragment tournamentFull on Tournament {
    id
    clientId
    title
    description
    website
    state
    preview
    sort
    seasons {
      ...tournamentSeasonTitle
    }
    createdAt
    updatedAt
  }
  ${TournamentSeasonTitleFragmentDoc}
`;
export const TournamentConnectionFragmentDoc = gql`
  fragment tournamentConnection on TournamentConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...tournamentFull
    }
  }
  ${TournamentFullFragmentDoc}
`;
export const TournamentDisputeFlatFragmentDoc = gql`
  fragment tournamentDisputeFlat on TournamentDispute {
    id
    state
    result
    confirmation
    userId
    matchId
    amount
    winAmount
    text
    createdAt
    updatedAt
  }
`;
export const TournamentDisputeFragmentDoc = gql`
  fragment tournamentDispute on TournamentDispute {
    ...tournamentDisputeFlat
    user {
      ...userFlat
    }
    match {
      ...tournamentMatch
    }
  }
  ${TournamentDisputeFlatFragmentDoc}
  ${UserFlatFragmentDoc}
  ${TournamentMatchFragmentDoc}
`;
export const TournamentDisqualificationFragmentDoc = gql`
  fragment tournamentDisqualification on TournamentDisqualification {
    id
    playerId
    reason
    tournamentId
    resolutionDate
    disqualificationValue
    leftMatches
    state
    createdAt
    updatedAt
  }
`;
export const TournamentTeamMemberFullFragmentDoc = gql`
  fragment tournamentTeamMemberFull on TournamentTeamMember {
    id
    type
    teamId
    firstName
    lastName
    middleName
    birthday
    weight
    height
    number
    role
    bio
    photo
    data {
      ...extensionData
    }
    team {
      id
      name
      logo
      website
    }
    createdAt
    updatedAt
  }
  ${ExtensionDataFragmentDoc}
`;
export const TournamentFragmentDoc = gql`
  fragment tournament on Tournament {
    id
    clientId
    title
    description
    website
    state
    preview
    sort
    createdAt
    updatedAt
  }
`;
export const TournamentDisqualificationFullFragmentDoc = gql`
  fragment tournamentDisqualificationFull on TournamentDisqualification {
    id
    playerId
    reason
    tournamentId
    resolutionDate
    disqualificationValue
    leftMatches
    state
    player {
      ...tournamentTeamMemberFull
    }
    tournament {
      ...tournament
    }
    createdAt
    updatedAt
  }
  ${TournamentTeamMemberFullFragmentDoc}
  ${TournamentFragmentDoc}
`;
export const TournamentDisqualificationConnectionFragmentDoc = gql`
  fragment tournamentDisqualificationConnection on TournamentDisqualificationConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...tournamentDisqualificationFull
    }
  }
  ${TournamentDisqualificationFullFragmentDoc}
`;
export const TournamentMatchDetailsFragmentDoc = gql`
  fragment tournamentMatchDetails on TournamentMatch {
    ...tournamentMatch
    team1 {
      ...tournamentTeam
    }
    team2 {
      ...tournamentTeam
    }
  }
  ${TournamentMatchFragmentDoc}
  ${TournamentTeamFragmentDoc}
`;
export const TournamentMatchConnectionFragmentDoc = gql`
  fragment tournamentMatchConnection on TournamentMatchConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...tournamentMatch
    }
  }
  ${TournamentMatchFragmentDoc}
`;
export const TournamentTeamMemberSmallFragmentDoc = gql`
  fragment tournamentTeamMemberSmall on TournamentTeamMember {
    id
    teamId
    firstName
    lastName
    middleName
    number
    role
    photo
    createdAt
    updatedAt
  }
`;
export const TournamentMatchEventFragmentDoc = gql`
  fragment tournamentMatchEvent on TournamentMatchEvent {
    id
    matchId
    datetime
    minute
    event
    teamMemberId
    teamId
    comment
    isProtocol
    linkedMatchEventId
    player {
      ...tournamentTeamMemberSmall
    }
    team {
      ...tournamentTeamSmall
    }
    createdAt
    updatedAt
  }
  ${TournamentTeamMemberSmallFragmentDoc}
  ${TournamentTeamSmallFragmentDoc}
`;
export const TournamentMatchEventConnectionFragmentDoc = gql`
  fragment tournamentMatchEventConnection on TournamentMatchEventConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...tournamentMatchEvent
    }
  }
  ${TournamentMatchEventFragmentDoc}
`;
export const TournamentRefereeFragmentDoc = gql`
  fragment tournamentReferee on TournamentReferee {
    id
    firstName
    lastName
    middleName
    birthday
    preview
    photo
    category
    createdAt
    updatedAt
  }
`;
export const TournamentMatchLineUpFragmentDoc = gql`
  fragment tournamentMatchLineUp on TournamentMatchLineUp {
    id
    matchId
    teamId
    teamMemberId
    refereeId
    type
    initialRole
    finalRole
    initialState
    finalState
    number
    isTeamCaptain
    createdAt
    updatedAt
    referee {
      ...tournamentReferee
    }
    team {
      id
      name
    }
    teamMember {
      ...tournamentTeamMemberSmall
    }
  }
  ${TournamentRefereeFragmentDoc}
  ${TournamentTeamMemberSmallFragmentDoc}
`;
export const TournamentPlayerStatFullFragmentDoc = gql`
  fragment tournamentPlayerStatFull on TournamentPlayerStat {
    id
    playerId
    seasonId
    games
    goals
    penaltyKicks
    passes
    yellowCards
    redCards
    missedGoals
    shutouts
    bombardierValue
    assistantValue
    goalsAndPassesValue
    goalkeeperValue
    warningValue
    player {
      ...tournamentTeamMember
    }
    createdAt
    updatedAt
  }
  ${TournamentTeamMemberFragmentDoc}
`;
export const TournamentPlayerStatFragmentDoc = gql`
  fragment tournamentPlayerStat on TournamentPlayerStat {
    id
    playerId
    seasonId
    games
    goals
    penaltyKicks
    passes
    yellowCards
    redCards
    missedGoals
    shutouts
    bombardierValue
    assistantValue
    goalsAndPassesValue
    goalkeeperValue
    warningValue
    createdAt
    updatedAt
  }
`;
export const TournamentPlayerStatConnectionFragmentDoc = gql`
  fragment tournamentPlayerStatConnection on TournamentPlayerStatConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...tournamentPlayerStat
    }
  }
  ${TournamentPlayerStatFragmentDoc}
`;
export const TournamentRefereeConnectionFragmentDoc = gql`
  fragment tournamentRefereeConnection on TournamentRefereeConnection {
    total
    limit
    cursor
    searchable
    sortable
    edges {
      ...tournamentReferee
    }
  }
  ${TournamentRefereeFragmentDoc}
`;
export const TournamentSeasonFragmentDoc = gql`
  fragment tournamentSeason on TournamentSeason {
    id
    title
    description
    tournamentId
    startDate
    finishDate
    state
    sort
    createdAt
    updatedAt
  }
`;
export const TournamentSeasonConnectionFragmentDoc = gql`
  fragment tournamentSeasonConnection on TournamentSeasonConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...tournamentSeason
    }
  }
  ${TournamentSeasonFragmentDoc}
`;
export const TournamentSeasonTeamFragmentDoc = gql`
  fragment tournamentSeasonTeam on TournamentSeasonTeam {
    id
    seasonId
    teamId
    createdAt
    updatedAt
  }
`;
export const TournamentSeasonTeamConnectionFragmentDoc = gql`
  fragment tournamentSeasonTeamConnection on TournamentSeasonTeamConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...tournamentSeasonTeam
    }
  }
  ${TournamentSeasonTeamFragmentDoc}
`;
export const TournamentSeasonTeamStatFragmentDoc = gql`
  fragment tournamentSeasonTeamStat on TournamentSeasonTeamStat {
    id
    seasonId
    position
    teamId
    points
    games
    win
    draw
    loss
    goals
    missedGoals
    yellowCards
    redCards
    shutouts
    sort
    createdAt
    updatedAt
  }
`;
export const TournamentSeasonTournamentFragmentDoc = gql`
  fragment tournamentSeasonTournament on TournamentSeason {
    id
    title
    description
    tournamentId
    startDate
    finishDate
    state
    sort
    createdAt
    updatedAt
    tournament {
      ...tournament
    }
  }
  ${TournamentFragmentDoc}
`;
export const TournamentSeasonTeamStatExtendedFragmentDoc = gql`
  fragment tournamentSeasonTeamStatExtended on TournamentSeasonTeamStat {
    id
    seasonId
    position
    teamId
    points
    games
    win
    draw
    loss
    goals
    missedGoals
    yellowCards
    redCards
    shutouts
    sort
    createdAt
    updatedAt
    season {
      ...tournamentSeasonTournament
    }
  }
  ${TournamentSeasonTournamentFragmentDoc}
`;
export const TournamentSeasonTeamStatConnectionFragmentDoc = gql`
  fragment tournamentSeasonTeamStatConnection on TournamentSeasonTeamStatConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...tournamentSeasonTeamStatExtended
    }
  }
  ${TournamentSeasonTeamStatExtendedFragmentDoc}
`;
export const TournamentStadiumFragmentDoc = gql`
  fragment tournamentStadium on TournamentStadium {
    id
    teamId
    name
    photo
    address
    lat
    lon
    builtIn
    capacity
    team {
      id
      name
    }
    createdAt
    updatedAt
  }
`;
export const TeamHistoryFragmentDoc = gql`
  fragment teamHistory on TournamentTeamHistory {
    id
    field
    value
    translationId
    createdAt
    updatedAt
  }
`;
export const SeasonTeamStatsFragmentDoc = gql`
  fragment seasonTeamStats on TournamentSeasonTeamStat {
    id
    position
    points
    games
    win
    loss
    draw
    yellowCards
    redCards
    goals
    shutouts
    missedGoals
  }
`;
export const TournamentTeamDetailsFragmentDoc = gql`
  fragment tournamentTeamDetails on TournamentTeam {
    ...tournamentTeam
    teamMembers {
      ...tournamentTeamMember
    }
    teamHistories {
      ...teamHistory
    }
    teamStats {
      ...seasonTeamStats
    }
  }
  ${TournamentTeamFragmentDoc}
  ${TournamentTeamMemberFragmentDoc}
  ${TeamHistoryFragmentDoc}
  ${SeasonTeamStatsFragmentDoc}
`;
export const TournamentTeamMemberConnectionFragmentDoc = gql`
  fragment tournamentTeamMemberConnection on TournamentTeamMemberConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...tournamentTeamMember
    }
  }
  ${TournamentTeamMemberFragmentDoc}
`;
export const TournamentTourFragmentDoc = gql`
  fragment tournamentTour on TournamentTour {
    id
    title
    description
    tournamentId
    seasonId
    number
    startDate
    finishDate
    state
    sort
    createdAt
    updatedAt
  }
`;
export const TournamentTourConnectionFragmentDoc = gql`
  fragment tournamentTourConnection on TournamentTourConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...tournamentTour
    }
  }
  ${TournamentTourFragmentDoc}
`;
export const TournamentTourDeepFragmentDoc = gql`
  fragment tournamentTourDeep on TournamentTour {
    ...tournamentTour
    matches {
      ...tournamentMatch
    }
  }
  ${TournamentTourFragmentDoc}
  ${TournamentMatchFragmentDoc}
`;
export const TournamentTourConnectionDeepFragmentDoc = gql`
  fragment tournamentTourConnectionDeep on TournamentTourConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...tournamentTourDeep
    }
  }
  ${TournamentTourDeepFragmentDoc}
`;
export const UserSimpleFragmentDoc = gql`
  fragment userSimple on User {
    id
    firstName
    lastName
    middleName
  }
`;
export const SystemUserLightFragmentDoc = gql`
  fragment systemUserLight on SystemUser {
    id
    firstName
    lastName
    middleName
    photo
  }
`;
export const UserApplicationTypeFragmentDoc = gql`
  fragment userApplicationType on UserApplicationType {
    id
    clientId
    name
    description
    createdAt
    updatedAt
  }
`;
export const UserApplicationFragmentDoc = gql`
  fragment userApplication on UserApplication {
    id
    clientId
    userId
    typeId
    payload
    assigneeId
    state
    user {
      ...userSimple
    }
    assignee {
      ...systemUserLight
    }
    comment
    data {
      ...extensionData
    }
    type {
      ...userApplicationType
    }
    createdAt
    updatedAt
  }
  ${UserSimpleFragmentDoc}
  ${SystemUserLightFragmentDoc}
  ${ExtensionDataFragmentDoc}
  ${UserApplicationTypeFragmentDoc}
`;
export const UserApplicationLogFragmentDoc = gql`
  fragment userApplicationLog on UserApplicationLog {
    id
    applicationId
    userId
    systemUserId
    type
    data
    comment
    user {
      ...userSimple
    }
    systemUser {
      ...systemUserLight
    }
    createdAt
  }
  ${UserSimpleFragmentDoc}
  ${SystemUserLightFragmentDoc}
`;
export const UserApplicationStatsFragmentDoc = gql`
  fragment userApplicationStats on UserApplicationStats {
    type {
      id
      name
    }
    accepted
    declined
    new
    deleted
  }
`;
export const UserDeviceFragmentDoc = gql`
  fragment userDevice on UserDevice {
    userId
    installId
    bundleId
    brand
    deviceLocale
    deviceName
    deviceModel
    installReferrer
    manufacturer
    systemName
    systemVersion
    appVersion
    userAgent
    pushToken
    expoPushToken
    createdAt
    updatedAt
  }
`;
export const UserGroupFragmentDoc = gql`
  fragment userGroup on UserGroup {
    id
    clientId
    name
    usersCount
    sort
    createdAt
    updatedAt
  }
`;
export const UserFragmentDoc = gql`
  fragment user on User {
    ...userFlat
    devices {
      ...userDevice
    }
    analytics {
      ...extensionData
    }
    data {
      ...extensionData
    }
    groups {
      ...userGroup
    }
  }
  ${UserFlatFragmentDoc}
  ${UserDeviceFragmentDoc}
  ${ExtensionDataFragmentDoc}
  ${UserGroupFragmentDoc}
`;
export const UserConnectionFragmentDoc = gql`
  fragment userConnection on UserConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...user
    }
  }
  ${UserFragmentDoc}
`;
export const UserDataFragmentDoc = gql`
  fragment userData on UserData {
    key
    title
    value
  }
`;
export const UserDocumentFragmentDoc = gql`
  fragment userDocument on UserDocument {
    id
    userId
    kidId
    type
    data
    state
    createdAt
    updatedAt
  }
`;
export const UserDocumentConnectionFragmentDoc = gql`
  fragment userDocumentConnection on UserDocumentConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...userDocument
    }
  }
  ${UserDocumentFragmentDoc}
`;
export const UserFieldsConnectionFragmentDoc = gql`
  fragment userFieldsConnection on UserFieldsConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...extensionField
    }
  }
  ${ExtensionFieldFragmentDoc}
`;
export const UserGroupConnectionFragmentDoc = gql`
  fragment userGroupConnection on UserGroupConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...userGroup
    }
  }
  ${UserGroupFragmentDoc}
`;
export const UserKidFragmentDoc = gql`
  fragment userKid on UserKid {
    id
    clientId
    userId
    state
    firstName
    lastName
    middleName
    birthday
    gender
    seasonTicket
    height
    user {
      id
      firstName
      lastName
      middleName
    }
    updatedAt
    createdAt
  }
`;
export const UserKidConnectionFragmentDoc = gql`
  fragment userKidConnection on UserKidConnection {
    total
    limit
    cursor
    searchable
    sortable
    edges {
      ...userKid
    }
  }
  ${UserKidFragmentDoc}
`;
export const UserSettingFragmentDoc = gql`
  fragment userSetting on UserSetting {
    id
    pushTemplate
    emailTemplate
    pushTransport
    emailTransport
    emailSender
  }
`;
export const UserSocialAccountFragmentDoc = gql`
  fragment userSocialAccount on UserSocialAccount {
    id
    clientId
    userId
    state
    socialNetwork
    socialNetworkId
    payload
    expiresAt
    createdAt
    updatedAt
  }
`;
export const UserSubscriberFragmentDoc = gql`
  fragment userSubscriber on UserSubscriber {
    id
    clientId
    userId
    subscriptionId
    modifierId
    user {
      ...userFlat
    }
    createdAt
    updatedAt
  }
  ${UserFlatFragmentDoc}
`;
export const UserSubscriberConnectionFragmentDoc = gql`
  fragment userSubscriberConnection on UserSubscriberConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...userSubscriber
    }
  }
  ${UserSubscriberFragmentDoc}
`;
export const UserSubscribersLogFragmentDoc = gql`
  fragment userSubscribersLog on UserSubscribersLog {
    id
    userId
    subscriptionId
    state
    reason
    systemUserId
    createdAt
    updatedAt
  }
`;
export const UserSubscribersLogConnectionFragmentDoc = gql`
  fragment userSubscribersLogConnection on UserSubscribersLogConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...userSubscribersLog
    }
  }
  ${UserSubscribersLogFragmentDoc}
`;
export const UserSubscriptionFragmentDoc = gql`
  fragment userSubscription on UserSubscription {
    id
    clientId
    state
    name
    description
    subscribersCount
    isSubscriber
    access
    createdAt
    updatedAt
  }
`;
export const UserSubscriptionConnectionFragmentDoc = gql`
  fragment userSubscriptionConnection on UserSubscriptionConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...userSubscription
    }
  }
  ${UserSubscriptionFragmentDoc}
`;
export const WidgetSummaryGraphDatasetDataFragmentDoc = gql`
  fragment widgetSummaryGraphDatasetData on WidgetSummaryGraphDatasetData {
    x
    y
  }
`;
export const WidgetSummaryGraphDatasetFragmentDoc = gql`
  fragment widgetSummaryGraphDataset on WidgetSummaryGraphDataset {
    label
    data {
      ...widgetSummaryGraphDatasetData
    }
  }
  ${WidgetSummaryGraphDatasetDataFragmentDoc}
`;
export const WidgetSummaryGraphFragmentDoc = gql`
  fragment widgetSummaryGraph on WidgetSummaryGraph {
    name
    dataset {
      ...widgetSummaryGraphDataset
    }
  }
  ${WidgetSummaryGraphDatasetFragmentDoc}
`;
export const AddToMessengerQueueDocument = gql`
  mutation addToMessengerQueue($mailingId: Int!, $userId: [Int!], $query: DefaultQueryInput) {
    addToMessengerQueue(mailingId: $mailingId, userId: $userId, query: $query) {
      ...messengerQueueTask
    }
  }
  ${MessengerQueueTaskFragmentDoc}
`;
export type AddToMessengerQueueMutationFn = ApolloReactCommon.MutationFunction<
  AddToMessengerQueueMutation,
  AddToMessengerQueueMutationVariables
>;
export type AddToMessengerQueueComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<AddToMessengerQueueMutation, AddToMessengerQueueMutationVariables>,
  'mutation'
>;

export const AddToMessengerQueueComponent = (props: AddToMessengerQueueComponentProps) => (
  <ApolloReactComponents.Mutation<AddToMessengerQueueMutation, AddToMessengerQueueMutationVariables>
    mutation={AddToMessengerQueueDocument}
    {...props}
  />
);

export type AddToMessengerQueueProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  AddToMessengerQueueMutation,
  AddToMessengerQueueMutationVariables
> &
  TChildProps;
export function withAddToMessengerQueue<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AddToMessengerQueueMutation,
    AddToMessengerQueueMutationVariables,
    AddToMessengerQueueProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AddToMessengerQueueMutation,
    AddToMessengerQueueMutationVariables,
    AddToMessengerQueueProps<TChildProps>
  >(AddToMessengerQueueDocument, {
    alias: 'addToMessengerQueue',
    ...operationOptions,
  });
}

/**
 * __useAddToMessengerQueueMutation__
 *
 * To run a mutation, you first call `useAddToMessengerQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToMessengerQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToMessengerQueueMutation, { data, loading, error }] = useAddToMessengerQueueMutation({
 *   variables: {
 *      mailingId: // value for 'mailingId'
 *      userId: // value for 'userId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAddToMessengerQueueMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddToMessengerQueueMutation, AddToMessengerQueueMutationVariables>
) {
  return ApolloReactHooks.useMutation<AddToMessengerQueueMutation, AddToMessengerQueueMutationVariables>(
    AddToMessengerQueueDocument,
    baseOptions
  );
}
export type AddToMessengerQueueMutationHookResult = ReturnType<typeof useAddToMessengerQueueMutation>;
export type AddToMessengerQueueMutationResult = ApolloReactCommon.MutationResult<AddToMessengerQueueMutation>;
export type AddToMessengerQueueMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddToMessengerQueueMutation,
  AddToMessengerQueueMutationVariables
>;
export const AddToUsersSubscriptionDocument = gql`
  mutation addToUsersSubscription($subscriptionId: Int!, $query: DefaultQueryInput) {
    addToUsersSubscription(subscriptionId: $subscriptionId, query: $query)
  }
`;
export type AddToUsersSubscriptionMutationFn = ApolloReactCommon.MutationFunction<
  AddToUsersSubscriptionMutation,
  AddToUsersSubscriptionMutationVariables
>;
export type AddToUsersSubscriptionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddToUsersSubscriptionMutation,
    AddToUsersSubscriptionMutationVariables
  >,
  'mutation'
>;

export const AddToUsersSubscriptionComponent = (props: AddToUsersSubscriptionComponentProps) => (
  <ApolloReactComponents.Mutation<AddToUsersSubscriptionMutation, AddToUsersSubscriptionMutationVariables>
    mutation={AddToUsersSubscriptionDocument}
    {...props}
  />
);

export type AddToUsersSubscriptionProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  AddToUsersSubscriptionMutation,
  AddToUsersSubscriptionMutationVariables
> &
  TChildProps;
export function withAddToUsersSubscription<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AddToUsersSubscriptionMutation,
    AddToUsersSubscriptionMutationVariables,
    AddToUsersSubscriptionProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AddToUsersSubscriptionMutation,
    AddToUsersSubscriptionMutationVariables,
    AddToUsersSubscriptionProps<TChildProps>
  >(AddToUsersSubscriptionDocument, {
    alias: 'addToUsersSubscription',
    ...operationOptions,
  });
}

/**
 * __useAddToUsersSubscriptionMutation__
 *
 * To run a mutation, you first call `useAddToUsersSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToUsersSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToUsersSubscriptionMutation, { data, loading, error }] = useAddToUsersSubscriptionMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAddToUsersSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddToUsersSubscriptionMutation,
    AddToUsersSubscriptionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<AddToUsersSubscriptionMutation, AddToUsersSubscriptionMutationVariables>(
    AddToUsersSubscriptionDocument,
    baseOptions
  );
}
export type AddToUsersSubscriptionMutationHookResult = ReturnType<typeof useAddToUsersSubscriptionMutation>;
export type AddToUsersSubscriptionMutationResult = ApolloReactCommon.MutationResult<AddToUsersSubscriptionMutation>;
export type AddToUsersSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddToUsersSubscriptionMutation,
  AddToUsersSubscriptionMutationVariables
>;
export const AddUsersToGroupDocument = gql`
  mutation addUsersToGroup($groupId: [Int!]!, $query: DefaultQueryInput!) {
    addUsersToGroup(groupId: $groupId, query: $query) {
      ...systemAsyncTask
    }
  }
  ${SystemAsyncTaskFragmentDoc}
`;
export type AddUsersToGroupMutationFn = ApolloReactCommon.MutationFunction<
  AddUsersToGroupMutation,
  AddUsersToGroupMutationVariables
>;
export type AddUsersToGroupComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<AddUsersToGroupMutation, AddUsersToGroupMutationVariables>,
  'mutation'
>;

export const AddUsersToGroupComponent = (props: AddUsersToGroupComponentProps) => (
  <ApolloReactComponents.Mutation<AddUsersToGroupMutation, AddUsersToGroupMutationVariables>
    mutation={AddUsersToGroupDocument}
    {...props}
  />
);

export type AddUsersToGroupProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  AddUsersToGroupMutation,
  AddUsersToGroupMutationVariables
> &
  TChildProps;
export function withAddUsersToGroup<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AddUsersToGroupMutation,
    AddUsersToGroupMutationVariables,
    AddUsersToGroupProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AddUsersToGroupMutation,
    AddUsersToGroupMutationVariables,
    AddUsersToGroupProps<TChildProps>
  >(AddUsersToGroupDocument, {
    alias: 'addUsersToGroup',
    ...operationOptions,
  });
}

/**
 * __useAddUsersToGroupMutation__
 *
 * To run a mutation, you first call `useAddUsersToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUsersToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUsersToGroupMutation, { data, loading, error }] = useAddUsersToGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAddUsersToGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddUsersToGroupMutation, AddUsersToGroupMutationVariables>
) {
  return ApolloReactHooks.useMutation<AddUsersToGroupMutation, AddUsersToGroupMutationVariables>(
    AddUsersToGroupDocument,
    baseOptions
  );
}
export type AddUsersToGroupMutationHookResult = ReturnType<typeof useAddUsersToGroupMutation>;
export type AddUsersToGroupMutationResult = ApolloReactCommon.MutationResult<AddUsersToGroupMutation>;
export type AddUsersToGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddUsersToGroupMutation,
  AddUsersToGroupMutationVariables
>;
export const AddUsersToGroupFromTicketsDocument = gql`
  mutation addUsersToGroupFromTickets($groupId: [Int!]!, $query: DefaultQueryInput!) {
    addUsersToGroupFromTickets(groupId: $groupId, query: $query) {
      ...systemAsyncTask
    }
  }
  ${SystemAsyncTaskFragmentDoc}
`;
export type AddUsersToGroupFromTicketsMutationFn = ApolloReactCommon.MutationFunction<
  AddUsersToGroupFromTicketsMutation,
  AddUsersToGroupFromTicketsMutationVariables
>;
export type AddUsersToGroupFromTicketsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddUsersToGroupFromTicketsMutation,
    AddUsersToGroupFromTicketsMutationVariables
  >,
  'mutation'
>;

export const AddUsersToGroupFromTicketsComponent = (props: AddUsersToGroupFromTicketsComponentProps) => (
  <ApolloReactComponents.Mutation<AddUsersToGroupFromTicketsMutation, AddUsersToGroupFromTicketsMutationVariables>
    mutation={AddUsersToGroupFromTicketsDocument}
    {...props}
  />
);

export type AddUsersToGroupFromTicketsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  AddUsersToGroupFromTicketsMutation,
  AddUsersToGroupFromTicketsMutationVariables
> &
  TChildProps;
export function withAddUsersToGroupFromTickets<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AddUsersToGroupFromTicketsMutation,
    AddUsersToGroupFromTicketsMutationVariables,
    AddUsersToGroupFromTicketsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AddUsersToGroupFromTicketsMutation,
    AddUsersToGroupFromTicketsMutationVariables,
    AddUsersToGroupFromTicketsProps<TChildProps>
  >(AddUsersToGroupFromTicketsDocument, {
    alias: 'addUsersToGroupFromTickets',
    ...operationOptions,
  });
}

/**
 * __useAddUsersToGroupFromTicketsMutation__
 *
 * To run a mutation, you first call `useAddUsersToGroupFromTicketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUsersToGroupFromTicketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUsersToGroupFromTicketsMutation, { data, loading, error }] = useAddUsersToGroupFromTicketsMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAddUsersToGroupFromTicketsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddUsersToGroupFromTicketsMutation,
    AddUsersToGroupFromTicketsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<AddUsersToGroupFromTicketsMutation, AddUsersToGroupFromTicketsMutationVariables>(
    AddUsersToGroupFromTicketsDocument,
    baseOptions
  );
}
export type AddUsersToGroupFromTicketsMutationHookResult = ReturnType<typeof useAddUsersToGroupFromTicketsMutation>;
export type AddUsersToGroupFromTicketsMutationResult = ApolloReactCommon.MutationResult<
  AddUsersToGroupFromTicketsMutation
>;
export type AddUsersToGroupFromTicketsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddUsersToGroupFromTicketsMutation,
  AddUsersToGroupFromTicketsMutationVariables
>;
export const ChargeFromAccountDocument = gql`
  mutation chargeFromAccount($data: PaymentAccountOperationInput!) {
    chargeFromAccount(data: $data) {
      amount
    }
  }
`;
export type ChargeFromAccountMutationFn = ApolloReactCommon.MutationFunction<
  ChargeFromAccountMutation,
  ChargeFromAccountMutationVariables
>;
export type ChargeFromAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<ChargeFromAccountMutation, ChargeFromAccountMutationVariables>,
  'mutation'
>;

export const ChargeFromAccountComponent = (props: ChargeFromAccountComponentProps) => (
  <ApolloReactComponents.Mutation<ChargeFromAccountMutation, ChargeFromAccountMutationVariables>
    mutation={ChargeFromAccountDocument}
    {...props}
  />
);

export type ChargeFromAccountProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  ChargeFromAccountMutation,
  ChargeFromAccountMutationVariables
> &
  TChildProps;
export function withChargeFromAccount<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ChargeFromAccountMutation,
    ChargeFromAccountMutationVariables,
    ChargeFromAccountProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ChargeFromAccountMutation,
    ChargeFromAccountMutationVariables,
    ChargeFromAccountProps<TChildProps>
  >(ChargeFromAccountDocument, {
    alias: 'chargeFromAccount',
    ...operationOptions,
  });
}

/**
 * __useChargeFromAccountMutation__
 *
 * To run a mutation, you first call `useChargeFromAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChargeFromAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chargeFromAccountMutation, { data, loading, error }] = useChargeFromAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChargeFromAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ChargeFromAccountMutation, ChargeFromAccountMutationVariables>
) {
  return ApolloReactHooks.useMutation<ChargeFromAccountMutation, ChargeFromAccountMutationVariables>(
    ChargeFromAccountDocument,
    baseOptions
  );
}
export type ChargeFromAccountMutationHookResult = ReturnType<typeof useChargeFromAccountMutation>;
export type ChargeFromAccountMutationResult = ApolloReactCommon.MutationResult<ChargeFromAccountMutation>;
export type ChargeFromAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ChargeFromAccountMutation,
  ChargeFromAccountMutationVariables
>;
export const CommerceOrderPrintDocument = gql`
  mutation commerceOrderPrint($id: Int!, $printerId: Int!, $templateId: Int!) {
    commerceOrderPrint(id: $id, printerId: $printerId, templateId: $templateId)
  }
`;
export type CommerceOrderPrintMutationFn = ApolloReactCommon.MutationFunction<
  CommerceOrderPrintMutation,
  CommerceOrderPrintMutationVariables
>;
export type CommerceOrderPrintComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CommerceOrderPrintMutation, CommerceOrderPrintMutationVariables>,
  'mutation'
>;

export const CommerceOrderPrintComponent = (props: CommerceOrderPrintComponentProps) => (
  <ApolloReactComponents.Mutation<CommerceOrderPrintMutation, CommerceOrderPrintMutationVariables>
    mutation={CommerceOrderPrintDocument}
    {...props}
  />
);

export type CommerceOrderPrintProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CommerceOrderPrintMutation,
  CommerceOrderPrintMutationVariables
> &
  TChildProps;
export function withCommerceOrderPrint<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CommerceOrderPrintMutation,
    CommerceOrderPrintMutationVariables,
    CommerceOrderPrintProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CommerceOrderPrintMutation,
    CommerceOrderPrintMutationVariables,
    CommerceOrderPrintProps<TChildProps>
  >(CommerceOrderPrintDocument, {
    alias: 'commerceOrderPrint',
    ...operationOptions,
  });
}

/**
 * __useCommerceOrderPrintMutation__
 *
 * To run a mutation, you first call `useCommerceOrderPrintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommerceOrderPrintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commerceOrderPrintMutation, { data, loading, error }] = useCommerceOrderPrintMutation({
 *   variables: {
 *      id: // value for 'id'
 *      printerId: // value for 'printerId'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useCommerceOrderPrintMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CommerceOrderPrintMutation, CommerceOrderPrintMutationVariables>
) {
  return ApolloReactHooks.useMutation<CommerceOrderPrintMutation, CommerceOrderPrintMutationVariables>(
    CommerceOrderPrintDocument,
    baseOptions
  );
}
export type CommerceOrderPrintMutationHookResult = ReturnType<typeof useCommerceOrderPrintMutation>;
export type CommerceOrderPrintMutationResult = ApolloReactCommon.MutationResult<CommerceOrderPrintMutation>;
export type CommerceOrderPrintMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CommerceOrderPrintMutation,
  CommerceOrderPrintMutationVariables
>;
export const ConfirmDisputeDisputeModeratorDocument = gql`
  mutation confirmDisputeDisputeModerator($id: Int!, $result: DisputeResult!) {
    confirmDisputeDisputeModerator(id: $id, result: $result) {
      ...dispute
    }
  }
  ${DisputeFragmentDoc}
`;
export type ConfirmDisputeDisputeModeratorMutationFn = ApolloReactCommon.MutationFunction<
  ConfirmDisputeDisputeModeratorMutation,
  ConfirmDisputeDisputeModeratorMutationVariables
>;
export type ConfirmDisputeDisputeModeratorComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ConfirmDisputeDisputeModeratorMutation,
    ConfirmDisputeDisputeModeratorMutationVariables
  >,
  'mutation'
>;

export const ConfirmDisputeDisputeModeratorComponent = (props: ConfirmDisputeDisputeModeratorComponentProps) => (
  <ApolloReactComponents.Mutation<
    ConfirmDisputeDisputeModeratorMutation,
    ConfirmDisputeDisputeModeratorMutationVariables
  >
    mutation={ConfirmDisputeDisputeModeratorDocument}
    {...props}
  />
);

export type ConfirmDisputeDisputeModeratorProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  ConfirmDisputeDisputeModeratorMutation,
  ConfirmDisputeDisputeModeratorMutationVariables
> &
  TChildProps;
export function withConfirmDisputeDisputeModerator<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ConfirmDisputeDisputeModeratorMutation,
    ConfirmDisputeDisputeModeratorMutationVariables,
    ConfirmDisputeDisputeModeratorProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ConfirmDisputeDisputeModeratorMutation,
    ConfirmDisputeDisputeModeratorMutationVariables,
    ConfirmDisputeDisputeModeratorProps<TChildProps>
  >(ConfirmDisputeDisputeModeratorDocument, {
    alias: 'confirmDisputeDisputeModerator',
    ...operationOptions,
  });
}

/**
 * __useConfirmDisputeDisputeModeratorMutation__
 *
 * To run a mutation, you first call `useConfirmDisputeDisputeModeratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmDisputeDisputeModeratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmDisputeDisputeModeratorMutation, { data, loading, error }] = useConfirmDisputeDisputeModeratorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      result: // value for 'result'
 *   },
 * });
 */
export function useConfirmDisputeDisputeModeratorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConfirmDisputeDisputeModeratorMutation,
    ConfirmDisputeDisputeModeratorMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ConfirmDisputeDisputeModeratorMutation,
    ConfirmDisputeDisputeModeratorMutationVariables
  >(ConfirmDisputeDisputeModeratorDocument, baseOptions);
}
export type ConfirmDisputeDisputeModeratorMutationHookResult = ReturnType<
  typeof useConfirmDisputeDisputeModeratorMutation
>;
export type ConfirmDisputeDisputeModeratorMutationResult = ApolloReactCommon.MutationResult<
  ConfirmDisputeDisputeModeratorMutation
>;
export type ConfirmDisputeDisputeModeratorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConfirmDisputeDisputeModeratorMutation,
  ConfirmDisputeDisputeModeratorMutationVariables
>;
export const CreateArticleDocument = gql`
  mutation createArticle($data: ArticleCreateInput!) {
    createArticle(data: $data) {
      ...article
    }
  }
  ${ArticleFragmentDoc}
`;
export type CreateArticleMutationFn = ApolloReactCommon.MutationFunction<
  CreateArticleMutation,
  CreateArticleMutationVariables
>;
export type CreateArticleComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateArticleMutation, CreateArticleMutationVariables>,
  'mutation'
>;

export const CreateArticleComponent = (props: CreateArticleComponentProps) => (
  <ApolloReactComponents.Mutation<CreateArticleMutation, CreateArticleMutationVariables>
    mutation={CreateArticleDocument}
    {...props}
  />
);

export type CreateArticleProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateArticleMutation,
  CreateArticleMutationVariables
> &
  TChildProps;
export function withCreateArticle<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateArticleMutation,
    CreateArticleMutationVariables,
    CreateArticleProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateArticleMutation,
    CreateArticleMutationVariables,
    CreateArticleProps<TChildProps>
  >(CreateArticleDocument, {
    alias: 'createArticle',
    ...operationOptions,
  });
}

/**
 * __useCreateArticleMutation__
 *
 * To run a mutation, you first call `useCreateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleMutation, { data, loading, error }] = useCreateArticleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateArticleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArticleMutation, CreateArticleMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateArticleMutation, CreateArticleMutationVariables>(
    CreateArticleDocument,
    baseOptions
  );
}
export type CreateArticleMutationHookResult = ReturnType<typeof useCreateArticleMutation>;
export type CreateArticleMutationResult = ApolloReactCommon.MutationResult<CreateArticleMutation>;
export type CreateArticleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateArticleMutation,
  CreateArticleMutationVariables
>;
export const CreateArticleAuthorDocument = gql`
  mutation createArticleAuthor($data: ArticleAuthorCreateInput!) {
    createArticleAuthor(data: $data) {
      ...articleAuthor
    }
  }
  ${ArticleAuthorFragmentDoc}
`;
export type CreateArticleAuthorMutationFn = ApolloReactCommon.MutationFunction<
  CreateArticleAuthorMutation,
  CreateArticleAuthorMutationVariables
>;
export type CreateArticleAuthorComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateArticleAuthorMutation, CreateArticleAuthorMutationVariables>,
  'mutation'
>;

export const CreateArticleAuthorComponent = (props: CreateArticleAuthorComponentProps) => (
  <ApolloReactComponents.Mutation<CreateArticleAuthorMutation, CreateArticleAuthorMutationVariables>
    mutation={CreateArticleAuthorDocument}
    {...props}
  />
);

export type CreateArticleAuthorProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateArticleAuthorMutation,
  CreateArticleAuthorMutationVariables
> &
  TChildProps;
export function withCreateArticleAuthor<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateArticleAuthorMutation,
    CreateArticleAuthorMutationVariables,
    CreateArticleAuthorProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateArticleAuthorMutation,
    CreateArticleAuthorMutationVariables,
    CreateArticleAuthorProps<TChildProps>
  >(CreateArticleAuthorDocument, {
    alias: 'createArticleAuthor',
    ...operationOptions,
  });
}

/**
 * __useCreateArticleAuthorMutation__
 *
 * To run a mutation, you first call `useCreateArticleAuthorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleAuthorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleAuthorMutation, { data, loading, error }] = useCreateArticleAuthorMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateArticleAuthorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArticleAuthorMutation, CreateArticleAuthorMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateArticleAuthorMutation, CreateArticleAuthorMutationVariables>(
    CreateArticleAuthorDocument,
    baseOptions
  );
}
export type CreateArticleAuthorMutationHookResult = ReturnType<typeof useCreateArticleAuthorMutation>;
export type CreateArticleAuthorMutationResult = ApolloReactCommon.MutationResult<CreateArticleAuthorMutation>;
export type CreateArticleAuthorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateArticleAuthorMutation,
  CreateArticleAuthorMutationVariables
>;
export const CreateArticleBlockDocument = gql`
  mutation createArticleBlock($data: ArticleBlockCreateInput!) {
    createArticleBlock(data: $data) {
      ...articleBlock
    }
  }
  ${ArticleBlockFragmentDoc}
`;
export type CreateArticleBlockMutationFn = ApolloReactCommon.MutationFunction<
  CreateArticleBlockMutation,
  CreateArticleBlockMutationVariables
>;
export type CreateArticleBlockComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateArticleBlockMutation, CreateArticleBlockMutationVariables>,
  'mutation'
>;

export const CreateArticleBlockComponent = (props: CreateArticleBlockComponentProps) => (
  <ApolloReactComponents.Mutation<CreateArticleBlockMutation, CreateArticleBlockMutationVariables>
    mutation={CreateArticleBlockDocument}
    {...props}
  />
);

export type CreateArticleBlockProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateArticleBlockMutation,
  CreateArticleBlockMutationVariables
> &
  TChildProps;
export function withCreateArticleBlock<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateArticleBlockMutation,
    CreateArticleBlockMutationVariables,
    CreateArticleBlockProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateArticleBlockMutation,
    CreateArticleBlockMutationVariables,
    CreateArticleBlockProps<TChildProps>
  >(CreateArticleBlockDocument, {
    alias: 'createArticleBlock',
    ...operationOptions,
  });
}

/**
 * __useCreateArticleBlockMutation__
 *
 * To run a mutation, you first call `useCreateArticleBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleBlockMutation, { data, loading, error }] = useCreateArticleBlockMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateArticleBlockMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArticleBlockMutation, CreateArticleBlockMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateArticleBlockMutation, CreateArticleBlockMutationVariables>(
    CreateArticleBlockDocument,
    baseOptions
  );
}
export type CreateArticleBlockMutationHookResult = ReturnType<typeof useCreateArticleBlockMutation>;
export type CreateArticleBlockMutationResult = ApolloReactCommon.MutationResult<CreateArticleBlockMutation>;
export type CreateArticleBlockMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateArticleBlockMutation,
  CreateArticleBlockMutationVariables
>;
export const CreateArticleCategoryDocument = gql`
  mutation createArticleCategory($data: ArticleCategoryCreateInput!) {
    createArticleCategory(data: $data) {
      ...articleCategory
    }
  }
  ${ArticleCategoryFragmentDoc}
`;
export type CreateArticleCategoryMutationFn = ApolloReactCommon.MutationFunction<
  CreateArticleCategoryMutation,
  CreateArticleCategoryMutationVariables
>;
export type CreateArticleCategoryComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateArticleCategoryMutation, CreateArticleCategoryMutationVariables>,
  'mutation'
>;

export const CreateArticleCategoryComponent = (props: CreateArticleCategoryComponentProps) => (
  <ApolloReactComponents.Mutation<CreateArticleCategoryMutation, CreateArticleCategoryMutationVariables>
    mutation={CreateArticleCategoryDocument}
    {...props}
  />
);

export type CreateArticleCategoryProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateArticleCategoryMutation,
  CreateArticleCategoryMutationVariables
> &
  TChildProps;
export function withCreateArticleCategory<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateArticleCategoryMutation,
    CreateArticleCategoryMutationVariables,
    CreateArticleCategoryProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateArticleCategoryMutation,
    CreateArticleCategoryMutationVariables,
    CreateArticleCategoryProps<TChildProps>
  >(CreateArticleCategoryDocument, {
    alias: 'createArticleCategory',
    ...operationOptions,
  });
}

/**
 * __useCreateArticleCategoryMutation__
 *
 * To run a mutation, you first call `useCreateArticleCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleCategoryMutation, { data, loading, error }] = useCreateArticleCategoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateArticleCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateArticleCategoryMutation,
    CreateArticleCategoryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateArticleCategoryMutation, CreateArticleCategoryMutationVariables>(
    CreateArticleCategoryDocument,
    baseOptions
  );
}
export type CreateArticleCategoryMutationHookResult = ReturnType<typeof useCreateArticleCategoryMutation>;
export type CreateArticleCategoryMutationResult = ApolloReactCommon.MutationResult<CreateArticleCategoryMutation>;
export type CreateArticleCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateArticleCategoryMutation,
  CreateArticleCategoryMutationVariables
>;
export const CreateArticleLinkDocument = gql`
  mutation createArticleLink($data: ArticleLinkCreateInput!) {
    createArticleLink(data: $data) {
      ...articleLink
    }
  }
  ${ArticleLinkFragmentDoc}
`;
export type CreateArticleLinkMutationFn = ApolloReactCommon.MutationFunction<
  CreateArticleLinkMutation,
  CreateArticleLinkMutationVariables
>;
export type CreateArticleLinkComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateArticleLinkMutation, CreateArticleLinkMutationVariables>,
  'mutation'
>;

export const CreateArticleLinkComponent = (props: CreateArticleLinkComponentProps) => (
  <ApolloReactComponents.Mutation<CreateArticleLinkMutation, CreateArticleLinkMutationVariables>
    mutation={CreateArticleLinkDocument}
    {...props}
  />
);

export type CreateArticleLinkProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateArticleLinkMutation,
  CreateArticleLinkMutationVariables
> &
  TChildProps;
export function withCreateArticleLink<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateArticleLinkMutation,
    CreateArticleLinkMutationVariables,
    CreateArticleLinkProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateArticleLinkMutation,
    CreateArticleLinkMutationVariables,
    CreateArticleLinkProps<TChildProps>
  >(CreateArticleLinkDocument, {
    alias: 'createArticleLink',
    ...operationOptions,
  });
}

/**
 * __useCreateArticleLinkMutation__
 *
 * To run a mutation, you first call `useCreateArticleLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleLinkMutation, { data, loading, error }] = useCreateArticleLinkMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateArticleLinkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArticleLinkMutation, CreateArticleLinkMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateArticleLinkMutation, CreateArticleLinkMutationVariables>(
    CreateArticleLinkDocument,
    baseOptions
  );
}
export type CreateArticleLinkMutationHookResult = ReturnType<typeof useCreateArticleLinkMutation>;
export type CreateArticleLinkMutationResult = ApolloReactCommon.MutationResult<CreateArticleLinkMutation>;
export type CreateArticleLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateArticleLinkMutation,
  CreateArticleLinkMutationVariables
>;
export const CreateArticleTagDocument = gql`
  mutation createArticleTag($data: ArticleTagCreateInput!) {
    createArticleTag(data: $data) {
      ...articleTag
    }
  }
  ${ArticleTagFragmentDoc}
`;
export type CreateArticleTagMutationFn = ApolloReactCommon.MutationFunction<
  CreateArticleTagMutation,
  CreateArticleTagMutationVariables
>;
export type CreateArticleTagComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateArticleTagMutation, CreateArticleTagMutationVariables>,
  'mutation'
>;

export const CreateArticleTagComponent = (props: CreateArticleTagComponentProps) => (
  <ApolloReactComponents.Mutation<CreateArticleTagMutation, CreateArticleTagMutationVariables>
    mutation={CreateArticleTagDocument}
    {...props}
  />
);

export type CreateArticleTagProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateArticleTagMutation,
  CreateArticleTagMutationVariables
> &
  TChildProps;
export function withCreateArticleTag<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateArticleTagMutation,
    CreateArticleTagMutationVariables,
    CreateArticleTagProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateArticleTagMutation,
    CreateArticleTagMutationVariables,
    CreateArticleTagProps<TChildProps>
  >(CreateArticleTagDocument, {
    alias: 'createArticleTag',
    ...operationOptions,
  });
}

/**
 * __useCreateArticleTagMutation__
 *
 * To run a mutation, you first call `useCreateArticleTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleTagMutation, { data, loading, error }] = useCreateArticleTagMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateArticleTagMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArticleTagMutation, CreateArticleTagMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateArticleTagMutation, CreateArticleTagMutationVariables>(
    CreateArticleTagDocument,
    baseOptions
  );
}
export type CreateArticleTagMutationHookResult = ReturnType<typeof useCreateArticleTagMutation>;
export type CreateArticleTagMutationResult = ApolloReactCommon.MutationResult<CreateArticleTagMutation>;
export type CreateArticleTagMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateArticleTagMutation,
  CreateArticleTagMutationVariables
>;
export const CreateCatalogueDocument = gql`
  mutation createCatalogue($data: CatalogueCreateInput!) {
    createCatalogue(data: $data) {
      ...catalogueFlat
    }
  }
  ${CatalogueFlatFragmentDoc}
`;
export type CreateCatalogueMutationFn = ApolloReactCommon.MutationFunction<
  CreateCatalogueMutation,
  CreateCatalogueMutationVariables
>;
export type CreateCatalogueComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateCatalogueMutation, CreateCatalogueMutationVariables>,
  'mutation'
>;

export const CreateCatalogueComponent = (props: CreateCatalogueComponentProps) => (
  <ApolloReactComponents.Mutation<CreateCatalogueMutation, CreateCatalogueMutationVariables>
    mutation={CreateCatalogueDocument}
    {...props}
  />
);

export type CreateCatalogueProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateCatalogueMutation,
  CreateCatalogueMutationVariables
> &
  TChildProps;
export function withCreateCatalogue<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCatalogueMutation,
    CreateCatalogueMutationVariables,
    CreateCatalogueProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCatalogueMutation,
    CreateCatalogueMutationVariables,
    CreateCatalogueProps<TChildProps>
  >(CreateCatalogueDocument, {
    alias: 'createCatalogue',
    ...operationOptions,
  });
}

/**
 * __useCreateCatalogueMutation__
 *
 * To run a mutation, you first call `useCreateCatalogueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCatalogueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCatalogueMutation, { data, loading, error }] = useCreateCatalogueMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCatalogueMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCatalogueMutation, CreateCatalogueMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateCatalogueMutation, CreateCatalogueMutationVariables>(
    CreateCatalogueDocument,
    baseOptions
  );
}
export type CreateCatalogueMutationHookResult = ReturnType<typeof useCreateCatalogueMutation>;
export type CreateCatalogueMutationResult = ApolloReactCommon.MutationResult<CreateCatalogueMutation>;
export type CreateCatalogueMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCatalogueMutation,
  CreateCatalogueMutationVariables
>;
export const CreateCatalogueCategoryDocument = gql`
  mutation createCatalogueCategory($data: CatalogueCategoryCreateInput!) {
    createCatalogueCategory(data: $data) {
      ...catalogueCategory
    }
  }
  ${CatalogueCategoryFragmentDoc}
`;
export type CreateCatalogueCategoryMutationFn = ApolloReactCommon.MutationFunction<
  CreateCatalogueCategoryMutation,
  CreateCatalogueCategoryMutationVariables
>;
export type CreateCatalogueCategoryComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateCatalogueCategoryMutation,
    CreateCatalogueCategoryMutationVariables
  >,
  'mutation'
>;

export const CreateCatalogueCategoryComponent = (props: CreateCatalogueCategoryComponentProps) => (
  <ApolloReactComponents.Mutation<CreateCatalogueCategoryMutation, CreateCatalogueCategoryMutationVariables>
    mutation={CreateCatalogueCategoryDocument}
    {...props}
  />
);

export type CreateCatalogueCategoryProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateCatalogueCategoryMutation,
  CreateCatalogueCategoryMutationVariables
> &
  TChildProps;
export function withCreateCatalogueCategory<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCatalogueCategoryMutation,
    CreateCatalogueCategoryMutationVariables,
    CreateCatalogueCategoryProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCatalogueCategoryMutation,
    CreateCatalogueCategoryMutationVariables,
    CreateCatalogueCategoryProps<TChildProps>
  >(CreateCatalogueCategoryDocument, {
    alias: 'createCatalogueCategory',
    ...operationOptions,
  });
}

/**
 * __useCreateCatalogueCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCatalogueCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCatalogueCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCatalogueCategoryMutation, { data, loading, error }] = useCreateCatalogueCategoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCatalogueCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCatalogueCategoryMutation,
    CreateCatalogueCategoryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateCatalogueCategoryMutation, CreateCatalogueCategoryMutationVariables>(
    CreateCatalogueCategoryDocument,
    baseOptions
  );
}
export type CreateCatalogueCategoryMutationHookResult = ReturnType<typeof useCreateCatalogueCategoryMutation>;
export type CreateCatalogueCategoryMutationResult = ApolloReactCommon.MutationResult<CreateCatalogueCategoryMutation>;
export type CreateCatalogueCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCatalogueCategoryMutation,
  CreateCatalogueCategoryMutationVariables
>;
export const CreateCatalogueItemDocument = gql`
  mutation createCatalogueItem($data: CatalogueItemCreateInput!) {
    createCatalogueItem(data: $data) {
      ...catalogueItem
    }
  }
  ${CatalogueItemFragmentDoc}
`;
export type CreateCatalogueItemMutationFn = ApolloReactCommon.MutationFunction<
  CreateCatalogueItemMutation,
  CreateCatalogueItemMutationVariables
>;
export type CreateCatalogueItemComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateCatalogueItemMutation, CreateCatalogueItemMutationVariables>,
  'mutation'
>;

export const CreateCatalogueItemComponent = (props: CreateCatalogueItemComponentProps) => (
  <ApolloReactComponents.Mutation<CreateCatalogueItemMutation, CreateCatalogueItemMutationVariables>
    mutation={CreateCatalogueItemDocument}
    {...props}
  />
);

export type CreateCatalogueItemProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateCatalogueItemMutation,
  CreateCatalogueItemMutationVariables
> &
  TChildProps;
export function withCreateCatalogueItem<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCatalogueItemMutation,
    CreateCatalogueItemMutationVariables,
    CreateCatalogueItemProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCatalogueItemMutation,
    CreateCatalogueItemMutationVariables,
    CreateCatalogueItemProps<TChildProps>
  >(CreateCatalogueItemDocument, {
    alias: 'createCatalogueItem',
    ...operationOptions,
  });
}

/**
 * __useCreateCatalogueItemMutation__
 *
 * To run a mutation, you first call `useCreateCatalogueItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCatalogueItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCatalogueItemMutation, { data, loading, error }] = useCreateCatalogueItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCatalogueItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCatalogueItemMutation, CreateCatalogueItemMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateCatalogueItemMutation, CreateCatalogueItemMutationVariables>(
    CreateCatalogueItemDocument,
    baseOptions
  );
}
export type CreateCatalogueItemMutationHookResult = ReturnType<typeof useCreateCatalogueItemMutation>;
export type CreateCatalogueItemMutationResult = ApolloReactCommon.MutationResult<CreateCatalogueItemMutation>;
export type CreateCatalogueItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCatalogueItemMutation,
  CreateCatalogueItemMutationVariables
>;
export const CreateCatalogueItemPriceDocument = gql`
  mutation createCatalogueItemPrice($data: CatalogueItemPriceCreateInput!) {
    createCatalogueItemPrice(data: $data) {
      ...catalogueItemPrice
    }
  }
  ${CatalogueItemPriceFragmentDoc}
`;
export type CreateCatalogueItemPriceMutationFn = ApolloReactCommon.MutationFunction<
  CreateCatalogueItemPriceMutation,
  CreateCatalogueItemPriceMutationVariables
>;
export type CreateCatalogueItemPriceComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateCatalogueItemPriceMutation,
    CreateCatalogueItemPriceMutationVariables
  >,
  'mutation'
>;

export const CreateCatalogueItemPriceComponent = (props: CreateCatalogueItemPriceComponentProps) => (
  <ApolloReactComponents.Mutation<CreateCatalogueItemPriceMutation, CreateCatalogueItemPriceMutationVariables>
    mutation={CreateCatalogueItemPriceDocument}
    {...props}
  />
);

export type CreateCatalogueItemPriceProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateCatalogueItemPriceMutation,
  CreateCatalogueItemPriceMutationVariables
> &
  TChildProps;
export function withCreateCatalogueItemPrice<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCatalogueItemPriceMutation,
    CreateCatalogueItemPriceMutationVariables,
    CreateCatalogueItemPriceProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCatalogueItemPriceMutation,
    CreateCatalogueItemPriceMutationVariables,
    CreateCatalogueItemPriceProps<TChildProps>
  >(CreateCatalogueItemPriceDocument, {
    alias: 'createCatalogueItemPrice',
    ...operationOptions,
  });
}

/**
 * __useCreateCatalogueItemPriceMutation__
 *
 * To run a mutation, you first call `useCreateCatalogueItemPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCatalogueItemPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCatalogueItemPriceMutation, { data, loading, error }] = useCreateCatalogueItemPriceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCatalogueItemPriceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCatalogueItemPriceMutation,
    CreateCatalogueItemPriceMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateCatalogueItemPriceMutation, CreateCatalogueItemPriceMutationVariables>(
    CreateCatalogueItemPriceDocument,
    baseOptions
  );
}
export type CreateCatalogueItemPriceMutationHookResult = ReturnType<typeof useCreateCatalogueItemPriceMutation>;
export type CreateCatalogueItemPriceMutationResult = ApolloReactCommon.MutationResult<CreateCatalogueItemPriceMutation>;
export type CreateCatalogueItemPriceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCatalogueItemPriceMutation,
  CreateCatalogueItemPriceMutationVariables
>;
export const CreateCateringDocument = gql`
  mutation createCatering($data: CateringCreateInput!) {
    createCatering(data: $data) {
      ...catering
    }
  }
  ${CateringFragmentDoc}
`;
export type CreateCateringMutationFn = ApolloReactCommon.MutationFunction<
  CreateCateringMutation,
  CreateCateringMutationVariables
>;
export type CreateCateringComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateCateringMutation, CreateCateringMutationVariables>,
  'mutation'
>;

export const CreateCateringComponent = (props: CreateCateringComponentProps) => (
  <ApolloReactComponents.Mutation<CreateCateringMutation, CreateCateringMutationVariables>
    mutation={CreateCateringDocument}
    {...props}
  />
);

export type CreateCateringProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateCateringMutation,
  CreateCateringMutationVariables
> &
  TChildProps;
export function withCreateCatering<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCateringMutation,
    CreateCateringMutationVariables,
    CreateCateringProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCateringMutation,
    CreateCateringMutationVariables,
    CreateCateringProps<TChildProps>
  >(CreateCateringDocument, {
    alias: 'createCatering',
    ...operationOptions,
  });
}

/**
 * __useCreateCateringMutation__
 *
 * To run a mutation, you first call `useCreateCateringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCateringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCateringMutation, { data, loading, error }] = useCreateCateringMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCateringMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCateringMutation, CreateCateringMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateCateringMutation, CreateCateringMutationVariables>(
    CreateCateringDocument,
    baseOptions
  );
}
export type CreateCateringMutationHookResult = ReturnType<typeof useCreateCateringMutation>;
export type CreateCateringMutationResult = ApolloReactCommon.MutationResult<CreateCateringMutation>;
export type CreateCateringMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCateringMutation,
  CreateCateringMutationVariables
>;
export const CreateCommerceOrderDocument = gql`
  mutation createCommerceOrder($data: CommerceOrderCreateInput!) {
    createCommerceOrder(data: $data) {
      ...commerceOrder
    }
  }
  ${CommerceOrderFragmentDoc}
`;
export type CreateCommerceOrderMutationFn = ApolloReactCommon.MutationFunction<
  CreateCommerceOrderMutation,
  CreateCommerceOrderMutationVariables
>;
export type CreateCommerceOrderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateCommerceOrderMutation, CreateCommerceOrderMutationVariables>,
  'mutation'
>;

export const CreateCommerceOrderComponent = (props: CreateCommerceOrderComponentProps) => (
  <ApolloReactComponents.Mutation<CreateCommerceOrderMutation, CreateCommerceOrderMutationVariables>
    mutation={CreateCommerceOrderDocument}
    {...props}
  />
);

export type CreateCommerceOrderProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateCommerceOrderMutation,
  CreateCommerceOrderMutationVariables
> &
  TChildProps;
export function withCreateCommerceOrder<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCommerceOrderMutation,
    CreateCommerceOrderMutationVariables,
    CreateCommerceOrderProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCommerceOrderMutation,
    CreateCommerceOrderMutationVariables,
    CreateCommerceOrderProps<TChildProps>
  >(CreateCommerceOrderDocument, {
    alias: 'createCommerceOrder',
    ...operationOptions,
  });
}

/**
 * __useCreateCommerceOrderMutation__
 *
 * To run a mutation, you first call `useCreateCommerceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommerceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommerceOrderMutation, { data, loading, error }] = useCreateCommerceOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCommerceOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCommerceOrderMutation, CreateCommerceOrderMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateCommerceOrderMutation, CreateCommerceOrderMutationVariables>(
    CreateCommerceOrderDocument,
    baseOptions
  );
}
export type CreateCommerceOrderMutationHookResult = ReturnType<typeof useCreateCommerceOrderMutation>;
export type CreateCommerceOrderMutationResult = ApolloReactCommon.MutationResult<CreateCommerceOrderMutation>;
export type CreateCommerceOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCommerceOrderMutation,
  CreateCommerceOrderMutationVariables
>;
export const CreateCommerceOrderDataDocument = gql`
  mutation createCommerceOrderData($data: CommerceOrderDataCreateInput!) {
    createCommerceOrderData(data: $data) {
      ...commerceOrderData
    }
  }
  ${CommerceOrderDataFragmentDoc}
`;
export type CreateCommerceOrderDataMutationFn = ApolloReactCommon.MutationFunction<
  CreateCommerceOrderDataMutation,
  CreateCommerceOrderDataMutationVariables
>;
export type CreateCommerceOrderDataComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateCommerceOrderDataMutation,
    CreateCommerceOrderDataMutationVariables
  >,
  'mutation'
>;

export const CreateCommerceOrderDataComponent = (props: CreateCommerceOrderDataComponentProps) => (
  <ApolloReactComponents.Mutation<CreateCommerceOrderDataMutation, CreateCommerceOrderDataMutationVariables>
    mutation={CreateCommerceOrderDataDocument}
    {...props}
  />
);

export type CreateCommerceOrderDataProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateCommerceOrderDataMutation,
  CreateCommerceOrderDataMutationVariables
> &
  TChildProps;
export function withCreateCommerceOrderData<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCommerceOrderDataMutation,
    CreateCommerceOrderDataMutationVariables,
    CreateCommerceOrderDataProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCommerceOrderDataMutation,
    CreateCommerceOrderDataMutationVariables,
    CreateCommerceOrderDataProps<TChildProps>
  >(CreateCommerceOrderDataDocument, {
    alias: 'createCommerceOrderData',
    ...operationOptions,
  });
}

/**
 * __useCreateCommerceOrderDataMutation__
 *
 * To run a mutation, you first call `useCreateCommerceOrderDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommerceOrderDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommerceOrderDataMutation, { data, loading, error }] = useCreateCommerceOrderDataMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCommerceOrderDataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCommerceOrderDataMutation,
    CreateCommerceOrderDataMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateCommerceOrderDataMutation, CreateCommerceOrderDataMutationVariables>(
    CreateCommerceOrderDataDocument,
    baseOptions
  );
}
export type CreateCommerceOrderDataMutationHookResult = ReturnType<typeof useCreateCommerceOrderDataMutation>;
export type CreateCommerceOrderDataMutationResult = ApolloReactCommon.MutationResult<CreateCommerceOrderDataMutation>;
export type CreateCommerceOrderDataMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCommerceOrderDataMutation,
  CreateCommerceOrderDataMutationVariables
>;
export const CreateCommerceOrderFieldDocument = gql`
  mutation createCommerceOrderField($data: CommerceOrderFieldCreateInput!) {
    createCommerceOrderField(data: $data) {
      ...commerceOrderField
    }
  }
  ${CommerceOrderFieldFragmentDoc}
`;
export type CreateCommerceOrderFieldMutationFn = ApolloReactCommon.MutationFunction<
  CreateCommerceOrderFieldMutation,
  CreateCommerceOrderFieldMutationVariables
>;
export type CreateCommerceOrderFieldComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateCommerceOrderFieldMutation,
    CreateCommerceOrderFieldMutationVariables
  >,
  'mutation'
>;

export const CreateCommerceOrderFieldComponent = (props: CreateCommerceOrderFieldComponentProps) => (
  <ApolloReactComponents.Mutation<CreateCommerceOrderFieldMutation, CreateCommerceOrderFieldMutationVariables>
    mutation={CreateCommerceOrderFieldDocument}
    {...props}
  />
);

export type CreateCommerceOrderFieldProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateCommerceOrderFieldMutation,
  CreateCommerceOrderFieldMutationVariables
> &
  TChildProps;
export function withCreateCommerceOrderField<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCommerceOrderFieldMutation,
    CreateCommerceOrderFieldMutationVariables,
    CreateCommerceOrderFieldProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCommerceOrderFieldMutation,
    CreateCommerceOrderFieldMutationVariables,
    CreateCommerceOrderFieldProps<TChildProps>
  >(CreateCommerceOrderFieldDocument, {
    alias: 'createCommerceOrderField',
    ...operationOptions,
  });
}

/**
 * __useCreateCommerceOrderFieldMutation__
 *
 * To run a mutation, you first call `useCreateCommerceOrderFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommerceOrderFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommerceOrderFieldMutation, { data, loading, error }] = useCreateCommerceOrderFieldMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCommerceOrderFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCommerceOrderFieldMutation,
    CreateCommerceOrderFieldMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateCommerceOrderFieldMutation, CreateCommerceOrderFieldMutationVariables>(
    CreateCommerceOrderFieldDocument,
    baseOptions
  );
}
export type CreateCommerceOrderFieldMutationHookResult = ReturnType<typeof useCreateCommerceOrderFieldMutation>;
export type CreateCommerceOrderFieldMutationResult = ApolloReactCommon.MutationResult<CreateCommerceOrderFieldMutation>;
export type CreateCommerceOrderFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCommerceOrderFieldMutation,
  CreateCommerceOrderFieldMutationVariables
>;
export const CreateCommerceOrderItemDocument = gql`
  mutation createCommerceOrderItem($data: CommerceOrderItemCreateInput!) {
    createCommerceOrderItem(data: $data) {
      ...commerceOrderItem
    }
  }
  ${CommerceOrderItemFragmentDoc}
`;
export type CreateCommerceOrderItemMutationFn = ApolloReactCommon.MutationFunction<
  CreateCommerceOrderItemMutation,
  CreateCommerceOrderItemMutationVariables
>;
export type CreateCommerceOrderItemComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateCommerceOrderItemMutation,
    CreateCommerceOrderItemMutationVariables
  >,
  'mutation'
>;

export const CreateCommerceOrderItemComponent = (props: CreateCommerceOrderItemComponentProps) => (
  <ApolloReactComponents.Mutation<CreateCommerceOrderItemMutation, CreateCommerceOrderItemMutationVariables>
    mutation={CreateCommerceOrderItemDocument}
    {...props}
  />
);

export type CreateCommerceOrderItemProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateCommerceOrderItemMutation,
  CreateCommerceOrderItemMutationVariables
> &
  TChildProps;
export function withCreateCommerceOrderItem<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCommerceOrderItemMutation,
    CreateCommerceOrderItemMutationVariables,
    CreateCommerceOrderItemProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCommerceOrderItemMutation,
    CreateCommerceOrderItemMutationVariables,
    CreateCommerceOrderItemProps<TChildProps>
  >(CreateCommerceOrderItemDocument, {
    alias: 'createCommerceOrderItem',
    ...operationOptions,
  });
}

/**
 * __useCreateCommerceOrderItemMutation__
 *
 * To run a mutation, you first call `useCreateCommerceOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommerceOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommerceOrderItemMutation, { data, loading, error }] = useCreateCommerceOrderItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCommerceOrderItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCommerceOrderItemMutation,
    CreateCommerceOrderItemMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateCommerceOrderItemMutation, CreateCommerceOrderItemMutationVariables>(
    CreateCommerceOrderItemDocument,
    baseOptions
  );
}
export type CreateCommerceOrderItemMutationHookResult = ReturnType<typeof useCreateCommerceOrderItemMutation>;
export type CreateCommerceOrderItemMutationResult = ApolloReactCommon.MutationResult<CreateCommerceOrderItemMutation>;
export type CreateCommerceOrderItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCommerceOrderItemMutation,
  CreateCommerceOrderItemMutationVariables
>;
export const CreateCommerceShopDocument = gql`
  mutation createCommerceShop($data: CommerceShopCreateInput!) {
    createCommerceShop(data: $data) {
      ...commerceShop
    }
  }
  ${CommerceShopFragmentDoc}
`;
export type CreateCommerceShopMutationFn = ApolloReactCommon.MutationFunction<
  CreateCommerceShopMutation,
  CreateCommerceShopMutationVariables
>;
export type CreateCommerceShopComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateCommerceShopMutation, CreateCommerceShopMutationVariables>,
  'mutation'
>;

export const CreateCommerceShopComponent = (props: CreateCommerceShopComponentProps) => (
  <ApolloReactComponents.Mutation<CreateCommerceShopMutation, CreateCommerceShopMutationVariables>
    mutation={CreateCommerceShopDocument}
    {...props}
  />
);

export type CreateCommerceShopProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateCommerceShopMutation,
  CreateCommerceShopMutationVariables
> &
  TChildProps;
export function withCreateCommerceShop<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCommerceShopMutation,
    CreateCommerceShopMutationVariables,
    CreateCommerceShopProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCommerceShopMutation,
    CreateCommerceShopMutationVariables,
    CreateCommerceShopProps<TChildProps>
  >(CreateCommerceShopDocument, {
    alias: 'createCommerceShop',
    ...operationOptions,
  });
}

/**
 * __useCreateCommerceShopMutation__
 *
 * To run a mutation, you first call `useCreateCommerceShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommerceShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommerceShopMutation, { data, loading, error }] = useCreateCommerceShopMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCommerceShopMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCommerceShopMutation, CreateCommerceShopMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateCommerceShopMutation, CreateCommerceShopMutationVariables>(
    CreateCommerceShopDocument,
    baseOptions
  );
}
export type CreateCommerceShopMutationHookResult = ReturnType<typeof useCreateCommerceShopMutation>;
export type CreateCommerceShopMutationResult = ApolloReactCommon.MutationResult<CreateCommerceShopMutation>;
export type CreateCommerceShopMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCommerceShopMutation,
  CreateCommerceShopMutationVariables
>;
export const CreateContentTranslationDocument = gql`
  mutation createContentTranslation($data: ContentTranslationCreateInput!) {
    createContentTranslation(data: $data) {
      ...contentTranslation
    }
  }
  ${ContentTranslationFragmentDoc}
`;
export type CreateContentTranslationMutationFn = ApolloReactCommon.MutationFunction<
  CreateContentTranslationMutation,
  CreateContentTranslationMutationVariables
>;
export type CreateContentTranslationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateContentTranslationMutation,
    CreateContentTranslationMutationVariables
  >,
  'mutation'
>;

export const CreateContentTranslationComponent = (props: CreateContentTranslationComponentProps) => (
  <ApolloReactComponents.Mutation<CreateContentTranslationMutation, CreateContentTranslationMutationVariables>
    mutation={CreateContentTranslationDocument}
    {...props}
  />
);

export type CreateContentTranslationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateContentTranslationMutation,
  CreateContentTranslationMutationVariables
> &
  TChildProps;
export function withCreateContentTranslation<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateContentTranslationMutation,
    CreateContentTranslationMutationVariables,
    CreateContentTranslationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateContentTranslationMutation,
    CreateContentTranslationMutationVariables,
    CreateContentTranslationProps<TChildProps>
  >(CreateContentTranslationDocument, {
    alias: 'createContentTranslation',
    ...operationOptions,
  });
}

/**
 * __useCreateContentTranslationMutation__
 *
 * To run a mutation, you first call `useCreateContentTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContentTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContentTranslationMutation, { data, loading, error }] = useCreateContentTranslationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateContentTranslationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateContentTranslationMutation,
    CreateContentTranslationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateContentTranslationMutation, CreateContentTranslationMutationVariables>(
    CreateContentTranslationDocument,
    baseOptions
  );
}
export type CreateContentTranslationMutationHookResult = ReturnType<typeof useCreateContentTranslationMutation>;
export type CreateContentTranslationMutationResult = ApolloReactCommon.MutationResult<CreateContentTranslationMutation>;
export type CreateContentTranslationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateContentTranslationMutation,
  CreateContentTranslationMutationVariables
>;
export const CreateDisputeDocument = gql`
  mutation createDispute($data: DisputeCreateInput!) {
    createDispute(data: $data) {
      ...dispute
    }
  }
  ${DisputeFragmentDoc}
`;
export type CreateDisputeMutationFn = ApolloReactCommon.MutationFunction<
  CreateDisputeMutation,
  CreateDisputeMutationVariables
>;
export type CreateDisputeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateDisputeMutation, CreateDisputeMutationVariables>,
  'mutation'
>;

export const CreateDisputeComponent = (props: CreateDisputeComponentProps) => (
  <ApolloReactComponents.Mutation<CreateDisputeMutation, CreateDisputeMutationVariables>
    mutation={CreateDisputeDocument}
    {...props}
  />
);

export type CreateDisputeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateDisputeMutation,
  CreateDisputeMutationVariables
> &
  TChildProps;
export function withCreateDispute<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateDisputeMutation,
    CreateDisputeMutationVariables,
    CreateDisputeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateDisputeMutation,
    CreateDisputeMutationVariables,
    CreateDisputeProps<TChildProps>
  >(CreateDisputeDocument, {
    alias: 'createDispute',
    ...operationOptions,
  });
}

/**
 * __useCreateDisputeMutation__
 *
 * To run a mutation, you first call `useCreateDisputeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDisputeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDisputeMutation, { data, loading, error }] = useCreateDisputeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDisputeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDisputeMutation, CreateDisputeMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateDisputeMutation, CreateDisputeMutationVariables>(
    CreateDisputeDocument,
    baseOptions
  );
}
export type CreateDisputeMutationHookResult = ReturnType<typeof useCreateDisputeMutation>;
export type CreateDisputeMutationResult = ApolloReactCommon.MutationResult<CreateDisputeMutation>;
export type CreateDisputeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateDisputeMutation,
  CreateDisputeMutationVariables
>;
export const CreateDisputeDisputeResponseDocument = gql`
  mutation createDisputeDisputeResponse($data: DisputeResponseCreateInput!) {
    createDisputeDisputeResponse(data: $data) {
      ...disputeDisputeResponse
    }
  }
  ${DisputeDisputeResponseFragmentDoc}
`;
export type CreateDisputeDisputeResponseMutationFn = ApolloReactCommon.MutationFunction<
  CreateDisputeDisputeResponseMutation,
  CreateDisputeDisputeResponseMutationVariables
>;
export type CreateDisputeDisputeResponseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateDisputeDisputeResponseMutation,
    CreateDisputeDisputeResponseMutationVariables
  >,
  'mutation'
>;

export const CreateDisputeDisputeResponseComponent = (props: CreateDisputeDisputeResponseComponentProps) => (
  <ApolloReactComponents.Mutation<CreateDisputeDisputeResponseMutation, CreateDisputeDisputeResponseMutationVariables>
    mutation={CreateDisputeDisputeResponseDocument}
    {...props}
  />
);

export type CreateDisputeDisputeResponseProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateDisputeDisputeResponseMutation,
  CreateDisputeDisputeResponseMutationVariables
> &
  TChildProps;
export function withCreateDisputeDisputeResponse<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateDisputeDisputeResponseMutation,
    CreateDisputeDisputeResponseMutationVariables,
    CreateDisputeDisputeResponseProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateDisputeDisputeResponseMutation,
    CreateDisputeDisputeResponseMutationVariables,
    CreateDisputeDisputeResponseProps<TChildProps>
  >(CreateDisputeDisputeResponseDocument, {
    alias: 'createDisputeDisputeResponse',
    ...operationOptions,
  });
}

/**
 * __useCreateDisputeDisputeResponseMutation__
 *
 * To run a mutation, you first call `useCreateDisputeDisputeResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDisputeDisputeResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDisputeDisputeResponseMutation, { data, loading, error }] = useCreateDisputeDisputeResponseMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDisputeDisputeResponseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateDisputeDisputeResponseMutation,
    CreateDisputeDisputeResponseMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateDisputeDisputeResponseMutation,
    CreateDisputeDisputeResponseMutationVariables
  >(CreateDisputeDisputeResponseDocument, baseOptions);
}
export type CreateDisputeDisputeResponseMutationHookResult = ReturnType<typeof useCreateDisputeDisputeResponseMutation>;
export type CreateDisputeDisputeResponseMutationResult = ApolloReactCommon.MutationResult<
  CreateDisputeDisputeResponseMutation
>;
export type CreateDisputeDisputeResponseMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateDisputeDisputeResponseMutation,
  CreateDisputeDisputeResponseMutationVariables
>;
export const CreateEventDocument = gql`
  mutation createEvent($data: EventCreateInput!) {
    createEvent(data: $data) {
      ...eventFull
    }
  }
  ${EventFullFragmentDoc}
`;
export type CreateEventMutationFn = ApolloReactCommon.MutationFunction<
  CreateEventMutation,
  CreateEventMutationVariables
>;
export type CreateEventComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateEventMutation, CreateEventMutationVariables>,
  'mutation'
>;

export const CreateEventComponent = (props: CreateEventComponentProps) => (
  <ApolloReactComponents.Mutation<CreateEventMutation, CreateEventMutationVariables>
    mutation={CreateEventDocument}
    {...props}
  />
);

export type CreateEventProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateEventMutation,
  CreateEventMutationVariables
> &
  TChildProps;
export function withCreateEvent<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateEventMutation,
    CreateEventMutationVariables,
    CreateEventProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateEventMutation,
    CreateEventMutationVariables,
    CreateEventProps<TChildProps>
  >(CreateEventDocument, {
    alias: 'createEvent',
    ...operationOptions,
  });
}

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateEventMutation, CreateEventMutationVariables>(
    CreateEventDocument,
    baseOptions
  );
}
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = ApolloReactCommon.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateEventMutation,
  CreateEventMutationVariables
>;
export const CreateEventEventAccessDocument = gql`
  mutation createEventEventAccess($data: EventEventAccessCreateInput!) {
    createEventEventAccess(data: $data) {
      ...eventEventAccess
    }
  }
  ${EventEventAccessFragmentDoc}
`;
export type CreateEventEventAccessMutationFn = ApolloReactCommon.MutationFunction<
  CreateEventEventAccessMutation,
  CreateEventEventAccessMutationVariables
>;
export type CreateEventEventAccessComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateEventEventAccessMutation,
    CreateEventEventAccessMutationVariables
  >,
  'mutation'
>;

export const CreateEventEventAccessComponent = (props: CreateEventEventAccessComponentProps) => (
  <ApolloReactComponents.Mutation<CreateEventEventAccessMutation, CreateEventEventAccessMutationVariables>
    mutation={CreateEventEventAccessDocument}
    {...props}
  />
);

export type CreateEventEventAccessProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateEventEventAccessMutation,
  CreateEventEventAccessMutationVariables
> &
  TChildProps;
export function withCreateEventEventAccess<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateEventEventAccessMutation,
    CreateEventEventAccessMutationVariables,
    CreateEventEventAccessProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateEventEventAccessMutation,
    CreateEventEventAccessMutationVariables,
    CreateEventEventAccessProps<TChildProps>
  >(CreateEventEventAccessDocument, {
    alias: 'createEventEventAccess',
    ...operationOptions,
  });
}

/**
 * __useCreateEventEventAccessMutation__
 *
 * To run a mutation, you first call `useCreateEventEventAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventEventAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventEventAccessMutation, { data, loading, error }] = useCreateEventEventAccessMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEventEventAccessMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateEventEventAccessMutation,
    CreateEventEventAccessMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateEventEventAccessMutation, CreateEventEventAccessMutationVariables>(
    CreateEventEventAccessDocument,
    baseOptions
  );
}
export type CreateEventEventAccessMutationHookResult = ReturnType<typeof useCreateEventEventAccessMutation>;
export type CreateEventEventAccessMutationResult = ApolloReactCommon.MutationResult<CreateEventEventAccessMutation>;
export type CreateEventEventAccessMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateEventEventAccessMutation,
  CreateEventEventAccessMutationVariables
>;
export const CreateEventPlaceDocument = gql`
  mutation createEventPlace($data: EventPlaceCreateInput!) {
    createEventPlace(data: $data) {
      ...eventPlace
    }
  }
  ${EventPlaceFragmentDoc}
`;
export type CreateEventPlaceMutationFn = ApolloReactCommon.MutationFunction<
  CreateEventPlaceMutation,
  CreateEventPlaceMutationVariables
>;
export type CreateEventPlaceComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateEventPlaceMutation, CreateEventPlaceMutationVariables>,
  'mutation'
>;

export const CreateEventPlaceComponent = (props: CreateEventPlaceComponentProps) => (
  <ApolloReactComponents.Mutation<CreateEventPlaceMutation, CreateEventPlaceMutationVariables>
    mutation={CreateEventPlaceDocument}
    {...props}
  />
);

export type CreateEventPlaceProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateEventPlaceMutation,
  CreateEventPlaceMutationVariables
> &
  TChildProps;
export function withCreateEventPlace<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateEventPlaceMutation,
    CreateEventPlaceMutationVariables,
    CreateEventPlaceProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateEventPlaceMutation,
    CreateEventPlaceMutationVariables,
    CreateEventPlaceProps<TChildProps>
  >(CreateEventPlaceDocument, {
    alias: 'createEventPlace',
    ...operationOptions,
  });
}

/**
 * __useCreateEventPlaceMutation__
 *
 * To run a mutation, you first call `useCreateEventPlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventPlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventPlaceMutation, { data, loading, error }] = useCreateEventPlaceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEventPlaceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEventPlaceMutation, CreateEventPlaceMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateEventPlaceMutation, CreateEventPlaceMutationVariables>(
    CreateEventPlaceDocument,
    baseOptions
  );
}
export type CreateEventPlaceMutationHookResult = ReturnType<typeof useCreateEventPlaceMutation>;
export type CreateEventPlaceMutationResult = ApolloReactCommon.MutationResult<CreateEventPlaceMutation>;
export type CreateEventPlaceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateEventPlaceMutation,
  CreateEventPlaceMutationVariables
>;
export const CreateEventPlaceSectorDocument = gql`
  mutation createEventPlaceSector($data: EventPlaceSectorCreateInput!) {
    createEventPlaceSector(data: $data) {
      ...eventPlaceSector
    }
  }
  ${EventPlaceSectorFragmentDoc}
`;
export type CreateEventPlaceSectorMutationFn = ApolloReactCommon.MutationFunction<
  CreateEventPlaceSectorMutation,
  CreateEventPlaceSectorMutationVariables
>;
export type CreateEventPlaceSectorComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateEventPlaceSectorMutation,
    CreateEventPlaceSectorMutationVariables
  >,
  'mutation'
>;

export const CreateEventPlaceSectorComponent = (props: CreateEventPlaceSectorComponentProps) => (
  <ApolloReactComponents.Mutation<CreateEventPlaceSectorMutation, CreateEventPlaceSectorMutationVariables>
    mutation={CreateEventPlaceSectorDocument}
    {...props}
  />
);

export type CreateEventPlaceSectorProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateEventPlaceSectorMutation,
  CreateEventPlaceSectorMutationVariables
> &
  TChildProps;
export function withCreateEventPlaceSector<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateEventPlaceSectorMutation,
    CreateEventPlaceSectorMutationVariables,
    CreateEventPlaceSectorProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateEventPlaceSectorMutation,
    CreateEventPlaceSectorMutationVariables,
    CreateEventPlaceSectorProps<TChildProps>
  >(CreateEventPlaceSectorDocument, {
    alias: 'createEventPlaceSector',
    ...operationOptions,
  });
}

/**
 * __useCreateEventPlaceSectorMutation__
 *
 * To run a mutation, you first call `useCreateEventPlaceSectorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventPlaceSectorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventPlaceSectorMutation, { data, loading, error }] = useCreateEventPlaceSectorMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEventPlaceSectorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateEventPlaceSectorMutation,
    CreateEventPlaceSectorMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateEventPlaceSectorMutation, CreateEventPlaceSectorMutationVariables>(
    CreateEventPlaceSectorDocument,
    baseOptions
  );
}
export type CreateEventPlaceSectorMutationHookResult = ReturnType<typeof useCreateEventPlaceSectorMutation>;
export type CreateEventPlaceSectorMutationResult = ApolloReactCommon.MutationResult<CreateEventPlaceSectorMutation>;
export type CreateEventPlaceSectorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateEventPlaceSectorMutation,
  CreateEventPlaceSectorMutationVariables
>;
export const CreateEventTypeDocument = gql`
  mutation createEventType($data: EventTypeCreateInput!) {
    createEventType(data: $data) {
      ...eventType
    }
  }
  ${EventTypeFragmentDoc}
`;
export type CreateEventTypeMutationFn = ApolloReactCommon.MutationFunction<
  CreateEventTypeMutation,
  CreateEventTypeMutationVariables
>;
export type CreateEventTypeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateEventTypeMutation, CreateEventTypeMutationVariables>,
  'mutation'
>;

export const CreateEventTypeComponent = (props: CreateEventTypeComponentProps) => (
  <ApolloReactComponents.Mutation<CreateEventTypeMutation, CreateEventTypeMutationVariables>
    mutation={CreateEventTypeDocument}
    {...props}
  />
);

export type CreateEventTypeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateEventTypeMutation,
  CreateEventTypeMutationVariables
> &
  TChildProps;
export function withCreateEventType<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateEventTypeMutation,
    CreateEventTypeMutationVariables,
    CreateEventTypeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateEventTypeMutation,
    CreateEventTypeMutationVariables,
    CreateEventTypeProps<TChildProps>
  >(CreateEventTypeDocument, {
    alias: 'createEventType',
    ...operationOptions,
  });
}

/**
 * __useCreateEventTypeMutation__
 *
 * To run a mutation, you first call `useCreateEventTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventTypeMutation, { data, loading, error }] = useCreateEventTypeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEventTypeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEventTypeMutation, CreateEventTypeMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateEventTypeMutation, CreateEventTypeMutationVariables>(
    CreateEventTypeDocument,
    baseOptions
  );
}
export type CreateEventTypeMutationHookResult = ReturnType<typeof useCreateEventTypeMutation>;
export type CreateEventTypeMutationResult = ApolloReactCommon.MutationResult<CreateEventTypeMutation>;
export type CreateEventTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateEventTypeMutation,
  CreateEventTypeMutationVariables
>;
export const CreateLoyaltyPartnerDocument = gql`
  mutation createLoyaltyPartner($data: LoyaltyPartnerCreateInput!) {
    createLoyaltyPartner(data: $data) {
      ...loyaltyPartner
    }
  }
  ${LoyaltyPartnerFragmentDoc}
`;
export type CreateLoyaltyPartnerMutationFn = ApolloReactCommon.MutationFunction<
  CreateLoyaltyPartnerMutation,
  CreateLoyaltyPartnerMutationVariables
>;
export type CreateLoyaltyPartnerComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateLoyaltyPartnerMutation, CreateLoyaltyPartnerMutationVariables>,
  'mutation'
>;

export const CreateLoyaltyPartnerComponent = (props: CreateLoyaltyPartnerComponentProps) => (
  <ApolloReactComponents.Mutation<CreateLoyaltyPartnerMutation, CreateLoyaltyPartnerMutationVariables>
    mutation={CreateLoyaltyPartnerDocument}
    {...props}
  />
);

export type CreateLoyaltyPartnerProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateLoyaltyPartnerMutation,
  CreateLoyaltyPartnerMutationVariables
> &
  TChildProps;
export function withCreateLoyaltyPartner<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateLoyaltyPartnerMutation,
    CreateLoyaltyPartnerMutationVariables,
    CreateLoyaltyPartnerProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateLoyaltyPartnerMutation,
    CreateLoyaltyPartnerMutationVariables,
    CreateLoyaltyPartnerProps<TChildProps>
  >(CreateLoyaltyPartnerDocument, {
    alias: 'createLoyaltyPartner',
    ...operationOptions,
  });
}

/**
 * __useCreateLoyaltyPartnerMutation__
 *
 * To run a mutation, you first call `useCreateLoyaltyPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLoyaltyPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLoyaltyPartnerMutation, { data, loading, error }] = useCreateLoyaltyPartnerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateLoyaltyPartnerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateLoyaltyPartnerMutation,
    CreateLoyaltyPartnerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateLoyaltyPartnerMutation, CreateLoyaltyPartnerMutationVariables>(
    CreateLoyaltyPartnerDocument,
    baseOptions
  );
}
export type CreateLoyaltyPartnerMutationHookResult = ReturnType<typeof useCreateLoyaltyPartnerMutation>;
export type CreateLoyaltyPartnerMutationResult = ApolloReactCommon.MutationResult<CreateLoyaltyPartnerMutation>;
export type CreateLoyaltyPartnerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateLoyaltyPartnerMutation,
  CreateLoyaltyPartnerMutationVariables
>;
export const CreateLoyaltyPromoDocument = gql`
  mutation createLoyaltyPromo($data: LoyaltyPromoCreateInput!) {
    createLoyaltyPromo(data: $data) {
      ...loyaltyPromo
    }
  }
  ${LoyaltyPromoFragmentDoc}
`;
export type CreateLoyaltyPromoMutationFn = ApolloReactCommon.MutationFunction<
  CreateLoyaltyPromoMutation,
  CreateLoyaltyPromoMutationVariables
>;
export type CreateLoyaltyPromoComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateLoyaltyPromoMutation, CreateLoyaltyPromoMutationVariables>,
  'mutation'
>;

export const CreateLoyaltyPromoComponent = (props: CreateLoyaltyPromoComponentProps) => (
  <ApolloReactComponents.Mutation<CreateLoyaltyPromoMutation, CreateLoyaltyPromoMutationVariables>
    mutation={CreateLoyaltyPromoDocument}
    {...props}
  />
);

export type CreateLoyaltyPromoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateLoyaltyPromoMutation,
  CreateLoyaltyPromoMutationVariables
> &
  TChildProps;
export function withCreateLoyaltyPromo<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateLoyaltyPromoMutation,
    CreateLoyaltyPromoMutationVariables,
    CreateLoyaltyPromoProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateLoyaltyPromoMutation,
    CreateLoyaltyPromoMutationVariables,
    CreateLoyaltyPromoProps<TChildProps>
  >(CreateLoyaltyPromoDocument, {
    alias: 'createLoyaltyPromo',
    ...operationOptions,
  });
}

/**
 * __useCreateLoyaltyPromoMutation__
 *
 * To run a mutation, you first call `useCreateLoyaltyPromoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLoyaltyPromoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLoyaltyPromoMutation, { data, loading, error }] = useCreateLoyaltyPromoMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateLoyaltyPromoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLoyaltyPromoMutation, CreateLoyaltyPromoMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateLoyaltyPromoMutation, CreateLoyaltyPromoMutationVariables>(
    CreateLoyaltyPromoDocument,
    baseOptions
  );
}
export type CreateLoyaltyPromoMutationHookResult = ReturnType<typeof useCreateLoyaltyPromoMutation>;
export type CreateLoyaltyPromoMutationResult = ApolloReactCommon.MutationResult<CreateLoyaltyPromoMutation>;
export type CreateLoyaltyPromoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateLoyaltyPromoMutation,
  CreateLoyaltyPromoMutationVariables
>;
export const CreateLoyaltyPromoCodeDocument = gql`
  mutation createLoyaltyPromoCode($data: LoyaltyPromoCodeCreateInput!) {
    createLoyaltyPromoCode(data: $data) {
      ...loyaltyPromoCode
    }
  }
  ${LoyaltyPromoCodeFragmentDoc}
`;
export type CreateLoyaltyPromoCodeMutationFn = ApolloReactCommon.MutationFunction<
  CreateLoyaltyPromoCodeMutation,
  CreateLoyaltyPromoCodeMutationVariables
>;
export type CreateLoyaltyPromoCodeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateLoyaltyPromoCodeMutation,
    CreateLoyaltyPromoCodeMutationVariables
  >,
  'mutation'
>;

export const CreateLoyaltyPromoCodeComponent = (props: CreateLoyaltyPromoCodeComponentProps) => (
  <ApolloReactComponents.Mutation<CreateLoyaltyPromoCodeMutation, CreateLoyaltyPromoCodeMutationVariables>
    mutation={CreateLoyaltyPromoCodeDocument}
    {...props}
  />
);

export type CreateLoyaltyPromoCodeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateLoyaltyPromoCodeMutation,
  CreateLoyaltyPromoCodeMutationVariables
> &
  TChildProps;
export function withCreateLoyaltyPromoCode<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateLoyaltyPromoCodeMutation,
    CreateLoyaltyPromoCodeMutationVariables,
    CreateLoyaltyPromoCodeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateLoyaltyPromoCodeMutation,
    CreateLoyaltyPromoCodeMutationVariables,
    CreateLoyaltyPromoCodeProps<TChildProps>
  >(CreateLoyaltyPromoCodeDocument, {
    alias: 'createLoyaltyPromoCode',
    ...operationOptions,
  });
}

/**
 * __useCreateLoyaltyPromoCodeMutation__
 *
 * To run a mutation, you first call `useCreateLoyaltyPromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLoyaltyPromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLoyaltyPromoCodeMutation, { data, loading, error }] = useCreateLoyaltyPromoCodeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateLoyaltyPromoCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateLoyaltyPromoCodeMutation,
    CreateLoyaltyPromoCodeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateLoyaltyPromoCodeMutation, CreateLoyaltyPromoCodeMutationVariables>(
    CreateLoyaltyPromoCodeDocument,
    baseOptions
  );
}
export type CreateLoyaltyPromoCodeMutationHookResult = ReturnType<typeof useCreateLoyaltyPromoCodeMutation>;
export type CreateLoyaltyPromoCodeMutationResult = ApolloReactCommon.MutationResult<CreateLoyaltyPromoCodeMutation>;
export type CreateLoyaltyPromoCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateLoyaltyPromoCodeMutation,
  CreateLoyaltyPromoCodeMutationVariables
>;
export const CreateMessengerMailingDocument = gql`
  mutation createMessengerMailing($data: MessengerMailingCreateInput!) {
    createMessengerMailing(data: $data) {
      ...messengerMailing
    }
  }
  ${MessengerMailingFragmentDoc}
`;
export type CreateMessengerMailingMutationFn = ApolloReactCommon.MutationFunction<
  CreateMessengerMailingMutation,
  CreateMessengerMailingMutationVariables
>;
export type CreateMessengerMailingComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateMessengerMailingMutation,
    CreateMessengerMailingMutationVariables
  >,
  'mutation'
>;

export const CreateMessengerMailingComponent = (props: CreateMessengerMailingComponentProps) => (
  <ApolloReactComponents.Mutation<CreateMessengerMailingMutation, CreateMessengerMailingMutationVariables>
    mutation={CreateMessengerMailingDocument}
    {...props}
  />
);

export type CreateMessengerMailingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateMessengerMailingMutation,
  CreateMessengerMailingMutationVariables
> &
  TChildProps;
export function withCreateMessengerMailing<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateMessengerMailingMutation,
    CreateMessengerMailingMutationVariables,
    CreateMessengerMailingProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateMessengerMailingMutation,
    CreateMessengerMailingMutationVariables,
    CreateMessengerMailingProps<TChildProps>
  >(CreateMessengerMailingDocument, {
    alias: 'createMessengerMailing',
    ...operationOptions,
  });
}

/**
 * __useCreateMessengerMailingMutation__
 *
 * To run a mutation, you first call `useCreateMessengerMailingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessengerMailingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessengerMailingMutation, { data, loading, error }] = useCreateMessengerMailingMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMessengerMailingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateMessengerMailingMutation,
    CreateMessengerMailingMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateMessengerMailingMutation, CreateMessengerMailingMutationVariables>(
    CreateMessengerMailingDocument,
    baseOptions
  );
}
export type CreateMessengerMailingMutationHookResult = ReturnType<typeof useCreateMessengerMailingMutation>;
export type CreateMessengerMailingMutationResult = ApolloReactCommon.MutationResult<CreateMessengerMailingMutation>;
export type CreateMessengerMailingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateMessengerMailingMutation,
  CreateMessengerMailingMutationVariables
>;
export const CreateMessengerQueueNotificationDocument = gql`
  mutation createMessengerQueueNotification($data: MessengerQueueNotificationCreateInput!) {
    createMessengerQueueNotification(data: $data) {
      ...messengerQueueNotification
    }
  }
  ${MessengerQueueNotificationFragmentDoc}
`;
export type CreateMessengerQueueNotificationMutationFn = ApolloReactCommon.MutationFunction<
  CreateMessengerQueueNotificationMutation,
  CreateMessengerQueueNotificationMutationVariables
>;
export type CreateMessengerQueueNotificationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateMessengerQueueNotificationMutation,
    CreateMessengerQueueNotificationMutationVariables
  >,
  'mutation'
>;

export const CreateMessengerQueueNotificationComponent = (props: CreateMessengerQueueNotificationComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateMessengerQueueNotificationMutation,
    CreateMessengerQueueNotificationMutationVariables
  >
    mutation={CreateMessengerQueueNotificationDocument}
    {...props}
  />
);

export type CreateMessengerQueueNotificationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateMessengerQueueNotificationMutation,
  CreateMessengerQueueNotificationMutationVariables
> &
  TChildProps;
export function withCreateMessengerQueueNotification<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateMessengerQueueNotificationMutation,
    CreateMessengerQueueNotificationMutationVariables,
    CreateMessengerQueueNotificationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateMessengerQueueNotificationMutation,
    CreateMessengerQueueNotificationMutationVariables,
    CreateMessengerQueueNotificationProps<TChildProps>
  >(CreateMessengerQueueNotificationDocument, {
    alias: 'createMessengerQueueNotification',
    ...operationOptions,
  });
}

/**
 * __useCreateMessengerQueueNotificationMutation__
 *
 * To run a mutation, you first call `useCreateMessengerQueueNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessengerQueueNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessengerQueueNotificationMutation, { data, loading, error }] = useCreateMessengerQueueNotificationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMessengerQueueNotificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateMessengerQueueNotificationMutation,
    CreateMessengerQueueNotificationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateMessengerQueueNotificationMutation,
    CreateMessengerQueueNotificationMutationVariables
  >(CreateMessengerQueueNotificationDocument, baseOptions);
}
export type CreateMessengerQueueNotificationMutationHookResult = ReturnType<
  typeof useCreateMessengerQueueNotificationMutation
>;
export type CreateMessengerQueueNotificationMutationResult = ApolloReactCommon.MutationResult<
  CreateMessengerQueueNotificationMutation
>;
export type CreateMessengerQueueNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateMessengerQueueNotificationMutation,
  CreateMessengerQueueNotificationMutationVariables
>;
export const CreateMessengerQueueTaskDocument = gql`
  mutation createMessengerQueueTask($data: MessengerQueueTaskCreateInput!) {
    createMessengerQueueTask(data: $data) {
      ...messengerQueueTask
    }
  }
  ${MessengerQueueTaskFragmentDoc}
`;
export type CreateMessengerQueueTaskMutationFn = ApolloReactCommon.MutationFunction<
  CreateMessengerQueueTaskMutation,
  CreateMessengerQueueTaskMutationVariables
>;
export type CreateMessengerQueueTaskComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateMessengerQueueTaskMutation,
    CreateMessengerQueueTaskMutationVariables
  >,
  'mutation'
>;

export const CreateMessengerQueueTaskComponent = (props: CreateMessengerQueueTaskComponentProps) => (
  <ApolloReactComponents.Mutation<CreateMessengerQueueTaskMutation, CreateMessengerQueueTaskMutationVariables>
    mutation={CreateMessengerQueueTaskDocument}
    {...props}
  />
);

export type CreateMessengerQueueTaskProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateMessengerQueueTaskMutation,
  CreateMessengerQueueTaskMutationVariables
> &
  TChildProps;
export function withCreateMessengerQueueTask<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateMessengerQueueTaskMutation,
    CreateMessengerQueueTaskMutationVariables,
    CreateMessengerQueueTaskProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateMessengerQueueTaskMutation,
    CreateMessengerQueueTaskMutationVariables,
    CreateMessengerQueueTaskProps<TChildProps>
  >(CreateMessengerQueueTaskDocument, {
    alias: 'createMessengerQueueTask',
    ...operationOptions,
  });
}

/**
 * __useCreateMessengerQueueTaskMutation__
 *
 * To run a mutation, you first call `useCreateMessengerQueueTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessengerQueueTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessengerQueueTaskMutation, { data, loading, error }] = useCreateMessengerQueueTaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMessengerQueueTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateMessengerQueueTaskMutation,
    CreateMessengerQueueTaskMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateMessengerQueueTaskMutation, CreateMessengerQueueTaskMutationVariables>(
    CreateMessengerQueueTaskDocument,
    baseOptions
  );
}
export type CreateMessengerQueueTaskMutationHookResult = ReturnType<typeof useCreateMessengerQueueTaskMutation>;
export type CreateMessengerQueueTaskMutationResult = ApolloReactCommon.MutationResult<CreateMessengerQueueTaskMutation>;
export type CreateMessengerQueueTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateMessengerQueueTaskMutation,
  CreateMessengerQueueTaskMutationVariables
>;
export const CreateMessengerTemplateDocument = gql`
  mutation createMessengerTemplate($data: MessengerTemplateCreateInput!) {
    createMessengerTemplate(data: $data) {
      ...messengerTemplate
    }
  }
  ${MessengerTemplateFragmentDoc}
`;
export type CreateMessengerTemplateMutationFn = ApolloReactCommon.MutationFunction<
  CreateMessengerTemplateMutation,
  CreateMessengerTemplateMutationVariables
>;
export type CreateMessengerTemplateComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateMessengerTemplateMutation,
    CreateMessengerTemplateMutationVariables
  >,
  'mutation'
>;

export const CreateMessengerTemplateComponent = (props: CreateMessengerTemplateComponentProps) => (
  <ApolloReactComponents.Mutation<CreateMessengerTemplateMutation, CreateMessengerTemplateMutationVariables>
    mutation={CreateMessengerTemplateDocument}
    {...props}
  />
);

export type CreateMessengerTemplateProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateMessengerTemplateMutation,
  CreateMessengerTemplateMutationVariables
> &
  TChildProps;
export function withCreateMessengerTemplate<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateMessengerTemplateMutation,
    CreateMessengerTemplateMutationVariables,
    CreateMessengerTemplateProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateMessengerTemplateMutation,
    CreateMessengerTemplateMutationVariables,
    CreateMessengerTemplateProps<TChildProps>
  >(CreateMessengerTemplateDocument, {
    alias: 'createMessengerTemplate',
    ...operationOptions,
  });
}

/**
 * __useCreateMessengerTemplateMutation__
 *
 * To run a mutation, you first call `useCreateMessengerTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessengerTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessengerTemplateMutation, { data, loading, error }] = useCreateMessengerTemplateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMessengerTemplateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateMessengerTemplateMutation,
    CreateMessengerTemplateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateMessengerTemplateMutation, CreateMessengerTemplateMutationVariables>(
    CreateMessengerTemplateDocument,
    baseOptions
  );
}
export type CreateMessengerTemplateMutationHookResult = ReturnType<typeof useCreateMessengerTemplateMutation>;
export type CreateMessengerTemplateMutationResult = ApolloReactCommon.MutationResult<CreateMessengerTemplateMutation>;
export type CreateMessengerTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateMessengerTemplateMutation,
  CreateMessengerTemplateMutationVariables
>;
export const CreateMessengerTransportDocument = gql`
  mutation createMessengerTransport($data: MessengerTransportCreateInput!) {
    createMessengerTransport(data: $data) {
      ...messengerTransport
    }
  }
  ${MessengerTransportFragmentDoc}
`;
export type CreateMessengerTransportMutationFn = ApolloReactCommon.MutationFunction<
  CreateMessengerTransportMutation,
  CreateMessengerTransportMutationVariables
>;
export type CreateMessengerTransportComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateMessengerTransportMutation,
    CreateMessengerTransportMutationVariables
  >,
  'mutation'
>;

export const CreateMessengerTransportComponent = (props: CreateMessengerTransportComponentProps) => (
  <ApolloReactComponents.Mutation<CreateMessengerTransportMutation, CreateMessengerTransportMutationVariables>
    mutation={CreateMessengerTransportDocument}
    {...props}
  />
);

export type CreateMessengerTransportProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateMessengerTransportMutation,
  CreateMessengerTransportMutationVariables
> &
  TChildProps;
export function withCreateMessengerTransport<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateMessengerTransportMutation,
    CreateMessengerTransportMutationVariables,
    CreateMessengerTransportProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateMessengerTransportMutation,
    CreateMessengerTransportMutationVariables,
    CreateMessengerTransportProps<TChildProps>
  >(CreateMessengerTransportDocument, {
    alias: 'createMessengerTransport',
    ...operationOptions,
  });
}

/**
 * __useCreateMessengerTransportMutation__
 *
 * To run a mutation, you first call `useCreateMessengerTransportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessengerTransportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessengerTransportMutation, { data, loading, error }] = useCreateMessengerTransportMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMessengerTransportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateMessengerTransportMutation,
    CreateMessengerTransportMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateMessengerTransportMutation, CreateMessengerTransportMutationVariables>(
    CreateMessengerTransportDocument,
    baseOptions
  );
}
export type CreateMessengerTransportMutationHookResult = ReturnType<typeof useCreateMessengerTransportMutation>;
export type CreateMessengerTransportMutationResult = ApolloReactCommon.MutationResult<CreateMessengerTransportMutation>;
export type CreateMessengerTransportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateMessengerTransportMutation,
  CreateMessengerTransportMutationVariables
>;
export const CreatePaymentAccountDocument = gql`
  mutation createPaymentAccount($data: PaymentAccountCreateInput!) {
    createPaymentAccount(data: $data) {
      ...paymentAccount
    }
  }
  ${PaymentAccountFragmentDoc}
`;
export type CreatePaymentAccountMutationFn = ApolloReactCommon.MutationFunction<
  CreatePaymentAccountMutation,
  CreatePaymentAccountMutationVariables
>;
export type CreatePaymentAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreatePaymentAccountMutation, CreatePaymentAccountMutationVariables>,
  'mutation'
>;

export const CreatePaymentAccountComponent = (props: CreatePaymentAccountComponentProps) => (
  <ApolloReactComponents.Mutation<CreatePaymentAccountMutation, CreatePaymentAccountMutationVariables>
    mutation={CreatePaymentAccountDocument}
    {...props}
  />
);

export type CreatePaymentAccountProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreatePaymentAccountMutation,
  CreatePaymentAccountMutationVariables
> &
  TChildProps;
export function withCreatePaymentAccount<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreatePaymentAccountMutation,
    CreatePaymentAccountMutationVariables,
    CreatePaymentAccountProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreatePaymentAccountMutation,
    CreatePaymentAccountMutationVariables,
    CreatePaymentAccountProps<TChildProps>
  >(CreatePaymentAccountDocument, {
    alias: 'createPaymentAccount',
    ...operationOptions,
  });
}

/**
 * __useCreatePaymentAccountMutation__
 *
 * To run a mutation, you first call `useCreatePaymentAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentAccountMutation, { data, loading, error }] = useCreatePaymentAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePaymentAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePaymentAccountMutation,
    CreatePaymentAccountMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreatePaymentAccountMutation, CreatePaymentAccountMutationVariables>(
    CreatePaymentAccountDocument,
    baseOptions
  );
}
export type CreatePaymentAccountMutationHookResult = ReturnType<typeof useCreatePaymentAccountMutation>;
export type CreatePaymentAccountMutationResult = ApolloReactCommon.MutationResult<CreatePaymentAccountMutation>;
export type CreatePaymentAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePaymentAccountMutation,
  CreatePaymentAccountMutationVariables
>;
export const CreatePaymentAccountByApplicationCodeDocument = gql`
  mutation createPaymentAccountByApplicationCode($userId: Int!, $partnerId: Int!, $providerCode: String!) {
    createPaymentAccountByApplicationCode(userId: $userId, partnerId: $partnerId, providerCode: $providerCode) {
      ...paymentAccount
    }
  }
  ${PaymentAccountFragmentDoc}
`;
export type CreatePaymentAccountByApplicationCodeMutationFn = ApolloReactCommon.MutationFunction<
  CreatePaymentAccountByApplicationCodeMutation,
  CreatePaymentAccountByApplicationCodeMutationVariables
>;
export type CreatePaymentAccountByApplicationCodeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreatePaymentAccountByApplicationCodeMutation,
    CreatePaymentAccountByApplicationCodeMutationVariables
  >,
  'mutation'
>;

export const CreatePaymentAccountByApplicationCodeComponent = (
  props: CreatePaymentAccountByApplicationCodeComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreatePaymentAccountByApplicationCodeMutation,
    CreatePaymentAccountByApplicationCodeMutationVariables
  >
    mutation={CreatePaymentAccountByApplicationCodeDocument}
    {...props}
  />
);

export type CreatePaymentAccountByApplicationCodeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreatePaymentAccountByApplicationCodeMutation,
  CreatePaymentAccountByApplicationCodeMutationVariables
> &
  TChildProps;
export function withCreatePaymentAccountByApplicationCode<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreatePaymentAccountByApplicationCodeMutation,
    CreatePaymentAccountByApplicationCodeMutationVariables,
    CreatePaymentAccountByApplicationCodeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreatePaymentAccountByApplicationCodeMutation,
    CreatePaymentAccountByApplicationCodeMutationVariables,
    CreatePaymentAccountByApplicationCodeProps<TChildProps>
  >(CreatePaymentAccountByApplicationCodeDocument, {
    alias: 'createPaymentAccountByApplicationCode',
    ...operationOptions,
  });
}

/**
 * __useCreatePaymentAccountByApplicationCodeMutation__
 *
 * To run a mutation, you first call `useCreatePaymentAccountByApplicationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentAccountByApplicationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentAccountByApplicationCodeMutation, { data, loading, error }] = useCreatePaymentAccountByApplicationCodeMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      partnerId: // value for 'partnerId'
 *      providerCode: // value for 'providerCode'
 *   },
 * });
 */
export function useCreatePaymentAccountByApplicationCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePaymentAccountByApplicationCodeMutation,
    CreatePaymentAccountByApplicationCodeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreatePaymentAccountByApplicationCodeMutation,
    CreatePaymentAccountByApplicationCodeMutationVariables
  >(CreatePaymentAccountByApplicationCodeDocument, baseOptions);
}
export type CreatePaymentAccountByApplicationCodeMutationHookResult = ReturnType<
  typeof useCreatePaymentAccountByApplicationCodeMutation
>;
export type CreatePaymentAccountByApplicationCodeMutationResult = ApolloReactCommon.MutationResult<
  CreatePaymentAccountByApplicationCodeMutation
>;
export type CreatePaymentAccountByApplicationCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePaymentAccountByApplicationCodeMutation,
  CreatePaymentAccountByApplicationCodeMutationVariables
>;
export const CreatePaymentCurrencyDocument = gql`
  mutation createPaymentCurrency($data: PaymentCurrencyCreateInput!) {
    createPaymentCurrency(data: $data) {
      ...paymentCurrency
    }
  }
  ${PaymentCurrencyFragmentDoc}
`;
export type CreatePaymentCurrencyMutationFn = ApolloReactCommon.MutationFunction<
  CreatePaymentCurrencyMutation,
  CreatePaymentCurrencyMutationVariables
>;
export type CreatePaymentCurrencyComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreatePaymentCurrencyMutation, CreatePaymentCurrencyMutationVariables>,
  'mutation'
>;

export const CreatePaymentCurrencyComponent = (props: CreatePaymentCurrencyComponentProps) => (
  <ApolloReactComponents.Mutation<CreatePaymentCurrencyMutation, CreatePaymentCurrencyMutationVariables>
    mutation={CreatePaymentCurrencyDocument}
    {...props}
  />
);

export type CreatePaymentCurrencyProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreatePaymentCurrencyMutation,
  CreatePaymentCurrencyMutationVariables
> &
  TChildProps;
export function withCreatePaymentCurrency<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreatePaymentCurrencyMutation,
    CreatePaymentCurrencyMutationVariables,
    CreatePaymentCurrencyProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreatePaymentCurrencyMutation,
    CreatePaymentCurrencyMutationVariables,
    CreatePaymentCurrencyProps<TChildProps>
  >(CreatePaymentCurrencyDocument, {
    alias: 'createPaymentCurrency',
    ...operationOptions,
  });
}

/**
 * __useCreatePaymentCurrencyMutation__
 *
 * To run a mutation, you first call `useCreatePaymentCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentCurrencyMutation, { data, loading, error }] = useCreatePaymentCurrencyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePaymentCurrencyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePaymentCurrencyMutation,
    CreatePaymentCurrencyMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreatePaymentCurrencyMutation, CreatePaymentCurrencyMutationVariables>(
    CreatePaymentCurrencyDocument,
    baseOptions
  );
}
export type CreatePaymentCurrencyMutationHookResult = ReturnType<typeof useCreatePaymentCurrencyMutation>;
export type CreatePaymentCurrencyMutationResult = ApolloReactCommon.MutationResult<CreatePaymentCurrencyMutation>;
export type CreatePaymentCurrencyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePaymentCurrencyMutation,
  CreatePaymentCurrencyMutationVariables
>;
export const CreatePaymentProviderDocument = gql`
  mutation createPaymentProvider($data: PaymentProviderCreateInput!) {
    createPaymentProvider(data: $data) {
      ...paymentProvider
    }
  }
  ${PaymentProviderFragmentDoc}
`;
export type CreatePaymentProviderMutationFn = ApolloReactCommon.MutationFunction<
  CreatePaymentProviderMutation,
  CreatePaymentProviderMutationVariables
>;
export type CreatePaymentProviderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreatePaymentProviderMutation, CreatePaymentProviderMutationVariables>,
  'mutation'
>;

export const CreatePaymentProviderComponent = (props: CreatePaymentProviderComponentProps) => (
  <ApolloReactComponents.Mutation<CreatePaymentProviderMutation, CreatePaymentProviderMutationVariables>
    mutation={CreatePaymentProviderDocument}
    {...props}
  />
);

export type CreatePaymentProviderProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreatePaymentProviderMutation,
  CreatePaymentProviderMutationVariables
> &
  TChildProps;
export function withCreatePaymentProvider<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreatePaymentProviderMutation,
    CreatePaymentProviderMutationVariables,
    CreatePaymentProviderProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreatePaymentProviderMutation,
    CreatePaymentProviderMutationVariables,
    CreatePaymentProviderProps<TChildProps>
  >(CreatePaymentProviderDocument, {
    alias: 'createPaymentProvider',
    ...operationOptions,
  });
}

/**
 * __useCreatePaymentProviderMutation__
 *
 * To run a mutation, you first call `useCreatePaymentProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentProviderMutation, { data, loading, error }] = useCreatePaymentProviderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePaymentProviderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePaymentProviderMutation,
    CreatePaymentProviderMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreatePaymentProviderMutation, CreatePaymentProviderMutationVariables>(
    CreatePaymentProviderDocument,
    baseOptions
  );
}
export type CreatePaymentProviderMutationHookResult = ReturnType<typeof useCreatePaymentProviderMutation>;
export type CreatePaymentProviderMutationResult = ApolloReactCommon.MutationResult<CreatePaymentProviderMutation>;
export type CreatePaymentProviderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePaymentProviderMutation,
  CreatePaymentProviderMutationVariables
>;
export const CreatePaymentTransactionDocument = gql`
  mutation createPaymentTransaction($data: PaymentTransactionCreateInput!) {
    createPaymentTransaction(data: $data) {
      ...paymentTransaction
    }
  }
  ${PaymentTransactionFragmentDoc}
`;
export type CreatePaymentTransactionMutationFn = ApolloReactCommon.MutationFunction<
  CreatePaymentTransactionMutation,
  CreatePaymentTransactionMutationVariables
>;
export type CreatePaymentTransactionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreatePaymentTransactionMutation,
    CreatePaymentTransactionMutationVariables
  >,
  'mutation'
>;

export const CreatePaymentTransactionComponent = (props: CreatePaymentTransactionComponentProps) => (
  <ApolloReactComponents.Mutation<CreatePaymentTransactionMutation, CreatePaymentTransactionMutationVariables>
    mutation={CreatePaymentTransactionDocument}
    {...props}
  />
);

export type CreatePaymentTransactionProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreatePaymentTransactionMutation,
  CreatePaymentTransactionMutationVariables
> &
  TChildProps;
export function withCreatePaymentTransaction<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreatePaymentTransactionMutation,
    CreatePaymentTransactionMutationVariables,
    CreatePaymentTransactionProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreatePaymentTransactionMutation,
    CreatePaymentTransactionMutationVariables,
    CreatePaymentTransactionProps<TChildProps>
  >(CreatePaymentTransactionDocument, {
    alias: 'createPaymentTransaction',
    ...operationOptions,
  });
}

/**
 * __useCreatePaymentTransactionMutation__
 *
 * To run a mutation, you first call `useCreatePaymentTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentTransactionMutation, { data, loading, error }] = useCreatePaymentTransactionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePaymentTransactionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePaymentTransactionMutation,
    CreatePaymentTransactionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreatePaymentTransactionMutation, CreatePaymentTransactionMutationVariables>(
    CreatePaymentTransactionDocument,
    baseOptions
  );
}
export type CreatePaymentTransactionMutationHookResult = ReturnType<typeof useCreatePaymentTransactionMutation>;
export type CreatePaymentTransactionMutationResult = ApolloReactCommon.MutationResult<CreatePaymentTransactionMutation>;
export type CreatePaymentTransactionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePaymentTransactionMutation,
  CreatePaymentTransactionMutationVariables
>;
export const CreateSystemPrinterDocument = gql`
  mutation createSystemPrinter($data: SystemPrinterCreateInput!) {
    createSystemPrinter(data: $data) {
      ...systemPrinter
    }
  }
  ${SystemPrinterFragmentDoc}
`;
export type CreateSystemPrinterMutationFn = ApolloReactCommon.MutationFunction<
  CreateSystemPrinterMutation,
  CreateSystemPrinterMutationVariables
>;
export type CreateSystemPrinterComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateSystemPrinterMutation, CreateSystemPrinterMutationVariables>,
  'mutation'
>;

export const CreateSystemPrinterComponent = (props: CreateSystemPrinterComponentProps) => (
  <ApolloReactComponents.Mutation<CreateSystemPrinterMutation, CreateSystemPrinterMutationVariables>
    mutation={CreateSystemPrinterDocument}
    {...props}
  />
);

export type CreateSystemPrinterProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateSystemPrinterMutation,
  CreateSystemPrinterMutationVariables
> &
  TChildProps;
export function withCreateSystemPrinter<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateSystemPrinterMutation,
    CreateSystemPrinterMutationVariables,
    CreateSystemPrinterProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateSystemPrinterMutation,
    CreateSystemPrinterMutationVariables,
    CreateSystemPrinterProps<TChildProps>
  >(CreateSystemPrinterDocument, {
    alias: 'createSystemPrinter',
    ...operationOptions,
  });
}

/**
 * __useCreateSystemPrinterMutation__
 *
 * To run a mutation, you first call `useCreateSystemPrinterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSystemPrinterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSystemPrinterMutation, { data, loading, error }] = useCreateSystemPrinterMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSystemPrinterMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSystemPrinterMutation, CreateSystemPrinterMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateSystemPrinterMutation, CreateSystemPrinterMutationVariables>(
    CreateSystemPrinterDocument,
    baseOptions
  );
}
export type CreateSystemPrinterMutationHookResult = ReturnType<typeof useCreateSystemPrinterMutation>;
export type CreateSystemPrinterMutationResult = ApolloReactCommon.MutationResult<CreateSystemPrinterMutation>;
export type CreateSystemPrinterMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateSystemPrinterMutation,
  CreateSystemPrinterMutationVariables
>;
export const CreateSystemTranslationDocument = gql`
  mutation createSystemTranslation($data: SystemTranslationCreateInput!) {
    createSystemTranslation(data: $data) {
      ...systemTranslation
    }
  }
  ${SystemTranslationFragmentDoc}
`;
export type CreateSystemTranslationMutationFn = ApolloReactCommon.MutationFunction<
  CreateSystemTranslationMutation,
  CreateSystemTranslationMutationVariables
>;
export type CreateSystemTranslationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateSystemTranslationMutation,
    CreateSystemTranslationMutationVariables
  >,
  'mutation'
>;

export const CreateSystemTranslationComponent = (props: CreateSystemTranslationComponentProps) => (
  <ApolloReactComponents.Mutation<CreateSystemTranslationMutation, CreateSystemTranslationMutationVariables>
    mutation={CreateSystemTranslationDocument}
    {...props}
  />
);

export type CreateSystemTranslationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateSystemTranslationMutation,
  CreateSystemTranslationMutationVariables
> &
  TChildProps;
export function withCreateSystemTranslation<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateSystemTranslationMutation,
    CreateSystemTranslationMutationVariables,
    CreateSystemTranslationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateSystemTranslationMutation,
    CreateSystemTranslationMutationVariables,
    CreateSystemTranslationProps<TChildProps>
  >(CreateSystemTranslationDocument, {
    alias: 'createSystemTranslation',
    ...operationOptions,
  });
}

/**
 * __useCreateSystemTranslationMutation__
 *
 * To run a mutation, you first call `useCreateSystemTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSystemTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSystemTranslationMutation, { data, loading, error }] = useCreateSystemTranslationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSystemTranslationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateSystemTranslationMutation,
    CreateSystemTranslationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateSystemTranslationMutation, CreateSystemTranslationMutationVariables>(
    CreateSystemTranslationDocument,
    baseOptions
  );
}
export type CreateSystemTranslationMutationHookResult = ReturnType<typeof useCreateSystemTranslationMutation>;
export type CreateSystemTranslationMutationResult = ApolloReactCommon.MutationResult<CreateSystemTranslationMutation>;
export type CreateSystemTranslationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateSystemTranslationMutation,
  CreateSystemTranslationMutationVariables
>;
export const CreateSystemUserDocument = gql`
  mutation createSystemUser($data: CreateSystemUserInput!) {
    createSystemUser(data: $data) {
      ...systemUser
    }
  }
  ${SystemUserFragmentDoc}
`;
export type CreateSystemUserMutationFn = ApolloReactCommon.MutationFunction<
  CreateSystemUserMutation,
  CreateSystemUserMutationVariables
>;
export type CreateSystemUserComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateSystemUserMutation, CreateSystemUserMutationVariables>,
  'mutation'
>;

export const CreateSystemUserComponent = (props: CreateSystemUserComponentProps) => (
  <ApolloReactComponents.Mutation<CreateSystemUserMutation, CreateSystemUserMutationVariables>
    mutation={CreateSystemUserDocument}
    {...props}
  />
);

export type CreateSystemUserProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateSystemUserMutation,
  CreateSystemUserMutationVariables
> &
  TChildProps;
export function withCreateSystemUser<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateSystemUserMutation,
    CreateSystemUserMutationVariables,
    CreateSystemUserProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateSystemUserMutation,
    CreateSystemUserMutationVariables,
    CreateSystemUserProps<TChildProps>
  >(CreateSystemUserDocument, {
    alias: 'createSystemUser',
    ...operationOptions,
  });
}

/**
 * __useCreateSystemUserMutation__
 *
 * To run a mutation, you first call `useCreateSystemUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSystemUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSystemUserMutation, { data, loading, error }] = useCreateSystemUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSystemUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSystemUserMutation, CreateSystemUserMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateSystemUserMutation, CreateSystemUserMutationVariables>(
    CreateSystemUserDocument,
    baseOptions
  );
}
export type CreateSystemUserMutationHookResult = ReturnType<typeof useCreateSystemUserMutation>;
export type CreateSystemUserMutationResult = ApolloReactCommon.MutationResult<CreateSystemUserMutation>;
export type CreateSystemUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateSystemUserMutation,
  CreateSystemUserMutationVariables
>;
export const CreateSystemWebHookDocument = gql`
  mutation createSystemWebHook($data: SystemWebHookCreateInput!) {
    createSystemWebHook(data: $data) {
      ...systemWebHook
    }
  }
  ${SystemWebHookFragmentDoc}
`;
export type CreateSystemWebHookMutationFn = ApolloReactCommon.MutationFunction<
  CreateSystemWebHookMutation,
  CreateSystemWebHookMutationVariables
>;
export type CreateSystemWebHookComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateSystemWebHookMutation, CreateSystemWebHookMutationVariables>,
  'mutation'
>;

export const CreateSystemWebHookComponent = (props: CreateSystemWebHookComponentProps) => (
  <ApolloReactComponents.Mutation<CreateSystemWebHookMutation, CreateSystemWebHookMutationVariables>
    mutation={CreateSystemWebHookDocument}
    {...props}
  />
);

export type CreateSystemWebHookProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateSystemWebHookMutation,
  CreateSystemWebHookMutationVariables
> &
  TChildProps;
export function withCreateSystemWebHook<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateSystemWebHookMutation,
    CreateSystemWebHookMutationVariables,
    CreateSystemWebHookProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateSystemWebHookMutation,
    CreateSystemWebHookMutationVariables,
    CreateSystemWebHookProps<TChildProps>
  >(CreateSystemWebHookDocument, {
    alias: 'createSystemWebHook',
    ...operationOptions,
  });
}

/**
 * __useCreateSystemWebHookMutation__
 *
 * To run a mutation, you first call `useCreateSystemWebHookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSystemWebHookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSystemWebHookMutation, { data, loading, error }] = useCreateSystemWebHookMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSystemWebHookMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSystemWebHookMutation, CreateSystemWebHookMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateSystemWebHookMutation, CreateSystemWebHookMutationVariables>(
    CreateSystemWebHookDocument,
    baseOptions
  );
}
export type CreateSystemWebHookMutationHookResult = ReturnType<typeof useCreateSystemWebHookMutation>;
export type CreateSystemWebHookMutationResult = ApolloReactCommon.MutationResult<CreateSystemWebHookMutation>;
export type CreateSystemWebHookMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateSystemWebHookMutation,
  CreateSystemWebHookMutationVariables
>;
export const CreateTemplateApplePassDocument = gql`
  mutation createTemplateApplePass($data: TemplateApplePassCreateInput!) {
    createTemplateApplePass(data: $data) {
      ...templateApplePass
    }
  }
  ${TemplateApplePassFragmentDoc}
`;
export type CreateTemplateApplePassMutationFn = ApolloReactCommon.MutationFunction<
  CreateTemplateApplePassMutation,
  CreateTemplateApplePassMutationVariables
>;
export type CreateTemplateApplePassComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateTemplateApplePassMutation,
    CreateTemplateApplePassMutationVariables
  >,
  'mutation'
>;

export const CreateTemplateApplePassComponent = (props: CreateTemplateApplePassComponentProps) => (
  <ApolloReactComponents.Mutation<CreateTemplateApplePassMutation, CreateTemplateApplePassMutationVariables>
    mutation={CreateTemplateApplePassDocument}
    {...props}
  />
);

export type CreateTemplateApplePassProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateTemplateApplePassMutation,
  CreateTemplateApplePassMutationVariables
> &
  TChildProps;
export function withCreateTemplateApplePass<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateTemplateApplePassMutation,
    CreateTemplateApplePassMutationVariables,
    CreateTemplateApplePassProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateTemplateApplePassMutation,
    CreateTemplateApplePassMutationVariables,
    CreateTemplateApplePassProps<TChildProps>
  >(CreateTemplateApplePassDocument, {
    alias: 'createTemplateApplePass',
    ...operationOptions,
  });
}

/**
 * __useCreateTemplateApplePassMutation__
 *
 * To run a mutation, you first call `useCreateTemplateApplePassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateApplePassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateApplePassMutation, { data, loading, error }] = useCreateTemplateApplePassMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTemplateApplePassMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTemplateApplePassMutation,
    CreateTemplateApplePassMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateTemplateApplePassMutation, CreateTemplateApplePassMutationVariables>(
    CreateTemplateApplePassDocument,
    baseOptions
  );
}
export type CreateTemplateApplePassMutationHookResult = ReturnType<typeof useCreateTemplateApplePassMutation>;
export type CreateTemplateApplePassMutationResult = ApolloReactCommon.MutationResult<CreateTemplateApplePassMutation>;
export type CreateTemplateApplePassMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTemplateApplePassMutation,
  CreateTemplateApplePassMutationVariables
>;
export const CreateTemplatePdfDocument = gql`
  mutation createTemplatePDF($data: TemplatePDFCreateInput!) {
    createTemplatePDF(data: $data) {
      ...templatePDF
    }
  }
  ${TemplatePdfFragmentDoc}
`;
export type CreateTemplatePdfMutationFn = ApolloReactCommon.MutationFunction<
  CreateTemplatePdfMutation,
  CreateTemplatePdfMutationVariables
>;
export type CreateTemplatePdfComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateTemplatePdfMutation, CreateTemplatePdfMutationVariables>,
  'mutation'
>;

export const CreateTemplatePdfComponent = (props: CreateTemplatePdfComponentProps) => (
  <ApolloReactComponents.Mutation<CreateTemplatePdfMutation, CreateTemplatePdfMutationVariables>
    mutation={CreateTemplatePdfDocument}
    {...props}
  />
);

export type CreateTemplatePdfProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateTemplatePdfMutation,
  CreateTemplatePdfMutationVariables
> &
  TChildProps;
export function withCreateTemplatePdf<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateTemplatePdfMutation,
    CreateTemplatePdfMutationVariables,
    CreateTemplatePdfProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateTemplatePdfMutation,
    CreateTemplatePdfMutationVariables,
    CreateTemplatePdfProps<TChildProps>
  >(CreateTemplatePdfDocument, {
    alias: 'createTemplatePdf',
    ...operationOptions,
  });
}

/**
 * __useCreateTemplatePdfMutation__
 *
 * To run a mutation, you first call `useCreateTemplatePdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplatePdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplatePdfMutation, { data, loading, error }] = useCreateTemplatePdfMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTemplatePdfMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTemplatePdfMutation, CreateTemplatePdfMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateTemplatePdfMutation, CreateTemplatePdfMutationVariables>(
    CreateTemplatePdfDocument,
    baseOptions
  );
}
export type CreateTemplatePdfMutationHookResult = ReturnType<typeof useCreateTemplatePdfMutation>;
export type CreateTemplatePdfMutationResult = ApolloReactCommon.MutationResult<CreateTemplatePdfMutation>;
export type CreateTemplatePdfMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTemplatePdfMutation,
  CreateTemplatePdfMutationVariables
>;
export const CreateTournamentDocument = gql`
  mutation createTournament($data: TournamentCreateInput!) {
    createTournament(data: $data) {
      ...tournamentFull
    }
  }
  ${TournamentFullFragmentDoc}
`;
export type CreateTournamentMutationFn = ApolloReactCommon.MutationFunction<
  CreateTournamentMutation,
  CreateTournamentMutationVariables
>;
export type CreateTournamentComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateTournamentMutation, CreateTournamentMutationVariables>,
  'mutation'
>;

export const CreateTournamentComponent = (props: CreateTournamentComponentProps) => (
  <ApolloReactComponents.Mutation<CreateTournamentMutation, CreateTournamentMutationVariables>
    mutation={CreateTournamentDocument}
    {...props}
  />
);

export type CreateTournamentProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateTournamentMutation,
  CreateTournamentMutationVariables
> &
  TChildProps;
export function withCreateTournament<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateTournamentMutation,
    CreateTournamentMutationVariables,
    CreateTournamentProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateTournamentMutation,
    CreateTournamentMutationVariables,
    CreateTournamentProps<TChildProps>
  >(CreateTournamentDocument, {
    alias: 'createTournament',
    ...operationOptions,
  });
}

/**
 * __useCreateTournamentMutation__
 *
 * To run a mutation, you first call `useCreateTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentMutation, { data, loading, error }] = useCreateTournamentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTournamentMutation, CreateTournamentMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateTournamentMutation, CreateTournamentMutationVariables>(
    CreateTournamentDocument,
    baseOptions
  );
}
export type CreateTournamentMutationHookResult = ReturnType<typeof useCreateTournamentMutation>;
export type CreateTournamentMutationResult = ApolloReactCommon.MutationResult<CreateTournamentMutation>;
export type CreateTournamentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTournamentMutation,
  CreateTournamentMutationVariables
>;
export const CreateTournamentDisqualificationDocument = gql`
  mutation createTournamentDisqualification($data: TournamentDisqualificationCreateInput!) {
    createTournamentDisqualification(data: $data) {
      ...tournamentDisqualification
    }
  }
  ${TournamentDisqualificationFragmentDoc}
`;
export type CreateTournamentDisqualificationMutationFn = ApolloReactCommon.MutationFunction<
  CreateTournamentDisqualificationMutation,
  CreateTournamentDisqualificationMutationVariables
>;
export type CreateTournamentDisqualificationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateTournamentDisqualificationMutation,
    CreateTournamentDisqualificationMutationVariables
  >,
  'mutation'
>;

export const CreateTournamentDisqualificationComponent = (props: CreateTournamentDisqualificationComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateTournamentDisqualificationMutation,
    CreateTournamentDisqualificationMutationVariables
  >
    mutation={CreateTournamentDisqualificationDocument}
    {...props}
  />
);

export type CreateTournamentDisqualificationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateTournamentDisqualificationMutation,
  CreateTournamentDisqualificationMutationVariables
> &
  TChildProps;
export function withCreateTournamentDisqualification<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateTournamentDisqualificationMutation,
    CreateTournamentDisqualificationMutationVariables,
    CreateTournamentDisqualificationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateTournamentDisqualificationMutation,
    CreateTournamentDisqualificationMutationVariables,
    CreateTournamentDisqualificationProps<TChildProps>
  >(CreateTournamentDisqualificationDocument, {
    alias: 'createTournamentDisqualification',
    ...operationOptions,
  });
}

/**
 * __useCreateTournamentDisqualificationMutation__
 *
 * To run a mutation, you first call `useCreateTournamentDisqualificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentDisqualificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentDisqualificationMutation, { data, loading, error }] = useCreateTournamentDisqualificationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentDisqualificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentDisqualificationMutation,
    CreateTournamentDisqualificationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateTournamentDisqualificationMutation,
    CreateTournamentDisqualificationMutationVariables
  >(CreateTournamentDisqualificationDocument, baseOptions);
}
export type CreateTournamentDisqualificationMutationHookResult = ReturnType<
  typeof useCreateTournamentDisqualificationMutation
>;
export type CreateTournamentDisqualificationMutationResult = ApolloReactCommon.MutationResult<
  CreateTournamentDisqualificationMutation
>;
export type CreateTournamentDisqualificationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTournamentDisqualificationMutation,
  CreateTournamentDisqualificationMutationVariables
>;
export const CreateTournamentMatchDocument = gql`
  mutation createTournamentMatch($data: TournamentMatchCreateInput!) {
    createTournamentMatch(data: $data) {
      ...tournamentMatch
    }
  }
  ${TournamentMatchFragmentDoc}
`;
export type CreateTournamentMatchMutationFn = ApolloReactCommon.MutationFunction<
  CreateTournamentMatchMutation,
  CreateTournamentMatchMutationVariables
>;
export type CreateTournamentMatchComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateTournamentMatchMutation, CreateTournamentMatchMutationVariables>,
  'mutation'
>;

export const CreateTournamentMatchComponent = (props: CreateTournamentMatchComponentProps) => (
  <ApolloReactComponents.Mutation<CreateTournamentMatchMutation, CreateTournamentMatchMutationVariables>
    mutation={CreateTournamentMatchDocument}
    {...props}
  />
);

export type CreateTournamentMatchProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateTournamentMatchMutation,
  CreateTournamentMatchMutationVariables
> &
  TChildProps;
export function withCreateTournamentMatch<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateTournamentMatchMutation,
    CreateTournamentMatchMutationVariables,
    CreateTournamentMatchProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateTournamentMatchMutation,
    CreateTournamentMatchMutationVariables,
    CreateTournamentMatchProps<TChildProps>
  >(CreateTournamentMatchDocument, {
    alias: 'createTournamentMatch',
    ...operationOptions,
  });
}

/**
 * __useCreateTournamentMatchMutation__
 *
 * To run a mutation, you first call `useCreateTournamentMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentMatchMutation, { data, loading, error }] = useCreateTournamentMatchMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentMatchMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentMatchMutation,
    CreateTournamentMatchMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateTournamentMatchMutation, CreateTournamentMatchMutationVariables>(
    CreateTournamentMatchDocument,
    baseOptions
  );
}
export type CreateTournamentMatchMutationHookResult = ReturnType<typeof useCreateTournamentMatchMutation>;
export type CreateTournamentMatchMutationResult = ApolloReactCommon.MutationResult<CreateTournamentMatchMutation>;
export type CreateTournamentMatchMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTournamentMatchMutation,
  CreateTournamentMatchMutationVariables
>;
export const CreateTournamentMatchEventDocument = gql`
  mutation createTournamentMatchEvent($data: TournamentMatchEventCreateInput!) {
    createTournamentMatchEvent(data: $data) {
      ...tournamentMatchEvent
    }
  }
  ${TournamentMatchEventFragmentDoc}
`;
export type CreateTournamentMatchEventMutationFn = ApolloReactCommon.MutationFunction<
  CreateTournamentMatchEventMutation,
  CreateTournamentMatchEventMutationVariables
>;
export type CreateTournamentMatchEventComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateTournamentMatchEventMutation,
    CreateTournamentMatchEventMutationVariables
  >,
  'mutation'
>;

export const CreateTournamentMatchEventComponent = (props: CreateTournamentMatchEventComponentProps) => (
  <ApolloReactComponents.Mutation<CreateTournamentMatchEventMutation, CreateTournamentMatchEventMutationVariables>
    mutation={CreateTournamentMatchEventDocument}
    {...props}
  />
);

export type CreateTournamentMatchEventProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateTournamentMatchEventMutation,
  CreateTournamentMatchEventMutationVariables
> &
  TChildProps;
export function withCreateTournamentMatchEvent<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateTournamentMatchEventMutation,
    CreateTournamentMatchEventMutationVariables,
    CreateTournamentMatchEventProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateTournamentMatchEventMutation,
    CreateTournamentMatchEventMutationVariables,
    CreateTournamentMatchEventProps<TChildProps>
  >(CreateTournamentMatchEventDocument, {
    alias: 'createTournamentMatchEvent',
    ...operationOptions,
  });
}

/**
 * __useCreateTournamentMatchEventMutation__
 *
 * To run a mutation, you first call `useCreateTournamentMatchEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentMatchEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentMatchEventMutation, { data, loading, error }] = useCreateTournamentMatchEventMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentMatchEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentMatchEventMutation,
    CreateTournamentMatchEventMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateTournamentMatchEventMutation, CreateTournamentMatchEventMutationVariables>(
    CreateTournamentMatchEventDocument,
    baseOptions
  );
}
export type CreateTournamentMatchEventMutationHookResult = ReturnType<typeof useCreateTournamentMatchEventMutation>;
export type CreateTournamentMatchEventMutationResult = ApolloReactCommon.MutationResult<
  CreateTournamentMatchEventMutation
>;
export type CreateTournamentMatchEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTournamentMatchEventMutation,
  CreateTournamentMatchEventMutationVariables
>;
export const CreateTournamentMatchLineUpDocument = gql`
  mutation createTournamentMatchLineUp($data: TournamentMatchLineUpCreateInput!) {
    createTournamentMatchLineUp(data: $data) {
      ...tournamentMatchLineUp
    }
  }
  ${TournamentMatchLineUpFragmentDoc}
`;
export type CreateTournamentMatchLineUpMutationFn = ApolloReactCommon.MutationFunction<
  CreateTournamentMatchLineUpMutation,
  CreateTournamentMatchLineUpMutationVariables
>;
export type CreateTournamentMatchLineUpComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateTournamentMatchLineUpMutation,
    CreateTournamentMatchLineUpMutationVariables
  >,
  'mutation'
>;

export const CreateTournamentMatchLineUpComponent = (props: CreateTournamentMatchLineUpComponentProps) => (
  <ApolloReactComponents.Mutation<CreateTournamentMatchLineUpMutation, CreateTournamentMatchLineUpMutationVariables>
    mutation={CreateTournamentMatchLineUpDocument}
    {...props}
  />
);

export type CreateTournamentMatchLineUpProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateTournamentMatchLineUpMutation,
  CreateTournamentMatchLineUpMutationVariables
> &
  TChildProps;
export function withCreateTournamentMatchLineUp<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateTournamentMatchLineUpMutation,
    CreateTournamentMatchLineUpMutationVariables,
    CreateTournamentMatchLineUpProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateTournamentMatchLineUpMutation,
    CreateTournamentMatchLineUpMutationVariables,
    CreateTournamentMatchLineUpProps<TChildProps>
  >(CreateTournamentMatchLineUpDocument, {
    alias: 'createTournamentMatchLineUp',
    ...operationOptions,
  });
}

/**
 * __useCreateTournamentMatchLineUpMutation__
 *
 * To run a mutation, you first call `useCreateTournamentMatchLineUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentMatchLineUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentMatchLineUpMutation, { data, loading, error }] = useCreateTournamentMatchLineUpMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentMatchLineUpMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentMatchLineUpMutation,
    CreateTournamentMatchLineUpMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateTournamentMatchLineUpMutation,
    CreateTournamentMatchLineUpMutationVariables
  >(CreateTournamentMatchLineUpDocument, baseOptions);
}
export type CreateTournamentMatchLineUpMutationHookResult = ReturnType<typeof useCreateTournamentMatchLineUpMutation>;
export type CreateTournamentMatchLineUpMutationResult = ApolloReactCommon.MutationResult<
  CreateTournamentMatchLineUpMutation
>;
export type CreateTournamentMatchLineUpMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTournamentMatchLineUpMutation,
  CreateTournamentMatchLineUpMutationVariables
>;
export const CreateTournamentMatchProtocolDocument = gql`
  mutation createTournamentMatchProtocol($data: TournamentMatchProtocolCreateInput!) {
    createTournamentMatchProtocol(data: $data) {
      ...tournamentMatchProtocol
    }
  }
  ${TournamentMatchProtocolFragmentDoc}
`;
export type CreateTournamentMatchProtocolMutationFn = ApolloReactCommon.MutationFunction<
  CreateTournamentMatchProtocolMutation,
  CreateTournamentMatchProtocolMutationVariables
>;
export type CreateTournamentMatchProtocolComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateTournamentMatchProtocolMutation,
    CreateTournamentMatchProtocolMutationVariables
  >,
  'mutation'
>;

export const CreateTournamentMatchProtocolComponent = (props: CreateTournamentMatchProtocolComponentProps) => (
  <ApolloReactComponents.Mutation<CreateTournamentMatchProtocolMutation, CreateTournamentMatchProtocolMutationVariables>
    mutation={CreateTournamentMatchProtocolDocument}
    {...props}
  />
);

export type CreateTournamentMatchProtocolProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateTournamentMatchProtocolMutation,
  CreateTournamentMatchProtocolMutationVariables
> &
  TChildProps;
export function withCreateTournamentMatchProtocol<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateTournamentMatchProtocolMutation,
    CreateTournamentMatchProtocolMutationVariables,
    CreateTournamentMatchProtocolProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateTournamentMatchProtocolMutation,
    CreateTournamentMatchProtocolMutationVariables,
    CreateTournamentMatchProtocolProps<TChildProps>
  >(CreateTournamentMatchProtocolDocument, {
    alias: 'createTournamentMatchProtocol',
    ...operationOptions,
  });
}

/**
 * __useCreateTournamentMatchProtocolMutation__
 *
 * To run a mutation, you first call `useCreateTournamentMatchProtocolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentMatchProtocolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentMatchProtocolMutation, { data, loading, error }] = useCreateTournamentMatchProtocolMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentMatchProtocolMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentMatchProtocolMutation,
    CreateTournamentMatchProtocolMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateTournamentMatchProtocolMutation,
    CreateTournamentMatchProtocolMutationVariables
  >(CreateTournamentMatchProtocolDocument, baseOptions);
}
export type CreateTournamentMatchProtocolMutationHookResult = ReturnType<
  typeof useCreateTournamentMatchProtocolMutation
>;
export type CreateTournamentMatchProtocolMutationResult = ApolloReactCommon.MutationResult<
  CreateTournamentMatchProtocolMutation
>;
export type CreateTournamentMatchProtocolMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTournamentMatchProtocolMutation,
  CreateTournamentMatchProtocolMutationVariables
>;
export const CreateTournamentPlayerStatDocument = gql`
  mutation createTournamentPlayerStat($data: TournamentPlayerStatCreateInput!) {
    createTournamentPlayerStat(data: $data) {
      ...tournamentPlayerStat
    }
  }
  ${TournamentPlayerStatFragmentDoc}
`;
export type CreateTournamentPlayerStatMutationFn = ApolloReactCommon.MutationFunction<
  CreateTournamentPlayerStatMutation,
  CreateTournamentPlayerStatMutationVariables
>;
export type CreateTournamentPlayerStatComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateTournamentPlayerStatMutation,
    CreateTournamentPlayerStatMutationVariables
  >,
  'mutation'
>;

export const CreateTournamentPlayerStatComponent = (props: CreateTournamentPlayerStatComponentProps) => (
  <ApolloReactComponents.Mutation<CreateTournamentPlayerStatMutation, CreateTournamentPlayerStatMutationVariables>
    mutation={CreateTournamentPlayerStatDocument}
    {...props}
  />
);

export type CreateTournamentPlayerStatProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateTournamentPlayerStatMutation,
  CreateTournamentPlayerStatMutationVariables
> &
  TChildProps;
export function withCreateTournamentPlayerStat<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateTournamentPlayerStatMutation,
    CreateTournamentPlayerStatMutationVariables,
    CreateTournamentPlayerStatProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateTournamentPlayerStatMutation,
    CreateTournamentPlayerStatMutationVariables,
    CreateTournamentPlayerStatProps<TChildProps>
  >(CreateTournamentPlayerStatDocument, {
    alias: 'createTournamentPlayerStat',
    ...operationOptions,
  });
}

/**
 * __useCreateTournamentPlayerStatMutation__
 *
 * To run a mutation, you first call `useCreateTournamentPlayerStatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentPlayerStatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentPlayerStatMutation, { data, loading, error }] = useCreateTournamentPlayerStatMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentPlayerStatMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentPlayerStatMutation,
    CreateTournamentPlayerStatMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateTournamentPlayerStatMutation, CreateTournamentPlayerStatMutationVariables>(
    CreateTournamentPlayerStatDocument,
    baseOptions
  );
}
export type CreateTournamentPlayerStatMutationHookResult = ReturnType<typeof useCreateTournamentPlayerStatMutation>;
export type CreateTournamentPlayerStatMutationResult = ApolloReactCommon.MutationResult<
  CreateTournamentPlayerStatMutation
>;
export type CreateTournamentPlayerStatMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTournamentPlayerStatMutation,
  CreateTournamentPlayerStatMutationVariables
>;
export const CreateTournamentRefereeDocument = gql`
  mutation createTournamentReferee($data: TournamentRefereeCreateInput!) {
    createTournamentReferee(data: $data) {
      ...tournamentReferee
    }
  }
  ${TournamentRefereeFragmentDoc}
`;
export type CreateTournamentRefereeMutationFn = ApolloReactCommon.MutationFunction<
  CreateTournamentRefereeMutation,
  CreateTournamentRefereeMutationVariables
>;
export type CreateTournamentRefereeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateTournamentRefereeMutation,
    CreateTournamentRefereeMutationVariables
  >,
  'mutation'
>;

export const CreateTournamentRefereeComponent = (props: CreateTournamentRefereeComponentProps) => (
  <ApolloReactComponents.Mutation<CreateTournamentRefereeMutation, CreateTournamentRefereeMutationVariables>
    mutation={CreateTournamentRefereeDocument}
    {...props}
  />
);

export type CreateTournamentRefereeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateTournamentRefereeMutation,
  CreateTournamentRefereeMutationVariables
> &
  TChildProps;
export function withCreateTournamentReferee<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateTournamentRefereeMutation,
    CreateTournamentRefereeMutationVariables,
    CreateTournamentRefereeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateTournamentRefereeMutation,
    CreateTournamentRefereeMutationVariables,
    CreateTournamentRefereeProps<TChildProps>
  >(CreateTournamentRefereeDocument, {
    alias: 'createTournamentReferee',
    ...operationOptions,
  });
}

/**
 * __useCreateTournamentRefereeMutation__
 *
 * To run a mutation, you first call `useCreateTournamentRefereeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentRefereeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentRefereeMutation, { data, loading, error }] = useCreateTournamentRefereeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentRefereeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentRefereeMutation,
    CreateTournamentRefereeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateTournamentRefereeMutation, CreateTournamentRefereeMutationVariables>(
    CreateTournamentRefereeDocument,
    baseOptions
  );
}
export type CreateTournamentRefereeMutationHookResult = ReturnType<typeof useCreateTournamentRefereeMutation>;
export type CreateTournamentRefereeMutationResult = ApolloReactCommon.MutationResult<CreateTournamentRefereeMutation>;
export type CreateTournamentRefereeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTournamentRefereeMutation,
  CreateTournamentRefereeMutationVariables
>;
export const CreateTournamentSeasonDocument = gql`
  mutation createTournamentSeason($data: TournamentSeasonCreateInput!) {
    createTournamentSeason(data: $data) {
      ...tournamentSeason
    }
  }
  ${TournamentSeasonFragmentDoc}
`;
export type CreateTournamentSeasonMutationFn = ApolloReactCommon.MutationFunction<
  CreateTournamentSeasonMutation,
  CreateTournamentSeasonMutationVariables
>;
export type CreateTournamentSeasonComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateTournamentSeasonMutation,
    CreateTournamentSeasonMutationVariables
  >,
  'mutation'
>;

export const CreateTournamentSeasonComponent = (props: CreateTournamentSeasonComponentProps) => (
  <ApolloReactComponents.Mutation<CreateTournamentSeasonMutation, CreateTournamentSeasonMutationVariables>
    mutation={CreateTournamentSeasonDocument}
    {...props}
  />
);

export type CreateTournamentSeasonProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateTournamentSeasonMutation,
  CreateTournamentSeasonMutationVariables
> &
  TChildProps;
export function withCreateTournamentSeason<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateTournamentSeasonMutation,
    CreateTournamentSeasonMutationVariables,
    CreateTournamentSeasonProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateTournamentSeasonMutation,
    CreateTournamentSeasonMutationVariables,
    CreateTournamentSeasonProps<TChildProps>
  >(CreateTournamentSeasonDocument, {
    alias: 'createTournamentSeason',
    ...operationOptions,
  });
}

/**
 * __useCreateTournamentSeasonMutation__
 *
 * To run a mutation, you first call `useCreateTournamentSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentSeasonMutation, { data, loading, error }] = useCreateTournamentSeasonMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentSeasonMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentSeasonMutation,
    CreateTournamentSeasonMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateTournamentSeasonMutation, CreateTournamentSeasonMutationVariables>(
    CreateTournamentSeasonDocument,
    baseOptions
  );
}
export type CreateTournamentSeasonMutationHookResult = ReturnType<typeof useCreateTournamentSeasonMutation>;
export type CreateTournamentSeasonMutationResult = ApolloReactCommon.MutationResult<CreateTournamentSeasonMutation>;
export type CreateTournamentSeasonMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTournamentSeasonMutation,
  CreateTournamentSeasonMutationVariables
>;
export const CreateTournamentSeasonTeamDocument = gql`
  mutation createTournamentSeasonTeam($data: TournamentSeasonTeamCreateInput!) {
    createTournamentSeasonTeam(data: $data) {
      ...tournamentSeasonTeam
    }
  }
  ${TournamentSeasonTeamFragmentDoc}
`;
export type CreateTournamentSeasonTeamMutationFn = ApolloReactCommon.MutationFunction<
  CreateTournamentSeasonTeamMutation,
  CreateTournamentSeasonTeamMutationVariables
>;
export type CreateTournamentSeasonTeamComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateTournamentSeasonTeamMutation,
    CreateTournamentSeasonTeamMutationVariables
  >,
  'mutation'
>;

export const CreateTournamentSeasonTeamComponent = (props: CreateTournamentSeasonTeamComponentProps) => (
  <ApolloReactComponents.Mutation<CreateTournamentSeasonTeamMutation, CreateTournamentSeasonTeamMutationVariables>
    mutation={CreateTournamentSeasonTeamDocument}
    {...props}
  />
);

export type CreateTournamentSeasonTeamProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateTournamentSeasonTeamMutation,
  CreateTournamentSeasonTeamMutationVariables
> &
  TChildProps;
export function withCreateTournamentSeasonTeam<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateTournamentSeasonTeamMutation,
    CreateTournamentSeasonTeamMutationVariables,
    CreateTournamentSeasonTeamProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateTournamentSeasonTeamMutation,
    CreateTournamentSeasonTeamMutationVariables,
    CreateTournamentSeasonTeamProps<TChildProps>
  >(CreateTournamentSeasonTeamDocument, {
    alias: 'createTournamentSeasonTeam',
    ...operationOptions,
  });
}

/**
 * __useCreateTournamentSeasonTeamMutation__
 *
 * To run a mutation, you first call `useCreateTournamentSeasonTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentSeasonTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentSeasonTeamMutation, { data, loading, error }] = useCreateTournamentSeasonTeamMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentSeasonTeamMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentSeasonTeamMutation,
    CreateTournamentSeasonTeamMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateTournamentSeasonTeamMutation, CreateTournamentSeasonTeamMutationVariables>(
    CreateTournamentSeasonTeamDocument,
    baseOptions
  );
}
export type CreateTournamentSeasonTeamMutationHookResult = ReturnType<typeof useCreateTournamentSeasonTeamMutation>;
export type CreateTournamentSeasonTeamMutationResult = ApolloReactCommon.MutationResult<
  CreateTournamentSeasonTeamMutation
>;
export type CreateTournamentSeasonTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTournamentSeasonTeamMutation,
  CreateTournamentSeasonTeamMutationVariables
>;
export const CreateTournamentSeasonTeamStatDocument = gql`
  mutation createTournamentSeasonTeamStat($data: TournamentSeasonTeamStatCreateInput!) {
    createTournamentSeasonTeamStat(data: $data) {
      ...tournamentSeasonTeamStat
    }
  }
  ${TournamentSeasonTeamStatFragmentDoc}
`;
export type CreateTournamentSeasonTeamStatMutationFn = ApolloReactCommon.MutationFunction<
  CreateTournamentSeasonTeamStatMutation,
  CreateTournamentSeasonTeamStatMutationVariables
>;
export type CreateTournamentSeasonTeamStatComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateTournamentSeasonTeamStatMutation,
    CreateTournamentSeasonTeamStatMutationVariables
  >,
  'mutation'
>;

export const CreateTournamentSeasonTeamStatComponent = (props: CreateTournamentSeasonTeamStatComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateTournamentSeasonTeamStatMutation,
    CreateTournamentSeasonTeamStatMutationVariables
  >
    mutation={CreateTournamentSeasonTeamStatDocument}
    {...props}
  />
);

export type CreateTournamentSeasonTeamStatProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateTournamentSeasonTeamStatMutation,
  CreateTournamentSeasonTeamStatMutationVariables
> &
  TChildProps;
export function withCreateTournamentSeasonTeamStat<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateTournamentSeasonTeamStatMutation,
    CreateTournamentSeasonTeamStatMutationVariables,
    CreateTournamentSeasonTeamStatProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateTournamentSeasonTeamStatMutation,
    CreateTournamentSeasonTeamStatMutationVariables,
    CreateTournamentSeasonTeamStatProps<TChildProps>
  >(CreateTournamentSeasonTeamStatDocument, {
    alias: 'createTournamentSeasonTeamStat',
    ...operationOptions,
  });
}

/**
 * __useCreateTournamentSeasonTeamStatMutation__
 *
 * To run a mutation, you first call `useCreateTournamentSeasonTeamStatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentSeasonTeamStatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentSeasonTeamStatMutation, { data, loading, error }] = useCreateTournamentSeasonTeamStatMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentSeasonTeamStatMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentSeasonTeamStatMutation,
    CreateTournamentSeasonTeamStatMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateTournamentSeasonTeamStatMutation,
    CreateTournamentSeasonTeamStatMutationVariables
  >(CreateTournamentSeasonTeamStatDocument, baseOptions);
}
export type CreateTournamentSeasonTeamStatMutationHookResult = ReturnType<
  typeof useCreateTournamentSeasonTeamStatMutation
>;
export type CreateTournamentSeasonTeamStatMutationResult = ApolloReactCommon.MutationResult<
  CreateTournamentSeasonTeamStatMutation
>;
export type CreateTournamentSeasonTeamStatMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTournamentSeasonTeamStatMutation,
  CreateTournamentSeasonTeamStatMutationVariables
>;
export const CreateTournamentStadiumDocument = gql`
  mutation createTournamentStadium($data: TournamentStadiumCreateInput!) {
    createTournamentStadium(data: $data) {
      ...tournamentStadium
    }
  }
  ${TournamentStadiumFragmentDoc}
`;
export type CreateTournamentStadiumMutationFn = ApolloReactCommon.MutationFunction<
  CreateTournamentStadiumMutation,
  CreateTournamentStadiumMutationVariables
>;
export type CreateTournamentStadiumComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateTournamentStadiumMutation,
    CreateTournamentStadiumMutationVariables
  >,
  'mutation'
>;

export const CreateTournamentStadiumComponent = (props: CreateTournamentStadiumComponentProps) => (
  <ApolloReactComponents.Mutation<CreateTournamentStadiumMutation, CreateTournamentStadiumMutationVariables>
    mutation={CreateTournamentStadiumDocument}
    {...props}
  />
);

export type CreateTournamentStadiumProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateTournamentStadiumMutation,
  CreateTournamentStadiumMutationVariables
> &
  TChildProps;
export function withCreateTournamentStadium<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateTournamentStadiumMutation,
    CreateTournamentStadiumMutationVariables,
    CreateTournamentStadiumProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateTournamentStadiumMutation,
    CreateTournamentStadiumMutationVariables,
    CreateTournamentStadiumProps<TChildProps>
  >(CreateTournamentStadiumDocument, {
    alias: 'createTournamentStadium',
    ...operationOptions,
  });
}

/**
 * __useCreateTournamentStadiumMutation__
 *
 * To run a mutation, you first call `useCreateTournamentStadiumMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentStadiumMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentStadiumMutation, { data, loading, error }] = useCreateTournamentStadiumMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentStadiumMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentStadiumMutation,
    CreateTournamentStadiumMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateTournamentStadiumMutation, CreateTournamentStadiumMutationVariables>(
    CreateTournamentStadiumDocument,
    baseOptions
  );
}
export type CreateTournamentStadiumMutationHookResult = ReturnType<typeof useCreateTournamentStadiumMutation>;
export type CreateTournamentStadiumMutationResult = ApolloReactCommon.MutationResult<CreateTournamentStadiumMutation>;
export type CreateTournamentStadiumMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTournamentStadiumMutation,
  CreateTournamentStadiumMutationVariables
>;
export const CreateTournamentTeamDocument = gql`
  mutation createTournamentTeam($data: TournamentTeamCreateInput!) {
    createTournamentTeam(data: $data) {
      ...tournamentTeam
    }
  }
  ${TournamentTeamFragmentDoc}
`;
export type CreateTournamentTeamMutationFn = ApolloReactCommon.MutationFunction<
  CreateTournamentTeamMutation,
  CreateTournamentTeamMutationVariables
>;
export type CreateTournamentTeamComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateTournamentTeamMutation, CreateTournamentTeamMutationVariables>,
  'mutation'
>;

export const CreateTournamentTeamComponent = (props: CreateTournamentTeamComponentProps) => (
  <ApolloReactComponents.Mutation<CreateTournamentTeamMutation, CreateTournamentTeamMutationVariables>
    mutation={CreateTournamentTeamDocument}
    {...props}
  />
);

export type CreateTournamentTeamProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateTournamentTeamMutation,
  CreateTournamentTeamMutationVariables
> &
  TChildProps;
export function withCreateTournamentTeam<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateTournamentTeamMutation,
    CreateTournamentTeamMutationVariables,
    CreateTournamentTeamProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateTournamentTeamMutation,
    CreateTournamentTeamMutationVariables,
    CreateTournamentTeamProps<TChildProps>
  >(CreateTournamentTeamDocument, {
    alias: 'createTournamentTeam',
    ...operationOptions,
  });
}

/**
 * __useCreateTournamentTeamMutation__
 *
 * To run a mutation, you first call `useCreateTournamentTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentTeamMutation, { data, loading, error }] = useCreateTournamentTeamMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentTeamMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentTeamMutation,
    CreateTournamentTeamMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateTournamentTeamMutation, CreateTournamentTeamMutationVariables>(
    CreateTournamentTeamDocument,
    baseOptions
  );
}
export type CreateTournamentTeamMutationHookResult = ReturnType<typeof useCreateTournamentTeamMutation>;
export type CreateTournamentTeamMutationResult = ApolloReactCommon.MutationResult<CreateTournamentTeamMutation>;
export type CreateTournamentTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTournamentTeamMutation,
  CreateTournamentTeamMutationVariables
>;
export const CreateTournamentTeamMemberDocument = gql`
  mutation createTournamentTeamMember($data: TournamentTeamMemberCreateInput!) {
    createTournamentTeamMember(data: $data) {
      ...tournamentTeamMember
    }
  }
  ${TournamentTeamMemberFragmentDoc}
`;
export type CreateTournamentTeamMemberMutationFn = ApolloReactCommon.MutationFunction<
  CreateTournamentTeamMemberMutation,
  CreateTournamentTeamMemberMutationVariables
>;
export type CreateTournamentTeamMemberComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateTournamentTeamMemberMutation,
    CreateTournamentTeamMemberMutationVariables
  >,
  'mutation'
>;

export const CreateTournamentTeamMemberComponent = (props: CreateTournamentTeamMemberComponentProps) => (
  <ApolloReactComponents.Mutation<CreateTournamentTeamMemberMutation, CreateTournamentTeamMemberMutationVariables>
    mutation={CreateTournamentTeamMemberDocument}
    {...props}
  />
);

export type CreateTournamentTeamMemberProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateTournamentTeamMemberMutation,
  CreateTournamentTeamMemberMutationVariables
> &
  TChildProps;
export function withCreateTournamentTeamMember<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateTournamentTeamMemberMutation,
    CreateTournamentTeamMemberMutationVariables,
    CreateTournamentTeamMemberProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateTournamentTeamMemberMutation,
    CreateTournamentTeamMemberMutationVariables,
    CreateTournamentTeamMemberProps<TChildProps>
  >(CreateTournamentTeamMemberDocument, {
    alias: 'createTournamentTeamMember',
    ...operationOptions,
  });
}

/**
 * __useCreateTournamentTeamMemberMutation__
 *
 * To run a mutation, you first call `useCreateTournamentTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentTeamMemberMutation, { data, loading, error }] = useCreateTournamentTeamMemberMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentTeamMemberMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentTeamMemberMutation,
    CreateTournamentTeamMemberMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateTournamentTeamMemberMutation, CreateTournamentTeamMemberMutationVariables>(
    CreateTournamentTeamMemberDocument,
    baseOptions
  );
}
export type CreateTournamentTeamMemberMutationHookResult = ReturnType<typeof useCreateTournamentTeamMemberMutation>;
export type CreateTournamentTeamMemberMutationResult = ApolloReactCommon.MutationResult<
  CreateTournamentTeamMemberMutation
>;
export type CreateTournamentTeamMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTournamentTeamMemberMutation,
  CreateTournamentTeamMemberMutationVariables
>;
export const CreateTournamentTourDocument = gql`
  mutation createTournamentTour($data: TournamentTourCreateInput!) {
    createTournamentTour(data: $data) {
      ...tournamentTour
    }
  }
  ${TournamentTourFragmentDoc}
`;
export type CreateTournamentTourMutationFn = ApolloReactCommon.MutationFunction<
  CreateTournamentTourMutation,
  CreateTournamentTourMutationVariables
>;
export type CreateTournamentTourComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateTournamentTourMutation, CreateTournamentTourMutationVariables>,
  'mutation'
>;

export const CreateTournamentTourComponent = (props: CreateTournamentTourComponentProps) => (
  <ApolloReactComponents.Mutation<CreateTournamentTourMutation, CreateTournamentTourMutationVariables>
    mutation={CreateTournamentTourDocument}
    {...props}
  />
);

export type CreateTournamentTourProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateTournamentTourMutation,
  CreateTournamentTourMutationVariables
> &
  TChildProps;
export function withCreateTournamentTour<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateTournamentTourMutation,
    CreateTournamentTourMutationVariables,
    CreateTournamentTourProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateTournamentTourMutation,
    CreateTournamentTourMutationVariables,
    CreateTournamentTourProps<TChildProps>
  >(CreateTournamentTourDocument, {
    alias: 'createTournamentTour',
    ...operationOptions,
  });
}

/**
 * __useCreateTournamentTourMutation__
 *
 * To run a mutation, you first call `useCreateTournamentTourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentTourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentTourMutation, { data, loading, error }] = useCreateTournamentTourMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentTourMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentTourMutation,
    CreateTournamentTourMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateTournamentTourMutation, CreateTournamentTourMutationVariables>(
    CreateTournamentTourDocument,
    baseOptions
  );
}
export type CreateTournamentTourMutationHookResult = ReturnType<typeof useCreateTournamentTourMutation>;
export type CreateTournamentTourMutationResult = ApolloReactCommon.MutationResult<CreateTournamentTourMutation>;
export type CreateTournamentTourMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTournamentTourMutation,
  CreateTournamentTourMutationVariables
>;
export const CreateUserApplicationFieldDocument = gql`
  mutation createUserApplicationField($data: CreateExtensionFieldInput!) {
    createUserApplicationField(data: $data) {
      ...extensionField
    }
  }
  ${ExtensionFieldFragmentDoc}
`;
export type CreateUserApplicationFieldMutationFn = ApolloReactCommon.MutationFunction<
  CreateUserApplicationFieldMutation,
  CreateUserApplicationFieldMutationVariables
>;
export type CreateUserApplicationFieldComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateUserApplicationFieldMutation,
    CreateUserApplicationFieldMutationVariables
  >,
  'mutation'
>;

export const CreateUserApplicationFieldComponent = (props: CreateUserApplicationFieldComponentProps) => (
  <ApolloReactComponents.Mutation<CreateUserApplicationFieldMutation, CreateUserApplicationFieldMutationVariables>
    mutation={CreateUserApplicationFieldDocument}
    {...props}
  />
);

export type CreateUserApplicationFieldProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateUserApplicationFieldMutation,
  CreateUserApplicationFieldMutationVariables
> &
  TChildProps;
export function withCreateUserApplicationField<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateUserApplicationFieldMutation,
    CreateUserApplicationFieldMutationVariables,
    CreateUserApplicationFieldProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateUserApplicationFieldMutation,
    CreateUserApplicationFieldMutationVariables,
    CreateUserApplicationFieldProps<TChildProps>
  >(CreateUserApplicationFieldDocument, {
    alias: 'createUserApplicationField',
    ...operationOptions,
  });
}

/**
 * __useCreateUserApplicationFieldMutation__
 *
 * To run a mutation, you first call `useCreateUserApplicationFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserApplicationFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserApplicationFieldMutation, { data, loading, error }] = useCreateUserApplicationFieldMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserApplicationFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateUserApplicationFieldMutation,
    CreateUserApplicationFieldMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateUserApplicationFieldMutation, CreateUserApplicationFieldMutationVariables>(
    CreateUserApplicationFieldDocument,
    baseOptions
  );
}
export type CreateUserApplicationFieldMutationHookResult = ReturnType<typeof useCreateUserApplicationFieldMutation>;
export type CreateUserApplicationFieldMutationResult = ApolloReactCommon.MutationResult<
  CreateUserApplicationFieldMutation
>;
export type CreateUserApplicationFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateUserApplicationFieldMutation,
  CreateUserApplicationFieldMutationVariables
>;
export const CreateUserApplicationLogCommentDocument = gql`
  mutation createUserApplicationLogComment($applicationId: Int!, $comment: String!) {
    createUserApplicationLogComment(applicationId: $applicationId, comment: $comment) {
      ...userApplicationLog
    }
  }
  ${UserApplicationLogFragmentDoc}
`;
export type CreateUserApplicationLogCommentMutationFn = ApolloReactCommon.MutationFunction<
  CreateUserApplicationLogCommentMutation,
  CreateUserApplicationLogCommentMutationVariables
>;
export type CreateUserApplicationLogCommentComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateUserApplicationLogCommentMutation,
    CreateUserApplicationLogCommentMutationVariables
  >,
  'mutation'
>;

export const CreateUserApplicationLogCommentComponent = (props: CreateUserApplicationLogCommentComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateUserApplicationLogCommentMutation,
    CreateUserApplicationLogCommentMutationVariables
  >
    mutation={CreateUserApplicationLogCommentDocument}
    {...props}
  />
);

export type CreateUserApplicationLogCommentProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateUserApplicationLogCommentMutation,
  CreateUserApplicationLogCommentMutationVariables
> &
  TChildProps;
export function withCreateUserApplicationLogComment<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateUserApplicationLogCommentMutation,
    CreateUserApplicationLogCommentMutationVariables,
    CreateUserApplicationLogCommentProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateUserApplicationLogCommentMutation,
    CreateUserApplicationLogCommentMutationVariables,
    CreateUserApplicationLogCommentProps<TChildProps>
  >(CreateUserApplicationLogCommentDocument, {
    alias: 'createUserApplicationLogComment',
    ...operationOptions,
  });
}

/**
 * __useCreateUserApplicationLogCommentMutation__
 *
 * To run a mutation, you first call `useCreateUserApplicationLogCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserApplicationLogCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserApplicationLogCommentMutation, { data, loading, error }] = useCreateUserApplicationLogCommentMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCreateUserApplicationLogCommentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateUserApplicationLogCommentMutation,
    CreateUserApplicationLogCommentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateUserApplicationLogCommentMutation,
    CreateUserApplicationLogCommentMutationVariables
  >(CreateUserApplicationLogCommentDocument, baseOptions);
}
export type CreateUserApplicationLogCommentMutationHookResult = ReturnType<
  typeof useCreateUserApplicationLogCommentMutation
>;
export type CreateUserApplicationLogCommentMutationResult = ApolloReactCommon.MutationResult<
  CreateUserApplicationLogCommentMutation
>;
export type CreateUserApplicationLogCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateUserApplicationLogCommentMutation,
  CreateUserApplicationLogCommentMutationVariables
>;
export const CreateUserApplicationTypeDocument = gql`
  mutation createUserApplicationType($data: UserApplicationTypeCreateInput!) {
    createUserApplicationType(data: $data) {
      ...userApplicationType
    }
  }
  ${UserApplicationTypeFragmentDoc}
`;
export type CreateUserApplicationTypeMutationFn = ApolloReactCommon.MutationFunction<
  CreateUserApplicationTypeMutation,
  CreateUserApplicationTypeMutationVariables
>;
export type CreateUserApplicationTypeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateUserApplicationTypeMutation,
    CreateUserApplicationTypeMutationVariables
  >,
  'mutation'
>;

export const CreateUserApplicationTypeComponent = (props: CreateUserApplicationTypeComponentProps) => (
  <ApolloReactComponents.Mutation<CreateUserApplicationTypeMutation, CreateUserApplicationTypeMutationVariables>
    mutation={CreateUserApplicationTypeDocument}
    {...props}
  />
);

export type CreateUserApplicationTypeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateUserApplicationTypeMutation,
  CreateUserApplicationTypeMutationVariables
> &
  TChildProps;
export function withCreateUserApplicationType<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateUserApplicationTypeMutation,
    CreateUserApplicationTypeMutationVariables,
    CreateUserApplicationTypeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateUserApplicationTypeMutation,
    CreateUserApplicationTypeMutationVariables,
    CreateUserApplicationTypeProps<TChildProps>
  >(CreateUserApplicationTypeDocument, {
    alias: 'createUserApplicationType',
    ...operationOptions,
  });
}

/**
 * __useCreateUserApplicationTypeMutation__
 *
 * To run a mutation, you first call `useCreateUserApplicationTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserApplicationTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserApplicationTypeMutation, { data, loading, error }] = useCreateUserApplicationTypeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserApplicationTypeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateUserApplicationTypeMutation,
    CreateUserApplicationTypeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateUserApplicationTypeMutation, CreateUserApplicationTypeMutationVariables>(
    CreateUserApplicationTypeDocument,
    baseOptions
  );
}
export type CreateUserApplicationTypeMutationHookResult = ReturnType<typeof useCreateUserApplicationTypeMutation>;
export type CreateUserApplicationTypeMutationResult = ApolloReactCommon.MutationResult<
  CreateUserApplicationTypeMutation
>;
export type CreateUserApplicationTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateUserApplicationTypeMutation,
  CreateUserApplicationTypeMutationVariables
>;
export const CreateUserGroupDocument = gql`
  mutation createUserGroup($data: UserGroupCreateInput!) {
    createUserGroup(data: $data) {
      ...userGroup
    }
  }
  ${UserGroupFragmentDoc}
`;
export type CreateUserGroupMutationFn = ApolloReactCommon.MutationFunction<
  CreateUserGroupMutation,
  CreateUserGroupMutationVariables
>;
export type CreateUserGroupComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateUserGroupMutation, CreateUserGroupMutationVariables>,
  'mutation'
>;

export const CreateUserGroupComponent = (props: CreateUserGroupComponentProps) => (
  <ApolloReactComponents.Mutation<CreateUserGroupMutation, CreateUserGroupMutationVariables>
    mutation={CreateUserGroupDocument}
    {...props}
  />
);

export type CreateUserGroupProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateUserGroupMutation,
  CreateUserGroupMutationVariables
> &
  TChildProps;
export function withCreateUserGroup<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateUserGroupMutation,
    CreateUserGroupMutationVariables,
    CreateUserGroupProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateUserGroupMutation,
    CreateUserGroupMutationVariables,
    CreateUserGroupProps<TChildProps>
  >(CreateUserGroupDocument, {
    alias: 'createUserGroup',
    ...operationOptions,
  });
}

/**
 * __useCreateUserGroupMutation__
 *
 * To run a mutation, you first call `useCreateUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserGroupMutation, { data, loading, error }] = useCreateUserGroupMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserGroupMutation, CreateUserGroupMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateUserGroupMutation, CreateUserGroupMutationVariables>(
    CreateUserGroupDocument,
    baseOptions
  );
}
export type CreateUserGroupMutationHookResult = ReturnType<typeof useCreateUserGroupMutation>;
export type CreateUserGroupMutationResult = ApolloReactCommon.MutationResult<CreateUserGroupMutation>;
export type CreateUserGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateUserGroupMutation,
  CreateUserGroupMutationVariables
>;
export const CreateUserSubscriberDocument = gql`
  mutation createUserSubscriber($data: UserSubscriberCreateInput!) {
    createUserSubscriber(data: $data) {
      ...userSubscriber
    }
  }
  ${UserSubscriberFragmentDoc}
`;
export type CreateUserSubscriberMutationFn = ApolloReactCommon.MutationFunction<
  CreateUserSubscriberMutation,
  CreateUserSubscriberMutationVariables
>;
export type CreateUserSubscriberComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateUserSubscriberMutation, CreateUserSubscriberMutationVariables>,
  'mutation'
>;

export const CreateUserSubscriberComponent = (props: CreateUserSubscriberComponentProps) => (
  <ApolloReactComponents.Mutation<CreateUserSubscriberMutation, CreateUserSubscriberMutationVariables>
    mutation={CreateUserSubscriberDocument}
    {...props}
  />
);

export type CreateUserSubscriberProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateUserSubscriberMutation,
  CreateUserSubscriberMutationVariables
> &
  TChildProps;
export function withCreateUserSubscriber<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateUserSubscriberMutation,
    CreateUserSubscriberMutationVariables,
    CreateUserSubscriberProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateUserSubscriberMutation,
    CreateUserSubscriberMutationVariables,
    CreateUserSubscriberProps<TChildProps>
  >(CreateUserSubscriberDocument, {
    alias: 'createUserSubscriber',
    ...operationOptions,
  });
}

/**
 * __useCreateUserSubscriberMutation__
 *
 * To run a mutation, you first call `useCreateUserSubscriberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSubscriberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSubscriberMutation, { data, loading, error }] = useCreateUserSubscriberMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserSubscriberMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateUserSubscriberMutation,
    CreateUserSubscriberMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateUserSubscriberMutation, CreateUserSubscriberMutationVariables>(
    CreateUserSubscriberDocument,
    baseOptions
  );
}
export type CreateUserSubscriberMutationHookResult = ReturnType<typeof useCreateUserSubscriberMutation>;
export type CreateUserSubscriberMutationResult = ApolloReactCommon.MutationResult<CreateUserSubscriberMutation>;
export type CreateUserSubscriberMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateUserSubscriberMutation,
  CreateUserSubscriberMutationVariables
>;
export const CreateUserSubscribersDocument = gql`
  mutation createUserSubscribers($subscriptionId: Int!, $userId: [Int!]!, $modifierId: Int) {
    createUserSubscribers(subscriptionId: $subscriptionId, userId: $userId, modifierId: $modifierId)
  }
`;
export type CreateUserSubscribersMutationFn = ApolloReactCommon.MutationFunction<
  CreateUserSubscribersMutation,
  CreateUserSubscribersMutationVariables
>;
export type CreateUserSubscribersComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateUserSubscribersMutation, CreateUserSubscribersMutationVariables>,
  'mutation'
>;

export const CreateUserSubscribersComponent = (props: CreateUserSubscribersComponentProps) => (
  <ApolloReactComponents.Mutation<CreateUserSubscribersMutation, CreateUserSubscribersMutationVariables>
    mutation={CreateUserSubscribersDocument}
    {...props}
  />
);

export type CreateUserSubscribersProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateUserSubscribersMutation,
  CreateUserSubscribersMutationVariables
> &
  TChildProps;
export function withCreateUserSubscribers<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateUserSubscribersMutation,
    CreateUserSubscribersMutationVariables,
    CreateUserSubscribersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateUserSubscribersMutation,
    CreateUserSubscribersMutationVariables,
    CreateUserSubscribersProps<TChildProps>
  >(CreateUserSubscribersDocument, {
    alias: 'createUserSubscribers',
    ...operationOptions,
  });
}

/**
 * __useCreateUserSubscribersMutation__
 *
 * To run a mutation, you first call `useCreateUserSubscribersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSubscribersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSubscribersMutation, { data, loading, error }] = useCreateUserSubscribersMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      userId: // value for 'userId'
 *      modifierId: // value for 'modifierId'
 *   },
 * });
 */
export function useCreateUserSubscribersMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateUserSubscribersMutation,
    CreateUserSubscribersMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateUserSubscribersMutation, CreateUserSubscribersMutationVariables>(
    CreateUserSubscribersDocument,
    baseOptions
  );
}
export type CreateUserSubscribersMutationHookResult = ReturnType<typeof useCreateUserSubscribersMutation>;
export type CreateUserSubscribersMutationResult = ApolloReactCommon.MutationResult<CreateUserSubscribersMutation>;
export type CreateUserSubscribersMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateUserSubscribersMutation,
  CreateUserSubscribersMutationVariables
>;
export const CreateUserSubscriptionDocument = gql`
  mutation createUserSubscription($data: UserSubscriptionCreateInput!) {
    createUserSubscription(data: $data) {
      ...userSubscription
    }
  }
  ${UserSubscriptionFragmentDoc}
`;
export type CreateUserSubscriptionMutationFn = ApolloReactCommon.MutationFunction<
  CreateUserSubscriptionMutation,
  CreateUserSubscriptionMutationVariables
>;
export type CreateUserSubscriptionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateUserSubscriptionMutation,
    CreateUserSubscriptionMutationVariables
  >,
  'mutation'
>;

export const CreateUserSubscriptionComponent = (props: CreateUserSubscriptionComponentProps) => (
  <ApolloReactComponents.Mutation<CreateUserSubscriptionMutation, CreateUserSubscriptionMutationVariables>
    mutation={CreateUserSubscriptionDocument}
    {...props}
  />
);

export type CreateUserSubscriptionProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateUserSubscriptionMutation,
  CreateUserSubscriptionMutationVariables
> &
  TChildProps;
export function withCreateUserSubscription<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateUserSubscriptionMutation,
    CreateUserSubscriptionMutationVariables,
    CreateUserSubscriptionProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateUserSubscriptionMutation,
    CreateUserSubscriptionMutationVariables,
    CreateUserSubscriptionProps<TChildProps>
  >(CreateUserSubscriptionDocument, {
    alias: 'createUserSubscription',
    ...operationOptions,
  });
}

/**
 * __useCreateUserSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateUserSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSubscriptionMutation, { data, loading, error }] = useCreateUserSubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateUserSubscriptionMutation,
    CreateUserSubscriptionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateUserSubscriptionMutation, CreateUserSubscriptionMutationVariables>(
    CreateUserSubscriptionDocument,
    baseOptions
  );
}
export type CreateUserSubscriptionMutationHookResult = ReturnType<typeof useCreateUserSubscriptionMutation>;
export type CreateUserSubscriptionMutationResult = ApolloReactCommon.MutationResult<CreateUserSubscriptionMutation>;
export type CreateUserSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateUserSubscriptionMutation,
  CreateUserSubscriptionMutationVariables
>;
export const DeleteArticleDocument = gql`
  mutation deleteArticle($id: Int!) {
    deleteArticle(id: $id) {
      ...article
    }
  }
  ${ArticleFragmentDoc}
`;
export type DeleteArticleMutationFn = ApolloReactCommon.MutationFunction<
  DeleteArticleMutation,
  DeleteArticleMutationVariables
>;
export type DeleteArticleComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteArticleMutation, DeleteArticleMutationVariables>,
  'mutation'
>;

export const DeleteArticleComponent = (props: DeleteArticleComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteArticleMutation, DeleteArticleMutationVariables>
    mutation={DeleteArticleDocument}
    {...props}
  />
);

export type DeleteArticleProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteArticleMutation,
  DeleteArticleMutationVariables
> &
  TChildProps;
export function withDeleteArticle<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteArticleMutation,
    DeleteArticleMutationVariables,
    DeleteArticleProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteArticleMutation,
    DeleteArticleMutationVariables,
    DeleteArticleProps<TChildProps>
  >(DeleteArticleDocument, {
    alias: 'deleteArticle',
    ...operationOptions,
  });
}

/**
 * __useDeleteArticleMutation__
 *
 * To run a mutation, you first call `useDeleteArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleMutation, { data, loading, error }] = useDeleteArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArticleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArticleMutation, DeleteArticleMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteArticleMutation, DeleteArticleMutationVariables>(
    DeleteArticleDocument,
    baseOptions
  );
}
export type DeleteArticleMutationHookResult = ReturnType<typeof useDeleteArticleMutation>;
export type DeleteArticleMutationResult = ApolloReactCommon.MutationResult<DeleteArticleMutation>;
export type DeleteArticleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteArticleMutation,
  DeleteArticleMutationVariables
>;
export const DeleteArticleAuthorDocument = gql`
  mutation deleteArticleAuthor($id: Int!) {
    deleteArticleAuthor(id: $id) {
      ...articleAuthor
    }
  }
  ${ArticleAuthorFragmentDoc}
`;
export type DeleteArticleAuthorMutationFn = ApolloReactCommon.MutationFunction<
  DeleteArticleAuthorMutation,
  DeleteArticleAuthorMutationVariables
>;
export type DeleteArticleAuthorComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteArticleAuthorMutation, DeleteArticleAuthorMutationVariables>,
  'mutation'
>;

export const DeleteArticleAuthorComponent = (props: DeleteArticleAuthorComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteArticleAuthorMutation, DeleteArticleAuthorMutationVariables>
    mutation={DeleteArticleAuthorDocument}
    {...props}
  />
);

export type DeleteArticleAuthorProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteArticleAuthorMutation,
  DeleteArticleAuthorMutationVariables
> &
  TChildProps;
export function withDeleteArticleAuthor<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteArticleAuthorMutation,
    DeleteArticleAuthorMutationVariables,
    DeleteArticleAuthorProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteArticleAuthorMutation,
    DeleteArticleAuthorMutationVariables,
    DeleteArticleAuthorProps<TChildProps>
  >(DeleteArticleAuthorDocument, {
    alias: 'deleteArticleAuthor',
    ...operationOptions,
  });
}

/**
 * __useDeleteArticleAuthorMutation__
 *
 * To run a mutation, you first call `useDeleteArticleAuthorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleAuthorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleAuthorMutation, { data, loading, error }] = useDeleteArticleAuthorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArticleAuthorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArticleAuthorMutation, DeleteArticleAuthorMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteArticleAuthorMutation, DeleteArticleAuthorMutationVariables>(
    DeleteArticleAuthorDocument,
    baseOptions
  );
}
export type DeleteArticleAuthorMutationHookResult = ReturnType<typeof useDeleteArticleAuthorMutation>;
export type DeleteArticleAuthorMutationResult = ApolloReactCommon.MutationResult<DeleteArticleAuthorMutation>;
export type DeleteArticleAuthorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteArticleAuthorMutation,
  DeleteArticleAuthorMutationVariables
>;
export const DeleteArticleBlockDocument = gql`
  mutation deleteArticleBlock($id: Int!) {
    deleteArticleBlock(id: $id) {
      ...articleBlock
    }
  }
  ${ArticleBlockFragmentDoc}
`;
export type DeleteArticleBlockMutationFn = ApolloReactCommon.MutationFunction<
  DeleteArticleBlockMutation,
  DeleteArticleBlockMutationVariables
>;
export type DeleteArticleBlockComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteArticleBlockMutation, DeleteArticleBlockMutationVariables>,
  'mutation'
>;

export const DeleteArticleBlockComponent = (props: DeleteArticleBlockComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteArticleBlockMutation, DeleteArticleBlockMutationVariables>
    mutation={DeleteArticleBlockDocument}
    {...props}
  />
);

export type DeleteArticleBlockProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteArticleBlockMutation,
  DeleteArticleBlockMutationVariables
> &
  TChildProps;
export function withDeleteArticleBlock<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteArticleBlockMutation,
    DeleteArticleBlockMutationVariables,
    DeleteArticleBlockProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteArticleBlockMutation,
    DeleteArticleBlockMutationVariables,
    DeleteArticleBlockProps<TChildProps>
  >(DeleteArticleBlockDocument, {
    alias: 'deleteArticleBlock',
    ...operationOptions,
  });
}

/**
 * __useDeleteArticleBlockMutation__
 *
 * To run a mutation, you first call `useDeleteArticleBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleBlockMutation, { data, loading, error }] = useDeleteArticleBlockMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArticleBlockMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArticleBlockMutation, DeleteArticleBlockMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteArticleBlockMutation, DeleteArticleBlockMutationVariables>(
    DeleteArticleBlockDocument,
    baseOptions
  );
}
export type DeleteArticleBlockMutationHookResult = ReturnType<typeof useDeleteArticleBlockMutation>;
export type DeleteArticleBlockMutationResult = ApolloReactCommon.MutationResult<DeleteArticleBlockMutation>;
export type DeleteArticleBlockMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteArticleBlockMutation,
  DeleteArticleBlockMutationVariables
>;
export const DeleteArticleCategoryDocument = gql`
  mutation deleteArticleCategory($id: Int!) {
    deleteArticleCategory(id: $id) {
      ...articleCategory
    }
  }
  ${ArticleCategoryFragmentDoc}
`;
export type DeleteArticleCategoryMutationFn = ApolloReactCommon.MutationFunction<
  DeleteArticleCategoryMutation,
  DeleteArticleCategoryMutationVariables
>;
export type DeleteArticleCategoryComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteArticleCategoryMutation, DeleteArticleCategoryMutationVariables>,
  'mutation'
>;

export const DeleteArticleCategoryComponent = (props: DeleteArticleCategoryComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteArticleCategoryMutation, DeleteArticleCategoryMutationVariables>
    mutation={DeleteArticleCategoryDocument}
    {...props}
  />
);

export type DeleteArticleCategoryProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteArticleCategoryMutation,
  DeleteArticleCategoryMutationVariables
> &
  TChildProps;
export function withDeleteArticleCategory<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteArticleCategoryMutation,
    DeleteArticleCategoryMutationVariables,
    DeleteArticleCategoryProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteArticleCategoryMutation,
    DeleteArticleCategoryMutationVariables,
    DeleteArticleCategoryProps<TChildProps>
  >(DeleteArticleCategoryDocument, {
    alias: 'deleteArticleCategory',
    ...operationOptions,
  });
}

/**
 * __useDeleteArticleCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteArticleCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleCategoryMutation, { data, loading, error }] = useDeleteArticleCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArticleCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteArticleCategoryMutation,
    DeleteArticleCategoryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteArticleCategoryMutation, DeleteArticleCategoryMutationVariables>(
    DeleteArticleCategoryDocument,
    baseOptions
  );
}
export type DeleteArticleCategoryMutationHookResult = ReturnType<typeof useDeleteArticleCategoryMutation>;
export type DeleteArticleCategoryMutationResult = ApolloReactCommon.MutationResult<DeleteArticleCategoryMutation>;
export type DeleteArticleCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteArticleCategoryMutation,
  DeleteArticleCategoryMutationVariables
>;
export const DeleteArticleLinkDocument = gql`
  mutation deleteArticleLink($id: Int!) {
    deleteArticleLink(id: $id) {
      ...articleLink
    }
  }
  ${ArticleLinkFragmentDoc}
`;
export type DeleteArticleLinkMutationFn = ApolloReactCommon.MutationFunction<
  DeleteArticleLinkMutation,
  DeleteArticleLinkMutationVariables
>;
export type DeleteArticleLinkComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteArticleLinkMutation, DeleteArticleLinkMutationVariables>,
  'mutation'
>;

export const DeleteArticleLinkComponent = (props: DeleteArticleLinkComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteArticleLinkMutation, DeleteArticleLinkMutationVariables>
    mutation={DeleteArticleLinkDocument}
    {...props}
  />
);

export type DeleteArticleLinkProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteArticleLinkMutation,
  DeleteArticleLinkMutationVariables
> &
  TChildProps;
export function withDeleteArticleLink<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteArticleLinkMutation,
    DeleteArticleLinkMutationVariables,
    DeleteArticleLinkProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteArticleLinkMutation,
    DeleteArticleLinkMutationVariables,
    DeleteArticleLinkProps<TChildProps>
  >(DeleteArticleLinkDocument, {
    alias: 'deleteArticleLink',
    ...operationOptions,
  });
}

/**
 * __useDeleteArticleLinkMutation__
 *
 * To run a mutation, you first call `useDeleteArticleLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleLinkMutation, { data, loading, error }] = useDeleteArticleLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArticleLinkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArticleLinkMutation, DeleteArticleLinkMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteArticleLinkMutation, DeleteArticleLinkMutationVariables>(
    DeleteArticleLinkDocument,
    baseOptions
  );
}
export type DeleteArticleLinkMutationHookResult = ReturnType<typeof useDeleteArticleLinkMutation>;
export type DeleteArticleLinkMutationResult = ApolloReactCommon.MutationResult<DeleteArticleLinkMutation>;
export type DeleteArticleLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteArticleLinkMutation,
  DeleteArticleLinkMutationVariables
>;
export const DeleteArticleTagDocument = gql`
  mutation deleteArticleTag($id: Int!) {
    deleteArticleTag(id: $id) {
      ...articleTag
    }
  }
  ${ArticleTagFragmentDoc}
`;
export type DeleteArticleTagMutationFn = ApolloReactCommon.MutationFunction<
  DeleteArticleTagMutation,
  DeleteArticleTagMutationVariables
>;
export type DeleteArticleTagComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteArticleTagMutation, DeleteArticleTagMutationVariables>,
  'mutation'
>;

export const DeleteArticleTagComponent = (props: DeleteArticleTagComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteArticleTagMutation, DeleteArticleTagMutationVariables>
    mutation={DeleteArticleTagDocument}
    {...props}
  />
);

export type DeleteArticleTagProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteArticleTagMutation,
  DeleteArticleTagMutationVariables
> &
  TChildProps;
export function withDeleteArticleTag<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteArticleTagMutation,
    DeleteArticleTagMutationVariables,
    DeleteArticleTagProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteArticleTagMutation,
    DeleteArticleTagMutationVariables,
    DeleteArticleTagProps<TChildProps>
  >(DeleteArticleTagDocument, {
    alias: 'deleteArticleTag',
    ...operationOptions,
  });
}

/**
 * __useDeleteArticleTagMutation__
 *
 * To run a mutation, you first call `useDeleteArticleTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleTagMutation, { data, loading, error }] = useDeleteArticleTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArticleTagMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArticleTagMutation, DeleteArticleTagMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteArticleTagMutation, DeleteArticleTagMutationVariables>(
    DeleteArticleTagDocument,
    baseOptions
  );
}
export type DeleteArticleTagMutationHookResult = ReturnType<typeof useDeleteArticleTagMutation>;
export type DeleteArticleTagMutationResult = ApolloReactCommon.MutationResult<DeleteArticleTagMutation>;
export type DeleteArticleTagMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteArticleTagMutation,
  DeleteArticleTagMutationVariables
>;
export const DeleteCatalogueDocument = gql`
  mutation deleteCatalogue($id: Int!) {
    deleteCatalogue(id: $id) {
      ...catalogueFlat
    }
  }
  ${CatalogueFlatFragmentDoc}
`;
export type DeleteCatalogueMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCatalogueMutation,
  DeleteCatalogueMutationVariables
>;
export type DeleteCatalogueComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteCatalogueMutation, DeleteCatalogueMutationVariables>,
  'mutation'
>;

export const DeleteCatalogueComponent = (props: DeleteCatalogueComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteCatalogueMutation, DeleteCatalogueMutationVariables>
    mutation={DeleteCatalogueDocument}
    {...props}
  />
);

export type DeleteCatalogueProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteCatalogueMutation,
  DeleteCatalogueMutationVariables
> &
  TChildProps;
export function withDeleteCatalogue<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCatalogueMutation,
    DeleteCatalogueMutationVariables,
    DeleteCatalogueProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCatalogueMutation,
    DeleteCatalogueMutationVariables,
    DeleteCatalogueProps<TChildProps>
  >(DeleteCatalogueDocument, {
    alias: 'deleteCatalogue',
    ...operationOptions,
  });
}

/**
 * __useDeleteCatalogueMutation__
 *
 * To run a mutation, you first call `useDeleteCatalogueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCatalogueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCatalogueMutation, { data, loading, error }] = useDeleteCatalogueMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCatalogueMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCatalogueMutation, DeleteCatalogueMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteCatalogueMutation, DeleteCatalogueMutationVariables>(
    DeleteCatalogueDocument,
    baseOptions
  );
}
export type DeleteCatalogueMutationHookResult = ReturnType<typeof useDeleteCatalogueMutation>;
export type DeleteCatalogueMutationResult = ApolloReactCommon.MutationResult<DeleteCatalogueMutation>;
export type DeleteCatalogueMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCatalogueMutation,
  DeleteCatalogueMutationVariables
>;
export const DeleteCatalogueCategoryDocument = gql`
  mutation deleteCatalogueCategory($id: Int!) {
    deleteCatalogueCategory(id: $id) {
      ...catalogueCategory
    }
  }
  ${CatalogueCategoryFragmentDoc}
`;
export type DeleteCatalogueCategoryMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCatalogueCategoryMutation,
  DeleteCatalogueCategoryMutationVariables
>;
export type DeleteCatalogueCategoryComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteCatalogueCategoryMutation,
    DeleteCatalogueCategoryMutationVariables
  >,
  'mutation'
>;

export const DeleteCatalogueCategoryComponent = (props: DeleteCatalogueCategoryComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteCatalogueCategoryMutation, DeleteCatalogueCategoryMutationVariables>
    mutation={DeleteCatalogueCategoryDocument}
    {...props}
  />
);

export type DeleteCatalogueCategoryProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteCatalogueCategoryMutation,
  DeleteCatalogueCategoryMutationVariables
> &
  TChildProps;
export function withDeleteCatalogueCategory<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCatalogueCategoryMutation,
    DeleteCatalogueCategoryMutationVariables,
    DeleteCatalogueCategoryProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCatalogueCategoryMutation,
    DeleteCatalogueCategoryMutationVariables,
    DeleteCatalogueCategoryProps<TChildProps>
  >(DeleteCatalogueCategoryDocument, {
    alias: 'deleteCatalogueCategory',
    ...operationOptions,
  });
}

/**
 * __useDeleteCatalogueCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCatalogueCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCatalogueCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCatalogueCategoryMutation, { data, loading, error }] = useDeleteCatalogueCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCatalogueCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCatalogueCategoryMutation,
    DeleteCatalogueCategoryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteCatalogueCategoryMutation, DeleteCatalogueCategoryMutationVariables>(
    DeleteCatalogueCategoryDocument,
    baseOptions
  );
}
export type DeleteCatalogueCategoryMutationHookResult = ReturnType<typeof useDeleteCatalogueCategoryMutation>;
export type DeleteCatalogueCategoryMutationResult = ApolloReactCommon.MutationResult<DeleteCatalogueCategoryMutation>;
export type DeleteCatalogueCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCatalogueCategoryMutation,
  DeleteCatalogueCategoryMutationVariables
>;
export const DeleteCatalogueItemDocument = gql`
  mutation deleteCatalogueItem($id: Int!) {
    deleteCatalogueItem(id: $id) {
      ...catalogueItem
    }
  }
  ${CatalogueItemFragmentDoc}
`;
export type DeleteCatalogueItemMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCatalogueItemMutation,
  DeleteCatalogueItemMutationVariables
>;
export type DeleteCatalogueItemComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteCatalogueItemMutation, DeleteCatalogueItemMutationVariables>,
  'mutation'
>;

export const DeleteCatalogueItemComponent = (props: DeleteCatalogueItemComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteCatalogueItemMutation, DeleteCatalogueItemMutationVariables>
    mutation={DeleteCatalogueItemDocument}
    {...props}
  />
);

export type DeleteCatalogueItemProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteCatalogueItemMutation,
  DeleteCatalogueItemMutationVariables
> &
  TChildProps;
export function withDeleteCatalogueItem<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCatalogueItemMutation,
    DeleteCatalogueItemMutationVariables,
    DeleteCatalogueItemProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCatalogueItemMutation,
    DeleteCatalogueItemMutationVariables,
    DeleteCatalogueItemProps<TChildProps>
  >(DeleteCatalogueItemDocument, {
    alias: 'deleteCatalogueItem',
    ...operationOptions,
  });
}

/**
 * __useDeleteCatalogueItemMutation__
 *
 * To run a mutation, you first call `useDeleteCatalogueItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCatalogueItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCatalogueItemMutation, { data, loading, error }] = useDeleteCatalogueItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCatalogueItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCatalogueItemMutation, DeleteCatalogueItemMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteCatalogueItemMutation, DeleteCatalogueItemMutationVariables>(
    DeleteCatalogueItemDocument,
    baseOptions
  );
}
export type DeleteCatalogueItemMutationHookResult = ReturnType<typeof useDeleteCatalogueItemMutation>;
export type DeleteCatalogueItemMutationResult = ApolloReactCommon.MutationResult<DeleteCatalogueItemMutation>;
export type DeleteCatalogueItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCatalogueItemMutation,
  DeleteCatalogueItemMutationVariables
>;
export const DeleteCatalogueItemPriceDocument = gql`
  mutation deleteCatalogueItemPrice($id: Int!) {
    deleteCatalogueItemPrice(id: $id) {
      ...catalogueItemPrice
    }
  }
  ${CatalogueItemPriceFragmentDoc}
`;
export type DeleteCatalogueItemPriceMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCatalogueItemPriceMutation,
  DeleteCatalogueItemPriceMutationVariables
>;
export type DeleteCatalogueItemPriceComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteCatalogueItemPriceMutation,
    DeleteCatalogueItemPriceMutationVariables
  >,
  'mutation'
>;

export const DeleteCatalogueItemPriceComponent = (props: DeleteCatalogueItemPriceComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteCatalogueItemPriceMutation, DeleteCatalogueItemPriceMutationVariables>
    mutation={DeleteCatalogueItemPriceDocument}
    {...props}
  />
);

export type DeleteCatalogueItemPriceProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteCatalogueItemPriceMutation,
  DeleteCatalogueItemPriceMutationVariables
> &
  TChildProps;
export function withDeleteCatalogueItemPrice<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCatalogueItemPriceMutation,
    DeleteCatalogueItemPriceMutationVariables,
    DeleteCatalogueItemPriceProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCatalogueItemPriceMutation,
    DeleteCatalogueItemPriceMutationVariables,
    DeleteCatalogueItemPriceProps<TChildProps>
  >(DeleteCatalogueItemPriceDocument, {
    alias: 'deleteCatalogueItemPrice',
    ...operationOptions,
  });
}

/**
 * __useDeleteCatalogueItemPriceMutation__
 *
 * To run a mutation, you first call `useDeleteCatalogueItemPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCatalogueItemPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCatalogueItemPriceMutation, { data, loading, error }] = useDeleteCatalogueItemPriceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCatalogueItemPriceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCatalogueItemPriceMutation,
    DeleteCatalogueItemPriceMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteCatalogueItemPriceMutation, DeleteCatalogueItemPriceMutationVariables>(
    DeleteCatalogueItemPriceDocument,
    baseOptions
  );
}
export type DeleteCatalogueItemPriceMutationHookResult = ReturnType<typeof useDeleteCatalogueItemPriceMutation>;
export type DeleteCatalogueItemPriceMutationResult = ApolloReactCommon.MutationResult<DeleteCatalogueItemPriceMutation>;
export type DeleteCatalogueItemPriceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCatalogueItemPriceMutation,
  DeleteCatalogueItemPriceMutationVariables
>;
export const DeleteCateringDocument = gql`
  mutation deleteCatering($id: Int!) {
    deleteCatering(id: $id) {
      ...catering
    }
  }
  ${CateringFragmentDoc}
`;
export type DeleteCateringMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCateringMutation,
  DeleteCateringMutationVariables
>;
export type DeleteCateringComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteCateringMutation, DeleteCateringMutationVariables>,
  'mutation'
>;

export const DeleteCateringComponent = (props: DeleteCateringComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteCateringMutation, DeleteCateringMutationVariables>
    mutation={DeleteCateringDocument}
    {...props}
  />
);

export type DeleteCateringProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteCateringMutation,
  DeleteCateringMutationVariables
> &
  TChildProps;
export function withDeleteCatering<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCateringMutation,
    DeleteCateringMutationVariables,
    DeleteCateringProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCateringMutation,
    DeleteCateringMutationVariables,
    DeleteCateringProps<TChildProps>
  >(DeleteCateringDocument, {
    alias: 'deleteCatering',
    ...operationOptions,
  });
}

/**
 * __useDeleteCateringMutation__
 *
 * To run a mutation, you first call `useDeleteCateringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCateringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCateringMutation, { data, loading, error }] = useDeleteCateringMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCateringMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCateringMutation, DeleteCateringMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteCateringMutation, DeleteCateringMutationVariables>(
    DeleteCateringDocument,
    baseOptions
  );
}
export type DeleteCateringMutationHookResult = ReturnType<typeof useDeleteCateringMutation>;
export type DeleteCateringMutationResult = ApolloReactCommon.MutationResult<DeleteCateringMutation>;
export type DeleteCateringMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCateringMutation,
  DeleteCateringMutationVariables
>;
export const DeleteCommerceOrderDocument = gql`
  mutation deleteCommerceOrder($id: Int!) {
    deleteCommerceOrder(id: $id) {
      ...commerceOrder
    }
  }
  ${CommerceOrderFragmentDoc}
`;
export type DeleteCommerceOrderMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCommerceOrderMutation,
  DeleteCommerceOrderMutationVariables
>;
export type DeleteCommerceOrderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteCommerceOrderMutation, DeleteCommerceOrderMutationVariables>,
  'mutation'
>;

export const DeleteCommerceOrderComponent = (props: DeleteCommerceOrderComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteCommerceOrderMutation, DeleteCommerceOrderMutationVariables>
    mutation={DeleteCommerceOrderDocument}
    {...props}
  />
);

export type DeleteCommerceOrderProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteCommerceOrderMutation,
  DeleteCommerceOrderMutationVariables
> &
  TChildProps;
export function withDeleteCommerceOrder<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCommerceOrderMutation,
    DeleteCommerceOrderMutationVariables,
    DeleteCommerceOrderProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCommerceOrderMutation,
    DeleteCommerceOrderMutationVariables,
    DeleteCommerceOrderProps<TChildProps>
  >(DeleteCommerceOrderDocument, {
    alias: 'deleteCommerceOrder',
    ...operationOptions,
  });
}

/**
 * __useDeleteCommerceOrderMutation__
 *
 * To run a mutation, you first call `useDeleteCommerceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommerceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommerceOrderMutation, { data, loading, error }] = useDeleteCommerceOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommerceOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCommerceOrderMutation, DeleteCommerceOrderMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteCommerceOrderMutation, DeleteCommerceOrderMutationVariables>(
    DeleteCommerceOrderDocument,
    baseOptions
  );
}
export type DeleteCommerceOrderMutationHookResult = ReturnType<typeof useDeleteCommerceOrderMutation>;
export type DeleteCommerceOrderMutationResult = ApolloReactCommon.MutationResult<DeleteCommerceOrderMutation>;
export type DeleteCommerceOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCommerceOrderMutation,
  DeleteCommerceOrderMutationVariables
>;
export const DeleteCommerceOrderDataDocument = gql`
  mutation deleteCommerceOrderData($id: Int!) {
    deleteCommerceOrderData(id: $id) {
      ...commerceOrderData
    }
  }
  ${CommerceOrderDataFragmentDoc}
`;
export type DeleteCommerceOrderDataMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCommerceOrderDataMutation,
  DeleteCommerceOrderDataMutationVariables
>;
export type DeleteCommerceOrderDataComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteCommerceOrderDataMutation,
    DeleteCommerceOrderDataMutationVariables
  >,
  'mutation'
>;

export const DeleteCommerceOrderDataComponent = (props: DeleteCommerceOrderDataComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteCommerceOrderDataMutation, DeleteCommerceOrderDataMutationVariables>
    mutation={DeleteCommerceOrderDataDocument}
    {...props}
  />
);

export type DeleteCommerceOrderDataProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteCommerceOrderDataMutation,
  DeleteCommerceOrderDataMutationVariables
> &
  TChildProps;
export function withDeleteCommerceOrderData<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCommerceOrderDataMutation,
    DeleteCommerceOrderDataMutationVariables,
    DeleteCommerceOrderDataProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCommerceOrderDataMutation,
    DeleteCommerceOrderDataMutationVariables,
    DeleteCommerceOrderDataProps<TChildProps>
  >(DeleteCommerceOrderDataDocument, {
    alias: 'deleteCommerceOrderData',
    ...operationOptions,
  });
}

/**
 * __useDeleteCommerceOrderDataMutation__
 *
 * To run a mutation, you first call `useDeleteCommerceOrderDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommerceOrderDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommerceOrderDataMutation, { data, loading, error }] = useDeleteCommerceOrderDataMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommerceOrderDataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCommerceOrderDataMutation,
    DeleteCommerceOrderDataMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteCommerceOrderDataMutation, DeleteCommerceOrderDataMutationVariables>(
    DeleteCommerceOrderDataDocument,
    baseOptions
  );
}
export type DeleteCommerceOrderDataMutationHookResult = ReturnType<typeof useDeleteCommerceOrderDataMutation>;
export type DeleteCommerceOrderDataMutationResult = ApolloReactCommon.MutationResult<DeleteCommerceOrderDataMutation>;
export type DeleteCommerceOrderDataMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCommerceOrderDataMutation,
  DeleteCommerceOrderDataMutationVariables
>;
export const DeleteCommerceOrderFieldDocument = gql`
  mutation deleteCommerceOrderField($id: Int!) {
    deleteCommerceOrderField(id: $id) {
      ...commerceOrderField
    }
  }
  ${CommerceOrderFieldFragmentDoc}
`;
export type DeleteCommerceOrderFieldMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCommerceOrderFieldMutation,
  DeleteCommerceOrderFieldMutationVariables
>;
export type DeleteCommerceOrderFieldComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteCommerceOrderFieldMutation,
    DeleteCommerceOrderFieldMutationVariables
  >,
  'mutation'
>;

export const DeleteCommerceOrderFieldComponent = (props: DeleteCommerceOrderFieldComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteCommerceOrderFieldMutation, DeleteCommerceOrderFieldMutationVariables>
    mutation={DeleteCommerceOrderFieldDocument}
    {...props}
  />
);

export type DeleteCommerceOrderFieldProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteCommerceOrderFieldMutation,
  DeleteCommerceOrderFieldMutationVariables
> &
  TChildProps;
export function withDeleteCommerceOrderField<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCommerceOrderFieldMutation,
    DeleteCommerceOrderFieldMutationVariables,
    DeleteCommerceOrderFieldProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCommerceOrderFieldMutation,
    DeleteCommerceOrderFieldMutationVariables,
    DeleteCommerceOrderFieldProps<TChildProps>
  >(DeleteCommerceOrderFieldDocument, {
    alias: 'deleteCommerceOrderField',
    ...operationOptions,
  });
}

/**
 * __useDeleteCommerceOrderFieldMutation__
 *
 * To run a mutation, you first call `useDeleteCommerceOrderFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommerceOrderFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommerceOrderFieldMutation, { data, loading, error }] = useDeleteCommerceOrderFieldMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommerceOrderFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCommerceOrderFieldMutation,
    DeleteCommerceOrderFieldMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteCommerceOrderFieldMutation, DeleteCommerceOrderFieldMutationVariables>(
    DeleteCommerceOrderFieldDocument,
    baseOptions
  );
}
export type DeleteCommerceOrderFieldMutationHookResult = ReturnType<typeof useDeleteCommerceOrderFieldMutation>;
export type DeleteCommerceOrderFieldMutationResult = ApolloReactCommon.MutationResult<DeleteCommerceOrderFieldMutation>;
export type DeleteCommerceOrderFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCommerceOrderFieldMutation,
  DeleteCommerceOrderFieldMutationVariables
>;
export const DeleteCommerceOrderItemDocument = gql`
  mutation deleteCommerceOrderItem($id: Int!) {
    deleteCommerceOrderItem(id: $id) {
      ...commerceOrderItem
    }
  }
  ${CommerceOrderItemFragmentDoc}
`;
export type DeleteCommerceOrderItemMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCommerceOrderItemMutation,
  DeleteCommerceOrderItemMutationVariables
>;
export type DeleteCommerceOrderItemComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteCommerceOrderItemMutation,
    DeleteCommerceOrderItemMutationVariables
  >,
  'mutation'
>;

export const DeleteCommerceOrderItemComponent = (props: DeleteCommerceOrderItemComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteCommerceOrderItemMutation, DeleteCommerceOrderItemMutationVariables>
    mutation={DeleteCommerceOrderItemDocument}
    {...props}
  />
);

export type DeleteCommerceOrderItemProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteCommerceOrderItemMutation,
  DeleteCommerceOrderItemMutationVariables
> &
  TChildProps;
export function withDeleteCommerceOrderItem<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCommerceOrderItemMutation,
    DeleteCommerceOrderItemMutationVariables,
    DeleteCommerceOrderItemProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCommerceOrderItemMutation,
    DeleteCommerceOrderItemMutationVariables,
    DeleteCommerceOrderItemProps<TChildProps>
  >(DeleteCommerceOrderItemDocument, {
    alias: 'deleteCommerceOrderItem',
    ...operationOptions,
  });
}

/**
 * __useDeleteCommerceOrderItemMutation__
 *
 * To run a mutation, you first call `useDeleteCommerceOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommerceOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommerceOrderItemMutation, { data, loading, error }] = useDeleteCommerceOrderItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommerceOrderItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCommerceOrderItemMutation,
    DeleteCommerceOrderItemMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteCommerceOrderItemMutation, DeleteCommerceOrderItemMutationVariables>(
    DeleteCommerceOrderItemDocument,
    baseOptions
  );
}
export type DeleteCommerceOrderItemMutationHookResult = ReturnType<typeof useDeleteCommerceOrderItemMutation>;
export type DeleteCommerceOrderItemMutationResult = ApolloReactCommon.MutationResult<DeleteCommerceOrderItemMutation>;
export type DeleteCommerceOrderItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCommerceOrderItemMutation,
  DeleteCommerceOrderItemMutationVariables
>;
export const DeleteCommerceShopDocument = gql`
  mutation deleteCommerceShop($id: Int!) {
    deleteCommerceShop(id: $id) {
      ...commerceShop
    }
  }
  ${CommerceShopFragmentDoc}
`;
export type DeleteCommerceShopMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCommerceShopMutation,
  DeleteCommerceShopMutationVariables
>;
export type DeleteCommerceShopComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteCommerceShopMutation, DeleteCommerceShopMutationVariables>,
  'mutation'
>;

export const DeleteCommerceShopComponent = (props: DeleteCommerceShopComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteCommerceShopMutation, DeleteCommerceShopMutationVariables>
    mutation={DeleteCommerceShopDocument}
    {...props}
  />
);

export type DeleteCommerceShopProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteCommerceShopMutation,
  DeleteCommerceShopMutationVariables
> &
  TChildProps;
export function withDeleteCommerceShop<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCommerceShopMutation,
    DeleteCommerceShopMutationVariables,
    DeleteCommerceShopProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCommerceShopMutation,
    DeleteCommerceShopMutationVariables,
    DeleteCommerceShopProps<TChildProps>
  >(DeleteCommerceShopDocument, {
    alias: 'deleteCommerceShop',
    ...operationOptions,
  });
}

/**
 * __useDeleteCommerceShopMutation__
 *
 * To run a mutation, you first call `useDeleteCommerceShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommerceShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommerceShopMutation, { data, loading, error }] = useDeleteCommerceShopMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommerceShopMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCommerceShopMutation, DeleteCommerceShopMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteCommerceShopMutation, DeleteCommerceShopMutationVariables>(
    DeleteCommerceShopDocument,
    baseOptions
  );
}
export type DeleteCommerceShopMutationHookResult = ReturnType<typeof useDeleteCommerceShopMutation>;
export type DeleteCommerceShopMutationResult = ApolloReactCommon.MutationResult<DeleteCommerceShopMutation>;
export type DeleteCommerceShopMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCommerceShopMutation,
  DeleteCommerceShopMutationVariables
>;
export const DeleteContentTranslationDocument = gql`
  mutation deleteContentTranslation($id: Int!) {
    deleteContentTranslation(id: $id) {
      ...contentTranslation
    }
  }
  ${ContentTranslationFragmentDoc}
`;
export type DeleteContentTranslationMutationFn = ApolloReactCommon.MutationFunction<
  DeleteContentTranslationMutation,
  DeleteContentTranslationMutationVariables
>;
export type DeleteContentTranslationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteContentTranslationMutation,
    DeleteContentTranslationMutationVariables
  >,
  'mutation'
>;

export const DeleteContentTranslationComponent = (props: DeleteContentTranslationComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteContentTranslationMutation, DeleteContentTranslationMutationVariables>
    mutation={DeleteContentTranslationDocument}
    {...props}
  />
);

export type DeleteContentTranslationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteContentTranslationMutation,
  DeleteContentTranslationMutationVariables
> &
  TChildProps;
export function withDeleteContentTranslation<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteContentTranslationMutation,
    DeleteContentTranslationMutationVariables,
    DeleteContentTranslationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteContentTranslationMutation,
    DeleteContentTranslationMutationVariables,
    DeleteContentTranslationProps<TChildProps>
  >(DeleteContentTranslationDocument, {
    alias: 'deleteContentTranslation',
    ...operationOptions,
  });
}

/**
 * __useDeleteContentTranslationMutation__
 *
 * To run a mutation, you first call `useDeleteContentTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContentTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContentTranslationMutation, { data, loading, error }] = useDeleteContentTranslationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContentTranslationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteContentTranslationMutation,
    DeleteContentTranslationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteContentTranslationMutation, DeleteContentTranslationMutationVariables>(
    DeleteContentTranslationDocument,
    baseOptions
  );
}
export type DeleteContentTranslationMutationHookResult = ReturnType<typeof useDeleteContentTranslationMutation>;
export type DeleteContentTranslationMutationResult = ApolloReactCommon.MutationResult<DeleteContentTranslationMutation>;
export type DeleteContentTranslationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteContentTranslationMutation,
  DeleteContentTranslationMutationVariables
>;
export const DeleteEventDocument = gql`
  mutation deleteEvent($id: Int!) {
    deleteEvent(id: $id) {
      ...eventFull
    }
  }
  ${EventFullFragmentDoc}
`;
export type DeleteEventMutationFn = ApolloReactCommon.MutationFunction<
  DeleteEventMutation,
  DeleteEventMutationVariables
>;
export type DeleteEventComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteEventMutation, DeleteEventMutationVariables>,
  'mutation'
>;

export const DeleteEventComponent = (props: DeleteEventComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteEventMutation, DeleteEventMutationVariables>
    mutation={DeleteEventDocument}
    {...props}
  />
);

export type DeleteEventProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteEventMutation,
  DeleteEventMutationVariables
> &
  TChildProps;
export function withDeleteEvent<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteEventMutation,
    DeleteEventMutationVariables,
    DeleteEventProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteEventMutation,
    DeleteEventMutationVariables,
    DeleteEventProps<TChildProps>
  >(DeleteEventDocument, {
    alias: 'deleteEvent',
    ...operationOptions,
  });
}

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(
    DeleteEventDocument,
    baseOptions
  );
}
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = ApolloReactCommon.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteEventMutation,
  DeleteEventMutationVariables
>;
export const DeleteEventEventAccessDocument = gql`
  mutation deleteEventEventAccess($id: Int!) {
    deleteEventEventAccess(id: $id) {
      ...eventEventAccess
    }
  }
  ${EventEventAccessFragmentDoc}
`;
export type DeleteEventEventAccessMutationFn = ApolloReactCommon.MutationFunction<
  DeleteEventEventAccessMutation,
  DeleteEventEventAccessMutationVariables
>;
export type DeleteEventEventAccessComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteEventEventAccessMutation,
    DeleteEventEventAccessMutationVariables
  >,
  'mutation'
>;

export const DeleteEventEventAccessComponent = (props: DeleteEventEventAccessComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteEventEventAccessMutation, DeleteEventEventAccessMutationVariables>
    mutation={DeleteEventEventAccessDocument}
    {...props}
  />
);

export type DeleteEventEventAccessProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteEventEventAccessMutation,
  DeleteEventEventAccessMutationVariables
> &
  TChildProps;
export function withDeleteEventEventAccess<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteEventEventAccessMutation,
    DeleteEventEventAccessMutationVariables,
    DeleteEventEventAccessProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteEventEventAccessMutation,
    DeleteEventEventAccessMutationVariables,
    DeleteEventEventAccessProps<TChildProps>
  >(DeleteEventEventAccessDocument, {
    alias: 'deleteEventEventAccess',
    ...operationOptions,
  });
}

/**
 * __useDeleteEventEventAccessMutation__
 *
 * To run a mutation, you first call `useDeleteEventEventAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventEventAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventEventAccessMutation, { data, loading, error }] = useDeleteEventEventAccessMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventEventAccessMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteEventEventAccessMutation,
    DeleteEventEventAccessMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteEventEventAccessMutation, DeleteEventEventAccessMutationVariables>(
    DeleteEventEventAccessDocument,
    baseOptions
  );
}
export type DeleteEventEventAccessMutationHookResult = ReturnType<typeof useDeleteEventEventAccessMutation>;
export type DeleteEventEventAccessMutationResult = ApolloReactCommon.MutationResult<DeleteEventEventAccessMutation>;
export type DeleteEventEventAccessMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteEventEventAccessMutation,
  DeleteEventEventAccessMutationVariables
>;
export const DeleteEventOrderDocument = gql`
  mutation deleteEventOrder($id: Int!) {
    deleteEventOrder(id: $id) {
      ...eventOrder
    }
  }
  ${EventOrderFragmentDoc}
`;
export type DeleteEventOrderMutationFn = ApolloReactCommon.MutationFunction<
  DeleteEventOrderMutation,
  DeleteEventOrderMutationVariables
>;
export type DeleteEventOrderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteEventOrderMutation, DeleteEventOrderMutationVariables>,
  'mutation'
>;

export const DeleteEventOrderComponent = (props: DeleteEventOrderComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteEventOrderMutation, DeleteEventOrderMutationVariables>
    mutation={DeleteEventOrderDocument}
    {...props}
  />
);

export type DeleteEventOrderProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteEventOrderMutation,
  DeleteEventOrderMutationVariables
> &
  TChildProps;
export function withDeleteEventOrder<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteEventOrderMutation,
    DeleteEventOrderMutationVariables,
    DeleteEventOrderProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteEventOrderMutation,
    DeleteEventOrderMutationVariables,
    DeleteEventOrderProps<TChildProps>
  >(DeleteEventOrderDocument, {
    alias: 'deleteEventOrder',
    ...operationOptions,
  });
}

/**
 * __useDeleteEventOrderMutation__
 *
 * To run a mutation, you first call `useDeleteEventOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventOrderMutation, { data, loading, error }] = useDeleteEventOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEventOrderMutation, DeleteEventOrderMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteEventOrderMutation, DeleteEventOrderMutationVariables>(
    DeleteEventOrderDocument,
    baseOptions
  );
}
export type DeleteEventOrderMutationHookResult = ReturnType<typeof useDeleteEventOrderMutation>;
export type DeleteEventOrderMutationResult = ApolloReactCommon.MutationResult<DeleteEventOrderMutation>;
export type DeleteEventOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteEventOrderMutation,
  DeleteEventOrderMutationVariables
>;
export const DeleteEventPlaceDocument = gql`
  mutation deleteEventPlace($id: Int!) {
    deleteEventPlace(id: $id) {
      ...eventPlace
    }
  }
  ${EventPlaceFragmentDoc}
`;
export type DeleteEventPlaceMutationFn = ApolloReactCommon.MutationFunction<
  DeleteEventPlaceMutation,
  DeleteEventPlaceMutationVariables
>;
export type DeleteEventPlaceComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteEventPlaceMutation, DeleteEventPlaceMutationVariables>,
  'mutation'
>;

export const DeleteEventPlaceComponent = (props: DeleteEventPlaceComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteEventPlaceMutation, DeleteEventPlaceMutationVariables>
    mutation={DeleteEventPlaceDocument}
    {...props}
  />
);

export type DeleteEventPlaceProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteEventPlaceMutation,
  DeleteEventPlaceMutationVariables
> &
  TChildProps;
export function withDeleteEventPlace<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteEventPlaceMutation,
    DeleteEventPlaceMutationVariables,
    DeleteEventPlaceProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteEventPlaceMutation,
    DeleteEventPlaceMutationVariables,
    DeleteEventPlaceProps<TChildProps>
  >(DeleteEventPlaceDocument, {
    alias: 'deleteEventPlace',
    ...operationOptions,
  });
}

/**
 * __useDeleteEventPlaceMutation__
 *
 * To run a mutation, you first call `useDeleteEventPlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventPlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventPlaceMutation, { data, loading, error }] = useDeleteEventPlaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventPlaceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEventPlaceMutation, DeleteEventPlaceMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteEventPlaceMutation, DeleteEventPlaceMutationVariables>(
    DeleteEventPlaceDocument,
    baseOptions
  );
}
export type DeleteEventPlaceMutationHookResult = ReturnType<typeof useDeleteEventPlaceMutation>;
export type DeleteEventPlaceMutationResult = ApolloReactCommon.MutationResult<DeleteEventPlaceMutation>;
export type DeleteEventPlaceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteEventPlaceMutation,
  DeleteEventPlaceMutationVariables
>;
export const DeleteEventPlaceSectorDocument = gql`
  mutation deleteEventPlaceSector($id: Int!) {
    deleteEventPlaceSector(id: $id) {
      ...eventPlaceSector
    }
  }
  ${EventPlaceSectorFragmentDoc}
`;
export type DeleteEventPlaceSectorMutationFn = ApolloReactCommon.MutationFunction<
  DeleteEventPlaceSectorMutation,
  DeleteEventPlaceSectorMutationVariables
>;
export type DeleteEventPlaceSectorComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteEventPlaceSectorMutation,
    DeleteEventPlaceSectorMutationVariables
  >,
  'mutation'
>;

export const DeleteEventPlaceSectorComponent = (props: DeleteEventPlaceSectorComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteEventPlaceSectorMutation, DeleteEventPlaceSectorMutationVariables>
    mutation={DeleteEventPlaceSectorDocument}
    {...props}
  />
);

export type DeleteEventPlaceSectorProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteEventPlaceSectorMutation,
  DeleteEventPlaceSectorMutationVariables
> &
  TChildProps;
export function withDeleteEventPlaceSector<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteEventPlaceSectorMutation,
    DeleteEventPlaceSectorMutationVariables,
    DeleteEventPlaceSectorProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteEventPlaceSectorMutation,
    DeleteEventPlaceSectorMutationVariables,
    DeleteEventPlaceSectorProps<TChildProps>
  >(DeleteEventPlaceSectorDocument, {
    alias: 'deleteEventPlaceSector',
    ...operationOptions,
  });
}

/**
 * __useDeleteEventPlaceSectorMutation__
 *
 * To run a mutation, you first call `useDeleteEventPlaceSectorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventPlaceSectorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventPlaceSectorMutation, { data, loading, error }] = useDeleteEventPlaceSectorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventPlaceSectorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteEventPlaceSectorMutation,
    DeleteEventPlaceSectorMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteEventPlaceSectorMutation, DeleteEventPlaceSectorMutationVariables>(
    DeleteEventPlaceSectorDocument,
    baseOptions
  );
}
export type DeleteEventPlaceSectorMutationHookResult = ReturnType<typeof useDeleteEventPlaceSectorMutation>;
export type DeleteEventPlaceSectorMutationResult = ApolloReactCommon.MutationResult<DeleteEventPlaceSectorMutation>;
export type DeleteEventPlaceSectorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteEventPlaceSectorMutation,
  DeleteEventPlaceSectorMutationVariables
>;
export const DeleteEventTypeDocument = gql`
  mutation deleteEventType($id: Int!) {
    deleteEventType(id: $id) {
      ...eventType
    }
  }
  ${EventTypeFragmentDoc}
`;
export type DeleteEventTypeMutationFn = ApolloReactCommon.MutationFunction<
  DeleteEventTypeMutation,
  DeleteEventTypeMutationVariables
>;
export type DeleteEventTypeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteEventTypeMutation, DeleteEventTypeMutationVariables>,
  'mutation'
>;

export const DeleteEventTypeComponent = (props: DeleteEventTypeComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteEventTypeMutation, DeleteEventTypeMutationVariables>
    mutation={DeleteEventTypeDocument}
    {...props}
  />
);

export type DeleteEventTypeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteEventTypeMutation,
  DeleteEventTypeMutationVariables
> &
  TChildProps;
export function withDeleteEventType<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteEventTypeMutation,
    DeleteEventTypeMutationVariables,
    DeleteEventTypeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteEventTypeMutation,
    DeleteEventTypeMutationVariables,
    DeleteEventTypeProps<TChildProps>
  >(DeleteEventTypeDocument, {
    alias: 'deleteEventType',
    ...operationOptions,
  });
}

/**
 * __useDeleteEventTypeMutation__
 *
 * To run a mutation, you first call `useDeleteEventTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventTypeMutation, { data, loading, error }] = useDeleteEventTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventTypeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEventTypeMutation, DeleteEventTypeMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteEventTypeMutation, DeleteEventTypeMutationVariables>(
    DeleteEventTypeDocument,
    baseOptions
  );
}
export type DeleteEventTypeMutationHookResult = ReturnType<typeof useDeleteEventTypeMutation>;
export type DeleteEventTypeMutationResult = ApolloReactCommon.MutationResult<DeleteEventTypeMutation>;
export type DeleteEventTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteEventTypeMutation,
  DeleteEventTypeMutationVariables
>;
export const DeleteFromMessengerQueueDocument = gql`
  mutation deleteFromMessengerQueue($mailingId: Int!, $userId: [Int!]) {
    deleteFromMessengerQueue(mailingId: $mailingId, userId: $userId)
  }
`;
export type DeleteFromMessengerQueueMutationFn = ApolloReactCommon.MutationFunction<
  DeleteFromMessengerQueueMutation,
  DeleteFromMessengerQueueMutationVariables
>;
export type DeleteFromMessengerQueueComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteFromMessengerQueueMutation,
    DeleteFromMessengerQueueMutationVariables
  >,
  'mutation'
>;

export const DeleteFromMessengerQueueComponent = (props: DeleteFromMessengerQueueComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteFromMessengerQueueMutation, DeleteFromMessengerQueueMutationVariables>
    mutation={DeleteFromMessengerQueueDocument}
    {...props}
  />
);

export type DeleteFromMessengerQueueProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteFromMessengerQueueMutation,
  DeleteFromMessengerQueueMutationVariables
> &
  TChildProps;
export function withDeleteFromMessengerQueue<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteFromMessengerQueueMutation,
    DeleteFromMessengerQueueMutationVariables,
    DeleteFromMessengerQueueProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteFromMessengerQueueMutation,
    DeleteFromMessengerQueueMutationVariables,
    DeleteFromMessengerQueueProps<TChildProps>
  >(DeleteFromMessengerQueueDocument, {
    alias: 'deleteFromMessengerQueue',
    ...operationOptions,
  });
}

/**
 * __useDeleteFromMessengerQueueMutation__
 *
 * To run a mutation, you first call `useDeleteFromMessengerQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFromMessengerQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFromMessengerQueueMutation, { data, loading, error }] = useDeleteFromMessengerQueueMutation({
 *   variables: {
 *      mailingId: // value for 'mailingId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteFromMessengerQueueMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteFromMessengerQueueMutation,
    DeleteFromMessengerQueueMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteFromMessengerQueueMutation, DeleteFromMessengerQueueMutationVariables>(
    DeleteFromMessengerQueueDocument,
    baseOptions
  );
}
export type DeleteFromMessengerQueueMutationHookResult = ReturnType<typeof useDeleteFromMessengerQueueMutation>;
export type DeleteFromMessengerQueueMutationResult = ApolloReactCommon.MutationResult<DeleteFromMessengerQueueMutation>;
export type DeleteFromMessengerQueueMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteFromMessengerQueueMutation,
  DeleteFromMessengerQueueMutationVariables
>;
export const DeleteFromUsersSubscriptionDocument = gql`
  mutation deleteFromUsersSubscription($subscriptionId: Int!, $userId: [Int!]) {
    deleteFromUsersSubscription(subscriptionId: $subscriptionId, userId: $userId)
  }
`;
export type DeleteFromUsersSubscriptionMutationFn = ApolloReactCommon.MutationFunction<
  DeleteFromUsersSubscriptionMutation,
  DeleteFromUsersSubscriptionMutationVariables
>;
export type DeleteFromUsersSubscriptionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteFromUsersSubscriptionMutation,
    DeleteFromUsersSubscriptionMutationVariables
  >,
  'mutation'
>;

export const DeleteFromUsersSubscriptionComponent = (props: DeleteFromUsersSubscriptionComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteFromUsersSubscriptionMutation, DeleteFromUsersSubscriptionMutationVariables>
    mutation={DeleteFromUsersSubscriptionDocument}
    {...props}
  />
);

export type DeleteFromUsersSubscriptionProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteFromUsersSubscriptionMutation,
  DeleteFromUsersSubscriptionMutationVariables
> &
  TChildProps;
export function withDeleteFromUsersSubscription<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteFromUsersSubscriptionMutation,
    DeleteFromUsersSubscriptionMutationVariables,
    DeleteFromUsersSubscriptionProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteFromUsersSubscriptionMutation,
    DeleteFromUsersSubscriptionMutationVariables,
    DeleteFromUsersSubscriptionProps<TChildProps>
  >(DeleteFromUsersSubscriptionDocument, {
    alias: 'deleteFromUsersSubscription',
    ...operationOptions,
  });
}

/**
 * __useDeleteFromUsersSubscriptionMutation__
 *
 * To run a mutation, you first call `useDeleteFromUsersSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFromUsersSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFromUsersSubscriptionMutation, { data, loading, error }] = useDeleteFromUsersSubscriptionMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteFromUsersSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteFromUsersSubscriptionMutation,
    DeleteFromUsersSubscriptionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteFromUsersSubscriptionMutation,
    DeleteFromUsersSubscriptionMutationVariables
  >(DeleteFromUsersSubscriptionDocument, baseOptions);
}
export type DeleteFromUsersSubscriptionMutationHookResult = ReturnType<typeof useDeleteFromUsersSubscriptionMutation>;
export type DeleteFromUsersSubscriptionMutationResult = ApolloReactCommon.MutationResult<
  DeleteFromUsersSubscriptionMutation
>;
export type DeleteFromUsersSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteFromUsersSubscriptionMutation,
  DeleteFromUsersSubscriptionMutationVariables
>;
export const DeleteLoyaltyPartnerDocument = gql`
  mutation deleteLoyaltyPartner($id: Int!) {
    deleteLoyaltyPartner(id: $id) {
      ...loyaltyPartner
    }
  }
  ${LoyaltyPartnerFragmentDoc}
`;
export type DeleteLoyaltyPartnerMutationFn = ApolloReactCommon.MutationFunction<
  DeleteLoyaltyPartnerMutation,
  DeleteLoyaltyPartnerMutationVariables
>;
export type DeleteLoyaltyPartnerComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteLoyaltyPartnerMutation, DeleteLoyaltyPartnerMutationVariables>,
  'mutation'
>;

export const DeleteLoyaltyPartnerComponent = (props: DeleteLoyaltyPartnerComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteLoyaltyPartnerMutation, DeleteLoyaltyPartnerMutationVariables>
    mutation={DeleteLoyaltyPartnerDocument}
    {...props}
  />
);

export type DeleteLoyaltyPartnerProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteLoyaltyPartnerMutation,
  DeleteLoyaltyPartnerMutationVariables
> &
  TChildProps;
export function withDeleteLoyaltyPartner<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteLoyaltyPartnerMutation,
    DeleteLoyaltyPartnerMutationVariables,
    DeleteLoyaltyPartnerProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteLoyaltyPartnerMutation,
    DeleteLoyaltyPartnerMutationVariables,
    DeleteLoyaltyPartnerProps<TChildProps>
  >(DeleteLoyaltyPartnerDocument, {
    alias: 'deleteLoyaltyPartner',
    ...operationOptions,
  });
}

/**
 * __useDeleteLoyaltyPartnerMutation__
 *
 * To run a mutation, you first call `useDeleteLoyaltyPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLoyaltyPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLoyaltyPartnerMutation, { data, loading, error }] = useDeleteLoyaltyPartnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLoyaltyPartnerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteLoyaltyPartnerMutation,
    DeleteLoyaltyPartnerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteLoyaltyPartnerMutation, DeleteLoyaltyPartnerMutationVariables>(
    DeleteLoyaltyPartnerDocument,
    baseOptions
  );
}
export type DeleteLoyaltyPartnerMutationHookResult = ReturnType<typeof useDeleteLoyaltyPartnerMutation>;
export type DeleteLoyaltyPartnerMutationResult = ApolloReactCommon.MutationResult<DeleteLoyaltyPartnerMutation>;
export type DeleteLoyaltyPartnerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteLoyaltyPartnerMutation,
  DeleteLoyaltyPartnerMutationVariables
>;
export const DeleteLoyaltyPromoDocument = gql`
  mutation deleteLoyaltyPromo($id: Int!) {
    deleteLoyaltyPromo(id: $id) {
      ...loyaltyPromo
    }
  }
  ${LoyaltyPromoFragmentDoc}
`;
export type DeleteLoyaltyPromoMutationFn = ApolloReactCommon.MutationFunction<
  DeleteLoyaltyPromoMutation,
  DeleteLoyaltyPromoMutationVariables
>;
export type DeleteLoyaltyPromoComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteLoyaltyPromoMutation, DeleteLoyaltyPromoMutationVariables>,
  'mutation'
>;

export const DeleteLoyaltyPromoComponent = (props: DeleteLoyaltyPromoComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteLoyaltyPromoMutation, DeleteLoyaltyPromoMutationVariables>
    mutation={DeleteLoyaltyPromoDocument}
    {...props}
  />
);

export type DeleteLoyaltyPromoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteLoyaltyPromoMutation,
  DeleteLoyaltyPromoMutationVariables
> &
  TChildProps;
export function withDeleteLoyaltyPromo<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteLoyaltyPromoMutation,
    DeleteLoyaltyPromoMutationVariables,
    DeleteLoyaltyPromoProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteLoyaltyPromoMutation,
    DeleteLoyaltyPromoMutationVariables,
    DeleteLoyaltyPromoProps<TChildProps>
  >(DeleteLoyaltyPromoDocument, {
    alias: 'deleteLoyaltyPromo',
    ...operationOptions,
  });
}

/**
 * __useDeleteLoyaltyPromoMutation__
 *
 * To run a mutation, you first call `useDeleteLoyaltyPromoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLoyaltyPromoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLoyaltyPromoMutation, { data, loading, error }] = useDeleteLoyaltyPromoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLoyaltyPromoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteLoyaltyPromoMutation, DeleteLoyaltyPromoMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteLoyaltyPromoMutation, DeleteLoyaltyPromoMutationVariables>(
    DeleteLoyaltyPromoDocument,
    baseOptions
  );
}
export type DeleteLoyaltyPromoMutationHookResult = ReturnType<typeof useDeleteLoyaltyPromoMutation>;
export type DeleteLoyaltyPromoMutationResult = ApolloReactCommon.MutationResult<DeleteLoyaltyPromoMutation>;
export type DeleteLoyaltyPromoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteLoyaltyPromoMutation,
  DeleteLoyaltyPromoMutationVariables
>;
export const DeleteLoyaltyPromoCodeDocument = gql`
  mutation deleteLoyaltyPromoCode($id: Int!) {
    deleteLoyaltyPromoCode(id: $id) {
      ...loyaltyPromoCode
    }
  }
  ${LoyaltyPromoCodeFragmentDoc}
`;
export type DeleteLoyaltyPromoCodeMutationFn = ApolloReactCommon.MutationFunction<
  DeleteLoyaltyPromoCodeMutation,
  DeleteLoyaltyPromoCodeMutationVariables
>;
export type DeleteLoyaltyPromoCodeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteLoyaltyPromoCodeMutation,
    DeleteLoyaltyPromoCodeMutationVariables
  >,
  'mutation'
>;

export const DeleteLoyaltyPromoCodeComponent = (props: DeleteLoyaltyPromoCodeComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteLoyaltyPromoCodeMutation, DeleteLoyaltyPromoCodeMutationVariables>
    mutation={DeleteLoyaltyPromoCodeDocument}
    {...props}
  />
);

export type DeleteLoyaltyPromoCodeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteLoyaltyPromoCodeMutation,
  DeleteLoyaltyPromoCodeMutationVariables
> &
  TChildProps;
export function withDeleteLoyaltyPromoCode<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteLoyaltyPromoCodeMutation,
    DeleteLoyaltyPromoCodeMutationVariables,
    DeleteLoyaltyPromoCodeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteLoyaltyPromoCodeMutation,
    DeleteLoyaltyPromoCodeMutationVariables,
    DeleteLoyaltyPromoCodeProps<TChildProps>
  >(DeleteLoyaltyPromoCodeDocument, {
    alias: 'deleteLoyaltyPromoCode',
    ...operationOptions,
  });
}

/**
 * __useDeleteLoyaltyPromoCodeMutation__
 *
 * To run a mutation, you first call `useDeleteLoyaltyPromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLoyaltyPromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLoyaltyPromoCodeMutation, { data, loading, error }] = useDeleteLoyaltyPromoCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLoyaltyPromoCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteLoyaltyPromoCodeMutation,
    DeleteLoyaltyPromoCodeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteLoyaltyPromoCodeMutation, DeleteLoyaltyPromoCodeMutationVariables>(
    DeleteLoyaltyPromoCodeDocument,
    baseOptions
  );
}
export type DeleteLoyaltyPromoCodeMutationHookResult = ReturnType<typeof useDeleteLoyaltyPromoCodeMutation>;
export type DeleteLoyaltyPromoCodeMutationResult = ApolloReactCommon.MutationResult<DeleteLoyaltyPromoCodeMutation>;
export type DeleteLoyaltyPromoCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteLoyaltyPromoCodeMutation,
  DeleteLoyaltyPromoCodeMutationVariables
>;
export const DeleteMessengerMailingDocument = gql`
  mutation deleteMessengerMailing($id: Int!) {
    deleteMessengerMailing(id: $id) {
      ...messengerMailing
    }
  }
  ${MessengerMailingFragmentDoc}
`;
export type DeleteMessengerMailingMutationFn = ApolloReactCommon.MutationFunction<
  DeleteMessengerMailingMutation,
  DeleteMessengerMailingMutationVariables
>;
export type DeleteMessengerMailingComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteMessengerMailingMutation,
    DeleteMessengerMailingMutationVariables
  >,
  'mutation'
>;

export const DeleteMessengerMailingComponent = (props: DeleteMessengerMailingComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteMessengerMailingMutation, DeleteMessengerMailingMutationVariables>
    mutation={DeleteMessengerMailingDocument}
    {...props}
  />
);

export type DeleteMessengerMailingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteMessengerMailingMutation,
  DeleteMessengerMailingMutationVariables
> &
  TChildProps;
export function withDeleteMessengerMailing<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteMessengerMailingMutation,
    DeleteMessengerMailingMutationVariables,
    DeleteMessengerMailingProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteMessengerMailingMutation,
    DeleteMessengerMailingMutationVariables,
    DeleteMessengerMailingProps<TChildProps>
  >(DeleteMessengerMailingDocument, {
    alias: 'deleteMessengerMailing',
    ...operationOptions,
  });
}

/**
 * __useDeleteMessengerMailingMutation__
 *
 * To run a mutation, you first call `useDeleteMessengerMailingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessengerMailingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessengerMailingMutation, { data, loading, error }] = useDeleteMessengerMailingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMessengerMailingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteMessengerMailingMutation,
    DeleteMessengerMailingMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteMessengerMailingMutation, DeleteMessengerMailingMutationVariables>(
    DeleteMessengerMailingDocument,
    baseOptions
  );
}
export type DeleteMessengerMailingMutationHookResult = ReturnType<typeof useDeleteMessengerMailingMutation>;
export type DeleteMessengerMailingMutationResult = ApolloReactCommon.MutationResult<DeleteMessengerMailingMutation>;
export type DeleteMessengerMailingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteMessengerMailingMutation,
  DeleteMessengerMailingMutationVariables
>;
export const DeleteMessengerQueueNotificationDocument = gql`
  mutation deleteMessengerQueueNotification($id: String!) {
    deleteMessengerQueueNotification(id: $id) {
      ...messengerQueueNotification
    }
  }
  ${MessengerQueueNotificationFragmentDoc}
`;
export type DeleteMessengerQueueNotificationMutationFn = ApolloReactCommon.MutationFunction<
  DeleteMessengerQueueNotificationMutation,
  DeleteMessengerQueueNotificationMutationVariables
>;
export type DeleteMessengerQueueNotificationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteMessengerQueueNotificationMutation,
    DeleteMessengerQueueNotificationMutationVariables
  >,
  'mutation'
>;

export const DeleteMessengerQueueNotificationComponent = (props: DeleteMessengerQueueNotificationComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteMessengerQueueNotificationMutation,
    DeleteMessengerQueueNotificationMutationVariables
  >
    mutation={DeleteMessengerQueueNotificationDocument}
    {...props}
  />
);

export type DeleteMessengerQueueNotificationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteMessengerQueueNotificationMutation,
  DeleteMessengerQueueNotificationMutationVariables
> &
  TChildProps;
export function withDeleteMessengerQueueNotification<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteMessengerQueueNotificationMutation,
    DeleteMessengerQueueNotificationMutationVariables,
    DeleteMessengerQueueNotificationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteMessengerQueueNotificationMutation,
    DeleteMessengerQueueNotificationMutationVariables,
    DeleteMessengerQueueNotificationProps<TChildProps>
  >(DeleteMessengerQueueNotificationDocument, {
    alias: 'deleteMessengerQueueNotification',
    ...operationOptions,
  });
}

/**
 * __useDeleteMessengerQueueNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteMessengerQueueNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessengerQueueNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessengerQueueNotificationMutation, { data, loading, error }] = useDeleteMessengerQueueNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMessengerQueueNotificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteMessengerQueueNotificationMutation,
    DeleteMessengerQueueNotificationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteMessengerQueueNotificationMutation,
    DeleteMessengerQueueNotificationMutationVariables
  >(DeleteMessengerQueueNotificationDocument, baseOptions);
}
export type DeleteMessengerQueueNotificationMutationHookResult = ReturnType<
  typeof useDeleteMessengerQueueNotificationMutation
>;
export type DeleteMessengerQueueNotificationMutationResult = ApolloReactCommon.MutationResult<
  DeleteMessengerQueueNotificationMutation
>;
export type DeleteMessengerQueueNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteMessengerQueueNotificationMutation,
  DeleteMessengerQueueNotificationMutationVariables
>;
export const DeleteMessengerQueueTaskDocument = gql`
  mutation deleteMessengerQueueTask($id: Int!) {
    deleteMessengerQueueTask(id: $id) {
      ...messengerQueueTask
    }
  }
  ${MessengerQueueTaskFragmentDoc}
`;
export type DeleteMessengerQueueTaskMutationFn = ApolloReactCommon.MutationFunction<
  DeleteMessengerQueueTaskMutation,
  DeleteMessengerQueueTaskMutationVariables
>;
export type DeleteMessengerQueueTaskComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteMessengerQueueTaskMutation,
    DeleteMessengerQueueTaskMutationVariables
  >,
  'mutation'
>;

export const DeleteMessengerQueueTaskComponent = (props: DeleteMessengerQueueTaskComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteMessengerQueueTaskMutation, DeleteMessengerQueueTaskMutationVariables>
    mutation={DeleteMessengerQueueTaskDocument}
    {...props}
  />
);

export type DeleteMessengerQueueTaskProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteMessengerQueueTaskMutation,
  DeleteMessengerQueueTaskMutationVariables
> &
  TChildProps;
export function withDeleteMessengerQueueTask<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteMessengerQueueTaskMutation,
    DeleteMessengerQueueTaskMutationVariables,
    DeleteMessengerQueueTaskProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteMessengerQueueTaskMutation,
    DeleteMessengerQueueTaskMutationVariables,
    DeleteMessengerQueueTaskProps<TChildProps>
  >(DeleteMessengerQueueTaskDocument, {
    alias: 'deleteMessengerQueueTask',
    ...operationOptions,
  });
}

/**
 * __useDeleteMessengerQueueTaskMutation__
 *
 * To run a mutation, you first call `useDeleteMessengerQueueTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessengerQueueTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessengerQueueTaskMutation, { data, loading, error }] = useDeleteMessengerQueueTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMessengerQueueTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteMessengerQueueTaskMutation,
    DeleteMessengerQueueTaskMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteMessengerQueueTaskMutation, DeleteMessengerQueueTaskMutationVariables>(
    DeleteMessengerQueueTaskDocument,
    baseOptions
  );
}
export type DeleteMessengerQueueTaskMutationHookResult = ReturnType<typeof useDeleteMessengerQueueTaskMutation>;
export type DeleteMessengerQueueTaskMutationResult = ApolloReactCommon.MutationResult<DeleteMessengerQueueTaskMutation>;
export type DeleteMessengerQueueTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteMessengerQueueTaskMutation,
  DeleteMessengerQueueTaskMutationVariables
>;
export const DeleteMessengerTemplateDocument = gql`
  mutation deleteMessengerTemplate($id: Int!) {
    deleteMessengerTemplate(id: $id) {
      ...messengerTemplate
    }
  }
  ${MessengerTemplateFragmentDoc}
`;
export type DeleteMessengerTemplateMutationFn = ApolloReactCommon.MutationFunction<
  DeleteMessengerTemplateMutation,
  DeleteMessengerTemplateMutationVariables
>;
export type DeleteMessengerTemplateComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteMessengerTemplateMutation,
    DeleteMessengerTemplateMutationVariables
  >,
  'mutation'
>;

export const DeleteMessengerTemplateComponent = (props: DeleteMessengerTemplateComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteMessengerTemplateMutation, DeleteMessengerTemplateMutationVariables>
    mutation={DeleteMessengerTemplateDocument}
    {...props}
  />
);

export type DeleteMessengerTemplateProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteMessengerTemplateMutation,
  DeleteMessengerTemplateMutationVariables
> &
  TChildProps;
export function withDeleteMessengerTemplate<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteMessengerTemplateMutation,
    DeleteMessengerTemplateMutationVariables,
    DeleteMessengerTemplateProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteMessengerTemplateMutation,
    DeleteMessengerTemplateMutationVariables,
    DeleteMessengerTemplateProps<TChildProps>
  >(DeleteMessengerTemplateDocument, {
    alias: 'deleteMessengerTemplate',
    ...operationOptions,
  });
}

/**
 * __useDeleteMessengerTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteMessengerTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessengerTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessengerTemplateMutation, { data, loading, error }] = useDeleteMessengerTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMessengerTemplateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteMessengerTemplateMutation,
    DeleteMessengerTemplateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteMessengerTemplateMutation, DeleteMessengerTemplateMutationVariables>(
    DeleteMessengerTemplateDocument,
    baseOptions
  );
}
export type DeleteMessengerTemplateMutationHookResult = ReturnType<typeof useDeleteMessengerTemplateMutation>;
export type DeleteMessengerTemplateMutationResult = ApolloReactCommon.MutationResult<DeleteMessengerTemplateMutation>;
export type DeleteMessengerTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteMessengerTemplateMutation,
  DeleteMessengerTemplateMutationVariables
>;
export const DeleteMessengerTransportDocument = gql`
  mutation deleteMessengerTransport($id: Int!) {
    deleteMessengerTransport(id: $id) {
      ...messengerTransport
    }
  }
  ${MessengerTransportFragmentDoc}
`;
export type DeleteMessengerTransportMutationFn = ApolloReactCommon.MutationFunction<
  DeleteMessengerTransportMutation,
  DeleteMessengerTransportMutationVariables
>;
export type DeleteMessengerTransportComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteMessengerTransportMutation,
    DeleteMessengerTransportMutationVariables
  >,
  'mutation'
>;

export const DeleteMessengerTransportComponent = (props: DeleteMessengerTransportComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteMessengerTransportMutation, DeleteMessengerTransportMutationVariables>
    mutation={DeleteMessengerTransportDocument}
    {...props}
  />
);

export type DeleteMessengerTransportProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteMessengerTransportMutation,
  DeleteMessengerTransportMutationVariables
> &
  TChildProps;
export function withDeleteMessengerTransport<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteMessengerTransportMutation,
    DeleteMessengerTransportMutationVariables,
    DeleteMessengerTransportProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteMessengerTransportMutation,
    DeleteMessengerTransportMutationVariables,
    DeleteMessengerTransportProps<TChildProps>
  >(DeleteMessengerTransportDocument, {
    alias: 'deleteMessengerTransport',
    ...operationOptions,
  });
}

/**
 * __useDeleteMessengerTransportMutation__
 *
 * To run a mutation, you first call `useDeleteMessengerTransportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessengerTransportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessengerTransportMutation, { data, loading, error }] = useDeleteMessengerTransportMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMessengerTransportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteMessengerTransportMutation,
    DeleteMessengerTransportMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteMessengerTransportMutation, DeleteMessengerTransportMutationVariables>(
    DeleteMessengerTransportDocument,
    baseOptions
  );
}
export type DeleteMessengerTransportMutationHookResult = ReturnType<typeof useDeleteMessengerTransportMutation>;
export type DeleteMessengerTransportMutationResult = ApolloReactCommon.MutationResult<DeleteMessengerTransportMutation>;
export type DeleteMessengerTransportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteMessengerTransportMutation,
  DeleteMessengerTransportMutationVariables
>;
export const DeleteMobileTranslationDocument = gql`
  mutation deleteMobileTranslation($id: Int!) {
    deleteMobileTranslation(id: $id) {
      ...mobileTranslation
    }
  }
  ${MobileTranslationFragmentDoc}
`;
export type DeleteMobileTranslationMutationFn = ApolloReactCommon.MutationFunction<
  DeleteMobileTranslationMutation,
  DeleteMobileTranslationMutationVariables
>;
export type DeleteMobileTranslationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteMobileTranslationMutation,
    DeleteMobileTranslationMutationVariables
  >,
  'mutation'
>;

export const DeleteMobileTranslationComponent = (props: DeleteMobileTranslationComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteMobileTranslationMutation, DeleteMobileTranslationMutationVariables>
    mutation={DeleteMobileTranslationDocument}
    {...props}
  />
);

export type DeleteMobileTranslationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteMobileTranslationMutation,
  DeleteMobileTranslationMutationVariables
> &
  TChildProps;
export function withDeleteMobileTranslation<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteMobileTranslationMutation,
    DeleteMobileTranslationMutationVariables,
    DeleteMobileTranslationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteMobileTranslationMutation,
    DeleteMobileTranslationMutationVariables,
    DeleteMobileTranslationProps<TChildProps>
  >(DeleteMobileTranslationDocument, {
    alias: 'deleteMobileTranslation',
    ...operationOptions,
  });
}

/**
 * __useDeleteMobileTranslationMutation__
 *
 * To run a mutation, you first call `useDeleteMobileTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMobileTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMobileTranslationMutation, { data, loading, error }] = useDeleteMobileTranslationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMobileTranslationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteMobileTranslationMutation,
    DeleteMobileTranslationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteMobileTranslationMutation, DeleteMobileTranslationMutationVariables>(
    DeleteMobileTranslationDocument,
    baseOptions
  );
}
export type DeleteMobileTranslationMutationHookResult = ReturnType<typeof useDeleteMobileTranslationMutation>;
export type DeleteMobileTranslationMutationResult = ApolloReactCommon.MutationResult<DeleteMobileTranslationMutation>;
export type DeleteMobileTranslationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteMobileTranslationMutation,
  DeleteMobileTranslationMutationVariables
>;
export const DeletePaymentAccountDocument = gql`
  mutation deletePaymentAccount($id: Int!) {
    deletePaymentAccount(id: $id) {
      ...paymentAccount
    }
  }
  ${PaymentAccountFragmentDoc}
`;
export type DeletePaymentAccountMutationFn = ApolloReactCommon.MutationFunction<
  DeletePaymentAccountMutation,
  DeletePaymentAccountMutationVariables
>;
export type DeletePaymentAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeletePaymentAccountMutation, DeletePaymentAccountMutationVariables>,
  'mutation'
>;

export const DeletePaymentAccountComponent = (props: DeletePaymentAccountComponentProps) => (
  <ApolloReactComponents.Mutation<DeletePaymentAccountMutation, DeletePaymentAccountMutationVariables>
    mutation={DeletePaymentAccountDocument}
    {...props}
  />
);

export type DeletePaymentAccountProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeletePaymentAccountMutation,
  DeletePaymentAccountMutationVariables
> &
  TChildProps;
export function withDeletePaymentAccount<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeletePaymentAccountMutation,
    DeletePaymentAccountMutationVariables,
    DeletePaymentAccountProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeletePaymentAccountMutation,
    DeletePaymentAccountMutationVariables,
    DeletePaymentAccountProps<TChildProps>
  >(DeletePaymentAccountDocument, {
    alias: 'deletePaymentAccount',
    ...operationOptions,
  });
}

/**
 * __useDeletePaymentAccountMutation__
 *
 * To run a mutation, you first call `useDeletePaymentAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentAccountMutation, { data, loading, error }] = useDeletePaymentAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePaymentAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeletePaymentAccountMutation,
    DeletePaymentAccountMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeletePaymentAccountMutation, DeletePaymentAccountMutationVariables>(
    DeletePaymentAccountDocument,
    baseOptions
  );
}
export type DeletePaymentAccountMutationHookResult = ReturnType<typeof useDeletePaymentAccountMutation>;
export type DeletePaymentAccountMutationResult = ApolloReactCommon.MutationResult<DeletePaymentAccountMutation>;
export type DeletePaymentAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeletePaymentAccountMutation,
  DeletePaymentAccountMutationVariables
>;
export const DeletePaymentCurrencyDocument = gql`
  mutation deletePaymentCurrency($id: Int!) {
    deletePaymentCurrency(id: $id) {
      ...paymentCurrency
    }
  }
  ${PaymentCurrencyFragmentDoc}
`;
export type DeletePaymentCurrencyMutationFn = ApolloReactCommon.MutationFunction<
  DeletePaymentCurrencyMutation,
  DeletePaymentCurrencyMutationVariables
>;
export type DeletePaymentCurrencyComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeletePaymentCurrencyMutation, DeletePaymentCurrencyMutationVariables>,
  'mutation'
>;

export const DeletePaymentCurrencyComponent = (props: DeletePaymentCurrencyComponentProps) => (
  <ApolloReactComponents.Mutation<DeletePaymentCurrencyMutation, DeletePaymentCurrencyMutationVariables>
    mutation={DeletePaymentCurrencyDocument}
    {...props}
  />
);

export type DeletePaymentCurrencyProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeletePaymentCurrencyMutation,
  DeletePaymentCurrencyMutationVariables
> &
  TChildProps;
export function withDeletePaymentCurrency<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeletePaymentCurrencyMutation,
    DeletePaymentCurrencyMutationVariables,
    DeletePaymentCurrencyProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeletePaymentCurrencyMutation,
    DeletePaymentCurrencyMutationVariables,
    DeletePaymentCurrencyProps<TChildProps>
  >(DeletePaymentCurrencyDocument, {
    alias: 'deletePaymentCurrency',
    ...operationOptions,
  });
}

/**
 * __useDeletePaymentCurrencyMutation__
 *
 * To run a mutation, you first call `useDeletePaymentCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentCurrencyMutation, { data, loading, error }] = useDeletePaymentCurrencyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePaymentCurrencyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeletePaymentCurrencyMutation,
    DeletePaymentCurrencyMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeletePaymentCurrencyMutation, DeletePaymentCurrencyMutationVariables>(
    DeletePaymentCurrencyDocument,
    baseOptions
  );
}
export type DeletePaymentCurrencyMutationHookResult = ReturnType<typeof useDeletePaymentCurrencyMutation>;
export type DeletePaymentCurrencyMutationResult = ApolloReactCommon.MutationResult<DeletePaymentCurrencyMutation>;
export type DeletePaymentCurrencyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeletePaymentCurrencyMutation,
  DeletePaymentCurrencyMutationVariables
>;
export const DeletePaymentProviderDocument = gql`
  mutation deletePaymentProvider($id: Int!) {
    deletePaymentProvider(id: $id) {
      ...paymentProvider
    }
  }
  ${PaymentProviderFragmentDoc}
`;
export type DeletePaymentProviderMutationFn = ApolloReactCommon.MutationFunction<
  DeletePaymentProviderMutation,
  DeletePaymentProviderMutationVariables
>;
export type DeletePaymentProviderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeletePaymentProviderMutation, DeletePaymentProviderMutationVariables>,
  'mutation'
>;

export const DeletePaymentProviderComponent = (props: DeletePaymentProviderComponentProps) => (
  <ApolloReactComponents.Mutation<DeletePaymentProviderMutation, DeletePaymentProviderMutationVariables>
    mutation={DeletePaymentProviderDocument}
    {...props}
  />
);

export type DeletePaymentProviderProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeletePaymentProviderMutation,
  DeletePaymentProviderMutationVariables
> &
  TChildProps;
export function withDeletePaymentProvider<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeletePaymentProviderMutation,
    DeletePaymentProviderMutationVariables,
    DeletePaymentProviderProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeletePaymentProviderMutation,
    DeletePaymentProviderMutationVariables,
    DeletePaymentProviderProps<TChildProps>
  >(DeletePaymentProviderDocument, {
    alias: 'deletePaymentProvider',
    ...operationOptions,
  });
}

/**
 * __useDeletePaymentProviderMutation__
 *
 * To run a mutation, you first call `useDeletePaymentProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentProviderMutation, { data, loading, error }] = useDeletePaymentProviderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePaymentProviderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeletePaymentProviderMutation,
    DeletePaymentProviderMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeletePaymentProviderMutation, DeletePaymentProviderMutationVariables>(
    DeletePaymentProviderDocument,
    baseOptions
  );
}
export type DeletePaymentProviderMutationHookResult = ReturnType<typeof useDeletePaymentProviderMutation>;
export type DeletePaymentProviderMutationResult = ApolloReactCommon.MutationResult<DeletePaymentProviderMutation>;
export type DeletePaymentProviderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeletePaymentProviderMutation,
  DeletePaymentProviderMutationVariables
>;
export const DeletePaymentTransactionDocument = gql`
  mutation deletePaymentTransaction($id: Int!) {
    deletePaymentTransaction(id: $id) {
      ...paymentTransaction
    }
  }
  ${PaymentTransactionFragmentDoc}
`;
export type DeletePaymentTransactionMutationFn = ApolloReactCommon.MutationFunction<
  DeletePaymentTransactionMutation,
  DeletePaymentTransactionMutationVariables
>;
export type DeletePaymentTransactionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeletePaymentTransactionMutation,
    DeletePaymentTransactionMutationVariables
  >,
  'mutation'
>;

export const DeletePaymentTransactionComponent = (props: DeletePaymentTransactionComponentProps) => (
  <ApolloReactComponents.Mutation<DeletePaymentTransactionMutation, DeletePaymentTransactionMutationVariables>
    mutation={DeletePaymentTransactionDocument}
    {...props}
  />
);

export type DeletePaymentTransactionProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeletePaymentTransactionMutation,
  DeletePaymentTransactionMutationVariables
> &
  TChildProps;
export function withDeletePaymentTransaction<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeletePaymentTransactionMutation,
    DeletePaymentTransactionMutationVariables,
    DeletePaymentTransactionProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeletePaymentTransactionMutation,
    DeletePaymentTransactionMutationVariables,
    DeletePaymentTransactionProps<TChildProps>
  >(DeletePaymentTransactionDocument, {
    alias: 'deletePaymentTransaction',
    ...operationOptions,
  });
}

/**
 * __useDeletePaymentTransactionMutation__
 *
 * To run a mutation, you first call `useDeletePaymentTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentTransactionMutation, { data, loading, error }] = useDeletePaymentTransactionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePaymentTransactionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeletePaymentTransactionMutation,
    DeletePaymentTransactionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeletePaymentTransactionMutation, DeletePaymentTransactionMutationVariables>(
    DeletePaymentTransactionDocument,
    baseOptions
  );
}
export type DeletePaymentTransactionMutationHookResult = ReturnType<typeof useDeletePaymentTransactionMutation>;
export type DeletePaymentTransactionMutationResult = ApolloReactCommon.MutationResult<DeletePaymentTransactionMutation>;
export type DeletePaymentTransactionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeletePaymentTransactionMutation,
  DeletePaymentTransactionMutationVariables
>;
export const DeleteSystemPrinterDocument = gql`
  mutation deleteSystemPrinter($id: Int!) {
    deleteSystemPrinter(id: $id) {
      ...systemPrinter
    }
  }
  ${SystemPrinterFragmentDoc}
`;
export type DeleteSystemPrinterMutationFn = ApolloReactCommon.MutationFunction<
  DeleteSystemPrinterMutation,
  DeleteSystemPrinterMutationVariables
>;
export type DeleteSystemPrinterComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteSystemPrinterMutation, DeleteSystemPrinterMutationVariables>,
  'mutation'
>;

export const DeleteSystemPrinterComponent = (props: DeleteSystemPrinterComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteSystemPrinterMutation, DeleteSystemPrinterMutationVariables>
    mutation={DeleteSystemPrinterDocument}
    {...props}
  />
);

export type DeleteSystemPrinterProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteSystemPrinterMutation,
  DeleteSystemPrinterMutationVariables
> &
  TChildProps;
export function withDeleteSystemPrinter<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteSystemPrinterMutation,
    DeleteSystemPrinterMutationVariables,
    DeleteSystemPrinterProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteSystemPrinterMutation,
    DeleteSystemPrinterMutationVariables,
    DeleteSystemPrinterProps<TChildProps>
  >(DeleteSystemPrinterDocument, {
    alias: 'deleteSystemPrinter',
    ...operationOptions,
  });
}

/**
 * __useDeleteSystemPrinterMutation__
 *
 * To run a mutation, you first call `useDeleteSystemPrinterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSystemPrinterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSystemPrinterMutation, { data, loading, error }] = useDeleteSystemPrinterMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSystemPrinterMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSystemPrinterMutation, DeleteSystemPrinterMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteSystemPrinterMutation, DeleteSystemPrinterMutationVariables>(
    DeleteSystemPrinterDocument,
    baseOptions
  );
}
export type DeleteSystemPrinterMutationHookResult = ReturnType<typeof useDeleteSystemPrinterMutation>;
export type DeleteSystemPrinterMutationResult = ApolloReactCommon.MutationResult<DeleteSystemPrinterMutation>;
export type DeleteSystemPrinterMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteSystemPrinterMutation,
  DeleteSystemPrinterMutationVariables
>;
export const DeleteSystemTranslationDocument = gql`
  mutation deleteSystemTranslation($id: Int!) {
    deleteSystemTranslation(id: $id) {
      ...systemTranslation
    }
  }
  ${SystemTranslationFragmentDoc}
`;
export type DeleteSystemTranslationMutationFn = ApolloReactCommon.MutationFunction<
  DeleteSystemTranslationMutation,
  DeleteSystemTranslationMutationVariables
>;
export type DeleteSystemTranslationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteSystemTranslationMutation,
    DeleteSystemTranslationMutationVariables
  >,
  'mutation'
>;

export const DeleteSystemTranslationComponent = (props: DeleteSystemTranslationComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteSystemTranslationMutation, DeleteSystemTranslationMutationVariables>
    mutation={DeleteSystemTranslationDocument}
    {...props}
  />
);

export type DeleteSystemTranslationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteSystemTranslationMutation,
  DeleteSystemTranslationMutationVariables
> &
  TChildProps;
export function withDeleteSystemTranslation<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteSystemTranslationMutation,
    DeleteSystemTranslationMutationVariables,
    DeleteSystemTranslationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteSystemTranslationMutation,
    DeleteSystemTranslationMutationVariables,
    DeleteSystemTranslationProps<TChildProps>
  >(DeleteSystemTranslationDocument, {
    alias: 'deleteSystemTranslation',
    ...operationOptions,
  });
}

/**
 * __useDeleteSystemTranslationMutation__
 *
 * To run a mutation, you first call `useDeleteSystemTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSystemTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSystemTranslationMutation, { data, loading, error }] = useDeleteSystemTranslationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSystemTranslationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteSystemTranslationMutation,
    DeleteSystemTranslationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteSystemTranslationMutation, DeleteSystemTranslationMutationVariables>(
    DeleteSystemTranslationDocument,
    baseOptions
  );
}
export type DeleteSystemTranslationMutationHookResult = ReturnType<typeof useDeleteSystemTranslationMutation>;
export type DeleteSystemTranslationMutationResult = ApolloReactCommon.MutationResult<DeleteSystemTranslationMutation>;
export type DeleteSystemTranslationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteSystemTranslationMutation,
  DeleteSystemTranslationMutationVariables
>;
export const DeleteSystemWebHookDocument = gql`
  mutation deleteSystemWebHook($id: Int!) {
    deleteSystemWebHook(id: $id) {
      ...systemWebHook
    }
  }
  ${SystemWebHookFragmentDoc}
`;
export type DeleteSystemWebHookMutationFn = ApolloReactCommon.MutationFunction<
  DeleteSystemWebHookMutation,
  DeleteSystemWebHookMutationVariables
>;
export type DeleteSystemWebHookComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteSystemWebHookMutation, DeleteSystemWebHookMutationVariables>,
  'mutation'
>;

export const DeleteSystemWebHookComponent = (props: DeleteSystemWebHookComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteSystemWebHookMutation, DeleteSystemWebHookMutationVariables>
    mutation={DeleteSystemWebHookDocument}
    {...props}
  />
);

export type DeleteSystemWebHookProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteSystemWebHookMutation,
  DeleteSystemWebHookMutationVariables
> &
  TChildProps;
export function withDeleteSystemWebHook<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteSystemWebHookMutation,
    DeleteSystemWebHookMutationVariables,
    DeleteSystemWebHookProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteSystemWebHookMutation,
    DeleteSystemWebHookMutationVariables,
    DeleteSystemWebHookProps<TChildProps>
  >(DeleteSystemWebHookDocument, {
    alias: 'deleteSystemWebHook',
    ...operationOptions,
  });
}

/**
 * __useDeleteSystemWebHookMutation__
 *
 * To run a mutation, you first call `useDeleteSystemWebHookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSystemWebHookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSystemWebHookMutation, { data, loading, error }] = useDeleteSystemWebHookMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSystemWebHookMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSystemWebHookMutation, DeleteSystemWebHookMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteSystemWebHookMutation, DeleteSystemWebHookMutationVariables>(
    DeleteSystemWebHookDocument,
    baseOptions
  );
}
export type DeleteSystemWebHookMutationHookResult = ReturnType<typeof useDeleteSystemWebHookMutation>;
export type DeleteSystemWebHookMutationResult = ApolloReactCommon.MutationResult<DeleteSystemWebHookMutation>;
export type DeleteSystemWebHookMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteSystemWebHookMutation,
  DeleteSystemWebHookMutationVariables
>;
export const DeleteTemplateApplePassDocument = gql`
  mutation deleteTemplateApplePass($id: Int!) {
    deleteTemplateApplePass(id: $id) {
      ...templateApplePass
    }
  }
  ${TemplateApplePassFragmentDoc}
`;
export type DeleteTemplateApplePassMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTemplateApplePassMutation,
  DeleteTemplateApplePassMutationVariables
>;
export type DeleteTemplateApplePassComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteTemplateApplePassMutation,
    DeleteTemplateApplePassMutationVariables
  >,
  'mutation'
>;

export const DeleteTemplateApplePassComponent = (props: DeleteTemplateApplePassComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteTemplateApplePassMutation, DeleteTemplateApplePassMutationVariables>
    mutation={DeleteTemplateApplePassDocument}
    {...props}
  />
);

export type DeleteTemplateApplePassProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteTemplateApplePassMutation,
  DeleteTemplateApplePassMutationVariables
> &
  TChildProps;
export function withDeleteTemplateApplePass<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteTemplateApplePassMutation,
    DeleteTemplateApplePassMutationVariables,
    DeleteTemplateApplePassProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteTemplateApplePassMutation,
    DeleteTemplateApplePassMutationVariables,
    DeleteTemplateApplePassProps<TChildProps>
  >(DeleteTemplateApplePassDocument, {
    alias: 'deleteTemplateApplePass',
    ...operationOptions,
  });
}

/**
 * __useDeleteTemplateApplePassMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateApplePassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateApplePassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateApplePassMutation, { data, loading, error }] = useDeleteTemplateApplePassMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTemplateApplePassMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTemplateApplePassMutation,
    DeleteTemplateApplePassMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteTemplateApplePassMutation, DeleteTemplateApplePassMutationVariables>(
    DeleteTemplateApplePassDocument,
    baseOptions
  );
}
export type DeleteTemplateApplePassMutationHookResult = ReturnType<typeof useDeleteTemplateApplePassMutation>;
export type DeleteTemplateApplePassMutationResult = ApolloReactCommon.MutationResult<DeleteTemplateApplePassMutation>;
export type DeleteTemplateApplePassMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTemplateApplePassMutation,
  DeleteTemplateApplePassMutationVariables
>;
export const DeleteTemplatePdfDocument = gql`
  mutation deleteTemplatePDF($id: Int!) {
    deleteTemplatePDF(id: $id) {
      ...templatePDF
    }
  }
  ${TemplatePdfFragmentDoc}
`;
export type DeleteTemplatePdfMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTemplatePdfMutation,
  DeleteTemplatePdfMutationVariables
>;
export type DeleteTemplatePdfComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteTemplatePdfMutation, DeleteTemplatePdfMutationVariables>,
  'mutation'
>;

export const DeleteTemplatePdfComponent = (props: DeleteTemplatePdfComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteTemplatePdfMutation, DeleteTemplatePdfMutationVariables>
    mutation={DeleteTemplatePdfDocument}
    {...props}
  />
);

export type DeleteTemplatePdfProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteTemplatePdfMutation,
  DeleteTemplatePdfMutationVariables
> &
  TChildProps;
export function withDeleteTemplatePdf<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteTemplatePdfMutation,
    DeleteTemplatePdfMutationVariables,
    DeleteTemplatePdfProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteTemplatePdfMutation,
    DeleteTemplatePdfMutationVariables,
    DeleteTemplatePdfProps<TChildProps>
  >(DeleteTemplatePdfDocument, {
    alias: 'deleteTemplatePdf',
    ...operationOptions,
  });
}

/**
 * __useDeleteTemplatePdfMutation__
 *
 * To run a mutation, you first call `useDeleteTemplatePdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplatePdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplatePdfMutation, { data, loading, error }] = useDeleteTemplatePdfMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTemplatePdfMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTemplatePdfMutation, DeleteTemplatePdfMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteTemplatePdfMutation, DeleteTemplatePdfMutationVariables>(
    DeleteTemplatePdfDocument,
    baseOptions
  );
}
export type DeleteTemplatePdfMutationHookResult = ReturnType<typeof useDeleteTemplatePdfMutation>;
export type DeleteTemplatePdfMutationResult = ApolloReactCommon.MutationResult<DeleteTemplatePdfMutation>;
export type DeleteTemplatePdfMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTemplatePdfMutation,
  DeleteTemplatePdfMutationVariables
>;
export const DeleteTournamentDocument = gql`
  mutation deleteTournament($id: Int!) {
    deleteTournament(id: $id) {
      ...tournamentFull
    }
  }
  ${TournamentFullFragmentDoc}
`;
export type DeleteTournamentMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTournamentMutation,
  DeleteTournamentMutationVariables
>;
export type DeleteTournamentComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteTournamentMutation, DeleteTournamentMutationVariables>,
  'mutation'
>;

export const DeleteTournamentComponent = (props: DeleteTournamentComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteTournamentMutation, DeleteTournamentMutationVariables>
    mutation={DeleteTournamentDocument}
    {...props}
  />
);

export type DeleteTournamentProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteTournamentMutation,
  DeleteTournamentMutationVariables
> &
  TChildProps;
export function withDeleteTournament<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteTournamentMutation,
    DeleteTournamentMutationVariables,
    DeleteTournamentProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteTournamentMutation,
    DeleteTournamentMutationVariables,
    DeleteTournamentProps<TChildProps>
  >(DeleteTournamentDocument, {
    alias: 'deleteTournament',
    ...operationOptions,
  });
}

/**
 * __useDeleteTournamentMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentMutation, { data, loading, error }] = useDeleteTournamentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTournamentMutation, DeleteTournamentMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteTournamentMutation, DeleteTournamentMutationVariables>(
    DeleteTournamentDocument,
    baseOptions
  );
}
export type DeleteTournamentMutationHookResult = ReturnType<typeof useDeleteTournamentMutation>;
export type DeleteTournamentMutationResult = ApolloReactCommon.MutationResult<DeleteTournamentMutation>;
export type DeleteTournamentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTournamentMutation,
  DeleteTournamentMutationVariables
>;
export const DeleteTournamentDisqualificationDocument = gql`
  mutation deleteTournamentDisqualification($id: Int!) {
    deleteTournamentDisqualification(id: $id) {
      ...tournamentDisqualification
    }
  }
  ${TournamentDisqualificationFragmentDoc}
`;
export type DeleteTournamentDisqualificationMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTournamentDisqualificationMutation,
  DeleteTournamentDisqualificationMutationVariables
>;
export type DeleteTournamentDisqualificationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteTournamentDisqualificationMutation,
    DeleteTournamentDisqualificationMutationVariables
  >,
  'mutation'
>;

export const DeleteTournamentDisqualificationComponent = (props: DeleteTournamentDisqualificationComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteTournamentDisqualificationMutation,
    DeleteTournamentDisqualificationMutationVariables
  >
    mutation={DeleteTournamentDisqualificationDocument}
    {...props}
  />
);

export type DeleteTournamentDisqualificationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteTournamentDisqualificationMutation,
  DeleteTournamentDisqualificationMutationVariables
> &
  TChildProps;
export function withDeleteTournamentDisqualification<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteTournamentDisqualificationMutation,
    DeleteTournamentDisqualificationMutationVariables,
    DeleteTournamentDisqualificationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteTournamentDisqualificationMutation,
    DeleteTournamentDisqualificationMutationVariables,
    DeleteTournamentDisqualificationProps<TChildProps>
  >(DeleteTournamentDisqualificationDocument, {
    alias: 'deleteTournamentDisqualification',
    ...operationOptions,
  });
}

/**
 * __useDeleteTournamentDisqualificationMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentDisqualificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentDisqualificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentDisqualificationMutation, { data, loading, error }] = useDeleteTournamentDisqualificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentDisqualificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentDisqualificationMutation,
    DeleteTournamentDisqualificationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteTournamentDisqualificationMutation,
    DeleteTournamentDisqualificationMutationVariables
  >(DeleteTournamentDisqualificationDocument, baseOptions);
}
export type DeleteTournamentDisqualificationMutationHookResult = ReturnType<
  typeof useDeleteTournamentDisqualificationMutation
>;
export type DeleteTournamentDisqualificationMutationResult = ApolloReactCommon.MutationResult<
  DeleteTournamentDisqualificationMutation
>;
export type DeleteTournamentDisqualificationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTournamentDisqualificationMutation,
  DeleteTournamentDisqualificationMutationVariables
>;
export const DeleteTournamentMatchDocument = gql`
  mutation deleteTournamentMatch($id: Int!) {
    deleteTournamentMatch(id: $id) {
      ...tournamentMatch
    }
  }
  ${TournamentMatchFragmentDoc}
`;
export type DeleteTournamentMatchMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTournamentMatchMutation,
  DeleteTournamentMatchMutationVariables
>;
export type DeleteTournamentMatchComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteTournamentMatchMutation, DeleteTournamentMatchMutationVariables>,
  'mutation'
>;

export const DeleteTournamentMatchComponent = (props: DeleteTournamentMatchComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteTournamentMatchMutation, DeleteTournamentMatchMutationVariables>
    mutation={DeleteTournamentMatchDocument}
    {...props}
  />
);

export type DeleteTournamentMatchProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteTournamentMatchMutation,
  DeleteTournamentMatchMutationVariables
> &
  TChildProps;
export function withDeleteTournamentMatch<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteTournamentMatchMutation,
    DeleteTournamentMatchMutationVariables,
    DeleteTournamentMatchProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteTournamentMatchMutation,
    DeleteTournamentMatchMutationVariables,
    DeleteTournamentMatchProps<TChildProps>
  >(DeleteTournamentMatchDocument, {
    alias: 'deleteTournamentMatch',
    ...operationOptions,
  });
}

/**
 * __useDeleteTournamentMatchMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentMatchMutation, { data, loading, error }] = useDeleteTournamentMatchMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentMatchMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentMatchMutation,
    DeleteTournamentMatchMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteTournamentMatchMutation, DeleteTournamentMatchMutationVariables>(
    DeleteTournamentMatchDocument,
    baseOptions
  );
}
export type DeleteTournamentMatchMutationHookResult = ReturnType<typeof useDeleteTournamentMatchMutation>;
export type DeleteTournamentMatchMutationResult = ApolloReactCommon.MutationResult<DeleteTournamentMatchMutation>;
export type DeleteTournamentMatchMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTournamentMatchMutation,
  DeleteTournamentMatchMutationVariables
>;
export const DeleteTournamentMatchEventDocument = gql`
  mutation deleteTournamentMatchEvent($id: Int!) {
    deleteTournamentMatchEvent(id: $id) {
      ...tournamentMatchEvent
    }
  }
  ${TournamentMatchEventFragmentDoc}
`;
export type DeleteTournamentMatchEventMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTournamentMatchEventMutation,
  DeleteTournamentMatchEventMutationVariables
>;
export type DeleteTournamentMatchEventComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteTournamentMatchEventMutation,
    DeleteTournamentMatchEventMutationVariables
  >,
  'mutation'
>;

export const DeleteTournamentMatchEventComponent = (props: DeleteTournamentMatchEventComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteTournamentMatchEventMutation, DeleteTournamentMatchEventMutationVariables>
    mutation={DeleteTournamentMatchEventDocument}
    {...props}
  />
);

export type DeleteTournamentMatchEventProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteTournamentMatchEventMutation,
  DeleteTournamentMatchEventMutationVariables
> &
  TChildProps;
export function withDeleteTournamentMatchEvent<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteTournamentMatchEventMutation,
    DeleteTournamentMatchEventMutationVariables,
    DeleteTournamentMatchEventProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteTournamentMatchEventMutation,
    DeleteTournamentMatchEventMutationVariables,
    DeleteTournamentMatchEventProps<TChildProps>
  >(DeleteTournamentMatchEventDocument, {
    alias: 'deleteTournamentMatchEvent',
    ...operationOptions,
  });
}

/**
 * __useDeleteTournamentMatchEventMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentMatchEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentMatchEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentMatchEventMutation, { data, loading, error }] = useDeleteTournamentMatchEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentMatchEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentMatchEventMutation,
    DeleteTournamentMatchEventMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteTournamentMatchEventMutation, DeleteTournamentMatchEventMutationVariables>(
    DeleteTournamentMatchEventDocument,
    baseOptions
  );
}
export type DeleteTournamentMatchEventMutationHookResult = ReturnType<typeof useDeleteTournamentMatchEventMutation>;
export type DeleteTournamentMatchEventMutationResult = ApolloReactCommon.MutationResult<
  DeleteTournamentMatchEventMutation
>;
export type DeleteTournamentMatchEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTournamentMatchEventMutation,
  DeleteTournamentMatchEventMutationVariables
>;
export const DeleteTournamentMatchLineUpDocument = gql`
  mutation deleteTournamentMatchLineUp($id: Int!) {
    deleteTournamentMatchLineUp(id: $id) {
      ...tournamentMatchLineUp
    }
  }
  ${TournamentMatchLineUpFragmentDoc}
`;
export type DeleteTournamentMatchLineUpMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTournamentMatchLineUpMutation,
  DeleteTournamentMatchLineUpMutationVariables
>;
export type DeleteTournamentMatchLineUpComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteTournamentMatchLineUpMutation,
    DeleteTournamentMatchLineUpMutationVariables
  >,
  'mutation'
>;

export const DeleteTournamentMatchLineUpComponent = (props: DeleteTournamentMatchLineUpComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteTournamentMatchLineUpMutation, DeleteTournamentMatchLineUpMutationVariables>
    mutation={DeleteTournamentMatchLineUpDocument}
    {...props}
  />
);

export type DeleteTournamentMatchLineUpProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteTournamentMatchLineUpMutation,
  DeleteTournamentMatchLineUpMutationVariables
> &
  TChildProps;
export function withDeleteTournamentMatchLineUp<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteTournamentMatchLineUpMutation,
    DeleteTournamentMatchLineUpMutationVariables,
    DeleteTournamentMatchLineUpProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteTournamentMatchLineUpMutation,
    DeleteTournamentMatchLineUpMutationVariables,
    DeleteTournamentMatchLineUpProps<TChildProps>
  >(DeleteTournamentMatchLineUpDocument, {
    alias: 'deleteTournamentMatchLineUp',
    ...operationOptions,
  });
}

/**
 * __useDeleteTournamentMatchLineUpMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentMatchLineUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentMatchLineUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentMatchLineUpMutation, { data, loading, error }] = useDeleteTournamentMatchLineUpMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentMatchLineUpMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentMatchLineUpMutation,
    DeleteTournamentMatchLineUpMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteTournamentMatchLineUpMutation,
    DeleteTournamentMatchLineUpMutationVariables
  >(DeleteTournamentMatchLineUpDocument, baseOptions);
}
export type DeleteTournamentMatchLineUpMutationHookResult = ReturnType<typeof useDeleteTournamentMatchLineUpMutation>;
export type DeleteTournamentMatchLineUpMutationResult = ApolloReactCommon.MutationResult<
  DeleteTournamentMatchLineUpMutation
>;
export type DeleteTournamentMatchLineUpMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTournamentMatchLineUpMutation,
  DeleteTournamentMatchLineUpMutationVariables
>;
export const DeleteTournamentMatchProtocolDocument = gql`
  mutation deleteTournamentMatchProtocol($id: Int!) {
    deleteTournamentMatchProtocol(id: $id) {
      ...tournamentMatchProtocol
    }
  }
  ${TournamentMatchProtocolFragmentDoc}
`;
export type DeleteTournamentMatchProtocolMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTournamentMatchProtocolMutation,
  DeleteTournamentMatchProtocolMutationVariables
>;
export type DeleteTournamentMatchProtocolComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteTournamentMatchProtocolMutation,
    DeleteTournamentMatchProtocolMutationVariables
  >,
  'mutation'
>;

export const DeleteTournamentMatchProtocolComponent = (props: DeleteTournamentMatchProtocolComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteTournamentMatchProtocolMutation, DeleteTournamentMatchProtocolMutationVariables>
    mutation={DeleteTournamentMatchProtocolDocument}
    {...props}
  />
);

export type DeleteTournamentMatchProtocolProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteTournamentMatchProtocolMutation,
  DeleteTournamentMatchProtocolMutationVariables
> &
  TChildProps;
export function withDeleteTournamentMatchProtocol<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteTournamentMatchProtocolMutation,
    DeleteTournamentMatchProtocolMutationVariables,
    DeleteTournamentMatchProtocolProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteTournamentMatchProtocolMutation,
    DeleteTournamentMatchProtocolMutationVariables,
    DeleteTournamentMatchProtocolProps<TChildProps>
  >(DeleteTournamentMatchProtocolDocument, {
    alias: 'deleteTournamentMatchProtocol',
    ...operationOptions,
  });
}

/**
 * __useDeleteTournamentMatchProtocolMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentMatchProtocolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentMatchProtocolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentMatchProtocolMutation, { data, loading, error }] = useDeleteTournamentMatchProtocolMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentMatchProtocolMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentMatchProtocolMutation,
    DeleteTournamentMatchProtocolMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteTournamentMatchProtocolMutation,
    DeleteTournamentMatchProtocolMutationVariables
  >(DeleteTournamentMatchProtocolDocument, baseOptions);
}
export type DeleteTournamentMatchProtocolMutationHookResult = ReturnType<
  typeof useDeleteTournamentMatchProtocolMutation
>;
export type DeleteTournamentMatchProtocolMutationResult = ApolloReactCommon.MutationResult<
  DeleteTournamentMatchProtocolMutation
>;
export type DeleteTournamentMatchProtocolMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTournamentMatchProtocolMutation,
  DeleteTournamentMatchProtocolMutationVariables
>;
export const DeleteTournamentPlayerStatDocument = gql`
  mutation deleteTournamentPlayerStat($id: Int!) {
    deleteTournamentPlayerStat(id: $id) {
      ...tournamentPlayerStat
    }
  }
  ${TournamentPlayerStatFragmentDoc}
`;
export type DeleteTournamentPlayerStatMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTournamentPlayerStatMutation,
  DeleteTournamentPlayerStatMutationVariables
>;
export type DeleteTournamentPlayerStatComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteTournamentPlayerStatMutation,
    DeleteTournamentPlayerStatMutationVariables
  >,
  'mutation'
>;

export const DeleteTournamentPlayerStatComponent = (props: DeleteTournamentPlayerStatComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteTournamentPlayerStatMutation, DeleteTournamentPlayerStatMutationVariables>
    mutation={DeleteTournamentPlayerStatDocument}
    {...props}
  />
);

export type DeleteTournamentPlayerStatProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteTournamentPlayerStatMutation,
  DeleteTournamentPlayerStatMutationVariables
> &
  TChildProps;
export function withDeleteTournamentPlayerStat<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteTournamentPlayerStatMutation,
    DeleteTournamentPlayerStatMutationVariables,
    DeleteTournamentPlayerStatProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteTournamentPlayerStatMutation,
    DeleteTournamentPlayerStatMutationVariables,
    DeleteTournamentPlayerStatProps<TChildProps>
  >(DeleteTournamentPlayerStatDocument, {
    alias: 'deleteTournamentPlayerStat',
    ...operationOptions,
  });
}

/**
 * __useDeleteTournamentPlayerStatMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentPlayerStatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentPlayerStatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentPlayerStatMutation, { data, loading, error }] = useDeleteTournamentPlayerStatMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentPlayerStatMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentPlayerStatMutation,
    DeleteTournamentPlayerStatMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteTournamentPlayerStatMutation, DeleteTournamentPlayerStatMutationVariables>(
    DeleteTournamentPlayerStatDocument,
    baseOptions
  );
}
export type DeleteTournamentPlayerStatMutationHookResult = ReturnType<typeof useDeleteTournamentPlayerStatMutation>;
export type DeleteTournamentPlayerStatMutationResult = ApolloReactCommon.MutationResult<
  DeleteTournamentPlayerStatMutation
>;
export type DeleteTournamentPlayerStatMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTournamentPlayerStatMutation,
  DeleteTournamentPlayerStatMutationVariables
>;
export const DeleteTournamentRefereeDocument = gql`
  mutation deleteTournamentReferee($id: Int!) {
    deleteTournamentReferee(id: $id) {
      ...tournamentReferee
    }
  }
  ${TournamentRefereeFragmentDoc}
`;
export type DeleteTournamentRefereeMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTournamentRefereeMutation,
  DeleteTournamentRefereeMutationVariables
>;
export type DeleteTournamentRefereeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteTournamentRefereeMutation,
    DeleteTournamentRefereeMutationVariables
  >,
  'mutation'
>;

export const DeleteTournamentRefereeComponent = (props: DeleteTournamentRefereeComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteTournamentRefereeMutation, DeleteTournamentRefereeMutationVariables>
    mutation={DeleteTournamentRefereeDocument}
    {...props}
  />
);

export type DeleteTournamentRefereeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteTournamentRefereeMutation,
  DeleteTournamentRefereeMutationVariables
> &
  TChildProps;
export function withDeleteTournamentReferee<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteTournamentRefereeMutation,
    DeleteTournamentRefereeMutationVariables,
    DeleteTournamentRefereeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteTournamentRefereeMutation,
    DeleteTournamentRefereeMutationVariables,
    DeleteTournamentRefereeProps<TChildProps>
  >(DeleteTournamentRefereeDocument, {
    alias: 'deleteTournamentReferee',
    ...operationOptions,
  });
}

/**
 * __useDeleteTournamentRefereeMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentRefereeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentRefereeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentRefereeMutation, { data, loading, error }] = useDeleteTournamentRefereeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentRefereeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentRefereeMutation,
    DeleteTournamentRefereeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteTournamentRefereeMutation, DeleteTournamentRefereeMutationVariables>(
    DeleteTournamentRefereeDocument,
    baseOptions
  );
}
export type DeleteTournamentRefereeMutationHookResult = ReturnType<typeof useDeleteTournamentRefereeMutation>;
export type DeleteTournamentRefereeMutationResult = ApolloReactCommon.MutationResult<DeleteTournamentRefereeMutation>;
export type DeleteTournamentRefereeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTournamentRefereeMutation,
  DeleteTournamentRefereeMutationVariables
>;
export const DeleteTournamentSeasonDocument = gql`
  mutation deleteTournamentSeason($id: Int!) {
    deleteTournamentSeason(id: $id) {
      ...tournamentSeason
    }
  }
  ${TournamentSeasonFragmentDoc}
`;
export type DeleteTournamentSeasonMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTournamentSeasonMutation,
  DeleteTournamentSeasonMutationVariables
>;
export type DeleteTournamentSeasonComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteTournamentSeasonMutation,
    DeleteTournamentSeasonMutationVariables
  >,
  'mutation'
>;

export const DeleteTournamentSeasonComponent = (props: DeleteTournamentSeasonComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteTournamentSeasonMutation, DeleteTournamentSeasonMutationVariables>
    mutation={DeleteTournamentSeasonDocument}
    {...props}
  />
);

export type DeleteTournamentSeasonProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteTournamentSeasonMutation,
  DeleteTournamentSeasonMutationVariables
> &
  TChildProps;
export function withDeleteTournamentSeason<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteTournamentSeasonMutation,
    DeleteTournamentSeasonMutationVariables,
    DeleteTournamentSeasonProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteTournamentSeasonMutation,
    DeleteTournamentSeasonMutationVariables,
    DeleteTournamentSeasonProps<TChildProps>
  >(DeleteTournamentSeasonDocument, {
    alias: 'deleteTournamentSeason',
    ...operationOptions,
  });
}

/**
 * __useDeleteTournamentSeasonMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentSeasonMutation, { data, loading, error }] = useDeleteTournamentSeasonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentSeasonMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentSeasonMutation,
    DeleteTournamentSeasonMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteTournamentSeasonMutation, DeleteTournamentSeasonMutationVariables>(
    DeleteTournamentSeasonDocument,
    baseOptions
  );
}
export type DeleteTournamentSeasonMutationHookResult = ReturnType<typeof useDeleteTournamentSeasonMutation>;
export type DeleteTournamentSeasonMutationResult = ApolloReactCommon.MutationResult<DeleteTournamentSeasonMutation>;
export type DeleteTournamentSeasonMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTournamentSeasonMutation,
  DeleteTournamentSeasonMutationVariables
>;
export const DeleteTournamentSeasonTeamDocument = gql`
  mutation deleteTournamentSeasonTeam($id: Int!) {
    deleteTournamentSeasonTeam(id: $id) {
      ...tournamentSeasonTeam
    }
  }
  ${TournamentSeasonTeamFragmentDoc}
`;
export type DeleteTournamentSeasonTeamMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTournamentSeasonTeamMutation,
  DeleteTournamentSeasonTeamMutationVariables
>;
export type DeleteTournamentSeasonTeamComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteTournamentSeasonTeamMutation,
    DeleteTournamentSeasonTeamMutationVariables
  >,
  'mutation'
>;

export const DeleteTournamentSeasonTeamComponent = (props: DeleteTournamentSeasonTeamComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteTournamentSeasonTeamMutation, DeleteTournamentSeasonTeamMutationVariables>
    mutation={DeleteTournamentSeasonTeamDocument}
    {...props}
  />
);

export type DeleteTournamentSeasonTeamProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteTournamentSeasonTeamMutation,
  DeleteTournamentSeasonTeamMutationVariables
> &
  TChildProps;
export function withDeleteTournamentSeasonTeam<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteTournamentSeasonTeamMutation,
    DeleteTournamentSeasonTeamMutationVariables,
    DeleteTournamentSeasonTeamProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteTournamentSeasonTeamMutation,
    DeleteTournamentSeasonTeamMutationVariables,
    DeleteTournamentSeasonTeamProps<TChildProps>
  >(DeleteTournamentSeasonTeamDocument, {
    alias: 'deleteTournamentSeasonTeam',
    ...operationOptions,
  });
}

/**
 * __useDeleteTournamentSeasonTeamMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentSeasonTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentSeasonTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentSeasonTeamMutation, { data, loading, error }] = useDeleteTournamentSeasonTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentSeasonTeamMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentSeasonTeamMutation,
    DeleteTournamentSeasonTeamMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteTournamentSeasonTeamMutation, DeleteTournamentSeasonTeamMutationVariables>(
    DeleteTournamentSeasonTeamDocument,
    baseOptions
  );
}
export type DeleteTournamentSeasonTeamMutationHookResult = ReturnType<typeof useDeleteTournamentSeasonTeamMutation>;
export type DeleteTournamentSeasonTeamMutationResult = ApolloReactCommon.MutationResult<
  DeleteTournamentSeasonTeamMutation
>;
export type DeleteTournamentSeasonTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTournamentSeasonTeamMutation,
  DeleteTournamentSeasonTeamMutationVariables
>;
export const DeleteTournamentSeasonTeamStatDocument = gql`
  mutation deleteTournamentSeasonTeamStat($id: Int!) {
    deleteTournamentSeasonTeamStat(id: $id) {
      ...tournamentSeasonTeamStat
    }
  }
  ${TournamentSeasonTeamStatFragmentDoc}
`;
export type DeleteTournamentSeasonTeamStatMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTournamentSeasonTeamStatMutation,
  DeleteTournamentSeasonTeamStatMutationVariables
>;
export type DeleteTournamentSeasonTeamStatComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteTournamentSeasonTeamStatMutation,
    DeleteTournamentSeasonTeamStatMutationVariables
  >,
  'mutation'
>;

export const DeleteTournamentSeasonTeamStatComponent = (props: DeleteTournamentSeasonTeamStatComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteTournamentSeasonTeamStatMutation,
    DeleteTournamentSeasonTeamStatMutationVariables
  >
    mutation={DeleteTournamentSeasonTeamStatDocument}
    {...props}
  />
);

export type DeleteTournamentSeasonTeamStatProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteTournamentSeasonTeamStatMutation,
  DeleteTournamentSeasonTeamStatMutationVariables
> &
  TChildProps;
export function withDeleteTournamentSeasonTeamStat<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteTournamentSeasonTeamStatMutation,
    DeleteTournamentSeasonTeamStatMutationVariables,
    DeleteTournamentSeasonTeamStatProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteTournamentSeasonTeamStatMutation,
    DeleteTournamentSeasonTeamStatMutationVariables,
    DeleteTournamentSeasonTeamStatProps<TChildProps>
  >(DeleteTournamentSeasonTeamStatDocument, {
    alias: 'deleteTournamentSeasonTeamStat',
    ...operationOptions,
  });
}

/**
 * __useDeleteTournamentSeasonTeamStatMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentSeasonTeamStatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentSeasonTeamStatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentSeasonTeamStatMutation, { data, loading, error }] = useDeleteTournamentSeasonTeamStatMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentSeasonTeamStatMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentSeasonTeamStatMutation,
    DeleteTournamentSeasonTeamStatMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteTournamentSeasonTeamStatMutation,
    DeleteTournamentSeasonTeamStatMutationVariables
  >(DeleteTournamentSeasonTeamStatDocument, baseOptions);
}
export type DeleteTournamentSeasonTeamStatMutationHookResult = ReturnType<
  typeof useDeleteTournamentSeasonTeamStatMutation
>;
export type DeleteTournamentSeasonTeamStatMutationResult = ApolloReactCommon.MutationResult<
  DeleteTournamentSeasonTeamStatMutation
>;
export type DeleteTournamentSeasonTeamStatMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTournamentSeasonTeamStatMutation,
  DeleteTournamentSeasonTeamStatMutationVariables
>;
export const DeleteTournamentStadiumDocument = gql`
  mutation deleteTournamentStadium($id: Int!) {
    deleteTournamentStadium(id: $id) {
      ...tournamentStadium
    }
  }
  ${TournamentStadiumFragmentDoc}
`;
export type DeleteTournamentStadiumMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTournamentStadiumMutation,
  DeleteTournamentStadiumMutationVariables
>;
export type DeleteTournamentStadiumComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteTournamentStadiumMutation,
    DeleteTournamentStadiumMutationVariables
  >,
  'mutation'
>;

export const DeleteTournamentStadiumComponent = (props: DeleteTournamentStadiumComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteTournamentStadiumMutation, DeleteTournamentStadiumMutationVariables>
    mutation={DeleteTournamentStadiumDocument}
    {...props}
  />
);

export type DeleteTournamentStadiumProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteTournamentStadiumMutation,
  DeleteTournamentStadiumMutationVariables
> &
  TChildProps;
export function withDeleteTournamentStadium<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteTournamentStadiumMutation,
    DeleteTournamentStadiumMutationVariables,
    DeleteTournamentStadiumProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteTournamentStadiumMutation,
    DeleteTournamentStadiumMutationVariables,
    DeleteTournamentStadiumProps<TChildProps>
  >(DeleteTournamentStadiumDocument, {
    alias: 'deleteTournamentStadium',
    ...operationOptions,
  });
}

/**
 * __useDeleteTournamentStadiumMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentStadiumMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentStadiumMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentStadiumMutation, { data, loading, error }] = useDeleteTournamentStadiumMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentStadiumMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentStadiumMutation,
    DeleteTournamentStadiumMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteTournamentStadiumMutation, DeleteTournamentStadiumMutationVariables>(
    DeleteTournamentStadiumDocument,
    baseOptions
  );
}
export type DeleteTournamentStadiumMutationHookResult = ReturnType<typeof useDeleteTournamentStadiumMutation>;
export type DeleteTournamentStadiumMutationResult = ApolloReactCommon.MutationResult<DeleteTournamentStadiumMutation>;
export type DeleteTournamentStadiumMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTournamentStadiumMutation,
  DeleteTournamentStadiumMutationVariables
>;
export const DeleteTournamentTeamDocument = gql`
  mutation deleteTournamentTeam($id: Int!) {
    deleteTournamentTeam(id: $id) {
      ...tournamentTeam
    }
  }
  ${TournamentTeamFragmentDoc}
`;
export type DeleteTournamentTeamMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTournamentTeamMutation,
  DeleteTournamentTeamMutationVariables
>;
export type DeleteTournamentTeamComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteTournamentTeamMutation, DeleteTournamentTeamMutationVariables>,
  'mutation'
>;

export const DeleteTournamentTeamComponent = (props: DeleteTournamentTeamComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteTournamentTeamMutation, DeleteTournamentTeamMutationVariables>
    mutation={DeleteTournamentTeamDocument}
    {...props}
  />
);

export type DeleteTournamentTeamProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteTournamentTeamMutation,
  DeleteTournamentTeamMutationVariables
> &
  TChildProps;
export function withDeleteTournamentTeam<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteTournamentTeamMutation,
    DeleteTournamentTeamMutationVariables,
    DeleteTournamentTeamProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteTournamentTeamMutation,
    DeleteTournamentTeamMutationVariables,
    DeleteTournamentTeamProps<TChildProps>
  >(DeleteTournamentTeamDocument, {
    alias: 'deleteTournamentTeam',
    ...operationOptions,
  });
}

/**
 * __useDeleteTournamentTeamMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentTeamMutation, { data, loading, error }] = useDeleteTournamentTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentTeamMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentTeamMutation,
    DeleteTournamentTeamMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteTournamentTeamMutation, DeleteTournamentTeamMutationVariables>(
    DeleteTournamentTeamDocument,
    baseOptions
  );
}
export type DeleteTournamentTeamMutationHookResult = ReturnType<typeof useDeleteTournamentTeamMutation>;
export type DeleteTournamentTeamMutationResult = ApolloReactCommon.MutationResult<DeleteTournamentTeamMutation>;
export type DeleteTournamentTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTournamentTeamMutation,
  DeleteTournamentTeamMutationVariables
>;
export const DeleteTournamentTeamMemberDocument = gql`
  mutation deleteTournamentTeamMember($id: Int!) {
    deleteTournamentTeamMember(id: $id) {
      ...tournamentTeamMember
    }
  }
  ${TournamentTeamMemberFragmentDoc}
`;
export type DeleteTournamentTeamMemberMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTournamentTeamMemberMutation,
  DeleteTournamentTeamMemberMutationVariables
>;
export type DeleteTournamentTeamMemberComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteTournamentTeamMemberMutation,
    DeleteTournamentTeamMemberMutationVariables
  >,
  'mutation'
>;

export const DeleteTournamentTeamMemberComponent = (props: DeleteTournamentTeamMemberComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteTournamentTeamMemberMutation, DeleteTournamentTeamMemberMutationVariables>
    mutation={DeleteTournamentTeamMemberDocument}
    {...props}
  />
);

export type DeleteTournamentTeamMemberProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteTournamentTeamMemberMutation,
  DeleteTournamentTeamMemberMutationVariables
> &
  TChildProps;
export function withDeleteTournamentTeamMember<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteTournamentTeamMemberMutation,
    DeleteTournamentTeamMemberMutationVariables,
    DeleteTournamentTeamMemberProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteTournamentTeamMemberMutation,
    DeleteTournamentTeamMemberMutationVariables,
    DeleteTournamentTeamMemberProps<TChildProps>
  >(DeleteTournamentTeamMemberDocument, {
    alias: 'deleteTournamentTeamMember',
    ...operationOptions,
  });
}

/**
 * __useDeleteTournamentTeamMemberMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentTeamMemberMutation, { data, loading, error }] = useDeleteTournamentTeamMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentTeamMemberMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentTeamMemberMutation,
    DeleteTournamentTeamMemberMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteTournamentTeamMemberMutation, DeleteTournamentTeamMemberMutationVariables>(
    DeleteTournamentTeamMemberDocument,
    baseOptions
  );
}
export type DeleteTournamentTeamMemberMutationHookResult = ReturnType<typeof useDeleteTournamentTeamMemberMutation>;
export type DeleteTournamentTeamMemberMutationResult = ApolloReactCommon.MutationResult<
  DeleteTournamentTeamMemberMutation
>;
export type DeleteTournamentTeamMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTournamentTeamMemberMutation,
  DeleteTournamentTeamMemberMutationVariables
>;
export const DeleteTournamentTourDocument = gql`
  mutation deleteTournamentTour($id: Int!) {
    deleteTournamentTour(id: $id) {
      ...tournamentTour
    }
  }
  ${TournamentTourFragmentDoc}
`;
export type DeleteTournamentTourMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTournamentTourMutation,
  DeleteTournamentTourMutationVariables
>;
export type DeleteTournamentTourComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteTournamentTourMutation, DeleteTournamentTourMutationVariables>,
  'mutation'
>;

export const DeleteTournamentTourComponent = (props: DeleteTournamentTourComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteTournamentTourMutation, DeleteTournamentTourMutationVariables>
    mutation={DeleteTournamentTourDocument}
    {...props}
  />
);

export type DeleteTournamentTourProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteTournamentTourMutation,
  DeleteTournamentTourMutationVariables
> &
  TChildProps;
export function withDeleteTournamentTour<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteTournamentTourMutation,
    DeleteTournamentTourMutationVariables,
    DeleteTournamentTourProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteTournamentTourMutation,
    DeleteTournamentTourMutationVariables,
    DeleteTournamentTourProps<TChildProps>
  >(DeleteTournamentTourDocument, {
    alias: 'deleteTournamentTour',
    ...operationOptions,
  });
}

/**
 * __useDeleteTournamentTourMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentTourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentTourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentTourMutation, { data, loading, error }] = useDeleteTournamentTourMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentTourMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentTourMutation,
    DeleteTournamentTourMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteTournamentTourMutation, DeleteTournamentTourMutationVariables>(
    DeleteTournamentTourDocument,
    baseOptions
  );
}
export type DeleteTournamentTourMutationHookResult = ReturnType<typeof useDeleteTournamentTourMutation>;
export type DeleteTournamentTourMutationResult = ApolloReactCommon.MutationResult<DeleteTournamentTourMutation>;
export type DeleteTournamentTourMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTournamentTourMutation,
  DeleteTournamentTourMutationVariables
>;
export const DeleteUserApplicationTypeDocument = gql`
  mutation deleteUserApplicationType($id: Int!) {
    deleteUserApplicationType(id: $id) {
      ...userApplicationType
    }
  }
  ${UserApplicationTypeFragmentDoc}
`;
export type DeleteUserApplicationTypeMutationFn = ApolloReactCommon.MutationFunction<
  DeleteUserApplicationTypeMutation,
  DeleteUserApplicationTypeMutationVariables
>;
export type DeleteUserApplicationTypeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteUserApplicationTypeMutation,
    DeleteUserApplicationTypeMutationVariables
  >,
  'mutation'
>;

export const DeleteUserApplicationTypeComponent = (props: DeleteUserApplicationTypeComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteUserApplicationTypeMutation, DeleteUserApplicationTypeMutationVariables>
    mutation={DeleteUserApplicationTypeDocument}
    {...props}
  />
);

export type DeleteUserApplicationTypeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteUserApplicationTypeMutation,
  DeleteUserApplicationTypeMutationVariables
> &
  TChildProps;
export function withDeleteUserApplicationType<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteUserApplicationTypeMutation,
    DeleteUserApplicationTypeMutationVariables,
    DeleteUserApplicationTypeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteUserApplicationTypeMutation,
    DeleteUserApplicationTypeMutationVariables,
    DeleteUserApplicationTypeProps<TChildProps>
  >(DeleteUserApplicationTypeDocument, {
    alias: 'deleteUserApplicationType',
    ...operationOptions,
  });
}

/**
 * __useDeleteUserApplicationTypeMutation__
 *
 * To run a mutation, you first call `useDeleteUserApplicationTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserApplicationTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserApplicationTypeMutation, { data, loading, error }] = useDeleteUserApplicationTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserApplicationTypeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteUserApplicationTypeMutation,
    DeleteUserApplicationTypeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteUserApplicationTypeMutation, DeleteUserApplicationTypeMutationVariables>(
    DeleteUserApplicationTypeDocument,
    baseOptions
  );
}
export type DeleteUserApplicationTypeMutationHookResult = ReturnType<typeof useDeleteUserApplicationTypeMutation>;
export type DeleteUserApplicationTypeMutationResult = ApolloReactCommon.MutationResult<
  DeleteUserApplicationTypeMutation
>;
export type DeleteUserApplicationTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteUserApplicationTypeMutation,
  DeleteUserApplicationTypeMutationVariables
>;
export const DeleteUserByIdDocument = gql`
  mutation deleteUserById($id: Int!) {
    deleteUserById(id: $id)
  }
`;
export type DeleteUserByIdMutationFn = ApolloReactCommon.MutationFunction<
  DeleteUserByIdMutation,
  DeleteUserByIdMutationVariables
>;
export type DeleteUserByIdComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteUserByIdMutation, DeleteUserByIdMutationVariables>,
  'mutation'
>;

export const DeleteUserByIdComponent = (props: DeleteUserByIdComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteUserByIdMutation, DeleteUserByIdMutationVariables>
    mutation={DeleteUserByIdDocument}
    {...props}
  />
);

export type DeleteUserByIdProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteUserByIdMutation,
  DeleteUserByIdMutationVariables
> &
  TChildProps;
export function withDeleteUserById<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteUserByIdMutation,
    DeleteUserByIdMutationVariables,
    DeleteUserByIdProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteUserByIdMutation,
    DeleteUserByIdMutationVariables,
    DeleteUserByIdProps<TChildProps>
  >(DeleteUserByIdDocument, {
    alias: 'deleteUserById',
    ...operationOptions,
  });
}

/**
 * __useDeleteUserByIdMutation__
 *
 * To run a mutation, you first call `useDeleteUserByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserByIdMutation, { data, loading, error }] = useDeleteUserByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserByIdMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserByIdMutation, DeleteUserByIdMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteUserByIdMutation, DeleteUserByIdMutationVariables>(
    DeleteUserByIdDocument,
    baseOptions
  );
}
export type DeleteUserByIdMutationHookResult = ReturnType<typeof useDeleteUserByIdMutation>;
export type DeleteUserByIdMutationResult = ApolloReactCommon.MutationResult<DeleteUserByIdMutation>;
export type DeleteUserByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteUserByIdMutation,
  DeleteUserByIdMutationVariables
>;
export const DeleteUserFromGroupDocument = gql`
  mutation deleteUserFromGroup($groupId: [Int!]!, $query: DefaultQueryInput!) {
    deleteUserFromGroup(groupId: $groupId, query: $query) {
      id
      clientId
      entityId
      entityId
      operation
      state
      progress
      createdAt
      updatedAt
    }
  }
`;
export type DeleteUserFromGroupMutationFn = ApolloReactCommon.MutationFunction<
  DeleteUserFromGroupMutation,
  DeleteUserFromGroupMutationVariables
>;
export type DeleteUserFromGroupComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteUserFromGroupMutation, DeleteUserFromGroupMutationVariables>,
  'mutation'
>;

export const DeleteUserFromGroupComponent = (props: DeleteUserFromGroupComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteUserFromGroupMutation, DeleteUserFromGroupMutationVariables>
    mutation={DeleteUserFromGroupDocument}
    {...props}
  />
);

export type DeleteUserFromGroupProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteUserFromGroupMutation,
  DeleteUserFromGroupMutationVariables
> &
  TChildProps;
export function withDeleteUserFromGroup<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteUserFromGroupMutation,
    DeleteUserFromGroupMutationVariables,
    DeleteUserFromGroupProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteUserFromGroupMutation,
    DeleteUserFromGroupMutationVariables,
    DeleteUserFromGroupProps<TChildProps>
  >(DeleteUserFromGroupDocument, {
    alias: 'deleteUserFromGroup',
    ...operationOptions,
  });
}

/**
 * __useDeleteUserFromGroupMutation__
 *
 * To run a mutation, you first call `useDeleteUserFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserFromGroupMutation, { data, loading, error }] = useDeleteUserFromGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useDeleteUserFromGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserFromGroupMutation, DeleteUserFromGroupMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteUserFromGroupMutation, DeleteUserFromGroupMutationVariables>(
    DeleteUserFromGroupDocument,
    baseOptions
  );
}
export type DeleteUserFromGroupMutationHookResult = ReturnType<typeof useDeleteUserFromGroupMutation>;
export type DeleteUserFromGroupMutationResult = ApolloReactCommon.MutationResult<DeleteUserFromGroupMutation>;
export type DeleteUserFromGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteUserFromGroupMutation,
  DeleteUserFromGroupMutationVariables
>;
export const DeleteUserGroupDocument = gql`
  mutation deleteUserGroup($id: Int!) {
    deleteUserGroup(id: $id) {
      ...userGroup
    }
  }
  ${UserGroupFragmentDoc}
`;
export type DeleteUserGroupMutationFn = ApolloReactCommon.MutationFunction<
  DeleteUserGroupMutation,
  DeleteUserGroupMutationVariables
>;
export type DeleteUserGroupComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>,
  'mutation'
>;

export const DeleteUserGroupComponent = (props: DeleteUserGroupComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>
    mutation={DeleteUserGroupDocument}
    {...props}
  />
);

export type DeleteUserGroupProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteUserGroupMutation,
  DeleteUserGroupMutationVariables
> &
  TChildProps;
export function withDeleteUserGroup<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteUserGroupMutation,
    DeleteUserGroupMutationVariables,
    DeleteUserGroupProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteUserGroupMutation,
    DeleteUserGroupMutationVariables,
    DeleteUserGroupProps<TChildProps>
  >(DeleteUserGroupDocument, {
    alias: 'deleteUserGroup',
    ...operationOptions,
  });
}

/**
 * __useDeleteUserGroupMutation__
 *
 * To run a mutation, you first call `useDeleteUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserGroupMutation, { data, loading, error }] = useDeleteUserGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>(
    DeleteUserGroupDocument,
    baseOptions
  );
}
export type DeleteUserGroupMutationHookResult = ReturnType<typeof useDeleteUserGroupMutation>;
export type DeleteUserGroupMutationResult = ApolloReactCommon.MutationResult<DeleteUserGroupMutation>;
export type DeleteUserGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteUserGroupMutation,
  DeleteUserGroupMutationVariables
>;
export const DeleteUserSubscriberDocument = gql`
  mutation deleteUserSubscriber($id: String!) {
    deleteUserSubscriber(id: $id) {
      ...userSubscriber
    }
  }
  ${UserSubscriberFragmentDoc}
`;
export type DeleteUserSubscriberMutationFn = ApolloReactCommon.MutationFunction<
  DeleteUserSubscriberMutation,
  DeleteUserSubscriberMutationVariables
>;
export type DeleteUserSubscriberComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteUserSubscriberMutation, DeleteUserSubscriberMutationVariables>,
  'mutation'
>;

export const DeleteUserSubscriberComponent = (props: DeleteUserSubscriberComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteUserSubscriberMutation, DeleteUserSubscriberMutationVariables>
    mutation={DeleteUserSubscriberDocument}
    {...props}
  />
);

export type DeleteUserSubscriberProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteUserSubscriberMutation,
  DeleteUserSubscriberMutationVariables
> &
  TChildProps;
export function withDeleteUserSubscriber<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteUserSubscriberMutation,
    DeleteUserSubscriberMutationVariables,
    DeleteUserSubscriberProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteUserSubscriberMutation,
    DeleteUserSubscriberMutationVariables,
    DeleteUserSubscriberProps<TChildProps>
  >(DeleteUserSubscriberDocument, {
    alias: 'deleteUserSubscriber',
    ...operationOptions,
  });
}

/**
 * __useDeleteUserSubscriberMutation__
 *
 * To run a mutation, you first call `useDeleteUserSubscriberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserSubscriberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserSubscriberMutation, { data, loading, error }] = useDeleteUserSubscriberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserSubscriberMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteUserSubscriberMutation,
    DeleteUserSubscriberMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteUserSubscriberMutation, DeleteUserSubscriberMutationVariables>(
    DeleteUserSubscriberDocument,
    baseOptions
  );
}
export type DeleteUserSubscriberMutationHookResult = ReturnType<typeof useDeleteUserSubscriberMutation>;
export type DeleteUserSubscriberMutationResult = ApolloReactCommon.MutationResult<DeleteUserSubscriberMutation>;
export type DeleteUserSubscriberMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteUserSubscriberMutation,
  DeleteUserSubscriberMutationVariables
>;
export const DeleteUserSubscribersDocument = gql`
  mutation deleteUserSubscribers($id: [String!]!) {
    deleteUserSubscribers(id: $id)
  }
`;
export type DeleteUserSubscribersMutationFn = ApolloReactCommon.MutationFunction<
  DeleteUserSubscribersMutation,
  DeleteUserSubscribersMutationVariables
>;
export type DeleteUserSubscribersComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteUserSubscribersMutation, DeleteUserSubscribersMutationVariables>,
  'mutation'
>;

export const DeleteUserSubscribersComponent = (props: DeleteUserSubscribersComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteUserSubscribersMutation, DeleteUserSubscribersMutationVariables>
    mutation={DeleteUserSubscribersDocument}
    {...props}
  />
);

export type DeleteUserSubscribersProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteUserSubscribersMutation,
  DeleteUserSubscribersMutationVariables
> &
  TChildProps;
export function withDeleteUserSubscribers<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteUserSubscribersMutation,
    DeleteUserSubscribersMutationVariables,
    DeleteUserSubscribersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteUserSubscribersMutation,
    DeleteUserSubscribersMutationVariables,
    DeleteUserSubscribersProps<TChildProps>
  >(DeleteUserSubscribersDocument, {
    alias: 'deleteUserSubscribers',
    ...operationOptions,
  });
}

/**
 * __useDeleteUserSubscribersMutation__
 *
 * To run a mutation, you first call `useDeleteUserSubscribersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserSubscribersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserSubscribersMutation, { data, loading, error }] = useDeleteUserSubscribersMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserSubscribersMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteUserSubscribersMutation,
    DeleteUserSubscribersMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteUserSubscribersMutation, DeleteUserSubscribersMutationVariables>(
    DeleteUserSubscribersDocument,
    baseOptions
  );
}
export type DeleteUserSubscribersMutationHookResult = ReturnType<typeof useDeleteUserSubscribersMutation>;
export type DeleteUserSubscribersMutationResult = ApolloReactCommon.MutationResult<DeleteUserSubscribersMutation>;
export type DeleteUserSubscribersMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteUserSubscribersMutation,
  DeleteUserSubscribersMutationVariables
>;
export const DeleteUserSubscriptionDocument = gql`
  mutation deleteUserSubscription($id: Int!) {
    deleteUserSubscription(id: $id) {
      ...userSubscription
    }
  }
  ${UserSubscriptionFragmentDoc}
`;
export type DeleteUserSubscriptionMutationFn = ApolloReactCommon.MutationFunction<
  DeleteUserSubscriptionMutation,
  DeleteUserSubscriptionMutationVariables
>;
export type DeleteUserSubscriptionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteUserSubscriptionMutation,
    DeleteUserSubscriptionMutationVariables
  >,
  'mutation'
>;

export const DeleteUserSubscriptionComponent = (props: DeleteUserSubscriptionComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteUserSubscriptionMutation, DeleteUserSubscriptionMutationVariables>
    mutation={DeleteUserSubscriptionDocument}
    {...props}
  />
);

export type DeleteUserSubscriptionProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteUserSubscriptionMutation,
  DeleteUserSubscriptionMutationVariables
> &
  TChildProps;
export function withDeleteUserSubscription<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteUserSubscriptionMutation,
    DeleteUserSubscriptionMutationVariables,
    DeleteUserSubscriptionProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteUserSubscriptionMutation,
    DeleteUserSubscriptionMutationVariables,
    DeleteUserSubscriptionProps<TChildProps>
  >(DeleteUserSubscriptionDocument, {
    alias: 'deleteUserSubscription',
    ...operationOptions,
  });
}

/**
 * __useDeleteUserSubscriptionMutation__
 *
 * To run a mutation, you first call `useDeleteUserSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserSubscriptionMutation, { data, loading, error }] = useDeleteUserSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteUserSubscriptionMutation,
    DeleteUserSubscriptionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteUserSubscriptionMutation, DeleteUserSubscriptionMutationVariables>(
    DeleteUserSubscriptionDocument,
    baseOptions
  );
}
export type DeleteUserSubscriptionMutationHookResult = ReturnType<typeof useDeleteUserSubscriptionMutation>;
export type DeleteUserSubscriptionMutationResult = ApolloReactCommon.MutationResult<DeleteUserSubscriptionMutation>;
export type DeleteUserSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteUserSubscriptionMutation,
  DeleteUserSubscriptionMutationVariables
>;
export const DepositToAccountDocument = gql`
  mutation depositToAccount($data: PaymentAccountOperationInput!) {
    depositToAccount(data: $data) {
      amount
    }
  }
`;
export type DepositToAccountMutationFn = ApolloReactCommon.MutationFunction<
  DepositToAccountMutation,
  DepositToAccountMutationVariables
>;
export type DepositToAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DepositToAccountMutation, DepositToAccountMutationVariables>,
  'mutation'
>;

export const DepositToAccountComponent = (props: DepositToAccountComponentProps) => (
  <ApolloReactComponents.Mutation<DepositToAccountMutation, DepositToAccountMutationVariables>
    mutation={DepositToAccountDocument}
    {...props}
  />
);

export type DepositToAccountProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DepositToAccountMutation,
  DepositToAccountMutationVariables
> &
  TChildProps;
export function withDepositToAccount<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DepositToAccountMutation,
    DepositToAccountMutationVariables,
    DepositToAccountProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DepositToAccountMutation,
    DepositToAccountMutationVariables,
    DepositToAccountProps<TChildProps>
  >(DepositToAccountDocument, {
    alias: 'depositToAccount',
    ...operationOptions,
  });
}

/**
 * __useDepositToAccountMutation__
 *
 * To run a mutation, you first call `useDepositToAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDepositToAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [depositToAccountMutation, { data, loading, error }] = useDepositToAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDepositToAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DepositToAccountMutation, DepositToAccountMutationVariables>
) {
  return ApolloReactHooks.useMutation<DepositToAccountMutation, DepositToAccountMutationVariables>(
    DepositToAccountDocument,
    baseOptions
  );
}
export type DepositToAccountMutationHookResult = ReturnType<typeof useDepositToAccountMutation>;
export type DepositToAccountMutationResult = ApolloReactCommon.MutationResult<DepositToAccountMutation>;
export type DepositToAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DepositToAccountMutation,
  DepositToAccountMutationVariables
>;
export const ExportEventsTicketsDocument = gql`
  mutation exportEventsTickets($eventId: Int!) {
    exportEventsTickets(eventId: $eventId)
  }
`;
export type ExportEventsTicketsMutationFn = ApolloReactCommon.MutationFunction<
  ExportEventsTicketsMutation,
  ExportEventsTicketsMutationVariables
>;
export type ExportEventsTicketsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<ExportEventsTicketsMutation, ExportEventsTicketsMutationVariables>,
  'mutation'
>;

export const ExportEventsTicketsComponent = (props: ExportEventsTicketsComponentProps) => (
  <ApolloReactComponents.Mutation<ExportEventsTicketsMutation, ExportEventsTicketsMutationVariables>
    mutation={ExportEventsTicketsDocument}
    {...props}
  />
);

export type ExportEventsTicketsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  ExportEventsTicketsMutation,
  ExportEventsTicketsMutationVariables
> &
  TChildProps;
export function withExportEventsTickets<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ExportEventsTicketsMutation,
    ExportEventsTicketsMutationVariables,
    ExportEventsTicketsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ExportEventsTicketsMutation,
    ExportEventsTicketsMutationVariables,
    ExportEventsTicketsProps<TChildProps>
  >(ExportEventsTicketsDocument, {
    alias: 'exportEventsTickets',
    ...operationOptions,
  });
}

/**
 * __useExportEventsTicketsMutation__
 *
 * To run a mutation, you first call `useExportEventsTicketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportEventsTicketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportEventsTicketsMutation, { data, loading, error }] = useExportEventsTicketsMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useExportEventsTicketsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ExportEventsTicketsMutation, ExportEventsTicketsMutationVariables>
) {
  return ApolloReactHooks.useMutation<ExportEventsTicketsMutation, ExportEventsTicketsMutationVariables>(
    ExportEventsTicketsDocument,
    baseOptions
  );
}
export type ExportEventsTicketsMutationHookResult = ReturnType<typeof useExportEventsTicketsMutation>;
export type ExportEventsTicketsMutationResult = ApolloReactCommon.MutationResult<ExportEventsTicketsMutation>;
export type ExportEventsTicketsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ExportEventsTicketsMutation,
  ExportEventsTicketsMutationVariables
>;
export const ExportPromocodesDocument = gql`
  mutation exportPromocodes($promoId: Int!, $format: ExportFormat!) {
    exportPromocodes(promoId: $promoId, format: $format)
  }
`;
export type ExportPromocodesMutationFn = ApolloReactCommon.MutationFunction<
  ExportPromocodesMutation,
  ExportPromocodesMutationVariables
>;
export type ExportPromocodesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<ExportPromocodesMutation, ExportPromocodesMutationVariables>,
  'mutation'
>;

export const ExportPromocodesComponent = (props: ExportPromocodesComponentProps) => (
  <ApolloReactComponents.Mutation<ExportPromocodesMutation, ExportPromocodesMutationVariables>
    mutation={ExportPromocodesDocument}
    {...props}
  />
);

export type ExportPromocodesProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  ExportPromocodesMutation,
  ExportPromocodesMutationVariables
> &
  TChildProps;
export function withExportPromocodes<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ExportPromocodesMutation,
    ExportPromocodesMutationVariables,
    ExportPromocodesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ExportPromocodesMutation,
    ExportPromocodesMutationVariables,
    ExportPromocodesProps<TChildProps>
  >(ExportPromocodesDocument, {
    alias: 'exportPromocodes',
    ...operationOptions,
  });
}

/**
 * __useExportPromocodesMutation__
 *
 * To run a mutation, you first call `useExportPromocodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportPromocodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportPromocodesMutation, { data, loading, error }] = useExportPromocodesMutation({
 *   variables: {
 *      promoId: // value for 'promoId'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useExportPromocodesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ExportPromocodesMutation, ExportPromocodesMutationVariables>
) {
  return ApolloReactHooks.useMutation<ExportPromocodesMutation, ExportPromocodesMutationVariables>(
    ExportPromocodesDocument,
    baseOptions
  );
}
export type ExportPromocodesMutationHookResult = ReturnType<typeof useExportPromocodesMutation>;
export type ExportPromocodesMutationResult = ApolloReactCommon.MutationResult<ExportPromocodesMutation>;
export type ExportPromocodesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ExportPromocodesMutation,
  ExportPromocodesMutationVariables
>;
export const ExportUsersDocument = gql`
  mutation exportUsers($query: DefaultQueryInput, $settings: ExportSettingsInput) {
    exportUsers(query: $query, settings: $settings) {
      model
      fields {
        name
        title
        isExtension
      }
      fileUrl
      format
    }
  }
`;
export type ExportUsersMutationFn = ApolloReactCommon.MutationFunction<
  ExportUsersMutation,
  ExportUsersMutationVariables
>;
export type ExportUsersComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<ExportUsersMutation, ExportUsersMutationVariables>,
  'mutation'
>;

export const ExportUsersComponent = (props: ExportUsersComponentProps) => (
  <ApolloReactComponents.Mutation<ExportUsersMutation, ExportUsersMutationVariables>
    mutation={ExportUsersDocument}
    {...props}
  />
);

export type ExportUsersProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  ExportUsersMutation,
  ExportUsersMutationVariables
> &
  TChildProps;
export function withExportUsers<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ExportUsersMutation,
    ExportUsersMutationVariables,
    ExportUsersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ExportUsersMutation,
    ExportUsersMutationVariables,
    ExportUsersProps<TChildProps>
  >(ExportUsersDocument, {
    alias: 'exportUsers',
    ...operationOptions,
  });
}

/**
 * __useExportUsersMutation__
 *
 * To run a mutation, you first call `useExportUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportUsersMutation, { data, loading, error }] = useExportUsersMutation({
 *   variables: {
 *      query: // value for 'query'
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useExportUsersMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ExportUsersMutation, ExportUsersMutationVariables>
) {
  return ApolloReactHooks.useMutation<ExportUsersMutation, ExportUsersMutationVariables>(
    ExportUsersDocument,
    baseOptions
  );
}
export type ExportUsersMutationHookResult = ReturnType<typeof useExportUsersMutation>;
export type ExportUsersMutationResult = ApolloReactCommon.MutationResult<ExportUsersMutation>;
export type ExportUsersMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ExportUsersMutation,
  ExportUsersMutationVariables
>;
export const ExportUsersToUnisenderDocument = gql`
  mutation exportUsersToUnisender($query: DefaultQueryInput, $settings: ExportUnisenderSettingsInput!) {
    exportUsersToUnisender(query: $query, settings: $settings) {
      model
      fields {
        name
        title
        isExtension
      }
      fileUrl
      format
    }
  }
`;
export type ExportUsersToUnisenderMutationFn = ApolloReactCommon.MutationFunction<
  ExportUsersToUnisenderMutation,
  ExportUsersToUnisenderMutationVariables
>;
export type ExportUsersToUnisenderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ExportUsersToUnisenderMutation,
    ExportUsersToUnisenderMutationVariables
  >,
  'mutation'
>;

export const ExportUsersToUnisenderComponent = (props: ExportUsersToUnisenderComponentProps) => (
  <ApolloReactComponents.Mutation<ExportUsersToUnisenderMutation, ExportUsersToUnisenderMutationVariables>
    mutation={ExportUsersToUnisenderDocument}
    {...props}
  />
);

export type ExportUsersToUnisenderProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  ExportUsersToUnisenderMutation,
  ExportUsersToUnisenderMutationVariables
> &
  TChildProps;
export function withExportUsersToUnisender<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ExportUsersToUnisenderMutation,
    ExportUsersToUnisenderMutationVariables,
    ExportUsersToUnisenderProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ExportUsersToUnisenderMutation,
    ExportUsersToUnisenderMutationVariables,
    ExportUsersToUnisenderProps<TChildProps>
  >(ExportUsersToUnisenderDocument, {
    alias: 'exportUsersToUnisender',
    ...operationOptions,
  });
}

/**
 * __useExportUsersToUnisenderMutation__
 *
 * To run a mutation, you first call `useExportUsersToUnisenderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportUsersToUnisenderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportUsersToUnisenderMutation, { data, loading, error }] = useExportUsersToUnisenderMutation({
 *   variables: {
 *      query: // value for 'query'
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useExportUsersToUnisenderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ExportUsersToUnisenderMutation,
    ExportUsersToUnisenderMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<ExportUsersToUnisenderMutation, ExportUsersToUnisenderMutationVariables>(
    ExportUsersToUnisenderDocument,
    baseOptions
  );
}
export type ExportUsersToUnisenderMutationHookResult = ReturnType<typeof useExportUsersToUnisenderMutation>;
export type ExportUsersToUnisenderMutationResult = ApolloReactCommon.MutationResult<ExportUsersToUnisenderMutation>;
export type ExportUsersToUnisenderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ExportUsersToUnisenderMutation,
  ExportUsersToUnisenderMutationVariables
>;
export const GenerateDocsByEventOrderDocument = gql`
  mutation generateDocsByEventOrder($userId: Int!, $notify: Boolean!, $orderId: Int, $ticketId: Int) {
    generateDocsByEventOrder(userId: $userId, notify: $notify, orderId: $orderId, ticketId: $ticketId)
  }
`;
export type GenerateDocsByEventOrderMutationFn = ApolloReactCommon.MutationFunction<
  GenerateDocsByEventOrderMutation,
  GenerateDocsByEventOrderMutationVariables
>;
export type GenerateDocsByEventOrderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    GenerateDocsByEventOrderMutation,
    GenerateDocsByEventOrderMutationVariables
  >,
  'mutation'
>;

export const GenerateDocsByEventOrderComponent = (props: GenerateDocsByEventOrderComponentProps) => (
  <ApolloReactComponents.Mutation<GenerateDocsByEventOrderMutation, GenerateDocsByEventOrderMutationVariables>
    mutation={GenerateDocsByEventOrderDocument}
    {...props}
  />
);

export type GenerateDocsByEventOrderProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  GenerateDocsByEventOrderMutation,
  GenerateDocsByEventOrderMutationVariables
> &
  TChildProps;
export function withGenerateDocsByEventOrder<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GenerateDocsByEventOrderMutation,
    GenerateDocsByEventOrderMutationVariables,
    GenerateDocsByEventOrderProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    GenerateDocsByEventOrderMutation,
    GenerateDocsByEventOrderMutationVariables,
    GenerateDocsByEventOrderProps<TChildProps>
  >(GenerateDocsByEventOrderDocument, {
    alias: 'generateDocsByEventOrder',
    ...operationOptions,
  });
}

/**
 * __useGenerateDocsByEventOrderMutation__
 *
 * To run a mutation, you first call `useGenerateDocsByEventOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateDocsByEventOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateDocsByEventOrderMutation, { data, loading, error }] = useGenerateDocsByEventOrderMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      notify: // value for 'notify'
 *      orderId: // value for 'orderId'
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useGenerateDocsByEventOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GenerateDocsByEventOrderMutation,
    GenerateDocsByEventOrderMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<GenerateDocsByEventOrderMutation, GenerateDocsByEventOrderMutationVariables>(
    GenerateDocsByEventOrderDocument,
    baseOptions
  );
}
export type GenerateDocsByEventOrderMutationHookResult = ReturnType<typeof useGenerateDocsByEventOrderMutation>;
export type GenerateDocsByEventOrderMutationResult = ApolloReactCommon.MutationResult<GenerateDocsByEventOrderMutation>;
export type GenerateDocsByEventOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GenerateDocsByEventOrderMutation,
  GenerateDocsByEventOrderMutationVariables
>;
export const GenerateLoyaltyPromoCodesDocument = gql`
  mutation generateLoyaltyPromoCodes($promoId: Int!, $count: Int!, $length: Int!) {
    generateLoyaltyPromoCodes(promoId: $promoId, count: $count, length: $length)
  }
`;
export type GenerateLoyaltyPromoCodesMutationFn = ApolloReactCommon.MutationFunction<
  GenerateLoyaltyPromoCodesMutation,
  GenerateLoyaltyPromoCodesMutationVariables
>;
export type GenerateLoyaltyPromoCodesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    GenerateLoyaltyPromoCodesMutation,
    GenerateLoyaltyPromoCodesMutationVariables
  >,
  'mutation'
>;

export const GenerateLoyaltyPromoCodesComponent = (props: GenerateLoyaltyPromoCodesComponentProps) => (
  <ApolloReactComponents.Mutation<GenerateLoyaltyPromoCodesMutation, GenerateLoyaltyPromoCodesMutationVariables>
    mutation={GenerateLoyaltyPromoCodesDocument}
    {...props}
  />
);

export type GenerateLoyaltyPromoCodesProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  GenerateLoyaltyPromoCodesMutation,
  GenerateLoyaltyPromoCodesMutationVariables
> &
  TChildProps;
export function withGenerateLoyaltyPromoCodes<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GenerateLoyaltyPromoCodesMutation,
    GenerateLoyaltyPromoCodesMutationVariables,
    GenerateLoyaltyPromoCodesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    GenerateLoyaltyPromoCodesMutation,
    GenerateLoyaltyPromoCodesMutationVariables,
    GenerateLoyaltyPromoCodesProps<TChildProps>
  >(GenerateLoyaltyPromoCodesDocument, {
    alias: 'generateLoyaltyPromoCodes',
    ...operationOptions,
  });
}

/**
 * __useGenerateLoyaltyPromoCodesMutation__
 *
 * To run a mutation, you first call `useGenerateLoyaltyPromoCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateLoyaltyPromoCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateLoyaltyPromoCodesMutation, { data, loading, error }] = useGenerateLoyaltyPromoCodesMutation({
 *   variables: {
 *      promoId: // value for 'promoId'
 *      count: // value for 'count'
 *      length: // value for 'length'
 *   },
 * });
 */
export function useGenerateLoyaltyPromoCodesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GenerateLoyaltyPromoCodesMutation,
    GenerateLoyaltyPromoCodesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<GenerateLoyaltyPromoCodesMutation, GenerateLoyaltyPromoCodesMutationVariables>(
    GenerateLoyaltyPromoCodesDocument,
    baseOptions
  );
}
export type GenerateLoyaltyPromoCodesMutationHookResult = ReturnType<typeof useGenerateLoyaltyPromoCodesMutation>;
export type GenerateLoyaltyPromoCodesMutationResult = ApolloReactCommon.MutationResult<
  GenerateLoyaltyPromoCodesMutation
>;
export type GenerateLoyaltyPromoCodesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GenerateLoyaltyPromoCodesMutation,
  GenerateLoyaltyPromoCodesMutationVariables
>;
export const GrantAccessDocument = gql`
  mutation grantAccess($userId: ID!, $aclId: ID!) {
    grantAccess(userId: $userId, aclId: $aclId)
  }
`;
export type GrantAccessMutationFn = ApolloReactCommon.MutationFunction<
  GrantAccessMutation,
  GrantAccessMutationVariables
>;
export type GrantAccessComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<GrantAccessMutation, GrantAccessMutationVariables>,
  'mutation'
>;

export const GrantAccessComponent = (props: GrantAccessComponentProps) => (
  <ApolloReactComponents.Mutation<GrantAccessMutation, GrantAccessMutationVariables>
    mutation={GrantAccessDocument}
    {...props}
  />
);

export type GrantAccessProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  GrantAccessMutation,
  GrantAccessMutationVariables
> &
  TChildProps;
export function withGrantAccess<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GrantAccessMutation,
    GrantAccessMutationVariables,
    GrantAccessProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    GrantAccessMutation,
    GrantAccessMutationVariables,
    GrantAccessProps<TChildProps>
  >(GrantAccessDocument, {
    alias: 'grantAccess',
    ...operationOptions,
  });
}

/**
 * __useGrantAccessMutation__
 *
 * To run a mutation, you first call `useGrantAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGrantAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [grantAccessMutation, { data, loading, error }] = useGrantAccessMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      aclId: // value for 'aclId'
 *   },
 * });
 */
export function useGrantAccessMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<GrantAccessMutation, GrantAccessMutationVariables>
) {
  return ApolloReactHooks.useMutation<GrantAccessMutation, GrantAccessMutationVariables>(
    GrantAccessDocument,
    baseOptions
  );
}
export type GrantAccessMutationHookResult = ReturnType<typeof useGrantAccessMutation>;
export type GrantAccessMutationResult = ApolloReactCommon.MutationResult<GrantAccessMutation>;
export type GrantAccessMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GrantAccessMutation,
  GrantAccessMutationVariables
>;
export const ImportPromocodesDocument = gql`
  mutation importPromocodes($promoId: Int!, $file: Upload!) {
    importPromocodes(promoId: $promoId, file: $file)
  }
`;
export type ImportPromocodesMutationFn = ApolloReactCommon.MutationFunction<
  ImportPromocodesMutation,
  ImportPromocodesMutationVariables
>;
export type ImportPromocodesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<ImportPromocodesMutation, ImportPromocodesMutationVariables>,
  'mutation'
>;

export const ImportPromocodesComponent = (props: ImportPromocodesComponentProps) => (
  <ApolloReactComponents.Mutation<ImportPromocodesMutation, ImportPromocodesMutationVariables>
    mutation={ImportPromocodesDocument}
    {...props}
  />
);

export type ImportPromocodesProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  ImportPromocodesMutation,
  ImportPromocodesMutationVariables
> &
  TChildProps;
export function withImportPromocodes<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ImportPromocodesMutation,
    ImportPromocodesMutationVariables,
    ImportPromocodesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ImportPromocodesMutation,
    ImportPromocodesMutationVariables,
    ImportPromocodesProps<TChildProps>
  >(ImportPromocodesDocument, {
    alias: 'importPromocodes',
    ...operationOptions,
  });
}

/**
 * __useImportPromocodesMutation__
 *
 * To run a mutation, you first call `useImportPromocodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportPromocodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importPromocodesMutation, { data, loading, error }] = useImportPromocodesMutation({
 *   variables: {
 *      promoId: // value for 'promoId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useImportPromocodesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ImportPromocodesMutation, ImportPromocodesMutationVariables>
) {
  return ApolloReactHooks.useMutation<ImportPromocodesMutation, ImportPromocodesMutationVariables>(
    ImportPromocodesDocument,
    baseOptions
  );
}
export type ImportPromocodesMutationHookResult = ReturnType<typeof useImportPromocodesMutation>;
export type ImportPromocodesMutationResult = ApolloReactCommon.MutationResult<ImportPromocodesMutation>;
export type ImportPromocodesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ImportPromocodesMutation,
  ImportPromocodesMutationVariables
>;
export const ImportRecipientsToQueueNotificationDocument = gql`
  mutation importRecipientsToQueueNotification($mailingId: Int!, $file: Upload!) {
    importRecipientsToQueueNotification(mailingId: $mailingId, file: $file) {
      ...messengerQueueTask
    }
  }
  ${MessengerQueueTaskFragmentDoc}
`;
export type ImportRecipientsToQueueNotificationMutationFn = ApolloReactCommon.MutationFunction<
  ImportRecipientsToQueueNotificationMutation,
  ImportRecipientsToQueueNotificationMutationVariables
>;
export type ImportRecipientsToQueueNotificationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ImportRecipientsToQueueNotificationMutation,
    ImportRecipientsToQueueNotificationMutationVariables
  >,
  'mutation'
>;

export const ImportRecipientsToQueueNotificationComponent = (
  props: ImportRecipientsToQueueNotificationComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ImportRecipientsToQueueNotificationMutation,
    ImportRecipientsToQueueNotificationMutationVariables
  >
    mutation={ImportRecipientsToQueueNotificationDocument}
    {...props}
  />
);

export type ImportRecipientsToQueueNotificationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  ImportRecipientsToQueueNotificationMutation,
  ImportRecipientsToQueueNotificationMutationVariables
> &
  TChildProps;
export function withImportRecipientsToQueueNotification<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ImportRecipientsToQueueNotificationMutation,
    ImportRecipientsToQueueNotificationMutationVariables,
    ImportRecipientsToQueueNotificationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ImportRecipientsToQueueNotificationMutation,
    ImportRecipientsToQueueNotificationMutationVariables,
    ImportRecipientsToQueueNotificationProps<TChildProps>
  >(ImportRecipientsToQueueNotificationDocument, {
    alias: 'importRecipientsToQueueNotification',
    ...operationOptions,
  });
}

/**
 * __useImportRecipientsToQueueNotificationMutation__
 *
 * To run a mutation, you first call `useImportRecipientsToQueueNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportRecipientsToQueueNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importRecipientsToQueueNotificationMutation, { data, loading, error }] = useImportRecipientsToQueueNotificationMutation({
 *   variables: {
 *      mailingId: // value for 'mailingId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useImportRecipientsToQueueNotificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ImportRecipientsToQueueNotificationMutation,
    ImportRecipientsToQueueNotificationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ImportRecipientsToQueueNotificationMutation,
    ImportRecipientsToQueueNotificationMutationVariables
  >(ImportRecipientsToQueueNotificationDocument, baseOptions);
}
export type ImportRecipientsToQueueNotificationMutationHookResult = ReturnType<
  typeof useImportRecipientsToQueueNotificationMutation
>;
export type ImportRecipientsToQueueNotificationMutationResult = ApolloReactCommon.MutationResult<
  ImportRecipientsToQueueNotificationMutation
>;
export type ImportRecipientsToQueueNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ImportRecipientsToQueueNotificationMutation,
  ImportRecipientsToQueueNotificationMutationVariables
>;
export const ImportTemplateNotificationsDocument = gql`
  mutation importTemplateNotifications($providerId: Int, $templateId: Int) {
    importTemplateNotifications(providerId: $providerId, templateId: $templateId)
  }
`;
export type ImportTemplateNotificationsMutationFn = ApolloReactCommon.MutationFunction<
  ImportTemplateNotificationsMutation,
  ImportTemplateNotificationsMutationVariables
>;
export type ImportTemplateNotificationsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ImportTemplateNotificationsMutation,
    ImportTemplateNotificationsMutationVariables
  >,
  'mutation'
>;

export const ImportTemplateNotificationsComponent = (props: ImportTemplateNotificationsComponentProps) => (
  <ApolloReactComponents.Mutation<ImportTemplateNotificationsMutation, ImportTemplateNotificationsMutationVariables>
    mutation={ImportTemplateNotificationsDocument}
    {...props}
  />
);

export type ImportTemplateNotificationsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  ImportTemplateNotificationsMutation,
  ImportTemplateNotificationsMutationVariables
> &
  TChildProps;
export function withImportTemplateNotifications<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ImportTemplateNotificationsMutation,
    ImportTemplateNotificationsMutationVariables,
    ImportTemplateNotificationsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ImportTemplateNotificationsMutation,
    ImportTemplateNotificationsMutationVariables,
    ImportTemplateNotificationsProps<TChildProps>
  >(ImportTemplateNotificationsDocument, {
    alias: 'importTemplateNotifications',
    ...operationOptions,
  });
}

/**
 * __useImportTemplateNotificationsMutation__
 *
 * To run a mutation, you first call `useImportTemplateNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportTemplateNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importTemplateNotificationsMutation, { data, loading, error }] = useImportTemplateNotificationsMutation({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useImportTemplateNotificationsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ImportTemplateNotificationsMutation,
    ImportTemplateNotificationsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ImportTemplateNotificationsMutation,
    ImportTemplateNotificationsMutationVariables
  >(ImportTemplateNotificationsDocument, baseOptions);
}
export type ImportTemplateNotificationsMutationHookResult = ReturnType<typeof useImportTemplateNotificationsMutation>;
export type ImportTemplateNotificationsMutationResult = ApolloReactCommon.MutationResult<
  ImportTemplateNotificationsMutation
>;
export type ImportTemplateNotificationsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ImportTemplateNotificationsMutation,
  ImportTemplateNotificationsMutationVariables
>;
export const ImportUserSubscribersDocument = gql`
  mutation importUserSubscribers($subscriptionId: Int!, $file: Upload!) {
    importUserSubscribers(subscriptionId: $subscriptionId, file: $file)
  }
`;
export type ImportUserSubscribersMutationFn = ApolloReactCommon.MutationFunction<
  ImportUserSubscribersMutation,
  ImportUserSubscribersMutationVariables
>;
export type ImportUserSubscribersComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<ImportUserSubscribersMutation, ImportUserSubscribersMutationVariables>,
  'mutation'
>;

export const ImportUserSubscribersComponent = (props: ImportUserSubscribersComponentProps) => (
  <ApolloReactComponents.Mutation<ImportUserSubscribersMutation, ImportUserSubscribersMutationVariables>
    mutation={ImportUserSubscribersDocument}
    {...props}
  />
);

export type ImportUserSubscribersProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  ImportUserSubscribersMutation,
  ImportUserSubscribersMutationVariables
> &
  TChildProps;
export function withImportUserSubscribers<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ImportUserSubscribersMutation,
    ImportUserSubscribersMutationVariables,
    ImportUserSubscribersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ImportUserSubscribersMutation,
    ImportUserSubscribersMutationVariables,
    ImportUserSubscribersProps<TChildProps>
  >(ImportUserSubscribersDocument, {
    alias: 'importUserSubscribers',
    ...operationOptions,
  });
}

/**
 * __useImportUserSubscribersMutation__
 *
 * To run a mutation, you first call `useImportUserSubscribersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportUserSubscribersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importUserSubscribersMutation, { data, loading, error }] = useImportUserSubscribersMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useImportUserSubscribersMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ImportUserSubscribersMutation,
    ImportUserSubscribersMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<ImportUserSubscribersMutation, ImportUserSubscribersMutationVariables>(
    ImportUserSubscribersDocument,
    baseOptions
  );
}
export type ImportUserSubscribersMutationHookResult = ReturnType<typeof useImportUserSubscribersMutation>;
export type ImportUserSubscribersMutationResult = ApolloReactCommon.MutationResult<ImportUserSubscribersMutation>;
export type ImportUserSubscribersMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ImportUserSubscribersMutation,
  ImportUserSubscribersMutationVariables
>;
export const ImportUsersDocument = gql`
  mutation importUsers($data: ImportInput!) {
    importUsers(data: $data) {
      ...systemAsyncTask
    }
  }
  ${SystemAsyncTaskFragmentDoc}
`;
export type ImportUsersMutationFn = ApolloReactCommon.MutationFunction<
  ImportUsersMutation,
  ImportUsersMutationVariables
>;
export type ImportUsersComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<ImportUsersMutation, ImportUsersMutationVariables>,
  'mutation'
>;

export const ImportUsersComponent = (props: ImportUsersComponentProps) => (
  <ApolloReactComponents.Mutation<ImportUsersMutation, ImportUsersMutationVariables>
    mutation={ImportUsersDocument}
    {...props}
  />
);

export type ImportUsersProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  ImportUsersMutation,
  ImportUsersMutationVariables
> &
  TChildProps;
export function withImportUsers<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ImportUsersMutation,
    ImportUsersMutationVariables,
    ImportUsersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ImportUsersMutation,
    ImportUsersMutationVariables,
    ImportUsersProps<TChildProps>
  >(ImportUsersDocument, {
    alias: 'importUsers',
    ...operationOptions,
  });
}

/**
 * __useImportUsersMutation__
 *
 * To run a mutation, you first call `useImportUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importUsersMutation, { data, loading, error }] = useImportUsersMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImportUsersMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ImportUsersMutation, ImportUsersMutationVariables>
) {
  return ApolloReactHooks.useMutation<ImportUsersMutation, ImportUsersMutationVariables>(
    ImportUsersDocument,
    baseOptions
  );
}
export type ImportUsersMutationHookResult = ReturnType<typeof useImportUsersMutation>;
export type ImportUsersMutationResult = ApolloReactCommon.MutationResult<ImportUsersMutation>;
export type ImportUsersMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ImportUsersMutation,
  ImportUsersMutationVariables
>;
export const RefundOrderDocument = gql`
  mutation refundOrder($data: RefundOrderInput!) {
    refundOrder(data: $data) {
      ...refundPaymentTransaction
    }
  }
  ${RefundPaymentTransactionFragmentDoc}
`;
export type RefundOrderMutationFn = ApolloReactCommon.MutationFunction<
  RefundOrderMutation,
  RefundOrderMutationVariables
>;
export type RefundOrderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<RefundOrderMutation, RefundOrderMutationVariables>,
  'mutation'
>;

export const RefundOrderComponent = (props: RefundOrderComponentProps) => (
  <ApolloReactComponents.Mutation<RefundOrderMutation, RefundOrderMutationVariables>
    mutation={RefundOrderDocument}
    {...props}
  />
);

export type RefundOrderProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  RefundOrderMutation,
  RefundOrderMutationVariables
> &
  TChildProps;
export function withRefundOrder<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RefundOrderMutation,
    RefundOrderMutationVariables,
    RefundOrderProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RefundOrderMutation,
    RefundOrderMutationVariables,
    RefundOrderProps<TChildProps>
  >(RefundOrderDocument, {
    alias: 'refundOrder',
    ...operationOptions,
  });
}

/**
 * __useRefundOrderMutation__
 *
 * To run a mutation, you first call `useRefundOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundOrderMutation, { data, loading, error }] = useRefundOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRefundOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RefundOrderMutation, RefundOrderMutationVariables>
) {
  return ApolloReactHooks.useMutation<RefundOrderMutation, RefundOrderMutationVariables>(
    RefundOrderDocument,
    baseOptions
  );
}
export type RefundOrderMutationHookResult = ReturnType<typeof useRefundOrderMutation>;
export type RefundOrderMutationResult = ApolloReactCommon.MutationResult<RefundOrderMutation>;
export type RefundOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RefundOrderMutation,
  RefundOrderMutationVariables
>;
export const ResetLoyaltyPromoCodesDocument = gql`
  mutation resetLoyaltyPromoCodes($promoId: Int!) {
    resetLoyaltyPromoCodes(promoId: $promoId)
  }
`;
export type ResetLoyaltyPromoCodesMutationFn = ApolloReactCommon.MutationFunction<
  ResetLoyaltyPromoCodesMutation,
  ResetLoyaltyPromoCodesMutationVariables
>;
export type ResetLoyaltyPromoCodesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ResetLoyaltyPromoCodesMutation,
    ResetLoyaltyPromoCodesMutationVariables
  >,
  'mutation'
>;

export const ResetLoyaltyPromoCodesComponent = (props: ResetLoyaltyPromoCodesComponentProps) => (
  <ApolloReactComponents.Mutation<ResetLoyaltyPromoCodesMutation, ResetLoyaltyPromoCodesMutationVariables>
    mutation={ResetLoyaltyPromoCodesDocument}
    {...props}
  />
);

export type ResetLoyaltyPromoCodesProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  ResetLoyaltyPromoCodesMutation,
  ResetLoyaltyPromoCodesMutationVariables
> &
  TChildProps;
export function withResetLoyaltyPromoCodes<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ResetLoyaltyPromoCodesMutation,
    ResetLoyaltyPromoCodesMutationVariables,
    ResetLoyaltyPromoCodesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ResetLoyaltyPromoCodesMutation,
    ResetLoyaltyPromoCodesMutationVariables,
    ResetLoyaltyPromoCodesProps<TChildProps>
  >(ResetLoyaltyPromoCodesDocument, {
    alias: 'resetLoyaltyPromoCodes',
    ...operationOptions,
  });
}

/**
 * __useResetLoyaltyPromoCodesMutation__
 *
 * To run a mutation, you first call `useResetLoyaltyPromoCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetLoyaltyPromoCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetLoyaltyPromoCodesMutation, { data, loading, error }] = useResetLoyaltyPromoCodesMutation({
 *   variables: {
 *      promoId: // value for 'promoId'
 *   },
 * });
 */
export function useResetLoyaltyPromoCodesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResetLoyaltyPromoCodesMutation,
    ResetLoyaltyPromoCodesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<ResetLoyaltyPromoCodesMutation, ResetLoyaltyPromoCodesMutationVariables>(
    ResetLoyaltyPromoCodesDocument,
    baseOptions
  );
}
export type ResetLoyaltyPromoCodesMutationHookResult = ReturnType<typeof useResetLoyaltyPromoCodesMutation>;
export type ResetLoyaltyPromoCodesMutationResult = ApolloReactCommon.MutationResult<ResetLoyaltyPromoCodesMutation>;
export type ResetLoyaltyPromoCodesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResetLoyaltyPromoCodesMutation,
  ResetLoyaltyPromoCodesMutationVariables
>;
export const RevokeAccessDocument = gql`
  mutation revokeAccess($userId: ID!, $aclId: ID!) {
    revokeAccess(userId: $userId, aclId: $aclId)
  }
`;
export type RevokeAccessMutationFn = ApolloReactCommon.MutationFunction<
  RevokeAccessMutation,
  RevokeAccessMutationVariables
>;
export type RevokeAccessComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<RevokeAccessMutation, RevokeAccessMutationVariables>,
  'mutation'
>;

export const RevokeAccessComponent = (props: RevokeAccessComponentProps) => (
  <ApolloReactComponents.Mutation<RevokeAccessMutation, RevokeAccessMutationVariables>
    mutation={RevokeAccessDocument}
    {...props}
  />
);

export type RevokeAccessProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  RevokeAccessMutation,
  RevokeAccessMutationVariables
> &
  TChildProps;
export function withRevokeAccess<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RevokeAccessMutation,
    RevokeAccessMutationVariables,
    RevokeAccessProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RevokeAccessMutation,
    RevokeAccessMutationVariables,
    RevokeAccessProps<TChildProps>
  >(RevokeAccessDocument, {
    alias: 'revokeAccess',
    ...operationOptions,
  });
}

/**
 * __useRevokeAccessMutation__
 *
 * To run a mutation, you first call `useRevokeAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeAccessMutation, { data, loading, error }] = useRevokeAccessMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      aclId: // value for 'aclId'
 *   },
 * });
 */
export function useRevokeAccessMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RevokeAccessMutation, RevokeAccessMutationVariables>
) {
  return ApolloReactHooks.useMutation<RevokeAccessMutation, RevokeAccessMutationVariables>(
    RevokeAccessDocument,
    baseOptions
  );
}
export type RevokeAccessMutationHookResult = ReturnType<typeof useRevokeAccessMutation>;
export type RevokeAccessMutationResult = ApolloReactCommon.MutationResult<RevokeAccessMutation>;
export type RevokeAccessMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RevokeAccessMutation,
  RevokeAccessMutationVariables
>;
export const SaveDisputeSettingsDocument = gql`
  mutation saveDisputeSettings($data: DisputeSettingsUpdateInput!) {
    saveDisputeSettings(data: $data) {
      ...disputeSettings
    }
  }
  ${DisputeSettingsFragmentDoc}
`;
export type SaveDisputeSettingsMutationFn = ApolloReactCommon.MutationFunction<
  SaveDisputeSettingsMutation,
  SaveDisputeSettingsMutationVariables
>;
export type SaveDisputeSettingsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<SaveDisputeSettingsMutation, SaveDisputeSettingsMutationVariables>,
  'mutation'
>;

export const SaveDisputeSettingsComponent = (props: SaveDisputeSettingsComponentProps) => (
  <ApolloReactComponents.Mutation<SaveDisputeSettingsMutation, SaveDisputeSettingsMutationVariables>
    mutation={SaveDisputeSettingsDocument}
    {...props}
  />
);

export type SaveDisputeSettingsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  SaveDisputeSettingsMutation,
  SaveDisputeSettingsMutationVariables
> &
  TChildProps;
export function withSaveDisputeSettings<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SaveDisputeSettingsMutation,
    SaveDisputeSettingsMutationVariables,
    SaveDisputeSettingsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SaveDisputeSettingsMutation,
    SaveDisputeSettingsMutationVariables,
    SaveDisputeSettingsProps<TChildProps>
  >(SaveDisputeSettingsDocument, {
    alias: 'saveDisputeSettings',
    ...operationOptions,
  });
}

/**
 * __useSaveDisputeSettingsMutation__
 *
 * To run a mutation, you first call `useSaveDisputeSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDisputeSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDisputeSettingsMutation, { data, loading, error }] = useSaveDisputeSettingsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveDisputeSettingsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SaveDisputeSettingsMutation, SaveDisputeSettingsMutationVariables>
) {
  return ApolloReactHooks.useMutation<SaveDisputeSettingsMutation, SaveDisputeSettingsMutationVariables>(
    SaveDisputeSettingsDocument,
    baseOptions
  );
}
export type SaveDisputeSettingsMutationHookResult = ReturnType<typeof useSaveDisputeSettingsMutation>;
export type SaveDisputeSettingsMutationResult = ApolloReactCommon.MutationResult<SaveDisputeSettingsMutation>;
export type SaveDisputeSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveDisputeSettingsMutation,
  SaveDisputeSettingsMutationVariables
>;
export const SaveMobileTranslationsDocument = gql`
  mutation saveMobileTranslations($data: [MobileTranslationSaveInput!]) {
    saveMobileTranslations(data: $data) {
      ...mobileTranslation
    }
  }
  ${MobileTranslationFragmentDoc}
`;
export type SaveMobileTranslationsMutationFn = ApolloReactCommon.MutationFunction<
  SaveMobileTranslationsMutation,
  SaveMobileTranslationsMutationVariables
>;
export type SaveMobileTranslationsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SaveMobileTranslationsMutation,
    SaveMobileTranslationsMutationVariables
  >,
  'mutation'
>;

export const SaveMobileTranslationsComponent = (props: SaveMobileTranslationsComponentProps) => (
  <ApolloReactComponents.Mutation<SaveMobileTranslationsMutation, SaveMobileTranslationsMutationVariables>
    mutation={SaveMobileTranslationsDocument}
    {...props}
  />
);

export type SaveMobileTranslationsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  SaveMobileTranslationsMutation,
  SaveMobileTranslationsMutationVariables
> &
  TChildProps;
export function withSaveMobileTranslations<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SaveMobileTranslationsMutation,
    SaveMobileTranslationsMutationVariables,
    SaveMobileTranslationsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SaveMobileTranslationsMutation,
    SaveMobileTranslationsMutationVariables,
    SaveMobileTranslationsProps<TChildProps>
  >(SaveMobileTranslationsDocument, {
    alias: 'saveMobileTranslations',
    ...operationOptions,
  });
}

/**
 * __useSaveMobileTranslationsMutation__
 *
 * To run a mutation, you first call `useSaveMobileTranslationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMobileTranslationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMobileTranslationsMutation, { data, loading, error }] = useSaveMobileTranslationsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveMobileTranslationsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveMobileTranslationsMutation,
    SaveMobileTranslationsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<SaveMobileTranslationsMutation, SaveMobileTranslationsMutationVariables>(
    SaveMobileTranslationsDocument,
    baseOptions
  );
}
export type SaveMobileTranslationsMutationHookResult = ReturnType<typeof useSaveMobileTranslationsMutation>;
export type SaveMobileTranslationsMutationResult = ApolloReactCommon.MutationResult<SaveMobileTranslationsMutation>;
export type SaveMobileTranslationsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveMobileTranslationsMutation,
  SaveMobileTranslationsMutationVariables
>;
export const SaveSystemTranslationDocument = gql`
  mutation saveSystemTranslation($data: SystemTranslationSaveInput!) {
    saveSystemTranslation(data: $data) {
      ...systemTranslation
    }
  }
  ${SystemTranslationFragmentDoc}
`;
export type SaveSystemTranslationMutationFn = ApolloReactCommon.MutationFunction<
  SaveSystemTranslationMutation,
  SaveSystemTranslationMutationVariables
>;
export type SaveSystemTranslationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<SaveSystemTranslationMutation, SaveSystemTranslationMutationVariables>,
  'mutation'
>;

export const SaveSystemTranslationComponent = (props: SaveSystemTranslationComponentProps) => (
  <ApolloReactComponents.Mutation<SaveSystemTranslationMutation, SaveSystemTranslationMutationVariables>
    mutation={SaveSystemTranslationDocument}
    {...props}
  />
);

export type SaveSystemTranslationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  SaveSystemTranslationMutation,
  SaveSystemTranslationMutationVariables
> &
  TChildProps;
export function withSaveSystemTranslation<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SaveSystemTranslationMutation,
    SaveSystemTranslationMutationVariables,
    SaveSystemTranslationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SaveSystemTranslationMutation,
    SaveSystemTranslationMutationVariables,
    SaveSystemTranslationProps<TChildProps>
  >(SaveSystemTranslationDocument, {
    alias: 'saveSystemTranslation',
    ...operationOptions,
  });
}

/**
 * __useSaveSystemTranslationMutation__
 *
 * To run a mutation, you first call `useSaveSystemTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSystemTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSystemTranslationMutation, { data, loading, error }] = useSaveSystemTranslationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveSystemTranslationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveSystemTranslationMutation,
    SaveSystemTranslationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<SaveSystemTranslationMutation, SaveSystemTranslationMutationVariables>(
    SaveSystemTranslationDocument,
    baseOptions
  );
}
export type SaveSystemTranslationMutationHookResult = ReturnType<typeof useSaveSystemTranslationMutation>;
export type SaveSystemTranslationMutationResult = ApolloReactCommon.MutationResult<SaveSystemTranslationMutation>;
export type SaveSystemTranslationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveSystemTranslationMutation,
  SaveSystemTranslationMutationVariables
>;
export const SaveUserSettingsDocument = gql`
  mutation saveUserSettings($data: SaveUserSettingsInput!) {
    saveUserSettings(data: $data) {
      ...userSetting
    }
  }
  ${UserSettingFragmentDoc}
`;
export type SaveUserSettingsMutationFn = ApolloReactCommon.MutationFunction<
  SaveUserSettingsMutation,
  SaveUserSettingsMutationVariables
>;
export type SaveUserSettingsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<SaveUserSettingsMutation, SaveUserSettingsMutationVariables>,
  'mutation'
>;

export const SaveUserSettingsComponent = (props: SaveUserSettingsComponentProps) => (
  <ApolloReactComponents.Mutation<SaveUserSettingsMutation, SaveUserSettingsMutationVariables>
    mutation={SaveUserSettingsDocument}
    {...props}
  />
);

export type SaveUserSettingsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  SaveUserSettingsMutation,
  SaveUserSettingsMutationVariables
> &
  TChildProps;
export function withSaveUserSettings<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SaveUserSettingsMutation,
    SaveUserSettingsMutationVariables,
    SaveUserSettingsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SaveUserSettingsMutation,
    SaveUserSettingsMutationVariables,
    SaveUserSettingsProps<TChildProps>
  >(SaveUserSettingsDocument, {
    alias: 'saveUserSettings',
    ...operationOptions,
  });
}

/**
 * __useSaveUserSettingsMutation__
 *
 * To run a mutation, you first call `useSaveUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserSettingsMutation, { data, loading, error }] = useSaveUserSettingsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveUserSettingsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SaveUserSettingsMutation, SaveUserSettingsMutationVariables>
) {
  return ApolloReactHooks.useMutation<SaveUserSettingsMutation, SaveUserSettingsMutationVariables>(
    SaveUserSettingsDocument,
    baseOptions
  );
}
export type SaveUserSettingsMutationHookResult = ReturnType<typeof useSaveUserSettingsMutation>;
export type SaveUserSettingsMutationResult = ApolloReactCommon.MutationResult<SaveUserSettingsMutation>;
export type SaveUserSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveUserSettingsMutation,
  SaveUserSettingsMutationVariables
>;
export const SystemLoginDocument = gql`
  mutation systemLogin($username: String!, $password: String!, $clientId: Int) {
    systemLogin(username: $username, password: $password, clientId: $clientId) {
      ...systemLogin
    }
  }
  ${SystemLoginFragmentDoc}
`;
export type SystemLoginMutationFn = ApolloReactCommon.MutationFunction<
  SystemLoginMutation,
  SystemLoginMutationVariables
>;
export type SystemLoginComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<SystemLoginMutation, SystemLoginMutationVariables>,
  'mutation'
>;

export const SystemLoginComponent = (props: SystemLoginComponentProps) => (
  <ApolloReactComponents.Mutation<SystemLoginMutation, SystemLoginMutationVariables>
    mutation={SystemLoginDocument}
    {...props}
  />
);

export type SystemLoginProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  SystemLoginMutation,
  SystemLoginMutationVariables
> &
  TChildProps;
export function withSystemLogin<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SystemLoginMutation,
    SystemLoginMutationVariables,
    SystemLoginProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SystemLoginMutation,
    SystemLoginMutationVariables,
    SystemLoginProps<TChildProps>
  >(SystemLoginDocument, {
    alias: 'systemLogin',
    ...operationOptions,
  });
}

/**
 * __useSystemLoginMutation__
 *
 * To run a mutation, you first call `useSystemLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSystemLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [systemLoginMutation, { data, loading, error }] = useSystemLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useSystemLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SystemLoginMutation, SystemLoginMutationVariables>
) {
  return ApolloReactHooks.useMutation<SystemLoginMutation, SystemLoginMutationVariables>(
    SystemLoginDocument,
    baseOptions
  );
}
export type SystemLoginMutationHookResult = ReturnType<typeof useSystemLoginMutation>;
export type SystemLoginMutationResult = ApolloReactCommon.MutationResult<SystemLoginMutation>;
export type SystemLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SystemLoginMutation,
  SystemLoginMutationVariables
>;
export const TestMessengerMailingDocument = gql`
  mutation testMessengerMailing($mailingId: Int!) {
    testMessengerMailing(mailingId: $mailingId)
  }
`;
export type TestMessengerMailingMutationFn = ApolloReactCommon.MutationFunction<
  TestMessengerMailingMutation,
  TestMessengerMailingMutationVariables
>;
export type TestMessengerMailingComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<TestMessengerMailingMutation, TestMessengerMailingMutationVariables>,
  'mutation'
>;

export const TestMessengerMailingComponent = (props: TestMessengerMailingComponentProps) => (
  <ApolloReactComponents.Mutation<TestMessengerMailingMutation, TestMessengerMailingMutationVariables>
    mutation={TestMessengerMailingDocument}
    {...props}
  />
);

export type TestMessengerMailingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  TestMessengerMailingMutation,
  TestMessengerMailingMutationVariables
> &
  TChildProps;
export function withTestMessengerMailing<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TestMessengerMailingMutation,
    TestMessengerMailingMutationVariables,
    TestMessengerMailingProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    TestMessengerMailingMutation,
    TestMessengerMailingMutationVariables,
    TestMessengerMailingProps<TChildProps>
  >(TestMessengerMailingDocument, {
    alias: 'testMessengerMailing',
    ...operationOptions,
  });
}

/**
 * __useTestMessengerMailingMutation__
 *
 * To run a mutation, you first call `useTestMessengerMailingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestMessengerMailingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testMessengerMailingMutation, { data, loading, error }] = useTestMessengerMailingMutation({
 *   variables: {
 *      mailingId: // value for 'mailingId'
 *   },
 * });
 */
export function useTestMessengerMailingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TestMessengerMailingMutation,
    TestMessengerMailingMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<TestMessengerMailingMutation, TestMessengerMailingMutationVariables>(
    TestMessengerMailingDocument,
    baseOptions
  );
}
export type TestMessengerMailingMutationHookResult = ReturnType<typeof useTestMessengerMailingMutation>;
export type TestMessengerMailingMutationResult = ApolloReactCommon.MutationResult<TestMessengerMailingMutation>;
export type TestMessengerMailingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TestMessengerMailingMutation,
  TestMessengerMailingMutationVariables
>;
export const TriggerSystemWebHookDocument = gql`
  mutation triggerSystemWebHook($id: Int!, $entityId: Int, $payload: SystemWebHookPayloadInput) {
    triggerSystemWebHook(id: $id, entityId: $entityId, payload: $payload)
  }
`;
export type TriggerSystemWebHookMutationFn = ApolloReactCommon.MutationFunction<
  TriggerSystemWebHookMutation,
  TriggerSystemWebHookMutationVariables
>;
export type TriggerSystemWebHookComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<TriggerSystemWebHookMutation, TriggerSystemWebHookMutationVariables>,
  'mutation'
>;

export const TriggerSystemWebHookComponent = (props: TriggerSystemWebHookComponentProps) => (
  <ApolloReactComponents.Mutation<TriggerSystemWebHookMutation, TriggerSystemWebHookMutationVariables>
    mutation={TriggerSystemWebHookDocument}
    {...props}
  />
);

export type TriggerSystemWebHookProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  TriggerSystemWebHookMutation,
  TriggerSystemWebHookMutationVariables
> &
  TChildProps;
export function withTriggerSystemWebHook<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TriggerSystemWebHookMutation,
    TriggerSystemWebHookMutationVariables,
    TriggerSystemWebHookProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    TriggerSystemWebHookMutation,
    TriggerSystemWebHookMutationVariables,
    TriggerSystemWebHookProps<TChildProps>
  >(TriggerSystemWebHookDocument, {
    alias: 'triggerSystemWebHook',
    ...operationOptions,
  });
}

/**
 * __useTriggerSystemWebHookMutation__
 *
 * To run a mutation, you first call `useTriggerSystemWebHookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerSystemWebHookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerSystemWebHookMutation, { data, loading, error }] = useTriggerSystemWebHookMutation({
 *   variables: {
 *      id: // value for 'id'
 *      entityId: // value for 'entityId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useTriggerSystemWebHookMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TriggerSystemWebHookMutation,
    TriggerSystemWebHookMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<TriggerSystemWebHookMutation, TriggerSystemWebHookMutationVariables>(
    TriggerSystemWebHookDocument,
    baseOptions
  );
}
export type TriggerSystemWebHookMutationHookResult = ReturnType<typeof useTriggerSystemWebHookMutation>;
export type TriggerSystemWebHookMutationResult = ApolloReactCommon.MutationResult<TriggerSystemWebHookMutation>;
export type TriggerSystemWebHookMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TriggerSystemWebHookMutation,
  TriggerSystemWebHookMutationVariables
>;
export const UnisenderCreateListDocument = gql`
  mutation unisenderCreateList($data: UnisenderCreateListInput!) {
    unisenderCreateList(data: $data) {
      id
      title
    }
  }
`;
export type UnisenderCreateListMutationFn = ApolloReactCommon.MutationFunction<
  UnisenderCreateListMutation,
  UnisenderCreateListMutationVariables
>;
export type UnisenderCreateListComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UnisenderCreateListMutation, UnisenderCreateListMutationVariables>,
  'mutation'
>;

export const UnisenderCreateListComponent = (props: UnisenderCreateListComponentProps) => (
  <ApolloReactComponents.Mutation<UnisenderCreateListMutation, UnisenderCreateListMutationVariables>
    mutation={UnisenderCreateListDocument}
    {...props}
  />
);

export type UnisenderCreateListProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UnisenderCreateListMutation,
  UnisenderCreateListMutationVariables
> &
  TChildProps;
export function withUnisenderCreateList<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UnisenderCreateListMutation,
    UnisenderCreateListMutationVariables,
    UnisenderCreateListProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UnisenderCreateListMutation,
    UnisenderCreateListMutationVariables,
    UnisenderCreateListProps<TChildProps>
  >(UnisenderCreateListDocument, {
    alias: 'unisenderCreateList',
    ...operationOptions,
  });
}

/**
 * __useUnisenderCreateListMutation__
 *
 * To run a mutation, you first call `useUnisenderCreateListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnisenderCreateListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unisenderCreateListMutation, { data, loading, error }] = useUnisenderCreateListMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUnisenderCreateListMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UnisenderCreateListMutation, UnisenderCreateListMutationVariables>
) {
  return ApolloReactHooks.useMutation<UnisenderCreateListMutation, UnisenderCreateListMutationVariables>(
    UnisenderCreateListDocument,
    baseOptions
  );
}
export type UnisenderCreateListMutationHookResult = ReturnType<typeof useUnisenderCreateListMutation>;
export type UnisenderCreateListMutationResult = ApolloReactCommon.MutationResult<UnisenderCreateListMutation>;
export type UnisenderCreateListMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UnisenderCreateListMutation,
  UnisenderCreateListMutationVariables
>;
export const UpdateArticleDocument = gql`
  mutation updateArticle($id: Int!, $data: ArticleUpdateInput!) {
    updateArticle(id: $id, data: $data) {
      ...article
    }
  }
  ${ArticleFragmentDoc}
`;
export type UpdateArticleMutationFn = ApolloReactCommon.MutationFunction<
  UpdateArticleMutation,
  UpdateArticleMutationVariables
>;
export type UpdateArticleComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateArticleMutation, UpdateArticleMutationVariables>,
  'mutation'
>;

export const UpdateArticleComponent = (props: UpdateArticleComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateArticleMutation, UpdateArticleMutationVariables>
    mutation={UpdateArticleDocument}
    {...props}
  />
);

export type UpdateArticleProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateArticleMutation,
  UpdateArticleMutationVariables
> &
  TChildProps;
export function withUpdateArticle<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateArticleMutation,
    UpdateArticleMutationVariables,
    UpdateArticleProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateArticleMutation,
    UpdateArticleMutationVariables,
    UpdateArticleProps<TChildProps>
  >(UpdateArticleDocument, {
    alias: 'updateArticle',
    ...operationOptions,
  });
}

/**
 * __useUpdateArticleMutation__
 *
 * To run a mutation, you first call `useUpdateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleMutation, { data, loading, error }] = useUpdateArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateArticleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArticleMutation, UpdateArticleMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateArticleMutation, UpdateArticleMutationVariables>(
    UpdateArticleDocument,
    baseOptions
  );
}
export type UpdateArticleMutationHookResult = ReturnType<typeof useUpdateArticleMutation>;
export type UpdateArticleMutationResult = ApolloReactCommon.MutationResult<UpdateArticleMutation>;
export type UpdateArticleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateArticleMutation,
  UpdateArticleMutationVariables
>;
export const UpdateArticleAuthorDocument = gql`
  mutation updateArticleAuthor($id: Int!, $data: ArticleAuthorUpdateInput!) {
    updateArticleAuthor(id: $id, data: $data) {
      ...articleAuthor
    }
  }
  ${ArticleAuthorFragmentDoc}
`;
export type UpdateArticleAuthorMutationFn = ApolloReactCommon.MutationFunction<
  UpdateArticleAuthorMutation,
  UpdateArticleAuthorMutationVariables
>;
export type UpdateArticleAuthorComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateArticleAuthorMutation, UpdateArticleAuthorMutationVariables>,
  'mutation'
>;

export const UpdateArticleAuthorComponent = (props: UpdateArticleAuthorComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateArticleAuthorMutation, UpdateArticleAuthorMutationVariables>
    mutation={UpdateArticleAuthorDocument}
    {...props}
  />
);

export type UpdateArticleAuthorProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateArticleAuthorMutation,
  UpdateArticleAuthorMutationVariables
> &
  TChildProps;
export function withUpdateArticleAuthor<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateArticleAuthorMutation,
    UpdateArticleAuthorMutationVariables,
    UpdateArticleAuthorProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateArticleAuthorMutation,
    UpdateArticleAuthorMutationVariables,
    UpdateArticleAuthorProps<TChildProps>
  >(UpdateArticleAuthorDocument, {
    alias: 'updateArticleAuthor',
    ...operationOptions,
  });
}

/**
 * __useUpdateArticleAuthorMutation__
 *
 * To run a mutation, you first call `useUpdateArticleAuthorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleAuthorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleAuthorMutation, { data, loading, error }] = useUpdateArticleAuthorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateArticleAuthorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArticleAuthorMutation, UpdateArticleAuthorMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateArticleAuthorMutation, UpdateArticleAuthorMutationVariables>(
    UpdateArticleAuthorDocument,
    baseOptions
  );
}
export type UpdateArticleAuthorMutationHookResult = ReturnType<typeof useUpdateArticleAuthorMutation>;
export type UpdateArticleAuthorMutationResult = ApolloReactCommon.MutationResult<UpdateArticleAuthorMutation>;
export type UpdateArticleAuthorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateArticleAuthorMutation,
  UpdateArticleAuthorMutationVariables
>;
export const UpdateArticleBlockDocument = gql`
  mutation updateArticleBlock($id: Int!, $data: ArticleBlockUpdateInput!) {
    updateArticleBlock(id: $id, data: $data) {
      ...articleBlock
    }
  }
  ${ArticleBlockFragmentDoc}
`;
export type UpdateArticleBlockMutationFn = ApolloReactCommon.MutationFunction<
  UpdateArticleBlockMutation,
  UpdateArticleBlockMutationVariables
>;
export type UpdateArticleBlockComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateArticleBlockMutation, UpdateArticleBlockMutationVariables>,
  'mutation'
>;

export const UpdateArticleBlockComponent = (props: UpdateArticleBlockComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateArticleBlockMutation, UpdateArticleBlockMutationVariables>
    mutation={UpdateArticleBlockDocument}
    {...props}
  />
);

export type UpdateArticleBlockProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateArticleBlockMutation,
  UpdateArticleBlockMutationVariables
> &
  TChildProps;
export function withUpdateArticleBlock<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateArticleBlockMutation,
    UpdateArticleBlockMutationVariables,
    UpdateArticleBlockProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateArticleBlockMutation,
    UpdateArticleBlockMutationVariables,
    UpdateArticleBlockProps<TChildProps>
  >(UpdateArticleBlockDocument, {
    alias: 'updateArticleBlock',
    ...operationOptions,
  });
}

/**
 * __useUpdateArticleBlockMutation__
 *
 * To run a mutation, you first call `useUpdateArticleBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleBlockMutation, { data, loading, error }] = useUpdateArticleBlockMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateArticleBlockMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArticleBlockMutation, UpdateArticleBlockMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateArticleBlockMutation, UpdateArticleBlockMutationVariables>(
    UpdateArticleBlockDocument,
    baseOptions
  );
}
export type UpdateArticleBlockMutationHookResult = ReturnType<typeof useUpdateArticleBlockMutation>;
export type UpdateArticleBlockMutationResult = ApolloReactCommon.MutationResult<UpdateArticleBlockMutation>;
export type UpdateArticleBlockMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateArticleBlockMutation,
  UpdateArticleBlockMutationVariables
>;
export const UpdateArticleBlocksDocument = gql`
  mutation updateArticleBlocks($data: [ArticleBlocksUpdateInput!]!) {
    updateArticleBlocks(data: $data)
  }
`;
export type UpdateArticleBlocksMutationFn = ApolloReactCommon.MutationFunction<
  UpdateArticleBlocksMutation,
  UpdateArticleBlocksMutationVariables
>;
export type UpdateArticleBlocksComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateArticleBlocksMutation, UpdateArticleBlocksMutationVariables>,
  'mutation'
>;

export const UpdateArticleBlocksComponent = (props: UpdateArticleBlocksComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateArticleBlocksMutation, UpdateArticleBlocksMutationVariables>
    mutation={UpdateArticleBlocksDocument}
    {...props}
  />
);

export type UpdateArticleBlocksProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateArticleBlocksMutation,
  UpdateArticleBlocksMutationVariables
> &
  TChildProps;
export function withUpdateArticleBlocks<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateArticleBlocksMutation,
    UpdateArticleBlocksMutationVariables,
    UpdateArticleBlocksProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateArticleBlocksMutation,
    UpdateArticleBlocksMutationVariables,
    UpdateArticleBlocksProps<TChildProps>
  >(UpdateArticleBlocksDocument, {
    alias: 'updateArticleBlocks',
    ...operationOptions,
  });
}

/**
 * __useUpdateArticleBlocksMutation__
 *
 * To run a mutation, you first call `useUpdateArticleBlocksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleBlocksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleBlocksMutation, { data, loading, error }] = useUpdateArticleBlocksMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateArticleBlocksMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArticleBlocksMutation, UpdateArticleBlocksMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateArticleBlocksMutation, UpdateArticleBlocksMutationVariables>(
    UpdateArticleBlocksDocument,
    baseOptions
  );
}
export type UpdateArticleBlocksMutationHookResult = ReturnType<typeof useUpdateArticleBlocksMutation>;
export type UpdateArticleBlocksMutationResult = ApolloReactCommon.MutationResult<UpdateArticleBlocksMutation>;
export type UpdateArticleBlocksMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateArticleBlocksMutation,
  UpdateArticleBlocksMutationVariables
>;
export const UpdateArticleCategoryDocument = gql`
  mutation updateArticleCategory($id: Int!, $data: ArticleCategoryUpdateInput!) {
    updateArticleCategory(id: $id, data: $data) {
      ...articleCategory
    }
  }
  ${ArticleCategoryFragmentDoc}
`;
export type UpdateArticleCategoryMutationFn = ApolloReactCommon.MutationFunction<
  UpdateArticleCategoryMutation,
  UpdateArticleCategoryMutationVariables
>;
export type UpdateArticleCategoryComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateArticleCategoryMutation, UpdateArticleCategoryMutationVariables>,
  'mutation'
>;

export const UpdateArticleCategoryComponent = (props: UpdateArticleCategoryComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateArticleCategoryMutation, UpdateArticleCategoryMutationVariables>
    mutation={UpdateArticleCategoryDocument}
    {...props}
  />
);

export type UpdateArticleCategoryProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateArticleCategoryMutation,
  UpdateArticleCategoryMutationVariables
> &
  TChildProps;
export function withUpdateArticleCategory<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateArticleCategoryMutation,
    UpdateArticleCategoryMutationVariables,
    UpdateArticleCategoryProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateArticleCategoryMutation,
    UpdateArticleCategoryMutationVariables,
    UpdateArticleCategoryProps<TChildProps>
  >(UpdateArticleCategoryDocument, {
    alias: 'updateArticleCategory',
    ...operationOptions,
  });
}

/**
 * __useUpdateArticleCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateArticleCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleCategoryMutation, { data, loading, error }] = useUpdateArticleCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateArticleCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateArticleCategoryMutation,
    UpdateArticleCategoryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateArticleCategoryMutation, UpdateArticleCategoryMutationVariables>(
    UpdateArticleCategoryDocument,
    baseOptions
  );
}
export type UpdateArticleCategoryMutationHookResult = ReturnType<typeof useUpdateArticleCategoryMutation>;
export type UpdateArticleCategoryMutationResult = ApolloReactCommon.MutationResult<UpdateArticleCategoryMutation>;
export type UpdateArticleCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateArticleCategoryMutation,
  UpdateArticleCategoryMutationVariables
>;
export const UpdateArticleLinkDocument = gql`
  mutation updateArticleLink($id: Int!, $data: ArticleLinkUpdateInput!) {
    updateArticleLink(id: $id, data: $data) {
      ...articleLink
    }
  }
  ${ArticleLinkFragmentDoc}
`;
export type UpdateArticleLinkMutationFn = ApolloReactCommon.MutationFunction<
  UpdateArticleLinkMutation,
  UpdateArticleLinkMutationVariables
>;
export type UpdateArticleLinkComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateArticleLinkMutation, UpdateArticleLinkMutationVariables>,
  'mutation'
>;

export const UpdateArticleLinkComponent = (props: UpdateArticleLinkComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateArticleLinkMutation, UpdateArticleLinkMutationVariables>
    mutation={UpdateArticleLinkDocument}
    {...props}
  />
);

export type UpdateArticleLinkProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateArticleLinkMutation,
  UpdateArticleLinkMutationVariables
> &
  TChildProps;
export function withUpdateArticleLink<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateArticleLinkMutation,
    UpdateArticleLinkMutationVariables,
    UpdateArticleLinkProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateArticleLinkMutation,
    UpdateArticleLinkMutationVariables,
    UpdateArticleLinkProps<TChildProps>
  >(UpdateArticleLinkDocument, {
    alias: 'updateArticleLink',
    ...operationOptions,
  });
}

/**
 * __useUpdateArticleLinkMutation__
 *
 * To run a mutation, you first call `useUpdateArticleLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleLinkMutation, { data, loading, error }] = useUpdateArticleLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateArticleLinkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArticleLinkMutation, UpdateArticleLinkMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateArticleLinkMutation, UpdateArticleLinkMutationVariables>(
    UpdateArticleLinkDocument,
    baseOptions
  );
}
export type UpdateArticleLinkMutationHookResult = ReturnType<typeof useUpdateArticleLinkMutation>;
export type UpdateArticleLinkMutationResult = ApolloReactCommon.MutationResult<UpdateArticleLinkMutation>;
export type UpdateArticleLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateArticleLinkMutation,
  UpdateArticleLinkMutationVariables
>;
export const UpdateArticleTagDocument = gql`
  mutation updateArticleTag($id: Int!, $data: ArticleTagUpdateInput!) {
    updateArticleTag(id: $id, data: $data) {
      ...articleTag
    }
  }
  ${ArticleTagFragmentDoc}
`;
export type UpdateArticleTagMutationFn = ApolloReactCommon.MutationFunction<
  UpdateArticleTagMutation,
  UpdateArticleTagMutationVariables
>;
export type UpdateArticleTagComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateArticleTagMutation, UpdateArticleTagMutationVariables>,
  'mutation'
>;

export const UpdateArticleTagComponent = (props: UpdateArticleTagComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateArticleTagMutation, UpdateArticleTagMutationVariables>
    mutation={UpdateArticleTagDocument}
    {...props}
  />
);

export type UpdateArticleTagProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateArticleTagMutation,
  UpdateArticleTagMutationVariables
> &
  TChildProps;
export function withUpdateArticleTag<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateArticleTagMutation,
    UpdateArticleTagMutationVariables,
    UpdateArticleTagProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateArticleTagMutation,
    UpdateArticleTagMutationVariables,
    UpdateArticleTagProps<TChildProps>
  >(UpdateArticleTagDocument, {
    alias: 'updateArticleTag',
    ...operationOptions,
  });
}

/**
 * __useUpdateArticleTagMutation__
 *
 * To run a mutation, you first call `useUpdateArticleTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleTagMutation, { data, loading, error }] = useUpdateArticleTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateArticleTagMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArticleTagMutation, UpdateArticleTagMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateArticleTagMutation, UpdateArticleTagMutationVariables>(
    UpdateArticleTagDocument,
    baseOptions
  );
}
export type UpdateArticleTagMutationHookResult = ReturnType<typeof useUpdateArticleTagMutation>;
export type UpdateArticleTagMutationResult = ApolloReactCommon.MutationResult<UpdateArticleTagMutation>;
export type UpdateArticleTagMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateArticleTagMutation,
  UpdateArticleTagMutationVariables
>;
export const UpdateCatalogueDocument = gql`
  mutation updateCatalogue($id: Int!, $data: CatalogueUpdateInput!) {
    updateCatalogue(id: $id, data: $data) {
      ...catalogueFlat
    }
  }
  ${CatalogueFlatFragmentDoc}
`;
export type UpdateCatalogueMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCatalogueMutation,
  UpdateCatalogueMutationVariables
>;
export type UpdateCatalogueComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateCatalogueMutation, UpdateCatalogueMutationVariables>,
  'mutation'
>;

export const UpdateCatalogueComponent = (props: UpdateCatalogueComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateCatalogueMutation, UpdateCatalogueMutationVariables>
    mutation={UpdateCatalogueDocument}
    {...props}
  />
);

export type UpdateCatalogueProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateCatalogueMutation,
  UpdateCatalogueMutationVariables
> &
  TChildProps;
export function withUpdateCatalogue<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCatalogueMutation,
    UpdateCatalogueMutationVariables,
    UpdateCatalogueProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCatalogueMutation,
    UpdateCatalogueMutationVariables,
    UpdateCatalogueProps<TChildProps>
  >(UpdateCatalogueDocument, {
    alias: 'updateCatalogue',
    ...operationOptions,
  });
}

/**
 * __useUpdateCatalogueMutation__
 *
 * To run a mutation, you first call `useUpdateCatalogueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCatalogueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCatalogueMutation, { data, loading, error }] = useUpdateCatalogueMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCatalogueMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCatalogueMutation, UpdateCatalogueMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateCatalogueMutation, UpdateCatalogueMutationVariables>(
    UpdateCatalogueDocument,
    baseOptions
  );
}
export type UpdateCatalogueMutationHookResult = ReturnType<typeof useUpdateCatalogueMutation>;
export type UpdateCatalogueMutationResult = ApolloReactCommon.MutationResult<UpdateCatalogueMutation>;
export type UpdateCatalogueMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCatalogueMutation,
  UpdateCatalogueMutationVariables
>;
export const UpdateCatalogueCategoryDocument = gql`
  mutation updateCatalogueCategory($id: Int!, $data: CatalogueCategoryUpdateInput!) {
    updateCatalogueCategory(id: $id, data: $data) {
      ...catalogueCategoryFull
    }
  }
  ${CatalogueCategoryFullFragmentDoc}
`;
export type UpdateCatalogueCategoryMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCatalogueCategoryMutation,
  UpdateCatalogueCategoryMutationVariables
>;
export type UpdateCatalogueCategoryComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCatalogueCategoryMutation,
    UpdateCatalogueCategoryMutationVariables
  >,
  'mutation'
>;

export const UpdateCatalogueCategoryComponent = (props: UpdateCatalogueCategoryComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateCatalogueCategoryMutation, UpdateCatalogueCategoryMutationVariables>
    mutation={UpdateCatalogueCategoryDocument}
    {...props}
  />
);

export type UpdateCatalogueCategoryProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateCatalogueCategoryMutation,
  UpdateCatalogueCategoryMutationVariables
> &
  TChildProps;
export function withUpdateCatalogueCategory<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCatalogueCategoryMutation,
    UpdateCatalogueCategoryMutationVariables,
    UpdateCatalogueCategoryProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCatalogueCategoryMutation,
    UpdateCatalogueCategoryMutationVariables,
    UpdateCatalogueCategoryProps<TChildProps>
  >(UpdateCatalogueCategoryDocument, {
    alias: 'updateCatalogueCategory',
    ...operationOptions,
  });
}

/**
 * __useUpdateCatalogueCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCatalogueCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCatalogueCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCatalogueCategoryMutation, { data, loading, error }] = useUpdateCatalogueCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCatalogueCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCatalogueCategoryMutation,
    UpdateCatalogueCategoryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateCatalogueCategoryMutation, UpdateCatalogueCategoryMutationVariables>(
    UpdateCatalogueCategoryDocument,
    baseOptions
  );
}
export type UpdateCatalogueCategoryMutationHookResult = ReturnType<typeof useUpdateCatalogueCategoryMutation>;
export type UpdateCatalogueCategoryMutationResult = ApolloReactCommon.MutationResult<UpdateCatalogueCategoryMutation>;
export type UpdateCatalogueCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCatalogueCategoryMutation,
  UpdateCatalogueCategoryMutationVariables
>;
export const UpdateCatalogueCategoryBatchDocument = gql`
  mutation updateCatalogueCategoryBatch($data: [CatalogueCategoryBatchUpdateInput!]!) {
    updateCatalogueCategoryBatch(data: $data)
  }
`;
export type UpdateCatalogueCategoryBatchMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCatalogueCategoryBatchMutation,
  UpdateCatalogueCategoryBatchMutationVariables
>;
export type UpdateCatalogueCategoryBatchComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCatalogueCategoryBatchMutation,
    UpdateCatalogueCategoryBatchMutationVariables
  >,
  'mutation'
>;

export const UpdateCatalogueCategoryBatchComponent = (props: UpdateCatalogueCategoryBatchComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateCatalogueCategoryBatchMutation, UpdateCatalogueCategoryBatchMutationVariables>
    mutation={UpdateCatalogueCategoryBatchDocument}
    {...props}
  />
);

export type UpdateCatalogueCategoryBatchProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateCatalogueCategoryBatchMutation,
  UpdateCatalogueCategoryBatchMutationVariables
> &
  TChildProps;
export function withUpdateCatalogueCategoryBatch<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCatalogueCategoryBatchMutation,
    UpdateCatalogueCategoryBatchMutationVariables,
    UpdateCatalogueCategoryBatchProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCatalogueCategoryBatchMutation,
    UpdateCatalogueCategoryBatchMutationVariables,
    UpdateCatalogueCategoryBatchProps<TChildProps>
  >(UpdateCatalogueCategoryBatchDocument, {
    alias: 'updateCatalogueCategoryBatch',
    ...operationOptions,
  });
}

/**
 * __useUpdateCatalogueCategoryBatchMutation__
 *
 * To run a mutation, you first call `useUpdateCatalogueCategoryBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCatalogueCategoryBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCatalogueCategoryBatchMutation, { data, loading, error }] = useUpdateCatalogueCategoryBatchMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCatalogueCategoryBatchMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCatalogueCategoryBatchMutation,
    UpdateCatalogueCategoryBatchMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCatalogueCategoryBatchMutation,
    UpdateCatalogueCategoryBatchMutationVariables
  >(UpdateCatalogueCategoryBatchDocument, baseOptions);
}
export type UpdateCatalogueCategoryBatchMutationHookResult = ReturnType<typeof useUpdateCatalogueCategoryBatchMutation>;
export type UpdateCatalogueCategoryBatchMutationResult = ApolloReactCommon.MutationResult<
  UpdateCatalogueCategoryBatchMutation
>;
export type UpdateCatalogueCategoryBatchMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCatalogueCategoryBatchMutation,
  UpdateCatalogueCategoryBatchMutationVariables
>;
export const UpdateCatalogueItemDocument = gql`
  mutation updateCatalogueItem($id: Int!, $data: CatalogueItemUpdateInput!) {
    updateCatalogueItem(id: $id, data: $data) {
      ...catalogueItem
    }
  }
  ${CatalogueItemFragmentDoc}
`;
export type UpdateCatalogueItemMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCatalogueItemMutation,
  UpdateCatalogueItemMutationVariables
>;
export type UpdateCatalogueItemComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateCatalogueItemMutation, UpdateCatalogueItemMutationVariables>,
  'mutation'
>;

export const UpdateCatalogueItemComponent = (props: UpdateCatalogueItemComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateCatalogueItemMutation, UpdateCatalogueItemMutationVariables>
    mutation={UpdateCatalogueItemDocument}
    {...props}
  />
);

export type UpdateCatalogueItemProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateCatalogueItemMutation,
  UpdateCatalogueItemMutationVariables
> &
  TChildProps;
export function withUpdateCatalogueItem<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCatalogueItemMutation,
    UpdateCatalogueItemMutationVariables,
    UpdateCatalogueItemProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCatalogueItemMutation,
    UpdateCatalogueItemMutationVariables,
    UpdateCatalogueItemProps<TChildProps>
  >(UpdateCatalogueItemDocument, {
    alias: 'updateCatalogueItem',
    ...operationOptions,
  });
}

/**
 * __useUpdateCatalogueItemMutation__
 *
 * To run a mutation, you first call `useUpdateCatalogueItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCatalogueItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCatalogueItemMutation, { data, loading, error }] = useUpdateCatalogueItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCatalogueItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCatalogueItemMutation, UpdateCatalogueItemMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateCatalogueItemMutation, UpdateCatalogueItemMutationVariables>(
    UpdateCatalogueItemDocument,
    baseOptions
  );
}
export type UpdateCatalogueItemMutationHookResult = ReturnType<typeof useUpdateCatalogueItemMutation>;
export type UpdateCatalogueItemMutationResult = ApolloReactCommon.MutationResult<UpdateCatalogueItemMutation>;
export type UpdateCatalogueItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCatalogueItemMutation,
  UpdateCatalogueItemMutationVariables
>;
export const UpdateCatalogueItemBatchDocument = gql`
  mutation updateCatalogueItemBatch($data: [CatalogueItemBatchUpdateInput!]!) {
    updateCatalogueItemBatch(data: $data)
  }
`;
export type UpdateCatalogueItemBatchMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCatalogueItemBatchMutation,
  UpdateCatalogueItemBatchMutationVariables
>;
export type UpdateCatalogueItemBatchComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCatalogueItemBatchMutation,
    UpdateCatalogueItemBatchMutationVariables
  >,
  'mutation'
>;

export const UpdateCatalogueItemBatchComponent = (props: UpdateCatalogueItemBatchComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateCatalogueItemBatchMutation, UpdateCatalogueItemBatchMutationVariables>
    mutation={UpdateCatalogueItemBatchDocument}
    {...props}
  />
);

export type UpdateCatalogueItemBatchProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateCatalogueItemBatchMutation,
  UpdateCatalogueItemBatchMutationVariables
> &
  TChildProps;
export function withUpdateCatalogueItemBatch<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCatalogueItemBatchMutation,
    UpdateCatalogueItemBatchMutationVariables,
    UpdateCatalogueItemBatchProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCatalogueItemBatchMutation,
    UpdateCatalogueItemBatchMutationVariables,
    UpdateCatalogueItemBatchProps<TChildProps>
  >(UpdateCatalogueItemBatchDocument, {
    alias: 'updateCatalogueItemBatch',
    ...operationOptions,
  });
}

/**
 * __useUpdateCatalogueItemBatchMutation__
 *
 * To run a mutation, you first call `useUpdateCatalogueItemBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCatalogueItemBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCatalogueItemBatchMutation, { data, loading, error }] = useUpdateCatalogueItemBatchMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCatalogueItemBatchMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCatalogueItemBatchMutation,
    UpdateCatalogueItemBatchMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateCatalogueItemBatchMutation, UpdateCatalogueItemBatchMutationVariables>(
    UpdateCatalogueItemBatchDocument,
    baseOptions
  );
}
export type UpdateCatalogueItemBatchMutationHookResult = ReturnType<typeof useUpdateCatalogueItemBatchMutation>;
export type UpdateCatalogueItemBatchMutationResult = ApolloReactCommon.MutationResult<UpdateCatalogueItemBatchMutation>;
export type UpdateCatalogueItemBatchMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCatalogueItemBatchMutation,
  UpdateCatalogueItemBatchMutationVariables
>;
export const UpdateCatalogueItemPriceDocument = gql`
  mutation updateCatalogueItemPrice($id: Int!, $data: CatalogueItemPriceUpdateInput!) {
    updateCatalogueItemPrice(id: $id, data: $data) {
      ...catalogueItemPrice
    }
  }
  ${CatalogueItemPriceFragmentDoc}
`;
export type UpdateCatalogueItemPriceMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCatalogueItemPriceMutation,
  UpdateCatalogueItemPriceMutationVariables
>;
export type UpdateCatalogueItemPriceComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCatalogueItemPriceMutation,
    UpdateCatalogueItemPriceMutationVariables
  >,
  'mutation'
>;

export const UpdateCatalogueItemPriceComponent = (props: UpdateCatalogueItemPriceComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateCatalogueItemPriceMutation, UpdateCatalogueItemPriceMutationVariables>
    mutation={UpdateCatalogueItemPriceDocument}
    {...props}
  />
);

export type UpdateCatalogueItemPriceProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateCatalogueItemPriceMutation,
  UpdateCatalogueItemPriceMutationVariables
> &
  TChildProps;
export function withUpdateCatalogueItemPrice<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCatalogueItemPriceMutation,
    UpdateCatalogueItemPriceMutationVariables,
    UpdateCatalogueItemPriceProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCatalogueItemPriceMutation,
    UpdateCatalogueItemPriceMutationVariables,
    UpdateCatalogueItemPriceProps<TChildProps>
  >(UpdateCatalogueItemPriceDocument, {
    alias: 'updateCatalogueItemPrice',
    ...operationOptions,
  });
}

/**
 * __useUpdateCatalogueItemPriceMutation__
 *
 * To run a mutation, you first call `useUpdateCatalogueItemPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCatalogueItemPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCatalogueItemPriceMutation, { data, loading, error }] = useUpdateCatalogueItemPriceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCatalogueItemPriceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCatalogueItemPriceMutation,
    UpdateCatalogueItemPriceMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateCatalogueItemPriceMutation, UpdateCatalogueItemPriceMutationVariables>(
    UpdateCatalogueItemPriceDocument,
    baseOptions
  );
}
export type UpdateCatalogueItemPriceMutationHookResult = ReturnType<typeof useUpdateCatalogueItemPriceMutation>;
export type UpdateCatalogueItemPriceMutationResult = ApolloReactCommon.MutationResult<UpdateCatalogueItemPriceMutation>;
export type UpdateCatalogueItemPriceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCatalogueItemPriceMutation,
  UpdateCatalogueItemPriceMutationVariables
>;
export const UpdateCateringDocument = gql`
  mutation updateCatering($id: Int!, $data: CateringUpdateInput!) {
    updateCatering(id: $id, data: $data) {
      ...catering
    }
  }
  ${CateringFragmentDoc}
`;
export type UpdateCateringMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCateringMutation,
  UpdateCateringMutationVariables
>;
export type UpdateCateringComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateCateringMutation, UpdateCateringMutationVariables>,
  'mutation'
>;

export const UpdateCateringComponent = (props: UpdateCateringComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateCateringMutation, UpdateCateringMutationVariables>
    mutation={UpdateCateringDocument}
    {...props}
  />
);

export type UpdateCateringProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateCateringMutation,
  UpdateCateringMutationVariables
> &
  TChildProps;
export function withUpdateCatering<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCateringMutation,
    UpdateCateringMutationVariables,
    UpdateCateringProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCateringMutation,
    UpdateCateringMutationVariables,
    UpdateCateringProps<TChildProps>
  >(UpdateCateringDocument, {
    alias: 'updateCatering',
    ...operationOptions,
  });
}

/**
 * __useUpdateCateringMutation__
 *
 * To run a mutation, you first call `useUpdateCateringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCateringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCateringMutation, { data, loading, error }] = useUpdateCateringMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCateringMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCateringMutation, UpdateCateringMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateCateringMutation, UpdateCateringMutationVariables>(
    UpdateCateringDocument,
    baseOptions
  );
}
export type UpdateCateringMutationHookResult = ReturnType<typeof useUpdateCateringMutation>;
export type UpdateCateringMutationResult = ApolloReactCommon.MutationResult<UpdateCateringMutation>;
export type UpdateCateringMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCateringMutation,
  UpdateCateringMutationVariables
>;
export const UpdateCommerceOrderDocument = gql`
  mutation updateCommerceOrder($id: Int!, $data: CommerceOrderUpdateInput!) {
    updateCommerceOrder(id: $id, data: $data) {
      ...commerceOrderUpdate
    }
  }
  ${CommerceOrderUpdateFragmentDoc}
`;
export type UpdateCommerceOrderMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCommerceOrderMutation,
  UpdateCommerceOrderMutationVariables
>;
export type UpdateCommerceOrderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateCommerceOrderMutation, UpdateCommerceOrderMutationVariables>,
  'mutation'
>;

export const UpdateCommerceOrderComponent = (props: UpdateCommerceOrderComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateCommerceOrderMutation, UpdateCommerceOrderMutationVariables>
    mutation={UpdateCommerceOrderDocument}
    {...props}
  />
);

export type UpdateCommerceOrderProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateCommerceOrderMutation,
  UpdateCommerceOrderMutationVariables
> &
  TChildProps;
export function withUpdateCommerceOrder<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCommerceOrderMutation,
    UpdateCommerceOrderMutationVariables,
    UpdateCommerceOrderProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCommerceOrderMutation,
    UpdateCommerceOrderMutationVariables,
    UpdateCommerceOrderProps<TChildProps>
  >(UpdateCommerceOrderDocument, {
    alias: 'updateCommerceOrder',
    ...operationOptions,
  });
}

/**
 * __useUpdateCommerceOrderMutation__
 *
 * To run a mutation, you first call `useUpdateCommerceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommerceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommerceOrderMutation, { data, loading, error }] = useUpdateCommerceOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCommerceOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCommerceOrderMutation, UpdateCommerceOrderMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateCommerceOrderMutation, UpdateCommerceOrderMutationVariables>(
    UpdateCommerceOrderDocument,
    baseOptions
  );
}
export type UpdateCommerceOrderMutationHookResult = ReturnType<typeof useUpdateCommerceOrderMutation>;
export type UpdateCommerceOrderMutationResult = ApolloReactCommon.MutationResult<UpdateCommerceOrderMutation>;
export type UpdateCommerceOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCommerceOrderMutation,
  UpdateCommerceOrderMutationVariables
>;
export const UpdateCommerceOrderDataDocument = gql`
  mutation updateCommerceOrderData($id: Int!, $data: CommerceOrderDataUpdateInput!) {
    updateCommerceOrderData(id: $id, data: $data) {
      ...commerceOrderData
    }
  }
  ${CommerceOrderDataFragmentDoc}
`;
export type UpdateCommerceOrderDataMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCommerceOrderDataMutation,
  UpdateCommerceOrderDataMutationVariables
>;
export type UpdateCommerceOrderDataComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCommerceOrderDataMutation,
    UpdateCommerceOrderDataMutationVariables
  >,
  'mutation'
>;

export const UpdateCommerceOrderDataComponent = (props: UpdateCommerceOrderDataComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateCommerceOrderDataMutation, UpdateCommerceOrderDataMutationVariables>
    mutation={UpdateCommerceOrderDataDocument}
    {...props}
  />
);

export type UpdateCommerceOrderDataProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateCommerceOrderDataMutation,
  UpdateCommerceOrderDataMutationVariables
> &
  TChildProps;
export function withUpdateCommerceOrderData<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCommerceOrderDataMutation,
    UpdateCommerceOrderDataMutationVariables,
    UpdateCommerceOrderDataProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCommerceOrderDataMutation,
    UpdateCommerceOrderDataMutationVariables,
    UpdateCommerceOrderDataProps<TChildProps>
  >(UpdateCommerceOrderDataDocument, {
    alias: 'updateCommerceOrderData',
    ...operationOptions,
  });
}

/**
 * __useUpdateCommerceOrderDataMutation__
 *
 * To run a mutation, you first call `useUpdateCommerceOrderDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommerceOrderDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommerceOrderDataMutation, { data, loading, error }] = useUpdateCommerceOrderDataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCommerceOrderDataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCommerceOrderDataMutation,
    UpdateCommerceOrderDataMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateCommerceOrderDataMutation, UpdateCommerceOrderDataMutationVariables>(
    UpdateCommerceOrderDataDocument,
    baseOptions
  );
}
export type UpdateCommerceOrderDataMutationHookResult = ReturnType<typeof useUpdateCommerceOrderDataMutation>;
export type UpdateCommerceOrderDataMutationResult = ApolloReactCommon.MutationResult<UpdateCommerceOrderDataMutation>;
export type UpdateCommerceOrderDataMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCommerceOrderDataMutation,
  UpdateCommerceOrderDataMutationVariables
>;
export const UpdateCommerceOrderDatasDocument = gql`
  mutation updateCommerceOrderDatas($data: [CommerceOrderDatasUpdateInput!]!) {
    updateCommerceOrderDatas(data: $data)
  }
`;
export type UpdateCommerceOrderDatasMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCommerceOrderDatasMutation,
  UpdateCommerceOrderDatasMutationVariables
>;
export type UpdateCommerceOrderDatasComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCommerceOrderDatasMutation,
    UpdateCommerceOrderDatasMutationVariables
  >,
  'mutation'
>;

export const UpdateCommerceOrderDatasComponent = (props: UpdateCommerceOrderDatasComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateCommerceOrderDatasMutation, UpdateCommerceOrderDatasMutationVariables>
    mutation={UpdateCommerceOrderDatasDocument}
    {...props}
  />
);

export type UpdateCommerceOrderDatasProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateCommerceOrderDatasMutation,
  UpdateCommerceOrderDatasMutationVariables
> &
  TChildProps;
export function withUpdateCommerceOrderDatas<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCommerceOrderDatasMutation,
    UpdateCommerceOrderDatasMutationVariables,
    UpdateCommerceOrderDatasProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCommerceOrderDatasMutation,
    UpdateCommerceOrderDatasMutationVariables,
    UpdateCommerceOrderDatasProps<TChildProps>
  >(UpdateCommerceOrderDatasDocument, {
    alias: 'updateCommerceOrderDatas',
    ...operationOptions,
  });
}

/**
 * __useUpdateCommerceOrderDatasMutation__
 *
 * To run a mutation, you first call `useUpdateCommerceOrderDatasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommerceOrderDatasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommerceOrderDatasMutation, { data, loading, error }] = useUpdateCommerceOrderDatasMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCommerceOrderDatasMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCommerceOrderDatasMutation,
    UpdateCommerceOrderDatasMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateCommerceOrderDatasMutation, UpdateCommerceOrderDatasMutationVariables>(
    UpdateCommerceOrderDatasDocument,
    baseOptions
  );
}
export type UpdateCommerceOrderDatasMutationHookResult = ReturnType<typeof useUpdateCommerceOrderDatasMutation>;
export type UpdateCommerceOrderDatasMutationResult = ApolloReactCommon.MutationResult<UpdateCommerceOrderDatasMutation>;
export type UpdateCommerceOrderDatasMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCommerceOrderDatasMutation,
  UpdateCommerceOrderDatasMutationVariables
>;
export const UpdateCommerceOrderFieldDocument = gql`
  mutation updateCommerceOrderField($id: Int!, $data: CommerceOrderFieldUpdateInput!) {
    updateCommerceOrderField(id: $id, data: $data) {
      ...commerceOrderField
    }
  }
  ${CommerceOrderFieldFragmentDoc}
`;
export type UpdateCommerceOrderFieldMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCommerceOrderFieldMutation,
  UpdateCommerceOrderFieldMutationVariables
>;
export type UpdateCommerceOrderFieldComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCommerceOrderFieldMutation,
    UpdateCommerceOrderFieldMutationVariables
  >,
  'mutation'
>;

export const UpdateCommerceOrderFieldComponent = (props: UpdateCommerceOrderFieldComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateCommerceOrderFieldMutation, UpdateCommerceOrderFieldMutationVariables>
    mutation={UpdateCommerceOrderFieldDocument}
    {...props}
  />
);

export type UpdateCommerceOrderFieldProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateCommerceOrderFieldMutation,
  UpdateCommerceOrderFieldMutationVariables
> &
  TChildProps;
export function withUpdateCommerceOrderField<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCommerceOrderFieldMutation,
    UpdateCommerceOrderFieldMutationVariables,
    UpdateCommerceOrderFieldProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCommerceOrderFieldMutation,
    UpdateCommerceOrderFieldMutationVariables,
    UpdateCommerceOrderFieldProps<TChildProps>
  >(UpdateCommerceOrderFieldDocument, {
    alias: 'updateCommerceOrderField',
    ...operationOptions,
  });
}

/**
 * __useUpdateCommerceOrderFieldMutation__
 *
 * To run a mutation, you first call `useUpdateCommerceOrderFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommerceOrderFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommerceOrderFieldMutation, { data, loading, error }] = useUpdateCommerceOrderFieldMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCommerceOrderFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCommerceOrderFieldMutation,
    UpdateCommerceOrderFieldMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateCommerceOrderFieldMutation, UpdateCommerceOrderFieldMutationVariables>(
    UpdateCommerceOrderFieldDocument,
    baseOptions
  );
}
export type UpdateCommerceOrderFieldMutationHookResult = ReturnType<typeof useUpdateCommerceOrderFieldMutation>;
export type UpdateCommerceOrderFieldMutationResult = ApolloReactCommon.MutationResult<UpdateCommerceOrderFieldMutation>;
export type UpdateCommerceOrderFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCommerceOrderFieldMutation,
  UpdateCommerceOrderFieldMutationVariables
>;
export const UpdateCommerceOrderItemDocument = gql`
  mutation updateCommerceOrderItem($id: Int!, $data: CommerceOrderItemUpdateInput!) {
    updateCommerceOrderItem(id: $id, data: $data) {
      ...commerceOrderItem
    }
  }
  ${CommerceOrderItemFragmentDoc}
`;
export type UpdateCommerceOrderItemMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCommerceOrderItemMutation,
  UpdateCommerceOrderItemMutationVariables
>;
export type UpdateCommerceOrderItemComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCommerceOrderItemMutation,
    UpdateCommerceOrderItemMutationVariables
  >,
  'mutation'
>;

export const UpdateCommerceOrderItemComponent = (props: UpdateCommerceOrderItemComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateCommerceOrderItemMutation, UpdateCommerceOrderItemMutationVariables>
    mutation={UpdateCommerceOrderItemDocument}
    {...props}
  />
);

export type UpdateCommerceOrderItemProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateCommerceOrderItemMutation,
  UpdateCommerceOrderItemMutationVariables
> &
  TChildProps;
export function withUpdateCommerceOrderItem<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCommerceOrderItemMutation,
    UpdateCommerceOrderItemMutationVariables,
    UpdateCommerceOrderItemProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCommerceOrderItemMutation,
    UpdateCommerceOrderItemMutationVariables,
    UpdateCommerceOrderItemProps<TChildProps>
  >(UpdateCommerceOrderItemDocument, {
    alias: 'updateCommerceOrderItem',
    ...operationOptions,
  });
}

/**
 * __useUpdateCommerceOrderItemMutation__
 *
 * To run a mutation, you first call `useUpdateCommerceOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommerceOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommerceOrderItemMutation, { data, loading, error }] = useUpdateCommerceOrderItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCommerceOrderItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCommerceOrderItemMutation,
    UpdateCommerceOrderItemMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateCommerceOrderItemMutation, UpdateCommerceOrderItemMutationVariables>(
    UpdateCommerceOrderItemDocument,
    baseOptions
  );
}
export type UpdateCommerceOrderItemMutationHookResult = ReturnType<typeof useUpdateCommerceOrderItemMutation>;
export type UpdateCommerceOrderItemMutationResult = ApolloReactCommon.MutationResult<UpdateCommerceOrderItemMutation>;
export type UpdateCommerceOrderItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCommerceOrderItemMutation,
  UpdateCommerceOrderItemMutationVariables
>;
export const UpdateCommerceShopDocument = gql`
  mutation updateCommerceShop($id: Int!, $data: CommerceShopUpdateInput!) {
    updateCommerceShop(id: $id, data: $data) {
      ...commerceShop
    }
  }
  ${CommerceShopFragmentDoc}
`;
export type UpdateCommerceShopMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCommerceShopMutation,
  UpdateCommerceShopMutationVariables
>;
export type UpdateCommerceShopComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateCommerceShopMutation, UpdateCommerceShopMutationVariables>,
  'mutation'
>;

export const UpdateCommerceShopComponent = (props: UpdateCommerceShopComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateCommerceShopMutation, UpdateCommerceShopMutationVariables>
    mutation={UpdateCommerceShopDocument}
    {...props}
  />
);

export type UpdateCommerceShopProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateCommerceShopMutation,
  UpdateCommerceShopMutationVariables
> &
  TChildProps;
export function withUpdateCommerceShop<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCommerceShopMutation,
    UpdateCommerceShopMutationVariables,
    UpdateCommerceShopProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCommerceShopMutation,
    UpdateCommerceShopMutationVariables,
    UpdateCommerceShopProps<TChildProps>
  >(UpdateCommerceShopDocument, {
    alias: 'updateCommerceShop',
    ...operationOptions,
  });
}

/**
 * __useUpdateCommerceShopMutation__
 *
 * To run a mutation, you first call `useUpdateCommerceShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommerceShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommerceShopMutation, { data, loading, error }] = useUpdateCommerceShopMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCommerceShopMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCommerceShopMutation, UpdateCommerceShopMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateCommerceShopMutation, UpdateCommerceShopMutationVariables>(
    UpdateCommerceShopDocument,
    baseOptions
  );
}
export type UpdateCommerceShopMutationHookResult = ReturnType<typeof useUpdateCommerceShopMutation>;
export type UpdateCommerceShopMutationResult = ApolloReactCommon.MutationResult<UpdateCommerceShopMutation>;
export type UpdateCommerceShopMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCommerceShopMutation,
  UpdateCommerceShopMutationVariables
>;
export const UpdateContentTranslationDocument = gql`
  mutation updateContentTranslation($id: Int!, $data: ContentTranslationUpdateInput!) {
    updateContentTranslation(id: $id, data: $data) {
      ...contentTranslation
    }
  }
  ${ContentTranslationFragmentDoc}
`;
export type UpdateContentTranslationMutationFn = ApolloReactCommon.MutationFunction<
  UpdateContentTranslationMutation,
  UpdateContentTranslationMutationVariables
>;
export type UpdateContentTranslationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateContentTranslationMutation,
    UpdateContentTranslationMutationVariables
  >,
  'mutation'
>;

export const UpdateContentTranslationComponent = (props: UpdateContentTranslationComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateContentTranslationMutation, UpdateContentTranslationMutationVariables>
    mutation={UpdateContentTranslationDocument}
    {...props}
  />
);

export type UpdateContentTranslationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateContentTranslationMutation,
  UpdateContentTranslationMutationVariables
> &
  TChildProps;
export function withUpdateContentTranslation<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateContentTranslationMutation,
    UpdateContentTranslationMutationVariables,
    UpdateContentTranslationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateContentTranslationMutation,
    UpdateContentTranslationMutationVariables,
    UpdateContentTranslationProps<TChildProps>
  >(UpdateContentTranslationDocument, {
    alias: 'updateContentTranslation',
    ...operationOptions,
  });
}

/**
 * __useUpdateContentTranslationMutation__
 *
 * To run a mutation, you first call `useUpdateContentTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContentTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContentTranslationMutation, { data, loading, error }] = useUpdateContentTranslationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateContentTranslationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateContentTranslationMutation,
    UpdateContentTranslationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateContentTranslationMutation, UpdateContentTranslationMutationVariables>(
    UpdateContentTranslationDocument,
    baseOptions
  );
}
export type UpdateContentTranslationMutationHookResult = ReturnType<typeof useUpdateContentTranslationMutation>;
export type UpdateContentTranslationMutationResult = ApolloReactCommon.MutationResult<UpdateContentTranslationMutation>;
export type UpdateContentTranslationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateContentTranslationMutation,
  UpdateContentTranslationMutationVariables
>;
export const UpdateDisputeDocument = gql`
  mutation updateDispute($id: Int!, $data: DisputeUpdateInput!) {
    updateDispute(id: $id, data: $data) {
      ...dispute
    }
  }
  ${DisputeFragmentDoc}
`;
export type UpdateDisputeMutationFn = ApolloReactCommon.MutationFunction<
  UpdateDisputeMutation,
  UpdateDisputeMutationVariables
>;
export type UpdateDisputeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateDisputeMutation, UpdateDisputeMutationVariables>,
  'mutation'
>;

export const UpdateDisputeComponent = (props: UpdateDisputeComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateDisputeMutation, UpdateDisputeMutationVariables>
    mutation={UpdateDisputeDocument}
    {...props}
  />
);

export type UpdateDisputeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateDisputeMutation,
  UpdateDisputeMutationVariables
> &
  TChildProps;
export function withUpdateDispute<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateDisputeMutation,
    UpdateDisputeMutationVariables,
    UpdateDisputeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateDisputeMutation,
    UpdateDisputeMutationVariables,
    UpdateDisputeProps<TChildProps>
  >(UpdateDisputeDocument, {
    alias: 'updateDispute',
    ...operationOptions,
  });
}

/**
 * __useUpdateDisputeMutation__
 *
 * To run a mutation, you first call `useUpdateDisputeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDisputeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDisputeMutation, { data, loading, error }] = useUpdateDisputeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDisputeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDisputeMutation, UpdateDisputeMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateDisputeMutation, UpdateDisputeMutationVariables>(
    UpdateDisputeDocument,
    baseOptions
  );
}
export type UpdateDisputeMutationHookResult = ReturnType<typeof useUpdateDisputeMutation>;
export type UpdateDisputeMutationResult = ApolloReactCommon.MutationResult<UpdateDisputeMutation>;
export type UpdateDisputeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateDisputeMutation,
  UpdateDisputeMutationVariables
>;
export const UpdateDisputeDisputeResponseDocument = gql`
  mutation updateDisputeDisputeResponse($id: Int!, $data: DisputeResponseUpdateInput!) {
    updateDisputeDisputeResponse(id: $id, data: $data) {
      ...disputeDisputeResponse
    }
  }
  ${DisputeDisputeResponseFragmentDoc}
`;
export type UpdateDisputeDisputeResponseMutationFn = ApolloReactCommon.MutationFunction<
  UpdateDisputeDisputeResponseMutation,
  UpdateDisputeDisputeResponseMutationVariables
>;
export type UpdateDisputeDisputeResponseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateDisputeDisputeResponseMutation,
    UpdateDisputeDisputeResponseMutationVariables
  >,
  'mutation'
>;

export const UpdateDisputeDisputeResponseComponent = (props: UpdateDisputeDisputeResponseComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateDisputeDisputeResponseMutation, UpdateDisputeDisputeResponseMutationVariables>
    mutation={UpdateDisputeDisputeResponseDocument}
    {...props}
  />
);

export type UpdateDisputeDisputeResponseProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateDisputeDisputeResponseMutation,
  UpdateDisputeDisputeResponseMutationVariables
> &
  TChildProps;
export function withUpdateDisputeDisputeResponse<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateDisputeDisputeResponseMutation,
    UpdateDisputeDisputeResponseMutationVariables,
    UpdateDisputeDisputeResponseProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateDisputeDisputeResponseMutation,
    UpdateDisputeDisputeResponseMutationVariables,
    UpdateDisputeDisputeResponseProps<TChildProps>
  >(UpdateDisputeDisputeResponseDocument, {
    alias: 'updateDisputeDisputeResponse',
    ...operationOptions,
  });
}

/**
 * __useUpdateDisputeDisputeResponseMutation__
 *
 * To run a mutation, you first call `useUpdateDisputeDisputeResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDisputeDisputeResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDisputeDisputeResponseMutation, { data, loading, error }] = useUpdateDisputeDisputeResponseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDisputeDisputeResponseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateDisputeDisputeResponseMutation,
    UpdateDisputeDisputeResponseMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateDisputeDisputeResponseMutation,
    UpdateDisputeDisputeResponseMutationVariables
  >(UpdateDisputeDisputeResponseDocument, baseOptions);
}
export type UpdateDisputeDisputeResponseMutationHookResult = ReturnType<typeof useUpdateDisputeDisputeResponseMutation>;
export type UpdateDisputeDisputeResponseMutationResult = ApolloReactCommon.MutationResult<
  UpdateDisputeDisputeResponseMutation
>;
export type UpdateDisputeDisputeResponseMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateDisputeDisputeResponseMutation,
  UpdateDisputeDisputeResponseMutationVariables
>;
export const UpdateEventDocument = gql`
  mutation updateEvent($id: Int!, $data: EventUpdateInput!) {
    updateEvent(id: $id, data: $data) {
      ...eventFull
    }
  }
  ${EventFullFragmentDoc}
`;
export type UpdateEventMutationFn = ApolloReactCommon.MutationFunction<
  UpdateEventMutation,
  UpdateEventMutationVariables
>;
export type UpdateEventComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateEventMutation, UpdateEventMutationVariables>,
  'mutation'
>;

export const UpdateEventComponent = (props: UpdateEventComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateEventMutation, UpdateEventMutationVariables>
    mutation={UpdateEventDocument}
    {...props}
  />
);

export type UpdateEventProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateEventMutation,
  UpdateEventMutationVariables
> &
  TChildProps;
export function withUpdateEvent<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateEventMutation,
    UpdateEventMutationVariables,
    UpdateEventProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateEventMutation,
    UpdateEventMutationVariables,
    UpdateEventProps<TChildProps>
  >(UpdateEventDocument, {
    alias: 'updateEvent',
    ...operationOptions,
  });
}

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(
    UpdateEventDocument,
    baseOptions
  );
}
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = ApolloReactCommon.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateEventMutation,
  UpdateEventMutationVariables
>;
export const UpdateEventEventAccessDocument = gql`
  mutation updateEventEventAccess($id: Int!, $data: EventEventAccessUpdateInput!) {
    updateEventEventAccess(id: $id, data: $data) {
      ...eventEventAccess
    }
  }
  ${EventEventAccessFragmentDoc}
`;
export type UpdateEventEventAccessMutationFn = ApolloReactCommon.MutationFunction<
  UpdateEventEventAccessMutation,
  UpdateEventEventAccessMutationVariables
>;
export type UpdateEventEventAccessComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateEventEventAccessMutation,
    UpdateEventEventAccessMutationVariables
  >,
  'mutation'
>;

export const UpdateEventEventAccessComponent = (props: UpdateEventEventAccessComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateEventEventAccessMutation, UpdateEventEventAccessMutationVariables>
    mutation={UpdateEventEventAccessDocument}
    {...props}
  />
);

export type UpdateEventEventAccessProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateEventEventAccessMutation,
  UpdateEventEventAccessMutationVariables
> &
  TChildProps;
export function withUpdateEventEventAccess<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateEventEventAccessMutation,
    UpdateEventEventAccessMutationVariables,
    UpdateEventEventAccessProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateEventEventAccessMutation,
    UpdateEventEventAccessMutationVariables,
    UpdateEventEventAccessProps<TChildProps>
  >(UpdateEventEventAccessDocument, {
    alias: 'updateEventEventAccess',
    ...operationOptions,
  });
}

/**
 * __useUpdateEventEventAccessMutation__
 *
 * To run a mutation, you first call `useUpdateEventEventAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventEventAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventEventAccessMutation, { data, loading, error }] = useUpdateEventEventAccessMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEventEventAccessMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateEventEventAccessMutation,
    UpdateEventEventAccessMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateEventEventAccessMutation, UpdateEventEventAccessMutationVariables>(
    UpdateEventEventAccessDocument,
    baseOptions
  );
}
export type UpdateEventEventAccessMutationHookResult = ReturnType<typeof useUpdateEventEventAccessMutation>;
export type UpdateEventEventAccessMutationResult = ApolloReactCommon.MutationResult<UpdateEventEventAccessMutation>;
export type UpdateEventEventAccessMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateEventEventAccessMutation,
  UpdateEventEventAccessMutationVariables
>;
export const UpdateEventOrderDocument = gql`
  mutation updateEventOrder($id: Int!, $data: EventOrderUpdateInput!) {
    updateEventOrder(id: $id, data: $data) {
      ...eventOrder
    }
  }
  ${EventOrderFragmentDoc}
`;
export type UpdateEventOrderMutationFn = ApolloReactCommon.MutationFunction<
  UpdateEventOrderMutation,
  UpdateEventOrderMutationVariables
>;
export type UpdateEventOrderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateEventOrderMutation, UpdateEventOrderMutationVariables>,
  'mutation'
>;

export const UpdateEventOrderComponent = (props: UpdateEventOrderComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateEventOrderMutation, UpdateEventOrderMutationVariables>
    mutation={UpdateEventOrderDocument}
    {...props}
  />
);

export type UpdateEventOrderProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateEventOrderMutation,
  UpdateEventOrderMutationVariables
> &
  TChildProps;
export function withUpdateEventOrder<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateEventOrderMutation,
    UpdateEventOrderMutationVariables,
    UpdateEventOrderProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateEventOrderMutation,
    UpdateEventOrderMutationVariables,
    UpdateEventOrderProps<TChildProps>
  >(UpdateEventOrderDocument, {
    alias: 'updateEventOrder',
    ...operationOptions,
  });
}

/**
 * __useUpdateEventOrderMutation__
 *
 * To run a mutation, you first call `useUpdateEventOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventOrderMutation, { data, loading, error }] = useUpdateEventOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEventOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEventOrderMutation, UpdateEventOrderMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateEventOrderMutation, UpdateEventOrderMutationVariables>(
    UpdateEventOrderDocument,
    baseOptions
  );
}
export type UpdateEventOrderMutationHookResult = ReturnType<typeof useUpdateEventOrderMutation>;
export type UpdateEventOrderMutationResult = ApolloReactCommon.MutationResult<UpdateEventOrderMutation>;
export type UpdateEventOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateEventOrderMutation,
  UpdateEventOrderMutationVariables
>;
export const UpdateEventPlaceDocument = gql`
  mutation updateEventPlace($id: Int!, $data: EventPlaceUpdateInput!) {
    updateEventPlace(id: $id, data: $data) {
      ...eventPlace
    }
  }
  ${EventPlaceFragmentDoc}
`;
export type UpdateEventPlaceMutationFn = ApolloReactCommon.MutationFunction<
  UpdateEventPlaceMutation,
  UpdateEventPlaceMutationVariables
>;
export type UpdateEventPlaceComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateEventPlaceMutation, UpdateEventPlaceMutationVariables>,
  'mutation'
>;

export const UpdateEventPlaceComponent = (props: UpdateEventPlaceComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateEventPlaceMutation, UpdateEventPlaceMutationVariables>
    mutation={UpdateEventPlaceDocument}
    {...props}
  />
);

export type UpdateEventPlaceProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateEventPlaceMutation,
  UpdateEventPlaceMutationVariables
> &
  TChildProps;
export function withUpdateEventPlace<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateEventPlaceMutation,
    UpdateEventPlaceMutationVariables,
    UpdateEventPlaceProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateEventPlaceMutation,
    UpdateEventPlaceMutationVariables,
    UpdateEventPlaceProps<TChildProps>
  >(UpdateEventPlaceDocument, {
    alias: 'updateEventPlace',
    ...operationOptions,
  });
}

/**
 * __useUpdateEventPlaceMutation__
 *
 * To run a mutation, you first call `useUpdateEventPlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventPlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventPlaceMutation, { data, loading, error }] = useUpdateEventPlaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEventPlaceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEventPlaceMutation, UpdateEventPlaceMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateEventPlaceMutation, UpdateEventPlaceMutationVariables>(
    UpdateEventPlaceDocument,
    baseOptions
  );
}
export type UpdateEventPlaceMutationHookResult = ReturnType<typeof useUpdateEventPlaceMutation>;
export type UpdateEventPlaceMutationResult = ApolloReactCommon.MutationResult<UpdateEventPlaceMutation>;
export type UpdateEventPlaceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateEventPlaceMutation,
  UpdateEventPlaceMutationVariables
>;
export const UpdateEventPlaceSectorDocument = gql`
  mutation updateEventPlaceSector($id: Int!, $data: EventPlaceSectorUpdateInput!) {
    updateEventPlaceSector(id: $id, data: $data) {
      ...eventPlaceSector
    }
  }
  ${EventPlaceSectorFragmentDoc}
`;
export type UpdateEventPlaceSectorMutationFn = ApolloReactCommon.MutationFunction<
  UpdateEventPlaceSectorMutation,
  UpdateEventPlaceSectorMutationVariables
>;
export type UpdateEventPlaceSectorComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateEventPlaceSectorMutation,
    UpdateEventPlaceSectorMutationVariables
  >,
  'mutation'
>;

export const UpdateEventPlaceSectorComponent = (props: UpdateEventPlaceSectorComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateEventPlaceSectorMutation, UpdateEventPlaceSectorMutationVariables>
    mutation={UpdateEventPlaceSectorDocument}
    {...props}
  />
);

export type UpdateEventPlaceSectorProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateEventPlaceSectorMutation,
  UpdateEventPlaceSectorMutationVariables
> &
  TChildProps;
export function withUpdateEventPlaceSector<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateEventPlaceSectorMutation,
    UpdateEventPlaceSectorMutationVariables,
    UpdateEventPlaceSectorProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateEventPlaceSectorMutation,
    UpdateEventPlaceSectorMutationVariables,
    UpdateEventPlaceSectorProps<TChildProps>
  >(UpdateEventPlaceSectorDocument, {
    alias: 'updateEventPlaceSector',
    ...operationOptions,
  });
}

/**
 * __useUpdateEventPlaceSectorMutation__
 *
 * To run a mutation, you first call `useUpdateEventPlaceSectorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventPlaceSectorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventPlaceSectorMutation, { data, loading, error }] = useUpdateEventPlaceSectorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEventPlaceSectorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateEventPlaceSectorMutation,
    UpdateEventPlaceSectorMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateEventPlaceSectorMutation, UpdateEventPlaceSectorMutationVariables>(
    UpdateEventPlaceSectorDocument,
    baseOptions
  );
}
export type UpdateEventPlaceSectorMutationHookResult = ReturnType<typeof useUpdateEventPlaceSectorMutation>;
export type UpdateEventPlaceSectorMutationResult = ApolloReactCommon.MutationResult<UpdateEventPlaceSectorMutation>;
export type UpdateEventPlaceSectorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateEventPlaceSectorMutation,
  UpdateEventPlaceSectorMutationVariables
>;
export const UpdateEventTypeDocument = gql`
  mutation updateEventType($id: Int!, $data: EventTypeUpdateInput!) {
    updateEventType(id: $id, data: $data) {
      ...eventType
    }
  }
  ${EventTypeFragmentDoc}
`;
export type UpdateEventTypeMutationFn = ApolloReactCommon.MutationFunction<
  UpdateEventTypeMutation,
  UpdateEventTypeMutationVariables
>;
export type UpdateEventTypeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateEventTypeMutation, UpdateEventTypeMutationVariables>,
  'mutation'
>;

export const UpdateEventTypeComponent = (props: UpdateEventTypeComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateEventTypeMutation, UpdateEventTypeMutationVariables>
    mutation={UpdateEventTypeDocument}
    {...props}
  />
);

export type UpdateEventTypeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateEventTypeMutation,
  UpdateEventTypeMutationVariables
> &
  TChildProps;
export function withUpdateEventType<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateEventTypeMutation,
    UpdateEventTypeMutationVariables,
    UpdateEventTypeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateEventTypeMutation,
    UpdateEventTypeMutationVariables,
    UpdateEventTypeProps<TChildProps>
  >(UpdateEventTypeDocument, {
    alias: 'updateEventType',
    ...operationOptions,
  });
}

/**
 * __useUpdateEventTypeMutation__
 *
 * To run a mutation, you first call `useUpdateEventTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventTypeMutation, { data, loading, error }] = useUpdateEventTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEventTypeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEventTypeMutation, UpdateEventTypeMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateEventTypeMutation, UpdateEventTypeMutationVariables>(
    UpdateEventTypeDocument,
    baseOptions
  );
}
export type UpdateEventTypeMutationHookResult = ReturnType<typeof useUpdateEventTypeMutation>;
export type UpdateEventTypeMutationResult = ApolloReactCommon.MutationResult<UpdateEventTypeMutation>;
export type UpdateEventTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateEventTypeMutation,
  UpdateEventTypeMutationVariables
>;
export const UpdateLoyaltyPartnerDocument = gql`
  mutation updateLoyaltyPartner($id: Int!, $data: LoyaltyPartnerUpdateInput!) {
    updateLoyaltyPartner(id: $id, data: $data) {
      ...loyaltyPartner
    }
  }
  ${LoyaltyPartnerFragmentDoc}
`;
export type UpdateLoyaltyPartnerMutationFn = ApolloReactCommon.MutationFunction<
  UpdateLoyaltyPartnerMutation,
  UpdateLoyaltyPartnerMutationVariables
>;
export type UpdateLoyaltyPartnerComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateLoyaltyPartnerMutation, UpdateLoyaltyPartnerMutationVariables>,
  'mutation'
>;

export const UpdateLoyaltyPartnerComponent = (props: UpdateLoyaltyPartnerComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateLoyaltyPartnerMutation, UpdateLoyaltyPartnerMutationVariables>
    mutation={UpdateLoyaltyPartnerDocument}
    {...props}
  />
);

export type UpdateLoyaltyPartnerProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateLoyaltyPartnerMutation,
  UpdateLoyaltyPartnerMutationVariables
> &
  TChildProps;
export function withUpdateLoyaltyPartner<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateLoyaltyPartnerMutation,
    UpdateLoyaltyPartnerMutationVariables,
    UpdateLoyaltyPartnerProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateLoyaltyPartnerMutation,
    UpdateLoyaltyPartnerMutationVariables,
    UpdateLoyaltyPartnerProps<TChildProps>
  >(UpdateLoyaltyPartnerDocument, {
    alias: 'updateLoyaltyPartner',
    ...operationOptions,
  });
}

/**
 * __useUpdateLoyaltyPartnerMutation__
 *
 * To run a mutation, you first call `useUpdateLoyaltyPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoyaltyPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoyaltyPartnerMutation, { data, loading, error }] = useUpdateLoyaltyPartnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLoyaltyPartnerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateLoyaltyPartnerMutation,
    UpdateLoyaltyPartnerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateLoyaltyPartnerMutation, UpdateLoyaltyPartnerMutationVariables>(
    UpdateLoyaltyPartnerDocument,
    baseOptions
  );
}
export type UpdateLoyaltyPartnerMutationHookResult = ReturnType<typeof useUpdateLoyaltyPartnerMutation>;
export type UpdateLoyaltyPartnerMutationResult = ApolloReactCommon.MutationResult<UpdateLoyaltyPartnerMutation>;
export type UpdateLoyaltyPartnerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateLoyaltyPartnerMutation,
  UpdateLoyaltyPartnerMutationVariables
>;
export const UpdateLoyaltyPromoDocument = gql`
  mutation updateLoyaltyPromo($id: Int!, $data: LoyaltyPromoUpdateInput!) {
    updateLoyaltyPromo(id: $id, data: $data) {
      ...loyaltyPromo
    }
  }
  ${LoyaltyPromoFragmentDoc}
`;
export type UpdateLoyaltyPromoMutationFn = ApolloReactCommon.MutationFunction<
  UpdateLoyaltyPromoMutation,
  UpdateLoyaltyPromoMutationVariables
>;
export type UpdateLoyaltyPromoComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateLoyaltyPromoMutation, UpdateLoyaltyPromoMutationVariables>,
  'mutation'
>;

export const UpdateLoyaltyPromoComponent = (props: UpdateLoyaltyPromoComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateLoyaltyPromoMutation, UpdateLoyaltyPromoMutationVariables>
    mutation={UpdateLoyaltyPromoDocument}
    {...props}
  />
);

export type UpdateLoyaltyPromoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateLoyaltyPromoMutation,
  UpdateLoyaltyPromoMutationVariables
> &
  TChildProps;
export function withUpdateLoyaltyPromo<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateLoyaltyPromoMutation,
    UpdateLoyaltyPromoMutationVariables,
    UpdateLoyaltyPromoProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateLoyaltyPromoMutation,
    UpdateLoyaltyPromoMutationVariables,
    UpdateLoyaltyPromoProps<TChildProps>
  >(UpdateLoyaltyPromoDocument, {
    alias: 'updateLoyaltyPromo',
    ...operationOptions,
  });
}

/**
 * __useUpdateLoyaltyPromoMutation__
 *
 * To run a mutation, you first call `useUpdateLoyaltyPromoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoyaltyPromoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoyaltyPromoMutation, { data, loading, error }] = useUpdateLoyaltyPromoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLoyaltyPromoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLoyaltyPromoMutation, UpdateLoyaltyPromoMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateLoyaltyPromoMutation, UpdateLoyaltyPromoMutationVariables>(
    UpdateLoyaltyPromoDocument,
    baseOptions
  );
}
export type UpdateLoyaltyPromoMutationHookResult = ReturnType<typeof useUpdateLoyaltyPromoMutation>;
export type UpdateLoyaltyPromoMutationResult = ApolloReactCommon.MutationResult<UpdateLoyaltyPromoMutation>;
export type UpdateLoyaltyPromoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateLoyaltyPromoMutation,
  UpdateLoyaltyPromoMutationVariables
>;
export const UpdateLoyaltyPromoCodeDocument = gql`
  mutation updateLoyaltyPromoCode($id: Int!, $data: LoyaltyPromoCodeUpdateInput!) {
    updateLoyaltyPromoCode(id: $id, data: $data) {
      ...loyaltyPromoCode
    }
  }
  ${LoyaltyPromoCodeFragmentDoc}
`;
export type UpdateLoyaltyPromoCodeMutationFn = ApolloReactCommon.MutationFunction<
  UpdateLoyaltyPromoCodeMutation,
  UpdateLoyaltyPromoCodeMutationVariables
>;
export type UpdateLoyaltyPromoCodeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateLoyaltyPromoCodeMutation,
    UpdateLoyaltyPromoCodeMutationVariables
  >,
  'mutation'
>;

export const UpdateLoyaltyPromoCodeComponent = (props: UpdateLoyaltyPromoCodeComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateLoyaltyPromoCodeMutation, UpdateLoyaltyPromoCodeMutationVariables>
    mutation={UpdateLoyaltyPromoCodeDocument}
    {...props}
  />
);

export type UpdateLoyaltyPromoCodeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateLoyaltyPromoCodeMutation,
  UpdateLoyaltyPromoCodeMutationVariables
> &
  TChildProps;
export function withUpdateLoyaltyPromoCode<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateLoyaltyPromoCodeMutation,
    UpdateLoyaltyPromoCodeMutationVariables,
    UpdateLoyaltyPromoCodeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateLoyaltyPromoCodeMutation,
    UpdateLoyaltyPromoCodeMutationVariables,
    UpdateLoyaltyPromoCodeProps<TChildProps>
  >(UpdateLoyaltyPromoCodeDocument, {
    alias: 'updateLoyaltyPromoCode',
    ...operationOptions,
  });
}

/**
 * __useUpdateLoyaltyPromoCodeMutation__
 *
 * To run a mutation, you first call `useUpdateLoyaltyPromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoyaltyPromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoyaltyPromoCodeMutation, { data, loading, error }] = useUpdateLoyaltyPromoCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLoyaltyPromoCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateLoyaltyPromoCodeMutation,
    UpdateLoyaltyPromoCodeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateLoyaltyPromoCodeMutation, UpdateLoyaltyPromoCodeMutationVariables>(
    UpdateLoyaltyPromoCodeDocument,
    baseOptions
  );
}
export type UpdateLoyaltyPromoCodeMutationHookResult = ReturnType<typeof useUpdateLoyaltyPromoCodeMutation>;
export type UpdateLoyaltyPromoCodeMutationResult = ApolloReactCommon.MutationResult<UpdateLoyaltyPromoCodeMutation>;
export type UpdateLoyaltyPromoCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateLoyaltyPromoCodeMutation,
  UpdateLoyaltyPromoCodeMutationVariables
>;
export const UpdateMessengerMailingDocument = gql`
  mutation updateMessengerMailing($id: Int!, $data: MessengerMailingUpdateInput!) {
    updateMessengerMailing(id: $id, data: $data) {
      ...messengerMailing
    }
  }
  ${MessengerMailingFragmentDoc}
`;
export type UpdateMessengerMailingMutationFn = ApolloReactCommon.MutationFunction<
  UpdateMessengerMailingMutation,
  UpdateMessengerMailingMutationVariables
>;
export type UpdateMessengerMailingComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateMessengerMailingMutation,
    UpdateMessengerMailingMutationVariables
  >,
  'mutation'
>;

export const UpdateMessengerMailingComponent = (props: UpdateMessengerMailingComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateMessengerMailingMutation, UpdateMessengerMailingMutationVariables>
    mutation={UpdateMessengerMailingDocument}
    {...props}
  />
);

export type UpdateMessengerMailingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateMessengerMailingMutation,
  UpdateMessengerMailingMutationVariables
> &
  TChildProps;
export function withUpdateMessengerMailing<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateMessengerMailingMutation,
    UpdateMessengerMailingMutationVariables,
    UpdateMessengerMailingProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateMessengerMailingMutation,
    UpdateMessengerMailingMutationVariables,
    UpdateMessengerMailingProps<TChildProps>
  >(UpdateMessengerMailingDocument, {
    alias: 'updateMessengerMailing',
    ...operationOptions,
  });
}

/**
 * __useUpdateMessengerMailingMutation__
 *
 * To run a mutation, you first call `useUpdateMessengerMailingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessengerMailingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessengerMailingMutation, { data, loading, error }] = useUpdateMessengerMailingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMessengerMailingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMessengerMailingMutation,
    UpdateMessengerMailingMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateMessengerMailingMutation, UpdateMessengerMailingMutationVariables>(
    UpdateMessengerMailingDocument,
    baseOptions
  );
}
export type UpdateMessengerMailingMutationHookResult = ReturnType<typeof useUpdateMessengerMailingMutation>;
export type UpdateMessengerMailingMutationResult = ApolloReactCommon.MutationResult<UpdateMessengerMailingMutation>;
export type UpdateMessengerMailingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateMessengerMailingMutation,
  UpdateMessengerMailingMutationVariables
>;
export const UpdateMessengerQueueNotificationDocument = gql`
  mutation updateMessengerQueueNotification($id: String!, $data: MessengerQueueNotificationUpdateInput!) {
    updateMessengerQueueNotification(id: $id, data: $data) {
      ...messengerQueueNotification
    }
  }
  ${MessengerQueueNotificationFragmentDoc}
`;
export type UpdateMessengerQueueNotificationMutationFn = ApolloReactCommon.MutationFunction<
  UpdateMessengerQueueNotificationMutation,
  UpdateMessengerQueueNotificationMutationVariables
>;
export type UpdateMessengerQueueNotificationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateMessengerQueueNotificationMutation,
    UpdateMessengerQueueNotificationMutationVariables
  >,
  'mutation'
>;

export const UpdateMessengerQueueNotificationComponent = (props: UpdateMessengerQueueNotificationComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateMessengerQueueNotificationMutation,
    UpdateMessengerQueueNotificationMutationVariables
  >
    mutation={UpdateMessengerQueueNotificationDocument}
    {...props}
  />
);

export type UpdateMessengerQueueNotificationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateMessengerQueueNotificationMutation,
  UpdateMessengerQueueNotificationMutationVariables
> &
  TChildProps;
export function withUpdateMessengerQueueNotification<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateMessengerQueueNotificationMutation,
    UpdateMessengerQueueNotificationMutationVariables,
    UpdateMessengerQueueNotificationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateMessengerQueueNotificationMutation,
    UpdateMessengerQueueNotificationMutationVariables,
    UpdateMessengerQueueNotificationProps<TChildProps>
  >(UpdateMessengerQueueNotificationDocument, {
    alias: 'updateMessengerQueueNotification',
    ...operationOptions,
  });
}

/**
 * __useUpdateMessengerQueueNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateMessengerQueueNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessengerQueueNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessengerQueueNotificationMutation, { data, loading, error }] = useUpdateMessengerQueueNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMessengerQueueNotificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMessengerQueueNotificationMutation,
    UpdateMessengerQueueNotificationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateMessengerQueueNotificationMutation,
    UpdateMessengerQueueNotificationMutationVariables
  >(UpdateMessengerQueueNotificationDocument, baseOptions);
}
export type UpdateMessengerQueueNotificationMutationHookResult = ReturnType<
  typeof useUpdateMessengerQueueNotificationMutation
>;
export type UpdateMessengerQueueNotificationMutationResult = ApolloReactCommon.MutationResult<
  UpdateMessengerQueueNotificationMutation
>;
export type UpdateMessengerQueueNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateMessengerQueueNotificationMutation,
  UpdateMessengerQueueNotificationMutationVariables
>;
export const UpdateMessengerQueueTaskDocument = gql`
  mutation updateMessengerQueueTask($id: Int!, $data: MessengerQueueTaskUpdateInput!) {
    updateMessengerQueueTask(id: $id, data: $data) {
      ...messengerQueueTask
    }
  }
  ${MessengerQueueTaskFragmentDoc}
`;
export type UpdateMessengerQueueTaskMutationFn = ApolloReactCommon.MutationFunction<
  UpdateMessengerQueueTaskMutation,
  UpdateMessengerQueueTaskMutationVariables
>;
export type UpdateMessengerQueueTaskComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateMessengerQueueTaskMutation,
    UpdateMessengerQueueTaskMutationVariables
  >,
  'mutation'
>;

export const UpdateMessengerQueueTaskComponent = (props: UpdateMessengerQueueTaskComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateMessengerQueueTaskMutation, UpdateMessengerQueueTaskMutationVariables>
    mutation={UpdateMessengerQueueTaskDocument}
    {...props}
  />
);

export type UpdateMessengerQueueTaskProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateMessengerQueueTaskMutation,
  UpdateMessengerQueueTaskMutationVariables
> &
  TChildProps;
export function withUpdateMessengerQueueTask<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateMessengerQueueTaskMutation,
    UpdateMessengerQueueTaskMutationVariables,
    UpdateMessengerQueueTaskProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateMessengerQueueTaskMutation,
    UpdateMessengerQueueTaskMutationVariables,
    UpdateMessengerQueueTaskProps<TChildProps>
  >(UpdateMessengerQueueTaskDocument, {
    alias: 'updateMessengerQueueTask',
    ...operationOptions,
  });
}

/**
 * __useUpdateMessengerQueueTaskMutation__
 *
 * To run a mutation, you first call `useUpdateMessengerQueueTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessengerQueueTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessengerQueueTaskMutation, { data, loading, error }] = useUpdateMessengerQueueTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMessengerQueueTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMessengerQueueTaskMutation,
    UpdateMessengerQueueTaskMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateMessengerQueueTaskMutation, UpdateMessengerQueueTaskMutationVariables>(
    UpdateMessengerQueueTaskDocument,
    baseOptions
  );
}
export type UpdateMessengerQueueTaskMutationHookResult = ReturnType<typeof useUpdateMessengerQueueTaskMutation>;
export type UpdateMessengerQueueTaskMutationResult = ApolloReactCommon.MutationResult<UpdateMessengerQueueTaskMutation>;
export type UpdateMessengerQueueTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateMessengerQueueTaskMutation,
  UpdateMessengerQueueTaskMutationVariables
>;
export const UpdateMessengerSettingDocument = gql`
  mutation updateMessengerSetting($data: MessengerSettingUpdateInput!) {
    updateMessengerSetting(data: $data) {
      ...messengerSetting
    }
  }
  ${MessengerSettingFragmentDoc}
`;
export type UpdateMessengerSettingMutationFn = ApolloReactCommon.MutationFunction<
  UpdateMessengerSettingMutation,
  UpdateMessengerSettingMutationVariables
>;
export type UpdateMessengerSettingComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateMessengerSettingMutation,
    UpdateMessengerSettingMutationVariables
  >,
  'mutation'
>;

export const UpdateMessengerSettingComponent = (props: UpdateMessengerSettingComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateMessengerSettingMutation, UpdateMessengerSettingMutationVariables>
    mutation={UpdateMessengerSettingDocument}
    {...props}
  />
);

export type UpdateMessengerSettingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateMessengerSettingMutation,
  UpdateMessengerSettingMutationVariables
> &
  TChildProps;
export function withUpdateMessengerSetting<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateMessengerSettingMutation,
    UpdateMessengerSettingMutationVariables,
    UpdateMessengerSettingProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateMessengerSettingMutation,
    UpdateMessengerSettingMutationVariables,
    UpdateMessengerSettingProps<TChildProps>
  >(UpdateMessengerSettingDocument, {
    alias: 'updateMessengerSetting',
    ...operationOptions,
  });
}

/**
 * __useUpdateMessengerSettingMutation__
 *
 * To run a mutation, you first call `useUpdateMessengerSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessengerSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessengerSettingMutation, { data, loading, error }] = useUpdateMessengerSettingMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMessengerSettingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMessengerSettingMutation,
    UpdateMessengerSettingMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateMessengerSettingMutation, UpdateMessengerSettingMutationVariables>(
    UpdateMessengerSettingDocument,
    baseOptions
  );
}
export type UpdateMessengerSettingMutationHookResult = ReturnType<typeof useUpdateMessengerSettingMutation>;
export type UpdateMessengerSettingMutationResult = ApolloReactCommon.MutationResult<UpdateMessengerSettingMutation>;
export type UpdateMessengerSettingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateMessengerSettingMutation,
  UpdateMessengerSettingMutationVariables
>;
export const UpdateMessengerTemplateDocument = gql`
  mutation updateMessengerTemplate($id: Int!, $data: MessengerTemplateUpdateInput!) {
    updateMessengerTemplate(id: $id, data: $data) {
      ...messengerTemplate
    }
  }
  ${MessengerTemplateFragmentDoc}
`;
export type UpdateMessengerTemplateMutationFn = ApolloReactCommon.MutationFunction<
  UpdateMessengerTemplateMutation,
  UpdateMessengerTemplateMutationVariables
>;
export type UpdateMessengerTemplateComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateMessengerTemplateMutation,
    UpdateMessengerTemplateMutationVariables
  >,
  'mutation'
>;

export const UpdateMessengerTemplateComponent = (props: UpdateMessengerTemplateComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateMessengerTemplateMutation, UpdateMessengerTemplateMutationVariables>
    mutation={UpdateMessengerTemplateDocument}
    {...props}
  />
);

export type UpdateMessengerTemplateProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateMessengerTemplateMutation,
  UpdateMessengerTemplateMutationVariables
> &
  TChildProps;
export function withUpdateMessengerTemplate<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateMessengerTemplateMutation,
    UpdateMessengerTemplateMutationVariables,
    UpdateMessengerTemplateProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateMessengerTemplateMutation,
    UpdateMessengerTemplateMutationVariables,
    UpdateMessengerTemplateProps<TChildProps>
  >(UpdateMessengerTemplateDocument, {
    alias: 'updateMessengerTemplate',
    ...operationOptions,
  });
}

/**
 * __useUpdateMessengerTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateMessengerTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessengerTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessengerTemplateMutation, { data, loading, error }] = useUpdateMessengerTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMessengerTemplateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMessengerTemplateMutation,
    UpdateMessengerTemplateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateMessengerTemplateMutation, UpdateMessengerTemplateMutationVariables>(
    UpdateMessengerTemplateDocument,
    baseOptions
  );
}
export type UpdateMessengerTemplateMutationHookResult = ReturnType<typeof useUpdateMessengerTemplateMutation>;
export type UpdateMessengerTemplateMutationResult = ApolloReactCommon.MutationResult<UpdateMessengerTemplateMutation>;
export type UpdateMessengerTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateMessengerTemplateMutation,
  UpdateMessengerTemplateMutationVariables
>;
export const UpdateMessengerTransportDocument = gql`
  mutation updateMessengerTransport($id: Int!, $data: MessengerTransportUpdateInput!) {
    updateMessengerTransport(id: $id, data: $data) {
      ...messengerTransport
    }
  }
  ${MessengerTransportFragmentDoc}
`;
export type UpdateMessengerTransportMutationFn = ApolloReactCommon.MutationFunction<
  UpdateMessengerTransportMutation,
  UpdateMessengerTransportMutationVariables
>;
export type UpdateMessengerTransportComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateMessengerTransportMutation,
    UpdateMessengerTransportMutationVariables
  >,
  'mutation'
>;

export const UpdateMessengerTransportComponent = (props: UpdateMessengerTransportComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateMessengerTransportMutation, UpdateMessengerTransportMutationVariables>
    mutation={UpdateMessengerTransportDocument}
    {...props}
  />
);

export type UpdateMessengerTransportProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateMessengerTransportMutation,
  UpdateMessengerTransportMutationVariables
> &
  TChildProps;
export function withUpdateMessengerTransport<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateMessengerTransportMutation,
    UpdateMessengerTransportMutationVariables,
    UpdateMessengerTransportProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateMessengerTransportMutation,
    UpdateMessengerTransportMutationVariables,
    UpdateMessengerTransportProps<TChildProps>
  >(UpdateMessengerTransportDocument, {
    alias: 'updateMessengerTransport',
    ...operationOptions,
  });
}

/**
 * __useUpdateMessengerTransportMutation__
 *
 * To run a mutation, you first call `useUpdateMessengerTransportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessengerTransportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessengerTransportMutation, { data, loading, error }] = useUpdateMessengerTransportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMessengerTransportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMessengerTransportMutation,
    UpdateMessengerTransportMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateMessengerTransportMutation, UpdateMessengerTransportMutationVariables>(
    UpdateMessengerTransportDocument,
    baseOptions
  );
}
export type UpdateMessengerTransportMutationHookResult = ReturnType<typeof useUpdateMessengerTransportMutation>;
export type UpdateMessengerTransportMutationResult = ApolloReactCommon.MutationResult<UpdateMessengerTransportMutation>;
export type UpdateMessengerTransportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateMessengerTransportMutation,
  UpdateMessengerTransportMutationVariables
>;
export const UpdatePaymentAccountDocument = gql`
  mutation updatePaymentAccount($id: Int!, $data: PaymentAccountUpdateInput!) {
    updatePaymentAccount(id: $id, data: $data) {
      ...paymentAccount
    }
  }
  ${PaymentAccountFragmentDoc}
`;
export type UpdatePaymentAccountMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePaymentAccountMutation,
  UpdatePaymentAccountMutationVariables
>;
export type UpdatePaymentAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdatePaymentAccountMutation, UpdatePaymentAccountMutationVariables>,
  'mutation'
>;

export const UpdatePaymentAccountComponent = (props: UpdatePaymentAccountComponentProps) => (
  <ApolloReactComponents.Mutation<UpdatePaymentAccountMutation, UpdatePaymentAccountMutationVariables>
    mutation={UpdatePaymentAccountDocument}
    {...props}
  />
);

export type UpdatePaymentAccountProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdatePaymentAccountMutation,
  UpdatePaymentAccountMutationVariables
> &
  TChildProps;
export function withUpdatePaymentAccount<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdatePaymentAccountMutation,
    UpdatePaymentAccountMutationVariables,
    UpdatePaymentAccountProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdatePaymentAccountMutation,
    UpdatePaymentAccountMutationVariables,
    UpdatePaymentAccountProps<TChildProps>
  >(UpdatePaymentAccountDocument, {
    alias: 'updatePaymentAccount',
    ...operationOptions,
  });
}

/**
 * __useUpdatePaymentAccountMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentAccountMutation, { data, loading, error }] = useUpdatePaymentAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePaymentAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePaymentAccountMutation,
    UpdatePaymentAccountMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdatePaymentAccountMutation, UpdatePaymentAccountMutationVariables>(
    UpdatePaymentAccountDocument,
    baseOptions
  );
}
export type UpdatePaymentAccountMutationHookResult = ReturnType<typeof useUpdatePaymentAccountMutation>;
export type UpdatePaymentAccountMutationResult = ApolloReactCommon.MutationResult<UpdatePaymentAccountMutation>;
export type UpdatePaymentAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePaymentAccountMutation,
  UpdatePaymentAccountMutationVariables
>;
export const UpdatePaymentCurrencyDocument = gql`
  mutation updatePaymentCurrency($id: Int!, $data: PaymentCurrencyUpdateInput!) {
    updatePaymentCurrency(id: $id, data: $data) {
      ...paymentCurrency
    }
  }
  ${PaymentCurrencyFragmentDoc}
`;
export type UpdatePaymentCurrencyMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePaymentCurrencyMutation,
  UpdatePaymentCurrencyMutationVariables
>;
export type UpdatePaymentCurrencyComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdatePaymentCurrencyMutation, UpdatePaymentCurrencyMutationVariables>,
  'mutation'
>;

export const UpdatePaymentCurrencyComponent = (props: UpdatePaymentCurrencyComponentProps) => (
  <ApolloReactComponents.Mutation<UpdatePaymentCurrencyMutation, UpdatePaymentCurrencyMutationVariables>
    mutation={UpdatePaymentCurrencyDocument}
    {...props}
  />
);

export type UpdatePaymentCurrencyProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdatePaymentCurrencyMutation,
  UpdatePaymentCurrencyMutationVariables
> &
  TChildProps;
export function withUpdatePaymentCurrency<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdatePaymentCurrencyMutation,
    UpdatePaymentCurrencyMutationVariables,
    UpdatePaymentCurrencyProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdatePaymentCurrencyMutation,
    UpdatePaymentCurrencyMutationVariables,
    UpdatePaymentCurrencyProps<TChildProps>
  >(UpdatePaymentCurrencyDocument, {
    alias: 'updatePaymentCurrency',
    ...operationOptions,
  });
}

/**
 * __useUpdatePaymentCurrencyMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentCurrencyMutation, { data, loading, error }] = useUpdatePaymentCurrencyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePaymentCurrencyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePaymentCurrencyMutation,
    UpdatePaymentCurrencyMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdatePaymentCurrencyMutation, UpdatePaymentCurrencyMutationVariables>(
    UpdatePaymentCurrencyDocument,
    baseOptions
  );
}
export type UpdatePaymentCurrencyMutationHookResult = ReturnType<typeof useUpdatePaymentCurrencyMutation>;
export type UpdatePaymentCurrencyMutationResult = ApolloReactCommon.MutationResult<UpdatePaymentCurrencyMutation>;
export type UpdatePaymentCurrencyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePaymentCurrencyMutation,
  UpdatePaymentCurrencyMutationVariables
>;
export const UpdatePaymentProviderDocument = gql`
  mutation updatePaymentProvider($id: Int!, $data: PaymentProviderUpdateInput!) {
    updatePaymentProvider(id: $id, data: $data) {
      ...paymentProvider
    }
  }
  ${PaymentProviderFragmentDoc}
`;
export type UpdatePaymentProviderMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePaymentProviderMutation,
  UpdatePaymentProviderMutationVariables
>;
export type UpdatePaymentProviderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdatePaymentProviderMutation, UpdatePaymentProviderMutationVariables>,
  'mutation'
>;

export const UpdatePaymentProviderComponent = (props: UpdatePaymentProviderComponentProps) => (
  <ApolloReactComponents.Mutation<UpdatePaymentProviderMutation, UpdatePaymentProviderMutationVariables>
    mutation={UpdatePaymentProviderDocument}
    {...props}
  />
);

export type UpdatePaymentProviderProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdatePaymentProviderMutation,
  UpdatePaymentProviderMutationVariables
> &
  TChildProps;
export function withUpdatePaymentProvider<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdatePaymentProviderMutation,
    UpdatePaymentProviderMutationVariables,
    UpdatePaymentProviderProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdatePaymentProviderMutation,
    UpdatePaymentProviderMutationVariables,
    UpdatePaymentProviderProps<TChildProps>
  >(UpdatePaymentProviderDocument, {
    alias: 'updatePaymentProvider',
    ...operationOptions,
  });
}

/**
 * __useUpdatePaymentProviderMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentProviderMutation, { data, loading, error }] = useUpdatePaymentProviderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePaymentProviderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePaymentProviderMutation,
    UpdatePaymentProviderMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdatePaymentProviderMutation, UpdatePaymentProviderMutationVariables>(
    UpdatePaymentProviderDocument,
    baseOptions
  );
}
export type UpdatePaymentProviderMutationHookResult = ReturnType<typeof useUpdatePaymentProviderMutation>;
export type UpdatePaymentProviderMutationResult = ApolloReactCommon.MutationResult<UpdatePaymentProviderMutation>;
export type UpdatePaymentProviderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePaymentProviderMutation,
  UpdatePaymentProviderMutationVariables
>;
export const UpdatePaymentTransactionDocument = gql`
  mutation updatePaymentTransaction($id: Int!, $data: PaymentTransactionUpdateInput!) {
    updatePaymentTransaction(id: $id, data: $data) {
      ...paymentTransaction
    }
  }
  ${PaymentTransactionFragmentDoc}
`;
export type UpdatePaymentTransactionMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePaymentTransactionMutation,
  UpdatePaymentTransactionMutationVariables
>;
export type UpdatePaymentTransactionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdatePaymentTransactionMutation,
    UpdatePaymentTransactionMutationVariables
  >,
  'mutation'
>;

export const UpdatePaymentTransactionComponent = (props: UpdatePaymentTransactionComponentProps) => (
  <ApolloReactComponents.Mutation<UpdatePaymentTransactionMutation, UpdatePaymentTransactionMutationVariables>
    mutation={UpdatePaymentTransactionDocument}
    {...props}
  />
);

export type UpdatePaymentTransactionProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdatePaymentTransactionMutation,
  UpdatePaymentTransactionMutationVariables
> &
  TChildProps;
export function withUpdatePaymentTransaction<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdatePaymentTransactionMutation,
    UpdatePaymentTransactionMutationVariables,
    UpdatePaymentTransactionProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdatePaymentTransactionMutation,
    UpdatePaymentTransactionMutationVariables,
    UpdatePaymentTransactionProps<TChildProps>
  >(UpdatePaymentTransactionDocument, {
    alias: 'updatePaymentTransaction',
    ...operationOptions,
  });
}

/**
 * __useUpdatePaymentTransactionMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentTransactionMutation, { data, loading, error }] = useUpdatePaymentTransactionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePaymentTransactionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePaymentTransactionMutation,
    UpdatePaymentTransactionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdatePaymentTransactionMutation, UpdatePaymentTransactionMutationVariables>(
    UpdatePaymentTransactionDocument,
    baseOptions
  );
}
export type UpdatePaymentTransactionMutationHookResult = ReturnType<typeof useUpdatePaymentTransactionMutation>;
export type UpdatePaymentTransactionMutationResult = ApolloReactCommon.MutationResult<UpdatePaymentTransactionMutation>;
export type UpdatePaymentTransactionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePaymentTransactionMutation,
  UpdatePaymentTransactionMutationVariables
>;
export const UpdateSystemTaskDocument = gql`
  mutation updateSystemTask($id: UUID!, $data: SystemTaskUpdateInput!) {
    updateSystemTask(id: $id, data: $data) {
      ...systemTask
    }
  }
  ${SystemTaskFragmentDoc}
`;
export type UpdateSystemTaskMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSystemTaskMutation,
  UpdateSystemTaskMutationVariables
>;
export type UpdateSystemTaskComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateSystemTaskMutation, UpdateSystemTaskMutationVariables>,
  'mutation'
>;

export const UpdateSystemTaskComponent = (props: UpdateSystemTaskComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateSystemTaskMutation, UpdateSystemTaskMutationVariables>
    mutation={UpdateSystemTaskDocument}
    {...props}
  />
);

export type UpdateSystemTaskProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateSystemTaskMutation,
  UpdateSystemTaskMutationVariables
> &
  TChildProps;
export function withUpdateSystemTask<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateSystemTaskMutation,
    UpdateSystemTaskMutationVariables,
    UpdateSystemTaskProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateSystemTaskMutation,
    UpdateSystemTaskMutationVariables,
    UpdateSystemTaskProps<TChildProps>
  >(UpdateSystemTaskDocument, {
    alias: 'updateSystemTask',
    ...operationOptions,
  });
}

/**
 * __useUpdateSystemTaskMutation__
 *
 * To run a mutation, you first call `useUpdateSystemTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSystemTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSystemTaskMutation, { data, loading, error }] = useUpdateSystemTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSystemTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSystemTaskMutation, UpdateSystemTaskMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateSystemTaskMutation, UpdateSystemTaskMutationVariables>(
    UpdateSystemTaskDocument,
    baseOptions
  );
}
export type UpdateSystemTaskMutationHookResult = ReturnType<typeof useUpdateSystemTaskMutation>;
export type UpdateSystemTaskMutationResult = ApolloReactCommon.MutationResult<UpdateSystemTaskMutation>;
export type UpdateSystemTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSystemTaskMutation,
  UpdateSystemTaskMutationVariables
>;
export const UpdateSystemPrinterDocument = gql`
  mutation updateSystemPrinter($id: Int!, $data: SystemPrinterUpdateInput!) {
    updateSystemPrinter(id: $id, data: $data) {
      ...systemPrinter
    }
  }
  ${SystemPrinterFragmentDoc}
`;
export type UpdateSystemPrinterMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSystemPrinterMutation,
  UpdateSystemPrinterMutationVariables
>;
export type UpdateSystemPrinterComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateSystemPrinterMutation, UpdateSystemPrinterMutationVariables>,
  'mutation'
>;

export const UpdateSystemPrinterComponent = (props: UpdateSystemPrinterComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateSystemPrinterMutation, UpdateSystemPrinterMutationVariables>
    mutation={UpdateSystemPrinterDocument}
    {...props}
  />
);

export type UpdateSystemPrinterProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateSystemPrinterMutation,
  UpdateSystemPrinterMutationVariables
> &
  TChildProps;
export function withUpdateSystemPrinter<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateSystemPrinterMutation,
    UpdateSystemPrinterMutationVariables,
    UpdateSystemPrinterProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateSystemPrinterMutation,
    UpdateSystemPrinterMutationVariables,
    UpdateSystemPrinterProps<TChildProps>
  >(UpdateSystemPrinterDocument, {
    alias: 'updateSystemPrinter',
    ...operationOptions,
  });
}

/**
 * __useUpdateSystemPrinterMutation__
 *
 * To run a mutation, you first call `useUpdateSystemPrinterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSystemPrinterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSystemPrinterMutation, { data, loading, error }] = useUpdateSystemPrinterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSystemPrinterMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSystemPrinterMutation, UpdateSystemPrinterMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateSystemPrinterMutation, UpdateSystemPrinterMutationVariables>(
    UpdateSystemPrinterDocument,
    baseOptions
  );
}
export type UpdateSystemPrinterMutationHookResult = ReturnType<typeof useUpdateSystemPrinterMutation>;
export type UpdateSystemPrinterMutationResult = ApolloReactCommon.MutationResult<UpdateSystemPrinterMutation>;
export type UpdateSystemPrinterMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSystemPrinterMutation,
  UpdateSystemPrinterMutationVariables
>;
export const UpdateSystemTranslationDocument = gql`
  mutation updateSystemTranslation($id: Int!, $data: SystemTranslationUpdateInput!) {
    updateSystemTranslation(id: $id, data: $data) {
      ...systemTranslation
    }
  }
  ${SystemTranslationFragmentDoc}
`;
export type UpdateSystemTranslationMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSystemTranslationMutation,
  UpdateSystemTranslationMutationVariables
>;
export type UpdateSystemTranslationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateSystemTranslationMutation,
    UpdateSystemTranslationMutationVariables
  >,
  'mutation'
>;

export const UpdateSystemTranslationComponent = (props: UpdateSystemTranslationComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateSystemTranslationMutation, UpdateSystemTranslationMutationVariables>
    mutation={UpdateSystemTranslationDocument}
    {...props}
  />
);

export type UpdateSystemTranslationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateSystemTranslationMutation,
  UpdateSystemTranslationMutationVariables
> &
  TChildProps;
export function withUpdateSystemTranslation<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateSystemTranslationMutation,
    UpdateSystemTranslationMutationVariables,
    UpdateSystemTranslationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateSystemTranslationMutation,
    UpdateSystemTranslationMutationVariables,
    UpdateSystemTranslationProps<TChildProps>
  >(UpdateSystemTranslationDocument, {
    alias: 'updateSystemTranslation',
    ...operationOptions,
  });
}

/**
 * __useUpdateSystemTranslationMutation__
 *
 * To run a mutation, you first call `useUpdateSystemTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSystemTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSystemTranslationMutation, { data, loading, error }] = useUpdateSystemTranslationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSystemTranslationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSystemTranslationMutation,
    UpdateSystemTranslationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateSystemTranslationMutation, UpdateSystemTranslationMutationVariables>(
    UpdateSystemTranslationDocument,
    baseOptions
  );
}
export type UpdateSystemTranslationMutationHookResult = ReturnType<typeof useUpdateSystemTranslationMutation>;
export type UpdateSystemTranslationMutationResult = ApolloReactCommon.MutationResult<UpdateSystemTranslationMutation>;
export type UpdateSystemTranslationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSystemTranslationMutation,
  UpdateSystemTranslationMutationVariables
>;
export const UpdateSystemTranslationsDocument = gql`
  mutation updateSystemTranslations($data: [SystemTranslationUpdateInput!]!) {
    updateSystemTranslations(data: $data) {
      ...systemTranslation
    }
  }
  ${SystemTranslationFragmentDoc}
`;
export type UpdateSystemTranslationsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSystemTranslationsMutation,
  UpdateSystemTranslationsMutationVariables
>;
export type UpdateSystemTranslationsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateSystemTranslationsMutation,
    UpdateSystemTranslationsMutationVariables
  >,
  'mutation'
>;

export const UpdateSystemTranslationsComponent = (props: UpdateSystemTranslationsComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateSystemTranslationsMutation, UpdateSystemTranslationsMutationVariables>
    mutation={UpdateSystemTranslationsDocument}
    {...props}
  />
);

export type UpdateSystemTranslationsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateSystemTranslationsMutation,
  UpdateSystemTranslationsMutationVariables
> &
  TChildProps;
export function withUpdateSystemTranslations<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateSystemTranslationsMutation,
    UpdateSystemTranslationsMutationVariables,
    UpdateSystemTranslationsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateSystemTranslationsMutation,
    UpdateSystemTranslationsMutationVariables,
    UpdateSystemTranslationsProps<TChildProps>
  >(UpdateSystemTranslationsDocument, {
    alias: 'updateSystemTranslations',
    ...operationOptions,
  });
}

/**
 * __useUpdateSystemTranslationsMutation__
 *
 * To run a mutation, you first call `useUpdateSystemTranslationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSystemTranslationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSystemTranslationsMutation, { data, loading, error }] = useUpdateSystemTranslationsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSystemTranslationsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSystemTranslationsMutation,
    UpdateSystemTranslationsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateSystemTranslationsMutation, UpdateSystemTranslationsMutationVariables>(
    UpdateSystemTranslationsDocument,
    baseOptions
  );
}
export type UpdateSystemTranslationsMutationHookResult = ReturnType<typeof useUpdateSystemTranslationsMutation>;
export type UpdateSystemTranslationsMutationResult = ApolloReactCommon.MutationResult<UpdateSystemTranslationsMutation>;
export type UpdateSystemTranslationsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSystemTranslationsMutation,
  UpdateSystemTranslationsMutationVariables
>;
export const UpdateSystemUserDocument = gql`
  mutation updateSystemUser($id: ID!, $data: UpdateSystemUserInput!) {
    updateSystemUser(id: $id, data: $data) {
      ...systemUser
    }
  }
  ${SystemUserFragmentDoc}
`;
export type UpdateSystemUserMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSystemUserMutation,
  UpdateSystemUserMutationVariables
>;
export type UpdateSystemUserComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateSystemUserMutation, UpdateSystemUserMutationVariables>,
  'mutation'
>;

export const UpdateSystemUserComponent = (props: UpdateSystemUserComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateSystemUserMutation, UpdateSystemUserMutationVariables>
    mutation={UpdateSystemUserDocument}
    {...props}
  />
);

export type UpdateSystemUserProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateSystemUserMutation,
  UpdateSystemUserMutationVariables
> &
  TChildProps;
export function withUpdateSystemUser<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateSystemUserMutation,
    UpdateSystemUserMutationVariables,
    UpdateSystemUserProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateSystemUserMutation,
    UpdateSystemUserMutationVariables,
    UpdateSystemUserProps<TChildProps>
  >(UpdateSystemUserDocument, {
    alias: 'updateSystemUser',
    ...operationOptions,
  });
}

/**
 * __useUpdateSystemUserMutation__
 *
 * To run a mutation, you first call `useUpdateSystemUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSystemUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSystemUserMutation, { data, loading, error }] = useUpdateSystemUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSystemUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSystemUserMutation, UpdateSystemUserMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateSystemUserMutation, UpdateSystemUserMutationVariables>(
    UpdateSystemUserDocument,
    baseOptions
  );
}
export type UpdateSystemUserMutationHookResult = ReturnType<typeof useUpdateSystemUserMutation>;
export type UpdateSystemUserMutationResult = ApolloReactCommon.MutationResult<UpdateSystemUserMutation>;
export type UpdateSystemUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSystemUserMutation,
  UpdateSystemUserMutationVariables
>;
export const UpdateSystemWebHookDocument = gql`
  mutation updateSystemWebHook($id: Int!, $data: SystemWebHookUpdateInput!) {
    updateSystemWebHook(id: $id, data: $data) {
      ...systemWebHook
    }
  }
  ${SystemWebHookFragmentDoc}
`;
export type UpdateSystemWebHookMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSystemWebHookMutation,
  UpdateSystemWebHookMutationVariables
>;
export type UpdateSystemWebHookComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateSystemWebHookMutation, UpdateSystemWebHookMutationVariables>,
  'mutation'
>;

export const UpdateSystemWebHookComponent = (props: UpdateSystemWebHookComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateSystemWebHookMutation, UpdateSystemWebHookMutationVariables>
    mutation={UpdateSystemWebHookDocument}
    {...props}
  />
);

export type UpdateSystemWebHookProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateSystemWebHookMutation,
  UpdateSystemWebHookMutationVariables
> &
  TChildProps;
export function withUpdateSystemWebHook<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateSystemWebHookMutation,
    UpdateSystemWebHookMutationVariables,
    UpdateSystemWebHookProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateSystemWebHookMutation,
    UpdateSystemWebHookMutationVariables,
    UpdateSystemWebHookProps<TChildProps>
  >(UpdateSystemWebHookDocument, {
    alias: 'updateSystemWebHook',
    ...operationOptions,
  });
}

/**
 * __useUpdateSystemWebHookMutation__
 *
 * To run a mutation, you first call `useUpdateSystemWebHookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSystemWebHookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSystemWebHookMutation, { data, loading, error }] = useUpdateSystemWebHookMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSystemWebHookMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSystemWebHookMutation, UpdateSystemWebHookMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateSystemWebHookMutation, UpdateSystemWebHookMutationVariables>(
    UpdateSystemWebHookDocument,
    baseOptions
  );
}
export type UpdateSystemWebHookMutationHookResult = ReturnType<typeof useUpdateSystemWebHookMutation>;
export type UpdateSystemWebHookMutationResult = ApolloReactCommon.MutationResult<UpdateSystemWebHookMutation>;
export type UpdateSystemWebHookMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSystemWebHookMutation,
  UpdateSystemWebHookMutationVariables
>;
export const UpdateTemplateApplePassDocument = gql`
  mutation updateTemplateApplePass($id: Int!, $data: TemplateApplePassUpdateInput!) {
    updateTemplateApplePass(id: $id, data: $data) {
      ...templateApplePass
    }
  }
  ${TemplateApplePassFragmentDoc}
`;
export type UpdateTemplateApplePassMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTemplateApplePassMutation,
  UpdateTemplateApplePassMutationVariables
>;
export type UpdateTemplateApplePassComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateTemplateApplePassMutation,
    UpdateTemplateApplePassMutationVariables
  >,
  'mutation'
>;

export const UpdateTemplateApplePassComponent = (props: UpdateTemplateApplePassComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateTemplateApplePassMutation, UpdateTemplateApplePassMutationVariables>
    mutation={UpdateTemplateApplePassDocument}
    {...props}
  />
);

export type UpdateTemplateApplePassProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateTemplateApplePassMutation,
  UpdateTemplateApplePassMutationVariables
> &
  TChildProps;
export function withUpdateTemplateApplePass<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateTemplateApplePassMutation,
    UpdateTemplateApplePassMutationVariables,
    UpdateTemplateApplePassProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateTemplateApplePassMutation,
    UpdateTemplateApplePassMutationVariables,
    UpdateTemplateApplePassProps<TChildProps>
  >(UpdateTemplateApplePassDocument, {
    alias: 'updateTemplateApplePass',
    ...operationOptions,
  });
}

/**
 * __useUpdateTemplateApplePassMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateApplePassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateApplePassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateApplePassMutation, { data, loading, error }] = useUpdateTemplateApplePassMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTemplateApplePassMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTemplateApplePassMutation,
    UpdateTemplateApplePassMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateTemplateApplePassMutation, UpdateTemplateApplePassMutationVariables>(
    UpdateTemplateApplePassDocument,
    baseOptions
  );
}
export type UpdateTemplateApplePassMutationHookResult = ReturnType<typeof useUpdateTemplateApplePassMutation>;
export type UpdateTemplateApplePassMutationResult = ApolloReactCommon.MutationResult<UpdateTemplateApplePassMutation>;
export type UpdateTemplateApplePassMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTemplateApplePassMutation,
  UpdateTemplateApplePassMutationVariables
>;
export const UpdateTemplatePdfDocument = gql`
  mutation updateTemplatePDF($id: Int!, $data: TemplatePDFUpdateInput!) {
    updateTemplatePDF(id: $id, data: $data) {
      ...templatePDF
    }
  }
  ${TemplatePdfFragmentDoc}
`;
export type UpdateTemplatePdfMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTemplatePdfMutation,
  UpdateTemplatePdfMutationVariables
>;
export type UpdateTemplatePdfComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateTemplatePdfMutation, UpdateTemplatePdfMutationVariables>,
  'mutation'
>;

export const UpdateTemplatePdfComponent = (props: UpdateTemplatePdfComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateTemplatePdfMutation, UpdateTemplatePdfMutationVariables>
    mutation={UpdateTemplatePdfDocument}
    {...props}
  />
);

export type UpdateTemplatePdfProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateTemplatePdfMutation,
  UpdateTemplatePdfMutationVariables
> &
  TChildProps;
export function withUpdateTemplatePdf<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateTemplatePdfMutation,
    UpdateTemplatePdfMutationVariables,
    UpdateTemplatePdfProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateTemplatePdfMutation,
    UpdateTemplatePdfMutationVariables,
    UpdateTemplatePdfProps<TChildProps>
  >(UpdateTemplatePdfDocument, {
    alias: 'updateTemplatePdf',
    ...operationOptions,
  });
}

/**
 * __useUpdateTemplatePdfMutation__
 *
 * To run a mutation, you first call `useUpdateTemplatePdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplatePdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplatePdfMutation, { data, loading, error }] = useUpdateTemplatePdfMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTemplatePdfMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTemplatePdfMutation, UpdateTemplatePdfMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateTemplatePdfMutation, UpdateTemplatePdfMutationVariables>(
    UpdateTemplatePdfDocument,
    baseOptions
  );
}
export type UpdateTemplatePdfMutationHookResult = ReturnType<typeof useUpdateTemplatePdfMutation>;
export type UpdateTemplatePdfMutationResult = ApolloReactCommon.MutationResult<UpdateTemplatePdfMutation>;
export type UpdateTemplatePdfMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTemplatePdfMutation,
  UpdateTemplatePdfMutationVariables
>;
export const UpdateTournamentDocument = gql`
  mutation updateTournament($id: Int!, $data: TournamentUpdateInput!) {
    updateTournament(id: $id, data: $data) {
      ...tournamentFull
    }
  }
  ${TournamentFullFragmentDoc}
`;
export type UpdateTournamentMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTournamentMutation,
  UpdateTournamentMutationVariables
>;
export type UpdateTournamentComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateTournamentMutation, UpdateTournamentMutationVariables>,
  'mutation'
>;

export const UpdateTournamentComponent = (props: UpdateTournamentComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateTournamentMutation, UpdateTournamentMutationVariables>
    mutation={UpdateTournamentDocument}
    {...props}
  />
);

export type UpdateTournamentProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateTournamentMutation,
  UpdateTournamentMutationVariables
> &
  TChildProps;
export function withUpdateTournament<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateTournamentMutation,
    UpdateTournamentMutationVariables,
    UpdateTournamentProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateTournamentMutation,
    UpdateTournamentMutationVariables,
    UpdateTournamentProps<TChildProps>
  >(UpdateTournamentDocument, {
    alias: 'updateTournament',
    ...operationOptions,
  });
}

/**
 * __useUpdateTournamentMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentMutation, { data, loading, error }] = useUpdateTournamentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTournamentMutation, UpdateTournamentMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateTournamentMutation, UpdateTournamentMutationVariables>(
    UpdateTournamentDocument,
    baseOptions
  );
}
export type UpdateTournamentMutationHookResult = ReturnType<typeof useUpdateTournamentMutation>;
export type UpdateTournamentMutationResult = ApolloReactCommon.MutationResult<UpdateTournamentMutation>;
export type UpdateTournamentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTournamentMutation,
  UpdateTournamentMutationVariables
>;
export const UpdateTournamentDisqualificationDocument = gql`
  mutation updateTournamentDisqualification($id: Int!, $data: TournamentDisqualificationUpdateInput!) {
    updateTournamentDisqualification(id: $id, data: $data) {
      ...tournamentDisqualification
    }
  }
  ${TournamentDisqualificationFragmentDoc}
`;
export type UpdateTournamentDisqualificationMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTournamentDisqualificationMutation,
  UpdateTournamentDisqualificationMutationVariables
>;
export type UpdateTournamentDisqualificationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateTournamentDisqualificationMutation,
    UpdateTournamentDisqualificationMutationVariables
  >,
  'mutation'
>;

export const UpdateTournamentDisqualificationComponent = (props: UpdateTournamentDisqualificationComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateTournamentDisqualificationMutation,
    UpdateTournamentDisqualificationMutationVariables
  >
    mutation={UpdateTournamentDisqualificationDocument}
    {...props}
  />
);

export type UpdateTournamentDisqualificationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateTournamentDisqualificationMutation,
  UpdateTournamentDisqualificationMutationVariables
> &
  TChildProps;
export function withUpdateTournamentDisqualification<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateTournamentDisqualificationMutation,
    UpdateTournamentDisqualificationMutationVariables,
    UpdateTournamentDisqualificationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateTournamentDisqualificationMutation,
    UpdateTournamentDisqualificationMutationVariables,
    UpdateTournamentDisqualificationProps<TChildProps>
  >(UpdateTournamentDisqualificationDocument, {
    alias: 'updateTournamentDisqualification',
    ...operationOptions,
  });
}

/**
 * __useUpdateTournamentDisqualificationMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentDisqualificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentDisqualificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentDisqualificationMutation, { data, loading, error }] = useUpdateTournamentDisqualificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentDisqualificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentDisqualificationMutation,
    UpdateTournamentDisqualificationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateTournamentDisqualificationMutation,
    UpdateTournamentDisqualificationMutationVariables
  >(UpdateTournamentDisqualificationDocument, baseOptions);
}
export type UpdateTournamentDisqualificationMutationHookResult = ReturnType<
  typeof useUpdateTournamentDisqualificationMutation
>;
export type UpdateTournamentDisqualificationMutationResult = ApolloReactCommon.MutationResult<
  UpdateTournamentDisqualificationMutation
>;
export type UpdateTournamentDisqualificationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTournamentDisqualificationMutation,
  UpdateTournamentDisqualificationMutationVariables
>;
export const UpdateTournamentMatchDocument = gql`
  mutation updateTournamentMatch($id: Int!, $data: TournamentMatchUpdateInput!) {
    updateTournamentMatch(id: $id, data: $data) {
      ...tournamentMatch
    }
  }
  ${TournamentMatchFragmentDoc}
`;
export type UpdateTournamentMatchMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTournamentMatchMutation,
  UpdateTournamentMatchMutationVariables
>;
export type UpdateTournamentMatchComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateTournamentMatchMutation, UpdateTournamentMatchMutationVariables>,
  'mutation'
>;

export const UpdateTournamentMatchComponent = (props: UpdateTournamentMatchComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateTournamentMatchMutation, UpdateTournamentMatchMutationVariables>
    mutation={UpdateTournamentMatchDocument}
    {...props}
  />
);

export type UpdateTournamentMatchProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateTournamentMatchMutation,
  UpdateTournamentMatchMutationVariables
> &
  TChildProps;
export function withUpdateTournamentMatch<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateTournamentMatchMutation,
    UpdateTournamentMatchMutationVariables,
    UpdateTournamentMatchProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateTournamentMatchMutation,
    UpdateTournamentMatchMutationVariables,
    UpdateTournamentMatchProps<TChildProps>
  >(UpdateTournamentMatchDocument, {
    alias: 'updateTournamentMatch',
    ...operationOptions,
  });
}

/**
 * __useUpdateTournamentMatchMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentMatchMutation, { data, loading, error }] = useUpdateTournamentMatchMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentMatchMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentMatchMutation,
    UpdateTournamentMatchMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateTournamentMatchMutation, UpdateTournamentMatchMutationVariables>(
    UpdateTournamentMatchDocument,
    baseOptions
  );
}
export type UpdateTournamentMatchMutationHookResult = ReturnType<typeof useUpdateTournamentMatchMutation>;
export type UpdateTournamentMatchMutationResult = ApolloReactCommon.MutationResult<UpdateTournamentMatchMutation>;
export type UpdateTournamentMatchMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTournamentMatchMutation,
  UpdateTournamentMatchMutationVariables
>;
export const UpdateTournamentMatchEventDocument = gql`
  mutation updateTournamentMatchEvent($id: Int!, $data: TournamentMatchEventUpdateInput!) {
    updateTournamentMatchEvent(id: $id, data: $data) {
      ...tournamentMatchEvent
    }
  }
  ${TournamentMatchEventFragmentDoc}
`;
export type UpdateTournamentMatchEventMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTournamentMatchEventMutation,
  UpdateTournamentMatchEventMutationVariables
>;
export type UpdateTournamentMatchEventComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateTournamentMatchEventMutation,
    UpdateTournamentMatchEventMutationVariables
  >,
  'mutation'
>;

export const UpdateTournamentMatchEventComponent = (props: UpdateTournamentMatchEventComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateTournamentMatchEventMutation, UpdateTournamentMatchEventMutationVariables>
    mutation={UpdateTournamentMatchEventDocument}
    {...props}
  />
);

export type UpdateTournamentMatchEventProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateTournamentMatchEventMutation,
  UpdateTournamentMatchEventMutationVariables
> &
  TChildProps;
export function withUpdateTournamentMatchEvent<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateTournamentMatchEventMutation,
    UpdateTournamentMatchEventMutationVariables,
    UpdateTournamentMatchEventProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateTournamentMatchEventMutation,
    UpdateTournamentMatchEventMutationVariables,
    UpdateTournamentMatchEventProps<TChildProps>
  >(UpdateTournamentMatchEventDocument, {
    alias: 'updateTournamentMatchEvent',
    ...operationOptions,
  });
}

/**
 * __useUpdateTournamentMatchEventMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentMatchEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentMatchEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentMatchEventMutation, { data, loading, error }] = useUpdateTournamentMatchEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentMatchEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentMatchEventMutation,
    UpdateTournamentMatchEventMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateTournamentMatchEventMutation, UpdateTournamentMatchEventMutationVariables>(
    UpdateTournamentMatchEventDocument,
    baseOptions
  );
}
export type UpdateTournamentMatchEventMutationHookResult = ReturnType<typeof useUpdateTournamentMatchEventMutation>;
export type UpdateTournamentMatchEventMutationResult = ApolloReactCommon.MutationResult<
  UpdateTournamentMatchEventMutation
>;
export type UpdateTournamentMatchEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTournamentMatchEventMutation,
  UpdateTournamentMatchEventMutationVariables
>;
export const UpdateTournamentMatchLineUpDocument = gql`
  mutation updateTournamentMatchLineUp($id: Int!, $data: TournamentMatchLineUpUpdateInput!) {
    updateTournamentMatchLineUp(id: $id, data: $data) {
      ...tournamentMatchLineUp
    }
  }
  ${TournamentMatchLineUpFragmentDoc}
`;
export type UpdateTournamentMatchLineUpMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTournamentMatchLineUpMutation,
  UpdateTournamentMatchLineUpMutationVariables
>;
export type UpdateTournamentMatchLineUpComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateTournamentMatchLineUpMutation,
    UpdateTournamentMatchLineUpMutationVariables
  >,
  'mutation'
>;

export const UpdateTournamentMatchLineUpComponent = (props: UpdateTournamentMatchLineUpComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateTournamentMatchLineUpMutation, UpdateTournamentMatchLineUpMutationVariables>
    mutation={UpdateTournamentMatchLineUpDocument}
    {...props}
  />
);

export type UpdateTournamentMatchLineUpProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateTournamentMatchLineUpMutation,
  UpdateTournamentMatchLineUpMutationVariables
> &
  TChildProps;
export function withUpdateTournamentMatchLineUp<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateTournamentMatchLineUpMutation,
    UpdateTournamentMatchLineUpMutationVariables,
    UpdateTournamentMatchLineUpProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateTournamentMatchLineUpMutation,
    UpdateTournamentMatchLineUpMutationVariables,
    UpdateTournamentMatchLineUpProps<TChildProps>
  >(UpdateTournamentMatchLineUpDocument, {
    alias: 'updateTournamentMatchLineUp',
    ...operationOptions,
  });
}

/**
 * __useUpdateTournamentMatchLineUpMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentMatchLineUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentMatchLineUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentMatchLineUpMutation, { data, loading, error }] = useUpdateTournamentMatchLineUpMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentMatchLineUpMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentMatchLineUpMutation,
    UpdateTournamentMatchLineUpMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateTournamentMatchLineUpMutation,
    UpdateTournamentMatchLineUpMutationVariables
  >(UpdateTournamentMatchLineUpDocument, baseOptions);
}
export type UpdateTournamentMatchLineUpMutationHookResult = ReturnType<typeof useUpdateTournamentMatchLineUpMutation>;
export type UpdateTournamentMatchLineUpMutationResult = ApolloReactCommon.MutationResult<
  UpdateTournamentMatchLineUpMutation
>;
export type UpdateTournamentMatchLineUpMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTournamentMatchLineUpMutation,
  UpdateTournamentMatchLineUpMutationVariables
>;
export const UpdateTournamentMatchProtocolDocument = gql`
  mutation updateTournamentMatchProtocol($id: Int!, $data: TournamentMatchProtocolUpdateInput!) {
    updateTournamentMatchProtocol(id: $id, data: $data) {
      ...tournamentMatchProtocol
    }
  }
  ${TournamentMatchProtocolFragmentDoc}
`;
export type UpdateTournamentMatchProtocolMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTournamentMatchProtocolMutation,
  UpdateTournamentMatchProtocolMutationVariables
>;
export type UpdateTournamentMatchProtocolComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateTournamentMatchProtocolMutation,
    UpdateTournamentMatchProtocolMutationVariables
  >,
  'mutation'
>;

export const UpdateTournamentMatchProtocolComponent = (props: UpdateTournamentMatchProtocolComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateTournamentMatchProtocolMutation, UpdateTournamentMatchProtocolMutationVariables>
    mutation={UpdateTournamentMatchProtocolDocument}
    {...props}
  />
);

export type UpdateTournamentMatchProtocolProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateTournamentMatchProtocolMutation,
  UpdateTournamentMatchProtocolMutationVariables
> &
  TChildProps;
export function withUpdateTournamentMatchProtocol<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateTournamentMatchProtocolMutation,
    UpdateTournamentMatchProtocolMutationVariables,
    UpdateTournamentMatchProtocolProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateTournamentMatchProtocolMutation,
    UpdateTournamentMatchProtocolMutationVariables,
    UpdateTournamentMatchProtocolProps<TChildProps>
  >(UpdateTournamentMatchProtocolDocument, {
    alias: 'updateTournamentMatchProtocol',
    ...operationOptions,
  });
}

/**
 * __useUpdateTournamentMatchProtocolMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentMatchProtocolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentMatchProtocolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentMatchProtocolMutation, { data, loading, error }] = useUpdateTournamentMatchProtocolMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentMatchProtocolMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentMatchProtocolMutation,
    UpdateTournamentMatchProtocolMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateTournamentMatchProtocolMutation,
    UpdateTournamentMatchProtocolMutationVariables
  >(UpdateTournamentMatchProtocolDocument, baseOptions);
}
export type UpdateTournamentMatchProtocolMutationHookResult = ReturnType<
  typeof useUpdateTournamentMatchProtocolMutation
>;
export type UpdateTournamentMatchProtocolMutationResult = ApolloReactCommon.MutationResult<
  UpdateTournamentMatchProtocolMutation
>;
export type UpdateTournamentMatchProtocolMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTournamentMatchProtocolMutation,
  UpdateTournamentMatchProtocolMutationVariables
>;
export const UpdateTournamentPlayerStatDocument = gql`
  mutation updateTournamentPlayerStat($id: Int!, $data: TournamentPlayerStatUpdateInput!) {
    updateTournamentPlayerStat(id: $id, data: $data) {
      ...tournamentPlayerStat
    }
  }
  ${TournamentPlayerStatFragmentDoc}
`;
export type UpdateTournamentPlayerStatMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTournamentPlayerStatMutation,
  UpdateTournamentPlayerStatMutationVariables
>;
export type UpdateTournamentPlayerStatComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateTournamentPlayerStatMutation,
    UpdateTournamentPlayerStatMutationVariables
  >,
  'mutation'
>;

export const UpdateTournamentPlayerStatComponent = (props: UpdateTournamentPlayerStatComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateTournamentPlayerStatMutation, UpdateTournamentPlayerStatMutationVariables>
    mutation={UpdateTournamentPlayerStatDocument}
    {...props}
  />
);

export type UpdateTournamentPlayerStatProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateTournamentPlayerStatMutation,
  UpdateTournamentPlayerStatMutationVariables
> &
  TChildProps;
export function withUpdateTournamentPlayerStat<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateTournamentPlayerStatMutation,
    UpdateTournamentPlayerStatMutationVariables,
    UpdateTournamentPlayerStatProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateTournamentPlayerStatMutation,
    UpdateTournamentPlayerStatMutationVariables,
    UpdateTournamentPlayerStatProps<TChildProps>
  >(UpdateTournamentPlayerStatDocument, {
    alias: 'updateTournamentPlayerStat',
    ...operationOptions,
  });
}

/**
 * __useUpdateTournamentPlayerStatMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentPlayerStatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentPlayerStatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentPlayerStatMutation, { data, loading, error }] = useUpdateTournamentPlayerStatMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentPlayerStatMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentPlayerStatMutation,
    UpdateTournamentPlayerStatMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateTournamentPlayerStatMutation, UpdateTournamentPlayerStatMutationVariables>(
    UpdateTournamentPlayerStatDocument,
    baseOptions
  );
}
export type UpdateTournamentPlayerStatMutationHookResult = ReturnType<typeof useUpdateTournamentPlayerStatMutation>;
export type UpdateTournamentPlayerStatMutationResult = ApolloReactCommon.MutationResult<
  UpdateTournamentPlayerStatMutation
>;
export type UpdateTournamentPlayerStatMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTournamentPlayerStatMutation,
  UpdateTournamentPlayerStatMutationVariables
>;
export const UpdateTournamentRefereeDocument = gql`
  mutation updateTournamentReferee($id: Int!, $data: TournamentRefereeUpdateInput!) {
    updateTournamentReferee(id: $id, data: $data) {
      ...tournamentReferee
    }
  }
  ${TournamentRefereeFragmentDoc}
`;
export type UpdateTournamentRefereeMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTournamentRefereeMutation,
  UpdateTournamentRefereeMutationVariables
>;
export type UpdateTournamentRefereeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateTournamentRefereeMutation,
    UpdateTournamentRefereeMutationVariables
  >,
  'mutation'
>;

export const UpdateTournamentRefereeComponent = (props: UpdateTournamentRefereeComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateTournamentRefereeMutation, UpdateTournamentRefereeMutationVariables>
    mutation={UpdateTournamentRefereeDocument}
    {...props}
  />
);

export type UpdateTournamentRefereeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateTournamentRefereeMutation,
  UpdateTournamentRefereeMutationVariables
> &
  TChildProps;
export function withUpdateTournamentReferee<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateTournamentRefereeMutation,
    UpdateTournamentRefereeMutationVariables,
    UpdateTournamentRefereeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateTournamentRefereeMutation,
    UpdateTournamentRefereeMutationVariables,
    UpdateTournamentRefereeProps<TChildProps>
  >(UpdateTournamentRefereeDocument, {
    alias: 'updateTournamentReferee',
    ...operationOptions,
  });
}

/**
 * __useUpdateTournamentRefereeMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentRefereeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentRefereeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentRefereeMutation, { data, loading, error }] = useUpdateTournamentRefereeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentRefereeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentRefereeMutation,
    UpdateTournamentRefereeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateTournamentRefereeMutation, UpdateTournamentRefereeMutationVariables>(
    UpdateTournamentRefereeDocument,
    baseOptions
  );
}
export type UpdateTournamentRefereeMutationHookResult = ReturnType<typeof useUpdateTournamentRefereeMutation>;
export type UpdateTournamentRefereeMutationResult = ApolloReactCommon.MutationResult<UpdateTournamentRefereeMutation>;
export type UpdateTournamentRefereeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTournamentRefereeMutation,
  UpdateTournamentRefereeMutationVariables
>;
export const UpdateTournamentSeasonDocument = gql`
  mutation updateTournamentSeason($id: Int!, $data: TournamentSeasonUpdateInput!) {
    updateTournamentSeason(id: $id, data: $data) {
      ...tournamentSeason
    }
  }
  ${TournamentSeasonFragmentDoc}
`;
export type UpdateTournamentSeasonMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTournamentSeasonMutation,
  UpdateTournamentSeasonMutationVariables
>;
export type UpdateTournamentSeasonComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateTournamentSeasonMutation,
    UpdateTournamentSeasonMutationVariables
  >,
  'mutation'
>;

export const UpdateTournamentSeasonComponent = (props: UpdateTournamentSeasonComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateTournamentSeasonMutation, UpdateTournamentSeasonMutationVariables>
    mutation={UpdateTournamentSeasonDocument}
    {...props}
  />
);

export type UpdateTournamentSeasonProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateTournamentSeasonMutation,
  UpdateTournamentSeasonMutationVariables
> &
  TChildProps;
export function withUpdateTournamentSeason<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateTournamentSeasonMutation,
    UpdateTournamentSeasonMutationVariables,
    UpdateTournamentSeasonProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateTournamentSeasonMutation,
    UpdateTournamentSeasonMutationVariables,
    UpdateTournamentSeasonProps<TChildProps>
  >(UpdateTournamentSeasonDocument, {
    alias: 'updateTournamentSeason',
    ...operationOptions,
  });
}

/**
 * __useUpdateTournamentSeasonMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentSeasonMutation, { data, loading, error }] = useUpdateTournamentSeasonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentSeasonMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentSeasonMutation,
    UpdateTournamentSeasonMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateTournamentSeasonMutation, UpdateTournamentSeasonMutationVariables>(
    UpdateTournamentSeasonDocument,
    baseOptions
  );
}
export type UpdateTournamentSeasonMutationHookResult = ReturnType<typeof useUpdateTournamentSeasonMutation>;
export type UpdateTournamentSeasonMutationResult = ApolloReactCommon.MutationResult<UpdateTournamentSeasonMutation>;
export type UpdateTournamentSeasonMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTournamentSeasonMutation,
  UpdateTournamentSeasonMutationVariables
>;
export const UpdateTournamentSeasonTeamDocument = gql`
  mutation updateTournamentSeasonTeam($id: Int!, $data: TournamentSeasonTeamUpdateInput!) {
    updateTournamentSeasonTeam(id: $id, data: $data) {
      ...tournamentSeasonTeam
    }
  }
  ${TournamentSeasonTeamFragmentDoc}
`;
export type UpdateTournamentSeasonTeamMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTournamentSeasonTeamMutation,
  UpdateTournamentSeasonTeamMutationVariables
>;
export type UpdateTournamentSeasonTeamComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateTournamentSeasonTeamMutation,
    UpdateTournamentSeasonTeamMutationVariables
  >,
  'mutation'
>;

export const UpdateTournamentSeasonTeamComponent = (props: UpdateTournamentSeasonTeamComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateTournamentSeasonTeamMutation, UpdateTournamentSeasonTeamMutationVariables>
    mutation={UpdateTournamentSeasonTeamDocument}
    {...props}
  />
);

export type UpdateTournamentSeasonTeamProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateTournamentSeasonTeamMutation,
  UpdateTournamentSeasonTeamMutationVariables
> &
  TChildProps;
export function withUpdateTournamentSeasonTeam<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateTournamentSeasonTeamMutation,
    UpdateTournamentSeasonTeamMutationVariables,
    UpdateTournamentSeasonTeamProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateTournamentSeasonTeamMutation,
    UpdateTournamentSeasonTeamMutationVariables,
    UpdateTournamentSeasonTeamProps<TChildProps>
  >(UpdateTournamentSeasonTeamDocument, {
    alias: 'updateTournamentSeasonTeam',
    ...operationOptions,
  });
}

/**
 * __useUpdateTournamentSeasonTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentSeasonTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentSeasonTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentSeasonTeamMutation, { data, loading, error }] = useUpdateTournamentSeasonTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentSeasonTeamMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentSeasonTeamMutation,
    UpdateTournamentSeasonTeamMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateTournamentSeasonTeamMutation, UpdateTournamentSeasonTeamMutationVariables>(
    UpdateTournamentSeasonTeamDocument,
    baseOptions
  );
}
export type UpdateTournamentSeasonTeamMutationHookResult = ReturnType<typeof useUpdateTournamentSeasonTeamMutation>;
export type UpdateTournamentSeasonTeamMutationResult = ApolloReactCommon.MutationResult<
  UpdateTournamentSeasonTeamMutation
>;
export type UpdateTournamentSeasonTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTournamentSeasonTeamMutation,
  UpdateTournamentSeasonTeamMutationVariables
>;
export const UpdateTournamentSeasonTeamStatDocument = gql`
  mutation updateTournamentSeasonTeamStat($id: Int!, $data: TournamentSeasonTeamStatUpdateInput!) {
    updateTournamentSeasonTeamStat(id: $id, data: $data) {
      ...tournamentSeasonTeamStat
    }
  }
  ${TournamentSeasonTeamStatFragmentDoc}
`;
export type UpdateTournamentSeasonTeamStatMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTournamentSeasonTeamStatMutation,
  UpdateTournamentSeasonTeamStatMutationVariables
>;
export type UpdateTournamentSeasonTeamStatComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateTournamentSeasonTeamStatMutation,
    UpdateTournamentSeasonTeamStatMutationVariables
  >,
  'mutation'
>;

export const UpdateTournamentSeasonTeamStatComponent = (props: UpdateTournamentSeasonTeamStatComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateTournamentSeasonTeamStatMutation,
    UpdateTournamentSeasonTeamStatMutationVariables
  >
    mutation={UpdateTournamentSeasonTeamStatDocument}
    {...props}
  />
);

export type UpdateTournamentSeasonTeamStatProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateTournamentSeasonTeamStatMutation,
  UpdateTournamentSeasonTeamStatMutationVariables
> &
  TChildProps;
export function withUpdateTournamentSeasonTeamStat<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateTournamentSeasonTeamStatMutation,
    UpdateTournamentSeasonTeamStatMutationVariables,
    UpdateTournamentSeasonTeamStatProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateTournamentSeasonTeamStatMutation,
    UpdateTournamentSeasonTeamStatMutationVariables,
    UpdateTournamentSeasonTeamStatProps<TChildProps>
  >(UpdateTournamentSeasonTeamStatDocument, {
    alias: 'updateTournamentSeasonTeamStat',
    ...operationOptions,
  });
}

/**
 * __useUpdateTournamentSeasonTeamStatMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentSeasonTeamStatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentSeasonTeamStatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentSeasonTeamStatMutation, { data, loading, error }] = useUpdateTournamentSeasonTeamStatMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentSeasonTeamStatMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentSeasonTeamStatMutation,
    UpdateTournamentSeasonTeamStatMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateTournamentSeasonTeamStatMutation,
    UpdateTournamentSeasonTeamStatMutationVariables
  >(UpdateTournamentSeasonTeamStatDocument, baseOptions);
}
export type UpdateTournamentSeasonTeamStatMutationHookResult = ReturnType<
  typeof useUpdateTournamentSeasonTeamStatMutation
>;
export type UpdateTournamentSeasonTeamStatMutationResult = ApolloReactCommon.MutationResult<
  UpdateTournamentSeasonTeamStatMutation
>;
export type UpdateTournamentSeasonTeamStatMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTournamentSeasonTeamStatMutation,
  UpdateTournamentSeasonTeamStatMutationVariables
>;
export const UpdateTournamentStadiumDocument = gql`
  mutation updateTournamentStadium($id: Int!, $data: TournamentStadiumUpdateInput!) {
    updateTournamentStadium(id: $id, data: $data) {
      ...tournamentStadium
    }
  }
  ${TournamentStadiumFragmentDoc}
`;
export type UpdateTournamentStadiumMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTournamentStadiumMutation,
  UpdateTournamentStadiumMutationVariables
>;
export type UpdateTournamentStadiumComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateTournamentStadiumMutation,
    UpdateTournamentStadiumMutationVariables
  >,
  'mutation'
>;

export const UpdateTournamentStadiumComponent = (props: UpdateTournamentStadiumComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateTournamentStadiumMutation, UpdateTournamentStadiumMutationVariables>
    mutation={UpdateTournamentStadiumDocument}
    {...props}
  />
);

export type UpdateTournamentStadiumProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateTournamentStadiumMutation,
  UpdateTournamentStadiumMutationVariables
> &
  TChildProps;
export function withUpdateTournamentStadium<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateTournamentStadiumMutation,
    UpdateTournamentStadiumMutationVariables,
    UpdateTournamentStadiumProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateTournamentStadiumMutation,
    UpdateTournamentStadiumMutationVariables,
    UpdateTournamentStadiumProps<TChildProps>
  >(UpdateTournamentStadiumDocument, {
    alias: 'updateTournamentStadium',
    ...operationOptions,
  });
}

/**
 * __useUpdateTournamentStadiumMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentStadiumMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentStadiumMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentStadiumMutation, { data, loading, error }] = useUpdateTournamentStadiumMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentStadiumMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentStadiumMutation,
    UpdateTournamentStadiumMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateTournamentStadiumMutation, UpdateTournamentStadiumMutationVariables>(
    UpdateTournamentStadiumDocument,
    baseOptions
  );
}
export type UpdateTournamentStadiumMutationHookResult = ReturnType<typeof useUpdateTournamentStadiumMutation>;
export type UpdateTournamentStadiumMutationResult = ApolloReactCommon.MutationResult<UpdateTournamentStadiumMutation>;
export type UpdateTournamentStadiumMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTournamentStadiumMutation,
  UpdateTournamentStadiumMutationVariables
>;
export const UpdateTournamentTeamDocument = gql`
  mutation updateTournamentTeam($id: Int!, $data: TournamentTeamUpdateInput!) {
    updateTournamentTeam(id: $id, data: $data) {
      ...tournamentTeam
    }
  }
  ${TournamentTeamFragmentDoc}
`;
export type UpdateTournamentTeamMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTournamentTeamMutation,
  UpdateTournamentTeamMutationVariables
>;
export type UpdateTournamentTeamComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateTournamentTeamMutation, UpdateTournamentTeamMutationVariables>,
  'mutation'
>;

export const UpdateTournamentTeamComponent = (props: UpdateTournamentTeamComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateTournamentTeamMutation, UpdateTournamentTeamMutationVariables>
    mutation={UpdateTournamentTeamDocument}
    {...props}
  />
);

export type UpdateTournamentTeamProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateTournamentTeamMutation,
  UpdateTournamentTeamMutationVariables
> &
  TChildProps;
export function withUpdateTournamentTeam<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateTournamentTeamMutation,
    UpdateTournamentTeamMutationVariables,
    UpdateTournamentTeamProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateTournamentTeamMutation,
    UpdateTournamentTeamMutationVariables,
    UpdateTournamentTeamProps<TChildProps>
  >(UpdateTournamentTeamDocument, {
    alias: 'updateTournamentTeam',
    ...operationOptions,
  });
}

/**
 * __useUpdateTournamentTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentTeamMutation, { data, loading, error }] = useUpdateTournamentTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentTeamMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentTeamMutation,
    UpdateTournamentTeamMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateTournamentTeamMutation, UpdateTournamentTeamMutationVariables>(
    UpdateTournamentTeamDocument,
    baseOptions
  );
}
export type UpdateTournamentTeamMutationHookResult = ReturnType<typeof useUpdateTournamentTeamMutation>;
export type UpdateTournamentTeamMutationResult = ApolloReactCommon.MutationResult<UpdateTournamentTeamMutation>;
export type UpdateTournamentTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTournamentTeamMutation,
  UpdateTournamentTeamMutationVariables
>;
export const UpdateTournamentTeamMemberDocument = gql`
  mutation updateTournamentTeamMember($id: Int!, $data: TournamentTeamMemberUpdateInput!) {
    updateTournamentTeamMember(id: $id, data: $data) {
      ...tournamentTeamMember
    }
  }
  ${TournamentTeamMemberFragmentDoc}
`;
export type UpdateTournamentTeamMemberMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTournamentTeamMemberMutation,
  UpdateTournamentTeamMemberMutationVariables
>;
export type UpdateTournamentTeamMemberComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateTournamentTeamMemberMutation,
    UpdateTournamentTeamMemberMutationVariables
  >,
  'mutation'
>;

export const UpdateTournamentTeamMemberComponent = (props: UpdateTournamentTeamMemberComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateTournamentTeamMemberMutation, UpdateTournamentTeamMemberMutationVariables>
    mutation={UpdateTournamentTeamMemberDocument}
    {...props}
  />
);

export type UpdateTournamentTeamMemberProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateTournamentTeamMemberMutation,
  UpdateTournamentTeamMemberMutationVariables
> &
  TChildProps;
export function withUpdateTournamentTeamMember<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateTournamentTeamMemberMutation,
    UpdateTournamentTeamMemberMutationVariables,
    UpdateTournamentTeamMemberProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateTournamentTeamMemberMutation,
    UpdateTournamentTeamMemberMutationVariables,
    UpdateTournamentTeamMemberProps<TChildProps>
  >(UpdateTournamentTeamMemberDocument, {
    alias: 'updateTournamentTeamMember',
    ...operationOptions,
  });
}

/**
 * __useUpdateTournamentTeamMemberMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentTeamMemberMutation, { data, loading, error }] = useUpdateTournamentTeamMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentTeamMemberMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentTeamMemberMutation,
    UpdateTournamentTeamMemberMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateTournamentTeamMemberMutation, UpdateTournamentTeamMemberMutationVariables>(
    UpdateTournamentTeamMemberDocument,
    baseOptions
  );
}
export type UpdateTournamentTeamMemberMutationHookResult = ReturnType<typeof useUpdateTournamentTeamMemberMutation>;
export type UpdateTournamentTeamMemberMutationResult = ApolloReactCommon.MutationResult<
  UpdateTournamentTeamMemberMutation
>;
export type UpdateTournamentTeamMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTournamentTeamMemberMutation,
  UpdateTournamentTeamMemberMutationVariables
>;
export const UpdateTournamentTourDocument = gql`
  mutation updateTournamentTour($id: Int!, $data: TournamentTourUpdateInput!) {
    updateTournamentTour(id: $id, data: $data) {
      ...tournamentTour
    }
  }
  ${TournamentTourFragmentDoc}
`;
export type UpdateTournamentTourMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTournamentTourMutation,
  UpdateTournamentTourMutationVariables
>;
export type UpdateTournamentTourComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateTournamentTourMutation, UpdateTournamentTourMutationVariables>,
  'mutation'
>;

export const UpdateTournamentTourComponent = (props: UpdateTournamentTourComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateTournamentTourMutation, UpdateTournamentTourMutationVariables>
    mutation={UpdateTournamentTourDocument}
    {...props}
  />
);

export type UpdateTournamentTourProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateTournamentTourMutation,
  UpdateTournamentTourMutationVariables
> &
  TChildProps;
export function withUpdateTournamentTour<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateTournamentTourMutation,
    UpdateTournamentTourMutationVariables,
    UpdateTournamentTourProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateTournamentTourMutation,
    UpdateTournamentTourMutationVariables,
    UpdateTournamentTourProps<TChildProps>
  >(UpdateTournamentTourDocument, {
    alias: 'updateTournamentTour',
    ...operationOptions,
  });
}

/**
 * __useUpdateTournamentTourMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentTourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentTourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentTourMutation, { data, loading, error }] = useUpdateTournamentTourMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentTourMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentTourMutation,
    UpdateTournamentTourMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateTournamentTourMutation, UpdateTournamentTourMutationVariables>(
    UpdateTournamentTourDocument,
    baseOptions
  );
}
export type UpdateTournamentTourMutationHookResult = ReturnType<typeof useUpdateTournamentTourMutation>;
export type UpdateTournamentTourMutationResult = ApolloReactCommon.MutationResult<UpdateTournamentTourMutation>;
export type UpdateTournamentTourMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTournamentTourMutation,
  UpdateTournamentTourMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser($id: Int!, $data: UserUpdateInput!) {
    updateUser(id: $id, data: $data) {
      ...user
    }
  }
  ${UserFragmentDoc}
`;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export type UpdateUserComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateUserMutation, UpdateUserMutationVariables>,
  'mutation'
>;

export const UpdateUserComponent = (props: UpdateUserComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateUserMutation, UpdateUserMutationVariables>
    mutation={UpdateUserDocument}
    {...props}
  />
);

export type UpdateUserProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateUserMutation,
  UpdateUserMutationVariables
> &
  TChildProps;
export function withUpdateUser<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateUserMutation,
    UpdateUserMutationVariables,
    UpdateUserProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateUserMutation,
    UpdateUserMutationVariables,
    UpdateUserProps<TChildProps>
  >(UpdateUserDocument, {
    alias: 'updateUser',
    ...operationOptions,
  });
}

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const UpdateUserApplicationDocument = gql`
  mutation updateUserApplication($id: Int!, $data: UserApplicationUpdateInput!) {
    updateUserApplication(id: $id, data: $data) {
      ...userApplication
    }
  }
  ${UserApplicationFragmentDoc}
`;
export type UpdateUserApplicationMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserApplicationMutation,
  UpdateUserApplicationMutationVariables
>;
export type UpdateUserApplicationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateUserApplicationMutation, UpdateUserApplicationMutationVariables>,
  'mutation'
>;

export const UpdateUserApplicationComponent = (props: UpdateUserApplicationComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateUserApplicationMutation, UpdateUserApplicationMutationVariables>
    mutation={UpdateUserApplicationDocument}
    {...props}
  />
);

export type UpdateUserApplicationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateUserApplicationMutation,
  UpdateUserApplicationMutationVariables
> &
  TChildProps;
export function withUpdateUserApplication<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateUserApplicationMutation,
    UpdateUserApplicationMutationVariables,
    UpdateUserApplicationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateUserApplicationMutation,
    UpdateUserApplicationMutationVariables,
    UpdateUserApplicationProps<TChildProps>
  >(UpdateUserApplicationDocument, {
    alias: 'updateUserApplication',
    ...operationOptions,
  });
}

/**
 * __useUpdateUserApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateUserApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserApplicationMutation, { data, loading, error }] = useUpdateUserApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserApplicationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserApplicationMutation,
    UpdateUserApplicationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateUserApplicationMutation, UpdateUserApplicationMutationVariables>(
    UpdateUserApplicationDocument,
    baseOptions
  );
}
export type UpdateUserApplicationMutationHookResult = ReturnType<typeof useUpdateUserApplicationMutation>;
export type UpdateUserApplicationMutationResult = ApolloReactCommon.MutationResult<UpdateUserApplicationMutation>;
export type UpdateUserApplicationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserApplicationMutation,
  UpdateUserApplicationMutationVariables
>;
export const UpdateUserApplicationFieldDocument = gql`
  mutation updateUserApplicationField($id: Int!, $data: UpdateExtensionFieldInput!) {
    updateUserApplicationField(id: $id, data: $data) {
      ...extensionField
    }
  }
  ${ExtensionFieldFragmentDoc}
`;
export type UpdateUserApplicationFieldMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserApplicationFieldMutation,
  UpdateUserApplicationFieldMutationVariables
>;
export type UpdateUserApplicationFieldComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateUserApplicationFieldMutation,
    UpdateUserApplicationFieldMutationVariables
  >,
  'mutation'
>;

export const UpdateUserApplicationFieldComponent = (props: UpdateUserApplicationFieldComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateUserApplicationFieldMutation, UpdateUserApplicationFieldMutationVariables>
    mutation={UpdateUserApplicationFieldDocument}
    {...props}
  />
);

export type UpdateUserApplicationFieldProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateUserApplicationFieldMutation,
  UpdateUserApplicationFieldMutationVariables
> &
  TChildProps;
export function withUpdateUserApplicationField<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateUserApplicationFieldMutation,
    UpdateUserApplicationFieldMutationVariables,
    UpdateUserApplicationFieldProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateUserApplicationFieldMutation,
    UpdateUserApplicationFieldMutationVariables,
    UpdateUserApplicationFieldProps<TChildProps>
  >(UpdateUserApplicationFieldDocument, {
    alias: 'updateUserApplicationField',
    ...operationOptions,
  });
}

/**
 * __useUpdateUserApplicationFieldMutation__
 *
 * To run a mutation, you first call `useUpdateUserApplicationFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserApplicationFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserApplicationFieldMutation, { data, loading, error }] = useUpdateUserApplicationFieldMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserApplicationFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserApplicationFieldMutation,
    UpdateUserApplicationFieldMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateUserApplicationFieldMutation, UpdateUserApplicationFieldMutationVariables>(
    UpdateUserApplicationFieldDocument,
    baseOptions
  );
}
export type UpdateUserApplicationFieldMutationHookResult = ReturnType<typeof useUpdateUserApplicationFieldMutation>;
export type UpdateUserApplicationFieldMutationResult = ApolloReactCommon.MutationResult<
  UpdateUserApplicationFieldMutation
>;
export type UpdateUserApplicationFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserApplicationFieldMutation,
  UpdateUserApplicationFieldMutationVariables
>;
export const UpdateUserApplicationTypeDocument = gql`
  mutation updateUserApplicationType($id: Int!, $data: UserApplicationTypeUpdateInput!) {
    updateUserApplicationType(id: $id, data: $data) {
      ...userApplicationType
    }
  }
  ${UserApplicationTypeFragmentDoc}
`;
export type UpdateUserApplicationTypeMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserApplicationTypeMutation,
  UpdateUserApplicationTypeMutationVariables
>;
export type UpdateUserApplicationTypeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateUserApplicationTypeMutation,
    UpdateUserApplicationTypeMutationVariables
  >,
  'mutation'
>;

export const UpdateUserApplicationTypeComponent = (props: UpdateUserApplicationTypeComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateUserApplicationTypeMutation, UpdateUserApplicationTypeMutationVariables>
    mutation={UpdateUserApplicationTypeDocument}
    {...props}
  />
);

export type UpdateUserApplicationTypeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateUserApplicationTypeMutation,
  UpdateUserApplicationTypeMutationVariables
> &
  TChildProps;
export function withUpdateUserApplicationType<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateUserApplicationTypeMutation,
    UpdateUserApplicationTypeMutationVariables,
    UpdateUserApplicationTypeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateUserApplicationTypeMutation,
    UpdateUserApplicationTypeMutationVariables,
    UpdateUserApplicationTypeProps<TChildProps>
  >(UpdateUserApplicationTypeDocument, {
    alias: 'updateUserApplicationType',
    ...operationOptions,
  });
}

/**
 * __useUpdateUserApplicationTypeMutation__
 *
 * To run a mutation, you first call `useUpdateUserApplicationTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserApplicationTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserApplicationTypeMutation, { data, loading, error }] = useUpdateUserApplicationTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserApplicationTypeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserApplicationTypeMutation,
    UpdateUserApplicationTypeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateUserApplicationTypeMutation, UpdateUserApplicationTypeMutationVariables>(
    UpdateUserApplicationTypeDocument,
    baseOptions
  );
}
export type UpdateUserApplicationTypeMutationHookResult = ReturnType<typeof useUpdateUserApplicationTypeMutation>;
export type UpdateUserApplicationTypeMutationResult = ApolloReactCommon.MutationResult<
  UpdateUserApplicationTypeMutation
>;
export type UpdateUserApplicationTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserApplicationTypeMutation,
  UpdateUserApplicationTypeMutationVariables
>;
export const UpdateUserGroupDocument = gql`
  mutation updateUserGroup($id: Int!, $data: UserGroupUpdateInput!) {
    updateUserGroup(id: $id, data: $data) {
      ...userGroup
    }
  }
  ${UserGroupFragmentDoc}
`;
export type UpdateUserGroupMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserGroupMutation,
  UpdateUserGroupMutationVariables
>;
export type UpdateUserGroupComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateUserGroupMutation, UpdateUserGroupMutationVariables>,
  'mutation'
>;

export const UpdateUserGroupComponent = (props: UpdateUserGroupComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateUserGroupMutation, UpdateUserGroupMutationVariables>
    mutation={UpdateUserGroupDocument}
    {...props}
  />
);

export type UpdateUserGroupProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateUserGroupMutation,
  UpdateUserGroupMutationVariables
> &
  TChildProps;
export function withUpdateUserGroup<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateUserGroupMutation,
    UpdateUserGroupMutationVariables,
    UpdateUserGroupProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateUserGroupMutation,
    UpdateUserGroupMutationVariables,
    UpdateUserGroupProps<TChildProps>
  >(UpdateUserGroupDocument, {
    alias: 'updateUserGroup',
    ...operationOptions,
  });
}

/**
 * __useUpdateUserGroupMutation__
 *
 * To run a mutation, you first call `useUpdateUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserGroupMutation, { data, loading, error }] = useUpdateUserGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserGroupMutation, UpdateUserGroupMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateUserGroupMutation, UpdateUserGroupMutationVariables>(
    UpdateUserGroupDocument,
    baseOptions
  );
}
export type UpdateUserGroupMutationHookResult = ReturnType<typeof useUpdateUserGroupMutation>;
export type UpdateUserGroupMutationResult = ApolloReactCommon.MutationResult<UpdateUserGroupMutation>;
export type UpdateUserGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserGroupMutation,
  UpdateUserGroupMutationVariables
>;
export const UpdateUserKidDocument = gql`
  mutation updateUserKid($id: Int!, $data: UserKidUpdateInput!) {
    updateUserKid(id: $id, data: $data) {
      id
    }
  }
`;
export type UpdateUserKidMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserKidMutation,
  UpdateUserKidMutationVariables
>;
export type UpdateUserKidComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateUserKidMutation, UpdateUserKidMutationVariables>,
  'mutation'
>;

export const UpdateUserKidComponent = (props: UpdateUserKidComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateUserKidMutation, UpdateUserKidMutationVariables>
    mutation={UpdateUserKidDocument}
    {...props}
  />
);

export type UpdateUserKidProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateUserKidMutation,
  UpdateUserKidMutationVariables
> &
  TChildProps;
export function withUpdateUserKid<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateUserKidMutation,
    UpdateUserKidMutationVariables,
    UpdateUserKidProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateUserKidMutation,
    UpdateUserKidMutationVariables,
    UpdateUserKidProps<TChildProps>
  >(UpdateUserKidDocument, {
    alias: 'updateUserKid',
    ...operationOptions,
  });
}

/**
 * __useUpdateUserKidMutation__
 *
 * To run a mutation, you first call `useUpdateUserKidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserKidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserKidMutation, { data, loading, error }] = useUpdateUserKidMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserKidMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserKidMutation, UpdateUserKidMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateUserKidMutation, UpdateUserKidMutationVariables>(
    UpdateUserKidDocument,
    baseOptions
  );
}
export type UpdateUserKidMutationHookResult = ReturnType<typeof useUpdateUserKidMutation>;
export type UpdateUserKidMutationResult = ApolloReactCommon.MutationResult<UpdateUserKidMutation>;
export type UpdateUserKidMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserKidMutation,
  UpdateUserKidMutationVariables
>;
export const UpdateUserSubscriberDocument = gql`
  mutation updateUserSubscriber($id: String!, $data: UserSubscriberUpdateInput!) {
    updateUserSubscriber(id: $id, data: $data) {
      ...userSubscriber
    }
  }
  ${UserSubscriberFragmentDoc}
`;
export type UpdateUserSubscriberMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserSubscriberMutation,
  UpdateUserSubscriberMutationVariables
>;
export type UpdateUserSubscriberComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateUserSubscriberMutation, UpdateUserSubscriberMutationVariables>,
  'mutation'
>;

export const UpdateUserSubscriberComponent = (props: UpdateUserSubscriberComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateUserSubscriberMutation, UpdateUserSubscriberMutationVariables>
    mutation={UpdateUserSubscriberDocument}
    {...props}
  />
);

export type UpdateUserSubscriberProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateUserSubscriberMutation,
  UpdateUserSubscriberMutationVariables
> &
  TChildProps;
export function withUpdateUserSubscriber<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateUserSubscriberMutation,
    UpdateUserSubscriberMutationVariables,
    UpdateUserSubscriberProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateUserSubscriberMutation,
    UpdateUserSubscriberMutationVariables,
    UpdateUserSubscriberProps<TChildProps>
  >(UpdateUserSubscriberDocument, {
    alias: 'updateUserSubscriber',
    ...operationOptions,
  });
}

/**
 * __useUpdateUserSubscriberMutation__
 *
 * To run a mutation, you first call `useUpdateUserSubscriberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSubscriberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSubscriberMutation, { data, loading, error }] = useUpdateUserSubscriberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserSubscriberMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserSubscriberMutation,
    UpdateUserSubscriberMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateUserSubscriberMutation, UpdateUserSubscriberMutationVariables>(
    UpdateUserSubscriberDocument,
    baseOptions
  );
}
export type UpdateUserSubscriberMutationHookResult = ReturnType<typeof useUpdateUserSubscriberMutation>;
export type UpdateUserSubscriberMutationResult = ApolloReactCommon.MutationResult<UpdateUserSubscriberMutation>;
export type UpdateUserSubscriberMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserSubscriberMutation,
  UpdateUserSubscriberMutationVariables
>;
export const UpdateUserSubscriptionDocument = gql`
  mutation updateUserSubscription($id: Int!, $data: UserSubscriptionUpdateInput!) {
    updateUserSubscription(id: $id, data: $data) {
      ...userSubscription
    }
  }
  ${UserSubscriptionFragmentDoc}
`;
export type UpdateUserSubscriptionMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserSubscriptionMutation,
  UpdateUserSubscriptionMutationVariables
>;
export type UpdateUserSubscriptionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateUserSubscriptionMutation,
    UpdateUserSubscriptionMutationVariables
  >,
  'mutation'
>;

export const UpdateUserSubscriptionComponent = (props: UpdateUserSubscriptionComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateUserSubscriptionMutation, UpdateUserSubscriptionMutationVariables>
    mutation={UpdateUserSubscriptionDocument}
    {...props}
  />
);

export type UpdateUserSubscriptionProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateUserSubscriptionMutation,
  UpdateUserSubscriptionMutationVariables
> &
  TChildProps;
export function withUpdateUserSubscription<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateUserSubscriptionMutation,
    UpdateUserSubscriptionMutationVariables,
    UpdateUserSubscriptionProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateUserSubscriptionMutation,
    UpdateUserSubscriptionMutationVariables,
    UpdateUserSubscriptionProps<TChildProps>
  >(UpdateUserSubscriptionDocument, {
    alias: 'updateUserSubscription',
    ...operationOptions,
  });
}

/**
 * __useUpdateUserSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateUserSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSubscriptionMutation, { data, loading, error }] = useUpdateUserSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserSubscriptionMutation,
    UpdateUserSubscriptionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateUserSubscriptionMutation, UpdateUserSubscriptionMutationVariables>(
    UpdateUserSubscriptionDocument,
    baseOptions
  );
}
export type UpdateUserSubscriptionMutationHookResult = ReturnType<typeof useUpdateUserSubscriptionMutation>;
export type UpdateUserSubscriptionMutationResult = ApolloReactCommon.MutationResult<UpdateUserSubscriptionMutation>;
export type UpdateUserSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserSubscriptionMutation,
  UpdateUserSubscriptionMutationVariables
>;
export const UpdateUserSubscriptionsDocument = gql`
  mutation updateUserSubscriptions($userId: Int!, $subscriptionId: [Int!]!) {
    updateUserSubscriptions(userId: $userId, subscriptionId: $subscriptionId) {
      total
      limit
      cursor
      edges {
        ...userSubscription
      }
    }
  }
  ${UserSubscriptionFragmentDoc}
`;
export type UpdateUserSubscriptionsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserSubscriptionsMutation,
  UpdateUserSubscriptionsMutationVariables
>;
export type UpdateUserSubscriptionsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateUserSubscriptionsMutation,
    UpdateUserSubscriptionsMutationVariables
  >,
  'mutation'
>;

export const UpdateUserSubscriptionsComponent = (props: UpdateUserSubscriptionsComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateUserSubscriptionsMutation, UpdateUserSubscriptionsMutationVariables>
    mutation={UpdateUserSubscriptionsDocument}
    {...props}
  />
);

export type UpdateUserSubscriptionsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateUserSubscriptionsMutation,
  UpdateUserSubscriptionsMutationVariables
> &
  TChildProps;
export function withUpdateUserSubscriptions<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateUserSubscriptionsMutation,
    UpdateUserSubscriptionsMutationVariables,
    UpdateUserSubscriptionsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateUserSubscriptionsMutation,
    UpdateUserSubscriptionsMutationVariables,
    UpdateUserSubscriptionsProps<TChildProps>
  >(UpdateUserSubscriptionsDocument, {
    alias: 'updateUserSubscriptions',
    ...operationOptions,
  });
}

/**
 * __useUpdateUserSubscriptionsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSubscriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSubscriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSubscriptionsMutation, { data, loading, error }] = useUpdateUserSubscriptionsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useUpdateUserSubscriptionsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserSubscriptionsMutation,
    UpdateUserSubscriptionsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateUserSubscriptionsMutation, UpdateUserSubscriptionsMutationVariables>(
    UpdateUserSubscriptionsDocument,
    baseOptions
  );
}
export type UpdateUserSubscriptionsMutationHookResult = ReturnType<typeof useUpdateUserSubscriptionsMutation>;
export type UpdateUserSubscriptionsMutationResult = ApolloReactCommon.MutationResult<UpdateUserSubscriptionsMutation>;
export type UpdateUserSubscriptionsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserSubscriptionsMutation,
  UpdateUserSubscriptionsMutationVariables
>;
export const UploadFileToImportUsersDocument = gql`
  mutation uploadFileToImportUsers($file: Upload!) {
    uploadFileToImportUsers(file: $file) {
      ...importUploadResponse
    }
  }
  ${ImportUploadResponseFragmentDoc}
`;
export type UploadFileToImportUsersMutationFn = ApolloReactCommon.MutationFunction<
  UploadFileToImportUsersMutation,
  UploadFileToImportUsersMutationVariables
>;
export type UploadFileToImportUsersComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UploadFileToImportUsersMutation,
    UploadFileToImportUsersMutationVariables
  >,
  'mutation'
>;

export const UploadFileToImportUsersComponent = (props: UploadFileToImportUsersComponentProps) => (
  <ApolloReactComponents.Mutation<UploadFileToImportUsersMutation, UploadFileToImportUsersMutationVariables>
    mutation={UploadFileToImportUsersDocument}
    {...props}
  />
);

export type UploadFileToImportUsersProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UploadFileToImportUsersMutation,
  UploadFileToImportUsersMutationVariables
> &
  TChildProps;
export function withUploadFileToImportUsers<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UploadFileToImportUsersMutation,
    UploadFileToImportUsersMutationVariables,
    UploadFileToImportUsersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UploadFileToImportUsersMutation,
    UploadFileToImportUsersMutationVariables,
    UploadFileToImportUsersProps<TChildProps>
  >(UploadFileToImportUsersDocument, {
    alias: 'uploadFileToImportUsers',
    ...operationOptions,
  });
}

/**
 * __useUploadFileToImportUsersMutation__
 *
 * To run a mutation, you first call `useUploadFileToImportUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileToImportUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileToImportUsersMutation, { data, loading, error }] = useUploadFileToImportUsersMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileToImportUsersMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UploadFileToImportUsersMutation,
    UploadFileToImportUsersMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UploadFileToImportUsersMutation, UploadFileToImportUsersMutationVariables>(
    UploadFileToImportUsersDocument,
    baseOptions
  );
}
export type UploadFileToImportUsersMutationHookResult = ReturnType<typeof useUploadFileToImportUsersMutation>;
export type UploadFileToImportUsersMutationResult = ApolloReactCommon.MutationResult<UploadFileToImportUsersMutation>;
export type UploadFileToImportUsersMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UploadFileToImportUsersMutation,
  UploadFileToImportUsersMutationVariables
>;
export const AclDocument = gql`
  query acl {
    acl {
      ...acl
    }
  }
  ${AclFragmentDoc}
`;
export type AclComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AclQuery, AclQueryVariables>, 'query'>;

export const AclComponent = (props: AclComponentProps) => (
  <ApolloReactComponents.Query<AclQuery, AclQueryVariables> query={AclDocument} {...props} />
);

export type AclProps<TChildProps = {}> = ApolloReactHoc.DataProps<AclQuery, AclQueryVariables> & TChildProps;
export function withAcl<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<TProps, AclQuery, AclQueryVariables, AclProps<TChildProps>>
) {
  return ApolloReactHoc.withQuery<TProps, AclQuery, AclQueryVariables, AclProps<TChildProps>>(AclDocument, {
    alias: 'acl',
    ...operationOptions,
  });
}

/**
 * __useAclQuery__
 *
 * To run a query within a React component, call `useAclQuery` and pass it any options that fit your needs.
 * When your component renders, `useAclQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAclQuery({
 *   variables: {
 *   },
 * });
 */
export function useAclQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AclQuery, AclQueryVariables>) {
  return ApolloReactHooks.useQuery<AclQuery, AclQueryVariables>(AclDocument, baseOptions);
}
export function useAclLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AclQuery, AclQueryVariables>) {
  return ApolloReactHooks.useLazyQuery<AclQuery, AclQueryVariables>(AclDocument, baseOptions);
}
export type AclQueryHookResult = ReturnType<typeof useAclQuery>;
export type AclLazyQueryHookResult = ReturnType<typeof useAclLazyQuery>;
export type AclQueryResult = ApolloReactCommon.QueryResult<AclQuery, AclQueryVariables>;
export const AllArticleAuthorsDocument = gql`
  query allArticleAuthors($query: DefaultQueryInput) {
    allArticleAuthors(query: $query) {
      ...articleAuthorConnection
    }
  }
  ${ArticleAuthorConnectionFragmentDoc}
`;
export type AllArticleAuthorsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllArticleAuthorsQuery, AllArticleAuthorsQueryVariables>,
  'query'
>;

export const AllArticleAuthorsComponent = (props: AllArticleAuthorsComponentProps) => (
  <ApolloReactComponents.Query<AllArticleAuthorsQuery, AllArticleAuthorsQueryVariables>
    query={AllArticleAuthorsDocument}
    {...props}
  />
);

export type AllArticleAuthorsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllArticleAuthorsQuery,
  AllArticleAuthorsQueryVariables
> &
  TChildProps;
export function withAllArticleAuthors<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllArticleAuthorsQuery,
    AllArticleAuthorsQueryVariables,
    AllArticleAuthorsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllArticleAuthorsQuery,
    AllArticleAuthorsQueryVariables,
    AllArticleAuthorsProps<TChildProps>
  >(AllArticleAuthorsDocument, {
    alias: 'allArticleAuthors',
    ...operationOptions,
  });
}

/**
 * __useAllArticleAuthorsQuery__
 *
 * To run a query within a React component, call `useAllArticleAuthorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllArticleAuthorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllArticleAuthorsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllArticleAuthorsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllArticleAuthorsQuery, AllArticleAuthorsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllArticleAuthorsQuery, AllArticleAuthorsQueryVariables>(
    AllArticleAuthorsDocument,
    baseOptions
  );
}
export function useAllArticleAuthorsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllArticleAuthorsQuery, AllArticleAuthorsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllArticleAuthorsQuery, AllArticleAuthorsQueryVariables>(
    AllArticleAuthorsDocument,
    baseOptions
  );
}
export type AllArticleAuthorsQueryHookResult = ReturnType<typeof useAllArticleAuthorsQuery>;
export type AllArticleAuthorsLazyQueryHookResult = ReturnType<typeof useAllArticleAuthorsLazyQuery>;
export type AllArticleAuthorsQueryResult = ApolloReactCommon.QueryResult<
  AllArticleAuthorsQuery,
  AllArticleAuthorsQueryVariables
>;
export const AllArticleBlockAuthorsDocument = gql`
  query allArticleBlockAuthors($query: DefaultQueryInput) {
    allArticleBlockAuthors(query: $query) {
      ...articleBlockAuthorsConnection
    }
  }
  ${ArticleBlockAuthorsConnectionFragmentDoc}
`;
export type AllArticleBlockAuthorsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllArticleBlockAuthorsQuery, AllArticleBlockAuthorsQueryVariables>,
  'query'
>;

export const AllArticleBlockAuthorsComponent = (props: AllArticleBlockAuthorsComponentProps) => (
  <ApolloReactComponents.Query<AllArticleBlockAuthorsQuery, AllArticleBlockAuthorsQueryVariables>
    query={AllArticleBlockAuthorsDocument}
    {...props}
  />
);

export type AllArticleBlockAuthorsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllArticleBlockAuthorsQuery,
  AllArticleBlockAuthorsQueryVariables
> &
  TChildProps;
export function withAllArticleBlockAuthors<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllArticleBlockAuthorsQuery,
    AllArticleBlockAuthorsQueryVariables,
    AllArticleBlockAuthorsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllArticleBlockAuthorsQuery,
    AllArticleBlockAuthorsQueryVariables,
    AllArticleBlockAuthorsProps<TChildProps>
  >(AllArticleBlockAuthorsDocument, {
    alias: 'allArticleBlockAuthors',
    ...operationOptions,
  });
}

/**
 * __useAllArticleBlockAuthorsQuery__
 *
 * To run a query within a React component, call `useAllArticleBlockAuthorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllArticleBlockAuthorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllArticleBlockAuthorsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllArticleBlockAuthorsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllArticleBlockAuthorsQuery, AllArticleBlockAuthorsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllArticleBlockAuthorsQuery, AllArticleBlockAuthorsQueryVariables>(
    AllArticleBlockAuthorsDocument,
    baseOptions
  );
}
export function useAllArticleBlockAuthorsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllArticleBlockAuthorsQuery, AllArticleBlockAuthorsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllArticleBlockAuthorsQuery, AllArticleBlockAuthorsQueryVariables>(
    AllArticleBlockAuthorsDocument,
    baseOptions
  );
}
export type AllArticleBlockAuthorsQueryHookResult = ReturnType<typeof useAllArticleBlockAuthorsQuery>;
export type AllArticleBlockAuthorsLazyQueryHookResult = ReturnType<typeof useAllArticleBlockAuthorsLazyQuery>;
export type AllArticleBlockAuthorsQueryResult = ApolloReactCommon.QueryResult<
  AllArticleBlockAuthorsQuery,
  AllArticleBlockAuthorsQueryVariables
>;
export const AllArticleBlocksDocument = gql`
  query allArticleBlocks($query: DefaultQueryInput) {
    allArticleBlocks(query: $query) {
      ...articleBlockConnection
    }
  }
  ${ArticleBlockConnectionFragmentDoc}
`;
export type AllArticleBlocksComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllArticleBlocksQuery, AllArticleBlocksQueryVariables>,
  'query'
>;

export const AllArticleBlocksComponent = (props: AllArticleBlocksComponentProps) => (
  <ApolloReactComponents.Query<AllArticleBlocksQuery, AllArticleBlocksQueryVariables>
    query={AllArticleBlocksDocument}
    {...props}
  />
);

export type AllArticleBlocksProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllArticleBlocksQuery,
  AllArticleBlocksQueryVariables
> &
  TChildProps;
export function withAllArticleBlocks<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllArticleBlocksQuery,
    AllArticleBlocksQueryVariables,
    AllArticleBlocksProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllArticleBlocksQuery,
    AllArticleBlocksQueryVariables,
    AllArticleBlocksProps<TChildProps>
  >(AllArticleBlocksDocument, {
    alias: 'allArticleBlocks',
    ...operationOptions,
  });
}

/**
 * __useAllArticleBlocksQuery__
 *
 * To run a query within a React component, call `useAllArticleBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllArticleBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllArticleBlocksQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllArticleBlocksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllArticleBlocksQuery, AllArticleBlocksQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllArticleBlocksQuery, AllArticleBlocksQueryVariables>(
    AllArticleBlocksDocument,
    baseOptions
  );
}
export function useAllArticleBlocksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllArticleBlocksQuery, AllArticleBlocksQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllArticleBlocksQuery, AllArticleBlocksQueryVariables>(
    AllArticleBlocksDocument,
    baseOptions
  );
}
export type AllArticleBlocksQueryHookResult = ReturnType<typeof useAllArticleBlocksQuery>;
export type AllArticleBlocksLazyQueryHookResult = ReturnType<typeof useAllArticleBlocksLazyQuery>;
export type AllArticleBlocksQueryResult = ApolloReactCommon.QueryResult<
  AllArticleBlocksQuery,
  AllArticleBlocksQueryVariables
>;
export const AllArticleCategoriesDocument = gql`
  query allArticleCategories($query: DefaultQueryInput) {
    allArticleCategories(query: $query) {
      ...articleCategoryConnection
    }
  }
  ${ArticleCategoryConnectionFragmentDoc}
`;
export type AllArticleCategoriesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllArticleCategoriesQuery, AllArticleCategoriesQueryVariables>,
  'query'
>;

export const AllArticleCategoriesComponent = (props: AllArticleCategoriesComponentProps) => (
  <ApolloReactComponents.Query<AllArticleCategoriesQuery, AllArticleCategoriesQueryVariables>
    query={AllArticleCategoriesDocument}
    {...props}
  />
);

export type AllArticleCategoriesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllArticleCategoriesQuery,
  AllArticleCategoriesQueryVariables
> &
  TChildProps;
export function withAllArticleCategories<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllArticleCategoriesQuery,
    AllArticleCategoriesQueryVariables,
    AllArticleCategoriesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllArticleCategoriesQuery,
    AllArticleCategoriesQueryVariables,
    AllArticleCategoriesProps<TChildProps>
  >(AllArticleCategoriesDocument, {
    alias: 'allArticleCategories',
    ...operationOptions,
  });
}

/**
 * __useAllArticleCategoriesQuery__
 *
 * To run a query within a React component, call `useAllArticleCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllArticleCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllArticleCategoriesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllArticleCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllArticleCategoriesQuery, AllArticleCategoriesQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllArticleCategoriesQuery, AllArticleCategoriesQueryVariables>(
    AllArticleCategoriesDocument,
    baseOptions
  );
}
export function useAllArticleCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllArticleCategoriesQuery, AllArticleCategoriesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllArticleCategoriesQuery, AllArticleCategoriesQueryVariables>(
    AllArticleCategoriesDocument,
    baseOptions
  );
}
export type AllArticleCategoriesQueryHookResult = ReturnType<typeof useAllArticleCategoriesQuery>;
export type AllArticleCategoriesLazyQueryHookResult = ReturnType<typeof useAllArticleCategoriesLazyQuery>;
export type AllArticleCategoriesQueryResult = ApolloReactCommon.QueryResult<
  AllArticleCategoriesQuery,
  AllArticleCategoriesQueryVariables
>;
export const AllEntityRecordsByArticleLinkTypeDocument = gql`
  query allEntityRecordsByArticleLinkType($query: DefaultQueryInput) {
    allEntityRecordsByArticleLinkType(query: $query) {
      ...articleLinkEntityTypeConnection
    }
  }
  ${ArticleLinkEntityTypeConnectionFragmentDoc}
`;
export type AllEntityRecordsByArticleLinkTypeComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AllEntityRecordsByArticleLinkTypeQuery,
    AllEntityRecordsByArticleLinkTypeQueryVariables
  >,
  'query'
>;

export const AllEntityRecordsByArticleLinkTypeComponent = (props: AllEntityRecordsByArticleLinkTypeComponentProps) => (
  <ApolloReactComponents.Query<AllEntityRecordsByArticleLinkTypeQuery, AllEntityRecordsByArticleLinkTypeQueryVariables>
    query={AllEntityRecordsByArticleLinkTypeDocument}
    {...props}
  />
);

export type AllEntityRecordsByArticleLinkTypeProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllEntityRecordsByArticleLinkTypeQuery,
  AllEntityRecordsByArticleLinkTypeQueryVariables
> &
  TChildProps;
export function withAllEntityRecordsByArticleLinkType<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllEntityRecordsByArticleLinkTypeQuery,
    AllEntityRecordsByArticleLinkTypeQueryVariables,
    AllEntityRecordsByArticleLinkTypeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllEntityRecordsByArticleLinkTypeQuery,
    AllEntityRecordsByArticleLinkTypeQueryVariables,
    AllEntityRecordsByArticleLinkTypeProps<TChildProps>
  >(AllEntityRecordsByArticleLinkTypeDocument, {
    alias: 'allEntityRecordsByArticleLinkType',
    ...operationOptions,
  });
}

/**
 * __useAllEntityRecordsByArticleLinkTypeQuery__
 *
 * To run a query within a React component, call `useAllEntityRecordsByArticleLinkTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEntityRecordsByArticleLinkTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEntityRecordsByArticleLinkTypeQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllEntityRecordsByArticleLinkTypeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllEntityRecordsByArticleLinkTypeQuery,
    AllEntityRecordsByArticleLinkTypeQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AllEntityRecordsByArticleLinkTypeQuery,
    AllEntityRecordsByArticleLinkTypeQueryVariables
  >(AllEntityRecordsByArticleLinkTypeDocument, baseOptions);
}
export function useAllEntityRecordsByArticleLinkTypeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllEntityRecordsByArticleLinkTypeQuery,
    AllEntityRecordsByArticleLinkTypeQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AllEntityRecordsByArticleLinkTypeQuery,
    AllEntityRecordsByArticleLinkTypeQueryVariables
  >(AllEntityRecordsByArticleLinkTypeDocument, baseOptions);
}
export type AllEntityRecordsByArticleLinkTypeQueryHookResult = ReturnType<
  typeof useAllEntityRecordsByArticleLinkTypeQuery
>;
export type AllEntityRecordsByArticleLinkTypeLazyQueryHookResult = ReturnType<
  typeof useAllEntityRecordsByArticleLinkTypeLazyQuery
>;
export type AllEntityRecordsByArticleLinkTypeQueryResult = ApolloReactCommon.QueryResult<
  AllEntityRecordsByArticleLinkTypeQuery,
  AllEntityRecordsByArticleLinkTypeQueryVariables
>;
export const AllArticleLinksDocument = gql`
  query allArticleLinks($query: DefaultQueryInput) {
    allArticleLinks(query: $query) {
      ...articleLinkConnection
    }
  }
  ${ArticleLinkConnectionFragmentDoc}
`;
export type AllArticleLinksComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllArticleLinksQuery, AllArticleLinksQueryVariables>,
  'query'
>;

export const AllArticleLinksComponent = (props: AllArticleLinksComponentProps) => (
  <ApolloReactComponents.Query<AllArticleLinksQuery, AllArticleLinksQueryVariables>
    query={AllArticleLinksDocument}
    {...props}
  />
);

export type AllArticleLinksProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllArticleLinksQuery,
  AllArticleLinksQueryVariables
> &
  TChildProps;
export function withAllArticleLinks<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllArticleLinksQuery,
    AllArticleLinksQueryVariables,
    AllArticleLinksProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllArticleLinksQuery,
    AllArticleLinksQueryVariables,
    AllArticleLinksProps<TChildProps>
  >(AllArticleLinksDocument, {
    alias: 'allArticleLinks',
    ...operationOptions,
  });
}

/**
 * __useAllArticleLinksQuery__
 *
 * To run a query within a React component, call `useAllArticleLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllArticleLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllArticleLinksQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllArticleLinksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllArticleLinksQuery, AllArticleLinksQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllArticleLinksQuery, AllArticleLinksQueryVariables>(
    AllArticleLinksDocument,
    baseOptions
  );
}
export function useAllArticleLinksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllArticleLinksQuery, AllArticleLinksQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllArticleLinksQuery, AllArticleLinksQueryVariables>(
    AllArticleLinksDocument,
    baseOptions
  );
}
export type AllArticleLinksQueryHookResult = ReturnType<typeof useAllArticleLinksQuery>;
export type AllArticleLinksLazyQueryHookResult = ReturnType<typeof useAllArticleLinksLazyQuery>;
export type AllArticleLinksQueryResult = ApolloReactCommon.QueryResult<
  AllArticleLinksQuery,
  AllArticleLinksQueryVariables
>;
export const AllArticleTagsDocument = gql`
  query allArticleTags($query: DefaultQueryInput) {
    allArticleTags(query: $query) {
      ...articleTagConnection
    }
  }
  ${ArticleTagConnectionFragmentDoc}
`;
export type AllArticleTagsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllArticleTagsQuery, AllArticleTagsQueryVariables>,
  'query'
>;

export const AllArticleTagsComponent = (props: AllArticleTagsComponentProps) => (
  <ApolloReactComponents.Query<AllArticleTagsQuery, AllArticleTagsQueryVariables>
    query={AllArticleTagsDocument}
    {...props}
  />
);

export type AllArticleTagsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllArticleTagsQuery,
  AllArticleTagsQueryVariables
> &
  TChildProps;
export function withAllArticleTags<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllArticleTagsQuery,
    AllArticleTagsQueryVariables,
    AllArticleTagsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllArticleTagsQuery,
    AllArticleTagsQueryVariables,
    AllArticleTagsProps<TChildProps>
  >(AllArticleTagsDocument, {
    alias: 'allArticleTags',
    ...operationOptions,
  });
}

/**
 * __useAllArticleTagsQuery__
 *
 * To run a query within a React component, call `useAllArticleTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllArticleTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllArticleTagsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllArticleTagsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllArticleTagsQuery, AllArticleTagsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllArticleTagsQuery, AllArticleTagsQueryVariables>(
    AllArticleTagsDocument,
    baseOptions
  );
}
export function useAllArticleTagsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllArticleTagsQuery, AllArticleTagsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllArticleTagsQuery, AllArticleTagsQueryVariables>(
    AllArticleTagsDocument,
    baseOptions
  );
}
export type AllArticleTagsQueryHookResult = ReturnType<typeof useAllArticleTagsQuery>;
export type AllArticleTagsLazyQueryHookResult = ReturnType<typeof useAllArticleTagsLazyQuery>;
export type AllArticleTagsQueryResult = ApolloReactCommon.QueryResult<
  AllArticleTagsQuery,
  AllArticleTagsQueryVariables
>;
export const AllArticlesDocument = gql`
  query allArticles($query: DefaultQueryInput) {
    allArticles(query: $query) {
      ...articlesConnection
    }
  }
  ${ArticlesConnectionFragmentDoc}
`;
export type AllArticlesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllArticlesQuery, AllArticlesQueryVariables>,
  'query'
>;

export const AllArticlesComponent = (props: AllArticlesComponentProps) => (
  <ApolloReactComponents.Query<AllArticlesQuery, AllArticlesQueryVariables> query={AllArticlesDocument} {...props} />
);

export type AllArticlesProps<TChildProps = {}> = ApolloReactHoc.DataProps<AllArticlesQuery, AllArticlesQueryVariables> &
  TChildProps;
export function withAllArticles<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllArticlesQuery,
    AllArticlesQueryVariables,
    AllArticlesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<TProps, AllArticlesQuery, AllArticlesQueryVariables, AllArticlesProps<TChildProps>>(
    AllArticlesDocument,
    {
      alias: 'allArticles',
      ...operationOptions,
    }
  );
}

/**
 * __useAllArticlesQuery__
 *
 * To run a query within a React component, call `useAllArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllArticlesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllArticlesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllArticlesQuery, AllArticlesQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllArticlesQuery, AllArticlesQueryVariables>(AllArticlesDocument, baseOptions);
}
export function useAllArticlesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllArticlesQuery, AllArticlesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllArticlesQuery, AllArticlesQueryVariables>(AllArticlesDocument, baseOptions);
}
export type AllArticlesQueryHookResult = ReturnType<typeof useAllArticlesQuery>;
export type AllArticlesLazyQueryHookResult = ReturnType<typeof useAllArticlesLazyQuery>;
export type AllArticlesQueryResult = ApolloReactCommon.QueryResult<AllArticlesQuery, AllArticlesQueryVariables>;
export const AllCatalogueCategoriesDocument = gql`
  query allCatalogueCategories($query: DefaultQueryInput) {
    allCatalogueCategories(query: $query) {
      ...catalogueCategoryConnection
    }
  }
  ${CatalogueCategoryConnectionFragmentDoc}
`;
export type AllCatalogueCategoriesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllCatalogueCategoriesQuery, AllCatalogueCategoriesQueryVariables>,
  'query'
>;

export const AllCatalogueCategoriesComponent = (props: AllCatalogueCategoriesComponentProps) => (
  <ApolloReactComponents.Query<AllCatalogueCategoriesQuery, AllCatalogueCategoriesQueryVariables>
    query={AllCatalogueCategoriesDocument}
    {...props}
  />
);

export type AllCatalogueCategoriesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllCatalogueCategoriesQuery,
  AllCatalogueCategoriesQueryVariables
> &
  TChildProps;
export function withAllCatalogueCategories<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllCatalogueCategoriesQuery,
    AllCatalogueCategoriesQueryVariables,
    AllCatalogueCategoriesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllCatalogueCategoriesQuery,
    AllCatalogueCategoriesQueryVariables,
    AllCatalogueCategoriesProps<TChildProps>
  >(AllCatalogueCategoriesDocument, {
    alias: 'allCatalogueCategories',
    ...operationOptions,
  });
}

/**
 * __useAllCatalogueCategoriesQuery__
 *
 * To run a query within a React component, call `useAllCatalogueCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCatalogueCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCatalogueCategoriesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCatalogueCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllCatalogueCategoriesQuery, AllCatalogueCategoriesQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllCatalogueCategoriesQuery, AllCatalogueCategoriesQueryVariables>(
    AllCatalogueCategoriesDocument,
    baseOptions
  );
}
export function useAllCatalogueCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCatalogueCategoriesQuery, AllCatalogueCategoriesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllCatalogueCategoriesQuery, AllCatalogueCategoriesQueryVariables>(
    AllCatalogueCategoriesDocument,
    baseOptions
  );
}
export type AllCatalogueCategoriesQueryHookResult = ReturnType<typeof useAllCatalogueCategoriesQuery>;
export type AllCatalogueCategoriesLazyQueryHookResult = ReturnType<typeof useAllCatalogueCategoriesLazyQuery>;
export type AllCatalogueCategoriesQueryResult = ApolloReactCommon.QueryResult<
  AllCatalogueCategoriesQuery,
  AllCatalogueCategoriesQueryVariables
>;
export const AllCatalogueCategoryFieldsDocument = gql`
  query allCatalogueCategoryFields($query: DefaultQueryInput) {
    allCatalogueCategoryFields(query: $query) {
      total
      edges {
        ...extensionFieldContentTranslation
      }
    }
  }
  ${ExtensionFieldContentTranslationFragmentDoc}
`;
export type AllCatalogueCategoryFieldsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AllCatalogueCategoryFieldsQuery,
    AllCatalogueCategoryFieldsQueryVariables
  >,
  'query'
>;

export const AllCatalogueCategoryFieldsComponent = (props: AllCatalogueCategoryFieldsComponentProps) => (
  <ApolloReactComponents.Query<AllCatalogueCategoryFieldsQuery, AllCatalogueCategoryFieldsQueryVariables>
    query={AllCatalogueCategoryFieldsDocument}
    {...props}
  />
);

export type AllCatalogueCategoryFieldsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllCatalogueCategoryFieldsQuery,
  AllCatalogueCategoryFieldsQueryVariables
> &
  TChildProps;
export function withAllCatalogueCategoryFields<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllCatalogueCategoryFieldsQuery,
    AllCatalogueCategoryFieldsQueryVariables,
    AllCatalogueCategoryFieldsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllCatalogueCategoryFieldsQuery,
    AllCatalogueCategoryFieldsQueryVariables,
    AllCatalogueCategoryFieldsProps<TChildProps>
  >(AllCatalogueCategoryFieldsDocument, {
    alias: 'allCatalogueCategoryFields',
    ...operationOptions,
  });
}

/**
 * __useAllCatalogueCategoryFieldsQuery__
 *
 * To run a query within a React component, call `useAllCatalogueCategoryFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCatalogueCategoryFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCatalogueCategoryFieldsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCatalogueCategoryFieldsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllCatalogueCategoryFieldsQuery,
    AllCatalogueCategoryFieldsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<AllCatalogueCategoryFieldsQuery, AllCatalogueCategoryFieldsQueryVariables>(
    AllCatalogueCategoryFieldsDocument,
    baseOptions
  );
}
export function useAllCatalogueCategoryFieldsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllCatalogueCategoryFieldsQuery,
    AllCatalogueCategoryFieldsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<AllCatalogueCategoryFieldsQuery, AllCatalogueCategoryFieldsQueryVariables>(
    AllCatalogueCategoryFieldsDocument,
    baseOptions
  );
}
export type AllCatalogueCategoryFieldsQueryHookResult = ReturnType<typeof useAllCatalogueCategoryFieldsQuery>;
export type AllCatalogueCategoryFieldsLazyQueryHookResult = ReturnType<typeof useAllCatalogueCategoryFieldsLazyQuery>;
export type AllCatalogueCategoryFieldsQueryResult = ApolloReactCommon.QueryResult<
  AllCatalogueCategoryFieldsQuery,
  AllCatalogueCategoryFieldsQueryVariables
>;
export const AllCatalogueFieldsDocument = gql`
  query allCatalogueFields($query: DefaultQueryInput) {
    allCatalogueFields(query: $query) {
      total
      edges {
        ...extensionFieldContentTranslation
      }
    }
  }
  ${ExtensionFieldContentTranslationFragmentDoc}
`;
export type AllCatalogueFieldsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllCatalogueFieldsQuery, AllCatalogueFieldsQueryVariables>,
  'query'
>;

export const AllCatalogueFieldsComponent = (props: AllCatalogueFieldsComponentProps) => (
  <ApolloReactComponents.Query<AllCatalogueFieldsQuery, AllCatalogueFieldsQueryVariables>
    query={AllCatalogueFieldsDocument}
    {...props}
  />
);

export type AllCatalogueFieldsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllCatalogueFieldsQuery,
  AllCatalogueFieldsQueryVariables
> &
  TChildProps;
export function withAllCatalogueFields<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllCatalogueFieldsQuery,
    AllCatalogueFieldsQueryVariables,
    AllCatalogueFieldsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllCatalogueFieldsQuery,
    AllCatalogueFieldsQueryVariables,
    AllCatalogueFieldsProps<TChildProps>
  >(AllCatalogueFieldsDocument, {
    alias: 'allCatalogueFields',
    ...operationOptions,
  });
}

/**
 * __useAllCatalogueFieldsQuery__
 *
 * To run a query within a React component, call `useAllCatalogueFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCatalogueFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCatalogueFieldsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCatalogueFieldsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllCatalogueFieldsQuery, AllCatalogueFieldsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllCatalogueFieldsQuery, AllCatalogueFieldsQueryVariables>(
    AllCatalogueFieldsDocument,
    baseOptions
  );
}
export function useAllCatalogueFieldsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCatalogueFieldsQuery, AllCatalogueFieldsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllCatalogueFieldsQuery, AllCatalogueFieldsQueryVariables>(
    AllCatalogueFieldsDocument,
    baseOptions
  );
}
export type AllCatalogueFieldsQueryHookResult = ReturnType<typeof useAllCatalogueFieldsQuery>;
export type AllCatalogueFieldsLazyQueryHookResult = ReturnType<typeof useAllCatalogueFieldsLazyQuery>;
export type AllCatalogueFieldsQueryResult = ApolloReactCommon.QueryResult<
  AllCatalogueFieldsQuery,
  AllCatalogueFieldsQueryVariables
>;
export const AllCatalogueItemFieldsDocument = gql`
  query allCatalogueItemFields($query: DefaultQueryInput) {
    allCatalogueItemFields(query: $query) {
      ...catalogueItemFieldsConnection
    }
  }
  ${CatalogueItemFieldsConnectionFragmentDoc}
`;
export type AllCatalogueItemFieldsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllCatalogueItemFieldsQuery, AllCatalogueItemFieldsQueryVariables>,
  'query'
>;

export const AllCatalogueItemFieldsComponent = (props: AllCatalogueItemFieldsComponentProps) => (
  <ApolloReactComponents.Query<AllCatalogueItemFieldsQuery, AllCatalogueItemFieldsQueryVariables>
    query={AllCatalogueItemFieldsDocument}
    {...props}
  />
);

export type AllCatalogueItemFieldsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllCatalogueItemFieldsQuery,
  AllCatalogueItemFieldsQueryVariables
> &
  TChildProps;
export function withAllCatalogueItemFields<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllCatalogueItemFieldsQuery,
    AllCatalogueItemFieldsQueryVariables,
    AllCatalogueItemFieldsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllCatalogueItemFieldsQuery,
    AllCatalogueItemFieldsQueryVariables,
    AllCatalogueItemFieldsProps<TChildProps>
  >(AllCatalogueItemFieldsDocument, {
    alias: 'allCatalogueItemFields',
    ...operationOptions,
  });
}

/**
 * __useAllCatalogueItemFieldsQuery__
 *
 * To run a query within a React component, call `useAllCatalogueItemFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCatalogueItemFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCatalogueItemFieldsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCatalogueItemFieldsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllCatalogueItemFieldsQuery, AllCatalogueItemFieldsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllCatalogueItemFieldsQuery, AllCatalogueItemFieldsQueryVariables>(
    AllCatalogueItemFieldsDocument,
    baseOptions
  );
}
export function useAllCatalogueItemFieldsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCatalogueItemFieldsQuery, AllCatalogueItemFieldsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllCatalogueItemFieldsQuery, AllCatalogueItemFieldsQueryVariables>(
    AllCatalogueItemFieldsDocument,
    baseOptions
  );
}
export type AllCatalogueItemFieldsQueryHookResult = ReturnType<typeof useAllCatalogueItemFieldsQuery>;
export type AllCatalogueItemFieldsLazyQueryHookResult = ReturnType<typeof useAllCatalogueItemFieldsLazyQuery>;
export type AllCatalogueItemFieldsQueryResult = ApolloReactCommon.QueryResult<
  AllCatalogueItemFieldsQuery,
  AllCatalogueItemFieldsQueryVariables
>;
export const AllCatalogueItemPricesDocument = gql`
  query allCatalogueItemPrices($query: DefaultQueryInput) {
    allCatalogueItemPrices(query: $query) {
      ...catalogueItemPriceConnection
    }
  }
  ${CatalogueItemPriceConnectionFragmentDoc}
`;
export type AllCatalogueItemPricesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllCatalogueItemPricesQuery, AllCatalogueItemPricesQueryVariables>,
  'query'
>;

export const AllCatalogueItemPricesComponent = (props: AllCatalogueItemPricesComponentProps) => (
  <ApolloReactComponents.Query<AllCatalogueItemPricesQuery, AllCatalogueItemPricesQueryVariables>
    query={AllCatalogueItemPricesDocument}
    {...props}
  />
);

export type AllCatalogueItemPricesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllCatalogueItemPricesQuery,
  AllCatalogueItemPricesQueryVariables
> &
  TChildProps;
export function withAllCatalogueItemPrices<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllCatalogueItemPricesQuery,
    AllCatalogueItemPricesQueryVariables,
    AllCatalogueItemPricesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllCatalogueItemPricesQuery,
    AllCatalogueItemPricesQueryVariables,
    AllCatalogueItemPricesProps<TChildProps>
  >(AllCatalogueItemPricesDocument, {
    alias: 'allCatalogueItemPrices',
    ...operationOptions,
  });
}

/**
 * __useAllCatalogueItemPricesQuery__
 *
 * To run a query within a React component, call `useAllCatalogueItemPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCatalogueItemPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCatalogueItemPricesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCatalogueItemPricesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllCatalogueItemPricesQuery, AllCatalogueItemPricesQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllCatalogueItemPricesQuery, AllCatalogueItemPricesQueryVariables>(
    AllCatalogueItemPricesDocument,
    baseOptions
  );
}
export function useAllCatalogueItemPricesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCatalogueItemPricesQuery, AllCatalogueItemPricesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllCatalogueItemPricesQuery, AllCatalogueItemPricesQueryVariables>(
    AllCatalogueItemPricesDocument,
    baseOptions
  );
}
export type AllCatalogueItemPricesQueryHookResult = ReturnType<typeof useAllCatalogueItemPricesQuery>;
export type AllCatalogueItemPricesLazyQueryHookResult = ReturnType<typeof useAllCatalogueItemPricesLazyQuery>;
export type AllCatalogueItemPricesQueryResult = ApolloReactCommon.QueryResult<
  AllCatalogueItemPricesQuery,
  AllCatalogueItemPricesQueryVariables
>;
export const AllCatalogueItemsDocument = gql`
  query allCatalogueItems($query: DefaultQueryInput) {
    allCatalogueItems(query: $query) {
      ...catalogueItemConnection
    }
  }
  ${CatalogueItemConnectionFragmentDoc}
`;
export type AllCatalogueItemsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllCatalogueItemsQuery, AllCatalogueItemsQueryVariables>,
  'query'
>;

export const AllCatalogueItemsComponent = (props: AllCatalogueItemsComponentProps) => (
  <ApolloReactComponents.Query<AllCatalogueItemsQuery, AllCatalogueItemsQueryVariables>
    query={AllCatalogueItemsDocument}
    {...props}
  />
);

export type AllCatalogueItemsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllCatalogueItemsQuery,
  AllCatalogueItemsQueryVariables
> &
  TChildProps;
export function withAllCatalogueItems<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllCatalogueItemsQuery,
    AllCatalogueItemsQueryVariables,
    AllCatalogueItemsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllCatalogueItemsQuery,
    AllCatalogueItemsQueryVariables,
    AllCatalogueItemsProps<TChildProps>
  >(AllCatalogueItemsDocument, {
    alias: 'allCatalogueItems',
    ...operationOptions,
  });
}

/**
 * __useAllCatalogueItemsQuery__
 *
 * To run a query within a React component, call `useAllCatalogueItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCatalogueItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCatalogueItemsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCatalogueItemsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllCatalogueItemsQuery, AllCatalogueItemsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllCatalogueItemsQuery, AllCatalogueItemsQueryVariables>(
    AllCatalogueItemsDocument,
    baseOptions
  );
}
export function useAllCatalogueItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCatalogueItemsQuery, AllCatalogueItemsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllCatalogueItemsQuery, AllCatalogueItemsQueryVariables>(
    AllCatalogueItemsDocument,
    baseOptions
  );
}
export type AllCatalogueItemsQueryHookResult = ReturnType<typeof useAllCatalogueItemsQuery>;
export type AllCatalogueItemsLazyQueryHookResult = ReturnType<typeof useAllCatalogueItemsLazyQuery>;
export type AllCatalogueItemsQueryResult = ApolloReactCommon.QueryResult<
  AllCatalogueItemsQuery,
  AllCatalogueItemsQueryVariables
>;
export const AllCatalogueItemsAndCategoriesDocument = gql`
  query allCatalogueItemsAndCategories($categoriesQuery: DefaultQueryInput, $itemsQuery: DefaultQueryInput) {
    allCatalogueCategories(query: $categoriesQuery) {
      ...catalogueCategoryConnection
    }
    allCatalogueItems(query: $itemsQuery) {
      ...catalogueItemConnection
    }
  }
  ${CatalogueCategoryConnectionFragmentDoc}
  ${CatalogueItemConnectionFragmentDoc}
`;
export type AllCatalogueItemsAndCategoriesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AllCatalogueItemsAndCategoriesQuery,
    AllCatalogueItemsAndCategoriesQueryVariables
  >,
  'query'
>;

export const AllCatalogueItemsAndCategoriesComponent = (props: AllCatalogueItemsAndCategoriesComponentProps) => (
  <ApolloReactComponents.Query<AllCatalogueItemsAndCategoriesQuery, AllCatalogueItemsAndCategoriesQueryVariables>
    query={AllCatalogueItemsAndCategoriesDocument}
    {...props}
  />
);

export type AllCatalogueItemsAndCategoriesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllCatalogueItemsAndCategoriesQuery,
  AllCatalogueItemsAndCategoriesQueryVariables
> &
  TChildProps;
export function withAllCatalogueItemsAndCategories<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllCatalogueItemsAndCategoriesQuery,
    AllCatalogueItemsAndCategoriesQueryVariables,
    AllCatalogueItemsAndCategoriesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllCatalogueItemsAndCategoriesQuery,
    AllCatalogueItemsAndCategoriesQueryVariables,
    AllCatalogueItemsAndCategoriesProps<TChildProps>
  >(AllCatalogueItemsAndCategoriesDocument, {
    alias: 'allCatalogueItemsAndCategories',
    ...operationOptions,
  });
}

/**
 * __useAllCatalogueItemsAndCategoriesQuery__
 *
 * To run a query within a React component, call `useAllCatalogueItemsAndCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCatalogueItemsAndCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCatalogueItemsAndCategoriesQuery({
 *   variables: {
 *      categoriesQuery: // value for 'categoriesQuery'
 *      itemsQuery: // value for 'itemsQuery'
 *   },
 * });
 */
export function useAllCatalogueItemsAndCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllCatalogueItemsAndCategoriesQuery,
    AllCatalogueItemsAndCategoriesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<AllCatalogueItemsAndCategoriesQuery, AllCatalogueItemsAndCategoriesQueryVariables>(
    AllCatalogueItemsAndCategoriesDocument,
    baseOptions
  );
}
export function useAllCatalogueItemsAndCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllCatalogueItemsAndCategoriesQuery,
    AllCatalogueItemsAndCategoriesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AllCatalogueItemsAndCategoriesQuery,
    AllCatalogueItemsAndCategoriesQueryVariables
  >(AllCatalogueItemsAndCategoriesDocument, baseOptions);
}
export type AllCatalogueItemsAndCategoriesQueryHookResult = ReturnType<typeof useAllCatalogueItemsAndCategoriesQuery>;
export type AllCatalogueItemsAndCategoriesLazyQueryHookResult = ReturnType<
  typeof useAllCatalogueItemsAndCategoriesLazyQuery
>;
export type AllCatalogueItemsAndCategoriesQueryResult = ApolloReactCommon.QueryResult<
  AllCatalogueItemsAndCategoriesQuery,
  AllCatalogueItemsAndCategoriesQueryVariables
>;
export const AllCataloguesDocument = gql`
  query allCatalogues($query: DefaultQueryInput) {
    allCatalogues(query: $query) {
      ...catalogueConnection
    }
  }
  ${CatalogueConnectionFragmentDoc}
`;
export type AllCataloguesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllCataloguesQuery, AllCataloguesQueryVariables>,
  'query'
>;

export const AllCataloguesComponent = (props: AllCataloguesComponentProps) => (
  <ApolloReactComponents.Query<AllCataloguesQuery, AllCataloguesQueryVariables>
    query={AllCataloguesDocument}
    {...props}
  />
);

export type AllCataloguesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllCataloguesQuery,
  AllCataloguesQueryVariables
> &
  TChildProps;
export function withAllCatalogues<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllCataloguesQuery,
    AllCataloguesQueryVariables,
    AllCataloguesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllCataloguesQuery,
    AllCataloguesQueryVariables,
    AllCataloguesProps<TChildProps>
  >(AllCataloguesDocument, {
    alias: 'allCatalogues',
    ...operationOptions,
  });
}

/**
 * __useAllCataloguesQuery__
 *
 * To run a query within a React component, call `useAllCataloguesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCataloguesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCataloguesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCataloguesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllCataloguesQuery, AllCataloguesQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllCataloguesQuery, AllCataloguesQueryVariables>(AllCataloguesDocument, baseOptions);
}
export function useAllCataloguesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCataloguesQuery, AllCataloguesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllCataloguesQuery, AllCataloguesQueryVariables>(
    AllCataloguesDocument,
    baseOptions
  );
}
export type AllCataloguesQueryHookResult = ReturnType<typeof useAllCataloguesQuery>;
export type AllCataloguesLazyQueryHookResult = ReturnType<typeof useAllCataloguesLazyQuery>;
export type AllCataloguesQueryResult = ApolloReactCommon.QueryResult<AllCataloguesQuery, AllCataloguesQueryVariables>;
export const AllCateringsDocument = gql`
  query allCaterings($query: DefaultQueryInput) {
    allCaterings(query: $query) {
      ...cateringConnection
    }
  }
  ${CateringConnectionFragmentDoc}
`;
export type AllCateringsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllCateringsQuery, AllCateringsQueryVariables>,
  'query'
>;

export const AllCateringsComponent = (props: AllCateringsComponentProps) => (
  <ApolloReactComponents.Query<AllCateringsQuery, AllCateringsQueryVariables> query={AllCateringsDocument} {...props} />
);

export type AllCateringsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllCateringsQuery,
  AllCateringsQueryVariables
> &
  TChildProps;
export function withAllCaterings<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllCateringsQuery,
    AllCateringsQueryVariables,
    AllCateringsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllCateringsQuery,
    AllCateringsQueryVariables,
    AllCateringsProps<TChildProps>
  >(AllCateringsDocument, {
    alias: 'allCaterings',
    ...operationOptions,
  });
}

/**
 * __useAllCateringsQuery__
 *
 * To run a query within a React component, call `useAllCateringsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCateringsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCateringsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCateringsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllCateringsQuery, AllCateringsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllCateringsQuery, AllCateringsQueryVariables>(AllCateringsDocument, baseOptions);
}
export function useAllCateringsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCateringsQuery, AllCateringsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllCateringsQuery, AllCateringsQueryVariables>(
    AllCateringsDocument,
    baseOptions
  );
}
export type AllCateringsQueryHookResult = ReturnType<typeof useAllCateringsQuery>;
export type AllCateringsLazyQueryHookResult = ReturnType<typeof useAllCateringsLazyQuery>;
export type AllCateringsQueryResult = ApolloReactCommon.QueryResult<AllCateringsQuery, AllCateringsQueryVariables>;
export const AllCommerceOrderDatasDocument = gql`
  query allCommerceOrderDatas($query: DefaultQueryInput) {
    allCommerceOrderDatas(query: $query) {
      ...commerceOrderDataConnection
    }
  }
  ${CommerceOrderDataConnectionFragmentDoc}
`;
export type AllCommerceOrderDatasComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllCommerceOrderDatasQuery, AllCommerceOrderDatasQueryVariables>,
  'query'
>;

export const AllCommerceOrderDatasComponent = (props: AllCommerceOrderDatasComponentProps) => (
  <ApolloReactComponents.Query<AllCommerceOrderDatasQuery, AllCommerceOrderDatasQueryVariables>
    query={AllCommerceOrderDatasDocument}
    {...props}
  />
);

export type AllCommerceOrderDatasProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllCommerceOrderDatasQuery,
  AllCommerceOrderDatasQueryVariables
> &
  TChildProps;
export function withAllCommerceOrderDatas<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllCommerceOrderDatasQuery,
    AllCommerceOrderDatasQueryVariables,
    AllCommerceOrderDatasProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllCommerceOrderDatasQuery,
    AllCommerceOrderDatasQueryVariables,
    AllCommerceOrderDatasProps<TChildProps>
  >(AllCommerceOrderDatasDocument, {
    alias: 'allCommerceOrderDatas',
    ...operationOptions,
  });
}

/**
 * __useAllCommerceOrderDatasQuery__
 *
 * To run a query within a React component, call `useAllCommerceOrderDatasQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCommerceOrderDatasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCommerceOrderDatasQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCommerceOrderDatasQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllCommerceOrderDatasQuery, AllCommerceOrderDatasQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllCommerceOrderDatasQuery, AllCommerceOrderDatasQueryVariables>(
    AllCommerceOrderDatasDocument,
    baseOptions
  );
}
export function useAllCommerceOrderDatasLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCommerceOrderDatasQuery, AllCommerceOrderDatasQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllCommerceOrderDatasQuery, AllCommerceOrderDatasQueryVariables>(
    AllCommerceOrderDatasDocument,
    baseOptions
  );
}
export type AllCommerceOrderDatasQueryHookResult = ReturnType<typeof useAllCommerceOrderDatasQuery>;
export type AllCommerceOrderDatasLazyQueryHookResult = ReturnType<typeof useAllCommerceOrderDatasLazyQuery>;
export type AllCommerceOrderDatasQueryResult = ApolloReactCommon.QueryResult<
  AllCommerceOrderDatasQuery,
  AllCommerceOrderDatasQueryVariables
>;
export const AllCommerceOrderFieldsDocument = gql`
  query allCommerceOrderFields($query: DefaultQueryInput) {
    allCommerceOrderFields(query: $query) {
      ...commerceOrderFieldConnection
    }
  }
  ${CommerceOrderFieldConnectionFragmentDoc}
`;
export type AllCommerceOrderFieldsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllCommerceOrderFieldsQuery, AllCommerceOrderFieldsQueryVariables>,
  'query'
>;

export const AllCommerceOrderFieldsComponent = (props: AllCommerceOrderFieldsComponentProps) => (
  <ApolloReactComponents.Query<AllCommerceOrderFieldsQuery, AllCommerceOrderFieldsQueryVariables>
    query={AllCommerceOrderFieldsDocument}
    {...props}
  />
);

export type AllCommerceOrderFieldsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllCommerceOrderFieldsQuery,
  AllCommerceOrderFieldsQueryVariables
> &
  TChildProps;
export function withAllCommerceOrderFields<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllCommerceOrderFieldsQuery,
    AllCommerceOrderFieldsQueryVariables,
    AllCommerceOrderFieldsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllCommerceOrderFieldsQuery,
    AllCommerceOrderFieldsQueryVariables,
    AllCommerceOrderFieldsProps<TChildProps>
  >(AllCommerceOrderFieldsDocument, {
    alias: 'allCommerceOrderFields',
    ...operationOptions,
  });
}

/**
 * __useAllCommerceOrderFieldsQuery__
 *
 * To run a query within a React component, call `useAllCommerceOrderFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCommerceOrderFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCommerceOrderFieldsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCommerceOrderFieldsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllCommerceOrderFieldsQuery, AllCommerceOrderFieldsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllCommerceOrderFieldsQuery, AllCommerceOrderFieldsQueryVariables>(
    AllCommerceOrderFieldsDocument,
    baseOptions
  );
}
export function useAllCommerceOrderFieldsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCommerceOrderFieldsQuery, AllCommerceOrderFieldsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllCommerceOrderFieldsQuery, AllCommerceOrderFieldsQueryVariables>(
    AllCommerceOrderFieldsDocument,
    baseOptions
  );
}
export type AllCommerceOrderFieldsQueryHookResult = ReturnType<typeof useAllCommerceOrderFieldsQuery>;
export type AllCommerceOrderFieldsLazyQueryHookResult = ReturnType<typeof useAllCommerceOrderFieldsLazyQuery>;
export type AllCommerceOrderFieldsQueryResult = ApolloReactCommon.QueryResult<
  AllCommerceOrderFieldsQuery,
  AllCommerceOrderFieldsQueryVariables
>;
export const AllCommerceOrderItemsDocument = gql`
  query allCommerceOrderItems($query: DefaultQueryInput) {
    allCommerceOrderItems(query: $query) {
      ...commerceOrderItemConnection
    }
  }
  ${CommerceOrderItemConnectionFragmentDoc}
`;
export type AllCommerceOrderItemsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllCommerceOrderItemsQuery, AllCommerceOrderItemsQueryVariables>,
  'query'
>;

export const AllCommerceOrderItemsComponent = (props: AllCommerceOrderItemsComponentProps) => (
  <ApolloReactComponents.Query<AllCommerceOrderItemsQuery, AllCommerceOrderItemsQueryVariables>
    query={AllCommerceOrderItemsDocument}
    {...props}
  />
);

export type AllCommerceOrderItemsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllCommerceOrderItemsQuery,
  AllCommerceOrderItemsQueryVariables
> &
  TChildProps;
export function withAllCommerceOrderItems<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllCommerceOrderItemsQuery,
    AllCommerceOrderItemsQueryVariables,
    AllCommerceOrderItemsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllCommerceOrderItemsQuery,
    AllCommerceOrderItemsQueryVariables,
    AllCommerceOrderItemsProps<TChildProps>
  >(AllCommerceOrderItemsDocument, {
    alias: 'allCommerceOrderItems',
    ...operationOptions,
  });
}

/**
 * __useAllCommerceOrderItemsQuery__
 *
 * To run a query within a React component, call `useAllCommerceOrderItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCommerceOrderItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCommerceOrderItemsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCommerceOrderItemsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllCommerceOrderItemsQuery, AllCommerceOrderItemsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllCommerceOrderItemsQuery, AllCommerceOrderItemsQueryVariables>(
    AllCommerceOrderItemsDocument,
    baseOptions
  );
}
export function useAllCommerceOrderItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCommerceOrderItemsQuery, AllCommerceOrderItemsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllCommerceOrderItemsQuery, AllCommerceOrderItemsQueryVariables>(
    AllCommerceOrderItemsDocument,
    baseOptions
  );
}
export type AllCommerceOrderItemsQueryHookResult = ReturnType<typeof useAllCommerceOrderItemsQuery>;
export type AllCommerceOrderItemsLazyQueryHookResult = ReturnType<typeof useAllCommerceOrderItemsLazyQuery>;
export type AllCommerceOrderItemsQueryResult = ApolloReactCommon.QueryResult<
  AllCommerceOrderItemsQuery,
  AllCommerceOrderItemsQueryVariables
>;
export const AllCommerceOrdersDocument = gql`
  query allCommerceOrders($query: DefaultQueryInput) {
    allCommerceOrders(query: $query) {
      ...commerceOrderConnection
    }
  }
  ${CommerceOrderConnectionFragmentDoc}
`;
export type AllCommerceOrdersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllCommerceOrdersQuery, AllCommerceOrdersQueryVariables>,
  'query'
>;

export const AllCommerceOrdersComponent = (props: AllCommerceOrdersComponentProps) => (
  <ApolloReactComponents.Query<AllCommerceOrdersQuery, AllCommerceOrdersQueryVariables>
    query={AllCommerceOrdersDocument}
    {...props}
  />
);

export type AllCommerceOrdersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllCommerceOrdersQuery,
  AllCommerceOrdersQueryVariables
> &
  TChildProps;
export function withAllCommerceOrders<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllCommerceOrdersQuery,
    AllCommerceOrdersQueryVariables,
    AllCommerceOrdersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllCommerceOrdersQuery,
    AllCommerceOrdersQueryVariables,
    AllCommerceOrdersProps<TChildProps>
  >(AllCommerceOrdersDocument, {
    alias: 'allCommerceOrders',
    ...operationOptions,
  });
}

/**
 * __useAllCommerceOrdersQuery__
 *
 * To run a query within a React component, call `useAllCommerceOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCommerceOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCommerceOrdersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCommerceOrdersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllCommerceOrdersQuery, AllCommerceOrdersQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllCommerceOrdersQuery, AllCommerceOrdersQueryVariables>(
    AllCommerceOrdersDocument,
    baseOptions
  );
}
export function useAllCommerceOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCommerceOrdersQuery, AllCommerceOrdersQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllCommerceOrdersQuery, AllCommerceOrdersQueryVariables>(
    AllCommerceOrdersDocument,
    baseOptions
  );
}
export type AllCommerceOrdersQueryHookResult = ReturnType<typeof useAllCommerceOrdersQuery>;
export type AllCommerceOrdersLazyQueryHookResult = ReturnType<typeof useAllCommerceOrdersLazyQuery>;
export type AllCommerceOrdersQueryResult = ApolloReactCommon.QueryResult<
  AllCommerceOrdersQuery,
  AllCommerceOrdersQueryVariables
>;
export const AllCommerceShopsDocument = gql`
  query allCommerceShops($query: DefaultQueryInput) {
    allCommerceShops(query: $query) {
      ...commerceShopConnection
    }
  }
  ${CommerceShopConnectionFragmentDoc}
`;
export type AllCommerceShopsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllCommerceShopsQuery, AllCommerceShopsQueryVariables>,
  'query'
>;

export const AllCommerceShopsComponent = (props: AllCommerceShopsComponentProps) => (
  <ApolloReactComponents.Query<AllCommerceShopsQuery, AllCommerceShopsQueryVariables>
    query={AllCommerceShopsDocument}
    {...props}
  />
);

export type AllCommerceShopsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllCommerceShopsQuery,
  AllCommerceShopsQueryVariables
> &
  TChildProps;
export function withAllCommerceShops<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllCommerceShopsQuery,
    AllCommerceShopsQueryVariables,
    AllCommerceShopsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllCommerceShopsQuery,
    AllCommerceShopsQueryVariables,
    AllCommerceShopsProps<TChildProps>
  >(AllCommerceShopsDocument, {
    alias: 'allCommerceShops',
    ...operationOptions,
  });
}

/**
 * __useAllCommerceShopsQuery__
 *
 * To run a query within a React component, call `useAllCommerceShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCommerceShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCommerceShopsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCommerceShopsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllCommerceShopsQuery, AllCommerceShopsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllCommerceShopsQuery, AllCommerceShopsQueryVariables>(
    AllCommerceShopsDocument,
    baseOptions
  );
}
export function useAllCommerceShopsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCommerceShopsQuery, AllCommerceShopsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllCommerceShopsQuery, AllCommerceShopsQueryVariables>(
    AllCommerceShopsDocument,
    baseOptions
  );
}
export type AllCommerceShopsQueryHookResult = ReturnType<typeof useAllCommerceShopsQuery>;
export type AllCommerceShopsLazyQueryHookResult = ReturnType<typeof useAllCommerceShopsLazyQuery>;
export type AllCommerceShopsQueryResult = ApolloReactCommon.QueryResult<
  AllCommerceShopsQuery,
  AllCommerceShopsQueryVariables
>;
export const AllCommerceTemplatesDocument = gql`
  query allCommerceTemplates($query: DefaultQueryInput) {
    allCommerceTemplates(query: $query) {
      ...CommerceTemplateConnection
    }
  }
  ${CommerceTemplateConnectionFragmentDoc}
`;
export type AllCommerceTemplatesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllCommerceTemplatesQuery, AllCommerceTemplatesQueryVariables>,
  'query'
>;

export const AllCommerceTemplatesComponent = (props: AllCommerceTemplatesComponentProps) => (
  <ApolloReactComponents.Query<AllCommerceTemplatesQuery, AllCommerceTemplatesQueryVariables>
    query={AllCommerceTemplatesDocument}
    {...props}
  />
);

export type AllCommerceTemplatesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllCommerceTemplatesQuery,
  AllCommerceTemplatesQueryVariables
> &
  TChildProps;
export function withAllCommerceTemplates<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllCommerceTemplatesQuery,
    AllCommerceTemplatesQueryVariables,
    AllCommerceTemplatesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllCommerceTemplatesQuery,
    AllCommerceTemplatesQueryVariables,
    AllCommerceTemplatesProps<TChildProps>
  >(AllCommerceTemplatesDocument, {
    alias: 'allCommerceTemplates',
    ...operationOptions,
  });
}

/**
 * __useAllCommerceTemplatesQuery__
 *
 * To run a query within a React component, call `useAllCommerceTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCommerceTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCommerceTemplatesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCommerceTemplatesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllCommerceTemplatesQuery, AllCommerceTemplatesQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllCommerceTemplatesQuery, AllCommerceTemplatesQueryVariables>(
    AllCommerceTemplatesDocument,
    baseOptions
  );
}
export function useAllCommerceTemplatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCommerceTemplatesQuery, AllCommerceTemplatesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllCommerceTemplatesQuery, AllCommerceTemplatesQueryVariables>(
    AllCommerceTemplatesDocument,
    baseOptions
  );
}
export type AllCommerceTemplatesQueryHookResult = ReturnType<typeof useAllCommerceTemplatesQuery>;
export type AllCommerceTemplatesLazyQueryHookResult = ReturnType<typeof useAllCommerceTemplatesLazyQuery>;
export type AllCommerceTemplatesQueryResult = ApolloReactCommon.QueryResult<
  AllCommerceTemplatesQuery,
  AllCommerceTemplatesQueryVariables
>;
export const AllContentTranslationsDocument = gql`
  query allContentTranslations($query: DefaultQueryInput) {
    allContentTranslations(query: $query) {
      total
      limit
      cursor
      sortable
      searchable
      edges {
        ...contentTranslation
      }
    }
  }
  ${ContentTranslationFragmentDoc}
`;
export type AllContentTranslationsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllContentTranslationsQuery, AllContentTranslationsQueryVariables>,
  'query'
>;

export const AllContentTranslationsComponent = (props: AllContentTranslationsComponentProps) => (
  <ApolloReactComponents.Query<AllContentTranslationsQuery, AllContentTranslationsQueryVariables>
    query={AllContentTranslationsDocument}
    {...props}
  />
);

export type AllContentTranslationsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllContentTranslationsQuery,
  AllContentTranslationsQueryVariables
> &
  TChildProps;
export function withAllContentTranslations<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllContentTranslationsQuery,
    AllContentTranslationsQueryVariables,
    AllContentTranslationsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllContentTranslationsQuery,
    AllContentTranslationsQueryVariables,
    AllContentTranslationsProps<TChildProps>
  >(AllContentTranslationsDocument, {
    alias: 'allContentTranslations',
    ...operationOptions,
  });
}

/**
 * __useAllContentTranslationsQuery__
 *
 * To run a query within a React component, call `useAllContentTranslationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllContentTranslationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllContentTranslationsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllContentTranslationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllContentTranslationsQuery, AllContentTranslationsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllContentTranslationsQuery, AllContentTranslationsQueryVariables>(
    AllContentTranslationsDocument,
    baseOptions
  );
}
export function useAllContentTranslationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllContentTranslationsQuery, AllContentTranslationsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllContentTranslationsQuery, AllContentTranslationsQueryVariables>(
    AllContentTranslationsDocument,
    baseOptions
  );
}
export type AllContentTranslationsQueryHookResult = ReturnType<typeof useAllContentTranslationsQuery>;
export type AllContentTranslationsLazyQueryHookResult = ReturnType<typeof useAllContentTranslationsLazyQuery>;
export type AllContentTranslationsQueryResult = ApolloReactCommon.QueryResult<
  AllContentTranslationsQuery,
  AllContentTranslationsQueryVariables
>;
export const AllDisputeDisputeResponsesDocument = gql`
  query allDisputeDisputeResponses($query: DefaultQueryInput) {
    allDisputeDisputeResponses(query: $query) {
      ...disputeDisputeResponseConnection
    }
  }
  ${DisputeDisputeResponseConnectionFragmentDoc}
`;
export type AllDisputeDisputeResponsesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AllDisputeDisputeResponsesQuery,
    AllDisputeDisputeResponsesQueryVariables
  >,
  'query'
>;

export const AllDisputeDisputeResponsesComponent = (props: AllDisputeDisputeResponsesComponentProps) => (
  <ApolloReactComponents.Query<AllDisputeDisputeResponsesQuery, AllDisputeDisputeResponsesQueryVariables>
    query={AllDisputeDisputeResponsesDocument}
    {...props}
  />
);

export type AllDisputeDisputeResponsesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllDisputeDisputeResponsesQuery,
  AllDisputeDisputeResponsesQueryVariables
> &
  TChildProps;
export function withAllDisputeDisputeResponses<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllDisputeDisputeResponsesQuery,
    AllDisputeDisputeResponsesQueryVariables,
    AllDisputeDisputeResponsesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllDisputeDisputeResponsesQuery,
    AllDisputeDisputeResponsesQueryVariables,
    AllDisputeDisputeResponsesProps<TChildProps>
  >(AllDisputeDisputeResponsesDocument, {
    alias: 'allDisputeDisputeResponses',
    ...operationOptions,
  });
}

/**
 * __useAllDisputeDisputeResponsesQuery__
 *
 * To run a query within a React component, call `useAllDisputeDisputeResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDisputeDisputeResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDisputeDisputeResponsesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllDisputeDisputeResponsesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllDisputeDisputeResponsesQuery,
    AllDisputeDisputeResponsesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<AllDisputeDisputeResponsesQuery, AllDisputeDisputeResponsesQueryVariables>(
    AllDisputeDisputeResponsesDocument,
    baseOptions
  );
}
export function useAllDisputeDisputeResponsesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllDisputeDisputeResponsesQuery,
    AllDisputeDisputeResponsesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<AllDisputeDisputeResponsesQuery, AllDisputeDisputeResponsesQueryVariables>(
    AllDisputeDisputeResponsesDocument,
    baseOptions
  );
}
export type AllDisputeDisputeResponsesQueryHookResult = ReturnType<typeof useAllDisputeDisputeResponsesQuery>;
export type AllDisputeDisputeResponsesLazyQueryHookResult = ReturnType<typeof useAllDisputeDisputeResponsesLazyQuery>;
export type AllDisputeDisputeResponsesQueryResult = ApolloReactCommon.QueryResult<
  AllDisputeDisputeResponsesQuery,
  AllDisputeDisputeResponsesQueryVariables
>;
export const AllDisputesDocument = gql`
  query allDisputes($query: DefaultQueryInput) {
    allDisputes(query: $query) {
      ...disputeConnection
    }
  }
  ${DisputeConnectionFragmentDoc}
`;
export type AllDisputesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllDisputesQuery, AllDisputesQueryVariables>,
  'query'
>;

export const AllDisputesComponent = (props: AllDisputesComponentProps) => (
  <ApolloReactComponents.Query<AllDisputesQuery, AllDisputesQueryVariables> query={AllDisputesDocument} {...props} />
);

export type AllDisputesProps<TChildProps = {}> = ApolloReactHoc.DataProps<AllDisputesQuery, AllDisputesQueryVariables> &
  TChildProps;
export function withAllDisputes<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllDisputesQuery,
    AllDisputesQueryVariables,
    AllDisputesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<TProps, AllDisputesQuery, AllDisputesQueryVariables, AllDisputesProps<TChildProps>>(
    AllDisputesDocument,
    {
      alias: 'allDisputes',
      ...operationOptions,
    }
  );
}

/**
 * __useAllDisputesQuery__
 *
 * To run a query within a React component, call `useAllDisputesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDisputesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDisputesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllDisputesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllDisputesQuery, AllDisputesQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllDisputesQuery, AllDisputesQueryVariables>(AllDisputesDocument, baseOptions);
}
export function useAllDisputesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllDisputesQuery, AllDisputesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllDisputesQuery, AllDisputesQueryVariables>(AllDisputesDocument, baseOptions);
}
export type AllDisputesQueryHookResult = ReturnType<typeof useAllDisputesQuery>;
export type AllDisputesLazyQueryHookResult = ReturnType<typeof useAllDisputesLazyQuery>;
export type AllDisputesQueryResult = ApolloReactCommon.QueryResult<AllDisputesQuery, AllDisputesQueryVariables>;
export const AllEventEventAccessesDocument = gql`
  query allEventEventAccesses($query: DefaultQueryInput) {
    allEventEventAccesses(query: $query) {
      ...eventEventAccessConnection
    }
  }
  ${EventEventAccessConnectionFragmentDoc}
`;
export type AllEventEventAccessesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllEventEventAccessesQuery, AllEventEventAccessesQueryVariables>,
  'query'
>;

export const AllEventEventAccessesComponent = (props: AllEventEventAccessesComponentProps) => (
  <ApolloReactComponents.Query<AllEventEventAccessesQuery, AllEventEventAccessesQueryVariables>
    query={AllEventEventAccessesDocument}
    {...props}
  />
);

export type AllEventEventAccessesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllEventEventAccessesQuery,
  AllEventEventAccessesQueryVariables
> &
  TChildProps;
export function withAllEventEventAccesses<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllEventEventAccessesQuery,
    AllEventEventAccessesQueryVariables,
    AllEventEventAccessesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllEventEventAccessesQuery,
    AllEventEventAccessesQueryVariables,
    AllEventEventAccessesProps<TChildProps>
  >(AllEventEventAccessesDocument, {
    alias: 'allEventEventAccesses',
    ...operationOptions,
  });
}

/**
 * __useAllEventEventAccessesQuery__
 *
 * To run a query within a React component, call `useAllEventEventAccessesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEventEventAccessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEventEventAccessesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllEventEventAccessesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllEventEventAccessesQuery, AllEventEventAccessesQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllEventEventAccessesQuery, AllEventEventAccessesQueryVariables>(
    AllEventEventAccessesDocument,
    baseOptions
  );
}
export function useAllEventEventAccessesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllEventEventAccessesQuery, AllEventEventAccessesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllEventEventAccessesQuery, AllEventEventAccessesQueryVariables>(
    AllEventEventAccessesDocument,
    baseOptions
  );
}
export type AllEventEventAccessesQueryHookResult = ReturnType<typeof useAllEventEventAccessesQuery>;
export type AllEventEventAccessesLazyQueryHookResult = ReturnType<typeof useAllEventEventAccessesLazyQuery>;
export type AllEventEventAccessesQueryResult = ApolloReactCommon.QueryResult<
  AllEventEventAccessesQuery,
  AllEventEventAccessesQueryVariables
>;
export const AllEventFieldsDocument = gql`
  query allEventFields($query: DefaultQueryInput) {
    allEventFields(query: $query) {
      ...eventFieldsConnection
    }
  }
  ${EventFieldsConnectionFragmentDoc}
`;
export type AllEventFieldsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllEventFieldsQuery, AllEventFieldsQueryVariables>,
  'query'
>;

export const AllEventFieldsComponent = (props: AllEventFieldsComponentProps) => (
  <ApolloReactComponents.Query<AllEventFieldsQuery, AllEventFieldsQueryVariables>
    query={AllEventFieldsDocument}
    {...props}
  />
);

export type AllEventFieldsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllEventFieldsQuery,
  AllEventFieldsQueryVariables
> &
  TChildProps;
export function withAllEventFields<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllEventFieldsQuery,
    AllEventFieldsQueryVariables,
    AllEventFieldsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllEventFieldsQuery,
    AllEventFieldsQueryVariables,
    AllEventFieldsProps<TChildProps>
  >(AllEventFieldsDocument, {
    alias: 'allEventFields',
    ...operationOptions,
  });
}

/**
 * __useAllEventFieldsQuery__
 *
 * To run a query within a React component, call `useAllEventFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEventFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEventFieldsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllEventFieldsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllEventFieldsQuery, AllEventFieldsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllEventFieldsQuery, AllEventFieldsQueryVariables>(
    AllEventFieldsDocument,
    baseOptions
  );
}
export function useAllEventFieldsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllEventFieldsQuery, AllEventFieldsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllEventFieldsQuery, AllEventFieldsQueryVariables>(
    AllEventFieldsDocument,
    baseOptions
  );
}
export type AllEventFieldsQueryHookResult = ReturnType<typeof useAllEventFieldsQuery>;
export type AllEventFieldsLazyQueryHookResult = ReturnType<typeof useAllEventFieldsLazyQuery>;
export type AllEventFieldsQueryResult = ApolloReactCommon.QueryResult<
  AllEventFieldsQuery,
  AllEventFieldsQueryVariables
>;
export const AllEventOrderTicketsDocument = gql`
  query allEventOrderTickets($query: DefaultQueryInput) {
    allEventOrderTickets(query: $query) {
      ...eventOrderTicketConnection
    }
  }
  ${EventOrderTicketConnectionFragmentDoc}
`;
export type AllEventOrderTicketsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllEventOrderTicketsQuery, AllEventOrderTicketsQueryVariables>,
  'query'
>;

export const AllEventOrderTicketsComponent = (props: AllEventOrderTicketsComponentProps) => (
  <ApolloReactComponents.Query<AllEventOrderTicketsQuery, AllEventOrderTicketsQueryVariables>
    query={AllEventOrderTicketsDocument}
    {...props}
  />
);

export type AllEventOrderTicketsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllEventOrderTicketsQuery,
  AllEventOrderTicketsQueryVariables
> &
  TChildProps;
export function withAllEventOrderTickets<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllEventOrderTicketsQuery,
    AllEventOrderTicketsQueryVariables,
    AllEventOrderTicketsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllEventOrderTicketsQuery,
    AllEventOrderTicketsQueryVariables,
    AllEventOrderTicketsProps<TChildProps>
  >(AllEventOrderTicketsDocument, {
    alias: 'allEventOrderTickets',
    ...operationOptions,
  });
}

/**
 * __useAllEventOrderTicketsQuery__
 *
 * To run a query within a React component, call `useAllEventOrderTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEventOrderTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEventOrderTicketsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllEventOrderTicketsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllEventOrderTicketsQuery, AllEventOrderTicketsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllEventOrderTicketsQuery, AllEventOrderTicketsQueryVariables>(
    AllEventOrderTicketsDocument,
    baseOptions
  );
}
export function useAllEventOrderTicketsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllEventOrderTicketsQuery, AllEventOrderTicketsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllEventOrderTicketsQuery, AllEventOrderTicketsQueryVariables>(
    AllEventOrderTicketsDocument,
    baseOptions
  );
}
export type AllEventOrderTicketsQueryHookResult = ReturnType<typeof useAllEventOrderTicketsQuery>;
export type AllEventOrderTicketsLazyQueryHookResult = ReturnType<typeof useAllEventOrderTicketsLazyQuery>;
export type AllEventOrderTicketsQueryResult = ApolloReactCommon.QueryResult<
  AllEventOrderTicketsQuery,
  AllEventOrderTicketsQueryVariables
>;
export const AllEventOrdersDocument = gql`
  query allEventOrders($query: DefaultQueryInput) {
    allEventOrders(query: $query) {
      ...eventOrderConnection
    }
  }
  ${EventOrderConnectionFragmentDoc}
`;
export type AllEventOrdersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllEventOrdersQuery, AllEventOrdersQueryVariables>,
  'query'
>;

export const AllEventOrdersComponent = (props: AllEventOrdersComponentProps) => (
  <ApolloReactComponents.Query<AllEventOrdersQuery, AllEventOrdersQueryVariables>
    query={AllEventOrdersDocument}
    {...props}
  />
);

export type AllEventOrdersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllEventOrdersQuery,
  AllEventOrdersQueryVariables
> &
  TChildProps;
export function withAllEventOrders<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllEventOrdersQuery,
    AllEventOrdersQueryVariables,
    AllEventOrdersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllEventOrdersQuery,
    AllEventOrdersQueryVariables,
    AllEventOrdersProps<TChildProps>
  >(AllEventOrdersDocument, {
    alias: 'allEventOrders',
    ...operationOptions,
  });
}

/**
 * __useAllEventOrdersQuery__
 *
 * To run a query within a React component, call `useAllEventOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEventOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEventOrdersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllEventOrdersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllEventOrdersQuery, AllEventOrdersQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllEventOrdersQuery, AllEventOrdersQueryVariables>(
    AllEventOrdersDocument,
    baseOptions
  );
}
export function useAllEventOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllEventOrdersQuery, AllEventOrdersQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllEventOrdersQuery, AllEventOrdersQueryVariables>(
    AllEventOrdersDocument,
    baseOptions
  );
}
export type AllEventOrdersQueryHookResult = ReturnType<typeof useAllEventOrdersQuery>;
export type AllEventOrdersLazyQueryHookResult = ReturnType<typeof useAllEventOrdersLazyQuery>;
export type AllEventOrdersQueryResult = ApolloReactCommon.QueryResult<
  AllEventOrdersQuery,
  AllEventOrdersQueryVariables
>;
export const AllEventPlaceSectorsDocument = gql`
  query allEventPlaceSectors($query: DefaultQueryInput) {
    allEventPlaceSectors(query: $query) {
      ...eventPlaceSectorConnection
    }
  }
  ${EventPlaceSectorConnectionFragmentDoc}
`;
export type AllEventPlaceSectorsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllEventPlaceSectorsQuery, AllEventPlaceSectorsQueryVariables>,
  'query'
>;

export const AllEventPlaceSectorsComponent = (props: AllEventPlaceSectorsComponentProps) => (
  <ApolloReactComponents.Query<AllEventPlaceSectorsQuery, AllEventPlaceSectorsQueryVariables>
    query={AllEventPlaceSectorsDocument}
    {...props}
  />
);

export type AllEventPlaceSectorsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllEventPlaceSectorsQuery,
  AllEventPlaceSectorsQueryVariables
> &
  TChildProps;
export function withAllEventPlaceSectors<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllEventPlaceSectorsQuery,
    AllEventPlaceSectorsQueryVariables,
    AllEventPlaceSectorsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllEventPlaceSectorsQuery,
    AllEventPlaceSectorsQueryVariables,
    AllEventPlaceSectorsProps<TChildProps>
  >(AllEventPlaceSectorsDocument, {
    alias: 'allEventPlaceSectors',
    ...operationOptions,
  });
}

/**
 * __useAllEventPlaceSectorsQuery__
 *
 * To run a query within a React component, call `useAllEventPlaceSectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEventPlaceSectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEventPlaceSectorsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllEventPlaceSectorsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllEventPlaceSectorsQuery, AllEventPlaceSectorsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllEventPlaceSectorsQuery, AllEventPlaceSectorsQueryVariables>(
    AllEventPlaceSectorsDocument,
    baseOptions
  );
}
export function useAllEventPlaceSectorsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllEventPlaceSectorsQuery, AllEventPlaceSectorsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllEventPlaceSectorsQuery, AllEventPlaceSectorsQueryVariables>(
    AllEventPlaceSectorsDocument,
    baseOptions
  );
}
export type AllEventPlaceSectorsQueryHookResult = ReturnType<typeof useAllEventPlaceSectorsQuery>;
export type AllEventPlaceSectorsLazyQueryHookResult = ReturnType<typeof useAllEventPlaceSectorsLazyQuery>;
export type AllEventPlaceSectorsQueryResult = ApolloReactCommon.QueryResult<
  AllEventPlaceSectorsQuery,
  AllEventPlaceSectorsQueryVariables
>;
export const AllEventPlacesDocument = gql`
  query allEventPlaces($query: DefaultQueryInput) {
    allEventPlaces(query: $query) {
      ...eventPlaceConnection
    }
  }
  ${EventPlaceConnectionFragmentDoc}
`;
export type AllEventPlacesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllEventPlacesQuery, AllEventPlacesQueryVariables>,
  'query'
>;

export const AllEventPlacesComponent = (props: AllEventPlacesComponentProps) => (
  <ApolloReactComponents.Query<AllEventPlacesQuery, AllEventPlacesQueryVariables>
    query={AllEventPlacesDocument}
    {...props}
  />
);

export type AllEventPlacesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllEventPlacesQuery,
  AllEventPlacesQueryVariables
> &
  TChildProps;
export function withAllEventPlaces<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllEventPlacesQuery,
    AllEventPlacesQueryVariables,
    AllEventPlacesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllEventPlacesQuery,
    AllEventPlacesQueryVariables,
    AllEventPlacesProps<TChildProps>
  >(AllEventPlacesDocument, {
    alias: 'allEventPlaces',
    ...operationOptions,
  });
}

/**
 * __useAllEventPlacesQuery__
 *
 * To run a query within a React component, call `useAllEventPlacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEventPlacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEventPlacesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllEventPlacesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllEventPlacesQuery, AllEventPlacesQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllEventPlacesQuery, AllEventPlacesQueryVariables>(
    AllEventPlacesDocument,
    baseOptions
  );
}
export function useAllEventPlacesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllEventPlacesQuery, AllEventPlacesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllEventPlacesQuery, AllEventPlacesQueryVariables>(
    AllEventPlacesDocument,
    baseOptions
  );
}
export type AllEventPlacesQueryHookResult = ReturnType<typeof useAllEventPlacesQuery>;
export type AllEventPlacesLazyQueryHookResult = ReturnType<typeof useAllEventPlacesLazyQuery>;
export type AllEventPlacesQueryResult = ApolloReactCommon.QueryResult<
  AllEventPlacesQuery,
  AllEventPlacesQueryVariables
>;
export const AllEventTypesDocument = gql`
  query allEventTypes($query: DefaultQueryInput) {
    allEventTypes(query: $query) {
      ...eventTypeConnection
    }
  }
  ${EventTypeConnectionFragmentDoc}
`;
export type AllEventTypesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllEventTypesQuery, AllEventTypesQueryVariables>,
  'query'
>;

export const AllEventTypesComponent = (props: AllEventTypesComponentProps) => (
  <ApolloReactComponents.Query<AllEventTypesQuery, AllEventTypesQueryVariables>
    query={AllEventTypesDocument}
    {...props}
  />
);

export type AllEventTypesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllEventTypesQuery,
  AllEventTypesQueryVariables
> &
  TChildProps;
export function withAllEventTypes<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllEventTypesQuery,
    AllEventTypesQueryVariables,
    AllEventTypesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllEventTypesQuery,
    AllEventTypesQueryVariables,
    AllEventTypesProps<TChildProps>
  >(AllEventTypesDocument, {
    alias: 'allEventTypes',
    ...operationOptions,
  });
}

/**
 * __useAllEventTypesQuery__
 *
 * To run a query within a React component, call `useAllEventTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEventTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEventTypesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllEventTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllEventTypesQuery, AllEventTypesQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllEventTypesQuery, AllEventTypesQueryVariables>(AllEventTypesDocument, baseOptions);
}
export function useAllEventTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllEventTypesQuery, AllEventTypesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllEventTypesQuery, AllEventTypesQueryVariables>(
    AllEventTypesDocument,
    baseOptions
  );
}
export type AllEventTypesQueryHookResult = ReturnType<typeof useAllEventTypesQuery>;
export type AllEventTypesLazyQueryHookResult = ReturnType<typeof useAllEventTypesLazyQuery>;
export type AllEventTypesQueryResult = ApolloReactCommon.QueryResult<AllEventTypesQuery, AllEventTypesQueryVariables>;
export const AllEventsDocument = gql`
  query allEvents($query: DefaultQueryInput) {
    allEvents(query: $query) {
      ...eventConnection
    }
  }
  ${EventConnectionFragmentDoc}
`;
export type AllEventsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllEventsQuery, AllEventsQueryVariables>,
  'query'
>;

export const AllEventsComponent = (props: AllEventsComponentProps) => (
  <ApolloReactComponents.Query<AllEventsQuery, AllEventsQueryVariables> query={AllEventsDocument} {...props} />
);

export type AllEventsProps<TChildProps = {}> = ApolloReactHoc.DataProps<AllEventsQuery, AllEventsQueryVariables> &
  TChildProps;
export function withAllEvents<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllEventsQuery,
    AllEventsQueryVariables,
    AllEventsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<TProps, AllEventsQuery, AllEventsQueryVariables, AllEventsProps<TChildProps>>(
    AllEventsDocument,
    {
      alias: 'allEvents',
      ...operationOptions,
    }
  );
}

/**
 * __useAllEventsQuery__
 *
 * To run a query within a React component, call `useAllEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEventsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllEventsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllEventsQuery, AllEventsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllEventsQuery, AllEventsQueryVariables>(AllEventsDocument, baseOptions);
}
export function useAllEventsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllEventsQuery, AllEventsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllEventsQuery, AllEventsQueryVariables>(AllEventsDocument, baseOptions);
}
export type AllEventsQueryHookResult = ReturnType<typeof useAllEventsQuery>;
export type AllEventsLazyQueryHookResult = ReturnType<typeof useAllEventsLazyQuery>;
export type AllEventsQueryResult = ApolloReactCommon.QueryResult<AllEventsQuery, AllEventsQueryVariables>;
export const AllLoyaltyPartnerEntitiesDocument = gql`
  query allLoyaltyPartnerEntities($query: DefaultQueryInput) {
    allLoyaltyPartnerEntities(query: $query) {
      ...loyaltyPartnerEntityConnection
    }
  }
  ${LoyaltyPartnerEntityConnectionFragmentDoc}
`;
export type AllLoyaltyPartnerEntitiesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllLoyaltyPartnerEntitiesQuery, AllLoyaltyPartnerEntitiesQueryVariables>,
  'query'
>;

export const AllLoyaltyPartnerEntitiesComponent = (props: AllLoyaltyPartnerEntitiesComponentProps) => (
  <ApolloReactComponents.Query<AllLoyaltyPartnerEntitiesQuery, AllLoyaltyPartnerEntitiesQueryVariables>
    query={AllLoyaltyPartnerEntitiesDocument}
    {...props}
  />
);

export type AllLoyaltyPartnerEntitiesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllLoyaltyPartnerEntitiesQuery,
  AllLoyaltyPartnerEntitiesQueryVariables
> &
  TChildProps;
export function withAllLoyaltyPartnerEntities<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllLoyaltyPartnerEntitiesQuery,
    AllLoyaltyPartnerEntitiesQueryVariables,
    AllLoyaltyPartnerEntitiesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllLoyaltyPartnerEntitiesQuery,
    AllLoyaltyPartnerEntitiesQueryVariables,
    AllLoyaltyPartnerEntitiesProps<TChildProps>
  >(AllLoyaltyPartnerEntitiesDocument, {
    alias: 'allLoyaltyPartnerEntities',
    ...operationOptions,
  });
}

/**
 * __useAllLoyaltyPartnerEntitiesQuery__
 *
 * To run a query within a React component, call `useAllLoyaltyPartnerEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLoyaltyPartnerEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLoyaltyPartnerEntitiesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllLoyaltyPartnerEntitiesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllLoyaltyPartnerEntitiesQuery,
    AllLoyaltyPartnerEntitiesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<AllLoyaltyPartnerEntitiesQuery, AllLoyaltyPartnerEntitiesQueryVariables>(
    AllLoyaltyPartnerEntitiesDocument,
    baseOptions
  );
}
export function useAllLoyaltyPartnerEntitiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllLoyaltyPartnerEntitiesQuery,
    AllLoyaltyPartnerEntitiesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<AllLoyaltyPartnerEntitiesQuery, AllLoyaltyPartnerEntitiesQueryVariables>(
    AllLoyaltyPartnerEntitiesDocument,
    baseOptions
  );
}
export type AllLoyaltyPartnerEntitiesQueryHookResult = ReturnType<typeof useAllLoyaltyPartnerEntitiesQuery>;
export type AllLoyaltyPartnerEntitiesLazyQueryHookResult = ReturnType<typeof useAllLoyaltyPartnerEntitiesLazyQuery>;
export type AllLoyaltyPartnerEntitiesQueryResult = ApolloReactCommon.QueryResult<
  AllLoyaltyPartnerEntitiesQuery,
  AllLoyaltyPartnerEntitiesQueryVariables
>;
export const AllLoyaltyPartnersDocument = gql`
  query allLoyaltyPartners($query: DefaultQueryInput) {
    allLoyaltyPartners(query: $query) {
      ...loyaltyPartnerConnection
    }
  }
  ${LoyaltyPartnerConnectionFragmentDoc}
`;
export type AllLoyaltyPartnersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllLoyaltyPartnersQuery, AllLoyaltyPartnersQueryVariables>,
  'query'
>;

export const AllLoyaltyPartnersComponent = (props: AllLoyaltyPartnersComponentProps) => (
  <ApolloReactComponents.Query<AllLoyaltyPartnersQuery, AllLoyaltyPartnersQueryVariables>
    query={AllLoyaltyPartnersDocument}
    {...props}
  />
);

export type AllLoyaltyPartnersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllLoyaltyPartnersQuery,
  AllLoyaltyPartnersQueryVariables
> &
  TChildProps;
export function withAllLoyaltyPartners<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllLoyaltyPartnersQuery,
    AllLoyaltyPartnersQueryVariables,
    AllLoyaltyPartnersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllLoyaltyPartnersQuery,
    AllLoyaltyPartnersQueryVariables,
    AllLoyaltyPartnersProps<TChildProps>
  >(AllLoyaltyPartnersDocument, {
    alias: 'allLoyaltyPartners',
    ...operationOptions,
  });
}

/**
 * __useAllLoyaltyPartnersQuery__
 *
 * To run a query within a React component, call `useAllLoyaltyPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLoyaltyPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLoyaltyPartnersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllLoyaltyPartnersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllLoyaltyPartnersQuery, AllLoyaltyPartnersQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllLoyaltyPartnersQuery, AllLoyaltyPartnersQueryVariables>(
    AllLoyaltyPartnersDocument,
    baseOptions
  );
}
export function useAllLoyaltyPartnersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllLoyaltyPartnersQuery, AllLoyaltyPartnersQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllLoyaltyPartnersQuery, AllLoyaltyPartnersQueryVariables>(
    AllLoyaltyPartnersDocument,
    baseOptions
  );
}
export type AllLoyaltyPartnersQueryHookResult = ReturnType<typeof useAllLoyaltyPartnersQuery>;
export type AllLoyaltyPartnersLazyQueryHookResult = ReturnType<typeof useAllLoyaltyPartnersLazyQuery>;
export type AllLoyaltyPartnersQueryResult = ApolloReactCommon.QueryResult<
  AllLoyaltyPartnersQuery,
  AllLoyaltyPartnersQueryVariables
>;
export const AllLoyaltyPromoCodesDocument = gql`
  query allLoyaltyPromoCodes($query: DefaultQueryInput) {
    allLoyaltyPromoCodes(query: $query) {
      ...loyaltyPromoCodeConnection
    }
  }
  ${LoyaltyPromoCodeConnectionFragmentDoc}
`;
export type AllLoyaltyPromoCodesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllLoyaltyPromoCodesQuery, AllLoyaltyPromoCodesQueryVariables>,
  'query'
>;

export const AllLoyaltyPromoCodesComponent = (props: AllLoyaltyPromoCodesComponentProps) => (
  <ApolloReactComponents.Query<AllLoyaltyPromoCodesQuery, AllLoyaltyPromoCodesQueryVariables>
    query={AllLoyaltyPromoCodesDocument}
    {...props}
  />
);

export type AllLoyaltyPromoCodesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllLoyaltyPromoCodesQuery,
  AllLoyaltyPromoCodesQueryVariables
> &
  TChildProps;
export function withAllLoyaltyPromoCodes<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllLoyaltyPromoCodesQuery,
    AllLoyaltyPromoCodesQueryVariables,
    AllLoyaltyPromoCodesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllLoyaltyPromoCodesQuery,
    AllLoyaltyPromoCodesQueryVariables,
    AllLoyaltyPromoCodesProps<TChildProps>
  >(AllLoyaltyPromoCodesDocument, {
    alias: 'allLoyaltyPromoCodes',
    ...operationOptions,
  });
}

/**
 * __useAllLoyaltyPromoCodesQuery__
 *
 * To run a query within a React component, call `useAllLoyaltyPromoCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLoyaltyPromoCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLoyaltyPromoCodesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllLoyaltyPromoCodesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllLoyaltyPromoCodesQuery, AllLoyaltyPromoCodesQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllLoyaltyPromoCodesQuery, AllLoyaltyPromoCodesQueryVariables>(
    AllLoyaltyPromoCodesDocument,
    baseOptions
  );
}
export function useAllLoyaltyPromoCodesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllLoyaltyPromoCodesQuery, AllLoyaltyPromoCodesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllLoyaltyPromoCodesQuery, AllLoyaltyPromoCodesQueryVariables>(
    AllLoyaltyPromoCodesDocument,
    baseOptions
  );
}
export type AllLoyaltyPromoCodesQueryHookResult = ReturnType<typeof useAllLoyaltyPromoCodesQuery>;
export type AllLoyaltyPromoCodesLazyQueryHookResult = ReturnType<typeof useAllLoyaltyPromoCodesLazyQuery>;
export type AllLoyaltyPromoCodesQueryResult = ApolloReactCommon.QueryResult<
  AllLoyaltyPromoCodesQuery,
  AllLoyaltyPromoCodesQueryVariables
>;
export const AllLoyaltyPromosDocument = gql`
  query allLoyaltyPromos($query: DefaultQueryInput) {
    allLoyaltyPromos(query: $query) {
      ...loyaltyPromoConnection
    }
  }
  ${LoyaltyPromoConnectionFragmentDoc}
`;
export type AllLoyaltyPromosComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllLoyaltyPromosQuery, AllLoyaltyPromosQueryVariables>,
  'query'
>;

export const AllLoyaltyPromosComponent = (props: AllLoyaltyPromosComponentProps) => (
  <ApolloReactComponents.Query<AllLoyaltyPromosQuery, AllLoyaltyPromosQueryVariables>
    query={AllLoyaltyPromosDocument}
    {...props}
  />
);

export type AllLoyaltyPromosProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllLoyaltyPromosQuery,
  AllLoyaltyPromosQueryVariables
> &
  TChildProps;
export function withAllLoyaltyPromos<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllLoyaltyPromosQuery,
    AllLoyaltyPromosQueryVariables,
    AllLoyaltyPromosProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllLoyaltyPromosQuery,
    AllLoyaltyPromosQueryVariables,
    AllLoyaltyPromosProps<TChildProps>
  >(AllLoyaltyPromosDocument, {
    alias: 'allLoyaltyPromos',
    ...operationOptions,
  });
}

/**
 * __useAllLoyaltyPromosQuery__
 *
 * To run a query within a React component, call `useAllLoyaltyPromosQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLoyaltyPromosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLoyaltyPromosQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllLoyaltyPromosQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllLoyaltyPromosQuery, AllLoyaltyPromosQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllLoyaltyPromosQuery, AllLoyaltyPromosQueryVariables>(
    AllLoyaltyPromosDocument,
    baseOptions
  );
}
export function useAllLoyaltyPromosLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllLoyaltyPromosQuery, AllLoyaltyPromosQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllLoyaltyPromosQuery, AllLoyaltyPromosQueryVariables>(
    AllLoyaltyPromosDocument,
    baseOptions
  );
}
export type AllLoyaltyPromosQueryHookResult = ReturnType<typeof useAllLoyaltyPromosQuery>;
export type AllLoyaltyPromosLazyQueryHookResult = ReturnType<typeof useAllLoyaltyPromosLazyQuery>;
export type AllLoyaltyPromosQueryResult = ApolloReactCommon.QueryResult<
  AllLoyaltyPromosQuery,
  AllLoyaltyPromosQueryVariables
>;
export const AllMessengerMailingsDocument = gql`
  query allMessengerMailings($query: DefaultQueryInput) {
    allMessengerMailings(query: $query) {
      ...messengerMailingConnection
    }
  }
  ${MessengerMailingConnectionFragmentDoc}
`;
export type AllMessengerMailingsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllMessengerMailingsQuery, AllMessengerMailingsQueryVariables>,
  'query'
>;

export const AllMessengerMailingsComponent = (props: AllMessengerMailingsComponentProps) => (
  <ApolloReactComponents.Query<AllMessengerMailingsQuery, AllMessengerMailingsQueryVariables>
    query={AllMessengerMailingsDocument}
    {...props}
  />
);

export type AllMessengerMailingsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllMessengerMailingsQuery,
  AllMessengerMailingsQueryVariables
> &
  TChildProps;
export function withAllMessengerMailings<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllMessengerMailingsQuery,
    AllMessengerMailingsQueryVariables,
    AllMessengerMailingsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllMessengerMailingsQuery,
    AllMessengerMailingsQueryVariables,
    AllMessengerMailingsProps<TChildProps>
  >(AllMessengerMailingsDocument, {
    alias: 'allMessengerMailings',
    ...operationOptions,
  });
}

/**
 * __useAllMessengerMailingsQuery__
 *
 * To run a query within a React component, call `useAllMessengerMailingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMessengerMailingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMessengerMailingsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllMessengerMailingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllMessengerMailingsQuery, AllMessengerMailingsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllMessengerMailingsQuery, AllMessengerMailingsQueryVariables>(
    AllMessengerMailingsDocument,
    baseOptions
  );
}
export function useAllMessengerMailingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllMessengerMailingsQuery, AllMessengerMailingsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllMessengerMailingsQuery, AllMessengerMailingsQueryVariables>(
    AllMessengerMailingsDocument,
    baseOptions
  );
}
export type AllMessengerMailingsQueryHookResult = ReturnType<typeof useAllMessengerMailingsQuery>;
export type AllMessengerMailingsLazyQueryHookResult = ReturnType<typeof useAllMessengerMailingsLazyQuery>;
export type AllMessengerMailingsQueryResult = ApolloReactCommon.QueryResult<
  AllMessengerMailingsQuery,
  AllMessengerMailingsQueryVariables
>;
export const AllMessengerQueueNotificationsDocument = gql`
  query allMessengerQueueNotifications($query: DefaultQueryInput) {
    allMessengerQueueNotifications(query: $query) {
      ...messengerQueueNotificationConnection
    }
  }
  ${MessengerQueueNotificationConnectionFragmentDoc}
`;
export type AllMessengerQueueNotificationsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AllMessengerQueueNotificationsQuery,
    AllMessengerQueueNotificationsQueryVariables
  >,
  'query'
>;

export const AllMessengerQueueNotificationsComponent = (props: AllMessengerQueueNotificationsComponentProps) => (
  <ApolloReactComponents.Query<AllMessengerQueueNotificationsQuery, AllMessengerQueueNotificationsQueryVariables>
    query={AllMessengerQueueNotificationsDocument}
    {...props}
  />
);

export type AllMessengerQueueNotificationsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllMessengerQueueNotificationsQuery,
  AllMessengerQueueNotificationsQueryVariables
> &
  TChildProps;
export function withAllMessengerQueueNotifications<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllMessengerQueueNotificationsQuery,
    AllMessengerQueueNotificationsQueryVariables,
    AllMessengerQueueNotificationsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllMessengerQueueNotificationsQuery,
    AllMessengerQueueNotificationsQueryVariables,
    AllMessengerQueueNotificationsProps<TChildProps>
  >(AllMessengerQueueNotificationsDocument, {
    alias: 'allMessengerQueueNotifications',
    ...operationOptions,
  });
}

/**
 * __useAllMessengerQueueNotificationsQuery__
 *
 * To run a query within a React component, call `useAllMessengerQueueNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMessengerQueueNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMessengerQueueNotificationsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllMessengerQueueNotificationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllMessengerQueueNotificationsQuery,
    AllMessengerQueueNotificationsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<AllMessengerQueueNotificationsQuery, AllMessengerQueueNotificationsQueryVariables>(
    AllMessengerQueueNotificationsDocument,
    baseOptions
  );
}
export function useAllMessengerQueueNotificationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllMessengerQueueNotificationsQuery,
    AllMessengerQueueNotificationsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AllMessengerQueueNotificationsQuery,
    AllMessengerQueueNotificationsQueryVariables
  >(AllMessengerQueueNotificationsDocument, baseOptions);
}
export type AllMessengerQueueNotificationsQueryHookResult = ReturnType<typeof useAllMessengerQueueNotificationsQuery>;
export type AllMessengerQueueNotificationsLazyQueryHookResult = ReturnType<
  typeof useAllMessengerQueueNotificationsLazyQuery
>;
export type AllMessengerQueueNotificationsQueryResult = ApolloReactCommon.QueryResult<
  AllMessengerQueueNotificationsQuery,
  AllMessengerQueueNotificationsQueryVariables
>;
export const AllMessengerQueueTasksDocument = gql`
  query allMessengerQueueTasks($query: DefaultQueryInput) {
    allMessengerQueueTasks(query: $query) {
      ...messengerQueueTaskConnection
    }
  }
  ${MessengerQueueTaskConnectionFragmentDoc}
`;
export type AllMessengerQueueTasksComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllMessengerQueueTasksQuery, AllMessengerQueueTasksQueryVariables>,
  'query'
>;

export const AllMessengerQueueTasksComponent = (props: AllMessengerQueueTasksComponentProps) => (
  <ApolloReactComponents.Query<AllMessengerQueueTasksQuery, AllMessengerQueueTasksQueryVariables>
    query={AllMessengerQueueTasksDocument}
    {...props}
  />
);

export type AllMessengerQueueTasksProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllMessengerQueueTasksQuery,
  AllMessengerQueueTasksQueryVariables
> &
  TChildProps;
export function withAllMessengerQueueTasks<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllMessengerQueueTasksQuery,
    AllMessengerQueueTasksQueryVariables,
    AllMessengerQueueTasksProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllMessengerQueueTasksQuery,
    AllMessengerQueueTasksQueryVariables,
    AllMessengerQueueTasksProps<TChildProps>
  >(AllMessengerQueueTasksDocument, {
    alias: 'allMessengerQueueTasks',
    ...operationOptions,
  });
}

/**
 * __useAllMessengerQueueTasksQuery__
 *
 * To run a query within a React component, call `useAllMessengerQueueTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMessengerQueueTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMessengerQueueTasksQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllMessengerQueueTasksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllMessengerQueueTasksQuery, AllMessengerQueueTasksQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllMessengerQueueTasksQuery, AllMessengerQueueTasksQueryVariables>(
    AllMessengerQueueTasksDocument,
    baseOptions
  );
}
export function useAllMessengerQueueTasksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllMessengerQueueTasksQuery, AllMessengerQueueTasksQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllMessengerQueueTasksQuery, AllMessengerQueueTasksQueryVariables>(
    AllMessengerQueueTasksDocument,
    baseOptions
  );
}
export type AllMessengerQueueTasksQueryHookResult = ReturnType<typeof useAllMessengerQueueTasksQuery>;
export type AllMessengerQueueTasksLazyQueryHookResult = ReturnType<typeof useAllMessengerQueueTasksLazyQuery>;
export type AllMessengerQueueTasksQueryResult = ApolloReactCommon.QueryResult<
  AllMessengerQueueTasksQuery,
  AllMessengerQueueTasksQueryVariables
>;
export const AllMessengerTemplatesDocument = gql`
  query allMessengerTemplates($query: DefaultQueryInput) {
    allMessengerTemplates(query: $query) {
      ...messengerTemplateConnection
    }
  }
  ${MessengerTemplateConnectionFragmentDoc}
`;
export type AllMessengerTemplatesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllMessengerTemplatesQuery, AllMessengerTemplatesQueryVariables>,
  'query'
>;

export const AllMessengerTemplatesComponent = (props: AllMessengerTemplatesComponentProps) => (
  <ApolloReactComponents.Query<AllMessengerTemplatesQuery, AllMessengerTemplatesQueryVariables>
    query={AllMessengerTemplatesDocument}
    {...props}
  />
);

export type AllMessengerTemplatesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllMessengerTemplatesQuery,
  AllMessengerTemplatesQueryVariables
> &
  TChildProps;
export function withAllMessengerTemplates<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllMessengerTemplatesQuery,
    AllMessengerTemplatesQueryVariables,
    AllMessengerTemplatesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllMessengerTemplatesQuery,
    AllMessengerTemplatesQueryVariables,
    AllMessengerTemplatesProps<TChildProps>
  >(AllMessengerTemplatesDocument, {
    alias: 'allMessengerTemplates',
    ...operationOptions,
  });
}

/**
 * __useAllMessengerTemplatesQuery__
 *
 * To run a query within a React component, call `useAllMessengerTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMessengerTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMessengerTemplatesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllMessengerTemplatesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllMessengerTemplatesQuery, AllMessengerTemplatesQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllMessengerTemplatesQuery, AllMessengerTemplatesQueryVariables>(
    AllMessengerTemplatesDocument,
    baseOptions
  );
}
export function useAllMessengerTemplatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllMessengerTemplatesQuery, AllMessengerTemplatesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllMessengerTemplatesQuery, AllMessengerTemplatesQueryVariables>(
    AllMessengerTemplatesDocument,
    baseOptions
  );
}
export type AllMessengerTemplatesQueryHookResult = ReturnType<typeof useAllMessengerTemplatesQuery>;
export type AllMessengerTemplatesLazyQueryHookResult = ReturnType<typeof useAllMessengerTemplatesLazyQuery>;
export type AllMessengerTemplatesQueryResult = ApolloReactCommon.QueryResult<
  AllMessengerTemplatesQuery,
  AllMessengerTemplatesQueryVariables
>;
export const AllMessengerTransportsDocument = gql`
  query allMessengerTransports($query: DefaultQueryInput) {
    allMessengerTransports(query: $query) {
      ...messengerTransportConnection
    }
  }
  ${MessengerTransportConnectionFragmentDoc}
`;
export type AllMessengerTransportsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllMessengerTransportsQuery, AllMessengerTransportsQueryVariables>,
  'query'
>;

export const AllMessengerTransportsComponent = (props: AllMessengerTransportsComponentProps) => (
  <ApolloReactComponents.Query<AllMessengerTransportsQuery, AllMessengerTransportsQueryVariables>
    query={AllMessengerTransportsDocument}
    {...props}
  />
);

export type AllMessengerTransportsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllMessengerTransportsQuery,
  AllMessengerTransportsQueryVariables
> &
  TChildProps;
export function withAllMessengerTransports<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllMessengerTransportsQuery,
    AllMessengerTransportsQueryVariables,
    AllMessengerTransportsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllMessengerTransportsQuery,
    AllMessengerTransportsQueryVariables,
    AllMessengerTransportsProps<TChildProps>
  >(AllMessengerTransportsDocument, {
    alias: 'allMessengerTransports',
    ...operationOptions,
  });
}

/**
 * __useAllMessengerTransportsQuery__
 *
 * To run a query within a React component, call `useAllMessengerTransportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMessengerTransportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMessengerTransportsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllMessengerTransportsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllMessengerTransportsQuery, AllMessengerTransportsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllMessengerTransportsQuery, AllMessengerTransportsQueryVariables>(
    AllMessengerTransportsDocument,
    baseOptions
  );
}
export function useAllMessengerTransportsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllMessengerTransportsQuery, AllMessengerTransportsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllMessengerTransportsQuery, AllMessengerTransportsQueryVariables>(
    AllMessengerTransportsDocument,
    baseOptions
  );
}
export type AllMessengerTransportsQueryHookResult = ReturnType<typeof useAllMessengerTransportsQuery>;
export type AllMessengerTransportsLazyQueryHookResult = ReturnType<typeof useAllMessengerTransportsLazyQuery>;
export type AllMessengerTransportsQueryResult = ApolloReactCommon.QueryResult<
  AllMessengerTransportsQuery,
  AllMessengerTransportsQueryVariables
>;
export const AllMobileTranslationsDocument = gql`
  query allMobileTranslations($locale: String!) {
    allMobileTranslations(locale: $locale) {
      ...mobileTranslation
    }
  }
  ${MobileTranslationFragmentDoc}
`;
export type AllMobileTranslationsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllMobileTranslationsQuery, AllMobileTranslationsQueryVariables>,
  'query'
> &
  ({ variables: AllMobileTranslationsQueryVariables; skip?: boolean } | { skip: boolean });

export const AllMobileTranslationsComponent = (props: AllMobileTranslationsComponentProps) => (
  <ApolloReactComponents.Query<AllMobileTranslationsQuery, AllMobileTranslationsQueryVariables>
    query={AllMobileTranslationsDocument}
    {...props}
  />
);

export type AllMobileTranslationsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllMobileTranslationsQuery,
  AllMobileTranslationsQueryVariables
> &
  TChildProps;
export function withAllMobileTranslations<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllMobileTranslationsQuery,
    AllMobileTranslationsQueryVariables,
    AllMobileTranslationsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllMobileTranslationsQuery,
    AllMobileTranslationsQueryVariables,
    AllMobileTranslationsProps<TChildProps>
  >(AllMobileTranslationsDocument, {
    alias: 'allMobileTranslations',
    ...operationOptions,
  });
}

/**
 * __useAllMobileTranslationsQuery__
 *
 * To run a query within a React component, call `useAllMobileTranslationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMobileTranslationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMobileTranslationsQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useAllMobileTranslationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllMobileTranslationsQuery, AllMobileTranslationsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllMobileTranslationsQuery, AllMobileTranslationsQueryVariables>(
    AllMobileTranslationsDocument,
    baseOptions
  );
}
export function useAllMobileTranslationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllMobileTranslationsQuery, AllMobileTranslationsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllMobileTranslationsQuery, AllMobileTranslationsQueryVariables>(
    AllMobileTranslationsDocument,
    baseOptions
  );
}
export type AllMobileTranslationsQueryHookResult = ReturnType<typeof useAllMobileTranslationsQuery>;
export type AllMobileTranslationsLazyQueryHookResult = ReturnType<typeof useAllMobileTranslationsLazyQuery>;
export type AllMobileTranslationsQueryResult = ApolloReactCommon.QueryResult<
  AllMobileTranslationsQuery,
  AllMobileTranslationsQueryVariables
>;
export const AllPaymentAccountsDocument = gql`
  query allPaymentAccounts($query: DefaultQueryInput) {
    allPaymentAccounts(query: $query) {
      ...paymentAccountConnection
    }
  }
  ${PaymentAccountConnectionFragmentDoc}
`;
export type AllPaymentAccountsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllPaymentAccountsQuery, AllPaymentAccountsQueryVariables>,
  'query'
>;

export const AllPaymentAccountsComponent = (props: AllPaymentAccountsComponentProps) => (
  <ApolloReactComponents.Query<AllPaymentAccountsQuery, AllPaymentAccountsQueryVariables>
    query={AllPaymentAccountsDocument}
    {...props}
  />
);

export type AllPaymentAccountsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllPaymentAccountsQuery,
  AllPaymentAccountsQueryVariables
> &
  TChildProps;
export function withAllPaymentAccounts<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllPaymentAccountsQuery,
    AllPaymentAccountsQueryVariables,
    AllPaymentAccountsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllPaymentAccountsQuery,
    AllPaymentAccountsQueryVariables,
    AllPaymentAccountsProps<TChildProps>
  >(AllPaymentAccountsDocument, {
    alias: 'allPaymentAccounts',
    ...operationOptions,
  });
}

/**
 * __useAllPaymentAccountsQuery__
 *
 * To run a query within a React component, call `useAllPaymentAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPaymentAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPaymentAccountsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllPaymentAccountsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllPaymentAccountsQuery, AllPaymentAccountsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllPaymentAccountsQuery, AllPaymentAccountsQueryVariables>(
    AllPaymentAccountsDocument,
    baseOptions
  );
}
export function useAllPaymentAccountsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllPaymentAccountsQuery, AllPaymentAccountsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllPaymentAccountsQuery, AllPaymentAccountsQueryVariables>(
    AllPaymentAccountsDocument,
    baseOptions
  );
}
export type AllPaymentAccountsQueryHookResult = ReturnType<typeof useAllPaymentAccountsQuery>;
export type AllPaymentAccountsLazyQueryHookResult = ReturnType<typeof useAllPaymentAccountsLazyQuery>;
export type AllPaymentAccountsQueryResult = ApolloReactCommon.QueryResult<
  AllPaymentAccountsQuery,
  AllPaymentAccountsQueryVariables
>;
export const AllPaymentCurrenciesDocument = gql`
  query allPaymentCurrencies($query: DefaultQueryInput) {
    allPaymentCurrencies(query: $query) {
      ...paymentCurrencyConnection
    }
  }
  ${PaymentCurrencyConnectionFragmentDoc}
`;
export type AllPaymentCurrenciesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllPaymentCurrenciesQuery, AllPaymentCurrenciesQueryVariables>,
  'query'
>;

export const AllPaymentCurrenciesComponent = (props: AllPaymentCurrenciesComponentProps) => (
  <ApolloReactComponents.Query<AllPaymentCurrenciesQuery, AllPaymentCurrenciesQueryVariables>
    query={AllPaymentCurrenciesDocument}
    {...props}
  />
);

export type AllPaymentCurrenciesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllPaymentCurrenciesQuery,
  AllPaymentCurrenciesQueryVariables
> &
  TChildProps;
export function withAllPaymentCurrencies<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllPaymentCurrenciesQuery,
    AllPaymentCurrenciesQueryVariables,
    AllPaymentCurrenciesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllPaymentCurrenciesQuery,
    AllPaymentCurrenciesQueryVariables,
    AllPaymentCurrenciesProps<TChildProps>
  >(AllPaymentCurrenciesDocument, {
    alias: 'allPaymentCurrencies',
    ...operationOptions,
  });
}

/**
 * __useAllPaymentCurrenciesQuery__
 *
 * To run a query within a React component, call `useAllPaymentCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPaymentCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPaymentCurrenciesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllPaymentCurrenciesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllPaymentCurrenciesQuery, AllPaymentCurrenciesQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllPaymentCurrenciesQuery, AllPaymentCurrenciesQueryVariables>(
    AllPaymentCurrenciesDocument,
    baseOptions
  );
}
export function useAllPaymentCurrenciesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllPaymentCurrenciesQuery, AllPaymentCurrenciesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllPaymentCurrenciesQuery, AllPaymentCurrenciesQueryVariables>(
    AllPaymentCurrenciesDocument,
    baseOptions
  );
}
export type AllPaymentCurrenciesQueryHookResult = ReturnType<typeof useAllPaymentCurrenciesQuery>;
export type AllPaymentCurrenciesLazyQueryHookResult = ReturnType<typeof useAllPaymentCurrenciesLazyQuery>;
export type AllPaymentCurrenciesQueryResult = ApolloReactCommon.QueryResult<
  AllPaymentCurrenciesQuery,
  AllPaymentCurrenciesQueryVariables
>;
export const AllPaymentGatewaiesDocument = gql`
  query allPaymentGatewaies($query: DefaultQueryInput) {
    allPaymentGatewaies(query: $query) {
      ...paymentGatewayConnection
    }
  }
  ${PaymentGatewayConnectionFragmentDoc}
`;
export type AllPaymentGatewaiesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllPaymentGatewaiesQuery, AllPaymentGatewaiesQueryVariables>,
  'query'
>;

export const AllPaymentGatewaiesComponent = (props: AllPaymentGatewaiesComponentProps) => (
  <ApolloReactComponents.Query<AllPaymentGatewaiesQuery, AllPaymentGatewaiesQueryVariables>
    query={AllPaymentGatewaiesDocument}
    {...props}
  />
);

export type AllPaymentGatewaiesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllPaymentGatewaiesQuery,
  AllPaymentGatewaiesQueryVariables
> &
  TChildProps;
export function withAllPaymentGatewaies<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllPaymentGatewaiesQuery,
    AllPaymentGatewaiesQueryVariables,
    AllPaymentGatewaiesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllPaymentGatewaiesQuery,
    AllPaymentGatewaiesQueryVariables,
    AllPaymentGatewaiesProps<TChildProps>
  >(AllPaymentGatewaiesDocument, {
    alias: 'allPaymentGatewaies',
    ...operationOptions,
  });
}

/**
 * __useAllPaymentGatewaiesQuery__
 *
 * To run a query within a React component, call `useAllPaymentGatewaiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPaymentGatewaiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPaymentGatewaiesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllPaymentGatewaiesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllPaymentGatewaiesQuery, AllPaymentGatewaiesQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllPaymentGatewaiesQuery, AllPaymentGatewaiesQueryVariables>(
    AllPaymentGatewaiesDocument,
    baseOptions
  );
}
export function useAllPaymentGatewaiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllPaymentGatewaiesQuery, AllPaymentGatewaiesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllPaymentGatewaiesQuery, AllPaymentGatewaiesQueryVariables>(
    AllPaymentGatewaiesDocument,
    baseOptions
  );
}
export type AllPaymentGatewaiesQueryHookResult = ReturnType<typeof useAllPaymentGatewaiesQuery>;
export type AllPaymentGatewaiesLazyQueryHookResult = ReturnType<typeof useAllPaymentGatewaiesLazyQuery>;
export type AllPaymentGatewaiesQueryResult = ApolloReactCommon.QueryResult<
  AllPaymentGatewaiesQuery,
  AllPaymentGatewaiesQueryVariables
>;
export const AllPaymentProvidersDocument = gql`
  query allPaymentProviders($query: DefaultQueryInput) {
    allPaymentProviders(query: $query) {
      ...paymentProviderConnection
    }
  }
  ${PaymentProviderConnectionFragmentDoc}
`;
export type AllPaymentProvidersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllPaymentProvidersQuery, AllPaymentProvidersQueryVariables>,
  'query'
>;

export const AllPaymentProvidersComponent = (props: AllPaymentProvidersComponentProps) => (
  <ApolloReactComponents.Query<AllPaymentProvidersQuery, AllPaymentProvidersQueryVariables>
    query={AllPaymentProvidersDocument}
    {...props}
  />
);

export type AllPaymentProvidersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllPaymentProvidersQuery,
  AllPaymentProvidersQueryVariables
> &
  TChildProps;
export function withAllPaymentProviders<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllPaymentProvidersQuery,
    AllPaymentProvidersQueryVariables,
    AllPaymentProvidersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllPaymentProvidersQuery,
    AllPaymentProvidersQueryVariables,
    AllPaymentProvidersProps<TChildProps>
  >(AllPaymentProvidersDocument, {
    alias: 'allPaymentProviders',
    ...operationOptions,
  });
}

/**
 * __useAllPaymentProvidersQuery__
 *
 * To run a query within a React component, call `useAllPaymentProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPaymentProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPaymentProvidersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllPaymentProvidersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllPaymentProvidersQuery, AllPaymentProvidersQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllPaymentProvidersQuery, AllPaymentProvidersQueryVariables>(
    AllPaymentProvidersDocument,
    baseOptions
  );
}
export function useAllPaymentProvidersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllPaymentProvidersQuery, AllPaymentProvidersQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllPaymentProvidersQuery, AllPaymentProvidersQueryVariables>(
    AllPaymentProvidersDocument,
    baseOptions
  );
}
export type AllPaymentProvidersQueryHookResult = ReturnType<typeof useAllPaymentProvidersQuery>;
export type AllPaymentProvidersLazyQueryHookResult = ReturnType<typeof useAllPaymentProvidersLazyQuery>;
export type AllPaymentProvidersQueryResult = ApolloReactCommon.QueryResult<
  AllPaymentProvidersQuery,
  AllPaymentProvidersQueryVariables
>;
export const AllPaymentTransactionsDocument = gql`
  query allPaymentTransactions($query: DefaultQueryInput) {
    allPaymentTransactions(query: $query) {
      ...paymentTransactionConnection
    }
  }
  ${PaymentTransactionConnectionFragmentDoc}
`;
export type AllPaymentTransactionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllPaymentTransactionsQuery, AllPaymentTransactionsQueryVariables>,
  'query'
>;

export const AllPaymentTransactionsComponent = (props: AllPaymentTransactionsComponentProps) => (
  <ApolloReactComponents.Query<AllPaymentTransactionsQuery, AllPaymentTransactionsQueryVariables>
    query={AllPaymentTransactionsDocument}
    {...props}
  />
);

export type AllPaymentTransactionsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllPaymentTransactionsQuery,
  AllPaymentTransactionsQueryVariables
> &
  TChildProps;
export function withAllPaymentTransactions<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllPaymentTransactionsQuery,
    AllPaymentTransactionsQueryVariables,
    AllPaymentTransactionsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllPaymentTransactionsQuery,
    AllPaymentTransactionsQueryVariables,
    AllPaymentTransactionsProps<TChildProps>
  >(AllPaymentTransactionsDocument, {
    alias: 'allPaymentTransactions',
    ...operationOptions,
  });
}

/**
 * __useAllPaymentTransactionsQuery__
 *
 * To run a query within a React component, call `useAllPaymentTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPaymentTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPaymentTransactionsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllPaymentTransactionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllPaymentTransactionsQuery, AllPaymentTransactionsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllPaymentTransactionsQuery, AllPaymentTransactionsQueryVariables>(
    AllPaymentTransactionsDocument,
    baseOptions
  );
}
export function useAllPaymentTransactionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllPaymentTransactionsQuery, AllPaymentTransactionsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllPaymentTransactionsQuery, AllPaymentTransactionsQueryVariables>(
    AllPaymentTransactionsDocument,
    baseOptions
  );
}
export type AllPaymentTransactionsQueryHookResult = ReturnType<typeof useAllPaymentTransactionsQuery>;
export type AllPaymentTransactionsLazyQueryHookResult = ReturnType<typeof useAllPaymentTransactionsLazyQuery>;
export type AllPaymentTransactionsQueryResult = ApolloReactCommon.QueryResult<
  AllPaymentTransactionsQuery,
  AllPaymentTransactionsQueryVariables
>;
export const AllSystemAsyncTasksDocument = gql`
  query allSystemAsyncTasks($query: DefaultQueryInput) {
    allSystemAsyncTasks(query: $query) {
      ...systemAsyncTaskConnection
    }
  }
  ${SystemAsyncTaskConnectionFragmentDoc}
`;
export type AllSystemAsyncTasksComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllSystemAsyncTasksQuery, AllSystemAsyncTasksQueryVariables>,
  'query'
>;

export const AllSystemAsyncTasksComponent = (props: AllSystemAsyncTasksComponentProps) => (
  <ApolloReactComponents.Query<AllSystemAsyncTasksQuery, AllSystemAsyncTasksQueryVariables>
    query={AllSystemAsyncTasksDocument}
    {...props}
  />
);

export type AllSystemAsyncTasksProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllSystemAsyncTasksQuery,
  AllSystemAsyncTasksQueryVariables
> &
  TChildProps;
export function withAllSystemAsyncTasks<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllSystemAsyncTasksQuery,
    AllSystemAsyncTasksQueryVariables,
    AllSystemAsyncTasksProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllSystemAsyncTasksQuery,
    AllSystemAsyncTasksQueryVariables,
    AllSystemAsyncTasksProps<TChildProps>
  >(AllSystemAsyncTasksDocument, {
    alias: 'allSystemAsyncTasks',
    ...operationOptions,
  });
}

/**
 * __useAllSystemAsyncTasksQuery__
 *
 * To run a query within a React component, call `useAllSystemAsyncTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSystemAsyncTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSystemAsyncTasksQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllSystemAsyncTasksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllSystemAsyncTasksQuery, AllSystemAsyncTasksQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllSystemAsyncTasksQuery, AllSystemAsyncTasksQueryVariables>(
    AllSystemAsyncTasksDocument,
    baseOptions
  );
}
export function useAllSystemAsyncTasksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllSystemAsyncTasksQuery, AllSystemAsyncTasksQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllSystemAsyncTasksQuery, AllSystemAsyncTasksQueryVariables>(
    AllSystemAsyncTasksDocument,
    baseOptions
  );
}
export type AllSystemAsyncTasksQueryHookResult = ReturnType<typeof useAllSystemAsyncTasksQuery>;
export type AllSystemAsyncTasksLazyQueryHookResult = ReturnType<typeof useAllSystemAsyncTasksLazyQuery>;
export type AllSystemAsyncTasksQueryResult = ApolloReactCommon.QueryResult<
  AllSystemAsyncTasksQuery,
  AllSystemAsyncTasksQueryVariables
>;
export const AllSystemLocalesDocument = gql`
  query allSystemLocales($query: DefaultQueryInput) {
    allSystemLocales(query: $query) {
      ...systemLocaleConnection
    }
  }
  ${SystemLocaleConnectionFragmentDoc}
`;
export type AllSystemLocalesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllSystemLocalesQuery, AllSystemLocalesQueryVariables>,
  'query'
>;

export const AllSystemLocalesComponent = (props: AllSystemLocalesComponentProps) => (
  <ApolloReactComponents.Query<AllSystemLocalesQuery, AllSystemLocalesQueryVariables>
    query={AllSystemLocalesDocument}
    {...props}
  />
);

export type AllSystemLocalesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllSystemLocalesQuery,
  AllSystemLocalesQueryVariables
> &
  TChildProps;
export function withAllSystemLocales<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllSystemLocalesQuery,
    AllSystemLocalesQueryVariables,
    AllSystemLocalesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllSystemLocalesQuery,
    AllSystemLocalesQueryVariables,
    AllSystemLocalesProps<TChildProps>
  >(AllSystemLocalesDocument, {
    alias: 'allSystemLocales',
    ...operationOptions,
  });
}

/**
 * __useAllSystemLocalesQuery__
 *
 * To run a query within a React component, call `useAllSystemLocalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSystemLocalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSystemLocalesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllSystemLocalesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllSystemLocalesQuery, AllSystemLocalesQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllSystemLocalesQuery, AllSystemLocalesQueryVariables>(
    AllSystemLocalesDocument,
    baseOptions
  );
}
export function useAllSystemLocalesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllSystemLocalesQuery, AllSystemLocalesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllSystemLocalesQuery, AllSystemLocalesQueryVariables>(
    AllSystemLocalesDocument,
    baseOptions
  );
}
export type AllSystemLocalesQueryHookResult = ReturnType<typeof useAllSystemLocalesQuery>;
export type AllSystemLocalesLazyQueryHookResult = ReturnType<typeof useAllSystemLocalesLazyQuery>;
export type AllSystemLocalesQueryResult = ApolloReactCommon.QueryResult<
  AllSystemLocalesQuery,
  AllSystemLocalesQueryVariables
>;
export const AllSystemPrintersDocument = gql`
  query allSystemPrinters($query: DefaultQueryInput) {
    allSystemPrinters(query: $query) {
      ...systemPrinterConnection
    }
  }
  ${SystemPrinterConnectionFragmentDoc}
`;
export type AllSystemPrintersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllSystemPrintersQuery, AllSystemPrintersQueryVariables>,
  'query'
>;

export const AllSystemPrintersComponent = (props: AllSystemPrintersComponentProps) => (
  <ApolloReactComponents.Query<AllSystemPrintersQuery, AllSystemPrintersQueryVariables>
    query={AllSystemPrintersDocument}
    {...props}
  />
);

export type AllSystemPrintersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllSystemPrintersQuery,
  AllSystemPrintersQueryVariables
> &
  TChildProps;
export function withAllSystemPrinters<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllSystemPrintersQuery,
    AllSystemPrintersQueryVariables,
    AllSystemPrintersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllSystemPrintersQuery,
    AllSystemPrintersQueryVariables,
    AllSystemPrintersProps<TChildProps>
  >(AllSystemPrintersDocument, {
    alias: 'allSystemPrinters',
    ...operationOptions,
  });
}

/**
 * __useAllSystemPrintersQuery__
 *
 * To run a query within a React component, call `useAllSystemPrintersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSystemPrintersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSystemPrintersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllSystemPrintersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllSystemPrintersQuery, AllSystemPrintersQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllSystemPrintersQuery, AllSystemPrintersQueryVariables>(
    AllSystemPrintersDocument,
    baseOptions
  );
}
export function useAllSystemPrintersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllSystemPrintersQuery, AllSystemPrintersQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllSystemPrintersQuery, AllSystemPrintersQueryVariables>(
    AllSystemPrintersDocument,
    baseOptions
  );
}
export type AllSystemPrintersQueryHookResult = ReturnType<typeof useAllSystemPrintersQuery>;
export type AllSystemPrintersLazyQueryHookResult = ReturnType<typeof useAllSystemPrintersLazyQuery>;
export type AllSystemPrintersQueryResult = ApolloReactCommon.QueryResult<
  AllSystemPrintersQuery,
  AllSystemPrintersQueryVariables
>;
export const AllSystemTranslationsDocument = gql`
  query allSystemTranslations($query: DefaultQueryInput) {
    allSystemTranslations(query: $query) {
      ...systemTranslationConnection
    }
  }
  ${SystemTranslationConnectionFragmentDoc}
`;
export type AllSystemTranslationsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllSystemTranslationsQuery, AllSystemTranslationsQueryVariables>,
  'query'
>;

export const AllSystemTranslationsComponent = (props: AllSystemTranslationsComponentProps) => (
  <ApolloReactComponents.Query<AllSystemTranslationsQuery, AllSystemTranslationsQueryVariables>
    query={AllSystemTranslationsDocument}
    {...props}
  />
);

export type AllSystemTranslationsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllSystemTranslationsQuery,
  AllSystemTranslationsQueryVariables
> &
  TChildProps;
export function withAllSystemTranslations<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllSystemTranslationsQuery,
    AllSystemTranslationsQueryVariables,
    AllSystemTranslationsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllSystemTranslationsQuery,
    AllSystemTranslationsQueryVariables,
    AllSystemTranslationsProps<TChildProps>
  >(AllSystemTranslationsDocument, {
    alias: 'allSystemTranslations',
    ...operationOptions,
  });
}

/**
 * __useAllSystemTranslationsQuery__
 *
 * To run a query within a React component, call `useAllSystemTranslationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSystemTranslationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSystemTranslationsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllSystemTranslationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllSystemTranslationsQuery, AllSystemTranslationsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllSystemTranslationsQuery, AllSystemTranslationsQueryVariables>(
    AllSystemTranslationsDocument,
    baseOptions
  );
}
export function useAllSystemTranslationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllSystemTranslationsQuery, AllSystemTranslationsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllSystemTranslationsQuery, AllSystemTranslationsQueryVariables>(
    AllSystemTranslationsDocument,
    baseOptions
  );
}
export type AllSystemTranslationsQueryHookResult = ReturnType<typeof useAllSystemTranslationsQuery>;
export type AllSystemTranslationsLazyQueryHookResult = ReturnType<typeof useAllSystemTranslationsLazyQuery>;
export type AllSystemTranslationsQueryResult = ApolloReactCommon.QueryResult<
  AllSystemTranslationsQuery,
  AllSystemTranslationsQueryVariables
>;
export const AllSystemUsersDocument = gql`
  query allSystemUsers(
    $include: SystemUsersWhereInput
    $sort: [DefaultSortInput!]
    $search: String
    $cursor: Int
    $limit: Int
  ) {
    allSystemUsers(include: $include, sort: $sort, search: $search, cursor: $cursor, limit: $limit) {
      ...systemUsersConnection
    }
  }
  ${SystemUsersConnectionFragmentDoc}
`;
export type AllSystemUsersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllSystemUsersQuery, AllSystemUsersQueryVariables>,
  'query'
>;

export const AllSystemUsersComponent = (props: AllSystemUsersComponentProps) => (
  <ApolloReactComponents.Query<AllSystemUsersQuery, AllSystemUsersQueryVariables>
    query={AllSystemUsersDocument}
    {...props}
  />
);

export type AllSystemUsersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllSystemUsersQuery,
  AllSystemUsersQueryVariables
> &
  TChildProps;
export function withAllSystemUsers<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllSystemUsersQuery,
    AllSystemUsersQueryVariables,
    AllSystemUsersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllSystemUsersQuery,
    AllSystemUsersQueryVariables,
    AllSystemUsersProps<TChildProps>
  >(AllSystemUsersDocument, {
    alias: 'allSystemUsers',
    ...operationOptions,
  });
}

/**
 * __useAllSystemUsersQuery__
 *
 * To run a query within a React component, call `useAllSystemUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSystemUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSystemUsersQuery({
 *   variables: {
 *      include: // value for 'include'
 *      sort: // value for 'sort'
 *      search: // value for 'search'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAllSystemUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllSystemUsersQuery, AllSystemUsersQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllSystemUsersQuery, AllSystemUsersQueryVariables>(
    AllSystemUsersDocument,
    baseOptions
  );
}
export function useAllSystemUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllSystemUsersQuery, AllSystemUsersQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllSystemUsersQuery, AllSystemUsersQueryVariables>(
    AllSystemUsersDocument,
    baseOptions
  );
}
export type AllSystemUsersQueryHookResult = ReturnType<typeof useAllSystemUsersQuery>;
export type AllSystemUsersLazyQueryHookResult = ReturnType<typeof useAllSystemUsersLazyQuery>;
export type AllSystemUsersQueryResult = ApolloReactCommon.QueryResult<
  AllSystemUsersQuery,
  AllSystemUsersQueryVariables
>;
export const AllSystemWebHooksDocument = gql`
  query allSystemWebHooks($query: DefaultQueryInput) {
    allSystemWebHooks(query: $query) {
      ...systemWebHookConnection
    }
  }
  ${SystemWebHookConnectionFragmentDoc}
`;
export type AllSystemWebHooksComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllSystemWebHooksQuery, AllSystemWebHooksQueryVariables>,
  'query'
>;

export const AllSystemWebHooksComponent = (props: AllSystemWebHooksComponentProps) => (
  <ApolloReactComponents.Query<AllSystemWebHooksQuery, AllSystemWebHooksQueryVariables>
    query={AllSystemWebHooksDocument}
    {...props}
  />
);

export type AllSystemWebHooksProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllSystemWebHooksQuery,
  AllSystemWebHooksQueryVariables
> &
  TChildProps;
export function withAllSystemWebHooks<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllSystemWebHooksQuery,
    AllSystemWebHooksQueryVariables,
    AllSystemWebHooksProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllSystemWebHooksQuery,
    AllSystemWebHooksQueryVariables,
    AllSystemWebHooksProps<TChildProps>
  >(AllSystemWebHooksDocument, {
    alias: 'allSystemWebHooks',
    ...operationOptions,
  });
}

/**
 * __useAllSystemWebHooksQuery__
 *
 * To run a query within a React component, call `useAllSystemWebHooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSystemWebHooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSystemWebHooksQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllSystemWebHooksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllSystemWebHooksQuery, AllSystemWebHooksQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllSystemWebHooksQuery, AllSystemWebHooksQueryVariables>(
    AllSystemWebHooksDocument,
    baseOptions
  );
}
export function useAllSystemWebHooksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllSystemWebHooksQuery, AllSystemWebHooksQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllSystemWebHooksQuery, AllSystemWebHooksQueryVariables>(
    AllSystemWebHooksDocument,
    baseOptions
  );
}
export type AllSystemWebHooksQueryHookResult = ReturnType<typeof useAllSystemWebHooksQuery>;
export type AllSystemWebHooksLazyQueryHookResult = ReturnType<typeof useAllSystemWebHooksLazyQuery>;
export type AllSystemWebHooksQueryResult = ApolloReactCommon.QueryResult<
  AllSystemWebHooksQuery,
  AllSystemWebHooksQueryVariables
>;
export const AllTemplateApplePassesDocument = gql`
  query allTemplateApplePasses($query: DefaultQueryInput) {
    allTemplateApplePasses(query: $query) {
      ...templateApplePassConnection
    }
  }
  ${TemplateApplePassConnectionFragmentDoc}
`;
export type AllTemplateApplePassesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllTemplateApplePassesQuery, AllTemplateApplePassesQueryVariables>,
  'query'
>;

export const AllTemplateApplePassesComponent = (props: AllTemplateApplePassesComponentProps) => (
  <ApolloReactComponents.Query<AllTemplateApplePassesQuery, AllTemplateApplePassesQueryVariables>
    query={AllTemplateApplePassesDocument}
    {...props}
  />
);

export type AllTemplateApplePassesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllTemplateApplePassesQuery,
  AllTemplateApplePassesQueryVariables
> &
  TChildProps;
export function withAllTemplateApplePasses<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllTemplateApplePassesQuery,
    AllTemplateApplePassesQueryVariables,
    AllTemplateApplePassesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllTemplateApplePassesQuery,
    AllTemplateApplePassesQueryVariables,
    AllTemplateApplePassesProps<TChildProps>
  >(AllTemplateApplePassesDocument, {
    alias: 'allTemplateApplePasses',
    ...operationOptions,
  });
}

/**
 * __useAllTemplateApplePassesQuery__
 *
 * To run a query within a React component, call `useAllTemplateApplePassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTemplateApplePassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTemplateApplePassesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTemplateApplePassesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTemplateApplePassesQuery, AllTemplateApplePassesQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllTemplateApplePassesQuery, AllTemplateApplePassesQueryVariables>(
    AllTemplateApplePassesDocument,
    baseOptions
  );
}
export function useAllTemplateApplePassesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTemplateApplePassesQuery, AllTemplateApplePassesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllTemplateApplePassesQuery, AllTemplateApplePassesQueryVariables>(
    AllTemplateApplePassesDocument,
    baseOptions
  );
}
export type AllTemplateApplePassesQueryHookResult = ReturnType<typeof useAllTemplateApplePassesQuery>;
export type AllTemplateApplePassesLazyQueryHookResult = ReturnType<typeof useAllTemplateApplePassesLazyQuery>;
export type AllTemplateApplePassesQueryResult = ApolloReactCommon.QueryResult<
  AllTemplateApplePassesQuery,
  AllTemplateApplePassesQueryVariables
>;
export const AllTemplatePdFsDocument = gql`
  query allTemplatePDFs($query: DefaultQueryInput) {
    allTemplatePDFs(query: $query) {
      ...templatePDFConnection
    }
  }
  ${TemplatePdfConnectionFragmentDoc}
`;
export type AllTemplatePdFsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllTemplatePdFsQuery, AllTemplatePdFsQueryVariables>,
  'query'
>;

export const AllTemplatePdFsComponent = (props: AllTemplatePdFsComponentProps) => (
  <ApolloReactComponents.Query<AllTemplatePdFsQuery, AllTemplatePdFsQueryVariables>
    query={AllTemplatePdFsDocument}
    {...props}
  />
);

export type AllTemplatePdFsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllTemplatePdFsQuery,
  AllTemplatePdFsQueryVariables
> &
  TChildProps;
export function withAllTemplatePdFs<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllTemplatePdFsQuery,
    AllTemplatePdFsQueryVariables,
    AllTemplatePdFsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllTemplatePdFsQuery,
    AllTemplatePdFsQueryVariables,
    AllTemplatePdFsProps<TChildProps>
  >(AllTemplatePdFsDocument, {
    alias: 'allTemplatePdFs',
    ...operationOptions,
  });
}

/**
 * __useAllTemplatePdFsQuery__
 *
 * To run a query within a React component, call `useAllTemplatePdFsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTemplatePdFsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTemplatePdFsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTemplatePdFsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTemplatePdFsQuery, AllTemplatePdFsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllTemplatePdFsQuery, AllTemplatePdFsQueryVariables>(
    AllTemplatePdFsDocument,
    baseOptions
  );
}
export function useAllTemplatePdFsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTemplatePdFsQuery, AllTemplatePdFsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllTemplatePdFsQuery, AllTemplatePdFsQueryVariables>(
    AllTemplatePdFsDocument,
    baseOptions
  );
}
export type AllTemplatePdFsQueryHookResult = ReturnType<typeof useAllTemplatePdFsQuery>;
export type AllTemplatePdFsLazyQueryHookResult = ReturnType<typeof useAllTemplatePdFsLazyQuery>;
export type AllTemplatePdFsQueryResult = ApolloReactCommon.QueryResult<
  AllTemplatePdFsQuery,
  AllTemplatePdFsQueryVariables
>;
export const AllTournamentDisqualificationsDocument = gql`
  query allTournamentDisqualifications($query: DefaultQueryInput) {
    allTournamentDisqualifications(query: $query) {
      ...tournamentDisqualificationConnection
    }
  }
  ${TournamentDisqualificationConnectionFragmentDoc}
`;
export type AllTournamentDisqualificationsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AllTournamentDisqualificationsQuery,
    AllTournamentDisqualificationsQueryVariables
  >,
  'query'
>;

export const AllTournamentDisqualificationsComponent = (props: AllTournamentDisqualificationsComponentProps) => (
  <ApolloReactComponents.Query<AllTournamentDisqualificationsQuery, AllTournamentDisqualificationsQueryVariables>
    query={AllTournamentDisqualificationsDocument}
    {...props}
  />
);

export type AllTournamentDisqualificationsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllTournamentDisqualificationsQuery,
  AllTournamentDisqualificationsQueryVariables
> &
  TChildProps;
export function withAllTournamentDisqualifications<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllTournamentDisqualificationsQuery,
    AllTournamentDisqualificationsQueryVariables,
    AllTournamentDisqualificationsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllTournamentDisqualificationsQuery,
    AllTournamentDisqualificationsQueryVariables,
    AllTournamentDisqualificationsProps<TChildProps>
  >(AllTournamentDisqualificationsDocument, {
    alias: 'allTournamentDisqualifications',
    ...operationOptions,
  });
}

/**
 * __useAllTournamentDisqualificationsQuery__
 *
 * To run a query within a React component, call `useAllTournamentDisqualificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentDisqualificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentDisqualificationsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentDisqualificationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllTournamentDisqualificationsQuery,
    AllTournamentDisqualificationsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<AllTournamentDisqualificationsQuery, AllTournamentDisqualificationsQueryVariables>(
    AllTournamentDisqualificationsDocument,
    baseOptions
  );
}
export function useAllTournamentDisqualificationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllTournamentDisqualificationsQuery,
    AllTournamentDisqualificationsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AllTournamentDisqualificationsQuery,
    AllTournamentDisqualificationsQueryVariables
  >(AllTournamentDisqualificationsDocument, baseOptions);
}
export type AllTournamentDisqualificationsQueryHookResult = ReturnType<typeof useAllTournamentDisqualificationsQuery>;
export type AllTournamentDisqualificationsLazyQueryHookResult = ReturnType<
  typeof useAllTournamentDisqualificationsLazyQuery
>;
export type AllTournamentDisqualificationsQueryResult = ApolloReactCommon.QueryResult<
  AllTournamentDisqualificationsQuery,
  AllTournamentDisqualificationsQueryVariables
>;
export const AllTournamentFieldsDocument = gql`
  query allTournamentFields($query: DefaultQueryInput) {
    allTournamentFields(query: $query) {
      total
      edges {
        ...extensionFieldContentTranslation
      }
    }
  }
  ${ExtensionFieldContentTranslationFragmentDoc}
`;
export type AllTournamentFieldsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllTournamentFieldsQuery, AllTournamentFieldsQueryVariables>,
  'query'
>;

export const AllTournamentFieldsComponent = (props: AllTournamentFieldsComponentProps) => (
  <ApolloReactComponents.Query<AllTournamentFieldsQuery, AllTournamentFieldsQueryVariables>
    query={AllTournamentFieldsDocument}
    {...props}
  />
);

export type AllTournamentFieldsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllTournamentFieldsQuery,
  AllTournamentFieldsQueryVariables
> &
  TChildProps;
export function withAllTournamentFields<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllTournamentFieldsQuery,
    AllTournamentFieldsQueryVariables,
    AllTournamentFieldsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllTournamentFieldsQuery,
    AllTournamentFieldsQueryVariables,
    AllTournamentFieldsProps<TChildProps>
  >(AllTournamentFieldsDocument, {
    alias: 'allTournamentFields',
    ...operationOptions,
  });
}

/**
 * __useAllTournamentFieldsQuery__
 *
 * To run a query within a React component, call `useAllTournamentFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentFieldsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentFieldsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTournamentFieldsQuery, AllTournamentFieldsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllTournamentFieldsQuery, AllTournamentFieldsQueryVariables>(
    AllTournamentFieldsDocument,
    baseOptions
  );
}
export function useAllTournamentFieldsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTournamentFieldsQuery, AllTournamentFieldsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllTournamentFieldsQuery, AllTournamentFieldsQueryVariables>(
    AllTournamentFieldsDocument,
    baseOptions
  );
}
export type AllTournamentFieldsQueryHookResult = ReturnType<typeof useAllTournamentFieldsQuery>;
export type AllTournamentFieldsLazyQueryHookResult = ReturnType<typeof useAllTournamentFieldsLazyQuery>;
export type AllTournamentFieldsQueryResult = ApolloReactCommon.QueryResult<
  AllTournamentFieldsQuery,
  AllTournamentFieldsQueryVariables
>;
export const AllTournamentMatchEventsDocument = gql`
  query allTournamentMatchEvents($query: DefaultQueryInput) {
    allTournamentMatchEvents(query: $query) {
      ...tournamentMatchEventConnection
    }
  }
  ${TournamentMatchEventConnectionFragmentDoc}
`;
export type AllTournamentMatchEventsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllTournamentMatchEventsQuery, AllTournamentMatchEventsQueryVariables>,
  'query'
>;

export const AllTournamentMatchEventsComponent = (props: AllTournamentMatchEventsComponentProps) => (
  <ApolloReactComponents.Query<AllTournamentMatchEventsQuery, AllTournamentMatchEventsQueryVariables>
    query={AllTournamentMatchEventsDocument}
    {...props}
  />
);

export type AllTournamentMatchEventsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllTournamentMatchEventsQuery,
  AllTournamentMatchEventsQueryVariables
> &
  TChildProps;
export function withAllTournamentMatchEvents<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllTournamentMatchEventsQuery,
    AllTournamentMatchEventsQueryVariables,
    AllTournamentMatchEventsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllTournamentMatchEventsQuery,
    AllTournamentMatchEventsQueryVariables,
    AllTournamentMatchEventsProps<TChildProps>
  >(AllTournamentMatchEventsDocument, {
    alias: 'allTournamentMatchEvents',
    ...operationOptions,
  });
}

/**
 * __useAllTournamentMatchEventsQuery__
 *
 * To run a query within a React component, call `useAllTournamentMatchEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentMatchEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentMatchEventsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentMatchEventsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTournamentMatchEventsQuery, AllTournamentMatchEventsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllTournamentMatchEventsQuery, AllTournamentMatchEventsQueryVariables>(
    AllTournamentMatchEventsDocument,
    baseOptions
  );
}
export function useAllTournamentMatchEventsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllTournamentMatchEventsQuery,
    AllTournamentMatchEventsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<AllTournamentMatchEventsQuery, AllTournamentMatchEventsQueryVariables>(
    AllTournamentMatchEventsDocument,
    baseOptions
  );
}
export type AllTournamentMatchEventsQueryHookResult = ReturnType<typeof useAllTournamentMatchEventsQuery>;
export type AllTournamentMatchEventsLazyQueryHookResult = ReturnType<typeof useAllTournamentMatchEventsLazyQuery>;
export type AllTournamentMatchEventsQueryResult = ApolloReactCommon.QueryResult<
  AllTournamentMatchEventsQuery,
  AllTournamentMatchEventsQueryVariables
>;
export const AllTournamentMatchProtocolsDocument = gql`
  query allTournamentMatchProtocols($query: DefaultQueryInput) {
    allTournamentMatchProtocols(query: $query) {
      ...tournamentMatchProtocolConnection
    }
  }
  ${TournamentMatchProtocolConnectionFragmentDoc}
`;
export type AllTournamentMatchProtocolsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AllTournamentMatchProtocolsQuery,
    AllTournamentMatchProtocolsQueryVariables
  >,
  'query'
>;

export const AllTournamentMatchProtocolsComponent = (props: AllTournamentMatchProtocolsComponentProps) => (
  <ApolloReactComponents.Query<AllTournamentMatchProtocolsQuery, AllTournamentMatchProtocolsQueryVariables>
    query={AllTournamentMatchProtocolsDocument}
    {...props}
  />
);

export type AllTournamentMatchProtocolsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllTournamentMatchProtocolsQuery,
  AllTournamentMatchProtocolsQueryVariables
> &
  TChildProps;
export function withAllTournamentMatchProtocols<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllTournamentMatchProtocolsQuery,
    AllTournamentMatchProtocolsQueryVariables,
    AllTournamentMatchProtocolsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllTournamentMatchProtocolsQuery,
    AllTournamentMatchProtocolsQueryVariables,
    AllTournamentMatchProtocolsProps<TChildProps>
  >(AllTournamentMatchProtocolsDocument, {
    alias: 'allTournamentMatchProtocols',
    ...operationOptions,
  });
}

/**
 * __useAllTournamentMatchProtocolsQuery__
 *
 * To run a query within a React component, call `useAllTournamentMatchProtocolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentMatchProtocolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentMatchProtocolsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentMatchProtocolsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllTournamentMatchProtocolsQuery,
    AllTournamentMatchProtocolsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<AllTournamentMatchProtocolsQuery, AllTournamentMatchProtocolsQueryVariables>(
    AllTournamentMatchProtocolsDocument,
    baseOptions
  );
}
export function useAllTournamentMatchProtocolsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllTournamentMatchProtocolsQuery,
    AllTournamentMatchProtocolsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<AllTournamentMatchProtocolsQuery, AllTournamentMatchProtocolsQueryVariables>(
    AllTournamentMatchProtocolsDocument,
    baseOptions
  );
}
export type AllTournamentMatchProtocolsQueryHookResult = ReturnType<typeof useAllTournamentMatchProtocolsQuery>;
export type AllTournamentMatchProtocolsLazyQueryHookResult = ReturnType<typeof useAllTournamentMatchProtocolsLazyQuery>;
export type AllTournamentMatchProtocolsQueryResult = ApolloReactCommon.QueryResult<
  AllTournamentMatchProtocolsQuery,
  AllTournamentMatchProtocolsQueryVariables
>;
export const AllTournamentMatchesDocument = gql`
  query allTournamentMatches($query: DefaultQueryInput) {
    allTournamentMatches(query: $query) {
      ...tournamentMatchConnection
    }
  }
  ${TournamentMatchConnectionFragmentDoc}
`;
export type AllTournamentMatchesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllTournamentMatchesQuery, AllTournamentMatchesQueryVariables>,
  'query'
>;

export const AllTournamentMatchesComponent = (props: AllTournamentMatchesComponentProps) => (
  <ApolloReactComponents.Query<AllTournamentMatchesQuery, AllTournamentMatchesQueryVariables>
    query={AllTournamentMatchesDocument}
    {...props}
  />
);

export type AllTournamentMatchesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllTournamentMatchesQuery,
  AllTournamentMatchesQueryVariables
> &
  TChildProps;
export function withAllTournamentMatches<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllTournamentMatchesQuery,
    AllTournamentMatchesQueryVariables,
    AllTournamentMatchesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllTournamentMatchesQuery,
    AllTournamentMatchesQueryVariables,
    AllTournamentMatchesProps<TChildProps>
  >(AllTournamentMatchesDocument, {
    alias: 'allTournamentMatches',
    ...operationOptions,
  });
}

/**
 * __useAllTournamentMatchesQuery__
 *
 * To run a query within a React component, call `useAllTournamentMatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentMatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentMatchesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentMatchesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTournamentMatchesQuery, AllTournamentMatchesQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllTournamentMatchesQuery, AllTournamentMatchesQueryVariables>(
    AllTournamentMatchesDocument,
    baseOptions
  );
}
export function useAllTournamentMatchesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTournamentMatchesQuery, AllTournamentMatchesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllTournamentMatchesQuery, AllTournamentMatchesQueryVariables>(
    AllTournamentMatchesDocument,
    baseOptions
  );
}
export type AllTournamentMatchesQueryHookResult = ReturnType<typeof useAllTournamentMatchesQuery>;
export type AllTournamentMatchesLazyQueryHookResult = ReturnType<typeof useAllTournamentMatchesLazyQuery>;
export type AllTournamentMatchesQueryResult = ApolloReactCommon.QueryResult<
  AllTournamentMatchesQuery,
  AllTournamentMatchesQueryVariables
>;
export const AllTournamentPlayerStatsDocument = gql`
  query allTournamentPlayerStats($query: DefaultQueryInput) {
    allTournamentPlayerStats(query: $query) {
      ...tournamentPlayerStatConnection
    }
  }
  ${TournamentPlayerStatConnectionFragmentDoc}
`;
export type AllTournamentPlayerStatsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllTournamentPlayerStatsQuery, AllTournamentPlayerStatsQueryVariables>,
  'query'
>;

export const AllTournamentPlayerStatsComponent = (props: AllTournamentPlayerStatsComponentProps) => (
  <ApolloReactComponents.Query<AllTournamentPlayerStatsQuery, AllTournamentPlayerStatsQueryVariables>
    query={AllTournamentPlayerStatsDocument}
    {...props}
  />
);

export type AllTournamentPlayerStatsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllTournamentPlayerStatsQuery,
  AllTournamentPlayerStatsQueryVariables
> &
  TChildProps;
export function withAllTournamentPlayerStats<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllTournamentPlayerStatsQuery,
    AllTournamentPlayerStatsQueryVariables,
    AllTournamentPlayerStatsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllTournamentPlayerStatsQuery,
    AllTournamentPlayerStatsQueryVariables,
    AllTournamentPlayerStatsProps<TChildProps>
  >(AllTournamentPlayerStatsDocument, {
    alias: 'allTournamentPlayerStats',
    ...operationOptions,
  });
}

/**
 * __useAllTournamentPlayerStatsQuery__
 *
 * To run a query within a React component, call `useAllTournamentPlayerStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentPlayerStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentPlayerStatsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentPlayerStatsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTournamentPlayerStatsQuery, AllTournamentPlayerStatsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllTournamentPlayerStatsQuery, AllTournamentPlayerStatsQueryVariables>(
    AllTournamentPlayerStatsDocument,
    baseOptions
  );
}
export function useAllTournamentPlayerStatsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllTournamentPlayerStatsQuery,
    AllTournamentPlayerStatsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<AllTournamentPlayerStatsQuery, AllTournamentPlayerStatsQueryVariables>(
    AllTournamentPlayerStatsDocument,
    baseOptions
  );
}
export type AllTournamentPlayerStatsQueryHookResult = ReturnType<typeof useAllTournamentPlayerStatsQuery>;
export type AllTournamentPlayerStatsLazyQueryHookResult = ReturnType<typeof useAllTournamentPlayerStatsLazyQuery>;
export type AllTournamentPlayerStatsQueryResult = ApolloReactCommon.QueryResult<
  AllTournamentPlayerStatsQuery,
  AllTournamentPlayerStatsQueryVariables
>;
export const AllTournamentRefereesDocument = gql`
  query allTournamentReferees($query: DefaultQueryInput) {
    allTournamentReferees(query: $query) {
      ...tournamentRefereeConnection
    }
  }
  ${TournamentRefereeConnectionFragmentDoc}
`;
export type AllTournamentRefereesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllTournamentRefereesQuery, AllTournamentRefereesQueryVariables>,
  'query'
>;

export const AllTournamentRefereesComponent = (props: AllTournamentRefereesComponentProps) => (
  <ApolloReactComponents.Query<AllTournamentRefereesQuery, AllTournamentRefereesQueryVariables>
    query={AllTournamentRefereesDocument}
    {...props}
  />
);

export type AllTournamentRefereesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllTournamentRefereesQuery,
  AllTournamentRefereesQueryVariables
> &
  TChildProps;
export function withAllTournamentReferees<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllTournamentRefereesQuery,
    AllTournamentRefereesQueryVariables,
    AllTournamentRefereesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllTournamentRefereesQuery,
    AllTournamentRefereesQueryVariables,
    AllTournamentRefereesProps<TChildProps>
  >(AllTournamentRefereesDocument, {
    alias: 'allTournamentReferees',
    ...operationOptions,
  });
}

/**
 * __useAllTournamentRefereesQuery__
 *
 * To run a query within a React component, call `useAllTournamentRefereesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentRefereesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentRefereesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentRefereesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTournamentRefereesQuery, AllTournamentRefereesQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllTournamentRefereesQuery, AllTournamentRefereesQueryVariables>(
    AllTournamentRefereesDocument,
    baseOptions
  );
}
export function useAllTournamentRefereesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTournamentRefereesQuery, AllTournamentRefereesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllTournamentRefereesQuery, AllTournamentRefereesQueryVariables>(
    AllTournamentRefereesDocument,
    baseOptions
  );
}
export type AllTournamentRefereesQueryHookResult = ReturnType<typeof useAllTournamentRefereesQuery>;
export type AllTournamentRefereesLazyQueryHookResult = ReturnType<typeof useAllTournamentRefereesLazyQuery>;
export type AllTournamentRefereesQueryResult = ApolloReactCommon.QueryResult<
  AllTournamentRefereesQuery,
  AllTournamentRefereesQueryVariables
>;
export const AllTournamentSeasonTeamStatsDocument = gql`
  query allTournamentSeasonTeamStats($query: DefaultQueryInput) {
    allTournamentSeasonTeamStats(query: $query) {
      ...tournamentSeasonTeamStatConnection
    }
  }
  ${TournamentSeasonTeamStatConnectionFragmentDoc}
`;
export type AllTournamentSeasonTeamStatsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AllTournamentSeasonTeamStatsQuery,
    AllTournamentSeasonTeamStatsQueryVariables
  >,
  'query'
>;

export const AllTournamentSeasonTeamStatsComponent = (props: AllTournamentSeasonTeamStatsComponentProps) => (
  <ApolloReactComponents.Query<AllTournamentSeasonTeamStatsQuery, AllTournamentSeasonTeamStatsQueryVariables>
    query={AllTournamentSeasonTeamStatsDocument}
    {...props}
  />
);

export type AllTournamentSeasonTeamStatsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllTournamentSeasonTeamStatsQuery,
  AllTournamentSeasonTeamStatsQueryVariables
> &
  TChildProps;
export function withAllTournamentSeasonTeamStats<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllTournamentSeasonTeamStatsQuery,
    AllTournamentSeasonTeamStatsQueryVariables,
    AllTournamentSeasonTeamStatsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllTournamentSeasonTeamStatsQuery,
    AllTournamentSeasonTeamStatsQueryVariables,
    AllTournamentSeasonTeamStatsProps<TChildProps>
  >(AllTournamentSeasonTeamStatsDocument, {
    alias: 'allTournamentSeasonTeamStats',
    ...operationOptions,
  });
}

/**
 * __useAllTournamentSeasonTeamStatsQuery__
 *
 * To run a query within a React component, call `useAllTournamentSeasonTeamStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentSeasonTeamStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentSeasonTeamStatsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentSeasonTeamStatsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllTournamentSeasonTeamStatsQuery,
    AllTournamentSeasonTeamStatsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<AllTournamentSeasonTeamStatsQuery, AllTournamentSeasonTeamStatsQueryVariables>(
    AllTournamentSeasonTeamStatsDocument,
    baseOptions
  );
}
export function useAllTournamentSeasonTeamStatsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllTournamentSeasonTeamStatsQuery,
    AllTournamentSeasonTeamStatsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<AllTournamentSeasonTeamStatsQuery, AllTournamentSeasonTeamStatsQueryVariables>(
    AllTournamentSeasonTeamStatsDocument,
    baseOptions
  );
}
export type AllTournamentSeasonTeamStatsQueryHookResult = ReturnType<typeof useAllTournamentSeasonTeamStatsQuery>;
export type AllTournamentSeasonTeamStatsLazyQueryHookResult = ReturnType<
  typeof useAllTournamentSeasonTeamStatsLazyQuery
>;
export type AllTournamentSeasonTeamStatsQueryResult = ApolloReactCommon.QueryResult<
  AllTournamentSeasonTeamStatsQuery,
  AllTournamentSeasonTeamStatsQueryVariables
>;
export const AllTournamentSeasonTeamsDocument = gql`
  query allTournamentSeasonTeams($query: DefaultQueryInput) {
    allTournamentSeasonTeams(query: $query) {
      ...tournamentSeasonTeamConnection
    }
  }
  ${TournamentSeasonTeamConnectionFragmentDoc}
`;
export type AllTournamentSeasonTeamsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllTournamentSeasonTeamsQuery, AllTournamentSeasonTeamsQueryVariables>,
  'query'
>;

export const AllTournamentSeasonTeamsComponent = (props: AllTournamentSeasonTeamsComponentProps) => (
  <ApolloReactComponents.Query<AllTournamentSeasonTeamsQuery, AllTournamentSeasonTeamsQueryVariables>
    query={AllTournamentSeasonTeamsDocument}
    {...props}
  />
);

export type AllTournamentSeasonTeamsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllTournamentSeasonTeamsQuery,
  AllTournamentSeasonTeamsQueryVariables
> &
  TChildProps;
export function withAllTournamentSeasonTeams<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllTournamentSeasonTeamsQuery,
    AllTournamentSeasonTeamsQueryVariables,
    AllTournamentSeasonTeamsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllTournamentSeasonTeamsQuery,
    AllTournamentSeasonTeamsQueryVariables,
    AllTournamentSeasonTeamsProps<TChildProps>
  >(AllTournamentSeasonTeamsDocument, {
    alias: 'allTournamentSeasonTeams',
    ...operationOptions,
  });
}

/**
 * __useAllTournamentSeasonTeamsQuery__
 *
 * To run a query within a React component, call `useAllTournamentSeasonTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentSeasonTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentSeasonTeamsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentSeasonTeamsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTournamentSeasonTeamsQuery, AllTournamentSeasonTeamsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllTournamentSeasonTeamsQuery, AllTournamentSeasonTeamsQueryVariables>(
    AllTournamentSeasonTeamsDocument,
    baseOptions
  );
}
export function useAllTournamentSeasonTeamsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllTournamentSeasonTeamsQuery,
    AllTournamentSeasonTeamsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<AllTournamentSeasonTeamsQuery, AllTournamentSeasonTeamsQueryVariables>(
    AllTournamentSeasonTeamsDocument,
    baseOptions
  );
}
export type AllTournamentSeasonTeamsQueryHookResult = ReturnType<typeof useAllTournamentSeasonTeamsQuery>;
export type AllTournamentSeasonTeamsLazyQueryHookResult = ReturnType<typeof useAllTournamentSeasonTeamsLazyQuery>;
export type AllTournamentSeasonTeamsQueryResult = ApolloReactCommon.QueryResult<
  AllTournamentSeasonTeamsQuery,
  AllTournamentSeasonTeamsQueryVariables
>;
export const AllTournamentSeasonsDocument = gql`
  query allTournamentSeasons($query: DefaultQueryInput) {
    allTournamentSeasons(query: $query) {
      ...tournamentSeasonConnection
    }
  }
  ${TournamentSeasonConnectionFragmentDoc}
`;
export type AllTournamentSeasonsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllTournamentSeasonsQuery, AllTournamentSeasonsQueryVariables>,
  'query'
>;

export const AllTournamentSeasonsComponent = (props: AllTournamentSeasonsComponentProps) => (
  <ApolloReactComponents.Query<AllTournamentSeasonsQuery, AllTournamentSeasonsQueryVariables>
    query={AllTournamentSeasonsDocument}
    {...props}
  />
);

export type AllTournamentSeasonsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllTournamentSeasonsQuery,
  AllTournamentSeasonsQueryVariables
> &
  TChildProps;
export function withAllTournamentSeasons<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllTournamentSeasonsQuery,
    AllTournamentSeasonsQueryVariables,
    AllTournamentSeasonsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllTournamentSeasonsQuery,
    AllTournamentSeasonsQueryVariables,
    AllTournamentSeasonsProps<TChildProps>
  >(AllTournamentSeasonsDocument, {
    alias: 'allTournamentSeasons',
    ...operationOptions,
  });
}

/**
 * __useAllTournamentSeasonsQuery__
 *
 * To run a query within a React component, call `useAllTournamentSeasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentSeasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentSeasonsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentSeasonsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTournamentSeasonsQuery, AllTournamentSeasonsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllTournamentSeasonsQuery, AllTournamentSeasonsQueryVariables>(
    AllTournamentSeasonsDocument,
    baseOptions
  );
}
export function useAllTournamentSeasonsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTournamentSeasonsQuery, AllTournamentSeasonsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllTournamentSeasonsQuery, AllTournamentSeasonsQueryVariables>(
    AllTournamentSeasonsDocument,
    baseOptions
  );
}
export type AllTournamentSeasonsQueryHookResult = ReturnType<typeof useAllTournamentSeasonsQuery>;
export type AllTournamentSeasonsLazyQueryHookResult = ReturnType<typeof useAllTournamentSeasonsLazyQuery>;
export type AllTournamentSeasonsQueryResult = ApolloReactCommon.QueryResult<
  AllTournamentSeasonsQuery,
  AllTournamentSeasonsQueryVariables
>;
export const AllTournamentStadiumsDocument = gql`
  query allTournamentStadiums($query: DefaultQueryInput) {
    allTournamentStadiums(query: $query) {
      total
      limit
      cursor
      sortable
      searchable
      edges {
        ...tournamentStadium
      }
    }
  }
  ${TournamentStadiumFragmentDoc}
`;
export type AllTournamentStadiumsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllTournamentStadiumsQuery, AllTournamentStadiumsQueryVariables>,
  'query'
>;

export const AllTournamentStadiumsComponent = (props: AllTournamentStadiumsComponentProps) => (
  <ApolloReactComponents.Query<AllTournamentStadiumsQuery, AllTournamentStadiumsQueryVariables>
    query={AllTournamentStadiumsDocument}
    {...props}
  />
);

export type AllTournamentStadiumsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllTournamentStadiumsQuery,
  AllTournamentStadiumsQueryVariables
> &
  TChildProps;
export function withAllTournamentStadiums<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllTournamentStadiumsQuery,
    AllTournamentStadiumsQueryVariables,
    AllTournamentStadiumsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllTournamentStadiumsQuery,
    AllTournamentStadiumsQueryVariables,
    AllTournamentStadiumsProps<TChildProps>
  >(AllTournamentStadiumsDocument, {
    alias: 'allTournamentStadiums',
    ...operationOptions,
  });
}

/**
 * __useAllTournamentStadiumsQuery__
 *
 * To run a query within a React component, call `useAllTournamentStadiumsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentStadiumsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentStadiumsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentStadiumsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTournamentStadiumsQuery, AllTournamentStadiumsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllTournamentStadiumsQuery, AllTournamentStadiumsQueryVariables>(
    AllTournamentStadiumsDocument,
    baseOptions
  );
}
export function useAllTournamentStadiumsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTournamentStadiumsQuery, AllTournamentStadiumsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllTournamentStadiumsQuery, AllTournamentStadiumsQueryVariables>(
    AllTournamentStadiumsDocument,
    baseOptions
  );
}
export type AllTournamentStadiumsQueryHookResult = ReturnType<typeof useAllTournamentStadiumsQuery>;
export type AllTournamentStadiumsLazyQueryHookResult = ReturnType<typeof useAllTournamentStadiumsLazyQuery>;
export type AllTournamentStadiumsQueryResult = ApolloReactCommon.QueryResult<
  AllTournamentStadiumsQuery,
  AllTournamentStadiumsQueryVariables
>;
export const AllTournamentTeamFieldsDocument = gql`
  query allTournamentTeamFields($query: DefaultQueryInput) {
    allTournamentTeamFields(query: $query) {
      total
      edges {
        ...extensionFieldContentTranslation
      }
    }
  }
  ${ExtensionFieldContentTranslationFragmentDoc}
`;
export type AllTournamentTeamFieldsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllTournamentTeamFieldsQuery, AllTournamentTeamFieldsQueryVariables>,
  'query'
>;

export const AllTournamentTeamFieldsComponent = (props: AllTournamentTeamFieldsComponentProps) => (
  <ApolloReactComponents.Query<AllTournamentTeamFieldsQuery, AllTournamentTeamFieldsQueryVariables>
    query={AllTournamentTeamFieldsDocument}
    {...props}
  />
);

export type AllTournamentTeamFieldsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllTournamentTeamFieldsQuery,
  AllTournamentTeamFieldsQueryVariables
> &
  TChildProps;
export function withAllTournamentTeamFields<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllTournamentTeamFieldsQuery,
    AllTournamentTeamFieldsQueryVariables,
    AllTournamentTeamFieldsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllTournamentTeamFieldsQuery,
    AllTournamentTeamFieldsQueryVariables,
    AllTournamentTeamFieldsProps<TChildProps>
  >(AllTournamentTeamFieldsDocument, {
    alias: 'allTournamentTeamFields',
    ...operationOptions,
  });
}

/**
 * __useAllTournamentTeamFieldsQuery__
 *
 * To run a query within a React component, call `useAllTournamentTeamFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentTeamFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentTeamFieldsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentTeamFieldsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTournamentTeamFieldsQuery, AllTournamentTeamFieldsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllTournamentTeamFieldsQuery, AllTournamentTeamFieldsQueryVariables>(
    AllTournamentTeamFieldsDocument,
    baseOptions
  );
}
export function useAllTournamentTeamFieldsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllTournamentTeamFieldsQuery,
    AllTournamentTeamFieldsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<AllTournamentTeamFieldsQuery, AllTournamentTeamFieldsQueryVariables>(
    AllTournamentTeamFieldsDocument,
    baseOptions
  );
}
export type AllTournamentTeamFieldsQueryHookResult = ReturnType<typeof useAllTournamentTeamFieldsQuery>;
export type AllTournamentTeamFieldsLazyQueryHookResult = ReturnType<typeof useAllTournamentTeamFieldsLazyQuery>;
export type AllTournamentTeamFieldsQueryResult = ApolloReactCommon.QueryResult<
  AllTournamentTeamFieldsQuery,
  AllTournamentTeamFieldsQueryVariables
>;
export const AllTournamentTeamMemberFieldsDocument = gql`
  query allTournamentTeamMemberFields($query: DefaultQueryInput) {
    allTournamentTeamMemberFields(query: $query) {
      total
      edges {
        ...extensionFieldContentTranslation
      }
    }
  }
  ${ExtensionFieldContentTranslationFragmentDoc}
`;
export type AllTournamentTeamMemberFieldsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AllTournamentTeamMemberFieldsQuery,
    AllTournamentTeamMemberFieldsQueryVariables
  >,
  'query'
>;

export const AllTournamentTeamMemberFieldsComponent = (props: AllTournamentTeamMemberFieldsComponentProps) => (
  <ApolloReactComponents.Query<AllTournamentTeamMemberFieldsQuery, AllTournamentTeamMemberFieldsQueryVariables>
    query={AllTournamentTeamMemberFieldsDocument}
    {...props}
  />
);

export type AllTournamentTeamMemberFieldsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllTournamentTeamMemberFieldsQuery,
  AllTournamentTeamMemberFieldsQueryVariables
> &
  TChildProps;
export function withAllTournamentTeamMemberFields<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllTournamentTeamMemberFieldsQuery,
    AllTournamentTeamMemberFieldsQueryVariables,
    AllTournamentTeamMemberFieldsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllTournamentTeamMemberFieldsQuery,
    AllTournamentTeamMemberFieldsQueryVariables,
    AllTournamentTeamMemberFieldsProps<TChildProps>
  >(AllTournamentTeamMemberFieldsDocument, {
    alias: 'allTournamentTeamMemberFields',
    ...operationOptions,
  });
}

/**
 * __useAllTournamentTeamMemberFieldsQuery__
 *
 * To run a query within a React component, call `useAllTournamentTeamMemberFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentTeamMemberFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentTeamMemberFieldsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentTeamMemberFieldsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllTournamentTeamMemberFieldsQuery,
    AllTournamentTeamMemberFieldsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<AllTournamentTeamMemberFieldsQuery, AllTournamentTeamMemberFieldsQueryVariables>(
    AllTournamentTeamMemberFieldsDocument,
    baseOptions
  );
}
export function useAllTournamentTeamMemberFieldsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllTournamentTeamMemberFieldsQuery,
    AllTournamentTeamMemberFieldsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<AllTournamentTeamMemberFieldsQuery, AllTournamentTeamMemberFieldsQueryVariables>(
    AllTournamentTeamMemberFieldsDocument,
    baseOptions
  );
}
export type AllTournamentTeamMemberFieldsQueryHookResult = ReturnType<typeof useAllTournamentTeamMemberFieldsQuery>;
export type AllTournamentTeamMemberFieldsLazyQueryHookResult = ReturnType<
  typeof useAllTournamentTeamMemberFieldsLazyQuery
>;
export type AllTournamentTeamMemberFieldsQueryResult = ApolloReactCommon.QueryResult<
  AllTournamentTeamMemberFieldsQuery,
  AllTournamentTeamMemberFieldsQueryVariables
>;
export const AllTournamentTeamMembersDocument = gql`
  query allTournamentTeamMembers($query: DefaultQueryInput) {
    allTournamentTeamMembers(query: $query) {
      ...tournamentTeamMemberConnection
    }
  }
  ${TournamentTeamMemberConnectionFragmentDoc}
`;
export type AllTournamentTeamMembersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllTournamentTeamMembersQuery, AllTournamentTeamMembersQueryVariables>,
  'query'
>;

export const AllTournamentTeamMembersComponent = (props: AllTournamentTeamMembersComponentProps) => (
  <ApolloReactComponents.Query<AllTournamentTeamMembersQuery, AllTournamentTeamMembersQueryVariables>
    query={AllTournamentTeamMembersDocument}
    {...props}
  />
);

export type AllTournamentTeamMembersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllTournamentTeamMembersQuery,
  AllTournamentTeamMembersQueryVariables
> &
  TChildProps;
export function withAllTournamentTeamMembers<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllTournamentTeamMembersQuery,
    AllTournamentTeamMembersQueryVariables,
    AllTournamentTeamMembersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllTournamentTeamMembersQuery,
    AllTournamentTeamMembersQueryVariables,
    AllTournamentTeamMembersProps<TChildProps>
  >(AllTournamentTeamMembersDocument, {
    alias: 'allTournamentTeamMembers',
    ...operationOptions,
  });
}

/**
 * __useAllTournamentTeamMembersQuery__
 *
 * To run a query within a React component, call `useAllTournamentTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentTeamMembersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentTeamMembersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTournamentTeamMembersQuery, AllTournamentTeamMembersQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllTournamentTeamMembersQuery, AllTournamentTeamMembersQueryVariables>(
    AllTournamentTeamMembersDocument,
    baseOptions
  );
}
export function useAllTournamentTeamMembersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllTournamentTeamMembersQuery,
    AllTournamentTeamMembersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<AllTournamentTeamMembersQuery, AllTournamentTeamMembersQueryVariables>(
    AllTournamentTeamMembersDocument,
    baseOptions
  );
}
export type AllTournamentTeamMembersQueryHookResult = ReturnType<typeof useAllTournamentTeamMembersQuery>;
export type AllTournamentTeamMembersLazyQueryHookResult = ReturnType<typeof useAllTournamentTeamMembersLazyQuery>;
export type AllTournamentTeamMembersQueryResult = ApolloReactCommon.QueryResult<
  AllTournamentTeamMembersQuery,
  AllTournamentTeamMembersQueryVariables
>;
export const AllTournamentTeamsDocument = gql`
  query allTournamentTeams($query: DefaultQueryInput) {
    allTournamentTeams(query: $query) {
      ...tournamentTeamConnection
    }
  }
  ${TournamentTeamConnectionFragmentDoc}
`;
export type AllTournamentTeamsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllTournamentTeamsQuery, AllTournamentTeamsQueryVariables>,
  'query'
>;

export const AllTournamentTeamsComponent = (props: AllTournamentTeamsComponentProps) => (
  <ApolloReactComponents.Query<AllTournamentTeamsQuery, AllTournamentTeamsQueryVariables>
    query={AllTournamentTeamsDocument}
    {...props}
  />
);

export type AllTournamentTeamsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllTournamentTeamsQuery,
  AllTournamentTeamsQueryVariables
> &
  TChildProps;
export function withAllTournamentTeams<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllTournamentTeamsQuery,
    AllTournamentTeamsQueryVariables,
    AllTournamentTeamsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllTournamentTeamsQuery,
    AllTournamentTeamsQueryVariables,
    AllTournamentTeamsProps<TChildProps>
  >(AllTournamentTeamsDocument, {
    alias: 'allTournamentTeams',
    ...operationOptions,
  });
}

/**
 * __useAllTournamentTeamsQuery__
 *
 * To run a query within a React component, call `useAllTournamentTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentTeamsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentTeamsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTournamentTeamsQuery, AllTournamentTeamsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllTournamentTeamsQuery, AllTournamentTeamsQueryVariables>(
    AllTournamentTeamsDocument,
    baseOptions
  );
}
export function useAllTournamentTeamsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTournamentTeamsQuery, AllTournamentTeamsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllTournamentTeamsQuery, AllTournamentTeamsQueryVariables>(
    AllTournamentTeamsDocument,
    baseOptions
  );
}
export type AllTournamentTeamsQueryHookResult = ReturnType<typeof useAllTournamentTeamsQuery>;
export type AllTournamentTeamsLazyQueryHookResult = ReturnType<typeof useAllTournamentTeamsLazyQuery>;
export type AllTournamentTeamsQueryResult = ApolloReactCommon.QueryResult<
  AllTournamentTeamsQuery,
  AllTournamentTeamsQueryVariables
>;
export const AllTournamentTeamsWithSeasonsDocument = gql`
  query allTournamentTeamsWithSeasons($query: DefaultQueryInput) {
    allTournamentTeams(query: $query) {
      ...tournamentTeamWithSeasonConnection
    }
  }
  ${TournamentTeamWithSeasonConnectionFragmentDoc}
`;
export type AllTournamentTeamsWithSeasonsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AllTournamentTeamsWithSeasonsQuery,
    AllTournamentTeamsWithSeasonsQueryVariables
  >,
  'query'
>;

export const AllTournamentTeamsWithSeasonsComponent = (props: AllTournamentTeamsWithSeasonsComponentProps) => (
  <ApolloReactComponents.Query<AllTournamentTeamsWithSeasonsQuery, AllTournamentTeamsWithSeasonsQueryVariables>
    query={AllTournamentTeamsWithSeasonsDocument}
    {...props}
  />
);

export type AllTournamentTeamsWithSeasonsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllTournamentTeamsWithSeasonsQuery,
  AllTournamentTeamsWithSeasonsQueryVariables
> &
  TChildProps;
export function withAllTournamentTeamsWithSeasons<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllTournamentTeamsWithSeasonsQuery,
    AllTournamentTeamsWithSeasonsQueryVariables,
    AllTournamentTeamsWithSeasonsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllTournamentTeamsWithSeasonsQuery,
    AllTournamentTeamsWithSeasonsQueryVariables,
    AllTournamentTeamsWithSeasonsProps<TChildProps>
  >(AllTournamentTeamsWithSeasonsDocument, {
    alias: 'allTournamentTeamsWithSeasons',
    ...operationOptions,
  });
}

/**
 * __useAllTournamentTeamsWithSeasonsQuery__
 *
 * To run a query within a React component, call `useAllTournamentTeamsWithSeasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentTeamsWithSeasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentTeamsWithSeasonsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentTeamsWithSeasonsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllTournamentTeamsWithSeasonsQuery,
    AllTournamentTeamsWithSeasonsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<AllTournamentTeamsWithSeasonsQuery, AllTournamentTeamsWithSeasonsQueryVariables>(
    AllTournamentTeamsWithSeasonsDocument,
    baseOptions
  );
}
export function useAllTournamentTeamsWithSeasonsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllTournamentTeamsWithSeasonsQuery,
    AllTournamentTeamsWithSeasonsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<AllTournamentTeamsWithSeasonsQuery, AllTournamentTeamsWithSeasonsQueryVariables>(
    AllTournamentTeamsWithSeasonsDocument,
    baseOptions
  );
}
export type AllTournamentTeamsWithSeasonsQueryHookResult = ReturnType<typeof useAllTournamentTeamsWithSeasonsQuery>;
export type AllTournamentTeamsWithSeasonsLazyQueryHookResult = ReturnType<
  typeof useAllTournamentTeamsWithSeasonsLazyQuery
>;
export type AllTournamentTeamsWithSeasonsQueryResult = ApolloReactCommon.QueryResult<
  AllTournamentTeamsWithSeasonsQuery,
  AllTournamentTeamsWithSeasonsQueryVariables
>;
export const AllTournamentToursDocument = gql`
  query allTournamentTours($query: DefaultQueryInput) {
    allTournamentTours(query: $query) {
      ...tournamentTourConnection
    }
  }
  ${TournamentTourConnectionFragmentDoc}
`;
export type AllTournamentToursComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllTournamentToursQuery, AllTournamentToursQueryVariables>,
  'query'
>;

export const AllTournamentToursComponent = (props: AllTournamentToursComponentProps) => (
  <ApolloReactComponents.Query<AllTournamentToursQuery, AllTournamentToursQueryVariables>
    query={AllTournamentToursDocument}
    {...props}
  />
);

export type AllTournamentToursProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllTournamentToursQuery,
  AllTournamentToursQueryVariables
> &
  TChildProps;
export function withAllTournamentTours<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllTournamentToursQuery,
    AllTournamentToursQueryVariables,
    AllTournamentToursProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllTournamentToursQuery,
    AllTournamentToursQueryVariables,
    AllTournamentToursProps<TChildProps>
  >(AllTournamentToursDocument, {
    alias: 'allTournamentTours',
    ...operationOptions,
  });
}

/**
 * __useAllTournamentToursQuery__
 *
 * To run a query within a React component, call `useAllTournamentToursQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentToursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentToursQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentToursQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTournamentToursQuery, AllTournamentToursQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllTournamentToursQuery, AllTournamentToursQueryVariables>(
    AllTournamentToursDocument,
    baseOptions
  );
}
export function useAllTournamentToursLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTournamentToursQuery, AllTournamentToursQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllTournamentToursQuery, AllTournamentToursQueryVariables>(
    AllTournamentToursDocument,
    baseOptions
  );
}
export type AllTournamentToursQueryHookResult = ReturnType<typeof useAllTournamentToursQuery>;
export type AllTournamentToursLazyQueryHookResult = ReturnType<typeof useAllTournamentToursLazyQuery>;
export type AllTournamentToursQueryResult = ApolloReactCommon.QueryResult<
  AllTournamentToursQuery,
  AllTournamentToursQueryVariables
>;
export const AllTournamentToursDeepDocument = gql`
  query allTournamentToursDeep($query: DefaultQueryInput) {
    allTournamentTours(query: $query) {
      ...tournamentTourConnectionDeep
    }
  }
  ${TournamentTourConnectionDeepFragmentDoc}
`;
export type AllTournamentToursDeepComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllTournamentToursDeepQuery, AllTournamentToursDeepQueryVariables>,
  'query'
>;

export const AllTournamentToursDeepComponent = (props: AllTournamentToursDeepComponentProps) => (
  <ApolloReactComponents.Query<AllTournamentToursDeepQuery, AllTournamentToursDeepQueryVariables>
    query={AllTournamentToursDeepDocument}
    {...props}
  />
);

export type AllTournamentToursDeepProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllTournamentToursDeepQuery,
  AllTournamentToursDeepQueryVariables
> &
  TChildProps;
export function withAllTournamentToursDeep<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllTournamentToursDeepQuery,
    AllTournamentToursDeepQueryVariables,
    AllTournamentToursDeepProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllTournamentToursDeepQuery,
    AllTournamentToursDeepQueryVariables,
    AllTournamentToursDeepProps<TChildProps>
  >(AllTournamentToursDeepDocument, {
    alias: 'allTournamentToursDeep',
    ...operationOptions,
  });
}

/**
 * __useAllTournamentToursDeepQuery__
 *
 * To run a query within a React component, call `useAllTournamentToursDeepQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentToursDeepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentToursDeepQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentToursDeepQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTournamentToursDeepQuery, AllTournamentToursDeepQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllTournamentToursDeepQuery, AllTournamentToursDeepQueryVariables>(
    AllTournamentToursDeepDocument,
    baseOptions
  );
}
export function useAllTournamentToursDeepLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTournamentToursDeepQuery, AllTournamentToursDeepQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllTournamentToursDeepQuery, AllTournamentToursDeepQueryVariables>(
    AllTournamentToursDeepDocument,
    baseOptions
  );
}
export type AllTournamentToursDeepQueryHookResult = ReturnType<typeof useAllTournamentToursDeepQuery>;
export type AllTournamentToursDeepLazyQueryHookResult = ReturnType<typeof useAllTournamentToursDeepLazyQuery>;
export type AllTournamentToursDeepQueryResult = ApolloReactCommon.QueryResult<
  AllTournamentToursDeepQuery,
  AllTournamentToursDeepQueryVariables
>;
export const AllTournamentsDocument = gql`
  query allTournaments($query: DefaultQueryInput) {
    allTournaments(query: $query) {
      ...tournamentConnection
    }
  }
  ${TournamentConnectionFragmentDoc}
`;
export type AllTournamentsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllTournamentsQuery, AllTournamentsQueryVariables>,
  'query'
>;

export const AllTournamentsComponent = (props: AllTournamentsComponentProps) => (
  <ApolloReactComponents.Query<AllTournamentsQuery, AllTournamentsQueryVariables>
    query={AllTournamentsDocument}
    {...props}
  />
);

export type AllTournamentsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllTournamentsQuery,
  AllTournamentsQueryVariables
> &
  TChildProps;
export function withAllTournaments<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllTournamentsQuery,
    AllTournamentsQueryVariables,
    AllTournamentsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllTournamentsQuery,
    AllTournamentsQueryVariables,
    AllTournamentsProps<TChildProps>
  >(AllTournamentsDocument, {
    alias: 'allTournaments',
    ...operationOptions,
  });
}

/**
 * __useAllTournamentsQuery__
 *
 * To run a query within a React component, call `useAllTournamentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTournamentsQuery, AllTournamentsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllTournamentsQuery, AllTournamentsQueryVariables>(
    AllTournamentsDocument,
    baseOptions
  );
}
export function useAllTournamentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTournamentsQuery, AllTournamentsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllTournamentsQuery, AllTournamentsQueryVariables>(
    AllTournamentsDocument,
    baseOptions
  );
}
export type AllTournamentsQueryHookResult = ReturnType<typeof useAllTournamentsQuery>;
export type AllTournamentsLazyQueryHookResult = ReturnType<typeof useAllTournamentsLazyQuery>;
export type AllTournamentsQueryResult = ApolloReactCommon.QueryResult<
  AllTournamentsQuery,
  AllTournamentsQueryVariables
>;
export const AllUserApplicationFieldsDocument = gql`
  query allUserApplicationFields($query: DefaultQueryInput) {
    allUserApplicationFields(query: $query) {
      total
      limit
      cursor
      edges {
        ...extensionField
      }
      sortable
      searchable
    }
  }
  ${ExtensionFieldFragmentDoc}
`;
export type AllUserApplicationFieldsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllUserApplicationFieldsQuery, AllUserApplicationFieldsQueryVariables>,
  'query'
>;

export const AllUserApplicationFieldsComponent = (props: AllUserApplicationFieldsComponentProps) => (
  <ApolloReactComponents.Query<AllUserApplicationFieldsQuery, AllUserApplicationFieldsQueryVariables>
    query={AllUserApplicationFieldsDocument}
    {...props}
  />
);

export type AllUserApplicationFieldsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllUserApplicationFieldsQuery,
  AllUserApplicationFieldsQueryVariables
> &
  TChildProps;
export function withAllUserApplicationFields<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllUserApplicationFieldsQuery,
    AllUserApplicationFieldsQueryVariables,
    AllUserApplicationFieldsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllUserApplicationFieldsQuery,
    AllUserApplicationFieldsQueryVariables,
    AllUserApplicationFieldsProps<TChildProps>
  >(AllUserApplicationFieldsDocument, {
    alias: 'allUserApplicationFields',
    ...operationOptions,
  });
}

/**
 * __useAllUserApplicationFieldsQuery__
 *
 * To run a query within a React component, call `useAllUserApplicationFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserApplicationFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserApplicationFieldsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllUserApplicationFieldsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllUserApplicationFieldsQuery, AllUserApplicationFieldsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllUserApplicationFieldsQuery, AllUserApplicationFieldsQueryVariables>(
    AllUserApplicationFieldsDocument,
    baseOptions
  );
}
export function useAllUserApplicationFieldsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllUserApplicationFieldsQuery,
    AllUserApplicationFieldsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<AllUserApplicationFieldsQuery, AllUserApplicationFieldsQueryVariables>(
    AllUserApplicationFieldsDocument,
    baseOptions
  );
}
export type AllUserApplicationFieldsQueryHookResult = ReturnType<typeof useAllUserApplicationFieldsQuery>;
export type AllUserApplicationFieldsLazyQueryHookResult = ReturnType<typeof useAllUserApplicationFieldsLazyQuery>;
export type AllUserApplicationFieldsQueryResult = ApolloReactCommon.QueryResult<
  AllUserApplicationFieldsQuery,
  AllUserApplicationFieldsQueryVariables
>;
export const AllUserApplicationTypesDocument = gql`
  query allUserApplicationTypes($query: DefaultQueryInput) {
    allUserApplicationTypes(query: $query) {
      total
      limit
      cursor
      edges {
        ...userApplicationType
      }
      sortable
      searchable
    }
  }
  ${UserApplicationTypeFragmentDoc}
`;
export type AllUserApplicationTypesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllUserApplicationTypesQuery, AllUserApplicationTypesQueryVariables>,
  'query'
>;

export const AllUserApplicationTypesComponent = (props: AllUserApplicationTypesComponentProps) => (
  <ApolloReactComponents.Query<AllUserApplicationTypesQuery, AllUserApplicationTypesQueryVariables>
    query={AllUserApplicationTypesDocument}
    {...props}
  />
);

export type AllUserApplicationTypesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllUserApplicationTypesQuery,
  AllUserApplicationTypesQueryVariables
> &
  TChildProps;
export function withAllUserApplicationTypes<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllUserApplicationTypesQuery,
    AllUserApplicationTypesQueryVariables,
    AllUserApplicationTypesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllUserApplicationTypesQuery,
    AllUserApplicationTypesQueryVariables,
    AllUserApplicationTypesProps<TChildProps>
  >(AllUserApplicationTypesDocument, {
    alias: 'allUserApplicationTypes',
    ...operationOptions,
  });
}

/**
 * __useAllUserApplicationTypesQuery__
 *
 * To run a query within a React component, call `useAllUserApplicationTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserApplicationTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserApplicationTypesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllUserApplicationTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllUserApplicationTypesQuery, AllUserApplicationTypesQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllUserApplicationTypesQuery, AllUserApplicationTypesQueryVariables>(
    AllUserApplicationTypesDocument,
    baseOptions
  );
}
export function useAllUserApplicationTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllUserApplicationTypesQuery,
    AllUserApplicationTypesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<AllUserApplicationTypesQuery, AllUserApplicationTypesQueryVariables>(
    AllUserApplicationTypesDocument,
    baseOptions
  );
}
export type AllUserApplicationTypesQueryHookResult = ReturnType<typeof useAllUserApplicationTypesQuery>;
export type AllUserApplicationTypesLazyQueryHookResult = ReturnType<typeof useAllUserApplicationTypesLazyQuery>;
export type AllUserApplicationTypesQueryResult = ApolloReactCommon.QueryResult<
  AllUserApplicationTypesQuery,
  AllUserApplicationTypesQueryVariables
>;
export const AllUserApplicationsDocument = gql`
  query allUserApplications($query: DefaultQueryInput) {
    allUserApplications(query: $query) {
      total
      limit
      cursor
      edges {
        ...userApplication
      }
      sortable
      searchable
    }
  }
  ${UserApplicationFragmentDoc}
`;
export type AllUserApplicationsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllUserApplicationsQuery, AllUserApplicationsQueryVariables>,
  'query'
>;

export const AllUserApplicationsComponent = (props: AllUserApplicationsComponentProps) => (
  <ApolloReactComponents.Query<AllUserApplicationsQuery, AllUserApplicationsQueryVariables>
    query={AllUserApplicationsDocument}
    {...props}
  />
);

export type AllUserApplicationsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllUserApplicationsQuery,
  AllUserApplicationsQueryVariables
> &
  TChildProps;
export function withAllUserApplications<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllUserApplicationsQuery,
    AllUserApplicationsQueryVariables,
    AllUserApplicationsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllUserApplicationsQuery,
    AllUserApplicationsQueryVariables,
    AllUserApplicationsProps<TChildProps>
  >(AllUserApplicationsDocument, {
    alias: 'allUserApplications',
    ...operationOptions,
  });
}

/**
 * __useAllUserApplicationsQuery__
 *
 * To run a query within a React component, call `useAllUserApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserApplicationsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllUserApplicationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllUserApplicationsQuery, AllUserApplicationsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllUserApplicationsQuery, AllUserApplicationsQueryVariables>(
    AllUserApplicationsDocument,
    baseOptions
  );
}
export function useAllUserApplicationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllUserApplicationsQuery, AllUserApplicationsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllUserApplicationsQuery, AllUserApplicationsQueryVariables>(
    AllUserApplicationsDocument,
    baseOptions
  );
}
export type AllUserApplicationsQueryHookResult = ReturnType<typeof useAllUserApplicationsQuery>;
export type AllUserApplicationsLazyQueryHookResult = ReturnType<typeof useAllUserApplicationsLazyQuery>;
export type AllUserApplicationsQueryResult = ApolloReactCommon.QueryResult<
  AllUserApplicationsQuery,
  AllUserApplicationsQueryVariables
>;
export const AllUserDocumentsDocument = gql`
  query allUserDocuments($query: DefaultQueryInput) {
    allUserDocuments(query: $query) {
      ...userDocumentConnection
    }
  }
  ${UserDocumentConnectionFragmentDoc}
`;
export type AllUserDocumentsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllUserDocumentsQuery, AllUserDocumentsQueryVariables>,
  'query'
>;

export const AllUserDocumentsComponent = (props: AllUserDocumentsComponentProps) => (
  <ApolloReactComponents.Query<AllUserDocumentsQuery, AllUserDocumentsQueryVariables>
    query={AllUserDocumentsDocument}
    {...props}
  />
);

export type AllUserDocumentsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllUserDocumentsQuery,
  AllUserDocumentsQueryVariables
> &
  TChildProps;
export function withAllUserDocuments<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllUserDocumentsQuery,
    AllUserDocumentsQueryVariables,
    AllUserDocumentsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllUserDocumentsQuery,
    AllUserDocumentsQueryVariables,
    AllUserDocumentsProps<TChildProps>
  >(AllUserDocumentsDocument, {
    alias: 'allUserDocuments',
    ...operationOptions,
  });
}

/**
 * __useAllUserDocumentsQuery__
 *
 * To run a query within a React component, call `useAllUserDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserDocumentsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllUserDocumentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllUserDocumentsQuery, AllUserDocumentsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllUserDocumentsQuery, AllUserDocumentsQueryVariables>(
    AllUserDocumentsDocument,
    baseOptions
  );
}
export function useAllUserDocumentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllUserDocumentsQuery, AllUserDocumentsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllUserDocumentsQuery, AllUserDocumentsQueryVariables>(
    AllUserDocumentsDocument,
    baseOptions
  );
}
export type AllUserDocumentsQueryHookResult = ReturnType<typeof useAllUserDocumentsQuery>;
export type AllUserDocumentsLazyQueryHookResult = ReturnType<typeof useAllUserDocumentsLazyQuery>;
export type AllUserDocumentsQueryResult = ApolloReactCommon.QueryResult<
  AllUserDocumentsQuery,
  AllUserDocumentsQueryVariables
>;
export const AllUserFieldsDocument = gql`
  query allUserFields($query: DefaultQueryInput) {
    allUserFields(query: $query) {
      ...userFieldsConnection
    }
  }
  ${UserFieldsConnectionFragmentDoc}
`;
export type AllUserFieldsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllUserFieldsQuery, AllUserFieldsQueryVariables>,
  'query'
>;

export const AllUserFieldsComponent = (props: AllUserFieldsComponentProps) => (
  <ApolloReactComponents.Query<AllUserFieldsQuery, AllUserFieldsQueryVariables>
    query={AllUserFieldsDocument}
    {...props}
  />
);

export type AllUserFieldsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllUserFieldsQuery,
  AllUserFieldsQueryVariables
> &
  TChildProps;
export function withAllUserFields<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllUserFieldsQuery,
    AllUserFieldsQueryVariables,
    AllUserFieldsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllUserFieldsQuery,
    AllUserFieldsQueryVariables,
    AllUserFieldsProps<TChildProps>
  >(AllUserFieldsDocument, {
    alias: 'allUserFields',
    ...operationOptions,
  });
}

/**
 * __useAllUserFieldsQuery__
 *
 * To run a query within a React component, call `useAllUserFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserFieldsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllUserFieldsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllUserFieldsQuery, AllUserFieldsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllUserFieldsQuery, AllUserFieldsQueryVariables>(AllUserFieldsDocument, baseOptions);
}
export function useAllUserFieldsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllUserFieldsQuery, AllUserFieldsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllUserFieldsQuery, AllUserFieldsQueryVariables>(
    AllUserFieldsDocument,
    baseOptions
  );
}
export type AllUserFieldsQueryHookResult = ReturnType<typeof useAllUserFieldsQuery>;
export type AllUserFieldsLazyQueryHookResult = ReturnType<typeof useAllUserFieldsLazyQuery>;
export type AllUserFieldsQueryResult = ApolloReactCommon.QueryResult<AllUserFieldsQuery, AllUserFieldsQueryVariables>;
export const AllUserGroupsDocument = gql`
  query allUserGroups($query: DefaultQueryInput) {
    allUserGroups(query: $query) {
      ...userGroupConnection
    }
  }
  ${UserGroupConnectionFragmentDoc}
`;
export type AllUserGroupsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllUserGroupsQuery, AllUserGroupsQueryVariables>,
  'query'
>;

export const AllUserGroupsComponent = (props: AllUserGroupsComponentProps) => (
  <ApolloReactComponents.Query<AllUserGroupsQuery, AllUserGroupsQueryVariables>
    query={AllUserGroupsDocument}
    {...props}
  />
);

export type AllUserGroupsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllUserGroupsQuery,
  AllUserGroupsQueryVariables
> &
  TChildProps;
export function withAllUserGroups<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllUserGroupsQuery,
    AllUserGroupsQueryVariables,
    AllUserGroupsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllUserGroupsQuery,
    AllUserGroupsQueryVariables,
    AllUserGroupsProps<TChildProps>
  >(AllUserGroupsDocument, {
    alias: 'allUserGroups',
    ...operationOptions,
  });
}

/**
 * __useAllUserGroupsQuery__
 *
 * To run a query within a React component, call `useAllUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserGroupsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllUserGroupsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllUserGroupsQuery, AllUserGroupsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllUserGroupsQuery, AllUserGroupsQueryVariables>(AllUserGroupsDocument, baseOptions);
}
export function useAllUserGroupsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllUserGroupsQuery, AllUserGroupsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllUserGroupsQuery, AllUserGroupsQueryVariables>(
    AllUserGroupsDocument,
    baseOptions
  );
}
export type AllUserGroupsQueryHookResult = ReturnType<typeof useAllUserGroupsQuery>;
export type AllUserGroupsLazyQueryHookResult = ReturnType<typeof useAllUserGroupsLazyQuery>;
export type AllUserGroupsQueryResult = ApolloReactCommon.QueryResult<AllUserGroupsQuery, AllUserGroupsQueryVariables>;
export const AllUserKidsDocument = gql`
  query allUserKids($query: DefaultQueryInput) {
    allUserKids(query: $query) {
      ...userKidConnection
    }
  }
  ${UserKidConnectionFragmentDoc}
`;
export type AllUserKidsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllUserKidsQuery, AllUserKidsQueryVariables>,
  'query'
>;

export const AllUserKidsComponent = (props: AllUserKidsComponentProps) => (
  <ApolloReactComponents.Query<AllUserKidsQuery, AllUserKidsQueryVariables> query={AllUserKidsDocument} {...props} />
);

export type AllUserKidsProps<TChildProps = {}> = ApolloReactHoc.DataProps<AllUserKidsQuery, AllUserKidsQueryVariables> &
  TChildProps;
export function withAllUserKids<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllUserKidsQuery,
    AllUserKidsQueryVariables,
    AllUserKidsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<TProps, AllUserKidsQuery, AllUserKidsQueryVariables, AllUserKidsProps<TChildProps>>(
    AllUserKidsDocument,
    {
      alias: 'allUserKids',
      ...operationOptions,
    }
  );
}

/**
 * __useAllUserKidsQuery__
 *
 * To run a query within a React component, call `useAllUserKidsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserKidsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserKidsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllUserKidsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllUserKidsQuery, AllUserKidsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllUserKidsQuery, AllUserKidsQueryVariables>(AllUserKidsDocument, baseOptions);
}
export function useAllUserKidsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllUserKidsQuery, AllUserKidsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllUserKidsQuery, AllUserKidsQueryVariables>(AllUserKidsDocument, baseOptions);
}
export type AllUserKidsQueryHookResult = ReturnType<typeof useAllUserKidsQuery>;
export type AllUserKidsLazyQueryHookResult = ReturnType<typeof useAllUserKidsLazyQuery>;
export type AllUserKidsQueryResult = ApolloReactCommon.QueryResult<AllUserKidsQuery, AllUserKidsQueryVariables>;
export const AllUserSocialAccountsDocument = gql`
  query allUserSocialAccounts($query: DefaultQueryInput) {
    allUserSocialAccounts(query: $query) {
      total
      edges {
        ...userSocialAccount
      }
    }
  }
  ${UserSocialAccountFragmentDoc}
`;
export type AllUserSocialAccountsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllUserSocialAccountsQuery, AllUserSocialAccountsQueryVariables>,
  'query'
>;

export const AllUserSocialAccountsComponent = (props: AllUserSocialAccountsComponentProps) => (
  <ApolloReactComponents.Query<AllUserSocialAccountsQuery, AllUserSocialAccountsQueryVariables>
    query={AllUserSocialAccountsDocument}
    {...props}
  />
);

export type AllUserSocialAccountsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllUserSocialAccountsQuery,
  AllUserSocialAccountsQueryVariables
> &
  TChildProps;
export function withAllUserSocialAccounts<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllUserSocialAccountsQuery,
    AllUserSocialAccountsQueryVariables,
    AllUserSocialAccountsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllUserSocialAccountsQuery,
    AllUserSocialAccountsQueryVariables,
    AllUserSocialAccountsProps<TChildProps>
  >(AllUserSocialAccountsDocument, {
    alias: 'allUserSocialAccounts',
    ...operationOptions,
  });
}

/**
 * __useAllUserSocialAccountsQuery__
 *
 * To run a query within a React component, call `useAllUserSocialAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserSocialAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserSocialAccountsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllUserSocialAccountsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllUserSocialAccountsQuery, AllUserSocialAccountsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllUserSocialAccountsQuery, AllUserSocialAccountsQueryVariables>(
    AllUserSocialAccountsDocument,
    baseOptions
  );
}
export function useAllUserSocialAccountsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllUserSocialAccountsQuery, AllUserSocialAccountsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllUserSocialAccountsQuery, AllUserSocialAccountsQueryVariables>(
    AllUserSocialAccountsDocument,
    baseOptions
  );
}
export type AllUserSocialAccountsQueryHookResult = ReturnType<typeof useAllUserSocialAccountsQuery>;
export type AllUserSocialAccountsLazyQueryHookResult = ReturnType<typeof useAllUserSocialAccountsLazyQuery>;
export type AllUserSocialAccountsQueryResult = ApolloReactCommon.QueryResult<
  AllUserSocialAccountsQuery,
  AllUserSocialAccountsQueryVariables
>;
export const AllUserSubscribersDocument = gql`
  query allUserSubscribers($query: DefaultQueryInput) {
    allUserSubscribers(query: $query) {
      ...userSubscriberConnection
    }
  }
  ${UserSubscriberConnectionFragmentDoc}
`;
export type AllUserSubscribersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllUserSubscribersQuery, AllUserSubscribersQueryVariables>,
  'query'
>;

export const AllUserSubscribersComponent = (props: AllUserSubscribersComponentProps) => (
  <ApolloReactComponents.Query<AllUserSubscribersQuery, AllUserSubscribersQueryVariables>
    query={AllUserSubscribersDocument}
    {...props}
  />
);

export type AllUserSubscribersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllUserSubscribersQuery,
  AllUserSubscribersQueryVariables
> &
  TChildProps;
export function withAllUserSubscribers<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllUserSubscribersQuery,
    AllUserSubscribersQueryVariables,
    AllUserSubscribersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllUserSubscribersQuery,
    AllUserSubscribersQueryVariables,
    AllUserSubscribersProps<TChildProps>
  >(AllUserSubscribersDocument, {
    alias: 'allUserSubscribers',
    ...operationOptions,
  });
}

/**
 * __useAllUserSubscribersQuery__
 *
 * To run a query within a React component, call `useAllUserSubscribersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserSubscribersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserSubscribersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllUserSubscribersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllUserSubscribersQuery, AllUserSubscribersQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllUserSubscribersQuery, AllUserSubscribersQueryVariables>(
    AllUserSubscribersDocument,
    baseOptions
  );
}
export function useAllUserSubscribersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllUserSubscribersQuery, AllUserSubscribersQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllUserSubscribersQuery, AllUserSubscribersQueryVariables>(
    AllUserSubscribersDocument,
    baseOptions
  );
}
export type AllUserSubscribersQueryHookResult = ReturnType<typeof useAllUserSubscribersQuery>;
export type AllUserSubscribersLazyQueryHookResult = ReturnType<typeof useAllUserSubscribersLazyQuery>;
export type AllUserSubscribersQueryResult = ApolloReactCommon.QueryResult<
  AllUserSubscribersQuery,
  AllUserSubscribersQueryVariables
>;
export const AllUserSubscribersLogsDocument = gql`
  query allUserSubscribersLogs($query: DefaultQueryInput) {
    allUserSubscribersLogs(query: $query) {
      ...userSubscribersLogConnection
    }
  }
  ${UserSubscribersLogConnectionFragmentDoc}
`;
export type AllUserSubscribersLogsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllUserSubscribersLogsQuery, AllUserSubscribersLogsQueryVariables>,
  'query'
>;

export const AllUserSubscribersLogsComponent = (props: AllUserSubscribersLogsComponentProps) => (
  <ApolloReactComponents.Query<AllUserSubscribersLogsQuery, AllUserSubscribersLogsQueryVariables>
    query={AllUserSubscribersLogsDocument}
    {...props}
  />
);

export type AllUserSubscribersLogsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllUserSubscribersLogsQuery,
  AllUserSubscribersLogsQueryVariables
> &
  TChildProps;
export function withAllUserSubscribersLogs<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllUserSubscribersLogsQuery,
    AllUserSubscribersLogsQueryVariables,
    AllUserSubscribersLogsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllUserSubscribersLogsQuery,
    AllUserSubscribersLogsQueryVariables,
    AllUserSubscribersLogsProps<TChildProps>
  >(AllUserSubscribersLogsDocument, {
    alias: 'allUserSubscribersLogs',
    ...operationOptions,
  });
}

/**
 * __useAllUserSubscribersLogsQuery__
 *
 * To run a query within a React component, call `useAllUserSubscribersLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserSubscribersLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserSubscribersLogsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllUserSubscribersLogsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllUserSubscribersLogsQuery, AllUserSubscribersLogsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllUserSubscribersLogsQuery, AllUserSubscribersLogsQueryVariables>(
    AllUserSubscribersLogsDocument,
    baseOptions
  );
}
export function useAllUserSubscribersLogsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllUserSubscribersLogsQuery, AllUserSubscribersLogsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllUserSubscribersLogsQuery, AllUserSubscribersLogsQueryVariables>(
    AllUserSubscribersLogsDocument,
    baseOptions
  );
}
export type AllUserSubscribersLogsQueryHookResult = ReturnType<typeof useAllUserSubscribersLogsQuery>;
export type AllUserSubscribersLogsLazyQueryHookResult = ReturnType<typeof useAllUserSubscribersLogsLazyQuery>;
export type AllUserSubscribersLogsQueryResult = ApolloReactCommon.QueryResult<
  AllUserSubscribersLogsQuery,
  AllUserSubscribersLogsQueryVariables
>;
export const AllUserSubscriptionsDocument = gql`
  query allUserSubscriptions($query: DefaultQueryInput) {
    allUserSubscriptions(query: $query) {
      ...userSubscriptionConnection
    }
  }
  ${UserSubscriptionConnectionFragmentDoc}
`;
export type AllUserSubscriptionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllUserSubscriptionsQuery, AllUserSubscriptionsQueryVariables>,
  'query'
>;

export const AllUserSubscriptionsComponent = (props: AllUserSubscriptionsComponentProps) => (
  <ApolloReactComponents.Query<AllUserSubscriptionsQuery, AllUserSubscriptionsQueryVariables>
    query={AllUserSubscriptionsDocument}
    {...props}
  />
);

export type AllUserSubscriptionsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AllUserSubscriptionsQuery,
  AllUserSubscriptionsQueryVariables
> &
  TChildProps;
export function withAllUserSubscriptions<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllUserSubscriptionsQuery,
    AllUserSubscriptionsQueryVariables,
    AllUserSubscriptionsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllUserSubscriptionsQuery,
    AllUserSubscriptionsQueryVariables,
    AllUserSubscriptionsProps<TChildProps>
  >(AllUserSubscriptionsDocument, {
    alias: 'allUserSubscriptions',
    ...operationOptions,
  });
}

/**
 * __useAllUserSubscriptionsQuery__
 *
 * To run a query within a React component, call `useAllUserSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserSubscriptionsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllUserSubscriptionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllUserSubscriptionsQuery, AllUserSubscriptionsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllUserSubscriptionsQuery, AllUserSubscriptionsQueryVariables>(
    AllUserSubscriptionsDocument,
    baseOptions
  );
}
export function useAllUserSubscriptionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllUserSubscriptionsQuery, AllUserSubscriptionsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllUserSubscriptionsQuery, AllUserSubscriptionsQueryVariables>(
    AllUserSubscriptionsDocument,
    baseOptions
  );
}
export type AllUserSubscriptionsQueryHookResult = ReturnType<typeof useAllUserSubscriptionsQuery>;
export type AllUserSubscriptionsLazyQueryHookResult = ReturnType<typeof useAllUserSubscriptionsLazyQuery>;
export type AllUserSubscriptionsQueryResult = ApolloReactCommon.QueryResult<
  AllUserSubscriptionsQuery,
  AllUserSubscriptionsQueryVariables
>;
export const AllUsersDocument = gql`
  query allUsers($query: DefaultQueryInput) {
    allUsers(query: $query) {
      ...userConnection
    }
  }
  ${UserConnectionFragmentDoc}
`;
export type AllUsersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AllUsersQuery, AllUsersQueryVariables>,
  'query'
>;

export const AllUsersComponent = (props: AllUsersComponentProps) => (
  <ApolloReactComponents.Query<AllUsersQuery, AllUsersQueryVariables> query={AllUsersDocument} {...props} />
);

export type AllUsersProps<TChildProps = {}> = ApolloReactHoc.DataProps<AllUsersQuery, AllUsersQueryVariables> &
  TChildProps;
export function withAllUsers<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllUsersQuery,
    AllUsersQueryVariables,
    AllUsersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<TProps, AllUsersQuery, AllUsersQueryVariables, AllUsersProps<TChildProps>>(
    AllUsersDocument,
    {
      alias: 'allUsers',
      ...operationOptions,
    }
  );
}

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllUsersQuery, AllUsersQueryVariables>
) {
  return ApolloReactHooks.useQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, baseOptions);
}
export function useAllUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllUsersQuery, AllUsersQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, baseOptions);
}
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>;
export type AllUsersLazyQueryHookResult = ReturnType<typeof useAllUsersLazyQuery>;
export type AllUsersQueryResult = ApolloReactCommon.QueryResult<AllUsersQuery, AllUsersQueryVariables>;
export const ApplicationFiltersDocument = gql`
  query applicationFilters($partnerId: Int!) {
    applicationFilters(partnerId: $partnerId) {
      ...filterItem
    }
  }
  ${FilterItemFragmentDoc}
`;
export type ApplicationFiltersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<ApplicationFiltersQuery, ApplicationFiltersQueryVariables>,
  'query'
> &
  ({ variables: ApplicationFiltersQueryVariables; skip?: boolean } | { skip: boolean });

export const ApplicationFiltersComponent = (props: ApplicationFiltersComponentProps) => (
  <ApolloReactComponents.Query<ApplicationFiltersQuery, ApplicationFiltersQueryVariables>
    query={ApplicationFiltersDocument}
    {...props}
  />
);

export type ApplicationFiltersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ApplicationFiltersQuery,
  ApplicationFiltersQueryVariables
> &
  TChildProps;
export function withApplicationFilters<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ApplicationFiltersQuery,
    ApplicationFiltersQueryVariables,
    ApplicationFiltersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ApplicationFiltersQuery,
    ApplicationFiltersQueryVariables,
    ApplicationFiltersProps<TChildProps>
  >(ApplicationFiltersDocument, {
    alias: 'applicationFilters',
    ...operationOptions,
  });
}

/**
 * __useApplicationFiltersQuery__
 *
 * To run a query within a React component, call `useApplicationFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationFiltersQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useApplicationFiltersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ApplicationFiltersQuery, ApplicationFiltersQueryVariables>
) {
  return ApolloReactHooks.useQuery<ApplicationFiltersQuery, ApplicationFiltersQueryVariables>(
    ApplicationFiltersDocument,
    baseOptions
  );
}
export function useApplicationFiltersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApplicationFiltersQuery, ApplicationFiltersQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<ApplicationFiltersQuery, ApplicationFiltersQueryVariables>(
    ApplicationFiltersDocument,
    baseOptions
  );
}
export type ApplicationFiltersQueryHookResult = ReturnType<typeof useApplicationFiltersQuery>;
export type ApplicationFiltersLazyQueryHookResult = ReturnType<typeof useApplicationFiltersLazyQuery>;
export type ApplicationFiltersQueryResult = ApolloReactCommon.QueryResult<
  ApplicationFiltersQuery,
  ApplicationFiltersQueryVariables
>;
export const ArticleDocument = gql`
  query article($id: Int!) {
    article(id: $id) {
      ...articleFull
    }
  }
  ${ArticleFullFragmentDoc}
`;
export type ArticleComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<ArticleQuery, ArticleQueryVariables>,
  'query'
> &
  ({ variables: ArticleQueryVariables; skip?: boolean } | { skip: boolean });

export const ArticleComponent = (props: ArticleComponentProps) => (
  <ApolloReactComponents.Query<ArticleQuery, ArticleQueryVariables> query={ArticleDocument} {...props} />
);

export type ArticleProps<TChildProps = {}> = ApolloReactHoc.DataProps<ArticleQuery, ArticleQueryVariables> &
  TChildProps;
export function withArticle<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ArticleQuery,
    ArticleQueryVariables,
    ArticleProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<TProps, ArticleQuery, ArticleQueryVariables, ArticleProps<TChildProps>>(
    ArticleDocument,
    {
      alias: 'article',
      ...operationOptions,
    }
  );
}

/**
 * __useArticleQuery__
 *
 * To run a query within a React component, call `useArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
  return ApolloReactHooks.useQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, baseOptions);
}
export function useArticleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArticleQuery, ArticleQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, baseOptions);
}
export type ArticleQueryHookResult = ReturnType<typeof useArticleQuery>;
export type ArticleLazyQueryHookResult = ReturnType<typeof useArticleLazyQuery>;
export type ArticleQueryResult = ApolloReactCommon.QueryResult<ArticleQuery, ArticleQueryVariables>;
export const ArticleAuthorDocument = gql`
  query articleAuthor($id: Int!) {
    articleAuthor(id: $id) {
      ...articleAuthor
    }
  }
  ${ArticleAuthorFragmentDoc}
`;
export type ArticleAuthorComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<ArticleAuthorQuery, ArticleAuthorQueryVariables>,
  'query'
> &
  ({ variables: ArticleAuthorQueryVariables; skip?: boolean } | { skip: boolean });

export const ArticleAuthorComponent = (props: ArticleAuthorComponentProps) => (
  <ApolloReactComponents.Query<ArticleAuthorQuery, ArticleAuthorQueryVariables>
    query={ArticleAuthorDocument}
    {...props}
  />
);

export type ArticleAuthorProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ArticleAuthorQuery,
  ArticleAuthorQueryVariables
> &
  TChildProps;
export function withArticleAuthor<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ArticleAuthorQuery,
    ArticleAuthorQueryVariables,
    ArticleAuthorProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ArticleAuthorQuery,
    ArticleAuthorQueryVariables,
    ArticleAuthorProps<TChildProps>
  >(ArticleAuthorDocument, {
    alias: 'articleAuthor',
    ...operationOptions,
  });
}

/**
 * __useArticleAuthorQuery__
 *
 * To run a query within a React component, call `useArticleAuthorQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleAuthorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleAuthorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticleAuthorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ArticleAuthorQuery, ArticleAuthorQueryVariables>
) {
  return ApolloReactHooks.useQuery<ArticleAuthorQuery, ArticleAuthorQueryVariables>(ArticleAuthorDocument, baseOptions);
}
export function useArticleAuthorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArticleAuthorQuery, ArticleAuthorQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<ArticleAuthorQuery, ArticleAuthorQueryVariables>(
    ArticleAuthorDocument,
    baseOptions
  );
}
export type ArticleAuthorQueryHookResult = ReturnType<typeof useArticleAuthorQuery>;
export type ArticleAuthorLazyQueryHookResult = ReturnType<typeof useArticleAuthorLazyQuery>;
export type ArticleAuthorQueryResult = ApolloReactCommon.QueryResult<ArticleAuthorQuery, ArticleAuthorQueryVariables>;
export const ArticleBlockDocument = gql`
  query articleBlock($id: Int!) {
    articleBlock(id: $id) {
      ...articleBlock
    }
  }
  ${ArticleBlockFragmentDoc}
`;
export type ArticleBlockComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<ArticleBlockQuery, ArticleBlockQueryVariables>,
  'query'
> &
  ({ variables: ArticleBlockQueryVariables; skip?: boolean } | { skip: boolean });

export const ArticleBlockComponent = (props: ArticleBlockComponentProps) => (
  <ApolloReactComponents.Query<ArticleBlockQuery, ArticleBlockQueryVariables> query={ArticleBlockDocument} {...props} />
);

export type ArticleBlockProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ArticleBlockQuery,
  ArticleBlockQueryVariables
> &
  TChildProps;
export function withArticleBlock<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ArticleBlockQuery,
    ArticleBlockQueryVariables,
    ArticleBlockProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ArticleBlockQuery,
    ArticleBlockQueryVariables,
    ArticleBlockProps<TChildProps>
  >(ArticleBlockDocument, {
    alias: 'articleBlock',
    ...operationOptions,
  });
}

/**
 * __useArticleBlockQuery__
 *
 * To run a query within a React component, call `useArticleBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleBlockQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticleBlockQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ArticleBlockQuery, ArticleBlockQueryVariables>
) {
  return ApolloReactHooks.useQuery<ArticleBlockQuery, ArticleBlockQueryVariables>(ArticleBlockDocument, baseOptions);
}
export function useArticleBlockLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArticleBlockQuery, ArticleBlockQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<ArticleBlockQuery, ArticleBlockQueryVariables>(
    ArticleBlockDocument,
    baseOptions
  );
}
export type ArticleBlockQueryHookResult = ReturnType<typeof useArticleBlockQuery>;
export type ArticleBlockLazyQueryHookResult = ReturnType<typeof useArticleBlockLazyQuery>;
export type ArticleBlockQueryResult = ApolloReactCommon.QueryResult<ArticleBlockQuery, ArticleBlockQueryVariables>;
export const ArticleCategoryDocument = gql`
  query articleCategory($id: Int!) {
    articleCategory(id: $id) {
      ...articleCategory
    }
  }
  ${ArticleCategoryFragmentDoc}
`;
export type ArticleCategoryComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<ArticleCategoryQuery, ArticleCategoryQueryVariables>,
  'query'
> &
  ({ variables: ArticleCategoryQueryVariables; skip?: boolean } | { skip: boolean });

export const ArticleCategoryComponent = (props: ArticleCategoryComponentProps) => (
  <ApolloReactComponents.Query<ArticleCategoryQuery, ArticleCategoryQueryVariables>
    query={ArticleCategoryDocument}
    {...props}
  />
);

export type ArticleCategoryProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ArticleCategoryQuery,
  ArticleCategoryQueryVariables
> &
  TChildProps;
export function withArticleCategory<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ArticleCategoryQuery,
    ArticleCategoryQueryVariables,
    ArticleCategoryProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ArticleCategoryQuery,
    ArticleCategoryQueryVariables,
    ArticleCategoryProps<TChildProps>
  >(ArticleCategoryDocument, {
    alias: 'articleCategory',
    ...operationOptions,
  });
}

/**
 * __useArticleCategoryQuery__
 *
 * To run a query within a React component, call `useArticleCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticleCategoryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ArticleCategoryQuery, ArticleCategoryQueryVariables>
) {
  return ApolloReactHooks.useQuery<ArticleCategoryQuery, ArticleCategoryQueryVariables>(
    ArticleCategoryDocument,
    baseOptions
  );
}
export function useArticleCategoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArticleCategoryQuery, ArticleCategoryQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<ArticleCategoryQuery, ArticleCategoryQueryVariables>(
    ArticleCategoryDocument,
    baseOptions
  );
}
export type ArticleCategoryQueryHookResult = ReturnType<typeof useArticleCategoryQuery>;
export type ArticleCategoryLazyQueryHookResult = ReturnType<typeof useArticleCategoryLazyQuery>;
export type ArticleCategoryQueryResult = ApolloReactCommon.QueryResult<
  ArticleCategoryQuery,
  ArticleCategoryQueryVariables
>;
export const ArticleLinkDocument = gql`
  query articleLink($id: Int!) {
    articleLink(id: $id) {
      ...articleLink
    }
  }
  ${ArticleLinkFragmentDoc}
`;
export type ArticleLinkComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<ArticleLinkQuery, ArticleLinkQueryVariables>,
  'query'
> &
  ({ variables: ArticleLinkQueryVariables; skip?: boolean } | { skip: boolean });

export const ArticleLinkComponent = (props: ArticleLinkComponentProps) => (
  <ApolloReactComponents.Query<ArticleLinkQuery, ArticleLinkQueryVariables> query={ArticleLinkDocument} {...props} />
);

export type ArticleLinkProps<TChildProps = {}> = ApolloReactHoc.DataProps<ArticleLinkQuery, ArticleLinkQueryVariables> &
  TChildProps;
export function withArticleLink<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ArticleLinkQuery,
    ArticleLinkQueryVariables,
    ArticleLinkProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<TProps, ArticleLinkQuery, ArticleLinkQueryVariables, ArticleLinkProps<TChildProps>>(
    ArticleLinkDocument,
    {
      alias: 'articleLink',
      ...operationOptions,
    }
  );
}

/**
 * __useArticleLinkQuery__
 *
 * To run a query within a React component, call `useArticleLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticleLinkQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ArticleLinkQuery, ArticleLinkQueryVariables>
) {
  return ApolloReactHooks.useQuery<ArticleLinkQuery, ArticleLinkQueryVariables>(ArticleLinkDocument, baseOptions);
}
export function useArticleLinkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArticleLinkQuery, ArticleLinkQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<ArticleLinkQuery, ArticleLinkQueryVariables>(ArticleLinkDocument, baseOptions);
}
export type ArticleLinkQueryHookResult = ReturnType<typeof useArticleLinkQuery>;
export type ArticleLinkLazyQueryHookResult = ReturnType<typeof useArticleLinkLazyQuery>;
export type ArticleLinkQueryResult = ApolloReactCommon.QueryResult<ArticleLinkQuery, ArticleLinkQueryVariables>;
export const ArticleTagDocument = gql`
  query articleTag($id: Int!) {
    articleTag(id: $id) {
      ...articleTag
    }
  }
  ${ArticleTagFragmentDoc}
`;
export type ArticleTagComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<ArticleTagQuery, ArticleTagQueryVariables>,
  'query'
> &
  ({ variables: ArticleTagQueryVariables; skip?: boolean } | { skip: boolean });

export const ArticleTagComponent = (props: ArticleTagComponentProps) => (
  <ApolloReactComponents.Query<ArticleTagQuery, ArticleTagQueryVariables> query={ArticleTagDocument} {...props} />
);

export type ArticleTagProps<TChildProps = {}> = ApolloReactHoc.DataProps<ArticleTagQuery, ArticleTagQueryVariables> &
  TChildProps;
export function withArticleTag<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ArticleTagQuery,
    ArticleTagQueryVariables,
    ArticleTagProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<TProps, ArticleTagQuery, ArticleTagQueryVariables, ArticleTagProps<TChildProps>>(
    ArticleTagDocument,
    {
      alias: 'articleTag',
      ...operationOptions,
    }
  );
}

/**
 * __useArticleTagQuery__
 *
 * To run a query within a React component, call `useArticleTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleTagQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticleTagQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ArticleTagQuery, ArticleTagQueryVariables>
) {
  return ApolloReactHooks.useQuery<ArticleTagQuery, ArticleTagQueryVariables>(ArticleTagDocument, baseOptions);
}
export function useArticleTagLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArticleTagQuery, ArticleTagQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<ArticleTagQuery, ArticleTagQueryVariables>(ArticleTagDocument, baseOptions);
}
export type ArticleTagQueryHookResult = ReturnType<typeof useArticleTagQuery>;
export type ArticleTagLazyQueryHookResult = ReturnType<typeof useArticleTagLazyQuery>;
export type ArticleTagQueryResult = ApolloReactCommon.QueryResult<ArticleTagQuery, ArticleTagQueryVariables>;
export const CatalogueDocument = gql`
  query catalogue($id: Int!) {
    catalogue(id: $id) {
      ...catalogueFull
    }
  }
  ${CatalogueFullFragmentDoc}
`;
export type CatalogueComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CatalogueQuery, CatalogueQueryVariables>,
  'query'
> &
  ({ variables: CatalogueQueryVariables; skip?: boolean } | { skip: boolean });

export const CatalogueComponent = (props: CatalogueComponentProps) => (
  <ApolloReactComponents.Query<CatalogueQuery, CatalogueQueryVariables> query={CatalogueDocument} {...props} />
);

export type CatalogueProps<TChildProps = {}> = ApolloReactHoc.DataProps<CatalogueQuery, CatalogueQueryVariables> &
  TChildProps;
export function withCatalogue<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CatalogueQuery,
    CatalogueQueryVariables,
    CatalogueProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<TProps, CatalogueQuery, CatalogueQueryVariables, CatalogueProps<TChildProps>>(
    CatalogueDocument,
    {
      alias: 'catalogue',
      ...operationOptions,
    }
  );
}

/**
 * __useCatalogueQuery__
 *
 * To run a query within a React component, call `useCatalogueQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCatalogueQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CatalogueQuery, CatalogueQueryVariables>
) {
  return ApolloReactHooks.useQuery<CatalogueQuery, CatalogueQueryVariables>(CatalogueDocument, baseOptions);
}
export function useCatalogueLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CatalogueQuery, CatalogueQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<CatalogueQuery, CatalogueQueryVariables>(CatalogueDocument, baseOptions);
}
export type CatalogueQueryHookResult = ReturnType<typeof useCatalogueQuery>;
export type CatalogueLazyQueryHookResult = ReturnType<typeof useCatalogueLazyQuery>;
export type CatalogueQueryResult = ApolloReactCommon.QueryResult<CatalogueQuery, CatalogueQueryVariables>;
export const CatalogueCategoryDocument = gql`
  query catalogueCategory($id: Int!) {
    catalogueCategory(id: $id) {
      ...catalogueCategoryFull
    }
  }
  ${CatalogueCategoryFullFragmentDoc}
`;
export type CatalogueCategoryComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CatalogueCategoryQuery, CatalogueCategoryQueryVariables>,
  'query'
> &
  ({ variables: CatalogueCategoryQueryVariables; skip?: boolean } | { skip: boolean });

export const CatalogueCategoryComponent = (props: CatalogueCategoryComponentProps) => (
  <ApolloReactComponents.Query<CatalogueCategoryQuery, CatalogueCategoryQueryVariables>
    query={CatalogueCategoryDocument}
    {...props}
  />
);

export type CatalogueCategoryProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CatalogueCategoryQuery,
  CatalogueCategoryQueryVariables
> &
  TChildProps;
export function withCatalogueCategory<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CatalogueCategoryQuery,
    CatalogueCategoryQueryVariables,
    CatalogueCategoryProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CatalogueCategoryQuery,
    CatalogueCategoryQueryVariables,
    CatalogueCategoryProps<TChildProps>
  >(CatalogueCategoryDocument, {
    alias: 'catalogueCategory',
    ...operationOptions,
  });
}

/**
 * __useCatalogueCategoryQuery__
 *
 * To run a query within a React component, call `useCatalogueCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogueCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogueCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCatalogueCategoryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CatalogueCategoryQuery, CatalogueCategoryQueryVariables>
) {
  return ApolloReactHooks.useQuery<CatalogueCategoryQuery, CatalogueCategoryQueryVariables>(
    CatalogueCategoryDocument,
    baseOptions
  );
}
export function useCatalogueCategoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CatalogueCategoryQuery, CatalogueCategoryQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<CatalogueCategoryQuery, CatalogueCategoryQueryVariables>(
    CatalogueCategoryDocument,
    baseOptions
  );
}
export type CatalogueCategoryQueryHookResult = ReturnType<typeof useCatalogueCategoryQuery>;
export type CatalogueCategoryLazyQueryHookResult = ReturnType<typeof useCatalogueCategoryLazyQuery>;
export type CatalogueCategoryQueryResult = ApolloReactCommon.QueryResult<
  CatalogueCategoryQuery,
  CatalogueCategoryQueryVariables
>;
export const CatalogueItemDocument = gql`
  query catalogueItem($id: Int!) {
    catalogueItem(id: $id) {
      ...catalogueItem
    }
  }
  ${CatalogueItemFragmentDoc}
`;
export type CatalogueItemComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CatalogueItemQuery, CatalogueItemQueryVariables>,
  'query'
> &
  ({ variables: CatalogueItemQueryVariables; skip?: boolean } | { skip: boolean });

export const CatalogueItemComponent = (props: CatalogueItemComponentProps) => (
  <ApolloReactComponents.Query<CatalogueItemQuery, CatalogueItemQueryVariables>
    query={CatalogueItemDocument}
    {...props}
  />
);

export type CatalogueItemProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CatalogueItemQuery,
  CatalogueItemQueryVariables
> &
  TChildProps;
export function withCatalogueItem<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CatalogueItemQuery,
    CatalogueItemQueryVariables,
    CatalogueItemProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CatalogueItemQuery,
    CatalogueItemQueryVariables,
    CatalogueItemProps<TChildProps>
  >(CatalogueItemDocument, {
    alias: 'catalogueItem',
    ...operationOptions,
  });
}

/**
 * __useCatalogueItemQuery__
 *
 * To run a query within a React component, call `useCatalogueItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogueItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogueItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCatalogueItemQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CatalogueItemQuery, CatalogueItemQueryVariables>
) {
  return ApolloReactHooks.useQuery<CatalogueItemQuery, CatalogueItemQueryVariables>(CatalogueItemDocument, baseOptions);
}
export function useCatalogueItemLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CatalogueItemQuery, CatalogueItemQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<CatalogueItemQuery, CatalogueItemQueryVariables>(
    CatalogueItemDocument,
    baseOptions
  );
}
export type CatalogueItemQueryHookResult = ReturnType<typeof useCatalogueItemQuery>;
export type CatalogueItemLazyQueryHookResult = ReturnType<typeof useCatalogueItemLazyQuery>;
export type CatalogueItemQueryResult = ApolloReactCommon.QueryResult<CatalogueItemQuery, CatalogueItemQueryVariables>;
export const CatalogueItemPriceDocument = gql`
  query catalogueItemPrice($id: Int!) {
    catalogueItemPrice(id: $id) {
      ...catalogueItemPrice
    }
  }
  ${CatalogueItemPriceFragmentDoc}
`;
export type CatalogueItemPriceComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CatalogueItemPriceQuery, CatalogueItemPriceQueryVariables>,
  'query'
> &
  ({ variables: CatalogueItemPriceQueryVariables; skip?: boolean } | { skip: boolean });

export const CatalogueItemPriceComponent = (props: CatalogueItemPriceComponentProps) => (
  <ApolloReactComponents.Query<CatalogueItemPriceQuery, CatalogueItemPriceQueryVariables>
    query={CatalogueItemPriceDocument}
    {...props}
  />
);

export type CatalogueItemPriceProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CatalogueItemPriceQuery,
  CatalogueItemPriceQueryVariables
> &
  TChildProps;
export function withCatalogueItemPrice<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CatalogueItemPriceQuery,
    CatalogueItemPriceQueryVariables,
    CatalogueItemPriceProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CatalogueItemPriceQuery,
    CatalogueItemPriceQueryVariables,
    CatalogueItemPriceProps<TChildProps>
  >(CatalogueItemPriceDocument, {
    alias: 'catalogueItemPrice',
    ...operationOptions,
  });
}

/**
 * __useCatalogueItemPriceQuery__
 *
 * To run a query within a React component, call `useCatalogueItemPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogueItemPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogueItemPriceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCatalogueItemPriceQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CatalogueItemPriceQuery, CatalogueItemPriceQueryVariables>
) {
  return ApolloReactHooks.useQuery<CatalogueItemPriceQuery, CatalogueItemPriceQueryVariables>(
    CatalogueItemPriceDocument,
    baseOptions
  );
}
export function useCatalogueItemPriceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CatalogueItemPriceQuery, CatalogueItemPriceQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<CatalogueItemPriceQuery, CatalogueItemPriceQueryVariables>(
    CatalogueItemPriceDocument,
    baseOptions
  );
}
export type CatalogueItemPriceQueryHookResult = ReturnType<typeof useCatalogueItemPriceQuery>;
export type CatalogueItemPriceLazyQueryHookResult = ReturnType<typeof useCatalogueItemPriceLazyQuery>;
export type CatalogueItemPriceQueryResult = ApolloReactCommon.QueryResult<
  CatalogueItemPriceQuery,
  CatalogueItemPriceQueryVariables
>;
export const CatalogueItemsDocument = gql`
  query catalogueItems($query: DefaultQueryInput) {
    catalogueItems(query: $query) {
      ...catalogueItemInCollection
    }
  }
  ${CatalogueItemInCollectionFragmentDoc}
`;
export type CatalogueItemsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CatalogueItemsQuery, CatalogueItemsQueryVariables>,
  'query'
>;

export const CatalogueItemsComponent = (props: CatalogueItemsComponentProps) => (
  <ApolloReactComponents.Query<CatalogueItemsQuery, CatalogueItemsQueryVariables>
    query={CatalogueItemsDocument}
    {...props}
  />
);

export type CatalogueItemsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CatalogueItemsQuery,
  CatalogueItemsQueryVariables
> &
  TChildProps;
export function withCatalogueItems<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CatalogueItemsQuery,
    CatalogueItemsQueryVariables,
    CatalogueItemsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CatalogueItemsQuery,
    CatalogueItemsQueryVariables,
    CatalogueItemsProps<TChildProps>
  >(CatalogueItemsDocument, {
    alias: 'catalogueItems',
    ...operationOptions,
  });
}

/**
 * __useCatalogueItemsQuery__
 *
 * To run a query within a React component, call `useCatalogueItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogueItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogueItemsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useCatalogueItemsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CatalogueItemsQuery, CatalogueItemsQueryVariables>
) {
  return ApolloReactHooks.useQuery<CatalogueItemsQuery, CatalogueItemsQueryVariables>(
    CatalogueItemsDocument,
    baseOptions
  );
}
export function useCatalogueItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CatalogueItemsQuery, CatalogueItemsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<CatalogueItemsQuery, CatalogueItemsQueryVariables>(
    CatalogueItemsDocument,
    baseOptions
  );
}
export type CatalogueItemsQueryHookResult = ReturnType<typeof useCatalogueItemsQuery>;
export type CatalogueItemsLazyQueryHookResult = ReturnType<typeof useCatalogueItemsLazyQuery>;
export type CatalogueItemsQueryResult = ApolloReactCommon.QueryResult<
  CatalogueItemsQuery,
  CatalogueItemsQueryVariables
>;
export const CatalogueItemsByEventDocument = gql`
  query catalogueItemsByEvent($eventId: Int!) {
    catalogueItemsByEvent(eventId: $eventId) {
      ...catalogueItem
    }
  }
  ${CatalogueItemFragmentDoc}
`;
export type CatalogueItemsByEventComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CatalogueItemsByEventQuery, CatalogueItemsByEventQueryVariables>,
  'query'
> &
  ({ variables: CatalogueItemsByEventQueryVariables; skip?: boolean } | { skip: boolean });

export const CatalogueItemsByEventComponent = (props: CatalogueItemsByEventComponentProps) => (
  <ApolloReactComponents.Query<CatalogueItemsByEventQuery, CatalogueItemsByEventQueryVariables>
    query={CatalogueItemsByEventDocument}
    {...props}
  />
);

export type CatalogueItemsByEventProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CatalogueItemsByEventQuery,
  CatalogueItemsByEventQueryVariables
> &
  TChildProps;
export function withCatalogueItemsByEvent<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CatalogueItemsByEventQuery,
    CatalogueItemsByEventQueryVariables,
    CatalogueItemsByEventProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CatalogueItemsByEventQuery,
    CatalogueItemsByEventQueryVariables,
    CatalogueItemsByEventProps<TChildProps>
  >(CatalogueItemsByEventDocument, {
    alias: 'catalogueItemsByEvent',
    ...operationOptions,
  });
}

/**
 * __useCatalogueItemsByEventQuery__
 *
 * To run a query within a React component, call `useCatalogueItemsByEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogueItemsByEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogueItemsByEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useCatalogueItemsByEventQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CatalogueItemsByEventQuery, CatalogueItemsByEventQueryVariables>
) {
  return ApolloReactHooks.useQuery<CatalogueItemsByEventQuery, CatalogueItemsByEventQueryVariables>(
    CatalogueItemsByEventDocument,
    baseOptions
  );
}
export function useCatalogueItemsByEventLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CatalogueItemsByEventQuery, CatalogueItemsByEventQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<CatalogueItemsByEventQuery, CatalogueItemsByEventQueryVariables>(
    CatalogueItemsByEventDocument,
    baseOptions
  );
}
export type CatalogueItemsByEventQueryHookResult = ReturnType<typeof useCatalogueItemsByEventQuery>;
export type CatalogueItemsByEventLazyQueryHookResult = ReturnType<typeof useCatalogueItemsByEventLazyQuery>;
export type CatalogueItemsByEventQueryResult = ApolloReactCommon.QueryResult<
  CatalogueItemsByEventQuery,
  CatalogueItemsByEventQueryVariables
>;
export const CatalogueSummaryWidgetDocument = gql`
  query catalogueSummaryWidget {
    catalogueSummaryWidget {
      ...catalogueSummaryWidget
    }
  }
  ${CatalogueSummaryWidgetFragmentDoc}
`;
export type CatalogueSummaryWidgetComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CatalogueSummaryWidgetQuery, CatalogueSummaryWidgetQueryVariables>,
  'query'
>;

export const CatalogueSummaryWidgetComponent = (props: CatalogueSummaryWidgetComponentProps) => (
  <ApolloReactComponents.Query<CatalogueSummaryWidgetQuery, CatalogueSummaryWidgetQueryVariables>
    query={CatalogueSummaryWidgetDocument}
    {...props}
  />
);

export type CatalogueSummaryWidgetProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CatalogueSummaryWidgetQuery,
  CatalogueSummaryWidgetQueryVariables
> &
  TChildProps;
export function withCatalogueSummaryWidget<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CatalogueSummaryWidgetQuery,
    CatalogueSummaryWidgetQueryVariables,
    CatalogueSummaryWidgetProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CatalogueSummaryWidgetQuery,
    CatalogueSummaryWidgetQueryVariables,
    CatalogueSummaryWidgetProps<TChildProps>
  >(CatalogueSummaryWidgetDocument, {
    alias: 'catalogueSummaryWidget',
    ...operationOptions,
  });
}

/**
 * __useCatalogueSummaryWidgetQuery__
 *
 * To run a query within a React component, call `useCatalogueSummaryWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogueSummaryWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogueSummaryWidgetQuery({
 *   variables: {
 *   },
 * });
 */
export function useCatalogueSummaryWidgetQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CatalogueSummaryWidgetQuery, CatalogueSummaryWidgetQueryVariables>
) {
  return ApolloReactHooks.useQuery<CatalogueSummaryWidgetQuery, CatalogueSummaryWidgetQueryVariables>(
    CatalogueSummaryWidgetDocument,
    baseOptions
  );
}
export function useCatalogueSummaryWidgetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CatalogueSummaryWidgetQuery, CatalogueSummaryWidgetQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<CatalogueSummaryWidgetQuery, CatalogueSummaryWidgetQueryVariables>(
    CatalogueSummaryWidgetDocument,
    baseOptions
  );
}
export type CatalogueSummaryWidgetQueryHookResult = ReturnType<typeof useCatalogueSummaryWidgetQuery>;
export type CatalogueSummaryWidgetLazyQueryHookResult = ReturnType<typeof useCatalogueSummaryWidgetLazyQuery>;
export type CatalogueSummaryWidgetQueryResult = ApolloReactCommon.QueryResult<
  CatalogueSummaryWidgetQuery,
  CatalogueSummaryWidgetQueryVariables
>;
export const CateringDocument = gql`
  query catering($id: Int!) {
    catering(id: $id) {
      ...catering
    }
  }
  ${CateringFragmentDoc}
`;
export type CateringComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CateringQuery, CateringQueryVariables>,
  'query'
> &
  ({ variables: CateringQueryVariables; skip?: boolean } | { skip: boolean });

export const CateringComponent = (props: CateringComponentProps) => (
  <ApolloReactComponents.Query<CateringQuery, CateringQueryVariables> query={CateringDocument} {...props} />
);

export type CateringProps<TChildProps = {}> = ApolloReactHoc.DataProps<CateringQuery, CateringQueryVariables> &
  TChildProps;
export function withCatering<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CateringQuery,
    CateringQueryVariables,
    CateringProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<TProps, CateringQuery, CateringQueryVariables, CateringProps<TChildProps>>(
    CateringDocument,
    {
      alias: 'catering',
      ...operationOptions,
    }
  );
}

/**
 * __useCateringQuery__
 *
 * To run a query within a React component, call `useCateringQuery` and pass it any options that fit your needs.
 * When your component renders, `useCateringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCateringQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCateringQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CateringQuery, CateringQueryVariables>
) {
  return ApolloReactHooks.useQuery<CateringQuery, CateringQueryVariables>(CateringDocument, baseOptions);
}
export function useCateringLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CateringQuery, CateringQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<CateringQuery, CateringQueryVariables>(CateringDocument, baseOptions);
}
export type CateringQueryHookResult = ReturnType<typeof useCateringQuery>;
export type CateringLazyQueryHookResult = ReturnType<typeof useCateringLazyQuery>;
export type CateringQueryResult = ApolloReactCommon.QueryResult<CateringQuery, CateringQueryVariables>;
export const ClientPublicProfileDocument = gql`
  query clientPublicProfile($name: String, $domain: String) {
    clientPublicProfile(name: $name, domain: $domain) {
      ...clientPublicProfile
    }
  }
  ${ClientPublicProfileFragmentDoc}
`;
export type ClientPublicProfileComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<ClientPublicProfileQuery, ClientPublicProfileQueryVariables>,
  'query'
>;

export const ClientPublicProfileComponent = (props: ClientPublicProfileComponentProps) => (
  <ApolloReactComponents.Query<ClientPublicProfileQuery, ClientPublicProfileQueryVariables>
    query={ClientPublicProfileDocument}
    {...props}
  />
);

export type ClientPublicProfileProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ClientPublicProfileQuery,
  ClientPublicProfileQueryVariables
> &
  TChildProps;
export function withClientPublicProfile<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ClientPublicProfileQuery,
    ClientPublicProfileQueryVariables,
    ClientPublicProfileProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ClientPublicProfileQuery,
    ClientPublicProfileQueryVariables,
    ClientPublicProfileProps<TChildProps>
  >(ClientPublicProfileDocument, {
    alias: 'clientPublicProfile',
    ...operationOptions,
  });
}

/**
 * __useClientPublicProfileQuery__
 *
 * To run a query within a React component, call `useClientPublicProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientPublicProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientPublicProfileQuery({
 *   variables: {
 *      name: // value for 'name'
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useClientPublicProfileQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ClientPublicProfileQuery, ClientPublicProfileQueryVariables>
) {
  return ApolloReactHooks.useQuery<ClientPublicProfileQuery, ClientPublicProfileQueryVariables>(
    ClientPublicProfileDocument,
    baseOptions
  );
}
export function useClientPublicProfileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClientPublicProfileQuery, ClientPublicProfileQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<ClientPublicProfileQuery, ClientPublicProfileQueryVariables>(
    ClientPublicProfileDocument,
    baseOptions
  );
}
export type ClientPublicProfileQueryHookResult = ReturnType<typeof useClientPublicProfileQuery>;
export type ClientPublicProfileLazyQueryHookResult = ReturnType<typeof useClientPublicProfileLazyQuery>;
export type ClientPublicProfileQueryResult = ApolloReactCommon.QueryResult<
  ClientPublicProfileQuery,
  ClientPublicProfileQueryVariables
>;
export const CommerceOrderDocument = gql`
  query commerceOrder($id: Int!) {
    commerceOrder(id: $id) {
      ...commerceOrder
    }
  }
  ${CommerceOrderFragmentDoc}
`;
export type CommerceOrderComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CommerceOrderQuery, CommerceOrderQueryVariables>,
  'query'
> &
  ({ variables: CommerceOrderQueryVariables; skip?: boolean } | { skip: boolean });

export const CommerceOrderComponent = (props: CommerceOrderComponentProps) => (
  <ApolloReactComponents.Query<CommerceOrderQuery, CommerceOrderQueryVariables>
    query={CommerceOrderDocument}
    {...props}
  />
);

export type CommerceOrderProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CommerceOrderQuery,
  CommerceOrderQueryVariables
> &
  TChildProps;
export function withCommerceOrder<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CommerceOrderQuery,
    CommerceOrderQueryVariables,
    CommerceOrderProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CommerceOrderQuery,
    CommerceOrderQueryVariables,
    CommerceOrderProps<TChildProps>
  >(CommerceOrderDocument, {
    alias: 'commerceOrder',
    ...operationOptions,
  });
}

/**
 * __useCommerceOrderQuery__
 *
 * To run a query within a React component, call `useCommerceOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommerceOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommerceOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommerceOrderQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CommerceOrderQuery, CommerceOrderQueryVariables>
) {
  return ApolloReactHooks.useQuery<CommerceOrderQuery, CommerceOrderQueryVariables>(CommerceOrderDocument, baseOptions);
}
export function useCommerceOrderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommerceOrderQuery, CommerceOrderQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<CommerceOrderQuery, CommerceOrderQueryVariables>(
    CommerceOrderDocument,
    baseOptions
  );
}
export type CommerceOrderQueryHookResult = ReturnType<typeof useCommerceOrderQuery>;
export type CommerceOrderLazyQueryHookResult = ReturnType<typeof useCommerceOrderLazyQuery>;
export type CommerceOrderQueryResult = ApolloReactCommon.QueryResult<CommerceOrderQuery, CommerceOrderQueryVariables>;
export const CommerceOrderDataDocument = gql`
  query commerceOrderData($id: Int!) {
    commerceOrderData(id: $id) {
      ...commerceOrderData
    }
  }
  ${CommerceOrderDataFragmentDoc}
`;
export type CommerceOrderDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CommerceOrderDataQuery, CommerceOrderDataQueryVariables>,
  'query'
> &
  ({ variables: CommerceOrderDataQueryVariables; skip?: boolean } | { skip: boolean });

export const CommerceOrderDataComponent = (props: CommerceOrderDataComponentProps) => (
  <ApolloReactComponents.Query<CommerceOrderDataQuery, CommerceOrderDataQueryVariables>
    query={CommerceOrderDataDocument}
    {...props}
  />
);

export type CommerceOrderDataProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CommerceOrderDataQuery,
  CommerceOrderDataQueryVariables
> &
  TChildProps;
export function withCommerceOrderData<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CommerceOrderDataQuery,
    CommerceOrderDataQueryVariables,
    CommerceOrderDataProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CommerceOrderDataQuery,
    CommerceOrderDataQueryVariables,
    CommerceOrderDataProps<TChildProps>
  >(CommerceOrderDataDocument, {
    alias: 'commerceOrderData',
    ...operationOptions,
  });
}

/**
 * __useCommerceOrderDataQuery__
 *
 * To run a query within a React component, call `useCommerceOrderDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommerceOrderDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommerceOrderDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommerceOrderDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CommerceOrderDataQuery, CommerceOrderDataQueryVariables>
) {
  return ApolloReactHooks.useQuery<CommerceOrderDataQuery, CommerceOrderDataQueryVariables>(
    CommerceOrderDataDocument,
    baseOptions
  );
}
export function useCommerceOrderDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommerceOrderDataQuery, CommerceOrderDataQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<CommerceOrderDataQuery, CommerceOrderDataQueryVariables>(
    CommerceOrderDataDocument,
    baseOptions
  );
}
export type CommerceOrderDataQueryHookResult = ReturnType<typeof useCommerceOrderDataQuery>;
export type CommerceOrderDataLazyQueryHookResult = ReturnType<typeof useCommerceOrderDataLazyQuery>;
export type CommerceOrderDataQueryResult = ApolloReactCommon.QueryResult<
  CommerceOrderDataQuery,
  CommerceOrderDataQueryVariables
>;
export const CommerceOrderFieldDocument = gql`
  query commerceOrderField($id: Int!) {
    commerceOrderField(id: $id) {
      ...commerceOrderField
    }
  }
  ${CommerceOrderFieldFragmentDoc}
`;
export type CommerceOrderFieldComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CommerceOrderFieldQuery, CommerceOrderFieldQueryVariables>,
  'query'
> &
  ({ variables: CommerceOrderFieldQueryVariables; skip?: boolean } | { skip: boolean });

export const CommerceOrderFieldComponent = (props: CommerceOrderFieldComponentProps) => (
  <ApolloReactComponents.Query<CommerceOrderFieldQuery, CommerceOrderFieldQueryVariables>
    query={CommerceOrderFieldDocument}
    {...props}
  />
);

export type CommerceOrderFieldProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CommerceOrderFieldQuery,
  CommerceOrderFieldQueryVariables
> &
  TChildProps;
export function withCommerceOrderField<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CommerceOrderFieldQuery,
    CommerceOrderFieldQueryVariables,
    CommerceOrderFieldProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CommerceOrderFieldQuery,
    CommerceOrderFieldQueryVariables,
    CommerceOrderFieldProps<TChildProps>
  >(CommerceOrderFieldDocument, {
    alias: 'commerceOrderField',
    ...operationOptions,
  });
}

/**
 * __useCommerceOrderFieldQuery__
 *
 * To run a query within a React component, call `useCommerceOrderFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommerceOrderFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommerceOrderFieldQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommerceOrderFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CommerceOrderFieldQuery, CommerceOrderFieldQueryVariables>
) {
  return ApolloReactHooks.useQuery<CommerceOrderFieldQuery, CommerceOrderFieldQueryVariables>(
    CommerceOrderFieldDocument,
    baseOptions
  );
}
export function useCommerceOrderFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommerceOrderFieldQuery, CommerceOrderFieldQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<CommerceOrderFieldQuery, CommerceOrderFieldQueryVariables>(
    CommerceOrderFieldDocument,
    baseOptions
  );
}
export type CommerceOrderFieldQueryHookResult = ReturnType<typeof useCommerceOrderFieldQuery>;
export type CommerceOrderFieldLazyQueryHookResult = ReturnType<typeof useCommerceOrderFieldLazyQuery>;
export type CommerceOrderFieldQueryResult = ApolloReactCommon.QueryResult<
  CommerceOrderFieldQuery,
  CommerceOrderFieldQueryVariables
>;
export const CommerceOrderFiltersDocument = gql`
  query commerceOrderFilters($shopId: Int!) {
    commerceOrderFilters(shopId: $shopId) {
      ...filterItem
    }
  }
  ${FilterItemFragmentDoc}
`;
export type CommerceOrderFiltersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CommerceOrderFiltersQuery, CommerceOrderFiltersQueryVariables>,
  'query'
> &
  ({ variables: CommerceOrderFiltersQueryVariables; skip?: boolean } | { skip: boolean });

export const CommerceOrderFiltersComponent = (props: CommerceOrderFiltersComponentProps) => (
  <ApolloReactComponents.Query<CommerceOrderFiltersQuery, CommerceOrderFiltersQueryVariables>
    query={CommerceOrderFiltersDocument}
    {...props}
  />
);

export type CommerceOrderFiltersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CommerceOrderFiltersQuery,
  CommerceOrderFiltersQueryVariables
> &
  TChildProps;
export function withCommerceOrderFilters<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CommerceOrderFiltersQuery,
    CommerceOrderFiltersQueryVariables,
    CommerceOrderFiltersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CommerceOrderFiltersQuery,
    CommerceOrderFiltersQueryVariables,
    CommerceOrderFiltersProps<TChildProps>
  >(CommerceOrderFiltersDocument, {
    alias: 'commerceOrderFilters',
    ...operationOptions,
  });
}

/**
 * __useCommerceOrderFiltersQuery__
 *
 * To run a query within a React component, call `useCommerceOrderFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommerceOrderFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommerceOrderFiltersQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useCommerceOrderFiltersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CommerceOrderFiltersQuery, CommerceOrderFiltersQueryVariables>
) {
  return ApolloReactHooks.useQuery<CommerceOrderFiltersQuery, CommerceOrderFiltersQueryVariables>(
    CommerceOrderFiltersDocument,
    baseOptions
  );
}
export function useCommerceOrderFiltersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommerceOrderFiltersQuery, CommerceOrderFiltersQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<CommerceOrderFiltersQuery, CommerceOrderFiltersQueryVariables>(
    CommerceOrderFiltersDocument,
    baseOptions
  );
}
export type CommerceOrderFiltersQueryHookResult = ReturnType<typeof useCommerceOrderFiltersQuery>;
export type CommerceOrderFiltersLazyQueryHookResult = ReturnType<typeof useCommerceOrderFiltersLazyQuery>;
export type CommerceOrderFiltersQueryResult = ApolloReactCommon.QueryResult<
  CommerceOrderFiltersQuery,
  CommerceOrderFiltersQueryVariables
>;
export const CommerceOrderItemDocument = gql`
  query commerceOrderItem($id: Int!) {
    commerceOrderItem(id: $id) {
      ...commerceOrderItem
    }
  }
  ${CommerceOrderItemFragmentDoc}
`;
export type CommerceOrderItemComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CommerceOrderItemQuery, CommerceOrderItemQueryVariables>,
  'query'
> &
  ({ variables: CommerceOrderItemQueryVariables; skip?: boolean } | { skip: boolean });

export const CommerceOrderItemComponent = (props: CommerceOrderItemComponentProps) => (
  <ApolloReactComponents.Query<CommerceOrderItemQuery, CommerceOrderItemQueryVariables>
    query={CommerceOrderItemDocument}
    {...props}
  />
);

export type CommerceOrderItemProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CommerceOrderItemQuery,
  CommerceOrderItemQueryVariables
> &
  TChildProps;
export function withCommerceOrderItem<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CommerceOrderItemQuery,
    CommerceOrderItemQueryVariables,
    CommerceOrderItemProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CommerceOrderItemQuery,
    CommerceOrderItemQueryVariables,
    CommerceOrderItemProps<TChildProps>
  >(CommerceOrderItemDocument, {
    alias: 'commerceOrderItem',
    ...operationOptions,
  });
}

/**
 * __useCommerceOrderItemQuery__
 *
 * To run a query within a React component, call `useCommerceOrderItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommerceOrderItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommerceOrderItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommerceOrderItemQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CommerceOrderItemQuery, CommerceOrderItemQueryVariables>
) {
  return ApolloReactHooks.useQuery<CommerceOrderItemQuery, CommerceOrderItemQueryVariables>(
    CommerceOrderItemDocument,
    baseOptions
  );
}
export function useCommerceOrderItemLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommerceOrderItemQuery, CommerceOrderItemQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<CommerceOrderItemQuery, CommerceOrderItemQueryVariables>(
    CommerceOrderItemDocument,
    baseOptions
  );
}
export type CommerceOrderItemQueryHookResult = ReturnType<typeof useCommerceOrderItemQuery>;
export type CommerceOrderItemLazyQueryHookResult = ReturnType<typeof useCommerceOrderItemLazyQuery>;
export type CommerceOrderItemQueryResult = ApolloReactCommon.QueryResult<
  CommerceOrderItemQuery,
  CommerceOrderItemQueryVariables
>;
export const CommerceOrderStatesWidgetDocument = gql`
  query commerceOrderStatesWidget($shopId: Int!) {
    commerceOrderStatesWidget(shopId: $shopId) {
      ...commerceOrderStatesWidgetData
    }
  }
  ${CommerceOrderStatesWidgetDataFragmentDoc}
`;
export type CommerceOrderStatesWidgetComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CommerceOrderStatesWidgetQuery, CommerceOrderStatesWidgetQueryVariables>,
  'query'
> &
  ({ variables: CommerceOrderStatesWidgetQueryVariables; skip?: boolean } | { skip: boolean });

export const CommerceOrderStatesWidgetComponent = (props: CommerceOrderStatesWidgetComponentProps) => (
  <ApolloReactComponents.Query<CommerceOrderStatesWidgetQuery, CommerceOrderStatesWidgetQueryVariables>
    query={CommerceOrderStatesWidgetDocument}
    {...props}
  />
);

export type CommerceOrderStatesWidgetProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CommerceOrderStatesWidgetQuery,
  CommerceOrderStatesWidgetQueryVariables
> &
  TChildProps;
export function withCommerceOrderStatesWidget<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CommerceOrderStatesWidgetQuery,
    CommerceOrderStatesWidgetQueryVariables,
    CommerceOrderStatesWidgetProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CommerceOrderStatesWidgetQuery,
    CommerceOrderStatesWidgetQueryVariables,
    CommerceOrderStatesWidgetProps<TChildProps>
  >(CommerceOrderStatesWidgetDocument, {
    alias: 'commerceOrderStatesWidget',
    ...operationOptions,
  });
}

/**
 * __useCommerceOrderStatesWidgetQuery__
 *
 * To run a query within a React component, call `useCommerceOrderStatesWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommerceOrderStatesWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommerceOrderStatesWidgetQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useCommerceOrderStatesWidgetQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CommerceOrderStatesWidgetQuery,
    CommerceOrderStatesWidgetQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<CommerceOrderStatesWidgetQuery, CommerceOrderStatesWidgetQueryVariables>(
    CommerceOrderStatesWidgetDocument,
    baseOptions
  );
}
export function useCommerceOrderStatesWidgetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CommerceOrderStatesWidgetQuery,
    CommerceOrderStatesWidgetQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<CommerceOrderStatesWidgetQuery, CommerceOrderStatesWidgetQueryVariables>(
    CommerceOrderStatesWidgetDocument,
    baseOptions
  );
}
export type CommerceOrderStatesWidgetQueryHookResult = ReturnType<typeof useCommerceOrderStatesWidgetQuery>;
export type CommerceOrderStatesWidgetLazyQueryHookResult = ReturnType<typeof useCommerceOrderStatesWidgetLazyQuery>;
export type CommerceOrderStatesWidgetQueryResult = ApolloReactCommon.QueryResult<
  CommerceOrderStatesWidgetQuery,
  CommerceOrderStatesWidgetQueryVariables
>;
export const CommerceShopDocument = gql`
  query commerceShop($id: Int!) {
    commerceShop(id: $id) {
      ...commerceShop
    }
  }
  ${CommerceShopFragmentDoc}
`;
export type CommerceShopComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CommerceShopQuery, CommerceShopQueryVariables>,
  'query'
> &
  ({ variables: CommerceShopQueryVariables; skip?: boolean } | { skip: boolean });

export const CommerceShopComponent = (props: CommerceShopComponentProps) => (
  <ApolloReactComponents.Query<CommerceShopQuery, CommerceShopQueryVariables> query={CommerceShopDocument} {...props} />
);

export type CommerceShopProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CommerceShopQuery,
  CommerceShopQueryVariables
> &
  TChildProps;
export function withCommerceShop<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CommerceShopQuery,
    CommerceShopQueryVariables,
    CommerceShopProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CommerceShopQuery,
    CommerceShopQueryVariables,
    CommerceShopProps<TChildProps>
  >(CommerceShopDocument, {
    alias: 'commerceShop',
    ...operationOptions,
  });
}

/**
 * __useCommerceShopQuery__
 *
 * To run a query within a React component, call `useCommerceShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommerceShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommerceShopQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommerceShopQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CommerceShopQuery, CommerceShopQueryVariables>
) {
  return ApolloReactHooks.useQuery<CommerceShopQuery, CommerceShopQueryVariables>(CommerceShopDocument, baseOptions);
}
export function useCommerceShopLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommerceShopQuery, CommerceShopQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<CommerceShopQuery, CommerceShopQueryVariables>(
    CommerceShopDocument,
    baseOptions
  );
}
export type CommerceShopQueryHookResult = ReturnType<typeof useCommerceShopQuery>;
export type CommerceShopLazyQueryHookResult = ReturnType<typeof useCommerceShopLazyQuery>;
export type CommerceShopQueryResult = ApolloReactCommon.QueryResult<CommerceShopQuery, CommerceShopQueryVariables>;
export const CommerceSummaryWidgetDocument = gql`
  query commerceSummaryWidget($query: DefaultQueryInput) {
    commerceSummaryWidget(query: $query) {
      ...commerceSummaryWidget
    }
  }
  ${CommerceSummaryWidgetFragmentDoc}
`;
export type CommerceSummaryWidgetComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CommerceSummaryWidgetQuery, CommerceSummaryWidgetQueryVariables>,
  'query'
>;

export const CommerceSummaryWidgetComponent = (props: CommerceSummaryWidgetComponentProps) => (
  <ApolloReactComponents.Query<CommerceSummaryWidgetQuery, CommerceSummaryWidgetQueryVariables>
    query={CommerceSummaryWidgetDocument}
    {...props}
  />
);

export type CommerceSummaryWidgetProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CommerceSummaryWidgetQuery,
  CommerceSummaryWidgetQueryVariables
> &
  TChildProps;
export function withCommerceSummaryWidget<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CommerceSummaryWidgetQuery,
    CommerceSummaryWidgetQueryVariables,
    CommerceSummaryWidgetProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CommerceSummaryWidgetQuery,
    CommerceSummaryWidgetQueryVariables,
    CommerceSummaryWidgetProps<TChildProps>
  >(CommerceSummaryWidgetDocument, {
    alias: 'commerceSummaryWidget',
    ...operationOptions,
  });
}

/**
 * __useCommerceSummaryWidgetQuery__
 *
 * To run a query within a React component, call `useCommerceSummaryWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommerceSummaryWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommerceSummaryWidgetQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useCommerceSummaryWidgetQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CommerceSummaryWidgetQuery, CommerceSummaryWidgetQueryVariables>
) {
  return ApolloReactHooks.useQuery<CommerceSummaryWidgetQuery, CommerceSummaryWidgetQueryVariables>(
    CommerceSummaryWidgetDocument,
    baseOptions
  );
}
export function useCommerceSummaryWidgetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommerceSummaryWidgetQuery, CommerceSummaryWidgetQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<CommerceSummaryWidgetQuery, CommerceSummaryWidgetQueryVariables>(
    CommerceSummaryWidgetDocument,
    baseOptions
  );
}
export type CommerceSummaryWidgetQueryHookResult = ReturnType<typeof useCommerceSummaryWidgetQuery>;
export type CommerceSummaryWidgetLazyQueryHookResult = ReturnType<typeof useCommerceSummaryWidgetLazyQuery>;
export type CommerceSummaryWidgetQueryResult = ApolloReactCommon.QueryResult<
  CommerceSummaryWidgetQuery,
  CommerceSummaryWidgetQueryVariables
>;
export const DisputeDocument = gql`
  query dispute($id: Int!) {
    dispute(id: $id) {
      ...dispute
    }
  }
  ${DisputeFragmentDoc}
`;
export type DisputeComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<DisputeQuery, DisputeQueryVariables>,
  'query'
> &
  ({ variables: DisputeQueryVariables; skip?: boolean } | { skip: boolean });

export const DisputeComponent = (props: DisputeComponentProps) => (
  <ApolloReactComponents.Query<DisputeQuery, DisputeQueryVariables> query={DisputeDocument} {...props} />
);

export type DisputeProps<TChildProps = {}> = ApolloReactHoc.DataProps<DisputeQuery, DisputeQueryVariables> &
  TChildProps;
export function withDispute<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DisputeQuery,
    DisputeQueryVariables,
    DisputeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<TProps, DisputeQuery, DisputeQueryVariables, DisputeProps<TChildProps>>(
    DisputeDocument,
    {
      alias: 'dispute',
      ...operationOptions,
    }
  );
}

/**
 * __useDisputeQuery__
 *
 * To run a query within a React component, call `useDisputeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisputeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisputeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisputeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DisputeQuery, DisputeQueryVariables>) {
  return ApolloReactHooks.useQuery<DisputeQuery, DisputeQueryVariables>(DisputeDocument, baseOptions);
}
export function useDisputeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DisputeQuery, DisputeQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<DisputeQuery, DisputeQueryVariables>(DisputeDocument, baseOptions);
}
export type DisputeQueryHookResult = ReturnType<typeof useDisputeQuery>;
export type DisputeLazyQueryHookResult = ReturnType<typeof useDisputeLazyQuery>;
export type DisputeQueryResult = ApolloReactCommon.QueryResult<DisputeQuery, DisputeQueryVariables>;
export const DisputeDisputeResponseDocument = gql`
  query disputeDisputeResponse($id: Int!) {
    disputeDisputeResponse(id: $id) {
      ...disputeDisputeResponse
    }
  }
  ${DisputeDisputeResponseFragmentDoc}
`;
export type DisputeDisputeResponseComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<DisputeDisputeResponseQuery, DisputeDisputeResponseQueryVariables>,
  'query'
> &
  ({ variables: DisputeDisputeResponseQueryVariables; skip?: boolean } | { skip: boolean });

export const DisputeDisputeResponseComponent = (props: DisputeDisputeResponseComponentProps) => (
  <ApolloReactComponents.Query<DisputeDisputeResponseQuery, DisputeDisputeResponseQueryVariables>
    query={DisputeDisputeResponseDocument}
    {...props}
  />
);

export type DisputeDisputeResponseProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  DisputeDisputeResponseQuery,
  DisputeDisputeResponseQueryVariables
> &
  TChildProps;
export function withDisputeDisputeResponse<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DisputeDisputeResponseQuery,
    DisputeDisputeResponseQueryVariables,
    DisputeDisputeResponseProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    DisputeDisputeResponseQuery,
    DisputeDisputeResponseQueryVariables,
    DisputeDisputeResponseProps<TChildProps>
  >(DisputeDisputeResponseDocument, {
    alias: 'disputeDisputeResponse',
    ...operationOptions,
  });
}

/**
 * __useDisputeDisputeResponseQuery__
 *
 * To run a query within a React component, call `useDisputeDisputeResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisputeDisputeResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisputeDisputeResponseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisputeDisputeResponseQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DisputeDisputeResponseQuery, DisputeDisputeResponseQueryVariables>
) {
  return ApolloReactHooks.useQuery<DisputeDisputeResponseQuery, DisputeDisputeResponseQueryVariables>(
    DisputeDisputeResponseDocument,
    baseOptions
  );
}
export function useDisputeDisputeResponseLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DisputeDisputeResponseQuery, DisputeDisputeResponseQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<DisputeDisputeResponseQuery, DisputeDisputeResponseQueryVariables>(
    DisputeDisputeResponseDocument,
    baseOptions
  );
}
export type DisputeDisputeResponseQueryHookResult = ReturnType<typeof useDisputeDisputeResponseQuery>;
export type DisputeDisputeResponseLazyQueryHookResult = ReturnType<typeof useDisputeDisputeResponseLazyQuery>;
export type DisputeDisputeResponseQueryResult = ApolloReactCommon.QueryResult<
  DisputeDisputeResponseQuery,
  DisputeDisputeResponseQueryVariables
>;
export const DisputeSettingsDocument = gql`
  query disputeSettings {
    disputeSettings {
      ...disputeSettings
    }
  }
  ${DisputeSettingsFragmentDoc}
`;
export type DisputeSettingsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<DisputeSettingsQuery, DisputeSettingsQueryVariables>,
  'query'
>;

export const DisputeSettingsComponent = (props: DisputeSettingsComponentProps) => (
  <ApolloReactComponents.Query<DisputeSettingsQuery, DisputeSettingsQueryVariables>
    query={DisputeSettingsDocument}
    {...props}
  />
);

export type DisputeSettingsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  DisputeSettingsQuery,
  DisputeSettingsQueryVariables
> &
  TChildProps;
export function withDisputeSettings<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DisputeSettingsQuery,
    DisputeSettingsQueryVariables,
    DisputeSettingsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    DisputeSettingsQuery,
    DisputeSettingsQueryVariables,
    DisputeSettingsProps<TChildProps>
  >(DisputeSettingsDocument, {
    alias: 'disputeSettings',
    ...operationOptions,
  });
}

/**
 * __useDisputeSettingsQuery__
 *
 * To run a query within a React component, call `useDisputeSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisputeSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisputeSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDisputeSettingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DisputeSettingsQuery, DisputeSettingsQueryVariables>
) {
  return ApolloReactHooks.useQuery<DisputeSettingsQuery, DisputeSettingsQueryVariables>(
    DisputeSettingsDocument,
    baseOptions
  );
}
export function useDisputeSettingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DisputeSettingsQuery, DisputeSettingsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<DisputeSettingsQuery, DisputeSettingsQueryVariables>(
    DisputeSettingsDocument,
    baseOptions
  );
}
export type DisputeSettingsQueryHookResult = ReturnType<typeof useDisputeSettingsQuery>;
export type DisputeSettingsLazyQueryHookResult = ReturnType<typeof useDisputeSettingsLazyQuery>;
export type DisputeSettingsQueryResult = ApolloReactCommon.QueryResult<
  DisputeSettingsQuery,
  DisputeSettingsQueryVariables
>;
export const EventDocument = gql`
  query event($id: Int!) {
    event(id: $id) {
      ...eventFull
    }
  }
  ${EventFullFragmentDoc}
`;
export type EventComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<EventQuery, EventQueryVariables>,
  'query'
> &
  ({ variables: EventQueryVariables; skip?: boolean } | { skip: boolean });

export const EventComponent = (props: EventComponentProps) => (
  <ApolloReactComponents.Query<EventQuery, EventQueryVariables> query={EventDocument} {...props} />
);

export type EventProps<TChildProps = {}> = ApolloReactHoc.DataProps<EventQuery, EventQueryVariables> & TChildProps;
export function withEvent<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<TProps, EventQuery, EventQueryVariables, EventProps<TChildProps>>
) {
  return ApolloReactHoc.withQuery<TProps, EventQuery, EventQueryVariables, EventProps<TChildProps>>(EventDocument, {
    alias: 'event',
    ...operationOptions,
  });
}

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventQuery, EventQueryVariables>) {
  return ApolloReactHooks.useQuery<EventQuery, EventQueryVariables>(EventDocument, baseOptions);
}
export function useEventLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventQuery, EventQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<EventQuery, EventQueryVariables>(EventDocument, baseOptions);
}
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventQueryResult = ApolloReactCommon.QueryResult<EventQuery, EventQueryVariables>;
export const EventEventAccessDocument = gql`
  query eventEventAccess($id: Int!) {
    eventEventAccess(id: $id) {
      ...eventEventAccess
    }
  }
  ${EventEventAccessFragmentDoc}
`;
export type EventEventAccessComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<EventEventAccessQuery, EventEventAccessQueryVariables>,
  'query'
> &
  ({ variables: EventEventAccessQueryVariables; skip?: boolean } | { skip: boolean });

export const EventEventAccessComponent = (props: EventEventAccessComponentProps) => (
  <ApolloReactComponents.Query<EventEventAccessQuery, EventEventAccessQueryVariables>
    query={EventEventAccessDocument}
    {...props}
  />
);

export type EventEventAccessProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  EventEventAccessQuery,
  EventEventAccessQueryVariables
> &
  TChildProps;
export function withEventEventAccess<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    EventEventAccessQuery,
    EventEventAccessQueryVariables,
    EventEventAccessProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    EventEventAccessQuery,
    EventEventAccessQueryVariables,
    EventEventAccessProps<TChildProps>
  >(EventEventAccessDocument, {
    alias: 'eventEventAccess',
    ...operationOptions,
  });
}

/**
 * __useEventEventAccessQuery__
 *
 * To run a query within a React component, call `useEventEventAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventEventAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventEventAccessQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventEventAccessQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<EventEventAccessQuery, EventEventAccessQueryVariables>
) {
  return ApolloReactHooks.useQuery<EventEventAccessQuery, EventEventAccessQueryVariables>(
    EventEventAccessDocument,
    baseOptions
  );
}
export function useEventEventAccessLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventEventAccessQuery, EventEventAccessQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<EventEventAccessQuery, EventEventAccessQueryVariables>(
    EventEventAccessDocument,
    baseOptions
  );
}
export type EventEventAccessQueryHookResult = ReturnType<typeof useEventEventAccessQuery>;
export type EventEventAccessLazyQueryHookResult = ReturnType<typeof useEventEventAccessLazyQuery>;
export type EventEventAccessQueryResult = ApolloReactCommon.QueryResult<
  EventEventAccessQuery,
  EventEventAccessQueryVariables
>;
export const EventOrderDocument = gql`
  query eventOrder($id: Int!) {
    eventOrder(id: $id) {
      ...eventOrder
    }
  }
  ${EventOrderFragmentDoc}
`;
export type EventOrderComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<EventOrderQuery, EventOrderQueryVariables>,
  'query'
> &
  ({ variables: EventOrderQueryVariables; skip?: boolean } | { skip: boolean });

export const EventOrderComponent = (props: EventOrderComponentProps) => (
  <ApolloReactComponents.Query<EventOrderQuery, EventOrderQueryVariables> query={EventOrderDocument} {...props} />
);

export type EventOrderProps<TChildProps = {}> = ApolloReactHoc.DataProps<EventOrderQuery, EventOrderQueryVariables> &
  TChildProps;
export function withEventOrder<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    EventOrderQuery,
    EventOrderQueryVariables,
    EventOrderProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<TProps, EventOrderQuery, EventOrderQueryVariables, EventOrderProps<TChildProps>>(
    EventOrderDocument,
    {
      alias: 'eventOrder',
      ...operationOptions,
    }
  );
}

/**
 * __useEventOrderQuery__
 *
 * To run a query within a React component, call `useEventOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventOrderQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<EventOrderQuery, EventOrderQueryVariables>
) {
  return ApolloReactHooks.useQuery<EventOrderQuery, EventOrderQueryVariables>(EventOrderDocument, baseOptions);
}
export function useEventOrderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventOrderQuery, EventOrderQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<EventOrderQuery, EventOrderQueryVariables>(EventOrderDocument, baseOptions);
}
export type EventOrderQueryHookResult = ReturnType<typeof useEventOrderQuery>;
export type EventOrderLazyQueryHookResult = ReturnType<typeof useEventOrderLazyQuery>;
export type EventOrderQueryResult = ApolloReactCommon.QueryResult<EventOrderQuery, EventOrderQueryVariables>;
export const EventOrderTicketDocument = gql`
  query eventOrderTicket($id: Int!) {
    eventOrderTicket(id: $id) {
      ...eventOrderTicketFull
    }
  }
  ${EventOrderTicketFullFragmentDoc}
`;
export type EventOrderTicketComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<EventOrderTicketQuery, EventOrderTicketQueryVariables>,
  'query'
> &
  ({ variables: EventOrderTicketQueryVariables; skip?: boolean } | { skip: boolean });

export const EventOrderTicketComponent = (props: EventOrderTicketComponentProps) => (
  <ApolloReactComponents.Query<EventOrderTicketQuery, EventOrderTicketQueryVariables>
    query={EventOrderTicketDocument}
    {...props}
  />
);

export type EventOrderTicketProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  EventOrderTicketQuery,
  EventOrderTicketQueryVariables
> &
  TChildProps;
export function withEventOrderTicket<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    EventOrderTicketQuery,
    EventOrderTicketQueryVariables,
    EventOrderTicketProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    EventOrderTicketQuery,
    EventOrderTicketQueryVariables,
    EventOrderTicketProps<TChildProps>
  >(EventOrderTicketDocument, {
    alias: 'eventOrderTicket',
    ...operationOptions,
  });
}

/**
 * __useEventOrderTicketQuery__
 *
 * To run a query within a React component, call `useEventOrderTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventOrderTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventOrderTicketQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventOrderTicketQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<EventOrderTicketQuery, EventOrderTicketQueryVariables>
) {
  return ApolloReactHooks.useQuery<EventOrderTicketQuery, EventOrderTicketQueryVariables>(
    EventOrderTicketDocument,
    baseOptions
  );
}
export function useEventOrderTicketLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventOrderTicketQuery, EventOrderTicketQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<EventOrderTicketQuery, EventOrderTicketQueryVariables>(
    EventOrderTicketDocument,
    baseOptions
  );
}
export type EventOrderTicketQueryHookResult = ReturnType<typeof useEventOrderTicketQuery>;
export type EventOrderTicketLazyQueryHookResult = ReturnType<typeof useEventOrderTicketLazyQuery>;
export type EventOrderTicketQueryResult = ApolloReactCommon.QueryResult<
  EventOrderTicketQuery,
  EventOrderTicketQueryVariables
>;
export const EventPlaceDocument = gql`
  query eventPlace($id: Int!) {
    eventPlace(id: $id) {
      ...eventPlace
    }
  }
  ${EventPlaceFragmentDoc}
`;
export type EventPlaceComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<EventPlaceQuery, EventPlaceQueryVariables>,
  'query'
> &
  ({ variables: EventPlaceQueryVariables; skip?: boolean } | { skip: boolean });

export const EventPlaceComponent = (props: EventPlaceComponentProps) => (
  <ApolloReactComponents.Query<EventPlaceQuery, EventPlaceQueryVariables> query={EventPlaceDocument} {...props} />
);

export type EventPlaceProps<TChildProps = {}> = ApolloReactHoc.DataProps<EventPlaceQuery, EventPlaceQueryVariables> &
  TChildProps;
export function withEventPlace<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    EventPlaceQuery,
    EventPlaceQueryVariables,
    EventPlaceProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<TProps, EventPlaceQuery, EventPlaceQueryVariables, EventPlaceProps<TChildProps>>(
    EventPlaceDocument,
    {
      alias: 'eventPlace',
      ...operationOptions,
    }
  );
}

/**
 * __useEventPlaceQuery__
 *
 * To run a query within a React component, call `useEventPlaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventPlaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventPlaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventPlaceQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<EventPlaceQuery, EventPlaceQueryVariables>
) {
  return ApolloReactHooks.useQuery<EventPlaceQuery, EventPlaceQueryVariables>(EventPlaceDocument, baseOptions);
}
export function useEventPlaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventPlaceQuery, EventPlaceQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<EventPlaceQuery, EventPlaceQueryVariables>(EventPlaceDocument, baseOptions);
}
export type EventPlaceQueryHookResult = ReturnType<typeof useEventPlaceQuery>;
export type EventPlaceLazyQueryHookResult = ReturnType<typeof useEventPlaceLazyQuery>;
export type EventPlaceQueryResult = ApolloReactCommon.QueryResult<EventPlaceQuery, EventPlaceQueryVariables>;
export const EventWidgetActiveEventsDocument = gql`
  query eventWidgetActiveEvents {
    eventWidgetActiveEvents {
      ...eventWidgetActiveEvents
    }
  }
  ${EventWidgetActiveEventsFragmentDoc}
`;
export type EventWidgetActiveEventsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<EventWidgetActiveEventsQuery, EventWidgetActiveEventsQueryVariables>,
  'query'
>;

export const EventWidgetActiveEventsComponent = (props: EventWidgetActiveEventsComponentProps) => (
  <ApolloReactComponents.Query<EventWidgetActiveEventsQuery, EventWidgetActiveEventsQueryVariables>
    query={EventWidgetActiveEventsDocument}
    {...props}
  />
);

export type EventWidgetActiveEventsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  EventWidgetActiveEventsQuery,
  EventWidgetActiveEventsQueryVariables
> &
  TChildProps;
export function withEventWidgetActiveEvents<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    EventWidgetActiveEventsQuery,
    EventWidgetActiveEventsQueryVariables,
    EventWidgetActiveEventsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    EventWidgetActiveEventsQuery,
    EventWidgetActiveEventsQueryVariables,
    EventWidgetActiveEventsProps<TChildProps>
  >(EventWidgetActiveEventsDocument, {
    alias: 'eventWidgetActiveEvents',
    ...operationOptions,
  });
}

/**
 * __useEventWidgetActiveEventsQuery__
 *
 * To run a query within a React component, call `useEventWidgetActiveEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventWidgetActiveEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventWidgetActiveEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventWidgetActiveEventsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<EventWidgetActiveEventsQuery, EventWidgetActiveEventsQueryVariables>
) {
  return ApolloReactHooks.useQuery<EventWidgetActiveEventsQuery, EventWidgetActiveEventsQueryVariables>(
    EventWidgetActiveEventsDocument,
    baseOptions
  );
}
export function useEventWidgetActiveEventsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EventWidgetActiveEventsQuery,
    EventWidgetActiveEventsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<EventWidgetActiveEventsQuery, EventWidgetActiveEventsQueryVariables>(
    EventWidgetActiveEventsDocument,
    baseOptions
  );
}
export type EventWidgetActiveEventsQueryHookResult = ReturnType<typeof useEventWidgetActiveEventsQuery>;
export type EventWidgetActiveEventsLazyQueryHookResult = ReturnType<typeof useEventWidgetActiveEventsLazyQuery>;
export type EventWidgetActiveEventsQueryResult = ApolloReactCommon.QueryResult<
  EventWidgetActiveEventsQuery,
  EventWidgetActiveEventsQueryVariables
>;
export const EventWidgetEventTypesSalesDocument = gql`
  query eventWidgetEventTypesSales($placeId: Int!, $dates: [String!]) {
    eventWidgetEventTypesSales(placeId: $placeId, dates: $dates) {
      ticketTotalSale
      graphs {
        ...widgetSummaryGraph
      }
    }
  }
  ${WidgetSummaryGraphFragmentDoc}
`;
export type EventWidgetEventTypesSalesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    EventWidgetEventTypesSalesQuery,
    EventWidgetEventTypesSalesQueryVariables
  >,
  'query'
> &
  ({ variables: EventWidgetEventTypesSalesQueryVariables; skip?: boolean } | { skip: boolean });

export const EventWidgetEventTypesSalesComponent = (props: EventWidgetEventTypesSalesComponentProps) => (
  <ApolloReactComponents.Query<EventWidgetEventTypesSalesQuery, EventWidgetEventTypesSalesQueryVariables>
    query={EventWidgetEventTypesSalesDocument}
    {...props}
  />
);

export type EventWidgetEventTypesSalesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  EventWidgetEventTypesSalesQuery,
  EventWidgetEventTypesSalesQueryVariables
> &
  TChildProps;
export function withEventWidgetEventTypesSales<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    EventWidgetEventTypesSalesQuery,
    EventWidgetEventTypesSalesQueryVariables,
    EventWidgetEventTypesSalesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    EventWidgetEventTypesSalesQuery,
    EventWidgetEventTypesSalesQueryVariables,
    EventWidgetEventTypesSalesProps<TChildProps>
  >(EventWidgetEventTypesSalesDocument, {
    alias: 'eventWidgetEventTypesSales',
    ...operationOptions,
  });
}

/**
 * __useEventWidgetEventTypesSalesQuery__
 *
 * To run a query within a React component, call `useEventWidgetEventTypesSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventWidgetEventTypesSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventWidgetEventTypesSalesQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *      dates: // value for 'dates'
 *   },
 * });
 */
export function useEventWidgetEventTypesSalesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    EventWidgetEventTypesSalesQuery,
    EventWidgetEventTypesSalesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<EventWidgetEventTypesSalesQuery, EventWidgetEventTypesSalesQueryVariables>(
    EventWidgetEventTypesSalesDocument,
    baseOptions
  );
}
export function useEventWidgetEventTypesSalesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EventWidgetEventTypesSalesQuery,
    EventWidgetEventTypesSalesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<EventWidgetEventTypesSalesQuery, EventWidgetEventTypesSalesQueryVariables>(
    EventWidgetEventTypesSalesDocument,
    baseOptions
  );
}
export type EventWidgetEventTypesSalesQueryHookResult = ReturnType<typeof useEventWidgetEventTypesSalesQuery>;
export type EventWidgetEventTypesSalesLazyQueryHookResult = ReturnType<typeof useEventWidgetEventTypesSalesLazyQuery>;
export type EventWidgetEventTypesSalesQueryResult = ApolloReactCommon.QueryResult<
  EventWidgetEventTypesSalesQuery,
  EventWidgetEventTypesSalesQueryVariables
>;
export const EventWidgetNearestMatchesDocument = gql`
  query eventWidgetNearestMatches($placeId: Int!) {
    eventWidgetNearestMatches(placeId: $placeId) {
      ...eventWidgetNearestMatch
    }
  }
  ${EventWidgetNearestMatchFragmentDoc}
`;
export type EventWidgetNearestMatchesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<EventWidgetNearestMatchesQuery, EventWidgetNearestMatchesQueryVariables>,
  'query'
> &
  ({ variables: EventWidgetNearestMatchesQueryVariables; skip?: boolean } | { skip: boolean });

export const EventWidgetNearestMatchesComponent = (props: EventWidgetNearestMatchesComponentProps) => (
  <ApolloReactComponents.Query<EventWidgetNearestMatchesQuery, EventWidgetNearestMatchesQueryVariables>
    query={EventWidgetNearestMatchesDocument}
    {...props}
  />
);

export type EventWidgetNearestMatchesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  EventWidgetNearestMatchesQuery,
  EventWidgetNearestMatchesQueryVariables
> &
  TChildProps;
export function withEventWidgetNearestMatches<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    EventWidgetNearestMatchesQuery,
    EventWidgetNearestMatchesQueryVariables,
    EventWidgetNearestMatchesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    EventWidgetNearestMatchesQuery,
    EventWidgetNearestMatchesQueryVariables,
    EventWidgetNearestMatchesProps<TChildProps>
  >(EventWidgetNearestMatchesDocument, {
    alias: 'eventWidgetNearestMatches',
    ...operationOptions,
  });
}

/**
 * __useEventWidgetNearestMatchesQuery__
 *
 * To run a query within a React component, call `useEventWidgetNearestMatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventWidgetNearestMatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventWidgetNearestMatchesQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useEventWidgetNearestMatchesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    EventWidgetNearestMatchesQuery,
    EventWidgetNearestMatchesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<EventWidgetNearestMatchesQuery, EventWidgetNearestMatchesQueryVariables>(
    EventWidgetNearestMatchesDocument,
    baseOptions
  );
}
export function useEventWidgetNearestMatchesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EventWidgetNearestMatchesQuery,
    EventWidgetNearestMatchesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<EventWidgetNearestMatchesQuery, EventWidgetNearestMatchesQueryVariables>(
    EventWidgetNearestMatchesDocument,
    baseOptions
  );
}
export type EventWidgetNearestMatchesQueryHookResult = ReturnType<typeof useEventWidgetNearestMatchesQuery>;
export type EventWidgetNearestMatchesLazyQueryHookResult = ReturnType<typeof useEventWidgetNearestMatchesLazyQuery>;
export type EventWidgetNearestMatchesQueryResult = ApolloReactCommon.QueryResult<
  EventWidgetNearestMatchesQuery,
  EventWidgetNearestMatchesQueryVariables
>;
export const EventWidgetNextEventDocument = gql`
  query eventWidgetNextEvent {
    eventWidgetNextEvent {
      ...eventWidget
    }
  }
  ${EventWidgetFragmentDoc}
`;
export type EventWidgetNextEventComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<EventWidgetNextEventQuery, EventWidgetNextEventQueryVariables>,
  'query'
>;

export const EventWidgetNextEventComponent = (props: EventWidgetNextEventComponentProps) => (
  <ApolloReactComponents.Query<EventWidgetNextEventQuery, EventWidgetNextEventQueryVariables>
    query={EventWidgetNextEventDocument}
    {...props}
  />
);

export type EventWidgetNextEventProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  EventWidgetNextEventQuery,
  EventWidgetNextEventQueryVariables
> &
  TChildProps;
export function withEventWidgetNextEvent<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    EventWidgetNextEventQuery,
    EventWidgetNextEventQueryVariables,
    EventWidgetNextEventProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    EventWidgetNextEventQuery,
    EventWidgetNextEventQueryVariables,
    EventWidgetNextEventProps<TChildProps>
  >(EventWidgetNextEventDocument, {
    alias: 'eventWidgetNextEvent',
    ...operationOptions,
  });
}

/**
 * __useEventWidgetNextEventQuery__
 *
 * To run a query within a React component, call `useEventWidgetNextEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventWidgetNextEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventWidgetNextEventQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventWidgetNextEventQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<EventWidgetNextEventQuery, EventWidgetNextEventQueryVariables>
) {
  return ApolloReactHooks.useQuery<EventWidgetNextEventQuery, EventWidgetNextEventQueryVariables>(
    EventWidgetNextEventDocument,
    baseOptions
  );
}
export function useEventWidgetNextEventLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventWidgetNextEventQuery, EventWidgetNextEventQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<EventWidgetNextEventQuery, EventWidgetNextEventQueryVariables>(
    EventWidgetNextEventDocument,
    baseOptions
  );
}
export type EventWidgetNextEventQueryHookResult = ReturnType<typeof useEventWidgetNextEventQuery>;
export type EventWidgetNextEventLazyQueryHookResult = ReturnType<typeof useEventWidgetNextEventLazyQuery>;
export type EventWidgetNextEventQueryResult = ApolloReactCommon.QueryResult<
  EventWidgetNextEventQuery,
  EventWidgetNextEventQueryVariables
>;
export const EventWidgetSalesDocument = gql`
  query eventWidgetSales($placeId: Int) {
    eventWidgetSales(placeId: $placeId) {
      ...eventWidgetSales
    }
  }
  ${EventWidgetSalesFragmentDoc}
`;
export type EventWidgetSalesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<EventWidgetSalesQuery, EventWidgetSalesQueryVariables>,
  'query'
>;

export const EventWidgetSalesComponent = (props: EventWidgetSalesComponentProps) => (
  <ApolloReactComponents.Query<EventWidgetSalesQuery, EventWidgetSalesQueryVariables>
    query={EventWidgetSalesDocument}
    {...props}
  />
);

export type EventWidgetSalesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  EventWidgetSalesQuery,
  EventWidgetSalesQueryVariables
> &
  TChildProps;
export function withEventWidgetSales<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    EventWidgetSalesQuery,
    EventWidgetSalesQueryVariables,
    EventWidgetSalesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    EventWidgetSalesQuery,
    EventWidgetSalesQueryVariables,
    EventWidgetSalesProps<TChildProps>
  >(EventWidgetSalesDocument, {
    alias: 'eventWidgetSales',
    ...operationOptions,
  });
}

/**
 * __useEventWidgetSalesQuery__
 *
 * To run a query within a React component, call `useEventWidgetSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventWidgetSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventWidgetSalesQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useEventWidgetSalesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<EventWidgetSalesQuery, EventWidgetSalesQueryVariables>
) {
  return ApolloReactHooks.useQuery<EventWidgetSalesQuery, EventWidgetSalesQueryVariables>(
    EventWidgetSalesDocument,
    baseOptions
  );
}
export function useEventWidgetSalesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventWidgetSalesQuery, EventWidgetSalesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<EventWidgetSalesQuery, EventWidgetSalesQueryVariables>(
    EventWidgetSalesDocument,
    baseOptions
  );
}
export type EventWidgetSalesQueryHookResult = ReturnType<typeof useEventWidgetSalesQuery>;
export type EventWidgetSalesLazyQueryHookResult = ReturnType<typeof useEventWidgetSalesLazyQuery>;
export type EventWidgetSalesQueryResult = ApolloReactCommon.QueryResult<
  EventWidgetSalesQuery,
  EventWidgetSalesQueryVariables
>;
export const EventWidgetTicketSalesDocument = gql`
  query eventWidgetTicketSales($query: EventWidgetTicketSalesInput) {
    eventWidgetTicketSales(query: $query) {
      summary {
        ...eventWidgetTicketSalesTotals
      }
      graphs {
        ...commonGraph
      }
    }
  }
  ${EventWidgetTicketSalesTotalsFragmentDoc}
  ${CommonGraphFragmentDoc}
`;
export type EventWidgetTicketSalesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<EventWidgetTicketSalesQuery, EventWidgetTicketSalesQueryVariables>,
  'query'
>;

export const EventWidgetTicketSalesComponent = (props: EventWidgetTicketSalesComponentProps) => (
  <ApolloReactComponents.Query<EventWidgetTicketSalesQuery, EventWidgetTicketSalesQueryVariables>
    query={EventWidgetTicketSalesDocument}
    {...props}
  />
);

export type EventWidgetTicketSalesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  EventWidgetTicketSalesQuery,
  EventWidgetTicketSalesQueryVariables
> &
  TChildProps;
export function withEventWidgetTicketSales<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    EventWidgetTicketSalesQuery,
    EventWidgetTicketSalesQueryVariables,
    EventWidgetTicketSalesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    EventWidgetTicketSalesQuery,
    EventWidgetTicketSalesQueryVariables,
    EventWidgetTicketSalesProps<TChildProps>
  >(EventWidgetTicketSalesDocument, {
    alias: 'eventWidgetTicketSales',
    ...operationOptions,
  });
}

/**
 * __useEventWidgetTicketSalesQuery__
 *
 * To run a query within a React component, call `useEventWidgetTicketSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventWidgetTicketSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventWidgetTicketSalesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useEventWidgetTicketSalesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<EventWidgetTicketSalesQuery, EventWidgetTicketSalesQueryVariables>
) {
  return ApolloReactHooks.useQuery<EventWidgetTicketSalesQuery, EventWidgetTicketSalesQueryVariables>(
    EventWidgetTicketSalesDocument,
    baseOptions
  );
}
export function useEventWidgetTicketSalesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventWidgetTicketSalesQuery, EventWidgetTicketSalesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<EventWidgetTicketSalesQuery, EventWidgetTicketSalesQueryVariables>(
    EventWidgetTicketSalesDocument,
    baseOptions
  );
}
export type EventWidgetTicketSalesQueryHookResult = ReturnType<typeof useEventWidgetTicketSalesQuery>;
export type EventWidgetTicketSalesLazyQueryHookResult = ReturnType<typeof useEventWidgetTicketSalesLazyQuery>;
export type EventWidgetTicketSalesQueryResult = ApolloReactCommon.QueryResult<
  EventWidgetTicketSalesQuery,
  EventWidgetTicketSalesQueryVariables
>;
export const FiltersDocument = gql`
  query filters($queryName: String!) {
    filters(queryName: $queryName) {
      ...filterItem
    }
  }
  ${FilterItemFragmentDoc}
`;
export type FiltersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<FiltersQuery, FiltersQueryVariables>,
  'query'
> &
  ({ variables: FiltersQueryVariables; skip?: boolean } | { skip: boolean });

export const FiltersComponent = (props: FiltersComponentProps) => (
  <ApolloReactComponents.Query<FiltersQuery, FiltersQueryVariables> query={FiltersDocument} {...props} />
);

export type FiltersProps<TChildProps = {}> = ApolloReactHoc.DataProps<FiltersQuery, FiltersQueryVariables> &
  TChildProps;
export function withFilters<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FiltersQuery,
    FiltersQueryVariables,
    FiltersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<TProps, FiltersQuery, FiltersQueryVariables, FiltersProps<TChildProps>>(
    FiltersDocument,
    {
      alias: 'filters',
      ...operationOptions,
    }
  );
}

/**
 * __useFiltersQuery__
 *
 * To run a query within a React component, call `useFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFiltersQuery({
 *   variables: {
 *      queryName: // value for 'queryName'
 *   },
 * });
 */
export function useFiltersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FiltersQuery, FiltersQueryVariables>) {
  return ApolloReactHooks.useQuery<FiltersQuery, FiltersQueryVariables>(FiltersDocument, baseOptions);
}
export function useFiltersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FiltersQuery, FiltersQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<FiltersQuery, FiltersQueryVariables>(FiltersDocument, baseOptions);
}
export type FiltersQueryHookResult = ReturnType<typeof useFiltersQuery>;
export type FiltersLazyQueryHookResult = ReturnType<typeof useFiltersLazyQuery>;
export type FiltersQueryResult = ApolloReactCommon.QueryResult<FiltersQuery, FiltersQueryVariables>;
export const LoyaltyPartnerDocument = gql`
  query loyaltyPartner($id: Int!) {
    loyaltyPartner(id: $id) {
      ...loyaltyPartner
    }
  }
  ${LoyaltyPartnerFragmentDoc}
`;
export type LoyaltyPartnerComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<LoyaltyPartnerQuery, LoyaltyPartnerQueryVariables>,
  'query'
> &
  ({ variables: LoyaltyPartnerQueryVariables; skip?: boolean } | { skip: boolean });

export const LoyaltyPartnerComponent = (props: LoyaltyPartnerComponentProps) => (
  <ApolloReactComponents.Query<LoyaltyPartnerQuery, LoyaltyPartnerQueryVariables>
    query={LoyaltyPartnerDocument}
    {...props}
  />
);

export type LoyaltyPartnerProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  LoyaltyPartnerQuery,
  LoyaltyPartnerQueryVariables
> &
  TChildProps;
export function withLoyaltyPartner<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    LoyaltyPartnerQuery,
    LoyaltyPartnerQueryVariables,
    LoyaltyPartnerProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    LoyaltyPartnerQuery,
    LoyaltyPartnerQueryVariables,
    LoyaltyPartnerProps<TChildProps>
  >(LoyaltyPartnerDocument, {
    alias: 'loyaltyPartner',
    ...operationOptions,
  });
}

/**
 * __useLoyaltyPartnerQuery__
 *
 * To run a query within a React component, call `useLoyaltyPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyPartnerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoyaltyPartnerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LoyaltyPartnerQuery, LoyaltyPartnerQueryVariables>
) {
  return ApolloReactHooks.useQuery<LoyaltyPartnerQuery, LoyaltyPartnerQueryVariables>(
    LoyaltyPartnerDocument,
    baseOptions
  );
}
export function useLoyaltyPartnerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoyaltyPartnerQuery, LoyaltyPartnerQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<LoyaltyPartnerQuery, LoyaltyPartnerQueryVariables>(
    LoyaltyPartnerDocument,
    baseOptions
  );
}
export type LoyaltyPartnerQueryHookResult = ReturnType<typeof useLoyaltyPartnerQuery>;
export type LoyaltyPartnerLazyQueryHookResult = ReturnType<typeof useLoyaltyPartnerLazyQuery>;
export type LoyaltyPartnerQueryResult = ApolloReactCommon.QueryResult<
  LoyaltyPartnerQuery,
  LoyaltyPartnerQueryVariables
>;
export const LoyaltyPartnerEntityByProviderCodeDocument = gql`
  query loyaltyPartnerEntityByProviderCode($providerId: Int, $providerCode: String) {
    loyaltyPartnerEntityByProviderCode(providerId: $providerId, providerCode: $providerCode)
  }
`;
export type LoyaltyPartnerEntityByProviderCodeComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    LoyaltyPartnerEntityByProviderCodeQuery,
    LoyaltyPartnerEntityByProviderCodeQueryVariables
  >,
  'query'
>;

export const LoyaltyPartnerEntityByProviderCodeComponent = (
  props: LoyaltyPartnerEntityByProviderCodeComponentProps
) => (
  <ApolloReactComponents.Query<
    LoyaltyPartnerEntityByProviderCodeQuery,
    LoyaltyPartnerEntityByProviderCodeQueryVariables
  >
    query={LoyaltyPartnerEntityByProviderCodeDocument}
    {...props}
  />
);

export type LoyaltyPartnerEntityByProviderCodeProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  LoyaltyPartnerEntityByProviderCodeQuery,
  LoyaltyPartnerEntityByProviderCodeQueryVariables
> &
  TChildProps;
export function withLoyaltyPartnerEntityByProviderCode<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    LoyaltyPartnerEntityByProviderCodeQuery,
    LoyaltyPartnerEntityByProviderCodeQueryVariables,
    LoyaltyPartnerEntityByProviderCodeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    LoyaltyPartnerEntityByProviderCodeQuery,
    LoyaltyPartnerEntityByProviderCodeQueryVariables,
    LoyaltyPartnerEntityByProviderCodeProps<TChildProps>
  >(LoyaltyPartnerEntityByProviderCodeDocument, {
    alias: 'loyaltyPartnerEntityByProviderCode',
    ...operationOptions,
  });
}

/**
 * __useLoyaltyPartnerEntityByProviderCodeQuery__
 *
 * To run a query within a React component, call `useLoyaltyPartnerEntityByProviderCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyPartnerEntityByProviderCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyPartnerEntityByProviderCodeQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      providerCode: // value for 'providerCode'
 *   },
 * });
 */
export function useLoyaltyPartnerEntityByProviderCodeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LoyaltyPartnerEntityByProviderCodeQuery,
    LoyaltyPartnerEntityByProviderCodeQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    LoyaltyPartnerEntityByProviderCodeQuery,
    LoyaltyPartnerEntityByProviderCodeQueryVariables
  >(LoyaltyPartnerEntityByProviderCodeDocument, baseOptions);
}
export function useLoyaltyPartnerEntityByProviderCodeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LoyaltyPartnerEntityByProviderCodeQuery,
    LoyaltyPartnerEntityByProviderCodeQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    LoyaltyPartnerEntityByProviderCodeQuery,
    LoyaltyPartnerEntityByProviderCodeQueryVariables
  >(LoyaltyPartnerEntityByProviderCodeDocument, baseOptions);
}
export type LoyaltyPartnerEntityByProviderCodeQueryHookResult = ReturnType<
  typeof useLoyaltyPartnerEntityByProviderCodeQuery
>;
export type LoyaltyPartnerEntityByProviderCodeLazyQueryHookResult = ReturnType<
  typeof useLoyaltyPartnerEntityByProviderCodeLazyQuery
>;
export type LoyaltyPartnerEntityByProviderCodeQueryResult = ApolloReactCommon.QueryResult<
  LoyaltyPartnerEntityByProviderCodeQuery,
  LoyaltyPartnerEntityByProviderCodeQueryVariables
>;
export const LoyaltyPromoDocument = gql`
  query loyaltyPromo($id: Int!) {
    loyaltyPromo(id: $id) {
      ...extendedLoyaltyPromo
    }
  }
  ${ExtendedLoyaltyPromoFragmentDoc}
`;
export type LoyaltyPromoComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<LoyaltyPromoQuery, LoyaltyPromoQueryVariables>,
  'query'
> &
  ({ variables: LoyaltyPromoQueryVariables; skip?: boolean } | { skip: boolean });

export const LoyaltyPromoComponent = (props: LoyaltyPromoComponentProps) => (
  <ApolloReactComponents.Query<LoyaltyPromoQuery, LoyaltyPromoQueryVariables> query={LoyaltyPromoDocument} {...props} />
);

export type LoyaltyPromoProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  LoyaltyPromoQuery,
  LoyaltyPromoQueryVariables
> &
  TChildProps;
export function withLoyaltyPromo<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    LoyaltyPromoQuery,
    LoyaltyPromoQueryVariables,
    LoyaltyPromoProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    LoyaltyPromoQuery,
    LoyaltyPromoQueryVariables,
    LoyaltyPromoProps<TChildProps>
  >(LoyaltyPromoDocument, {
    alias: 'loyaltyPromo',
    ...operationOptions,
  });
}

/**
 * __useLoyaltyPromoQuery__
 *
 * To run a query within a React component, call `useLoyaltyPromoQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyPromoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyPromoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoyaltyPromoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LoyaltyPromoQuery, LoyaltyPromoQueryVariables>
) {
  return ApolloReactHooks.useQuery<LoyaltyPromoQuery, LoyaltyPromoQueryVariables>(LoyaltyPromoDocument, baseOptions);
}
export function useLoyaltyPromoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoyaltyPromoQuery, LoyaltyPromoQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<LoyaltyPromoQuery, LoyaltyPromoQueryVariables>(
    LoyaltyPromoDocument,
    baseOptions
  );
}
export type LoyaltyPromoQueryHookResult = ReturnType<typeof useLoyaltyPromoQuery>;
export type LoyaltyPromoLazyQueryHookResult = ReturnType<typeof useLoyaltyPromoLazyQuery>;
export type LoyaltyPromoQueryResult = ApolloReactCommon.QueryResult<LoyaltyPromoQuery, LoyaltyPromoQueryVariables>;
export const LoyaltyPromoCodeDocument = gql`
  query loyaltyPromoCode($id: Int!) {
    loyaltyPromoCode(id: $id) {
      ...loyaltyPromoCode
    }
  }
  ${LoyaltyPromoCodeFragmentDoc}
`;
export type LoyaltyPromoCodeComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<LoyaltyPromoCodeQuery, LoyaltyPromoCodeQueryVariables>,
  'query'
> &
  ({ variables: LoyaltyPromoCodeQueryVariables; skip?: boolean } | { skip: boolean });

export const LoyaltyPromoCodeComponent = (props: LoyaltyPromoCodeComponentProps) => (
  <ApolloReactComponents.Query<LoyaltyPromoCodeQuery, LoyaltyPromoCodeQueryVariables>
    query={LoyaltyPromoCodeDocument}
    {...props}
  />
);

export type LoyaltyPromoCodeProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  LoyaltyPromoCodeQuery,
  LoyaltyPromoCodeQueryVariables
> &
  TChildProps;
export function withLoyaltyPromoCode<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    LoyaltyPromoCodeQuery,
    LoyaltyPromoCodeQueryVariables,
    LoyaltyPromoCodeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    LoyaltyPromoCodeQuery,
    LoyaltyPromoCodeQueryVariables,
    LoyaltyPromoCodeProps<TChildProps>
  >(LoyaltyPromoCodeDocument, {
    alias: 'loyaltyPromoCode',
    ...operationOptions,
  });
}

/**
 * __useLoyaltyPromoCodeQuery__
 *
 * To run a query within a React component, call `useLoyaltyPromoCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyPromoCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyPromoCodeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoyaltyPromoCodeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LoyaltyPromoCodeQuery, LoyaltyPromoCodeQueryVariables>
) {
  return ApolloReactHooks.useQuery<LoyaltyPromoCodeQuery, LoyaltyPromoCodeQueryVariables>(
    LoyaltyPromoCodeDocument,
    baseOptions
  );
}
export function useLoyaltyPromoCodeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoyaltyPromoCodeQuery, LoyaltyPromoCodeQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<LoyaltyPromoCodeQuery, LoyaltyPromoCodeQueryVariables>(
    LoyaltyPromoCodeDocument,
    baseOptions
  );
}
export type LoyaltyPromoCodeQueryHookResult = ReturnType<typeof useLoyaltyPromoCodeQuery>;
export type LoyaltyPromoCodeLazyQueryHookResult = ReturnType<typeof useLoyaltyPromoCodeLazyQuery>;
export type LoyaltyPromoCodeQueryResult = ApolloReactCommon.QueryResult<
  LoyaltyPromoCodeQuery,
  LoyaltyPromoCodeQueryVariables
>;
export const LoyaltySummaryWidgetDocument = gql`
  query loyaltySummaryWidget($where: LoyaltySummaryWidgetInput) {
    loyaltySummaryWidget(where: $where) {
      ...loyaltySummaryWidget
    }
  }
  ${LoyaltySummaryWidgetFragmentDoc}
`;
export type LoyaltySummaryWidgetComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<LoyaltySummaryWidgetQuery, LoyaltySummaryWidgetQueryVariables>,
  'query'
>;

export const LoyaltySummaryWidgetComponent = (props: LoyaltySummaryWidgetComponentProps) => (
  <ApolloReactComponents.Query<LoyaltySummaryWidgetQuery, LoyaltySummaryWidgetQueryVariables>
    query={LoyaltySummaryWidgetDocument}
    {...props}
  />
);

export type LoyaltySummaryWidgetProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  LoyaltySummaryWidgetQuery,
  LoyaltySummaryWidgetQueryVariables
> &
  TChildProps;
export function withLoyaltySummaryWidget<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    LoyaltySummaryWidgetQuery,
    LoyaltySummaryWidgetQueryVariables,
    LoyaltySummaryWidgetProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    LoyaltySummaryWidgetQuery,
    LoyaltySummaryWidgetQueryVariables,
    LoyaltySummaryWidgetProps<TChildProps>
  >(LoyaltySummaryWidgetDocument, {
    alias: 'loyaltySummaryWidget',
    ...operationOptions,
  });
}

/**
 * __useLoyaltySummaryWidgetQuery__
 *
 * To run a query within a React component, call `useLoyaltySummaryWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltySummaryWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltySummaryWidgetQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLoyaltySummaryWidgetQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LoyaltySummaryWidgetQuery, LoyaltySummaryWidgetQueryVariables>
) {
  return ApolloReactHooks.useQuery<LoyaltySummaryWidgetQuery, LoyaltySummaryWidgetQueryVariables>(
    LoyaltySummaryWidgetDocument,
    baseOptions
  );
}
export function useLoyaltySummaryWidgetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoyaltySummaryWidgetQuery, LoyaltySummaryWidgetQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<LoyaltySummaryWidgetQuery, LoyaltySummaryWidgetQueryVariables>(
    LoyaltySummaryWidgetDocument,
    baseOptions
  );
}
export type LoyaltySummaryWidgetQueryHookResult = ReturnType<typeof useLoyaltySummaryWidgetQuery>;
export type LoyaltySummaryWidgetLazyQueryHookResult = ReturnType<typeof useLoyaltySummaryWidgetLazyQuery>;
export type LoyaltySummaryWidgetQueryResult = ApolloReactCommon.QueryResult<
  LoyaltySummaryWidgetQuery,
  LoyaltySummaryWidgetQueryVariables
>;
export const MessengerMailingDocument = gql`
  query messengerMailing($id: Int!) {
    messengerMailing(id: $id) {
      ...messengerMailing
    }
  }
  ${MessengerMailingFragmentDoc}
`;
export type MessengerMailingComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<MessengerMailingQuery, MessengerMailingQueryVariables>,
  'query'
> &
  ({ variables: MessengerMailingQueryVariables; skip?: boolean } | { skip: boolean });

export const MessengerMailingComponent = (props: MessengerMailingComponentProps) => (
  <ApolloReactComponents.Query<MessengerMailingQuery, MessengerMailingQueryVariables>
    query={MessengerMailingDocument}
    {...props}
  />
);

export type MessengerMailingProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  MessengerMailingQuery,
  MessengerMailingQueryVariables
> &
  TChildProps;
export function withMessengerMailing<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MessengerMailingQuery,
    MessengerMailingQueryVariables,
    MessengerMailingProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MessengerMailingQuery,
    MessengerMailingQueryVariables,
    MessengerMailingProps<TChildProps>
  >(MessengerMailingDocument, {
    alias: 'messengerMailing',
    ...operationOptions,
  });
}

/**
 * __useMessengerMailingQuery__
 *
 * To run a query within a React component, call `useMessengerMailingQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessengerMailingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessengerMailingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMessengerMailingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MessengerMailingQuery, MessengerMailingQueryVariables>
) {
  return ApolloReactHooks.useQuery<MessengerMailingQuery, MessengerMailingQueryVariables>(
    MessengerMailingDocument,
    baseOptions
  );
}
export function useMessengerMailingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MessengerMailingQuery, MessengerMailingQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<MessengerMailingQuery, MessengerMailingQueryVariables>(
    MessengerMailingDocument,
    baseOptions
  );
}
export type MessengerMailingQueryHookResult = ReturnType<typeof useMessengerMailingQuery>;
export type MessengerMailingLazyQueryHookResult = ReturnType<typeof useMessengerMailingLazyQuery>;
export type MessengerMailingQueryResult = ApolloReactCommon.QueryResult<
  MessengerMailingQuery,
  MessengerMailingQueryVariables
>;
export const MessengerMailingChannelsDocument = gql`
  query messengerMailingChannels {
    messengerMailingChannels {
      channel
      value
    }
  }
`;
export type MessengerMailingChannelsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<MessengerMailingChannelsQuery, MessengerMailingChannelsQueryVariables>,
  'query'
>;

export const MessengerMailingChannelsComponent = (props: MessengerMailingChannelsComponentProps) => (
  <ApolloReactComponents.Query<MessengerMailingChannelsQuery, MessengerMailingChannelsQueryVariables>
    query={MessengerMailingChannelsDocument}
    {...props}
  />
);

export type MessengerMailingChannelsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  MessengerMailingChannelsQuery,
  MessengerMailingChannelsQueryVariables
> &
  TChildProps;
export function withMessengerMailingChannels<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MessengerMailingChannelsQuery,
    MessengerMailingChannelsQueryVariables,
    MessengerMailingChannelsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MessengerMailingChannelsQuery,
    MessengerMailingChannelsQueryVariables,
    MessengerMailingChannelsProps<TChildProps>
  >(MessengerMailingChannelsDocument, {
    alias: 'messengerMailingChannels',
    ...operationOptions,
  });
}

/**
 * __useMessengerMailingChannelsQuery__
 *
 * To run a query within a React component, call `useMessengerMailingChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessengerMailingChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessengerMailingChannelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMessengerMailingChannelsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MessengerMailingChannelsQuery, MessengerMailingChannelsQueryVariables>
) {
  return ApolloReactHooks.useQuery<MessengerMailingChannelsQuery, MessengerMailingChannelsQueryVariables>(
    MessengerMailingChannelsDocument,
    baseOptions
  );
}
export function useMessengerMailingChannelsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MessengerMailingChannelsQuery,
    MessengerMailingChannelsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<MessengerMailingChannelsQuery, MessengerMailingChannelsQueryVariables>(
    MessengerMailingChannelsDocument,
    baseOptions
  );
}
export type MessengerMailingChannelsQueryHookResult = ReturnType<typeof useMessengerMailingChannelsQuery>;
export type MessengerMailingChannelsLazyQueryHookResult = ReturnType<typeof useMessengerMailingChannelsLazyQuery>;
export type MessengerMailingChannelsQueryResult = ApolloReactCommon.QueryResult<
  MessengerMailingChannelsQuery,
  MessengerMailingChannelsQueryVariables
>;
export const MessengerMailingStatDocument = gql`
  query messengerMailingStat($mailingId: Int) {
    messengerMailingStat(mailingId: $mailingId) {
      state
      value
    }
  }
`;
export type MessengerMailingStatComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<MessengerMailingStatQuery, MessengerMailingStatQueryVariables>,
  'query'
>;

export const MessengerMailingStatComponent = (props: MessengerMailingStatComponentProps) => (
  <ApolloReactComponents.Query<MessengerMailingStatQuery, MessengerMailingStatQueryVariables>
    query={MessengerMailingStatDocument}
    {...props}
  />
);

export type MessengerMailingStatProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  MessengerMailingStatQuery,
  MessengerMailingStatQueryVariables
> &
  TChildProps;
export function withMessengerMailingStat<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MessengerMailingStatQuery,
    MessengerMailingStatQueryVariables,
    MessengerMailingStatProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MessengerMailingStatQuery,
    MessengerMailingStatQueryVariables,
    MessengerMailingStatProps<TChildProps>
  >(MessengerMailingStatDocument, {
    alias: 'messengerMailingStat',
    ...operationOptions,
  });
}

/**
 * __useMessengerMailingStatQuery__
 *
 * To run a query within a React component, call `useMessengerMailingStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessengerMailingStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessengerMailingStatQuery({
 *   variables: {
 *      mailingId: // value for 'mailingId'
 *   },
 * });
 */
export function useMessengerMailingStatQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MessengerMailingStatQuery, MessengerMailingStatQueryVariables>
) {
  return ApolloReactHooks.useQuery<MessengerMailingStatQuery, MessengerMailingStatQueryVariables>(
    MessengerMailingStatDocument,
    baseOptions
  );
}
export function useMessengerMailingStatLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MessengerMailingStatQuery, MessengerMailingStatQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<MessengerMailingStatQuery, MessengerMailingStatQueryVariables>(
    MessengerMailingStatDocument,
    baseOptions
  );
}
export type MessengerMailingStatQueryHookResult = ReturnType<typeof useMessengerMailingStatQuery>;
export type MessengerMailingStatLazyQueryHookResult = ReturnType<typeof useMessengerMailingStatLazyQuery>;
export type MessengerMailingStatQueryResult = ApolloReactCommon.QueryResult<
  MessengerMailingStatQuery,
  MessengerMailingStatQueryVariables
>;
export const MessengerQueueNotificationDocument = gql`
  query messengerQueueNotification($id: String!) {
    messengerQueueNotification(id: $id) {
      ...messengerQueueNotification
    }
  }
  ${MessengerQueueNotificationFragmentDoc}
`;
export type MessengerQueueNotificationComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MessengerQueueNotificationQuery,
    MessengerQueueNotificationQueryVariables
  >,
  'query'
> &
  ({ variables: MessengerQueueNotificationQueryVariables; skip?: boolean } | { skip: boolean });

export const MessengerQueueNotificationComponent = (props: MessengerQueueNotificationComponentProps) => (
  <ApolloReactComponents.Query<MessengerQueueNotificationQuery, MessengerQueueNotificationQueryVariables>
    query={MessengerQueueNotificationDocument}
    {...props}
  />
);

export type MessengerQueueNotificationProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  MessengerQueueNotificationQuery,
  MessengerQueueNotificationQueryVariables
> &
  TChildProps;
export function withMessengerQueueNotification<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MessengerQueueNotificationQuery,
    MessengerQueueNotificationQueryVariables,
    MessengerQueueNotificationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MessengerQueueNotificationQuery,
    MessengerQueueNotificationQueryVariables,
    MessengerQueueNotificationProps<TChildProps>
  >(MessengerQueueNotificationDocument, {
    alias: 'messengerQueueNotification',
    ...operationOptions,
  });
}

/**
 * __useMessengerQueueNotificationQuery__
 *
 * To run a query within a React component, call `useMessengerQueueNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessengerQueueNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessengerQueueNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMessengerQueueNotificationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MessengerQueueNotificationQuery,
    MessengerQueueNotificationQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<MessengerQueueNotificationQuery, MessengerQueueNotificationQueryVariables>(
    MessengerQueueNotificationDocument,
    baseOptions
  );
}
export function useMessengerQueueNotificationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MessengerQueueNotificationQuery,
    MessengerQueueNotificationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<MessengerQueueNotificationQuery, MessengerQueueNotificationQueryVariables>(
    MessengerQueueNotificationDocument,
    baseOptions
  );
}
export type MessengerQueueNotificationQueryHookResult = ReturnType<typeof useMessengerQueueNotificationQuery>;
export type MessengerQueueNotificationLazyQueryHookResult = ReturnType<typeof useMessengerQueueNotificationLazyQuery>;
export type MessengerQueueNotificationQueryResult = ApolloReactCommon.QueryResult<
  MessengerQueueNotificationQuery,
  MessengerQueueNotificationQueryVariables
>;
export const MessengerQueueTaskDocument = gql`
  query messengerQueueTask($id: Int!) {
    messengerQueueTask(id: $id) {
      ...messengerQueueTask
    }
  }
  ${MessengerQueueTaskFragmentDoc}
`;
export type MessengerQueueTaskComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<MessengerQueueTaskQuery, MessengerQueueTaskQueryVariables>,
  'query'
> &
  ({ variables: MessengerQueueTaskQueryVariables; skip?: boolean } | { skip: boolean });

export const MessengerQueueTaskComponent = (props: MessengerQueueTaskComponentProps) => (
  <ApolloReactComponents.Query<MessengerQueueTaskQuery, MessengerQueueTaskQueryVariables>
    query={MessengerQueueTaskDocument}
    {...props}
  />
);

export type MessengerQueueTaskProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  MessengerQueueTaskQuery,
  MessengerQueueTaskQueryVariables
> &
  TChildProps;
export function withMessengerQueueTask<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MessengerQueueTaskQuery,
    MessengerQueueTaskQueryVariables,
    MessengerQueueTaskProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MessengerQueueTaskQuery,
    MessengerQueueTaskQueryVariables,
    MessengerQueueTaskProps<TChildProps>
  >(MessengerQueueTaskDocument, {
    alias: 'messengerQueueTask',
    ...operationOptions,
  });
}

/**
 * __useMessengerQueueTaskQuery__
 *
 * To run a query within a React component, call `useMessengerQueueTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessengerQueueTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessengerQueueTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMessengerQueueTaskQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MessengerQueueTaskQuery, MessengerQueueTaskQueryVariables>
) {
  return ApolloReactHooks.useQuery<MessengerQueueTaskQuery, MessengerQueueTaskQueryVariables>(
    MessengerQueueTaskDocument,
    baseOptions
  );
}
export function useMessengerQueueTaskLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MessengerQueueTaskQuery, MessengerQueueTaskQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<MessengerQueueTaskQuery, MessengerQueueTaskQueryVariables>(
    MessengerQueueTaskDocument,
    baseOptions
  );
}
export type MessengerQueueTaskQueryHookResult = ReturnType<typeof useMessengerQueueTaskQuery>;
export type MessengerQueueTaskLazyQueryHookResult = ReturnType<typeof useMessengerQueueTaskLazyQuery>;
export type MessengerQueueTaskQueryResult = ApolloReactCommon.QueryResult<
  MessengerQueueTaskQuery,
  MessengerQueueTaskQueryVariables
>;
export const MessengerSettingDocument = gql`
  query messengerSetting {
    messengerSetting {
      ...messengerSetting
    }
  }
  ${MessengerSettingFragmentDoc}
`;
export type MessengerSettingComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<MessengerSettingQuery, MessengerSettingQueryVariables>,
  'query'
>;

export const MessengerSettingComponent = (props: MessengerSettingComponentProps) => (
  <ApolloReactComponents.Query<MessengerSettingQuery, MessengerSettingQueryVariables>
    query={MessengerSettingDocument}
    {...props}
  />
);

export type MessengerSettingProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  MessengerSettingQuery,
  MessengerSettingQueryVariables
> &
  TChildProps;
export function withMessengerSetting<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MessengerSettingQuery,
    MessengerSettingQueryVariables,
    MessengerSettingProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MessengerSettingQuery,
    MessengerSettingQueryVariables,
    MessengerSettingProps<TChildProps>
  >(MessengerSettingDocument, {
    alias: 'messengerSetting',
    ...operationOptions,
  });
}

/**
 * __useMessengerSettingQuery__
 *
 * To run a query within a React component, call `useMessengerSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessengerSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessengerSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useMessengerSettingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MessengerSettingQuery, MessengerSettingQueryVariables>
) {
  return ApolloReactHooks.useQuery<MessengerSettingQuery, MessengerSettingQueryVariables>(
    MessengerSettingDocument,
    baseOptions
  );
}
export function useMessengerSettingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MessengerSettingQuery, MessengerSettingQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<MessengerSettingQuery, MessengerSettingQueryVariables>(
    MessengerSettingDocument,
    baseOptions
  );
}
export type MessengerSettingQueryHookResult = ReturnType<typeof useMessengerSettingQuery>;
export type MessengerSettingLazyQueryHookResult = ReturnType<typeof useMessengerSettingLazyQuery>;
export type MessengerSettingQueryResult = ApolloReactCommon.QueryResult<
  MessengerSettingQuery,
  MessengerSettingQueryVariables
>;
export const MessengerTemplateDocument = gql`
  query messengerTemplate($id: Int!) {
    messengerTemplate(id: $id) {
      ...messengerTemplate
    }
  }
  ${MessengerTemplateFragmentDoc}
`;
export type MessengerTemplateComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<MessengerTemplateQuery, MessengerTemplateQueryVariables>,
  'query'
> &
  ({ variables: MessengerTemplateQueryVariables; skip?: boolean } | { skip: boolean });

export const MessengerTemplateComponent = (props: MessengerTemplateComponentProps) => (
  <ApolloReactComponents.Query<MessengerTemplateQuery, MessengerTemplateQueryVariables>
    query={MessengerTemplateDocument}
    {...props}
  />
);

export type MessengerTemplateProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  MessengerTemplateQuery,
  MessengerTemplateQueryVariables
> &
  TChildProps;
export function withMessengerTemplate<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MessengerTemplateQuery,
    MessengerTemplateQueryVariables,
    MessengerTemplateProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MessengerTemplateQuery,
    MessengerTemplateQueryVariables,
    MessengerTemplateProps<TChildProps>
  >(MessengerTemplateDocument, {
    alias: 'messengerTemplate',
    ...operationOptions,
  });
}

/**
 * __useMessengerTemplateQuery__
 *
 * To run a query within a React component, call `useMessengerTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessengerTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessengerTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMessengerTemplateQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MessengerTemplateQuery, MessengerTemplateQueryVariables>
) {
  return ApolloReactHooks.useQuery<MessengerTemplateQuery, MessengerTemplateQueryVariables>(
    MessengerTemplateDocument,
    baseOptions
  );
}
export function useMessengerTemplateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MessengerTemplateQuery, MessengerTemplateQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<MessengerTemplateQuery, MessengerTemplateQueryVariables>(
    MessengerTemplateDocument,
    baseOptions
  );
}
export type MessengerTemplateQueryHookResult = ReturnType<typeof useMessengerTemplateQuery>;
export type MessengerTemplateLazyQueryHookResult = ReturnType<typeof useMessengerTemplateLazyQuery>;
export type MessengerTemplateQueryResult = ApolloReactCommon.QueryResult<
  MessengerTemplateQuery,
  MessengerTemplateQueryVariables
>;
export const MessengerTransportDocument = gql`
  query messengerTransport($id: Int!) {
    messengerTransport(id: $id) {
      ...messengerTransport
    }
  }
  ${MessengerTransportFragmentDoc}
`;
export type MessengerTransportComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<MessengerTransportQuery, MessengerTransportQueryVariables>,
  'query'
> &
  ({ variables: MessengerTransportQueryVariables; skip?: boolean } | { skip: boolean });

export const MessengerTransportComponent = (props: MessengerTransportComponentProps) => (
  <ApolloReactComponents.Query<MessengerTransportQuery, MessengerTransportQueryVariables>
    query={MessengerTransportDocument}
    {...props}
  />
);

export type MessengerTransportProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  MessengerTransportQuery,
  MessengerTransportQueryVariables
> &
  TChildProps;
export function withMessengerTransport<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MessengerTransportQuery,
    MessengerTransportQueryVariables,
    MessengerTransportProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MessengerTransportQuery,
    MessengerTransportQueryVariables,
    MessengerTransportProps<TChildProps>
  >(MessengerTransportDocument, {
    alias: 'messengerTransport',
    ...operationOptions,
  });
}

/**
 * __useMessengerTransportQuery__
 *
 * To run a query within a React component, call `useMessengerTransportQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessengerTransportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessengerTransportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMessengerTransportQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MessengerTransportQuery, MessengerTransportQueryVariables>
) {
  return ApolloReactHooks.useQuery<MessengerTransportQuery, MessengerTransportQueryVariables>(
    MessengerTransportDocument,
    baseOptions
  );
}
export function useMessengerTransportLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MessengerTransportQuery, MessengerTransportQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<MessengerTransportQuery, MessengerTransportQueryVariables>(
    MessengerTransportDocument,
    baseOptions
  );
}
export type MessengerTransportQueryHookResult = ReturnType<typeof useMessengerTransportQuery>;
export type MessengerTransportLazyQueryHookResult = ReturnType<typeof useMessengerTransportLazyQuery>;
export type MessengerTransportQueryResult = ApolloReactCommon.QueryResult<
  MessengerTransportQuery,
  MessengerTransportQueryVariables
>;
export const MobileTranslationDocument = gql`
  query mobileTranslation($id: Int!) {
    mobileTranslation(id: $id) {
      ...mobileTranslation
    }
  }
  ${MobileTranslationFragmentDoc}
`;
export type MobileTranslationComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<MobileTranslationQuery, MobileTranslationQueryVariables>,
  'query'
> &
  ({ variables: MobileTranslationQueryVariables; skip?: boolean } | { skip: boolean });

export const MobileTranslationComponent = (props: MobileTranslationComponentProps) => (
  <ApolloReactComponents.Query<MobileTranslationQuery, MobileTranslationQueryVariables>
    query={MobileTranslationDocument}
    {...props}
  />
);

export type MobileTranslationProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  MobileTranslationQuery,
  MobileTranslationQueryVariables
> &
  TChildProps;
export function withMobileTranslation<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MobileTranslationQuery,
    MobileTranslationQueryVariables,
    MobileTranslationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MobileTranslationQuery,
    MobileTranslationQueryVariables,
    MobileTranslationProps<TChildProps>
  >(MobileTranslationDocument, {
    alias: 'mobileTranslation',
    ...operationOptions,
  });
}

/**
 * __useMobileTranslationQuery__
 *
 * To run a query within a React component, call `useMobileTranslationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMobileTranslationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMobileTranslationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMobileTranslationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MobileTranslationQuery, MobileTranslationQueryVariables>
) {
  return ApolloReactHooks.useQuery<MobileTranslationQuery, MobileTranslationQueryVariables>(
    MobileTranslationDocument,
    baseOptions
  );
}
export function useMobileTranslationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MobileTranslationQuery, MobileTranslationQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<MobileTranslationQuery, MobileTranslationQueryVariables>(
    MobileTranslationDocument,
    baseOptions
  );
}
export type MobileTranslationQueryHookResult = ReturnType<typeof useMobileTranslationQuery>;
export type MobileTranslationLazyQueryHookResult = ReturnType<typeof useMobileTranslationLazyQuery>;
export type MobileTranslationQueryResult = ApolloReactCommon.QueryResult<
  MobileTranslationQuery,
  MobileTranslationQueryVariables
>;
export const NavigationDocument = gql`
  query navigation {
    navigation {
      ...navigation
    }
  }
  ${NavigationFragmentDoc}
`;
export type NavigationComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<NavigationQuery, NavigationQueryVariables>,
  'query'
>;

export const NavigationComponent = (props: NavigationComponentProps) => (
  <ApolloReactComponents.Query<NavigationQuery, NavigationQueryVariables> query={NavigationDocument} {...props} />
);

export type NavigationProps<TChildProps = {}> = ApolloReactHoc.DataProps<NavigationQuery, NavigationQueryVariables> &
  TChildProps;
export function withNavigation<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    NavigationQuery,
    NavigationQueryVariables,
    NavigationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<TProps, NavigationQuery, NavigationQueryVariables, NavigationProps<TChildProps>>(
    NavigationDocument,
    {
      alias: 'navigation',
      ...operationOptions,
    }
  );
}

/**
 * __useNavigationQuery__
 *
 * To run a query within a React component, call `useNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigationQuery({
 *   variables: {
 *   },
 * });
 */
export function useNavigationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<NavigationQuery, NavigationQueryVariables>
) {
  return ApolloReactHooks.useQuery<NavigationQuery, NavigationQueryVariables>(NavigationDocument, baseOptions);
}
export function useNavigationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NavigationQuery, NavigationQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<NavigationQuery, NavigationQueryVariables>(NavigationDocument, baseOptions);
}
export type NavigationQueryHookResult = ReturnType<typeof useNavigationQuery>;
export type NavigationLazyQueryHookResult = ReturnType<typeof useNavigationLazyQuery>;
export type NavigationQueryResult = ApolloReactCommon.QueryResult<NavigationQuery, NavigationQueryVariables>;
export const NewUsersWidgetDocument = gql`
  query newUsersWidget($dates: [String!]) {
    newUsersWidget(dates: $dates) {
      total
      graphs {
        ...widgetSummaryGraph
      }
    }
  }
  ${WidgetSummaryGraphFragmentDoc}
`;
export type NewUsersWidgetComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<NewUsersWidgetQuery, NewUsersWidgetQueryVariables>,
  'query'
>;

export const NewUsersWidgetComponent = (props: NewUsersWidgetComponentProps) => (
  <ApolloReactComponents.Query<NewUsersWidgetQuery, NewUsersWidgetQueryVariables>
    query={NewUsersWidgetDocument}
    {...props}
  />
);

export type NewUsersWidgetProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  NewUsersWidgetQuery,
  NewUsersWidgetQueryVariables
> &
  TChildProps;
export function withNewUsersWidget<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    NewUsersWidgetQuery,
    NewUsersWidgetQueryVariables,
    NewUsersWidgetProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    NewUsersWidgetQuery,
    NewUsersWidgetQueryVariables,
    NewUsersWidgetProps<TChildProps>
  >(NewUsersWidgetDocument, {
    alias: 'newUsersWidget',
    ...operationOptions,
  });
}

/**
 * __useNewUsersWidgetQuery__
 *
 * To run a query within a React component, call `useNewUsersWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewUsersWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewUsersWidgetQuery({
 *   variables: {
 *      dates: // value for 'dates'
 *   },
 * });
 */
export function useNewUsersWidgetQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<NewUsersWidgetQuery, NewUsersWidgetQueryVariables>
) {
  return ApolloReactHooks.useQuery<NewUsersWidgetQuery, NewUsersWidgetQueryVariables>(
    NewUsersWidgetDocument,
    baseOptions
  );
}
export function useNewUsersWidgetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewUsersWidgetQuery, NewUsersWidgetQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<NewUsersWidgetQuery, NewUsersWidgetQueryVariables>(
    NewUsersWidgetDocument,
    baseOptions
  );
}
export type NewUsersWidgetQueryHookResult = ReturnType<typeof useNewUsersWidgetQuery>;
export type NewUsersWidgetLazyQueryHookResult = ReturnType<typeof useNewUsersWidgetLazyQuery>;
export type NewUsersWidgetQueryResult = ApolloReactCommon.QueryResult<
  NewUsersWidgetQuery,
  NewUsersWidgetQueryVariables
>;
export const PaymentAccountDocument = gql`
  query paymentAccount($id: Int!) {
    paymentAccount(id: $id) {
      ...paymentAccount
    }
  }
  ${PaymentAccountFragmentDoc}
`;
export type PaymentAccountComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<PaymentAccountQuery, PaymentAccountQueryVariables>,
  'query'
> &
  ({ variables: PaymentAccountQueryVariables; skip?: boolean } | { skip: boolean });

export const PaymentAccountComponent = (props: PaymentAccountComponentProps) => (
  <ApolloReactComponents.Query<PaymentAccountQuery, PaymentAccountQueryVariables>
    query={PaymentAccountDocument}
    {...props}
  />
);

export type PaymentAccountProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  PaymentAccountQuery,
  PaymentAccountQueryVariables
> &
  TChildProps;
export function withPaymentAccount<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PaymentAccountQuery,
    PaymentAccountQueryVariables,
    PaymentAccountProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PaymentAccountQuery,
    PaymentAccountQueryVariables,
    PaymentAccountProps<TChildProps>
  >(PaymentAccountDocument, {
    alias: 'paymentAccount',
    ...operationOptions,
  });
}

/**
 * __usePaymentAccountQuery__
 *
 * To run a query within a React component, call `usePaymentAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentAccountQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<PaymentAccountQuery, PaymentAccountQueryVariables>
) {
  return ApolloReactHooks.useQuery<PaymentAccountQuery, PaymentAccountQueryVariables>(
    PaymentAccountDocument,
    baseOptions
  );
}
export function usePaymentAccountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentAccountQuery, PaymentAccountQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<PaymentAccountQuery, PaymentAccountQueryVariables>(
    PaymentAccountDocument,
    baseOptions
  );
}
export type PaymentAccountQueryHookResult = ReturnType<typeof usePaymentAccountQuery>;
export type PaymentAccountLazyQueryHookResult = ReturnType<typeof usePaymentAccountLazyQuery>;
export type PaymentAccountQueryResult = ApolloReactCommon.QueryResult<
  PaymentAccountQuery,
  PaymentAccountQueryVariables
>;
export const PaymentAccountsDocument = gql`
  query paymentAccounts($query: DefaultQueryInput!) {
    paymentAccounts(query: $query) {
      ...paymentAccountFlat
    }
  }
  ${PaymentAccountFlatFragmentDoc}
`;
export type PaymentAccountsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<PaymentAccountsQuery, PaymentAccountsQueryVariables>,
  'query'
> &
  ({ variables: PaymentAccountsQueryVariables; skip?: boolean } | { skip: boolean });

export const PaymentAccountsComponent = (props: PaymentAccountsComponentProps) => (
  <ApolloReactComponents.Query<PaymentAccountsQuery, PaymentAccountsQueryVariables>
    query={PaymentAccountsDocument}
    {...props}
  />
);

export type PaymentAccountsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  PaymentAccountsQuery,
  PaymentAccountsQueryVariables
> &
  TChildProps;
export function withPaymentAccounts<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PaymentAccountsQuery,
    PaymentAccountsQueryVariables,
    PaymentAccountsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PaymentAccountsQuery,
    PaymentAccountsQueryVariables,
    PaymentAccountsProps<TChildProps>
  >(PaymentAccountsDocument, {
    alias: 'paymentAccounts',
    ...operationOptions,
  });
}

/**
 * __usePaymentAccountsQuery__
 *
 * To run a query within a React component, call `usePaymentAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentAccountsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function usePaymentAccountsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<PaymentAccountsQuery, PaymentAccountsQueryVariables>
) {
  return ApolloReactHooks.useQuery<PaymentAccountsQuery, PaymentAccountsQueryVariables>(
    PaymentAccountsDocument,
    baseOptions
  );
}
export function usePaymentAccountsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentAccountsQuery, PaymentAccountsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<PaymentAccountsQuery, PaymentAccountsQueryVariables>(
    PaymentAccountsDocument,
    baseOptions
  );
}
export type PaymentAccountsQueryHookResult = ReturnType<typeof usePaymentAccountsQuery>;
export type PaymentAccountsLazyQueryHookResult = ReturnType<typeof usePaymentAccountsLazyQuery>;
export type PaymentAccountsQueryResult = ApolloReactCommon.QueryResult<
  PaymentAccountsQuery,
  PaymentAccountsQueryVariables
>;
export const PaymentChannelSummaryWidgetDocument = gql`
  query paymentChannelSummaryWidget {
    paymentChannelSummaryWidget {
      channel {
        ...paymentChannel
      }
      graphs {
        ...widgetSummaryGraph
      }
      sum
    }
  }
  ${PaymentChannelFragmentDoc}
  ${WidgetSummaryGraphFragmentDoc}
`;
export type PaymentChannelSummaryWidgetComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PaymentChannelSummaryWidgetQuery,
    PaymentChannelSummaryWidgetQueryVariables
  >,
  'query'
>;

export const PaymentChannelSummaryWidgetComponent = (props: PaymentChannelSummaryWidgetComponentProps) => (
  <ApolloReactComponents.Query<PaymentChannelSummaryWidgetQuery, PaymentChannelSummaryWidgetQueryVariables>
    query={PaymentChannelSummaryWidgetDocument}
    {...props}
  />
);

export type PaymentChannelSummaryWidgetProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  PaymentChannelSummaryWidgetQuery,
  PaymentChannelSummaryWidgetQueryVariables
> &
  TChildProps;
export function withPaymentChannelSummaryWidget<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PaymentChannelSummaryWidgetQuery,
    PaymentChannelSummaryWidgetQueryVariables,
    PaymentChannelSummaryWidgetProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PaymentChannelSummaryWidgetQuery,
    PaymentChannelSummaryWidgetQueryVariables,
    PaymentChannelSummaryWidgetProps<TChildProps>
  >(PaymentChannelSummaryWidgetDocument, {
    alias: 'paymentChannelSummaryWidget',
    ...operationOptions,
  });
}

/**
 * __usePaymentChannelSummaryWidgetQuery__
 *
 * To run a query within a React component, call `usePaymentChannelSummaryWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentChannelSummaryWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentChannelSummaryWidgetQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentChannelSummaryWidgetQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PaymentChannelSummaryWidgetQuery,
    PaymentChannelSummaryWidgetQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<PaymentChannelSummaryWidgetQuery, PaymentChannelSummaryWidgetQueryVariables>(
    PaymentChannelSummaryWidgetDocument,
    baseOptions
  );
}
export function usePaymentChannelSummaryWidgetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaymentChannelSummaryWidgetQuery,
    PaymentChannelSummaryWidgetQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<PaymentChannelSummaryWidgetQuery, PaymentChannelSummaryWidgetQueryVariables>(
    PaymentChannelSummaryWidgetDocument,
    baseOptions
  );
}
export type PaymentChannelSummaryWidgetQueryHookResult = ReturnType<typeof usePaymentChannelSummaryWidgetQuery>;
export type PaymentChannelSummaryWidgetLazyQueryHookResult = ReturnType<typeof usePaymentChannelSummaryWidgetLazyQuery>;
export type PaymentChannelSummaryWidgetQueryResult = ApolloReactCommon.QueryResult<
  PaymentChannelSummaryWidgetQuery,
  PaymentChannelSummaryWidgetQueryVariables
>;
export const PaymentCurrencyDocument = gql`
  query paymentCurrency($id: Int!) {
    paymentCurrency(id: $id) {
      ...paymentCurrency
    }
  }
  ${PaymentCurrencyFragmentDoc}
`;
export type PaymentCurrencyComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<PaymentCurrencyQuery, PaymentCurrencyQueryVariables>,
  'query'
> &
  ({ variables: PaymentCurrencyQueryVariables; skip?: boolean } | { skip: boolean });

export const PaymentCurrencyComponent = (props: PaymentCurrencyComponentProps) => (
  <ApolloReactComponents.Query<PaymentCurrencyQuery, PaymentCurrencyQueryVariables>
    query={PaymentCurrencyDocument}
    {...props}
  />
);

export type PaymentCurrencyProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  PaymentCurrencyQuery,
  PaymentCurrencyQueryVariables
> &
  TChildProps;
export function withPaymentCurrency<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PaymentCurrencyQuery,
    PaymentCurrencyQueryVariables,
    PaymentCurrencyProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PaymentCurrencyQuery,
    PaymentCurrencyQueryVariables,
    PaymentCurrencyProps<TChildProps>
  >(PaymentCurrencyDocument, {
    alias: 'paymentCurrency',
    ...operationOptions,
  });
}

/**
 * __usePaymentCurrencyQuery__
 *
 * To run a query within a React component, call `usePaymentCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentCurrencyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentCurrencyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<PaymentCurrencyQuery, PaymentCurrencyQueryVariables>
) {
  return ApolloReactHooks.useQuery<PaymentCurrencyQuery, PaymentCurrencyQueryVariables>(
    PaymentCurrencyDocument,
    baseOptions
  );
}
export function usePaymentCurrencyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentCurrencyQuery, PaymentCurrencyQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<PaymentCurrencyQuery, PaymentCurrencyQueryVariables>(
    PaymentCurrencyDocument,
    baseOptions
  );
}
export type PaymentCurrencyQueryHookResult = ReturnType<typeof usePaymentCurrencyQuery>;
export type PaymentCurrencyLazyQueryHookResult = ReturnType<typeof usePaymentCurrencyLazyQuery>;
export type PaymentCurrencyQueryResult = ApolloReactCommon.QueryResult<
  PaymentCurrencyQuery,
  PaymentCurrencyQueryVariables
>;
export const PaymentCurrencySummaryWidgetDocument = gql`
  query paymentCurrencySummaryWidget {
    paymentCurrencySummaryWidget {
      currency {
        ...paymentCurrency
      }
      graphs {
        ...widgetSummaryGraph
      }
    }
  }
  ${PaymentCurrencyFragmentDoc}
  ${WidgetSummaryGraphFragmentDoc}
`;
export type PaymentCurrencySummaryWidgetComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PaymentCurrencySummaryWidgetQuery,
    PaymentCurrencySummaryWidgetQueryVariables
  >,
  'query'
>;

export const PaymentCurrencySummaryWidgetComponent = (props: PaymentCurrencySummaryWidgetComponentProps) => (
  <ApolloReactComponents.Query<PaymentCurrencySummaryWidgetQuery, PaymentCurrencySummaryWidgetQueryVariables>
    query={PaymentCurrencySummaryWidgetDocument}
    {...props}
  />
);

export type PaymentCurrencySummaryWidgetProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  PaymentCurrencySummaryWidgetQuery,
  PaymentCurrencySummaryWidgetQueryVariables
> &
  TChildProps;
export function withPaymentCurrencySummaryWidget<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PaymentCurrencySummaryWidgetQuery,
    PaymentCurrencySummaryWidgetQueryVariables,
    PaymentCurrencySummaryWidgetProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PaymentCurrencySummaryWidgetQuery,
    PaymentCurrencySummaryWidgetQueryVariables,
    PaymentCurrencySummaryWidgetProps<TChildProps>
  >(PaymentCurrencySummaryWidgetDocument, {
    alias: 'paymentCurrencySummaryWidget',
    ...operationOptions,
  });
}

/**
 * __usePaymentCurrencySummaryWidgetQuery__
 *
 * To run a query within a React component, call `usePaymentCurrencySummaryWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentCurrencySummaryWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentCurrencySummaryWidgetQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentCurrencySummaryWidgetQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PaymentCurrencySummaryWidgetQuery,
    PaymentCurrencySummaryWidgetQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<PaymentCurrencySummaryWidgetQuery, PaymentCurrencySummaryWidgetQueryVariables>(
    PaymentCurrencySummaryWidgetDocument,
    baseOptions
  );
}
export function usePaymentCurrencySummaryWidgetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaymentCurrencySummaryWidgetQuery,
    PaymentCurrencySummaryWidgetQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<PaymentCurrencySummaryWidgetQuery, PaymentCurrencySummaryWidgetQueryVariables>(
    PaymentCurrencySummaryWidgetDocument,
    baseOptions
  );
}
export type PaymentCurrencySummaryWidgetQueryHookResult = ReturnType<typeof usePaymentCurrencySummaryWidgetQuery>;
export type PaymentCurrencySummaryWidgetLazyQueryHookResult = ReturnType<
  typeof usePaymentCurrencySummaryWidgetLazyQuery
>;
export type PaymentCurrencySummaryWidgetQueryResult = ApolloReactCommon.QueryResult<
  PaymentCurrencySummaryWidgetQuery,
  PaymentCurrencySummaryWidgetQueryVariables
>;
export const PaymentGatewaySummaryWidgetDocument = gql`
  query paymentGatewaySummaryWidget {
    paymentGatewaySummaryWidget {
      gateway {
        ...paymentGateway
      }
      graphs {
        ...widgetSummaryGraph
      }
    }
  }
  ${PaymentGatewayFragmentDoc}
  ${WidgetSummaryGraphFragmentDoc}
`;
export type PaymentGatewaySummaryWidgetComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PaymentGatewaySummaryWidgetQuery,
    PaymentGatewaySummaryWidgetQueryVariables
  >,
  'query'
>;

export const PaymentGatewaySummaryWidgetComponent = (props: PaymentGatewaySummaryWidgetComponentProps) => (
  <ApolloReactComponents.Query<PaymentGatewaySummaryWidgetQuery, PaymentGatewaySummaryWidgetQueryVariables>
    query={PaymentGatewaySummaryWidgetDocument}
    {...props}
  />
);

export type PaymentGatewaySummaryWidgetProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  PaymentGatewaySummaryWidgetQuery,
  PaymentGatewaySummaryWidgetQueryVariables
> &
  TChildProps;
export function withPaymentGatewaySummaryWidget<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PaymentGatewaySummaryWidgetQuery,
    PaymentGatewaySummaryWidgetQueryVariables,
    PaymentGatewaySummaryWidgetProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PaymentGatewaySummaryWidgetQuery,
    PaymentGatewaySummaryWidgetQueryVariables,
    PaymentGatewaySummaryWidgetProps<TChildProps>
  >(PaymentGatewaySummaryWidgetDocument, {
    alias: 'paymentGatewaySummaryWidget',
    ...operationOptions,
  });
}

/**
 * __usePaymentGatewaySummaryWidgetQuery__
 *
 * To run a query within a React component, call `usePaymentGatewaySummaryWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentGatewaySummaryWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentGatewaySummaryWidgetQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentGatewaySummaryWidgetQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PaymentGatewaySummaryWidgetQuery,
    PaymentGatewaySummaryWidgetQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<PaymentGatewaySummaryWidgetQuery, PaymentGatewaySummaryWidgetQueryVariables>(
    PaymentGatewaySummaryWidgetDocument,
    baseOptions
  );
}
export function usePaymentGatewaySummaryWidgetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaymentGatewaySummaryWidgetQuery,
    PaymentGatewaySummaryWidgetQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<PaymentGatewaySummaryWidgetQuery, PaymentGatewaySummaryWidgetQueryVariables>(
    PaymentGatewaySummaryWidgetDocument,
    baseOptions
  );
}
export type PaymentGatewaySummaryWidgetQueryHookResult = ReturnType<typeof usePaymentGatewaySummaryWidgetQuery>;
export type PaymentGatewaySummaryWidgetLazyQueryHookResult = ReturnType<typeof usePaymentGatewaySummaryWidgetLazyQuery>;
export type PaymentGatewaySummaryWidgetQueryResult = ApolloReactCommon.QueryResult<
  PaymentGatewaySummaryWidgetQuery,
  PaymentGatewaySummaryWidgetQueryVariables
>;
export const PaymentProviderDocument = gql`
  query paymentProvider($id: Int!) {
    paymentProvider(id: $id) {
      ...paymentProvider
    }
  }
  ${PaymentProviderFragmentDoc}
`;
export type PaymentProviderComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<PaymentProviderQuery, PaymentProviderQueryVariables>,
  'query'
> &
  ({ variables: PaymentProviderQueryVariables; skip?: boolean } | { skip: boolean });

export const PaymentProviderComponent = (props: PaymentProviderComponentProps) => (
  <ApolloReactComponents.Query<PaymentProviderQuery, PaymentProviderQueryVariables>
    query={PaymentProviderDocument}
    {...props}
  />
);

export type PaymentProviderProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  PaymentProviderQuery,
  PaymentProviderQueryVariables
> &
  TChildProps;
export function withPaymentProvider<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PaymentProviderQuery,
    PaymentProviderQueryVariables,
    PaymentProviderProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PaymentProviderQuery,
    PaymentProviderQueryVariables,
    PaymentProviderProps<TChildProps>
  >(PaymentProviderDocument, {
    alias: 'paymentProvider',
    ...operationOptions,
  });
}

/**
 * __usePaymentProviderQuery__
 *
 * To run a query within a React component, call `usePaymentProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentProviderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentProviderQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<PaymentProviderQuery, PaymentProviderQueryVariables>
) {
  return ApolloReactHooks.useQuery<PaymentProviderQuery, PaymentProviderQueryVariables>(
    PaymentProviderDocument,
    baseOptions
  );
}
export function usePaymentProviderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentProviderQuery, PaymentProviderQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<PaymentProviderQuery, PaymentProviderQueryVariables>(
    PaymentProviderDocument,
    baseOptions
  );
}
export type PaymentProviderQueryHookResult = ReturnType<typeof usePaymentProviderQuery>;
export type PaymentProviderLazyQueryHookResult = ReturnType<typeof usePaymentProviderLazyQuery>;
export type PaymentProviderQueryResult = ApolloReactCommon.QueryResult<
  PaymentProviderQuery,
  PaymentProviderQueryVariables
>;
export const PaymentProviderSummaryWidgetDocument = gql`
  query paymentProviderSummaryWidget {
    paymentProviderSummaryWidget {
      provider {
        ...paymentProvider
      }
      graphs {
        ...widgetSummaryGraph
      }
      sum
    }
  }
  ${PaymentProviderFragmentDoc}
  ${WidgetSummaryGraphFragmentDoc}
`;
export type PaymentProviderSummaryWidgetComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PaymentProviderSummaryWidgetQuery,
    PaymentProviderSummaryWidgetQueryVariables
  >,
  'query'
>;

export const PaymentProviderSummaryWidgetComponent = (props: PaymentProviderSummaryWidgetComponentProps) => (
  <ApolloReactComponents.Query<PaymentProviderSummaryWidgetQuery, PaymentProviderSummaryWidgetQueryVariables>
    query={PaymentProviderSummaryWidgetDocument}
    {...props}
  />
);

export type PaymentProviderSummaryWidgetProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  PaymentProviderSummaryWidgetQuery,
  PaymentProviderSummaryWidgetQueryVariables
> &
  TChildProps;
export function withPaymentProviderSummaryWidget<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PaymentProviderSummaryWidgetQuery,
    PaymentProviderSummaryWidgetQueryVariables,
    PaymentProviderSummaryWidgetProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PaymentProviderSummaryWidgetQuery,
    PaymentProviderSummaryWidgetQueryVariables,
    PaymentProviderSummaryWidgetProps<TChildProps>
  >(PaymentProviderSummaryWidgetDocument, {
    alias: 'paymentProviderSummaryWidget',
    ...operationOptions,
  });
}

/**
 * __usePaymentProviderSummaryWidgetQuery__
 *
 * To run a query within a React component, call `usePaymentProviderSummaryWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentProviderSummaryWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentProviderSummaryWidgetQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentProviderSummaryWidgetQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PaymentProviderSummaryWidgetQuery,
    PaymentProviderSummaryWidgetQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<PaymentProviderSummaryWidgetQuery, PaymentProviderSummaryWidgetQueryVariables>(
    PaymentProviderSummaryWidgetDocument,
    baseOptions
  );
}
export function usePaymentProviderSummaryWidgetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaymentProviderSummaryWidgetQuery,
    PaymentProviderSummaryWidgetQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<PaymentProviderSummaryWidgetQuery, PaymentProviderSummaryWidgetQueryVariables>(
    PaymentProviderSummaryWidgetDocument,
    baseOptions
  );
}
export type PaymentProviderSummaryWidgetQueryHookResult = ReturnType<typeof usePaymentProviderSummaryWidgetQuery>;
export type PaymentProviderSummaryWidgetLazyQueryHookResult = ReturnType<
  typeof usePaymentProviderSummaryWidgetLazyQuery
>;
export type PaymentProviderSummaryWidgetQueryResult = ApolloReactCommon.QueryResult<
  PaymentProviderSummaryWidgetQuery,
  PaymentProviderSummaryWidgetQueryVariables
>;
export const PaymentTransactionDocument = gql`
  query paymentTransaction($id: Int!) {
    paymentTransaction(id: $id) {
      ...paymentTransaction
    }
  }
  ${PaymentTransactionFragmentDoc}
`;
export type PaymentTransactionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<PaymentTransactionQuery, PaymentTransactionQueryVariables>,
  'query'
> &
  ({ variables: PaymentTransactionQueryVariables; skip?: boolean } | { skip: boolean });

export const PaymentTransactionComponent = (props: PaymentTransactionComponentProps) => (
  <ApolloReactComponents.Query<PaymentTransactionQuery, PaymentTransactionQueryVariables>
    query={PaymentTransactionDocument}
    {...props}
  />
);

export type PaymentTransactionProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  PaymentTransactionQuery,
  PaymentTransactionQueryVariables
> &
  TChildProps;
export function withPaymentTransaction<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PaymentTransactionQuery,
    PaymentTransactionQueryVariables,
    PaymentTransactionProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PaymentTransactionQuery,
    PaymentTransactionQueryVariables,
    PaymentTransactionProps<TChildProps>
  >(PaymentTransactionDocument, {
    alias: 'paymentTransaction',
    ...operationOptions,
  });
}

/**
 * __usePaymentTransactionQuery__
 *
 * To run a query within a React component, call `usePaymentTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentTransactionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentTransactionQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<PaymentTransactionQuery, PaymentTransactionQueryVariables>
) {
  return ApolloReactHooks.useQuery<PaymentTransactionQuery, PaymentTransactionQueryVariables>(
    PaymentTransactionDocument,
    baseOptions
  );
}
export function usePaymentTransactionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentTransactionQuery, PaymentTransactionQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<PaymentTransactionQuery, PaymentTransactionQueryVariables>(
    PaymentTransactionDocument,
    baseOptions
  );
}
export type PaymentTransactionQueryHookResult = ReturnType<typeof usePaymentTransactionQuery>;
export type PaymentTransactionLazyQueryHookResult = ReturnType<typeof usePaymentTransactionLazyQuery>;
export type PaymentTransactionQueryResult = ApolloReactCommon.QueryResult<
  PaymentTransactionQuery,
  PaymentTransactionQueryVariables
>;
export const RedirectAnalyticsDocument = gql`
  query redirectAnalytics($data: RedirectAnalyticsInput!) {
    redirectAnalytics(data: $data) {
      utmCampaign
      redirectsCount
    }
  }
`;
export type RedirectAnalyticsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<RedirectAnalyticsQuery, RedirectAnalyticsQueryVariables>,
  'query'
> &
  ({ variables: RedirectAnalyticsQueryVariables; skip?: boolean } | { skip: boolean });

export const RedirectAnalyticsComponent = (props: RedirectAnalyticsComponentProps) => (
  <ApolloReactComponents.Query<RedirectAnalyticsQuery, RedirectAnalyticsQueryVariables>
    query={RedirectAnalyticsDocument}
    {...props}
  />
);

export type RedirectAnalyticsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  RedirectAnalyticsQuery,
  RedirectAnalyticsQueryVariables
> &
  TChildProps;
export function withRedirectAnalytics<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RedirectAnalyticsQuery,
    RedirectAnalyticsQueryVariables,
    RedirectAnalyticsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    RedirectAnalyticsQuery,
    RedirectAnalyticsQueryVariables,
    RedirectAnalyticsProps<TChildProps>
  >(RedirectAnalyticsDocument, {
    alias: 'redirectAnalytics',
    ...operationOptions,
  });
}

/**
 * __useRedirectAnalyticsQuery__
 *
 * To run a query within a React component, call `useRedirectAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRedirectAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRedirectAnalyticsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRedirectAnalyticsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<RedirectAnalyticsQuery, RedirectAnalyticsQueryVariables>
) {
  return ApolloReactHooks.useQuery<RedirectAnalyticsQuery, RedirectAnalyticsQueryVariables>(
    RedirectAnalyticsDocument,
    baseOptions
  );
}
export function useRedirectAnalyticsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RedirectAnalyticsQuery, RedirectAnalyticsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<RedirectAnalyticsQuery, RedirectAnalyticsQueryVariables>(
    RedirectAnalyticsDocument,
    baseOptions
  );
}
export type RedirectAnalyticsQueryHookResult = ReturnType<typeof useRedirectAnalyticsQuery>;
export type RedirectAnalyticsLazyQueryHookResult = ReturnType<typeof useRedirectAnalyticsLazyQuery>;
export type RedirectAnalyticsQueryResult = ApolloReactCommon.QueryResult<
  RedirectAnalyticsQuery,
  RedirectAnalyticsQueryVariables
>;
export const SystemLocaleDocument = gql`
  query systemLocale($id: Int!) {
    systemLocale(id: $id) {
      ...systemLocale
    }
  }
  ${SystemLocaleFragmentDoc}
`;
export type SystemLocaleComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<SystemLocaleQuery, SystemLocaleQueryVariables>,
  'query'
> &
  ({ variables: SystemLocaleQueryVariables; skip?: boolean } | { skip: boolean });

export const SystemLocaleComponent = (props: SystemLocaleComponentProps) => (
  <ApolloReactComponents.Query<SystemLocaleQuery, SystemLocaleQueryVariables> query={SystemLocaleDocument} {...props} />
);

export type SystemLocaleProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  SystemLocaleQuery,
  SystemLocaleQueryVariables
> &
  TChildProps;
export function withSystemLocale<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SystemLocaleQuery,
    SystemLocaleQueryVariables,
    SystemLocaleProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SystemLocaleQuery,
    SystemLocaleQueryVariables,
    SystemLocaleProps<TChildProps>
  >(SystemLocaleDocument, {
    alias: 'systemLocale',
    ...operationOptions,
  });
}

/**
 * __useSystemLocaleQuery__
 *
 * To run a query within a React component, call `useSystemLocaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemLocaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemLocaleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSystemLocaleQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SystemLocaleQuery, SystemLocaleQueryVariables>
) {
  return ApolloReactHooks.useQuery<SystemLocaleQuery, SystemLocaleQueryVariables>(SystemLocaleDocument, baseOptions);
}
export function useSystemLocaleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SystemLocaleQuery, SystemLocaleQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<SystemLocaleQuery, SystemLocaleQueryVariables>(
    SystemLocaleDocument,
    baseOptions
  );
}
export type SystemLocaleQueryHookResult = ReturnType<typeof useSystemLocaleQuery>;
export type SystemLocaleLazyQueryHookResult = ReturnType<typeof useSystemLocaleLazyQuery>;
export type SystemLocaleQueryResult = ApolloReactCommon.QueryResult<SystemLocaleQuery, SystemLocaleQueryVariables>;
export const SystemPrinterDocument = gql`
  query systemPrinter($id: Int!) {
    systemPrinter(id: $id) {
      ...systemPrinter
    }
  }
  ${SystemPrinterFragmentDoc}
`;
export type SystemPrinterComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<SystemPrinterQuery, SystemPrinterQueryVariables>,
  'query'
> &
  ({ variables: SystemPrinterQueryVariables; skip?: boolean } | { skip: boolean });

export const SystemPrinterComponent = (props: SystemPrinterComponentProps) => (
  <ApolloReactComponents.Query<SystemPrinterQuery, SystemPrinterQueryVariables>
    query={SystemPrinterDocument}
    {...props}
  />
);

export type SystemPrinterProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  SystemPrinterQuery,
  SystemPrinterQueryVariables
> &
  TChildProps;
export function withSystemPrinter<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SystemPrinterQuery,
    SystemPrinterQueryVariables,
    SystemPrinterProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SystemPrinterQuery,
    SystemPrinterQueryVariables,
    SystemPrinterProps<TChildProps>
  >(SystemPrinterDocument, {
    alias: 'systemPrinter',
    ...operationOptions,
  });
}

/**
 * __useSystemPrinterQuery__
 *
 * To run a query within a React component, call `useSystemPrinterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemPrinterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemPrinterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSystemPrinterQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SystemPrinterQuery, SystemPrinterQueryVariables>
) {
  return ApolloReactHooks.useQuery<SystemPrinterQuery, SystemPrinterQueryVariables>(SystemPrinterDocument, baseOptions);
}
export function useSystemPrinterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SystemPrinterQuery, SystemPrinterQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<SystemPrinterQuery, SystemPrinterQueryVariables>(
    SystemPrinterDocument,
    baseOptions
  );
}
export type SystemPrinterQueryHookResult = ReturnType<typeof useSystemPrinterQuery>;
export type SystemPrinterLazyQueryHookResult = ReturnType<typeof useSystemPrinterLazyQuery>;
export type SystemPrinterQueryResult = ApolloReactCommon.QueryResult<SystemPrinterQuery, SystemPrinterQueryVariables>;
export const SystemUserDocument = gql`
  query systemUser($id: ID!) {
    systemUser(id: $id) {
      ...systemUser
    }
  }
  ${SystemUserFragmentDoc}
`;
export type SystemUserComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<SystemUserQuery, SystemUserQueryVariables>,
  'query'
> &
  ({ variables: SystemUserQueryVariables; skip?: boolean } | { skip: boolean });

export const SystemUserComponent = (props: SystemUserComponentProps) => (
  <ApolloReactComponents.Query<SystemUserQuery, SystemUserQueryVariables> query={SystemUserDocument} {...props} />
);

export type SystemUserProps<TChildProps = {}> = ApolloReactHoc.DataProps<SystemUserQuery, SystemUserQueryVariables> &
  TChildProps;
export function withSystemUser<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SystemUserQuery,
    SystemUserQueryVariables,
    SystemUserProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<TProps, SystemUserQuery, SystemUserQueryVariables, SystemUserProps<TChildProps>>(
    SystemUserDocument,
    {
      alias: 'systemUser',
      ...operationOptions,
    }
  );
}

/**
 * __useSystemUserQuery__
 *
 * To run a query within a React component, call `useSystemUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSystemUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SystemUserQuery, SystemUserQueryVariables>
) {
  return ApolloReactHooks.useQuery<SystemUserQuery, SystemUserQueryVariables>(SystemUserDocument, baseOptions);
}
export function useSystemUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SystemUserQuery, SystemUserQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<SystemUserQuery, SystemUserQueryVariables>(SystemUserDocument, baseOptions);
}
export type SystemUserQueryHookResult = ReturnType<typeof useSystemUserQuery>;
export type SystemUserLazyQueryHookResult = ReturnType<typeof useSystemUserLazyQuery>;
export type SystemUserQueryResult = ApolloReactCommon.QueryResult<SystemUserQuery, SystemUserQueryVariables>;
export const SystemUserAclDocument = gql`
  query systemUserACL($userId: ID!, $clientId: ID!) {
    systemUserACL(userId: $userId, clientId: $clientId) {
      ...acl
    }
  }
  ${AclFragmentDoc}
`;
export type SystemUserAclComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<SystemUserAclQuery, SystemUserAclQueryVariables>,
  'query'
> &
  ({ variables: SystemUserAclQueryVariables; skip?: boolean } | { skip: boolean });

export const SystemUserAclComponent = (props: SystemUserAclComponentProps) => (
  <ApolloReactComponents.Query<SystemUserAclQuery, SystemUserAclQueryVariables>
    query={SystemUserAclDocument}
    {...props}
  />
);

export type SystemUserAclProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  SystemUserAclQuery,
  SystemUserAclQueryVariables
> &
  TChildProps;
export function withSystemUserAcl<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SystemUserAclQuery,
    SystemUserAclQueryVariables,
    SystemUserAclProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SystemUserAclQuery,
    SystemUserAclQueryVariables,
    SystemUserAclProps<TChildProps>
  >(SystemUserAclDocument, {
    alias: 'systemUserAcl',
    ...operationOptions,
  });
}

/**
 * __useSystemUserAclQuery__
 *
 * To run a query within a React component, call `useSystemUserAclQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemUserAclQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemUserAclQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useSystemUserAclQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SystemUserAclQuery, SystemUserAclQueryVariables>
) {
  return ApolloReactHooks.useQuery<SystemUserAclQuery, SystemUserAclQueryVariables>(SystemUserAclDocument, baseOptions);
}
export function useSystemUserAclLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SystemUserAclQuery, SystemUserAclQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<SystemUserAclQuery, SystemUserAclQueryVariables>(
    SystemUserAclDocument,
    baseOptions
  );
}
export type SystemUserAclQueryHookResult = ReturnType<typeof useSystemUserAclQuery>;
export type SystemUserAclLazyQueryHookResult = ReturnType<typeof useSystemUserAclLazyQuery>;
export type SystemUserAclQueryResult = ApolloReactCommon.QueryResult<SystemUserAclQuery, SystemUserAclQueryVariables>;
export const SystemUserProfileDocument = gql`
  query systemUserProfile {
    systemUserProfile {
      ...systemUser
    }
  }
  ${SystemUserFragmentDoc}
`;
export type SystemUserProfileComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<SystemUserProfileQuery, SystemUserProfileQueryVariables>,
  'query'
>;

export const SystemUserProfileComponent = (props: SystemUserProfileComponentProps) => (
  <ApolloReactComponents.Query<SystemUserProfileQuery, SystemUserProfileQueryVariables>
    query={SystemUserProfileDocument}
    {...props}
  />
);

export type SystemUserProfileProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  SystemUserProfileQuery,
  SystemUserProfileQueryVariables
> &
  TChildProps;
export function withSystemUserProfile<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SystemUserProfileQuery,
    SystemUserProfileQueryVariables,
    SystemUserProfileProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SystemUserProfileQuery,
    SystemUserProfileQueryVariables,
    SystemUserProfileProps<TChildProps>
  >(SystemUserProfileDocument, {
    alias: 'systemUserProfile',
    ...operationOptions,
  });
}

/**
 * __useSystemUserProfileQuery__
 *
 * To run a query within a React component, call `useSystemUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useSystemUserProfileQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SystemUserProfileQuery, SystemUserProfileQueryVariables>
) {
  return ApolloReactHooks.useQuery<SystemUserProfileQuery, SystemUserProfileQueryVariables>(
    SystemUserProfileDocument,
    baseOptions
  );
}
export function useSystemUserProfileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SystemUserProfileQuery, SystemUserProfileQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<SystemUserProfileQuery, SystemUserProfileQueryVariables>(
    SystemUserProfileDocument,
    baseOptions
  );
}
export type SystemUserProfileQueryHookResult = ReturnType<typeof useSystemUserProfileQuery>;
export type SystemUserProfileLazyQueryHookResult = ReturnType<typeof useSystemUserProfileLazyQuery>;
export type SystemUserProfileQueryResult = ApolloReactCommon.QueryResult<
  SystemUserProfileQuery,
  SystemUserProfileQueryVariables
>;
export const SystemWebHookDocument = gql`
  query systemWebHook($id: Int!) {
    systemWebHook(id: $id) {
      ...systemWebHook
    }
  }
  ${SystemWebHookFragmentDoc}
`;
export type SystemWebHookComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<SystemWebHookQuery, SystemWebHookQueryVariables>,
  'query'
> &
  ({ variables: SystemWebHookQueryVariables; skip?: boolean } | { skip: boolean });

export const SystemWebHookComponent = (props: SystemWebHookComponentProps) => (
  <ApolloReactComponents.Query<SystemWebHookQuery, SystemWebHookQueryVariables>
    query={SystemWebHookDocument}
    {...props}
  />
);

export type SystemWebHookProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  SystemWebHookQuery,
  SystemWebHookQueryVariables
> &
  TChildProps;
export function withSystemWebHook<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SystemWebHookQuery,
    SystemWebHookQueryVariables,
    SystemWebHookProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SystemWebHookQuery,
    SystemWebHookQueryVariables,
    SystemWebHookProps<TChildProps>
  >(SystemWebHookDocument, {
    alias: 'systemWebHook',
    ...operationOptions,
  });
}

/**
 * __useSystemWebHookQuery__
 *
 * To run a query within a React component, call `useSystemWebHookQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemWebHookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemWebHookQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSystemWebHookQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SystemWebHookQuery, SystemWebHookQueryVariables>
) {
  return ApolloReactHooks.useQuery<SystemWebHookQuery, SystemWebHookQueryVariables>(SystemWebHookDocument, baseOptions);
}
export function useSystemWebHookLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SystemWebHookQuery, SystemWebHookQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<SystemWebHookQuery, SystemWebHookQueryVariables>(
    SystemWebHookDocument,
    baseOptions
  );
}
export type SystemWebHookQueryHookResult = ReturnType<typeof useSystemWebHookQuery>;
export type SystemWebHookLazyQueryHookResult = ReturnType<typeof useSystemWebHookLazyQuery>;
export type SystemWebHookQueryResult = ApolloReactCommon.QueryResult<SystemWebHookQuery, SystemWebHookQueryVariables>;
export const TemplateApplePassDocument = gql`
  query templateApplePass($id: Int!) {
    templateApplePass(id: $id) {
      ...templateApplePass
    }
  }
  ${TemplateApplePassFragmentDoc}
`;
export type TemplateApplePassComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<TemplateApplePassQuery, TemplateApplePassQueryVariables>,
  'query'
> &
  ({ variables: TemplateApplePassQueryVariables; skip?: boolean } | { skip: boolean });

export const TemplateApplePassComponent = (props: TemplateApplePassComponentProps) => (
  <ApolloReactComponents.Query<TemplateApplePassQuery, TemplateApplePassQueryVariables>
    query={TemplateApplePassDocument}
    {...props}
  />
);

export type TemplateApplePassProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  TemplateApplePassQuery,
  TemplateApplePassQueryVariables
> &
  TChildProps;
export function withTemplateApplePass<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TemplateApplePassQuery,
    TemplateApplePassQueryVariables,
    TemplateApplePassProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TemplateApplePassQuery,
    TemplateApplePassQueryVariables,
    TemplateApplePassProps<TChildProps>
  >(TemplateApplePassDocument, {
    alias: 'templateApplePass',
    ...operationOptions,
  });
}

/**
 * __useTemplateApplePassQuery__
 *
 * To run a query within a React component, call `useTemplateApplePassQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateApplePassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateApplePassQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemplateApplePassQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TemplateApplePassQuery, TemplateApplePassQueryVariables>
) {
  return ApolloReactHooks.useQuery<TemplateApplePassQuery, TemplateApplePassQueryVariables>(
    TemplateApplePassDocument,
    baseOptions
  );
}
export function useTemplateApplePassLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TemplateApplePassQuery, TemplateApplePassQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<TemplateApplePassQuery, TemplateApplePassQueryVariables>(
    TemplateApplePassDocument,
    baseOptions
  );
}
export type TemplateApplePassQueryHookResult = ReturnType<typeof useTemplateApplePassQuery>;
export type TemplateApplePassLazyQueryHookResult = ReturnType<typeof useTemplateApplePassLazyQuery>;
export type TemplateApplePassQueryResult = ApolloReactCommon.QueryResult<
  TemplateApplePassQuery,
  TemplateApplePassQueryVariables
>;
export const TemplatePdfDocument = gql`
  query templatePDF($id: Int!) {
    templatePDF(id: $id) {
      ...templatePDF
    }
  }
  ${TemplatePdfFragmentDoc}
`;
export type TemplatePdfComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<TemplatePdfQuery, TemplatePdfQueryVariables>,
  'query'
> &
  ({ variables: TemplatePdfQueryVariables; skip?: boolean } | { skip: boolean });

export const TemplatePdfComponent = (props: TemplatePdfComponentProps) => (
  <ApolloReactComponents.Query<TemplatePdfQuery, TemplatePdfQueryVariables> query={TemplatePdfDocument} {...props} />
);

export type TemplatePdfProps<TChildProps = {}> = ApolloReactHoc.DataProps<TemplatePdfQuery, TemplatePdfQueryVariables> &
  TChildProps;
export function withTemplatePdf<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TemplatePdfQuery,
    TemplatePdfQueryVariables,
    TemplatePdfProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<TProps, TemplatePdfQuery, TemplatePdfQueryVariables, TemplatePdfProps<TChildProps>>(
    TemplatePdfDocument,
    {
      alias: 'templatePdf',
      ...operationOptions,
    }
  );
}

/**
 * __useTemplatePdfQuery__
 *
 * To run a query within a React component, call `useTemplatePdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatePdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatePdfQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemplatePdfQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TemplatePdfQuery, TemplatePdfQueryVariables>
) {
  return ApolloReactHooks.useQuery<TemplatePdfQuery, TemplatePdfQueryVariables>(TemplatePdfDocument, baseOptions);
}
export function useTemplatePdfLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TemplatePdfQuery, TemplatePdfQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<TemplatePdfQuery, TemplatePdfQueryVariables>(TemplatePdfDocument, baseOptions);
}
export type TemplatePdfQueryHookResult = ReturnType<typeof useTemplatePdfQuery>;
export type TemplatePdfLazyQueryHookResult = ReturnType<typeof useTemplatePdfLazyQuery>;
export type TemplatePdfQueryResult = ApolloReactCommon.QueryResult<TemplatePdfQuery, TemplatePdfQueryVariables>;
export const TournamentDocument = gql`
  query tournament($id: Int!) {
    tournament(id: $id) {
      ...tournament
    }
  }
  ${TournamentFragmentDoc}
`;
export type TournamentComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<TournamentQuery, TournamentQueryVariables>,
  'query'
> &
  ({ variables: TournamentQueryVariables; skip?: boolean } | { skip: boolean });

export const TournamentComponent = (props: TournamentComponentProps) => (
  <ApolloReactComponents.Query<TournamentQuery, TournamentQueryVariables> query={TournamentDocument} {...props} />
);

export type TournamentProps<TChildProps = {}> = ApolloReactHoc.DataProps<TournamentQuery, TournamentQueryVariables> &
  TChildProps;
export function withTournament<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TournamentQuery,
    TournamentQueryVariables,
    TournamentProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<TProps, TournamentQuery, TournamentQueryVariables, TournamentProps<TChildProps>>(
    TournamentDocument,
    {
      alias: 'tournament',
      ...operationOptions,
    }
  );
}

/**
 * __useTournamentQuery__
 *
 * To run a query within a React component, call `useTournamentQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TournamentQuery, TournamentQueryVariables>
) {
  return ApolloReactHooks.useQuery<TournamentQuery, TournamentQueryVariables>(TournamentDocument, baseOptions);
}
export function useTournamentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentQuery, TournamentQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<TournamentQuery, TournamentQueryVariables>(TournamentDocument, baseOptions);
}
export type TournamentQueryHookResult = ReturnType<typeof useTournamentQuery>;
export type TournamentLazyQueryHookResult = ReturnType<typeof useTournamentLazyQuery>;
export type TournamentQueryResult = ApolloReactCommon.QueryResult<TournamentQuery, TournamentQueryVariables>;
export const TournamentDisputeDocument = gql`
  query tournamentDispute($id: Int!) {
    tournamentDispute(id: $id) {
      ...tournamentDispute
    }
  }
  ${TournamentDisputeFragmentDoc}
`;
export type TournamentDisputeComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<TournamentDisputeQuery, TournamentDisputeQueryVariables>,
  'query'
> &
  ({ variables: TournamentDisputeQueryVariables; skip?: boolean } | { skip: boolean });

export const TournamentDisputeComponent = (props: TournamentDisputeComponentProps) => (
  <ApolloReactComponents.Query<TournamentDisputeQuery, TournamentDisputeQueryVariables>
    query={TournamentDisputeDocument}
    {...props}
  />
);

export type TournamentDisputeProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  TournamentDisputeQuery,
  TournamentDisputeQueryVariables
> &
  TChildProps;
export function withTournamentDispute<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TournamentDisputeQuery,
    TournamentDisputeQueryVariables,
    TournamentDisputeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TournamentDisputeQuery,
    TournamentDisputeQueryVariables,
    TournamentDisputeProps<TChildProps>
  >(TournamentDisputeDocument, {
    alias: 'tournamentDispute',
    ...operationOptions,
  });
}

/**
 * __useTournamentDisputeQuery__
 *
 * To run a query within a React component, call `useTournamentDisputeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentDisputeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentDisputeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentDisputeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TournamentDisputeQuery, TournamentDisputeQueryVariables>
) {
  return ApolloReactHooks.useQuery<TournamentDisputeQuery, TournamentDisputeQueryVariables>(
    TournamentDisputeDocument,
    baseOptions
  );
}
export function useTournamentDisputeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentDisputeQuery, TournamentDisputeQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<TournamentDisputeQuery, TournamentDisputeQueryVariables>(
    TournamentDisputeDocument,
    baseOptions
  );
}
export type TournamentDisputeQueryHookResult = ReturnType<typeof useTournamentDisputeQuery>;
export type TournamentDisputeLazyQueryHookResult = ReturnType<typeof useTournamentDisputeLazyQuery>;
export type TournamentDisputeQueryResult = ApolloReactCommon.QueryResult<
  TournamentDisputeQuery,
  TournamentDisputeQueryVariables
>;
export const TournamentDisqualificationDocument = gql`
  query tournamentDisqualification($id: Int!) {
    tournamentDisqualification(id: $id) {
      ...tournamentDisqualificationFull
    }
  }
  ${TournamentDisqualificationFullFragmentDoc}
`;
export type TournamentDisqualificationComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    TournamentDisqualificationQuery,
    TournamentDisqualificationQueryVariables
  >,
  'query'
> &
  ({ variables: TournamentDisqualificationQueryVariables; skip?: boolean } | { skip: boolean });

export const TournamentDisqualificationComponent = (props: TournamentDisqualificationComponentProps) => (
  <ApolloReactComponents.Query<TournamentDisqualificationQuery, TournamentDisqualificationQueryVariables>
    query={TournamentDisqualificationDocument}
    {...props}
  />
);

export type TournamentDisqualificationProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  TournamentDisqualificationQuery,
  TournamentDisqualificationQueryVariables
> &
  TChildProps;
export function withTournamentDisqualification<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TournamentDisqualificationQuery,
    TournamentDisqualificationQueryVariables,
    TournamentDisqualificationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TournamentDisqualificationQuery,
    TournamentDisqualificationQueryVariables,
    TournamentDisqualificationProps<TChildProps>
  >(TournamentDisqualificationDocument, {
    alias: 'tournamentDisqualification',
    ...operationOptions,
  });
}

/**
 * __useTournamentDisqualificationQuery__
 *
 * To run a query within a React component, call `useTournamentDisqualificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentDisqualificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentDisqualificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentDisqualificationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TournamentDisqualificationQuery,
    TournamentDisqualificationQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<TournamentDisqualificationQuery, TournamentDisqualificationQueryVariables>(
    TournamentDisqualificationDocument,
    baseOptions
  );
}
export function useTournamentDisqualificationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TournamentDisqualificationQuery,
    TournamentDisqualificationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<TournamentDisqualificationQuery, TournamentDisqualificationQueryVariables>(
    TournamentDisqualificationDocument,
    baseOptions
  );
}
export type TournamentDisqualificationQueryHookResult = ReturnType<typeof useTournamentDisqualificationQuery>;
export type TournamentDisqualificationLazyQueryHookResult = ReturnType<typeof useTournamentDisqualificationLazyQuery>;
export type TournamentDisqualificationQueryResult = ApolloReactCommon.QueryResult<
  TournamentDisqualificationQuery,
  TournamentDisqualificationQueryVariables
>;
export const TournamentMatchDocument = gql`
  query tournamentMatch($id: Int!) {
    tournamentMatch(id: $id) {
      ...tournamentMatchDetails
    }
  }
  ${TournamentMatchDetailsFragmentDoc}
`;
export type TournamentMatchComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<TournamentMatchQuery, TournamentMatchQueryVariables>,
  'query'
> &
  ({ variables: TournamentMatchQueryVariables; skip?: boolean } | { skip: boolean });

export const TournamentMatchComponent = (props: TournamentMatchComponentProps) => (
  <ApolloReactComponents.Query<TournamentMatchQuery, TournamentMatchQueryVariables>
    query={TournamentMatchDocument}
    {...props}
  />
);

export type TournamentMatchProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  TournamentMatchQuery,
  TournamentMatchQueryVariables
> &
  TChildProps;
export function withTournamentMatch<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TournamentMatchQuery,
    TournamentMatchQueryVariables,
    TournamentMatchProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TournamentMatchQuery,
    TournamentMatchQueryVariables,
    TournamentMatchProps<TChildProps>
  >(TournamentMatchDocument, {
    alias: 'tournamentMatch',
    ...operationOptions,
  });
}

/**
 * __useTournamentMatchQuery__
 *
 * To run a query within a React component, call `useTournamentMatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentMatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentMatchQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentMatchQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TournamentMatchQuery, TournamentMatchQueryVariables>
) {
  return ApolloReactHooks.useQuery<TournamentMatchQuery, TournamentMatchQueryVariables>(
    TournamentMatchDocument,
    baseOptions
  );
}
export function useTournamentMatchLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentMatchQuery, TournamentMatchQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<TournamentMatchQuery, TournamentMatchQueryVariables>(
    TournamentMatchDocument,
    baseOptions
  );
}
export type TournamentMatchQueryHookResult = ReturnType<typeof useTournamentMatchQuery>;
export type TournamentMatchLazyQueryHookResult = ReturnType<typeof useTournamentMatchLazyQuery>;
export type TournamentMatchQueryResult = ApolloReactCommon.QueryResult<
  TournamentMatchQuery,
  TournamentMatchQueryVariables
>;
export const TournamentMatchEventDocument = gql`
  query tournamentMatchEvent($id: Int!) {
    tournamentMatchEvent(id: $id) {
      ...tournamentMatchEvent
    }
  }
  ${TournamentMatchEventFragmentDoc}
`;
export type TournamentMatchEventComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<TournamentMatchEventQuery, TournamentMatchEventQueryVariables>,
  'query'
> &
  ({ variables: TournamentMatchEventQueryVariables; skip?: boolean } | { skip: boolean });

export const TournamentMatchEventComponent = (props: TournamentMatchEventComponentProps) => (
  <ApolloReactComponents.Query<TournamentMatchEventQuery, TournamentMatchEventQueryVariables>
    query={TournamentMatchEventDocument}
    {...props}
  />
);

export type TournamentMatchEventProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  TournamentMatchEventQuery,
  TournamentMatchEventQueryVariables
> &
  TChildProps;
export function withTournamentMatchEvent<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TournamentMatchEventQuery,
    TournamentMatchEventQueryVariables,
    TournamentMatchEventProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TournamentMatchEventQuery,
    TournamentMatchEventQueryVariables,
    TournamentMatchEventProps<TChildProps>
  >(TournamentMatchEventDocument, {
    alias: 'tournamentMatchEvent',
    ...operationOptions,
  });
}

/**
 * __useTournamentMatchEventQuery__
 *
 * To run a query within a React component, call `useTournamentMatchEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentMatchEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentMatchEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentMatchEventQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TournamentMatchEventQuery, TournamentMatchEventQueryVariables>
) {
  return ApolloReactHooks.useQuery<TournamentMatchEventQuery, TournamentMatchEventQueryVariables>(
    TournamentMatchEventDocument,
    baseOptions
  );
}
export function useTournamentMatchEventLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentMatchEventQuery, TournamentMatchEventQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<TournamentMatchEventQuery, TournamentMatchEventQueryVariables>(
    TournamentMatchEventDocument,
    baseOptions
  );
}
export type TournamentMatchEventQueryHookResult = ReturnType<typeof useTournamentMatchEventQuery>;
export type TournamentMatchEventLazyQueryHookResult = ReturnType<typeof useTournamentMatchEventLazyQuery>;
export type TournamentMatchEventQueryResult = ApolloReactCommon.QueryResult<
  TournamentMatchEventQuery,
  TournamentMatchEventQueryVariables
>;
export const TournamentMatchLineUpsDocument = gql`
  query tournamentMatchLineUps($query: DefaultQueryInput!) {
    tournamentMatchLineUps(query: $query) {
      ...tournamentMatchLineUp
    }
  }
  ${TournamentMatchLineUpFragmentDoc}
`;
export type TournamentMatchLineUpsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<TournamentMatchLineUpsQuery, TournamentMatchLineUpsQueryVariables>,
  'query'
> &
  ({ variables: TournamentMatchLineUpsQueryVariables; skip?: boolean } | { skip: boolean });

export const TournamentMatchLineUpsComponent = (props: TournamentMatchLineUpsComponentProps) => (
  <ApolloReactComponents.Query<TournamentMatchLineUpsQuery, TournamentMatchLineUpsQueryVariables>
    query={TournamentMatchLineUpsDocument}
    {...props}
  />
);

export type TournamentMatchLineUpsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  TournamentMatchLineUpsQuery,
  TournamentMatchLineUpsQueryVariables
> &
  TChildProps;
export function withTournamentMatchLineUps<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TournamentMatchLineUpsQuery,
    TournamentMatchLineUpsQueryVariables,
    TournamentMatchLineUpsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TournamentMatchLineUpsQuery,
    TournamentMatchLineUpsQueryVariables,
    TournamentMatchLineUpsProps<TChildProps>
  >(TournamentMatchLineUpsDocument, {
    alias: 'tournamentMatchLineUps',
    ...operationOptions,
  });
}

/**
 * __useTournamentMatchLineUpsQuery__
 *
 * To run a query within a React component, call `useTournamentMatchLineUpsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentMatchLineUpsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentMatchLineUpsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useTournamentMatchLineUpsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TournamentMatchLineUpsQuery, TournamentMatchLineUpsQueryVariables>
) {
  return ApolloReactHooks.useQuery<TournamentMatchLineUpsQuery, TournamentMatchLineUpsQueryVariables>(
    TournamentMatchLineUpsDocument,
    baseOptions
  );
}
export function useTournamentMatchLineUpsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentMatchLineUpsQuery, TournamentMatchLineUpsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<TournamentMatchLineUpsQuery, TournamentMatchLineUpsQueryVariables>(
    TournamentMatchLineUpsDocument,
    baseOptions
  );
}
export type TournamentMatchLineUpsQueryHookResult = ReturnType<typeof useTournamentMatchLineUpsQuery>;
export type TournamentMatchLineUpsLazyQueryHookResult = ReturnType<typeof useTournamentMatchLineUpsLazyQuery>;
export type TournamentMatchLineUpsQueryResult = ApolloReactCommon.QueryResult<
  TournamentMatchLineUpsQuery,
  TournamentMatchLineUpsQueryVariables
>;
export const TournamentMatchProtocolDocument = gql`
  query tournamentMatchProtocol($id: Int!) {
    tournamentMatchProtocol(id: $id) {
      ...tournamentMatchProtocol
    }
  }
  ${TournamentMatchProtocolFragmentDoc}
`;
export type TournamentMatchProtocolComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<TournamentMatchProtocolQuery, TournamentMatchProtocolQueryVariables>,
  'query'
> &
  ({ variables: TournamentMatchProtocolQueryVariables; skip?: boolean } | { skip: boolean });

export const TournamentMatchProtocolComponent = (props: TournamentMatchProtocolComponentProps) => (
  <ApolloReactComponents.Query<TournamentMatchProtocolQuery, TournamentMatchProtocolQueryVariables>
    query={TournamentMatchProtocolDocument}
    {...props}
  />
);

export type TournamentMatchProtocolProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  TournamentMatchProtocolQuery,
  TournamentMatchProtocolQueryVariables
> &
  TChildProps;
export function withTournamentMatchProtocol<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TournamentMatchProtocolQuery,
    TournamentMatchProtocolQueryVariables,
    TournamentMatchProtocolProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TournamentMatchProtocolQuery,
    TournamentMatchProtocolQueryVariables,
    TournamentMatchProtocolProps<TChildProps>
  >(TournamentMatchProtocolDocument, {
    alias: 'tournamentMatchProtocol',
    ...operationOptions,
  });
}

/**
 * __useTournamentMatchProtocolQuery__
 *
 * To run a query within a React component, call `useTournamentMatchProtocolQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentMatchProtocolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentMatchProtocolQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentMatchProtocolQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TournamentMatchProtocolQuery, TournamentMatchProtocolQueryVariables>
) {
  return ApolloReactHooks.useQuery<TournamentMatchProtocolQuery, TournamentMatchProtocolQueryVariables>(
    TournamentMatchProtocolDocument,
    baseOptions
  );
}
export function useTournamentMatchProtocolLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TournamentMatchProtocolQuery,
    TournamentMatchProtocolQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<TournamentMatchProtocolQuery, TournamentMatchProtocolQueryVariables>(
    TournamentMatchProtocolDocument,
    baseOptions
  );
}
export type TournamentMatchProtocolQueryHookResult = ReturnType<typeof useTournamentMatchProtocolQuery>;
export type TournamentMatchProtocolLazyQueryHookResult = ReturnType<typeof useTournamentMatchProtocolLazyQuery>;
export type TournamentMatchProtocolQueryResult = ApolloReactCommon.QueryResult<
  TournamentMatchProtocolQuery,
  TournamentMatchProtocolQueryVariables
>;
export const TournamentPlayerStatDocument = gql`
  query tournamentPlayerStat($id: Int!) {
    tournamentPlayerStat(id: $id) {
      ...tournamentPlayerStatFull
    }
  }
  ${TournamentPlayerStatFullFragmentDoc}
`;
export type TournamentPlayerStatComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<TournamentPlayerStatQuery, TournamentPlayerStatQueryVariables>,
  'query'
> &
  ({ variables: TournamentPlayerStatQueryVariables; skip?: boolean } | { skip: boolean });

export const TournamentPlayerStatComponent = (props: TournamentPlayerStatComponentProps) => (
  <ApolloReactComponents.Query<TournamentPlayerStatQuery, TournamentPlayerStatQueryVariables>
    query={TournamentPlayerStatDocument}
    {...props}
  />
);

export type TournamentPlayerStatProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  TournamentPlayerStatQuery,
  TournamentPlayerStatQueryVariables
> &
  TChildProps;
export function withTournamentPlayerStat<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TournamentPlayerStatQuery,
    TournamentPlayerStatQueryVariables,
    TournamentPlayerStatProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TournamentPlayerStatQuery,
    TournamentPlayerStatQueryVariables,
    TournamentPlayerStatProps<TChildProps>
  >(TournamentPlayerStatDocument, {
    alias: 'tournamentPlayerStat',
    ...operationOptions,
  });
}

/**
 * __useTournamentPlayerStatQuery__
 *
 * To run a query within a React component, call `useTournamentPlayerStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentPlayerStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentPlayerStatQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentPlayerStatQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TournamentPlayerStatQuery, TournamentPlayerStatQueryVariables>
) {
  return ApolloReactHooks.useQuery<TournamentPlayerStatQuery, TournamentPlayerStatQueryVariables>(
    TournamentPlayerStatDocument,
    baseOptions
  );
}
export function useTournamentPlayerStatLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentPlayerStatQuery, TournamentPlayerStatQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<TournamentPlayerStatQuery, TournamentPlayerStatQueryVariables>(
    TournamentPlayerStatDocument,
    baseOptions
  );
}
export type TournamentPlayerStatQueryHookResult = ReturnType<typeof useTournamentPlayerStatQuery>;
export type TournamentPlayerStatLazyQueryHookResult = ReturnType<typeof useTournamentPlayerStatLazyQuery>;
export type TournamentPlayerStatQueryResult = ApolloReactCommon.QueryResult<
  TournamentPlayerStatQuery,
  TournamentPlayerStatQueryVariables
>;
export const TournamentSeasonDocument = gql`
  query tournamentSeason($id: Int!) {
    tournamentSeason(id: $id) {
      ...tournamentSeasonFull
    }
  }
  ${TournamentSeasonFullFragmentDoc}
`;
export type TournamentSeasonComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<TournamentSeasonQuery, TournamentSeasonQueryVariables>,
  'query'
> &
  ({ variables: TournamentSeasonQueryVariables; skip?: boolean } | { skip: boolean });

export const TournamentSeasonComponent = (props: TournamentSeasonComponentProps) => (
  <ApolloReactComponents.Query<TournamentSeasonQuery, TournamentSeasonQueryVariables>
    query={TournamentSeasonDocument}
    {...props}
  />
);

export type TournamentSeasonProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  TournamentSeasonQuery,
  TournamentSeasonQueryVariables
> &
  TChildProps;
export function withTournamentSeason<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TournamentSeasonQuery,
    TournamentSeasonQueryVariables,
    TournamentSeasonProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TournamentSeasonQuery,
    TournamentSeasonQueryVariables,
    TournamentSeasonProps<TChildProps>
  >(TournamentSeasonDocument, {
    alias: 'tournamentSeason',
    ...operationOptions,
  });
}

/**
 * __useTournamentSeasonQuery__
 *
 * To run a query within a React component, call `useTournamentSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentSeasonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentSeasonQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TournamentSeasonQuery, TournamentSeasonQueryVariables>
) {
  return ApolloReactHooks.useQuery<TournamentSeasonQuery, TournamentSeasonQueryVariables>(
    TournamentSeasonDocument,
    baseOptions
  );
}
export function useTournamentSeasonLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentSeasonQuery, TournamentSeasonQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<TournamentSeasonQuery, TournamentSeasonQueryVariables>(
    TournamentSeasonDocument,
    baseOptions
  );
}
export type TournamentSeasonQueryHookResult = ReturnType<typeof useTournamentSeasonQuery>;
export type TournamentSeasonLazyQueryHookResult = ReturnType<typeof useTournamentSeasonLazyQuery>;
export type TournamentSeasonQueryResult = ApolloReactCommon.QueryResult<
  TournamentSeasonQuery,
  TournamentSeasonQueryVariables
>;
export const TournamentSeasonTeamDocument = gql`
  query tournamentSeasonTeam($id: Int!) {
    tournamentSeasonTeam(id: $id) {
      ...tournamentSeasonTeam
    }
  }
  ${TournamentSeasonTeamFragmentDoc}
`;
export type TournamentSeasonTeamComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<TournamentSeasonTeamQuery, TournamentSeasonTeamQueryVariables>,
  'query'
> &
  ({ variables: TournamentSeasonTeamQueryVariables; skip?: boolean } | { skip: boolean });

export const TournamentSeasonTeamComponent = (props: TournamentSeasonTeamComponentProps) => (
  <ApolloReactComponents.Query<TournamentSeasonTeamQuery, TournamentSeasonTeamQueryVariables>
    query={TournamentSeasonTeamDocument}
    {...props}
  />
);

export type TournamentSeasonTeamProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  TournamentSeasonTeamQuery,
  TournamentSeasonTeamQueryVariables
> &
  TChildProps;
export function withTournamentSeasonTeam<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TournamentSeasonTeamQuery,
    TournamentSeasonTeamQueryVariables,
    TournamentSeasonTeamProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TournamentSeasonTeamQuery,
    TournamentSeasonTeamQueryVariables,
    TournamentSeasonTeamProps<TChildProps>
  >(TournamentSeasonTeamDocument, {
    alias: 'tournamentSeasonTeam',
    ...operationOptions,
  });
}

/**
 * __useTournamentSeasonTeamQuery__
 *
 * To run a query within a React component, call `useTournamentSeasonTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentSeasonTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentSeasonTeamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentSeasonTeamQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TournamentSeasonTeamQuery, TournamentSeasonTeamQueryVariables>
) {
  return ApolloReactHooks.useQuery<TournamentSeasonTeamQuery, TournamentSeasonTeamQueryVariables>(
    TournamentSeasonTeamDocument,
    baseOptions
  );
}
export function useTournamentSeasonTeamLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentSeasonTeamQuery, TournamentSeasonTeamQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<TournamentSeasonTeamQuery, TournamentSeasonTeamQueryVariables>(
    TournamentSeasonTeamDocument,
    baseOptions
  );
}
export type TournamentSeasonTeamQueryHookResult = ReturnType<typeof useTournamentSeasonTeamQuery>;
export type TournamentSeasonTeamLazyQueryHookResult = ReturnType<typeof useTournamentSeasonTeamLazyQuery>;
export type TournamentSeasonTeamQueryResult = ApolloReactCommon.QueryResult<
  TournamentSeasonTeamQuery,
  TournamentSeasonTeamQueryVariables
>;
export const TournamentSeasonTeamStatDocument = gql`
  query tournamentSeasonTeamStat($id: Int!) {
    tournamentSeasonTeamStat(id: $id) {
      ...tournamentSeasonTeamStat
    }
  }
  ${TournamentSeasonTeamStatFragmentDoc}
`;
export type TournamentSeasonTeamStatComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<TournamentSeasonTeamStatQuery, TournamentSeasonTeamStatQueryVariables>,
  'query'
> &
  ({ variables: TournamentSeasonTeamStatQueryVariables; skip?: boolean } | { skip: boolean });

export const TournamentSeasonTeamStatComponent = (props: TournamentSeasonTeamStatComponentProps) => (
  <ApolloReactComponents.Query<TournamentSeasonTeamStatQuery, TournamentSeasonTeamStatQueryVariables>
    query={TournamentSeasonTeamStatDocument}
    {...props}
  />
);

export type TournamentSeasonTeamStatProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  TournamentSeasonTeamStatQuery,
  TournamentSeasonTeamStatQueryVariables
> &
  TChildProps;
export function withTournamentSeasonTeamStat<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TournamentSeasonTeamStatQuery,
    TournamentSeasonTeamStatQueryVariables,
    TournamentSeasonTeamStatProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TournamentSeasonTeamStatQuery,
    TournamentSeasonTeamStatQueryVariables,
    TournamentSeasonTeamStatProps<TChildProps>
  >(TournamentSeasonTeamStatDocument, {
    alias: 'tournamentSeasonTeamStat',
    ...operationOptions,
  });
}

/**
 * __useTournamentSeasonTeamStatQuery__
 *
 * To run a query within a React component, call `useTournamentSeasonTeamStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentSeasonTeamStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentSeasonTeamStatQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentSeasonTeamStatQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TournamentSeasonTeamStatQuery, TournamentSeasonTeamStatQueryVariables>
) {
  return ApolloReactHooks.useQuery<TournamentSeasonTeamStatQuery, TournamentSeasonTeamStatQueryVariables>(
    TournamentSeasonTeamStatDocument,
    baseOptions
  );
}
export function useTournamentSeasonTeamStatLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TournamentSeasonTeamStatQuery,
    TournamentSeasonTeamStatQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<TournamentSeasonTeamStatQuery, TournamentSeasonTeamStatQueryVariables>(
    TournamentSeasonTeamStatDocument,
    baseOptions
  );
}
export type TournamentSeasonTeamStatQueryHookResult = ReturnType<typeof useTournamentSeasonTeamStatQuery>;
export type TournamentSeasonTeamStatLazyQueryHookResult = ReturnType<typeof useTournamentSeasonTeamStatLazyQuery>;
export type TournamentSeasonTeamStatQueryResult = ApolloReactCommon.QueryResult<
  TournamentSeasonTeamStatQuery,
  TournamentSeasonTeamStatQueryVariables
>;
export const TournamentStadiumDocument = gql`
  query tournamentStadium($id: Int!) {
    tournamentStadium(id: $id) {
      ...tournamentStadium
    }
  }
  ${TournamentStadiumFragmentDoc}
`;
export type TournamentStadiumComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<TournamentStadiumQuery, TournamentStadiumQueryVariables>,
  'query'
> &
  ({ variables: TournamentStadiumQueryVariables; skip?: boolean } | { skip: boolean });

export const TournamentStadiumComponent = (props: TournamentStadiumComponentProps) => (
  <ApolloReactComponents.Query<TournamentStadiumQuery, TournamentStadiumQueryVariables>
    query={TournamentStadiumDocument}
    {...props}
  />
);

export type TournamentStadiumProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  TournamentStadiumQuery,
  TournamentStadiumQueryVariables
> &
  TChildProps;
export function withTournamentStadium<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TournamentStadiumQuery,
    TournamentStadiumQueryVariables,
    TournamentStadiumProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TournamentStadiumQuery,
    TournamentStadiumQueryVariables,
    TournamentStadiumProps<TChildProps>
  >(TournamentStadiumDocument, {
    alias: 'tournamentStadium',
    ...operationOptions,
  });
}

/**
 * __useTournamentStadiumQuery__
 *
 * To run a query within a React component, call `useTournamentStadiumQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentStadiumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentStadiumQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentStadiumQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TournamentStadiumQuery, TournamentStadiumQueryVariables>
) {
  return ApolloReactHooks.useQuery<TournamentStadiumQuery, TournamentStadiumQueryVariables>(
    TournamentStadiumDocument,
    baseOptions
  );
}
export function useTournamentStadiumLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentStadiumQuery, TournamentStadiumQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<TournamentStadiumQuery, TournamentStadiumQueryVariables>(
    TournamentStadiumDocument,
    baseOptions
  );
}
export type TournamentStadiumQueryHookResult = ReturnType<typeof useTournamentStadiumQuery>;
export type TournamentStadiumLazyQueryHookResult = ReturnType<typeof useTournamentStadiumLazyQuery>;
export type TournamentStadiumQueryResult = ApolloReactCommon.QueryResult<
  TournamentStadiumQuery,
  TournamentStadiumQueryVariables
>;
export const TournamentTeamDocument = gql`
  query tournamentTeam($id: Int!) {
    tournamentTeam(id: $id) {
      ...tournamentTeamDetails
    }
  }
  ${TournamentTeamDetailsFragmentDoc}
`;
export type TournamentTeamComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<TournamentTeamQuery, TournamentTeamQueryVariables>,
  'query'
> &
  ({ variables: TournamentTeamQueryVariables; skip?: boolean } | { skip: boolean });

export const TournamentTeamComponent = (props: TournamentTeamComponentProps) => (
  <ApolloReactComponents.Query<TournamentTeamQuery, TournamentTeamQueryVariables>
    query={TournamentTeamDocument}
    {...props}
  />
);

export type TournamentTeamProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  TournamentTeamQuery,
  TournamentTeamQueryVariables
> &
  TChildProps;
export function withTournamentTeam<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TournamentTeamQuery,
    TournamentTeamQueryVariables,
    TournamentTeamProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TournamentTeamQuery,
    TournamentTeamQueryVariables,
    TournamentTeamProps<TChildProps>
  >(TournamentTeamDocument, {
    alias: 'tournamentTeam',
    ...operationOptions,
  });
}

/**
 * __useTournamentTeamQuery__
 *
 * To run a query within a React component, call `useTournamentTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentTeamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentTeamQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TournamentTeamQuery, TournamentTeamQueryVariables>
) {
  return ApolloReactHooks.useQuery<TournamentTeamQuery, TournamentTeamQueryVariables>(
    TournamentTeamDocument,
    baseOptions
  );
}
export function useTournamentTeamLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentTeamQuery, TournamentTeamQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<TournamentTeamQuery, TournamentTeamQueryVariables>(
    TournamentTeamDocument,
    baseOptions
  );
}
export type TournamentTeamQueryHookResult = ReturnType<typeof useTournamentTeamQuery>;
export type TournamentTeamLazyQueryHookResult = ReturnType<typeof useTournamentTeamLazyQuery>;
export type TournamentTeamQueryResult = ApolloReactCommon.QueryResult<
  TournamentTeamQuery,
  TournamentTeamQueryVariables
>;
export const TournamentTeamMemberDocument = gql`
  query tournamentTeamMember($id: Int!) {
    tournamentTeamMember(id: $id) {
      ...tournamentTeamMemberFull
    }
  }
  ${TournamentTeamMemberFullFragmentDoc}
`;
export type TournamentTeamMemberComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<TournamentTeamMemberQuery, TournamentTeamMemberQueryVariables>,
  'query'
> &
  ({ variables: TournamentTeamMemberQueryVariables; skip?: boolean } | { skip: boolean });

export const TournamentTeamMemberComponent = (props: TournamentTeamMemberComponentProps) => (
  <ApolloReactComponents.Query<TournamentTeamMemberQuery, TournamentTeamMemberQueryVariables>
    query={TournamentTeamMemberDocument}
    {...props}
  />
);

export type TournamentTeamMemberProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  TournamentTeamMemberQuery,
  TournamentTeamMemberQueryVariables
> &
  TChildProps;
export function withTournamentTeamMember<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TournamentTeamMemberQuery,
    TournamentTeamMemberQueryVariables,
    TournamentTeamMemberProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TournamentTeamMemberQuery,
    TournamentTeamMemberQueryVariables,
    TournamentTeamMemberProps<TChildProps>
  >(TournamentTeamMemberDocument, {
    alias: 'tournamentTeamMember',
    ...operationOptions,
  });
}

/**
 * __useTournamentTeamMemberQuery__
 *
 * To run a query within a React component, call `useTournamentTeamMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentTeamMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentTeamMemberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentTeamMemberQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TournamentTeamMemberQuery, TournamentTeamMemberQueryVariables>
) {
  return ApolloReactHooks.useQuery<TournamentTeamMemberQuery, TournamentTeamMemberQueryVariables>(
    TournamentTeamMemberDocument,
    baseOptions
  );
}
export function useTournamentTeamMemberLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentTeamMemberQuery, TournamentTeamMemberQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<TournamentTeamMemberQuery, TournamentTeamMemberQueryVariables>(
    TournamentTeamMemberDocument,
    baseOptions
  );
}
export type TournamentTeamMemberQueryHookResult = ReturnType<typeof useTournamentTeamMemberQuery>;
export type TournamentTeamMemberLazyQueryHookResult = ReturnType<typeof useTournamentTeamMemberLazyQuery>;
export type TournamentTeamMemberQueryResult = ApolloReactCommon.QueryResult<
  TournamentTeamMemberQuery,
  TournamentTeamMemberQueryVariables
>;
export const TournamentTourDocument = gql`
  query tournamentTour($id: Int!) {
    tournamentTour(id: $id) {
      ...tournamentTour
    }
  }
  ${TournamentTourFragmentDoc}
`;
export type TournamentTourComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<TournamentTourQuery, TournamentTourQueryVariables>,
  'query'
> &
  ({ variables: TournamentTourQueryVariables; skip?: boolean } | { skip: boolean });

export const TournamentTourComponent = (props: TournamentTourComponentProps) => (
  <ApolloReactComponents.Query<TournamentTourQuery, TournamentTourQueryVariables>
    query={TournamentTourDocument}
    {...props}
  />
);

export type TournamentTourProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  TournamentTourQuery,
  TournamentTourQueryVariables
> &
  TChildProps;
export function withTournamentTour<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TournamentTourQuery,
    TournamentTourQueryVariables,
    TournamentTourProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TournamentTourQuery,
    TournamentTourQueryVariables,
    TournamentTourProps<TChildProps>
  >(TournamentTourDocument, {
    alias: 'tournamentTour',
    ...operationOptions,
  });
}

/**
 * __useTournamentTourQuery__
 *
 * To run a query within a React component, call `useTournamentTourQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentTourQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentTourQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentTourQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TournamentTourQuery, TournamentTourQueryVariables>
) {
  return ApolloReactHooks.useQuery<TournamentTourQuery, TournamentTourQueryVariables>(
    TournamentTourDocument,
    baseOptions
  );
}
export function useTournamentTourLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentTourQuery, TournamentTourQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<TournamentTourQuery, TournamentTourQueryVariables>(
    TournamentTourDocument,
    baseOptions
  );
}
export type TournamentTourQueryHookResult = ReturnType<typeof useTournamentTourQuery>;
export type TournamentTourLazyQueryHookResult = ReturnType<typeof useTournamentTourLazyQuery>;
export type TournamentTourQueryResult = ApolloReactCommon.QueryResult<
  TournamentTourQuery,
  TournamentTourQueryVariables
>;
export const UnisenderListsDocument = gql`
  query unisenderLists {
    unisenderLists {
      id
      title
    }
  }
`;
export type UnisenderListsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<UnisenderListsQuery, UnisenderListsQueryVariables>,
  'query'
>;

export const UnisenderListsComponent = (props: UnisenderListsComponentProps) => (
  <ApolloReactComponents.Query<UnisenderListsQuery, UnisenderListsQueryVariables>
    query={UnisenderListsDocument}
    {...props}
  />
);

export type UnisenderListsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  UnisenderListsQuery,
  UnisenderListsQueryVariables
> &
  TChildProps;
export function withUnisenderLists<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UnisenderListsQuery,
    UnisenderListsQueryVariables,
    UnisenderListsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UnisenderListsQuery,
    UnisenderListsQueryVariables,
    UnisenderListsProps<TChildProps>
  >(UnisenderListsDocument, {
    alias: 'unisenderLists',
    ...operationOptions,
  });
}

/**
 * __useUnisenderListsQuery__
 *
 * To run a query within a React component, call `useUnisenderListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnisenderListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnisenderListsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnisenderListsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UnisenderListsQuery, UnisenderListsQueryVariables>
) {
  return ApolloReactHooks.useQuery<UnisenderListsQuery, UnisenderListsQueryVariables>(
    UnisenderListsDocument,
    baseOptions
  );
}
export function useUnisenderListsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnisenderListsQuery, UnisenderListsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<UnisenderListsQuery, UnisenderListsQueryVariables>(
    UnisenderListsDocument,
    baseOptions
  );
}
export type UnisenderListsQueryHookResult = ReturnType<typeof useUnisenderListsQuery>;
export type UnisenderListsLazyQueryHookResult = ReturnType<typeof useUnisenderListsLazyQuery>;
export type UnisenderListsQueryResult = ApolloReactCommon.QueryResult<
  UnisenderListsQuery,
  UnisenderListsQueryVariables
>;
export const UserDocument = gql`
  query user($id: Int!) {
    user(id: $id) {
      ...user
    }
  }
  ${UserFragmentDoc}
`;
export type UserComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<UserQuery, UserQueryVariables>,
  'query'
> &
  ({ variables: UserQueryVariables; skip?: boolean } | { skip: boolean });

export const UserComponent = (props: UserComponentProps) => (
  <ApolloReactComponents.Query<UserQuery, UserQueryVariables> query={UserDocument} {...props} />
);

export type UserProps<TChildProps = {}> = ApolloReactHoc.DataProps<UserQuery, UserQueryVariables> & TChildProps;
export function withUser<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<TProps, UserQuery, UserQueryVariables, UserProps<TChildProps>>
) {
  return ApolloReactHoc.withQuery<TProps, UserQuery, UserQueryVariables, UserProps<TChildProps>>(UserDocument, {
    alias: 'user',
    ...operationOptions,
  });
}

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>) {
  return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
}
export function useUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
  return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = ApolloReactCommon.QueryResult<UserQuery, UserQueryVariables>;
export const UserApplicationLogsDocument = gql`
  query userApplicationLogs($applicationId: Int!) {
    userApplicationLogs(applicationId: $applicationId) {
      ...userApplicationLog
    }
  }
  ${UserApplicationLogFragmentDoc}
`;
export type UserApplicationLogsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<UserApplicationLogsQuery, UserApplicationLogsQueryVariables>,
  'query'
> &
  ({ variables: UserApplicationLogsQueryVariables; skip?: boolean } | { skip: boolean });

export const UserApplicationLogsComponent = (props: UserApplicationLogsComponentProps) => (
  <ApolloReactComponents.Query<UserApplicationLogsQuery, UserApplicationLogsQueryVariables>
    query={UserApplicationLogsDocument}
    {...props}
  />
);

export type UserApplicationLogsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  UserApplicationLogsQuery,
  UserApplicationLogsQueryVariables
> &
  TChildProps;
export function withUserApplicationLogs<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserApplicationLogsQuery,
    UserApplicationLogsQueryVariables,
    UserApplicationLogsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserApplicationLogsQuery,
    UserApplicationLogsQueryVariables,
    UserApplicationLogsProps<TChildProps>
  >(UserApplicationLogsDocument, {
    alias: 'userApplicationLogs',
    ...operationOptions,
  });
}

/**
 * __useUserApplicationLogsQuery__
 *
 * To run a query within a React component, call `useUserApplicationLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserApplicationLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserApplicationLogsQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useUserApplicationLogsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UserApplicationLogsQuery, UserApplicationLogsQueryVariables>
) {
  return ApolloReactHooks.useQuery<UserApplicationLogsQuery, UserApplicationLogsQueryVariables>(
    UserApplicationLogsDocument,
    baseOptions
  );
}
export function useUserApplicationLogsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserApplicationLogsQuery, UserApplicationLogsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<UserApplicationLogsQuery, UserApplicationLogsQueryVariables>(
    UserApplicationLogsDocument,
    baseOptions
  );
}
export type UserApplicationLogsQueryHookResult = ReturnType<typeof useUserApplicationLogsQuery>;
export type UserApplicationLogsLazyQueryHookResult = ReturnType<typeof useUserApplicationLogsLazyQuery>;
export type UserApplicationLogsQueryResult = ApolloReactCommon.QueryResult<
  UserApplicationLogsQuery,
  UserApplicationLogsQueryVariables
>;
export const UserApplicationsDocument = gql`
  query userApplications($query: DefaultQueryInput) {
    userApplications(query: $query) {
      ...userApplication
    }
  }
  ${UserApplicationFragmentDoc}
`;
export type UserApplicationsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<UserApplicationsQuery, UserApplicationsQueryVariables>,
  'query'
>;

export const UserApplicationsComponent = (props: UserApplicationsComponentProps) => (
  <ApolloReactComponents.Query<UserApplicationsQuery, UserApplicationsQueryVariables>
    query={UserApplicationsDocument}
    {...props}
  />
);

export type UserApplicationsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  UserApplicationsQuery,
  UserApplicationsQueryVariables
> &
  TChildProps;
export function withUserApplications<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserApplicationsQuery,
    UserApplicationsQueryVariables,
    UserApplicationsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserApplicationsQuery,
    UserApplicationsQueryVariables,
    UserApplicationsProps<TChildProps>
  >(UserApplicationsDocument, {
    alias: 'userApplications',
    ...operationOptions,
  });
}

/**
 * __useUserApplicationsQuery__
 *
 * To run a query within a React component, call `useUserApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserApplicationsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useUserApplicationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UserApplicationsQuery, UserApplicationsQueryVariables>
) {
  return ApolloReactHooks.useQuery<UserApplicationsQuery, UserApplicationsQueryVariables>(
    UserApplicationsDocument,
    baseOptions
  );
}
export function useUserApplicationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserApplicationsQuery, UserApplicationsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<UserApplicationsQuery, UserApplicationsQueryVariables>(
    UserApplicationsDocument,
    baseOptions
  );
}
export type UserApplicationsQueryHookResult = ReturnType<typeof useUserApplicationsQuery>;
export type UserApplicationsLazyQueryHookResult = ReturnType<typeof useUserApplicationsLazyQuery>;
export type UserApplicationsQueryResult = ApolloReactCommon.QueryResult<
  UserApplicationsQuery,
  UserApplicationsQueryVariables
>;
export const UserGroupDocument = gql`
  query userGroup($id: Int!) {
    userGroup(id: $id) {
      ...userGroup
    }
  }
  ${UserGroupFragmentDoc}
`;
export type UserGroupComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<UserGroupQuery, UserGroupQueryVariables>,
  'query'
> &
  ({ variables: UserGroupQueryVariables; skip?: boolean } | { skip: boolean });

export const UserGroupComponent = (props: UserGroupComponentProps) => (
  <ApolloReactComponents.Query<UserGroupQuery, UserGroupQueryVariables> query={UserGroupDocument} {...props} />
);

export type UserGroupProps<TChildProps = {}> = ApolloReactHoc.DataProps<UserGroupQuery, UserGroupQueryVariables> &
  TChildProps;
export function withUserGroup<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserGroupQuery,
    UserGroupQueryVariables,
    UserGroupProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<TProps, UserGroupQuery, UserGroupQueryVariables, UserGroupProps<TChildProps>>(
    UserGroupDocument,
    {
      alias: 'userGroup',
      ...operationOptions,
    }
  );
}

/**
 * __useUserGroupQuery__
 *
 * To run a query within a React component, call `useUserGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserGroupQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UserGroupQuery, UserGroupQueryVariables>
) {
  return ApolloReactHooks.useQuery<UserGroupQuery, UserGroupQueryVariables>(UserGroupDocument, baseOptions);
}
export function useUserGroupLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserGroupQuery, UserGroupQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<UserGroupQuery, UserGroupQueryVariables>(UserGroupDocument, baseOptions);
}
export type UserGroupQueryHookResult = ReturnType<typeof useUserGroupQuery>;
export type UserGroupLazyQueryHookResult = ReturnType<typeof useUserGroupLazyQuery>;
export type UserGroupQueryResult = ApolloReactCommon.QueryResult<UserGroupQuery, UserGroupQueryVariables>;
export const UserSettingsDocument = gql`
  query userSettings {
    userSettings {
      ...userSetting
    }
  }
  ${UserSettingFragmentDoc}
`;
export type UserSettingsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<UserSettingsQuery, UserSettingsQueryVariables>,
  'query'
>;

export const UserSettingsComponent = (props: UserSettingsComponentProps) => (
  <ApolloReactComponents.Query<UserSettingsQuery, UserSettingsQueryVariables> query={UserSettingsDocument} {...props} />
);

export type UserSettingsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  UserSettingsQuery,
  UserSettingsQueryVariables
> &
  TChildProps;
export function withUserSettings<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserSettingsQuery,
    UserSettingsQueryVariables,
    UserSettingsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserSettingsQuery,
    UserSettingsQueryVariables,
    UserSettingsProps<TChildProps>
  >(UserSettingsDocument, {
    alias: 'userSettings',
    ...operationOptions,
  });
}

/**
 * __useUserSettingsQuery__
 *
 * To run a query within a React component, call `useUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSettingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UserSettingsQuery, UserSettingsQueryVariables>
) {
  return ApolloReactHooks.useQuery<UserSettingsQuery, UserSettingsQueryVariables>(UserSettingsDocument, baseOptions);
}
export function useUserSettingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserSettingsQuery, UserSettingsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<UserSettingsQuery, UserSettingsQueryVariables>(
    UserSettingsDocument,
    baseOptions
  );
}
export type UserSettingsQueryHookResult = ReturnType<typeof useUserSettingsQuery>;
export type UserSettingsLazyQueryHookResult = ReturnType<typeof useUserSettingsLazyQuery>;
export type UserSettingsQueryResult = ApolloReactCommon.QueryResult<UserSettingsQuery, UserSettingsQueryVariables>;
export const UserSubscriberDocument = gql`
  query userSubscriber($id: String!) {
    userSubscriber(id: $id) {
      ...userSubscriber
    }
  }
  ${UserSubscriberFragmentDoc}
`;
export type UserSubscriberComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<UserSubscriberQuery, UserSubscriberQueryVariables>,
  'query'
> &
  ({ variables: UserSubscriberQueryVariables; skip?: boolean } | { skip: boolean });

export const UserSubscriberComponent = (props: UserSubscriberComponentProps) => (
  <ApolloReactComponents.Query<UserSubscriberQuery, UserSubscriberQueryVariables>
    query={UserSubscriberDocument}
    {...props}
  />
);

export type UserSubscriberProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  UserSubscriberQuery,
  UserSubscriberQueryVariables
> &
  TChildProps;
export function withUserSubscriber<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserSubscriberQuery,
    UserSubscriberQueryVariables,
    UserSubscriberProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserSubscriberQuery,
    UserSubscriberQueryVariables,
    UserSubscriberProps<TChildProps>
  >(UserSubscriberDocument, {
    alias: 'userSubscriber',
    ...operationOptions,
  });
}

/**
 * __useUserSubscriberQuery__
 *
 * To run a query within a React component, call `useUserSubscriberQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscriberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscriberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserSubscriberQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UserSubscriberQuery, UserSubscriberQueryVariables>
) {
  return ApolloReactHooks.useQuery<UserSubscriberQuery, UserSubscriberQueryVariables>(
    UserSubscriberDocument,
    baseOptions
  );
}
export function useUserSubscriberLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserSubscriberQuery, UserSubscriberQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<UserSubscriberQuery, UserSubscriberQueryVariables>(
    UserSubscriberDocument,
    baseOptions
  );
}
export type UserSubscriberQueryHookResult = ReturnType<typeof useUserSubscriberQuery>;
export type UserSubscriberLazyQueryHookResult = ReturnType<typeof useUserSubscriberLazyQuery>;
export type UserSubscriberQueryResult = ApolloReactCommon.QueryResult<
  UserSubscriberQuery,
  UserSubscriberQueryVariables
>;
export const UserSubscriptionDocument = gql`
  query userSubscription($id: Int!) {
    userSubscription(id: $id) {
      ...userSubscription
    }
  }
  ${UserSubscriptionFragmentDoc}
`;
export type UserSubscriptionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<UserSubscriptionQuery, UserSubscriptionQueryVariables>,
  'query'
> &
  ({ variables: UserSubscriptionQueryVariables; skip?: boolean } | { skip: boolean });

export const UserSubscriptionComponent = (props: UserSubscriptionComponentProps) => (
  <ApolloReactComponents.Query<UserSubscriptionQuery, UserSubscriptionQueryVariables>
    query={UserSubscriptionDocument}
    {...props}
  />
);

export type UserSubscriptionProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  UserSubscriptionQuery,
  UserSubscriptionQueryVariables
> &
  TChildProps;
export function withUserSubscription<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserSubscriptionQuery,
    UserSubscriptionQueryVariables,
    UserSubscriptionProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserSubscriptionQuery,
    UserSubscriptionQueryVariables,
    UserSubscriptionProps<TChildProps>
  >(UserSubscriptionDocument, {
    alias: 'userSubscription',
    ...operationOptions,
  });
}

/**
 * __useUserSubscriptionQuery__
 *
 * To run a query within a React component, call `useUserSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscriptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserSubscriptionQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UserSubscriptionQuery, UserSubscriptionQueryVariables>
) {
  return ApolloReactHooks.useQuery<UserSubscriptionQuery, UserSubscriptionQueryVariables>(
    UserSubscriptionDocument,
    baseOptions
  );
}
export function useUserSubscriptionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserSubscriptionQuery, UserSubscriptionQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<UserSubscriptionQuery, UserSubscriptionQueryVariables>(
    UserSubscriptionDocument,
    baseOptions
  );
}
export type UserSubscriptionQueryHookResult = ReturnType<typeof useUserSubscriptionQuery>;
export type UserSubscriptionLazyQueryHookResult = ReturnType<typeof useUserSubscriptionLazyQuery>;
export type UserSubscriptionQueryResult = ApolloReactCommon.QueryResult<
  UserSubscriptionQuery,
  UserSubscriptionQueryVariables
>;
export const WidgetsDocument = gql`
  query widgets {
    widgets {
      commerceWidget: commerceWidget {
        shops: shops {
          id
          clientId
          state
          name
          description
          codeMask
          catalogueId
          currencyId
          saleEnabled
          createdAt
          updatedAt
          items
          orders
        }
      }
    }
  }
`;
export type WidgetsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<WidgetsQuery, WidgetsQueryVariables>,
  'query'
>;

export const WidgetsComponent = (props: WidgetsComponentProps) => (
  <ApolloReactComponents.Query<WidgetsQuery, WidgetsQueryVariables> query={WidgetsDocument} {...props} />
);

export type WidgetsProps<TChildProps = {}> = ApolloReactHoc.DataProps<WidgetsQuery, WidgetsQueryVariables> &
  TChildProps;
export function withWidgets<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    WidgetsQuery,
    WidgetsQueryVariables,
    WidgetsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<TProps, WidgetsQuery, WidgetsQueryVariables, WidgetsProps<TChildProps>>(
    WidgetsDocument,
    {
      alias: 'widgets',
      ...operationOptions,
    }
  );
}

/**
 * __useWidgetsQuery__
 *
 * To run a query within a React component, call `useWidgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWidgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWidgetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWidgetsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WidgetsQuery, WidgetsQueryVariables>) {
  return ApolloReactHooks.useQuery<WidgetsQuery, WidgetsQueryVariables>(WidgetsDocument, baseOptions);
}
export function useWidgetsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WidgetsQuery, WidgetsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<WidgetsQuery, WidgetsQueryVariables>(WidgetsDocument, baseOptions);
}
export type WidgetsQueryHookResult = ReturnType<typeof useWidgetsQuery>;
export type WidgetsLazyQueryHookResult = ReturnType<typeof useWidgetsLazyQuery>;
export type WidgetsQueryResult = ApolloReactCommon.QueryResult<WidgetsQuery, WidgetsQueryVariables>;
