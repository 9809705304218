import { gql } from 'apollo-boost';
import { EXTENDED_LOYALTY_PROMO_FRAGMENT } from '../fragments/loyaltyPromo';

export const LOYALTY_PROMO_QUERY = gql`
  query loyaltyPromo($id: Int!) {
    loyaltyPromo(id: $id) {
      ...extendedLoyaltyPromo
    }
  }
  ${EXTENDED_LOYALTY_PROMO_FRAGMENT}
`;
